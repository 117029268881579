import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpResponse, HttpErrorResponse, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from "rxjs";
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const router = this.injector.get(Router);
    return next.handle(request)
      .pipe(
        tap((ev: HttpEvent<any>) => {
          if (ev instanceof HttpResponse) {
            //console.debug('processing http response');
          }
        }), catchError(response => {
          if (response instanceof HttpErrorResponse) {
            var error = response.error.errors && response.error.errors[0];
            var errorCode = 500;
            switch (response.status) {
              case 400: errorCode = 400; break;
              case 401: errorCode = 401; break;
              case 404: errorCode = 404; break;
              case 422: errorCode = 422; break;
              case 500:
              default: errorCode = 500;
            }

            if (response.status === 500 && (error === 'Internal Server Error' || error === 'Internal Error')) {
              router.navigate(['error'], { state: { errorCode: errorCode } });
            }
            console.log('Processing http error', response);
          }
          return throwError(response);
        }));
  }
}
