import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DatascapeService {
  private _baseUrl = '/api/datascape';

  constructor(private http: HttpClient) {}

  getDatascapeReport(locationId: number, yearMonth: string): Observable<any> {
    return this.http.get<any>(this._baseUrl + '/' + locationId + '/' + yearMonth);
  }

  hideMTN(locationId: number, MTN: string, yearMonth: string): Observable<any> {
    return this.http.get(this._baseUrl + '/' + locationId + '/' + MTN + '/' + yearMonth + '/Hide');
  }

  hideMtnMultiple(dictionary): Observable<any> {    
    return this.http.post(this._baseUrl + '/HideImeiMultiple', dictionary);
  }

  addNote(locationId: number, MTN: string, yearMonth: string, note: string): Observable<any> {
    if (!MTN) {
      MTN = '0';
    }
    var noteObject = { 'locationId': locationId, 'MTN': MTN, 'yearMonth': yearMonth, 'note': note }
    return this.http.post(this._baseUrl + '/AddNote', noteObject);
  }

  editNote(noteId: number, note: string): Observable<any> {
    return this.http.get(this._baseUrl +'/'+ noteId + '/' + note + '/EditNote');
  }

  deleteNote(noteId: number): Observable<any> {
    return this.http.get(this._baseUrl +'/'+ noteId + '/DeleteNote');
  }
}
