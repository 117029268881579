export class Formula {
  id?: number;
  clientId: number;
  name: string;
  formulaValue: string;
  position: number;
  trendingPossible: boolean;
  color: string;
  decimalLength: number;
  warningLimit: number;
  belowAverageLimit: number;
  formulaViewGroupId: number;
  isDefaultVisibleOnEmployeeAndCompareMTDReport: boolean;
  isMoney: boolean;
  isPercentage: boolean;
  averageAchievedForRewards: boolean;
  formulaViewGroupName?: string;
  currentDecision?: string;
  value?: number;
  newValue?: number;
  list?: any[];
  containsComplexFormula?: boolean;
}
