import { Injectable } from '@angular/core';
import { LocalStorage } from '../decorators/local-storage';

const USER_NAME = 'UserName';

@Injectable()
export class LoggedInUserDetails {
    @LocalStorage
    rememberMe;

    constructor() {
    }

    saveUserName(userName: any) {
        if (this.rememberMe === 'true') {
            localStorage.setItem(USER_NAME, userName);
        } else {
            sessionStorage.setItem(USER_NAME, userName);
        }
    }

    getUserName(): string {
        if (this.rememberMe === 'true') {
            return localStorage.getItem(USER_NAME);
        } else {
            return sessionStorage.getItem(USER_NAME);
        }
    }

    clearUserName(): void {
        sessionStorage.removeItem(USER_NAME);
        localStorage.removeItem(USER_NAME);
    }
}
