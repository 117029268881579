
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../../core/services/auth.service';
import { Duration } from '../models/duration';
import { ShippingDetailViewModel } from '../models/shipment-reconciliation';
import { ShipmentReconciliationSearchRequest } from '../models/shipment-reconciliation-search-request';
import { DurationService } from '../services/duration.service';
import { LocationService } from '../services/location.service';
import { ShipmentReconciliationService } from '../services/shipment-reconciliation.service';
import { ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import * as XLSX from 'xlsx';
import { LoaderService } from '../services/loader.service';
import { MatDialog } from '@angular/material/dialog';
import { NotesDialogComponent } from '../dialogs/notes/notes-dialog.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import * as moment from 'moment';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDatepicker } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormControl } from '@angular/forms';
import { ConfirmDialogComponent } from '../dialogs/confirm/confirm-dialog.component';

export class Filters {
  value: number;
  viewValue: string;
} export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-shipment-reconciliation',
  templateUrl: './shipment-reconciliation.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
})
export class ShipmentReconciliationComponent implements OnInit, OnDestroy {
  constructor(
    private _authService: AuthService,
    private _shipmentReconciliationService: ShipmentReconciliationService,
    private _durationService: DurationService,
    private _locationService: LocationService,
    private _notifier: NotifierService,
    private _loaderService: LoaderService, private dialog: MatDialog, private observer: BreakpointObserver
  ) {

    this.observer.observe('(max-width: 1025px)').subscribe((result) => {
      console.log('max', result);
      if (result.matches) {
        this.columnDefinations = [
          { name: "Serial#", isSortable: true, mappedToProperty: "serialNumber", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Product Name", isSortable: true, mappedToProperty: "productName", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Tracking", isSortable: true, mappedToProperty: "tracking", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Vendor", isSortable: true, mappedToProperty: "vendor", class: "", isFilterable: true, sticky: false, hide: false },
          { name: "POSVendor", isSortable: true, mappedToProperty: "posVendor", class: "", isFilterable: true, sticky: false, hide: false },
          { name: "Order Created Date", isSortable: true, mappedToProperty: "orderCreatedDate", class: "", isFilterable: true, sticky: false, hide: true },
          { name: "RCPO#", isSortable: true, mappedToProperty: "rcpo", class: "", isFilterable: true, sticky: false, hide: true },
          { name: "Sub-Agent PO#", isSortable: true, mappedToProperty: "subAgentPO", class: "", isFilterable: true, sticky: false, hide: true },
          { name: "Ship To Code", isSortable: true, mappedToProperty: "shipToCode", class: "", isFilterable: true, sticky: false, hide: true },
          { name: "Store Name", isSortable: true, mappedToProperty: "storeName", class: "", isFilterable: true, sticky: false, hide: true },
          { name: "Owner", isSortable: true, mappedToProperty: "owner", class: "", isFilterable: true, sticky: false, hide: true },
          { name: "Item Code", isSortable: true, mappedToProperty: "itemCode", class: "", isFilterable: true, sticky: false, hide: true },
          { name: "Ship Date", isSortable: true, mappedToProperty: "shipDate", class: "", isFilterable: true, sticky: false, hide: true },
          { name: "Ship Via", isSortable: true, mappedToProperty: "shipVia", class: "", isFilterable: true, sticky: false, hide: true },
          { name: "POS Order#", isSortable: true, mappedToProperty: "posOrder", class: "", isFilterable: true, sticky: false, hide: true },
          { name: "App#", isSortable: true, mappedToProperty: "app", class: "", isFilterable: true, sticky: false, hide: true },
          { name: "End Customer", isSortable: true, mappedToProperty: "endCustomer", class: "", isFilterable: true, sticky: false, hide: true },
          { name: "MTN", isSortable: true, mappedToProperty: "mtn", class: "", isFilterable: true, sticky: false, hide: true },
          { name: "In RQ", isSortable: true, mappedToProperty: "notInRQ", class: "", isFilterable: true, sticky: false, hide: false },
          { name: "Verizon Cost", isSortable: true, mappedToProperty: "itemizedProductCost", class: "", isFilterable: true, sticky: false, hide: false },
          { name: "RQ Cost", isSortable: true, mappedToProperty: "invoiceProductCost", class: "", isFilterable: true, sticky: false, hide: false },
          { name: "Cost Variance", isSortable: true, mappedToProperty: "costVariance", class: "", isFilterable: true, sticky: false, hide: false },
          { name: "Reconciled", isSortable: true, mappedToProperty: "isHidden", class: "", isFilterable: true, sticky: false, hide: false },
          { name: "Notes", isSortable: true, mappedToProperty: "notes", class: "", isFilterable: true, sticky: false, hide: false },
        ];
        this.selectedColumns = this.columnDefinations.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
      }
    });
  }

  //#region Table settings
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selectedClientId: number;
  dataSource = new MatTableDataSource();
  months: any;
  originalData: ShippingDetailViewModel[] = [];
  data: ShippingDetailViewModel[] = [];
  moneySymbol = '$';
  selectedIMEI: string;
  noteToAdd: string;
  daysToIgnore = 0;
  filterDate: Date;
  todayDate: Date;
  recentDataDate: string;
  monthYear: string;
  subscriptionToClients: Subscription;
  private dialogRef: any;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  columnDefinations = [
    { name: "Status", isSortable: true, mappedToProperty: "icon", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Order Created Date", isSortable: true, mappedToProperty: "orderCreatedDate", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Ship Date", isSortable: true, mappedToProperty: "shipDate", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Cost Variance", isSortable: true, mappedToProperty: "costVariance", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Purchase Cost", isSortable: true, mappedToProperty: "itemizedProductCost", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Received Cost", isSortable: true, mappedToProperty: "invoiceProductCost", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "In POS", isSortable: true, mappedToProperty: "notInRQ", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Product Name", isSortable: true, mappedToProperty: "productName", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Tracking", isSortable: true, mappedToProperty: "tracking", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Vendor", isSortable: true, mappedToProperty: "vendor", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "POSVendor", isSortable: true, mappedToProperty: "posVendor", class: "", isFilterable: true, sticky: false, hide: false },

    { name: "RCPO#", isSortable: true, mappedToProperty: "rcpo", class: "", isFilterable: true, sticky: false, hide: true },
    { name: "Sub-Agent PO#", isSortable: true, mappedToProperty: "subAgentPO", class: "", isFilterable: true, sticky: false, hide: true },
    { name: "Ship To Code", isSortable: true, mappedToProperty: "shipToCode", class: "", isFilterable: true, sticky: false, hide: true },
    { name: "Store Name", isSortable: true, mappedToProperty: "storeName", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Owner", isSortable: true, mappedToProperty: "owner", class: "", isFilterable: true, sticky: false, hide: true },
    { name: "Item Code", isSortable: true, mappedToProperty: "itemCode", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "RQ Item Code", isSortable: true, mappedToProperty: "rqItemCode", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Ship Via", isSortable: true, mappedToProperty: "shipVia", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "POS Order#", isSortable: true, mappedToProperty: "posOrder", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "App#", isSortable: true, mappedToProperty: "app", class: "", isFilterable: true, sticky: false, hide: true },
    { name: "End Customer", isSortable: true, mappedToProperty: "endCustomer", class: "", isFilterable: true, sticky: false, hide: true },
    { name: "MTN", isSortable: true, mappedToProperty: "mtn", class: "", isFilterable: true, sticky: false, hide: true },
    { name: "Serial#", isSortable: true, mappedToProperty: "serialNumber", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Final Purchase Cost", isSortable: true, mappedToProperty: "finalItemizedProductCost", class: "", isFilterable: true, sticky: false, hide: true },
    { name: "Final Variance", isSortable: true, mappedToProperty: "finalCostVariance", class: "", isFilterable: true, sticky: false, hide: true },
    { name: "Reconciled", isSortable: true, mappedToProperty: "isHidden", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Notes", isSortable: true, mappedToProperty: "notes", class: "", isFilterable: true, sticky: false, hide: false },
  ];


  iconDefinition = [
    { toolTip: "Missing Data", color: "orange", code: 0 },
    { toolTip: "Incorrect", color: "red", code: 1 },
    { toolTip: "Balanced", color: "green", code: 2 },
    { toolTip: "Extra", color: "cyan", code: 3 },
  ];

  applyFilter(filterValue: string) {
    if (filterValue) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  getDisplayedColumns(): string[] {
    return this.columnDefinations.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
  }

  reconcileAllVisible() {
    this._loaderService.show();
    let hideThese = [];
    var spot = (this.dataSource.paginator.pageIndex) * this.dataSource.paginator.pageSize;
    for (let i = spot; i < spot + this.dataSource.paginator.pageSize; i++) {
      if (i < this.dataSource.filteredData.length) {
        if (this.selectedFilter === 3) this.dataSource.filteredData[i]['isHidden'] = false;
        else this.dataSource.filteredData[i]['isHidden'] = true;
        hideThese.push(this.dataSource.filteredData[i]['serialNumber']);
      }
    }
    this.hideIMEI(hideThese);
  }

  filterDays(add: number) {
    this.daysToIgnore += add;
    this.filterDate.setDate(this.filterDate.getDate() - add);
    this.updateDataSource(this.data);
  }
  getDateFromIgnore() {
    return moment(this.filterDate).format(this._durationService.format1);
    //return new Date(this.filterDate.getFullYear(), this.filterDate.getMonth(), this.filterDate.getDate());
  }

  checkDates(dateIn) {
    if (Date.parse(this.filterDate.toDateString()) >= Date.parse(dateIn)) {
      return true;
    }
    return false;
  }

  findLatestDateOfNonPOSData(imeiList: any) {
    var tempDate;

    imeiList.forEach(x => {
      if ((x.orderCreatedDate) && (!tempDate || new Date(x.orderCreatedDate) > tempDate)) {
        tempDate = new Date(x.orderCreatedDate);
      } else if ((x.vendorOrderDate) && (!tempDate || new Date(x.vendorOrderDate) > tempDate)) {
        tempDate = new Date(x.vendorOrderDate);
      }
    });
    if (!tempDate || tempDate.getFullYear() < 2000) {
      if (this.searchRequest.DateRange.FromDate) {
        tempDate = new Date(this.searchRequest.DateRange.FromDate);
        tempDate.setDate(1);
      }
    }
    if (!tempDate) {
      tempDate = new Date();
    }
    this.filterDate = tempDate;
    this.recentDataDate = moment(this.filterDate).format(this._durationService.format1);
  }

  exportTableToExcel() {
    let sheetData = [];
    let firstRow = [];
    let mapToList = [];
    this.columnDefinations.forEach(x => {
      if (!x.hide) {
        firstRow.push(x.name);
        mapToList.push(x.mappedToProperty);
      }
    });
    sheetData.push(firstRow);
    this.data.forEach(y => {
      let thisRow = [];
      mapToList.forEach(m => {
        let textToShow = '';
        switch (m) {
          case 'notes':
            textToShow = this.getTextBydata(y, this.getColumnDefinationByMappedToProperty('notes')) + this.getNoteCountText(y);
            break;
          case 'icon':
            textToShow = this.getIconHover(y);
            break;
          default:
            textToShow = y[m];
            break;
        }
        thisRow.push(textToShow);
      });
      sheetData.push(thisRow);
    });
    let ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ReconcileReceiving.xlsx');
  }

  toBeReconciled = new Map<string, ToHideReconciled>();
  toBeUnreconciled = new Map<string, ToHideReconciled>();
  collectToReconcile(event: MatCheckboxChange, element) {
    let tempVar = this.data.find(x => x.serialNumber == element['serialNumber']);
    let tempDate = new Date(tempVar.orderCreatedDate);
    let monthYear = ((tempDate.getMonth() + 1).toString() + tempDate.getFullYear().toString()).toString();
    let key = this.selectedClientId + element['serialNumber'] + monthYear;
    let value: ToHideReconciled = {
      clientId: this.selectedClientId, command: "daily", imei: element['serialNumber'], monthYear: monthYear
    };

    if (event.checked) {
      this.toBeReconciled.set(key, value);
      this.toBeUnreconciled.delete(key);
    } else {
      this.toBeReconciled.delete(key);
      this.toBeUnreconciled.set(key, value);
    }
  }


  hideImeiMultiple(isReconcile) {

    let that = this;

    this.reconcileDialog = this.dialog.open(ConfirmDialogComponent,
      {
        autoFocus: true, disableClose: false, panelClass: '',
        data: { title: "Confirm", message: "Are you sure you want to reconcile selected?" }
      });
    this.reconcileDialog.afterClosed().subscribe(result => {
      if (result === true) {
        if (isReconcile) {
          this._shipmentReconciliationService.reconcileMultipleDaily(Array.from(this.toBeReconciled.values()))
            .subscribe((response) => {
              this._notifier.notify('success', 'Reconciled successfully!');
              that.toBeReconciled.clear();
              this.applySelectedFilter();
            },
              (errorResponse) => { this._notifier.notify('error', errorResponse.error); },
              () => { this._loaderService.hide(); });
        } else {
          this._shipmentReconciliationService.reconcileMultipleDaily(Array.from(this.toBeUnreconciled.values())).subscribe((response) => {
            this._notifier.notify('success', 'Unreconciled successfully!');
            that.toBeReconciled.clear();
            this.applySelectedFilter();
          }, (errorResponse) => { this._notifier.notify('error', errorResponse.error); }, () => { this._loaderService.hide(); });
        }
      }
    });


  }
  reconcileDialog: any;
  hideIMEI(serialNumbers: any) {
    let count = 0;
    if (serialNumbers.length > 1) {
      serialNumbers.forEach(imei => {
        var tempVar = this.data.find(x => x.serialNumber == imei);
        var tempDate = new Date(tempVar.orderCreatedDate);
        this._shipmentReconciliationService.hideIMEI(this.selectedClientId, imei, ((tempDate.getMonth() + 1).toString() + tempDate.getFullYear().toString()).toString()).subscribe((response) => {
          count++;
          if (count === serialNumbers.length) {
            this.applySelectedFilter();
          }
        }, (errorResponse) => { }, () => { this._loaderService.hide(); });
      });
    } else {
      this.reconcileDialog = this.dialog.open(ConfirmDialogComponent,
        {
          autoFocus: true, disableClose: false, panelClass: '',
          data: { title: "Confirm", message: "Are you sure you want to reconcile selected?" }
        });
      this.reconcileDialog.afterClosed().subscribe(result => {
        if (result === true) {
          var tempVar = this.data.find(x => x.serialNumber == serialNumbers);
          var tempDate = new Date(tempVar.orderCreatedDate);
          this._shipmentReconciliationService.hideIMEI(this.selectedClientId,
            JSON.stringify(serialNumbers),
            ((tempDate.getMonth() + 1).toString() + tempDate.getFullYear().toString()).toString())
            .subscribe((response) => {
              this._notifier.notify('success', 'Reconciled successfully!');
              this.applySelectedFilter();
            },
              (errorResponse) => { this._notifier.notify('error', errorResponse.error); },
              () => { this._loaderService.hide(); });
        }
      });
    }
  }

  getNoteCountText(element) {
    var textToShow = '';
    var noteList = element.notes.filter(x => !x.isFinal)
    textToShow = element.notes.length > 0 ? " (+" + (noteList.length) + " ...)" : "";
    return textToShow;
  }

  openNotesDialog(column, element) {
    if (column == 'notes') {
      this.dialogRef = this.dialog.open(NotesDialogComponent,
        {
          autoFocus: true,
          disableClose: false,
          panelClass: 'notes-component',
          data: { element: element, selectedClientId: this.selectedClientId, pageOrigin: 'shipment' }
        });
      this.dialogRef.afterClosed().subscribe(result => {
        if (result.update === true) {
          const thisSerial = this.data.find(x => x['serialNumber'] === element['serialNumber']);
          thisSerial['notes'].push({ 'id': result.newNote.id, 'note': result.newNote.note, 'userName': result.newNote.userName, 'dateTime': result.newNote.dateTime });
          this.applySelectedFilter();
        }
      });
    }
  }

  //invoiceProductCost
  findIconType(element: any) {
    return element['invoiceProductCost'] == element['itemizedProductCost'] ? this.iconDefinition[2] : this.iconDefinition[1];
  }

  getIconColor(element: any) {
    return this.findIconType(element).color;
  }

  getIconHover(element: any) {
    return this.findIconType(element).toolTip;
  }

  getTextBydata(element, columnDef) {
    var textToShow = '';
    switch (columnDef.name) {
      case 'Status':
        textToShow = this.getIconHover(element);
        break;
      case 'In POS':
      case 'Reconciled':
        if (element[columnDef.mappedToProperty] == true) {
          textToShow = 'No';
        } else {
          textToShow = 'Yes';
        }
        break;
      case 'Notes':
        if (element.notes && element.notes.length > 0) {
          textToShow = element.notes[element.notes.length - 1].note;
          //textToShow += element.notes.length > 1 ? " (+" + (element.notes.length - 1) + " ...)" : "";
        } else {
          textToShow = "...";
        }
        break;
      case 'Purchase Cost':
      case 'Received Cost':
      case 'Cost Variance\n(Received-Purchase)':
      case 'Final Purchase Cost':
      case 'Final Variance':
        textToShow = (+(element[columnDef.mappedToProperty]).toFixed(2)).toString();
        textToShow = this.moneySymbol + textToShow;
        break;
      default:
        textToShow = element[columnDef.mappedToProperty];
    }
    return textToShow;
  }

  isTrackingLink(element, columnDef) {
    switch (columnDef.name) {
      case 'Tracking':
        if (element.trackingLink) {
          var isLink = element.trackingLink.indexOf("http://") >= 0 || element.trackingLink.indexOf("https://") >= 0;
          return isLink;
        }
      default:
        return false;
    }
  }

  getColumnDefinationByMappedToProperty(mappedToProperty) {
    var column = this.columnDefinations.find(x => x.mappedToProperty == mappedToProperty);
    switch (column.name) {
      case 'Cost Variance':
        column.name = 'Cost Variance\n(Received-Purchase)';
        break;
    }
    return column;
  }

  selectedColumns = this.getDisplayedColumns();
  columnsChanged(event: MatSelectChange) {
    for (var i = 0; i < this.columnDefinations.length; i++) {
      var column = this.selectedColumns.find(x => x == this.columnDefinations[i].mappedToProperty);
      if (column != null) {
        this.columnDefinations[i].hide = false;
      } else {
        this.columnDefinations[i].hide = true;
      }
    }
  }

  //#endregion Table settings

  //#region Filters settings

  //#region custom Filters
  filters: Filters[] = [
    { value: 0, viewValue: 'All Data' },
    { value: 1, viewValue: 'Not In POS' },
    { value: 2, viewValue: 'Cost Variance' },
    { value: 4, viewValue: 'Cost Variance #' },
    { value: 3, viewValue: 'Reconciled' },
    { value: 6, viewValue: 'NotMatchingProducts' }
  ];

  searchTerm: string;
  selectedFilter: number = 0; // Default filter is "All Data"
  varianceFilter: number = 4;

  async applySelectedFilter() {
    this.getColumnDefinationByMappedToProperty("invoiceProductCost").hide = false;
    this.getColumnDefinationByMappedToProperty("costVariance").hide = false;
    switch (this.selectedFilter) {
      case 0:
        this.searchRequest.FilterBy = 0;
        this.data = await this.originalData;
        break;
      case 1:
        this.searchRequest.FilterBy = 1;
        this.data = await this.filterByProperty(this.originalData, true, ["notInRQ"]);
        this.data = await this.data.filter(x => !x.isHidden);
        break;
      case 2:
        this.searchRequest.FilterBy = 2;
        this.data = await this.filterByCostVariance(this.originalData, "costVariance", 0);
        this.data = await this.data.filter(x => !x.isHidden);
        break;
      case 3:
        this.searchRequest.FilterBy = 3;
        this.data = await this.filterByProperty(this.originalData, true, ["isHidden"]);
        break;
      case 4:
        this.searchRequest.FilterBy = 4
        this.data = await this.filterByCostVariance(this.originalData, "costVariance", this.varianceFilter);
        this.data = await this.data.filter(x => !x.isHidden);
        break;
      case 5:
        this.searchRequest.FilterBy = 5
        this.getColumnDefinationByMappedToProperty("invoiceProductCost").hide = true;
        this.getColumnDefinationByMappedToProperty("costVariance").hide = true;
        this.getColumnDefinationByMappedToProperty("finalCostVariance").hide = false;
        this.getColumnDefinationByMappedToProperty("finalItemizedProductCost").hide = false;
        this.data = await this.filterByCostVariance(this.originalData, "finalCostVariance", 0);
        this.data = await this.data.filter(x => !x.isHidden);
        break;
      case 6:
        this.searchRequest.FilterBy = 6;
        this.data = await this.filterByProperty(this.originalData, true, ["notMatchingProducts"]);
        this.data = await this.data.filter(x => !x.isHidden);
        break;
    }
    this.updateDataSource(this.data);
  }

  async filterChanged(event: MatSelectChange) {
    this.selectedFilter = event.value;
    this.applySelectedFilter();
  }

  filterByProperty(collection, value, key) {
    return collection.filter(o => key.reduce((c, v) => c[v] || {}, o) === value);
  }

  filterByCostVariance(collection, key, amount) {
    return collection.filter((object) => {
      if (amount === 0) return object[key] != 0;
      else return (object[key] < (amount * -1) && object[key] < 0) || (object[key] > (amount * 1) && object[key] > 0);
    })
  }
  //#endregion custom Filters

  startDate: Date;
  endDate: Date;
  minDate: Date;
  maxDate: Date;



  //#endregion Filters settings
  date = new FormControl(moment());
  selectedDuration: Duration;
  yearHandler(year: moment.Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(year.year());
    this.date.setValue(ctrlValue);
  }

  monthHandler(month: any, datepicker: MatDatepicker<moment.Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(month.month());
    ctrlValue.year(month.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.selectedDuration.duration.FromDate = month.startOf("month").format(this._durationService.dateStartFormat).toString();
    this.selectedDuration.duration.ToDate = month.endOf("month").format(this._durationService.dateEndFormat).toString();
    let tempDate = new Date(this.selectedDuration.duration.FromDate);
    this.monthYear = '0' + (tempDate.getMonth() + 1) + tempDate.getFullYear();
    if (this.monthYear.length > 6) this.monthYear = this.monthYear.substring(1, 7);
    this.searchRequest.DateRange = this.selectedDuration.duration;
    this.loadSelectedMonth();
  }

  searchRequest: ShipmentReconciliationSearchRequest;
  duration: number = 3;
  ngOnInit() {
    this.durations = this._durationService.getDuration();
    this.durations.pop();
    this.selectedDuration = this.durations.find(x => x.value === this.duration);
    let tempDate = new Date(this.selectedDuration.duration.FromDate);
    this.monthYear = '0' + (tempDate.getMonth() + 1) + tempDate.getFullYear();
    if (this.monthYear.length > 6) this.monthYear = this.monthYear.substring(1, 7);
    this.startDate = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0));
    this.endDate = this.startDate;
    this.filterDate = moment().toDate();
    this.todayDate = moment().toDate();
    this.monthYear = moment().format('YYYY-MM');
    this.months = this._durationService.getMonths();
    this.selectedClientId = this._authService.clientId();
    this.selectedFilter = this.filters[1].value;
    this.searchRequest = {
      FilterBy: 0,
      DateRangeMoment: null,
      DateRange: this.durations.find(x => x.value === this.duration).duration,
      ClientId: this.selectedClientId,
      IsFinal: false
    };
    this.subscriptionToClients = this._locationService.currentActualClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        this.selectedClientId = message;
        if (this.selectedClientId && this.selectedClientId !== 0) {
          this.onClientSelected();
        }
      });

  }

  onClientSelected() {

    this.searchRequest.ClientId = this.selectedClientId;
    this.getData(this.searchRequest);
  }

  getData(searchRequest: ShipmentReconciliationSearchRequest) {
    this.tableready = false;
    this._loaderService.show();
    this._shipmentReconciliationService.getShipmentReconciliationData(searchRequest).subscribe(async (response) => {
      this.originalData = this.preProcessingViewModel(response);
      this.data = response;
      this.findLatestDateOfNonPOSData(this.data);
      await this.applySelectedFilter();
      this.tableready = true;
    }, (errorResponse) => { }, () => { this._loaderService.hide(); });
  }


  // updating tracking link
  preProcessingViewModel(data) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].shipVia) {
        var shipVia = data[i].shipVia.toLowerCase();
        if (shipVia.includes("fedex")) {
          var tracking = data[i].tracking;
          data[i].trackingLink = "https://www.fedex.com/fedextrack/?trknbr=" + tracking;
        }
        else if (shipVia.includes("ups")) {
          var tracking = data[i].tracking;
          data[i].trackingLink = "https://www.ups.com/track?loc=en_US&tracknum=" + tracking;
        }
      }

      if (data[i].orderCreatedDate === '0001-01-01T00:00:00') {
        data[i].orderCreatedDate = "N/A";
      } else {
        data[i].orderCreatedDate = new Date(data[i].orderCreatedDate).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' });
        data[i].orderCreatedDate = moment(data[i].orderCreatedDate).format(this._durationService.format1);
      }

      if (data[i].shipDate === '0001-01-01T00:00:00') {
        data[i].shipDate = "N/A";
      } else {
        data[i].shipDate = new Date(data[i].shipDate).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' });
        data[i].shipDate = moment(data[i].shipDate).format(this._durationService.format1);
      }

    }

    return data;
  }
  tableready = false;

  updateDataSource(data) {
    let filteredItems = data.filter(x => this.checkDates(x.orderCreatedDate));
    if (filteredItems) {
      this.dataSource = new MatTableDataSource(filteredItems);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.applyFilter(this.searchTerm);
    }
  }

  /**Duration */
  durations: Duration[];

  selectedMonth: any;


  loadSelectedMonth() {
    this.updateDataSource([]);
    if (this.selectedMonth) {
      let dateRange = this._durationService.getDateRangeByMonth(this.selectedMonth);
      this.selectedDuration.duration.FromDate = dateRange.start.format(this._durationService.dateStartFormat).toString();
      this.selectedDuration.duration.ToDate = dateRange.end.format(this._durationService.dateEndFormat).toString();
      this.searchRequest.DateRange = this.selectedDuration.duration;
    }
    this.getData(this.searchRequest);
  };

  selectIMEI(imei: string) {
    if (this.selectedIMEI !== imei) {
      this.selectedIMEI = imei;
      this.noteToAdd = '';
    }
  }

  ngOnDestroy() {
    this.columnDefinations = null;
    this.dataSource = null;
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
    this.dialogRef?.close();
  }

}
type ToHideReconciled = {
  command: string;
  clientId: number;
  imei: string;
  monthYear: string;
};
