import { Component, OnInit, ViewChild, ViewChildren, ElementRef, OnDestroy, QueryList } from '@angular/core';
import * as moment from 'moment';
import * as cloneDeep from 'lodash/cloneDeep';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../../core/services/auth.service';
import { MtdReportService } from '../services/mtd-report.service';
import { ClientService } from '../services/client.service';
import { FormulaService } from '../services/formula.service';
import { LocationService } from '../services/location.service';
import { FormulaViewGroupService } from '../services/formula-view-group.service';
import { TableService } from '../services/table.service';
import { UploadStatusService } from '../services/upload-status.service';
import { FormulaViewGroup } from '../models/formula-view-group';
import { LocationTypeCode } from '../models/location-type-code';
import { Formula } from '../models/formula';
import { MTDReport } from '../models/mtd-report';
import { UploadStatus } from '../models/upload-status';
import { EmployeeService } from '../services/employee.service';
import { ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import { MatTabGroup } from "@angular/material/tabs";



const CONFIG_CLIENTID_KEY = 'ConfigClientId';
const CONFIG_CLIENT_KEY = 'ConfigClient';
const CONFIG_REGION_KEY = 'ConfigRegion';
const CONFIG_DISTRICT_KEY = 'ConfigDistrict';

@Component({
  selector: 'app-mtd-report',
  templateUrl: './mtd-report.component.html'
})
export class MtdReportComponent implements OnInit, OnDestroy {
  clientId: number;
  previousClientId: number;
  clients: any[] = [];
  modes: any[];
  selectedMode: any;
  durations: any[];
  regions: any[];
  selectedClientId: number;
  selectedClient: any;
  selectedRegionId: number;
  selectedRegion: any;
  districts: any[];
  selectedDistrictId: number;
  selectedDistrict: any;
  clientSubscription: Subscription;
  districtSubscription: Subscription;
  selectedDuration: any;
  lastSelectedDurationForStoreAndEmployeeReport: any;
  isTrendingVisible: boolean;
  trendingCounter: number;
  isTrendingApplied: boolean;
  isFormulaSelectionVisible: boolean;
  isFormulaViewGroupSelectionVisible: boolean;
  singleOtherDurationSelection: boolean;
  multipleOtherDurationSelection: boolean;
  customDurationSelection: boolean;
  fetchReportInProgress: boolean;
  selectedOtherDuration: any;
  selectedOtherCurrentDuration: any;
  selectedOtherPreviousDuration: any;
  customRangeStartDate: any;
  customRangeEndDate: any;
  formulas: Formula[];
  formulaViewGroups: FormulaViewGroup[];
  uploadStatus: string;
  storeReportData: any[];
  employeeReportData: any[];
  compareReportData: any[];
  columns: any[];
  report: any = MTDReport;
  localStorageLoadingInProgress: boolean;
  columnUnderSorting = -1;
  isAscendingOrder = true;
  clientName: string;
  regionName: string;
  districtName: string;
  debug = false;
  table: any = {
    sorting: true,
    toggleColumns: true
  };

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @ViewChild("tabGroup") tabGroup: MatTabGroup;
  @ViewChild('employeeReportTable', { read: ElementRef }) employeeReportTable: ElementRef;
  @ViewChild('compareReportTable', { read: ElementRef }) compareReportTable: ElementRef;
  @ViewChildren('storeReportTable', { read: ElementRef }) storeReportTable: QueryList<ElementRef>;

  constructor(private _notifier: NotifierService,
    private _tableService: TableService,
    private _locationService: LocationService,
    private _clientService: ClientService,
    private _mtdReportService: MtdReportService,
    private _formulaService: FormulaService,
    private _employeeService: EmployeeService,
    private _uploadStatusService: UploadStatusService,
    private _formulaViewGroupService: FormulaViewGroupService,
    private _authService: AuthService,
    private _loaderService: LoaderService) { }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
  }

  ngOnInit() {
    this._loaderService.show();
    this.localStorageLoadingInProgress = true;
    this.districtSubscription = this._locationService.currentDistrict
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        this.selectedDistrictId = message;
      });
    this.clientSubscription = this._locationService.currentActualClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        this.clientId = message; this.initializeComponent();
      });

    var hourFraction = (20 - moment().hour()) / 20;
    if (hourFraction < 0) hourFraction = 0;
    this.trendingCounter = +((moment().daysInMonth()) / (moment().date() - hourFraction)).toFixed(2);
    this.waitForInitialLoading();
  }

  wait(ms) {
    return new Promise((resolve, reject) => setTimeout(resolve, ms));
  }

  async waitForInitialLoading() {
    while (true) {
      await this.wait(1000);
      if (this.selectedDistrictId !== 0 && !this.localStorageLoadingInProgress) {
        await this.wait(1000);    
        this.searchReport();
        break;
      }
    }
  }

  sortTables(index) {
    this.storeReportTable.forEach(x => {
      this.sortTable(index, 1, x);
    });
  }

  sortTable(index, reportType: number, tableItem?) {
    this.columnUnderSorting = index - 1;
    let table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = reportType === 1 ?
      tableItem.nativeElement : reportType === 2 ?
        this.employeeReportTable.nativeElement : this.compareReportTable.nativeElement;
    switching = true;
    dir = 'asc';
    while (switching) {
      switching = false;
      rows = table.tBodies[0].rows;
      for (i = 0; i < (rows.length - 1); i++) {
        shouldSwitch = false;

        if (reportType === 3) {
          x = rows[i].getElementsByTagName('td')[+index].getElementsByClassName('mtd-compare-new-value')[0];
          y = rows[i + 1].getElementsByTagName('td')[+index].getElementsByClassName('mtd-compare-new-value')[0];
        } else {
          x = rows[i].getElementsByTagName('td')[+index];
          y = rows[i + 1].getElementsByTagName('td')[+index];
        }


        x = Number(x.innerText.replace(/,/g, '').replace(/%/g, '').replace(/\$/g, ''));
        y = Number(y.innerText.replace(/,/g, '').replace(/%/g, '').replace(/\$/g, ''));

        if (dir === 'asc') {
          if (x > y) {
            shouldSwitch = true;
            break;
          }
        } else if (dir === 'desc') {
          if (x < y) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount === 0 && dir === 'asc') {
          dir = 'desc';
          switching = true;
        }
      }
    }
    this.isAscendingOrder = (dir === 'asc');
  }

  initializeComponent() {
    if (this.debug) { console.log('Initalize component'); }
    this._formulaViewGroupService.getFormulaViewGroups(this.clientId, (formulaViewGroupResponse) => {
      this.formulaViewGroups = formulaViewGroupResponse;
      this._formulaService.getFormulas(this.clientId, (formulaResponse) => {
        this.formulas = formulaResponse.filter(f => f.position !== 0);
        this.getModes();
        this.getSelectedFormulas();
        this.getSelectedFormulaViewGroups();
      }, true);
    });
  }

  onMTDReportModeSelected(mode: any) {
    this.selectedMode = mode;
    this.singleOtherDurationSelection = false;
    this.multipleOtherDurationSelection = false;
    this.customDurationSelection = false;
    this.columnUnderSorting = -1;
    this.isAscendingOrder = false;
    this.getDurations();
    this.searchReport();
  }

  getClients() {
    this._locationService.getLocations(LocationTypeCode.Client, (response) => {
      this.clients = response;
    });
  }

  onClientSelected() {
    if (+this.selectedClientId === 0) {
      return;
    }

    this.selectedClientId = +this.selectedClientId;
    this.previousClientId = this.clientId;
    this.selectedClient = this.clients.find(r => r.id === this.selectedClientId);
    this.clientId = this.selectedClient.clientId;
    this.selectedRegionId = 0;
    this.selectedDistrictId = 0;
    this._locationService.getLocations(LocationTypeCode.Region, (response) => {
      this.regions = response;
    }, this.selectedClient.id);
  }

  onRegionSelected() {
    if (+this.selectedRegionId === 0) {
      return;
    }

    this.selectedRegion = this.regions.find(r => r.id === +this.selectedRegionId);
    this._locationService.getLocations(LocationTypeCode.District, (response) => {
      this.districts = response;
    }, this.selectedRegion.id);
  }

  onDistrictSelected() {
    if (+this.selectedDistrictId === 0) {
      return;
    }

    this.selectedDistrict = this.districts.find(r => r.id === +this.selectedDistrictId);
    // this.searchReport();
  }

  //onComplexDurationSelected() {
  //  if (this.singleOtherDurationSelection && this.selectedOtherDuration) {
  //    this.searchReport();
  //  } else if (this.customDurationSelection && this.customRangeStartDate && this.customRangeEndDate){
  //    this.searchReport();
  //  }
  //}

  onDurationSelected() {
    this.isTrendingVisible = ((+this.selectedMode === MTDReport.Store || +this.selectedMode === MTDReport.Employee)
      && this.selectedDuration === this._mtdReportService.currentMonthDuration) ? true : false;

    if (+this.selectedMode === MTDReport.Store || +this.selectedMode === MTDReport.Employee) {
      this.lastSelectedDurationForStoreAndEmployeeReport = this.selectedDuration;
    }

    if (this.selectedDuration === 'CR') {
      this.customDurationSelection = true;
    } else {
      this.customDurationSelection = false;
    }

    if (this.selectedDuration === 'OTH') {
      if (+this.selectedMode === MTDReport.Store || +this.selectedMode === MTDReport.Employee) {
        this.singleOtherDurationSelection = true;
        this.multipleOtherDurationSelection = false;
      } else {
        this.singleOtherDurationSelection = false;
        this.multipleOtherDurationSelection = true;
      }
    } else {
      this.singleOtherDurationSelection = false;
      this.multipleOtherDurationSelection = false;
    }
    if (!this.customDurationSelection && !this.singleOtherDurationSelection && !this.multipleOtherDurationSelection) {
      //this.searchReport();
    }
  }

  updateColumnsSelectionTypeVisibility() {
    if (+this.selectedMode === MTDReport.Store) {
      this.isFormulaViewGroupSelectionVisible = true;
      this.isFormulaSelectionVisible = false;
    } else if (+this.selectedMode === MTDReport.Employee || +this.selectedMode === MTDReport.Compare) {
      this.isFormulaViewGroupSelectionVisible = false;
      this.isFormulaSelectionVisible = true;
    } else {
      this.isFormulaViewGroupSelectionVisible = false;
      this.isFormulaSelectionVisible = false;
    }
  }

  getModes() {
    this.modes = this._mtdReportService.modes;
    this.selectedMode = MTDReport.Store;
    this.updateColumnsSelectionTypeVisibility();
    this.getDurations();
  }

  getDurations() {
    this._mtdReportService.getDurations(this.selectedMode, (response) => {
      this.durations = response;

      switch (this.selectedMode) {
        case MTDReport.Store:
        case MTDReport.Employee:
          if (this.lastSelectedDurationForStoreAndEmployeeReport) {
            this.selectedDuration = this.lastSelectedDurationForStoreAndEmployeeReport;
            this.isTrendingVisible = this.lastSelectedDurationForStoreAndEmployeeReport === this._mtdReportService.currentMonthDuration;
          } else {
            this.selectedDuration = this.lastSelectedDurationForStoreAndEmployeeReport = this._mtdReportService.currentMonthDuration;
            //this.selectedDuration = this.lastSelectedDurationForStoreAndEmployeeReport = response[3].value;
            this.isTrendingVisible = true;
          }

          break;
        case MTDReport.Compare:
          this.selectedDuration = this._mtdReportService.thisMonthVsLastMonthToDateDuration;
          this.isTrendingVisible = false;
          break;
      }
      this.localStorageLoadingInProgress = false;
    });
  }

  //sortBy(prop: string) {
  //  return this.storeReportData[0].stores.sort((a, b) => {

  //    console.log("a[prop]", a[prop]);
  //    console.log("b[prop]", b[prop]);
  //    console.log("a[prop] > b[prop]", a[prop] > b[prop]);
  //    console.log("a[prop] ===  b[prop]", a[prop] ===  b[prop]);
  //    return a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1;
  //  });
  //  //return this.storeReportData[0].stores.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  //}

  getReportData() {
    this._loaderService.show();
    const self = this;
    let duration = null;
    const mode = +this.selectedMode;
    const districtId = +this.selectedDistrictId;
    this.fetchReportInProgress = true;

    if (this.selectedDuration === 'OTH') {
      if (+this.selectedMode === MTDReport.Store || +this.selectedMode === MTDReport.Employee) {
        duration = this._mtdReportService.getDurationInMonth([this.selectedOtherDuration]).join('');
      } else {
        duration =
          this._mtdReportService.getDurationInMonth([this.selectedOtherCurrentDuration, this.selectedOtherPreviousDuration]).join('||');
      }
    } else if (this.selectedDuration === 'CR') {
      duration = this._mtdReportService.getDuration([this.customRangeStartDate, this.customRangeEndDate]).join('||');
    } else {
      duration = this.selectedDuration;
    }

    this._mtdReportService.getReport(mode, districtId, duration)
      .subscribe((response: any[]) => {
        switch (+this.selectedMode) {
          case MTDReport.Store:
            self.storeReportData = response['districts'];
            //console.log(response);
            self.storeReportData.forEach((district) => {
              district.stores.forEach((store) => {
                store.employees.forEach((employee) => {
                  employee.formulas = cloneDeep(this.formulas);
                  employee.formulas.forEach((formula) => {
                    formula.value = this._mtdReportService.CalculateFormulaValue
                      (employee.skuGroups, employee.conversionData, formula.formulaValue, employee.hoursWorkedData);
                  });
                });
                store.formulas = cloneDeep(this.formulas);
                store.formulas.forEach((formula) => {
                  formula.value = this._mtdReportService.CalculateFormulaValue
                    (store.skuGroups, store.conversionData, formula.formulaValue, store.hoursWorkedData);
                });
                store.collapse = false;
              });
              let prop = 'name';
              district.stores.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
              district.formulas = cloneDeep(this.formulas);
              district.formulas.forEach((formula) => {
                formula.value = this._mtdReportService.CalculateFormulaValue
                  (district.skuGroups, district.conversionData, formula.formulaValue, district.hoursWorkedData);
              });
            });
            break;
          case MTDReport.Employee:
            self.employeeReportData = response['employees'];
            //console.log(response);
            self.employeeReportData.forEach((employee) => {
              employee.formulas = cloneDeep(this.formulas);
              employee.formulas.forEach((formula) => {
                formula.value = this._mtdReportService.CalculateFormulaValue
                  (employee.skuGroups, employee.conversionData, formula.formulaValue, employee.hoursWorkedData);
              });
            });
            let prop = 'name';
            self.employeeReportData.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
            break;
          case MTDReport.Compare:
            self.compareReportData = response['employees'];
            self.compareReportData.forEach((employee) => {
              employee.formulas = cloneDeep(this.formulas);
              employee.formulas.forEach((formula) => {
                formula.value = this._mtdReportService.CalculateFormulaValue(employee.skuGroups, null, formula.formulaValue, null);
                formula.newValue = this._mtdReportService.CalculateFormulaValue(employee.skuGroups1, null, formula.formulaValue, null);
              });
            });
            break;
        }
        this.fetchReportInProgress = false;
      }, (error) => { this.fetchReportInProgress = false, this._notifier.notify('error', error.error); }, () => {
        this.updateColumnsSelectionTypeVisibility();
        this._loaderService.hide(); this.tabGroup.realignInkBar();
      });
  }

  toggleCollapse(store: any) {
    store.collapse = !store.collapse;
  }

  getEmptyFormulas(formulas: any[]) {
    const resultedFormulas: any[] = [];

    formulas.forEach((formula, index) => {
      resultedFormulas.push({
        id: formula.id,
        name: formula.name,
        trendingPossible: formula.trendingPossible,
        decimalLength: formula.decimalLength,
        isPercentage: formula.isPercentage,
        isMoney: formula.isMoney,
        value: 0
      });
    });
    return resultedFormulas;
  }

  getColumns() {
    if (+this.selectedMode === MTDReport.Employee || +this.selectedMode === MTDReport.Compare) {
      this.updateColumnsByFormulas();
    } else {
      this.updateColumnsByFormulaViewGroups();
    }
    this.updateColumnsByFormulas();
    this.getReportData();
  }

  getFormattedDateTime(unformattedDateTime) {
    this.uploadStatus = `${moment(unformattedDateTime).format('L')} ${moment(unformattedDateTime).format('LT')}`;
  }

  getUploadStatus() {
    this._uploadStatusService.getUploadStatus(this.clientId)
      .subscribe((response: UploadStatus) => {
        this.getFormattedDateTime(response.time);
      }, (error) => this._notifier.notify('error', error.error), () => { });
  }

  searchReport() {

    if (this.debug) { console.log('Search Report'); }
    if (this._authService.isSuperAdmin()) {
      if (/*0 === +this.selectedClientId || 0 === +this.selectedRegionId ||*/ 0 === +this.selectedDistrictId) {
        this._notifier.notify('warning', 'Please select District');
        return;
      }
    } else {
      if (/*0 === +this.selectedRegionId ||*/ 0 === +this.selectedDistrictId) {
        this._notifier.notify('warning', 'Please select District');
        return;
      }
    }

    if (0 === +this.selectedDuration) {
      this._notifier.notify('warning', 'Please select Duration');
      return;
    }

    if ('OTH' === this.selectedDuration && this.singleOtherDurationSelection && !this.selectedOtherDuration) {
      this._notifier.notify('warning', 'Please select period');
      return;
    }

    if ('OTH' === this.selectedDuration && this.multipleOtherDurationSelection &&
      (!this.selectedOtherCurrentDuration || !this.selectedOtherPreviousDuration)) {
      this._notifier.notify('warning', 'Please select current and previous periods');
      return;
    }

    if (this.fetchReportInProgress) {
      this._notifier.notify('warning', 'Searching already in progress. Please try after sometime.');
      return;
    }

    /*if (this.previousClientId != this.clientId) {
      this.previousClientId = this.clientId;
      this.initializeComponent();
    } else {*/
    this.getColumns();
    this.getUploadStatus();

    this.isTrendingApplied = false;
    // }
    /*localStorage.setItem(CONFIG_CLIENTID_KEY, (this.clientId).toString());
    localStorage.setItem(CONFIG_CLIENT_KEY, (this.selectedClientId).toString());
    localStorage.setItem(CONFIG_REGION_KEY, (this.selectedRegionId).toString());
    localStorage.setItem(CONFIG_DISTRICT_KEY, (this.selectedDistrictId).toString());
    */

  }

  toggleFormulaViewGroup() {
    this.formulaViewGroups.forEach(x => {
      if (this.selectedFormulaViewGroups.find(viewGroup => viewGroup == x.name)) {
        x.isSelected = true;
      } else {
        x.isSelected = false;
      }
    });
    this.getSelectedFormulaViewGroups();
    this.updateColumnsByFormulaViewGroups();
  }

  updateColumnsByFormulaViewGroups() {
    const selectedFormulaViewGroups = this.formulaViewGroups.filter(viewGroup => (viewGroup.isSelected === true));
    const formulasUnderFormulaViewGroups = this.getFlatArray(selectedFormulaViewGroups.map(viewGroup => viewGroup.formulas))
      .map(function (formula) {
        return {
          'name': formula.id,
          'header': formula.name,
          'color': formula.color,
          'position': +formula.position,
          'ascending': true,
          'sorting': true,
          'visible': true
        };
      }).filter(f => f.position !== 0);
    this.columns = this._tableService.sorting(formulasUnderFormulaViewGroups, 'position', true);
  }

  toggleFormulas(formula: any) {
    const selectedFormula = this.formulas.filter(f => f.id === formula.id)[0];
    selectedFormula.isDefaultVisibleOnEmployeeAndCompareMTDReport =
      !selectedFormula.isDefaultVisibleOnEmployeeAndCompareMTDReport;
    this.getSelectedFormulas();
    this.updateColumnsByFormulas();
  }
  selectedFormulas: any
  getSelectedFormulas() {
    this.selectedFormulas = this.formulas.filter(f => f.isDefaultVisibleOnEmployeeAndCompareMTDReport == true).map(fn => fn.name);
  }
  selectedFormulaViewGroups: any
  getSelectedFormulaViewGroups() {
    this.selectedFormulaViewGroups = this.formulaViewGroups.filter(f => f.isSelected == true).map(fn => fn.name);
  }
  updateColumnsByFormulas() {
    this.getSelectedFormulas();
    const formulas = this.formulas.map(function (formula) {
      return {
        'name': formula.id,
        'header': formula.name,
        'color': formula.color,
        'position': +formula.position,
        'ascending': true,
        'sorting': true,
        'visible': formula.isDefaultVisibleOnEmployeeAndCompareMTDReport
      };
    });

    this.columns = this._tableService.sorting(formulas, 'position', true);
  }

  toggleTrending() {
    this.isTrendingApplied = !this.isTrendingApplied;
  }

  isTrendingPossibleInFormula(array: any[], id: string, property: string) {
    return array.find(f => f.id === id)[property];
  }

  isFormulaUnderWarningLimit(array: any[], id: string) {
    const value = array.find(f => f.id === id)['value'];
    const warningLimit = array.find(f => f.id === id)['warningLimit'];
    return (warningLimit > 0 && value >= 0 && value < warningLimit);
  }

  isFormulaUnderAverageLimit(array: any[], id: string) {
    const value = array.find(f => f.id === id)['value'];
    const warningLimit = array.find(f => f.id === id)['warningLimit'];
    const belowAverageLimit = array.find(f => f.id === id)['belowAverageLimit'];
    return (belowAverageLimit > 0 && value >= warningLimit && value < belowAverageLimit);
  }

  getFormulaComputedPropertyValue(array: any[], id: string, property: string, trendingValue: boolean = false) {
    const formula = array.find(f => f.id === id);
    return (formula.isMoney) ? ((trendingValue) ? '$' + this.formattedValue((formula[property] * this.trendingCounter), formula.decimalLength) :
      '$' + this.formattedValue((formula[property]), formula.decimalLength))
      : (formula.isPercentage) ? ((trendingValue) ?
        this.formattedValue((formula[property] * this.trendingCounter * 100), formula.decimalLength) + '%' :
        this.formattedValue((formula[property] * 100), formula.decimalLength) + '%')
        : ((trendingValue) ? this.formattedValue(formula[property] * this.trendingCounter, formula.decimalLength) : this.formattedValue(formula[property], formula.decimalLength));


    //return (formula.isMoney) ? ((trendingValue) ? '$' + this.formattedValue((formula[property] * this.trendingCounter)) :
    //  '$' + this.formattedValue((formula[property])))
    //  : (formula.isPercentage) ? ((trendingValue) ?
    //    this.formattedValue((formula[property] * this.trendingCounter * 100)) + '%' :
    //    this.formattedValue((formula[property] * 100)) + '%')
    //    : ((trendingValue) ? this.formattedValue(formula[property] * this.trendingCounter) : this.formattedValue(formula[property]));
  }

  getCompareReportMultiplyConstant(array: any[], id: string, numeratorKey: string, denominatorKey: string) {
    const formula = array.find(f => f.id === id);
    const constantValue = +(formula[numeratorKey] / formula[denominatorKey]).toFixed(formula.decimalLength);
    return isNaN(constantValue) ? 0 : constantValue + 'x';
  }

  formattedValue(number: number, decimal) {
    decimal = parseInt(decimal);
    return number.toFixed(decimal).toString();
  }

  formattedValue2(number: number) {
    return Math.floor(number).toLocaleString();
  }

  getFlatArray(array: any[]): any[] {
    const result: any[] = [];
    for (let i = 0; i <= array.length - 1; i++) {
      for (let j = 0; j <= array[i].length - 1; j++) {
        result.push(array[i][j]);
      }
    }

    return result;
  }

  getRGBColor(column: any) {
    let hex = column.color.replace('#', '');
    hex = hex.match(new RegExp('(.{' + hex.length / 3 + '})', 'g'));

    for (let i = 0; i < hex.length; i++) {
      hex[i] = parseInt(hex[i].length === 1 ? hex[i] + hex[i] : hex[i], 16);
    }

    return 'rgba(' + hex.join(',') + ')';
  }
}
