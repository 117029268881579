import { CommissionPeg } from './commission-peg';
import { Formula } from './formula';

export class CommissionFormula {
    id?: string;
    formulaId?: number;
    formula?: Formula;
    growth: string;
    commissionPegs?: CommissionPeg[];
    currentGoal?: number;
    estimatedPay?: number;
    chartData?: any;
  value?: number;
  lastMonthValue?: number;
  lastYearValue?: number;
  trendingValue?: number;
  trendingValueForChart?: number;
}
