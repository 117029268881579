import { Inject, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'group-create',
  templateUrl: 'group-create.component.html',
})
export class GroupCreateDialogComponent implements OnInit {

  title: string;
  newTableGroup: any; formulas: any;

  constructor(public dialogRef: MatDialogRef<GroupCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.newTableGroup = data.newTableGroup;
    this.formulas = data.formulas;
  }

  ngOnInit() { }

  onConfirm(): void { this.dialogRef.close({ action: 'Save', newTableGroup: this.newTableGroup }); }
  addNewReportFormula(): void { this.dialogRef.close({ action: 'Add_Formula' }); }
  editFormula(id: any): void { this.dialogRef.close({ action: 'Edit_Formula', id: id, newTableGroup: this.newTableGroup }); }
  onDismiss(): void { this.dialogRef.close({ action: 'Close' }); }
}

