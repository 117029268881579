import { Component, OnDestroy, OnInit } from '@angular/core';

import { NgxFileDropEntry, FileSystemFileEntry,  FileSystemDirectoryEntry} from 'ngx-file-drop';
import { ImportService } from '../services/import.service';
import { ClientService } from '../services/client.service';
import { AuthService } from '../../core/services/auth.service';
import { LocationService } from '../services/location.service';
import { NotifierService } from 'angular-notifier';
import { ReplaySubject } from 'rxjs';

const CONFIG_CLIENT_KEY = 'ConfigClient';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html'
})
export class ImportComponent implements OnInit, OnDestroy {
  files: NgxFileDropEntry[] = [];
  uploadReport = null;
  fileUploadInProgress = false;
  clients: any[] = [];
  localStorageLoadingInProgress: boolean;
  selectedClientId: number;
  clientName: string;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private importService: ImportService,
    private _clientService: ClientService,
    public _authService: AuthService,
    private _locationService: LocationService,
    private _notifier: NotifierService) { }

  ngOnInit() {
    if (this._authService.isSuperAdmin()) {
      this.getClients();
      this.selectedClientId = 0;
    } else {
      this.selectedClientId = this._authService.clientId();
      this._clientService.getClientNameById(this.selectedClientId, (name) => { this.clientName = name; });
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
  }

  getClients() {
    this._clientService.getClients((response) => {
      this.clients = response;
    });
  }

  public dropped(files: NgxFileDropEntry[]) {
    if (!+this.selectedClientId) {
      this._notifier.notify('warning', 'Please select client.');
      return;
    }

    if (this.fileUploadInProgress) {
      return;
    }
    this.uploadReport = "";
    const self = this;
    this.files = files;
    const droppedFile = this.files[0];
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        const formData = new FormData();
        formData.append('file', file, droppedFile.relativePath);
        formData.append('client', self.selectedClientId.toString());

        this.fileUploadInProgress = true;
        this.upload(formData);
      });
    } else {
      const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      //console.log(droppedFile.relativePath, fileEntry);
    }
  }

  upload(data: FormData): void {
    this.importService.importFile(data)
      .subscribe(
        (response) => {

          this.uploadReport = JSON.parse(response);
          this.fileUploadInProgress = false;
        },
        (err: any) => {
          this._notifier.notify('error', "Error importing file, ensure the file is closed");

          //console.log('upload(): ' + err),
          this.fileUploadInProgress = false;
      });
  }
}
