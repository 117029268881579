import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SkuGroup } from '../models/sku-group';
import { SkuFilterType } from '../models/sku-filter-type';

@Injectable({
  providedIn: 'root'
})
export class SkuGroupService {
  private _baseUrl = '/api/skugroups';
  private _skuFilterTypesUrl = '/api/skufiltertypes';

  constructor(private http: HttpClient) {}

  getSkuGroups(): Observable<SkuGroup[]> {
    return this.http.get<SkuGroup[]>(this._baseUrl);
  }

  getSkuGroupsByClient(clientId: number, callback: any) {
    this.getSkuGroups().subscribe((response) => {
      callback(response.filter(x => x.clientId === clientId));
    });
  }

  getSkuFilterTypes(): Observable<any[]> {
    return this.http.get<SkuFilterType[]>(this._skuFilterTypesUrl);
  }

  createSkuGroup(name: String, clientId: number): Observable<any> {
    const skuGroup = {
      name,
      clientId
    };

    return this.http.post(this._baseUrl, skuGroup);
  }

  //Not implemented
  //updateSkuGroup(id: String, name: String): Observable<any> {
  //  const skuGroup = {
  //    name
  //  };

  //  return this.http.put(this._baseUrl + '/' + id, skuGroup);
  //}

  deleteSkuGroup(id: string): Observable<any> {
    return this.http.delete(this._baseUrl + '/' + id);
  }
}
