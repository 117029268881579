import { Inject, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'user-create-dialog',
  templateUrl: 'user-create-dialog.component.html',
})
export class UserCreateDialogComponent implements OnInit {

  title: string;
  user:any;
  clients:any;
  userStatusCodes:any;
  roles:any;
  isEdit:false;
  constructor(public dialogRef: MatDialogRef<UserCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title= data.title;
    this.user = data.user;
    this.clients = data.clients;
    this.userStatusCodes = data.userStatusCodes;
    this.roles = data.roles;
    this.isEdit = data.isEdit;
  }

  ngOnInit() { }

  onConfirm(): void {

    let data = { user: this.user };
    this.dialogRef.close(data);
  }

  onDismiss(): void { this.dialogRef.close(null); }
}

