<style>
  .mat-column-model {
    max-width: fit-content;
  }
</style>
<div style="font-size: 20px;padding:10px; border-bottom: #ded7d7 1px solid;" *ngIf="selectedImei && selectedImei.vendorRebates && selectedImei.vendorRebates.length > 0">RQ Items</div>
<table *ngIf="selectedRqColumns.length > 0 && selectedImei.vendorRebates && selectedImei.vendorRebates.length >0" [dataSource]="selectedImei.vendorRebates" mat-table class="table table-stripped table-hover small-txt">
  <ng-container *ngFor="let column of getRqDisplayedColumns(); let colIndex = index;" [matColumnDef]="column">
    <th [hidden]="getRqColumnDefinationByMappedToProperty(column).hide" class="sort-column mat-sort-header-button-custom mat-header-cell" mat-header-cell *matHeaderCellDef
        [matTooltip]="getRqColumnDefinationByMappedToProperty(column).name" [disabled]="!getRqColumnDefinationByMappedToProperty(column).isSortable">
      {{getRqColumnDefinationByMappedToProperty(column).name}}
    </th>
    <ng-container *matCellDef="let element">
      <td mat-cell class="mat-cell" [matTooltip]="element[getRqColumnDefinationByMappedToProperty(column).mappedToProperty]"  [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip>
        {{getTextBydata(element, getRqColumnDefinationByMappedToProperty(column))}}
      </td>
    </ng-container>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="getRqDisplayedColumns()"></tr>
  <tr mat-row *matRowDef="let row; columns: getRqDisplayedColumns();">
  </tr>
</table>
<div style="font-size: 20px;padding:10px; border-bottom: #ded7d7 1px solid;" *ngIf="selectedImei && selectedImei.commissionRebates && selectedImei.commissionRebates.length >0">Verizon Items</div>
<table *ngIf="selectedVerizonColumns.length > 0 && selectedImei.commissionRebates && selectedImei.commissionRebates.length >0" mat-table [dataSource]="selectedImei.commissionRebates" class="table table-stripped table-hover small-txt">
  <ng-container *ngFor="let column of getVerizonDisplayedColumns(); let colIndex = index;" [matColumnDef]="column">
    <th [hidden]="getVerizonColumnDefinationByMappedToProperty(column).hide" class="sort-column mat-sort-header-button-custom mat-header-cell" *matHeaderCellDef
        [matTooltip]="getVerizonColumnDefinationByMappedToProperty(column).name" [disabled]="!getVerizonColumnDefinationByMappedToProperty(column).isSortable">
      {{getVerizonColumnDefinationByMappedToProperty(column).name}}
    </th>
    <ng-container *matCellDef="let element">
      <td mat-cell class="mat-cell" [matTooltip]="element[getVerizonColumnDefinationByMappedToProperty(column).mappedToProperty]" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip [hidden]="">
        {{getTextBydata(element, getVerizonColumnDefinationByMappedToProperty(column))}}
      </td>
    </ng-container>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="getVerizonDisplayedColumns()"></tr>
  <tr mat-row *matRowDef="let row; columns: getVerizonDisplayedColumns();" [hidden]="row.dp == '$0.00' && row.comm == '$0.00'">
  </tr>
</table>
