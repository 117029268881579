import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotifierService } from 'angular-notifier';

declare const _;

@Injectable({
  providedIn: 'root'
})
export class CommissionReconcileService {
  private _baseUrl = '/api/commissionreconcile';

  constructor(private http: HttpClient,
    private _notifier: NotifierService,) { }

  getCommissionReport(locationId: number, date: string): Observable<any> {
    const param = locationId + '@' + date;
    return this.http.get<any[]>(this._baseUrl + '/' + param);
  }
  getCCRSReport(locationId: number, date: string): Observable<any> {
    const param = locationId + '@' + date;
    return this.http.get<any[]>(this._baseUrl + '/CCRS/' + param);
  }
  AddImeiListCommand(list: any[], decision: number, monthYear: string = "0") {
    return this.http.put<any[]>(this._baseUrl + '/addList/' + decision + '/' + monthYear, list);
  }
  //updateImei(item: any, decision: number) {
  //  return this.http.put<any[]>(this._baseUrl + '/updateItem/' + decision, item);
  //}

  updateChargebacks(list: any[]) {
    return this.http.put<any[]>(this._baseUrl + '/updatechargeback', list);
  }
  //getSavedImeis(locationId: number, date: string) {
  //  const param = locationId + '@' + date;
  //  return this.http.get<any[]>(this._baseUrl + '/getImeiList/' + param);
  //}

  //GetRqVzDataByItem( item: any): Observable<any> {    
  //  return this.http.post(this._baseUrl + '/getImeiList', item);
  //}

  //getChargebackSummary(locationId: number, date: string) {
  //  const param = locationId + '@' + date;
  //  return this.http.get<any[]>(this._baseUrl + '/GetChargebackSummary/' + param);
  //}

  hideIMEI(clientId: number, imei: string): Observable<any> {
    return this.http.get(this._baseUrl + '/' + clientId + '/' + imei + '/Hide');
  }

  hideImeiMultiple(dictionary): Observable<any> {
    return this.http.post(this._baseUrl + '/Hide', dictionary);
  }

  addNote(clientId: number, imei: string, note: string): Observable<any> {
    let noteObject = { 'clientId': clientId, 'imei': imei, 'note': note }
    return this.http.post(this._baseUrl + '/AddNote', noteObject);
  }

  editNote(noteId: number, note: string): Observable<any> {
    return this.http.get(this._baseUrl + '/' + noteId + '/' + note + '/EditNote');
  }

  deleteNote(noteId: number): Observable<any> {
    return this.http.get(this._baseUrl + '/' + noteId + '/DeleteNote');
  }

  addNoteMonthlyCommission(clientId: number, id: string, note: string): Observable<any> {
    let noteObject = { 'clientId': clientId, 'id': id, 'note': note }
    return this.http.post(this._baseUrl + '/AddNoteMonthlyCommission', noteObject);
  }
  getIsDataLocked(clientId: number, monthYear: string, doChange: boolean): Observable<any> {
    return this.http.get(this._baseUrl + '/isDataLocked/' + monthYear + '/' + clientId);
  }

  lockData(clientId: number, monthYear: string, doChange: boolean): Observable<any> {
    return this.http.get(this._baseUrl + '/dataLock/' + monthYear + '/' + clientId);
  }
}
