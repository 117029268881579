import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ShipmentReconciliationSearchRequest } from '../models/shipment-reconciliation-search-request';

declare const _;

@Injectable({
  providedIn: 'root'
})
export class ShipmentReconciliationService {
  private _baseUrl = '/api/shipmentreconciliation';

  constructor(private http: HttpClient) { }

  getShipmentReconciliationData(searchRequest:ShipmentReconciliationSearchRequest): Observable<any> {
    return this.http.post(this._baseUrl, {
      FilterBy: searchRequest.FilterBy,
      DateRange: searchRequest.DateRange,
      ClientId: searchRequest.ClientId,
      IsFinal: searchRequest.IsFinal
    });
  }

  hideImeiMultiple(collection): Observable<any> {
    return this.http.post(this._baseUrl + '/HideIMEIMultiple', collection );
  }

  reconcileMultipleDaily(collection): Observable<any> {
    return this.http.post(this._baseUrl + '/ReconcileMultipleDaily', collection );
  }

  reconcileMultipleFinalize(collection): Observable<any> {
    return this.http.post(this._baseUrl + '/ReconcileMultipleFinalize', collection );
  }

  hideIMEI(clientId: number, imei: string, monthYear: string): Observable<any> {
    return this.http.post(this._baseUrl + '/Hide', {
      command: "daily",
      clientId: clientId,
      imei: imei,
      monthYear: monthYear
    });
       //'/' + clientId + '/' + imei + '/Hide');
  }

  OLDhideIMEI(clientId: number, imei:string): Observable<any> {
    return this.http.get(this._baseUrl + '/'+clientId+ '/' + imei + '/Hide');
  }

  hideIMEIFinalized(clientId: number, imei: string, monthYear: string, secondMonthYear: string): Observable<any> {
    return this.http.post(this._baseUrl + '/Hide', {
      Command: "final",
      clientId: clientId,
      imei: imei,
      monthYear: monthYear,
      secondMonthYear: secondMonthYear
    });
  }

  keepIMEIFinalized(clientId: number, imei: string, monthYear: string, balanceCost: number, balanceReceived: number): Observable<any> {
    return this.http.post(this._baseUrl+'/Hide', {
      Command: "keep",
      clientId: clientId,
      imei: imei,
      monthYear: monthYear,
      balanceCost: balanceCost,
      balanceReceived: balanceReceived
    });
  }

  OLDhideIMEIFinalized(clientId: number, imei: string): Observable<any> {
    return this.http.get(this._baseUrl + '/' + clientId + '/' + imei + '/HideFinalized');
  }

  addNote(clientId: number, imei: string, note: string): Observable<any> {
    let noteObject = { 'clientId': clientId, 'imei': imei, 'note': note }
    return this.http.post(this._baseUrl + '/AddNote', noteObject);
  }

  editNote(noteId: number, note: string): Observable<any> {
    return this.http.get(this._baseUrl +'/'+ noteId + '/' + note + '/EditNote');
  }

  deleteNote(noteId: number): Observable<any> {
    return this.http.get(this._baseUrl +'/'+ noteId+'/DeleteNote');
  }
}
