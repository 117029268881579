import { Component, OnInit, OnDestroy, ViewChildren, QueryList, AfterViewInit, ViewChild } from '@angular/core';
import { FormulaViewGroupService } from '../services/formula-view-group.service';
import { NotifierService } from 'angular-notifier';
import { FormulaViewGroup } from '../models/formula-view-group';
import { Action } from '../models/action';
import { TableService } from '../services/table.service';
import { AuthService } from '../../core/services/auth.service';
import { ClientService } from '../services/client.service';
import { Client } from '../models/client';
import { Subscription } from 'rxjs/internal/Subscription';
import { LocationService } from '../services/location.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import { FormulaViewGroupCreateComponent } from './formula-view-group-create-dialog.component';
import { DeleteFormulaViewGroupComponent } from './delete-formula-view-group.component';
import { MatDialog } from '@angular/material/dialog';
const CONFIG_CLIENT_KEY = 'ConfigClient';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-formula-view-group',
  templateUrl: './formula-view-group.component.html'
})
export class FormulaViewGroupComponent implements OnInit, OnDestroy {
  formulaViewGroup: FormulaViewGroup;
  formulaViewGroups: FormulaViewGroup[] = [];
  currentAction: Action;
  loadingInProgress: boolean;
  clientId: number;
  clients: Client[] = [];
  table: any = {
    sorting: true
  };
  subscriptionToClients: Subscription;
  dialog: any = {
    isOpen: false,
    headerText: '',
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    }
  };

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  formulaViewGroupAttributes: any = [{ name: 'name', header: 'Name', ascending: true, sorting: true },
  { name: 'isSelected', header: 'Selected', ascending: true, sorting: true }];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: any; dataSource = new MatTableDataSource();
  constructor(private _notifier: NotifierService,
    private _formulaViewGroupService: FormulaViewGroupService,
    private _tableService: TableService,
    private _authService: AuthService,
    private _clientService: ClientService,
    private _locationService: LocationService,
    private _loaderServer: LoaderService, private matDialog: MatDialog) { }

  ngOnInit() {
    this._locationService.currentActualClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        this.clientId = message; this.getFormulaViewGroups();
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
    this.dialogRef?.close();
  }


  columnDefination = [
    { name: "Id", isSortable: true, mappedToProperty: "id", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Name", isSortable: true, mappedToProperty: "name", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "IsSelected", isSortable: true, mappedToProperty: "isSelected", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Actions", isSortable: true, mappedToProperty: "actions", class: "", isFilterable: true, sticky: true, hide: false },

  ];

  getFormulaViewGroups() {
    this._loaderServer.show();
    this.loadingInProgress = true;
    this._formulaViewGroupService.getAllFormulaViewGroups().subscribe(response => {
      this.formulaViewGroups = response.filter(x => x.clientId === this.clientId);
      this.dataSource = new MatTableDataSource(this.formulaViewGroups);
      setTimeout(() => { this.dataSource.paginator = this.paginator; this.dataSource.sort = this.sort; }, 100);
      this.loadingInProgress = false;
      this._loaderServer.hide();
    });
  }

  getDisplayedColumns() {
    this.displayedColumns = this.columnDefination.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
    return this.displayedColumns;
  }

  getColumnDefinationByMappedToProperty(mappedToProperty) {
    var column = this.columnDefination.find(x => x.mappedToProperty == mappedToProperty);
    return column;
  }

  getTextBydata(element, columnDef) {
    var textToShow = '';
    switch (columnDef.name) {
      default:
        textToShow = element[columnDef.mappedToProperty];
        break;
    }
    return textToShow;
  }

  saveFormulaViewGroup() {
    switch (this.currentAction) {
      case Action.Create:
        this._formulaViewGroupService.createFormulaViewGroup(this.formulaViewGroup.name, this.formulaViewGroup.isSelected, this.clientId)
          .subscribe((response: boolean) => {
            this.dialog.isOpen = false;
            this.getFormulaViewGroups();
            this._notifier.notify('success', 'Formula View Group created successfully.');
          }, (error) => this._notifier.notify('error', error.message), () => { });
        break;
      case Action.Update:
        this._formulaViewGroupService.updateFormulaViewGroup(this.formulaViewGroup.id,
          this.formulaViewGroup.name, this.formulaViewGroup.isSelected)
          .subscribe((response: boolean) => {
            this.dialog.isOpen = false;
            this.getFormulaViewGroups();
            this._notifier.notify('success', 'Formula View Group updated successfully.');
          }, (error) => this._notifier.notify('error', error.message), () => { });
        break;
    }
  }
  private dialogRef: any;
  addFormulaViewGroup() {
    this.formulaViewGroup = { name: null, isSelected: false, clientId: this.clientId };
    this.currentAction = Action.Create;
    this.dialogRef = this.matDialog.open(FormulaViewGroupCreateComponent, {
      autoFocus: true, disableClose: false, panelClass: '', data: { title: 'Add Formula View Group', formulaViewGroup: this.formulaViewGroup }
    });
    this.dialogRef.afterClosed().subscribe(result => { if (result !== false) { this.formulaViewGroup = result.formulaViewGroup; this.saveFormulaViewGroup(); } });
  }

  editFormulaViewGroup(formulaViewGroup) {
    this.formulaViewGroup = formulaViewGroup;
    this.currentAction = Action.Update;

    this.dialogRef = this.matDialog.open(FormulaViewGroupCreateComponent, {
      autoFocus: true, disableClose: false, panelClass: '', data: { title: 'Edit Formula View Group', formulaViewGroup: this.formulaViewGroup }
    });
    this.dialogRef.afterClosed().subscribe(result => { if (result !== false) { this.formulaViewGroup = result.formulaViewGroup; this.saveFormulaViewGroup(); } });
  }

  deleteFormulaViewGroup(formulaViewGroup) {
    this.dialogRef = this.matDialog.open(DeleteFormulaViewGroupComponent, {
      autoFocus: true, disableClose: false, panelClass: '', data: { title: 'Delete Formula View Group', message: 'Are you sure you want to delete this formula view group?' }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result !== false) {
        this._formulaViewGroupService.deleteFormulaViewGroup(formulaViewGroup.id)
          .subscribe((response: boolean) => {
            this.dialog.isOpen = false;
            this.getFormulaViewGroups();
            this._notifier.notify('success', 'Formula View Group deleted successfully.');
          }, (error) => this._notifier.notify('error', error.message), () => { });
      }
    });
  }

  sorting(field, order) {
    if (!this.table.sorting) {
      return;
    }
    this.formulaViewGroups = this._tableService.sorting(this.formulaViewGroups, field, order);
    this.formulaViewGroupAttributes.filter(col => col.name === field)[0].ascending = !order;
  }
}
