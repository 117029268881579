import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Observable, ReplaySubject, Subscription } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { RqVzDataTableComponent } from "../../rq-vz-data-table/rq-vz-data-table.component";
import { CommissionReconcileService } from "../../services/commission-reconcile.service";
import { NotesDialogComponent } from "../../dialogs/notes/notes-dialog.component";
import { LoaderService } from "../../services/loader.service";
import * as XLSX from 'xlsx';
import { EmployeeService } from "../../services/employee.service";
import { Employee } from "../../models/employee";
import { MatDialog } from "@angular/material/dialog";
import { LocationService } from "../../services/location.service";
import { LocationTypeCode } from "../../models/location-type-code";
import { any } from "underscore";
import { ProductSearchDialogComponent } from "../../dialogs/product-search/product-search-dialog.component";

@Component({
  selector: 'chargebacks-data-table',
  templateUrl: './chargebacks-data-table.component.html',
  providers: []
})

export class ChargebacksDataTableComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(private _commissionReconcileService: CommissionReconcileService,
    private _loaderService: LoaderService,
    private _employeeService: EmployeeService,
    private _locationService: LocationService,
    private searchDialog: MatDialog,
    private dialog: MatDialog) { }
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  //@ViewChild(MatPaginator) paginator: MatPaginator;
  //@ViewChild(MatSort) sort: MatSort;
  @ViewChild(RqVzDataTableComponent) rqVzDataTableComponent: RqVzDataTableComponent;

  @Input()
  tableData: any;
  @Output()
  getCommissionReportUpdated = new EventEmitter<string>();
  employeeList: Employee[] = [];
  storeList: number[] = [];
  dataSource = new MatTableDataSource();
  dataSourceDetails = new MatTableDataSource();
  searchTerm: string = '';
  searchTerm2: string = '';
  selectedRow = new Set<any>();
  selectedImei: any;
  selectedItem = {};
  moneySymbol = '$';
  debug = true;
  noData: Observable<boolean>;
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dataSource.paginator = this.paginator.toArray()[0]; this.dataSource.sort = this.sort.toArray()[0];
      this.dataSourceDetails.paginator = this.paginator.toArray()[1]; this.dataSourceDetails.sort = this.sort.toArray()[1];
    });
  }



  getEmployees() {
    this.storeList = this._locationService.getListOfStores();
    this._employeeService.getRecentEmployeeUsersByLocations(this.storeList, 7, (employeeResponse) => {
      this.employeeList = employeeResponse.sort(x => x.name);
    });
  }

  ngOnDestroy(): void {
    this.columnDefinations = null;
    this.dataSource = null;
    this.dialogRef?.close();
    this.searchDialogRef?.close();
  }
  defaultFilterPredicate?: (data: any, filter: string) => boolean;

  ngOnInit(): void {
    /*this.updateDataSource(this.tableData);*/
    this.defaultFilterPredicate = this.dataSourceDetails.filterPredicate;
    this.subscriptionToClients = this._locationService.currentActualClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        this.selectedClientId = message; this.onClientSelected();
      });
  }

  onClientSelected() {
    if (!this.selectedClientId || +this.selectedClientId === 0) {
      return;
    }
    this.selectedClientId = +this.selectedClientId;
    this.selectedClient = this.clients.find(r => r.id === this.selectedClientId);
  }

  //applyFilter(filterValue: string) {
  //  this.dataSource.filter = filterValue.trim().toLowerCase();
  //}

  //applyFilter2(filterValue: string) {
  //  if (filterValue.length > 0)
  //    this.dataSourceDetails.filter = filterValue.trim().toLowerCase();

  //}



  columnDefinations = [
    { name: "Select", isSortable: true, mappedToProperty: "isSelected", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Soldby", isSortable: true, mappedToProperty: "soldBy", class: "", isFilterable: true, sticky: true, hide: false },
    /*{ name: "ChargeTo", isSortable: true, mappedToProperty: "chargeEmployee", class: "", isFilterable: true, sticky: true, hide: false },*/
    { name: "People Charged", isSortable: false, mappedToProperty: "employeesChargedback", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Warning", isSortable: false, mappedToProperty: "warning", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Inv#", isSortable: true, mappedToProperty: "mostRelevantInvoice", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "EquipmentType", isSortable: true, mappedToProperty: "equipmentType", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "IMEI", isSortable: true, mappedToProperty: "imei", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "MTN", isSortable: true, mappedToProperty: "mtn", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Difference", isSortable: true, mappedToProperty: "difference", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Details", isSortable: true, mappedToProperty: "details", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Notes", isSortable: false, mappedToProperty: "commissionIMEINotes", class: "", isFilterable: true, sticky: false, hide: false },

    //{ name: "Amount", isSortable: true, mappedToProperty: "amountChargedBack", class: "price", isFilterable: true, sticky: false, hide: false },
    //{ name: "Comment", isSortable: true, mappedToProperty: "comment", class: "", isFilterable: true, sticky: false, hide: false },
  ];

  columnDefinationsDetails = [
    { name: "Select", isSortable: true, mappedToProperty: "isSelected", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Origin", isSortable: true, mappedToProperty: "relevantProgram", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "EquipmentType", isSortable: true, mappedToProperty: "equipmentType", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Soldby", isSortable: true, mappedToProperty: "soldBy", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "ChargeTo", isSortable: true, mappedToProperty: "chargeEmployee", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Inv#", isSortable: true, mappedToProperty: "relevantInvoice", class: "", isFilterable: true, sticky: true, hide: false },

    { name: "IMEI", isSortable: true, mappedToProperty: "imei", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "MTN", isSortable: true, mappedToProperty: "mtn", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Amount", isSortable: true, mappedToProperty: "amount", class: "price", isFilterable: true, sticky: false, hide: false },
    { name: "Chargeback", isSortable: true, mappedToProperty: "chargebackAmountValue", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Notes", isSortable: false, mappedToProperty: "commissionIMEINotes", class: "", isFilterable: true, sticky: false, hide: false }
    //{ name: "Comment", isSortable: true, mappedToProperty: "comment", class: "", isFilterable: true, sticky: false, hide: false },
  ];

  exportTableToExcel() {
    let sheetData = [];
    let firstRow = [];
    let mapToList = [];
    this.columnDefinations.forEach(x => {
      if (!x.hide) {
        firstRow.push(x.name);
        mapToList.push(x.mappedToProperty);
      }
    });
    sheetData.push(firstRow);
    this.dataSource.filteredData.forEach((y: any) => {
      let thisRow = [];
      mapToList.forEach(m => {
        thisRow.push(y[m]);
      });
      sheetData.push(thisRow);
    });
    let ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'MonthlyCommission.xlsx');
  }

  exportDetailsTableToExcel() {
    let sheetData = [];
    let firstRow = [];
    let mapToList = [];
    this.columnDefinationsDetails.forEach(x => {
      if (!x.hide) {
        firstRow.push(x.name);
        mapToList.push(x.mappedToProperty);
      }
    });
    sheetData.push(firstRow);
    this.dataSourceDetails.filteredData.forEach((y: any) => {
      let thisRow = [];
      mapToList.forEach(m => {
        thisRow.push(y[m]);
      });
      sheetData.push(thisRow);
    });
    let ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'MonthlyCommission.xlsx');
  }

  exportSummaryTableToExcel() {
    let sheetData = [];
    let firstRow = [];
    firstRow.push("");
    firstRow.push(" GP Total Deduction ");
    firstRow.push("");
    firstRow.push("");
    firstRow.push(" Insurance Chargeback ");
    firstRow.push(" Device Chargeback ");
    sheetData.push(firstRow);
    if (this.dataSourceDetails.data.length > 0) {
      let uniqueEmployeeList = Array.from([new Set(this.chargebackDetailList[0].map((x: any) => x.soldBy))][0])
        .concat(Array.from([new Set(this.chargebackDetailList[1].map((x: any) => x.soldBy))][0]))
        .concat(Array.from([new Set(this.chargebackDetailList[2].map((x: any) => x.soldBy))][0]));
      uniqueEmployeeList = Array.from([new Set(uniqueEmployeeList)][0]);

      let noEmployeeRow = [];
      uniqueEmployeeList.forEach((employee) => {

        let deviceTotal = this.chargebackDetailList[0].filter((x: any) => x.chargeEmployee == employee).map((x: any) => x.chargebackAmountValue).reduce((a, b) => a + b, 0)
        let protectTotal = this.chargebackDetailList[1].filter((x: any) => x.chargeEmployee == employee).map((x: any) => x.chargebackAmountValue).reduce((a, b) => a + b, 0)
        let tradeTotal = this.chargebackDetailList[2].filter((x: any) => x.chargeEmployee == employee).map((x: any) => x.chargebackAmountValue).reduce((a, b) => a + b, 0)
        if (employee == "No Employee") {
          noEmployeeRow = [employee, (deviceTotal + protectTotal + tradeTotal), "", "", protectTotal, deviceTotal];
        } else {
          let employeeRow = [employee, (deviceTotal + protectTotal + tradeTotal), "", "", protectTotal, deviceTotal]
          sheetData.push(employeeRow);
        }
      })
      sheetData.push("");
      sheetData.push(noEmployeeRow);
    }
    let ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
    ws['!cols'] = [{ width: 35 }, { width: 15 }, { width: 15 }, { width: 15 }, { width: 15 }, { width: 15 }, { width: 15 }, { width: 15 }];
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'ChargebackSummary.xlsx');

  }

  selectedColumns = this.getDisplayedColumns();
  allAreSelected = false;

  getDisplayedColumns(): string[] {
    return this.columnDefinations.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
  }

  getColumnDefinationByMappedToProperty(mappedToProperty) {
    var column = this.columnDefinations.find(x => x.mappedToProperty == mappedToProperty);
    return column;
  }

  updateDataSource(data, details) {
    this.getEmployees();
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.data.forEach((x: any) => { x.isSelected = this.allAreSelected; });
    this.selectedImei = data[0];
    this.selectedItem = this.selectedImei;
    this.dataSource.paginator = this.paginator.toArray()[0];
    this.dataSource.sort = this.sort.toArray()[0];
    this.noData = this.dataSource.connect().pipe(map(data => data.length === 0));

    this.dataSourceDetails = new MatTableDataSource(details);
    this.dataSourceDetails.data.forEach((x: any) => { x.isSelected = this.allChargebacksAreSelected; });
    this.dataSourceDetails.paginator = this.paginator.toArray()[1];
    this.dataSourceDetails.sort = this.sort.toArray()[1];
    this._loaderService.hide();
    this.noData = this.dataSource.connect().pipe(map(data => data.length === 0));



    this.applyFilter();
  }


  selectItem(imei) {
    this.selectedItem = imei;
    if (this.rqVzDataTableComponent)
      this.rqVzDataTableComponent.updateSelectedImei(imei);
  }

  selectImei(imei) {
    imei.isSelected = !imei.isSelected;
    this.selectedImei = imei;
    this.allAreSelected = this.isAllSelected();
  }

  selectAll(e) {
    this.allAreSelected = e.checked;
    this.dataSource.filteredData.forEach((x: any) => {
      x.isSelected = this.allAreSelected;
    });

  }



  isAllSelected() {
    var notSelectedItems = this.dataSource.data.filter(function (item: any) {
      return item.isSelected === false;
    });
    return !(notSelectedItems != null && notSelectedItems.length > 0);
  }



  checkboxLabel(row): string {
    if (row != null) {
      return `${this.allAreSelected ? 'deselect' : 'select'} all`;
    }
    return "";
  }



  sendListOfImeis(decision: number) {
    this._loaderService.show();
    const thislist = this.dataSource.data.filter(function (item: any) {
      return item.isSelected === true;
    }).map(function (item: any) {
      return item.id;
    });
    this._commissionReconcileService.AddImeiListCommand(thislist, decision).subscribe((response: any) => {
      this.getCommissionReportUpdated.next();
      this.selectAll({ checked: false });
    });
  }


  getTextBydata(element, columnDef) {
    var textToShow = '';
    switch (columnDef.name) {
      case 'Notes':
        textToShow = element.commissionIMEINotes.length > 0 ? " (+" + (element.commissionIMEINotes.length) + " ...)" : "";
        break;
      default:
        textToShow = element[columnDef.mappedToProperty];
    }
    return textToShow;
  }

  chargebackDetailList: any[];
  setSummaryData(itemList: any[]) {
    this.chargebackDetailList = itemList;
  }

  selectedColumnsDetails = this.getDisplayedColumnsDetails();

  getDisplayedColumnsDetails(): string[] {
    return this.columnDefinationsDetails.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
  }

  getColumnDefinationByMappedToPropertyDetails(mappedToProperty) {
    var column = this.columnDefinationsDetails.find(x => x.mappedToProperty == mappedToProperty);
    return column;
  }


  getTextByDataDetails(element, columnDef) {
    var textToShow = '';
    switch (columnDef.name) {
      //this.dataSource.data.forEach((x: any) => { x.isSelected = this.allAreSelected; });
      case 'Notes':

        let parent: any = this.dataSource.data.filter((x: any) => x.id == element.commissionPostProcessedItemId);
        if (parent.length > 0) {
          let notes: any = parent[0].commissionIMEINotes;
          textToShow = notes.length > 0 ? " (+" + (notes.length) + " ...)" : "";
          break;
        }


      default:
        textToShow = element[columnDef.mappedToProperty];
    }
    return textToShow;
  }

  checkboxLabelChargeback(row): string {
    if (row != null) {
      return `${this.allChargebacksAreSelected ? 'deselect' : 'select'} all`;
    }
    return "";
  }
  applyFilter() {

    this.dataSource.filter = this.searchTerm.trim().toLowerCase();

    //if (this.searchTerm2.length > 0) {
    this.dataSourceDetails.filter = this.searchTerm2.trim().toLowerCase();
    //}

    if (this.chargebackDetailsIdFilter != 0) {
      this.dataSourceDetails.filterPredicate = this.filterPredicate;
      this.dataSourceDetails.filter = this.chargebackDetailsIdFilter.toString();
      this.selectAllChargebacks(true);

    }
  }

  showingDetails = false;
  chargebackDetailsIdFilter = 0;
  changeTableBeingShown(elementId: any = null) {
    if (elementId != null) {
      this.chargebackDetailsIdFilter = elementId;
      this.applyFilter();
      this.dataSourceDetails.filteredData.forEach((x: any) => { x.isSelected = false; });
      this.selectAllChargebacks(true);
    } else {
      this.applyFilter();
      this.dataSourceDetails.filteredData.forEach((x: any) => { x.isSelected = false; });
      this.allChargebacksAreSelected = this.isAllChargebacksSelected();
    }
    this.showingDetails = !this.showingDetails;
  }



  filterPredicate = (data: any): boolean => {
    if (
      (this.chargebackDetailsIdFilter > 0 &&
        data.commissionPostProcessedItemId == this.chargebackDetailsIdFilter)
    ) {
      return true;
    }
    return false;
  };

  returnToChargebackMain() {
    this.showingDetails = !this.showingDetails;
    this.chargebackDetailsIdFilter = 0;
    this.dataSourceDetails.filter = this.searchTerm2;
    this.dataSourceDetails.filterPredicate = this.defaultFilterPredicate;
  }

  isAllChargebacksSelected() {
    var notSelectedItems = this.dataSourceDetails.filteredData.filter(function (item: any) {
      return item.isSelected === false;
    });
    return !(notSelectedItems != null && notSelectedItems.length > 0);
  }

  selectAllChargebacks(e) {
    //this.allChargebacksAreSelected = e;
    this.dataSourceDetails.filteredData.forEach((x: any) => {

      //x.isSelected = this.allChargebacksAreSelected;
      this.selectChargeback(x);
    });

  }
  selectedChargeback = null;
  allChargebacksAreSelected = false;

  selectChargeback(chargeback) {

    chargeback.isSelected = !chargeback.isSelected;
    //this.selectedChargeback = chargeback;
    this.allChargebacksAreSelected = this.isAllChargebacksSelected();
  }

  sendListOfChargebacks() {
    this._loaderService.show();
    const thislist = this.dataSourceDetails.data.filter((item: any) => {
      return item.isSelected === true;
    }).map((item: any) => {
      return new ChargebackToUpdate(item, this.employeeList.find(x => x.user.name == item.chargeEmployee));
    });
    this._commissionReconcileService.updateChargebacks(thislist).subscribe((response: any) => {
      this.getCommissionReportUpdated.next();
      this.selectAllChargebacks(false);
    });
  }


  sortData() {
    let sortFunction =
      (items: any[], sort: MatSort): any[] => {
        if (!sort.active || sort.direction === '' || sort.active == 'symbol') {
          return items;
        }
        return items.sort((a: any, b: any) => {
          let comparatorResult = 0;
          switch (sort.active) {
            case 'isSelected':
              comparatorResult = (a[sort.active] === b[sort.active]) ? 0 : a[sort.active] ? -1 : 1;
              break;
            case 'commissionIMEINotes':
              comparatorResult = (a[sort.active].length - b[sort.active].length);
              break;
            case 'difference':
              comparatorResult = parseInt(a[sort.active].replace('$', '')) - parseInt(b[sort.active].replace('$', ''));
              break;
            default:
              if (a[sort.active] && b[sort.active]) {
                if (a[sort.active].localeCompare instanceof Function) {
                  comparatorResult = a[sort.active].localeCompare(b[sort.active]);
                }
              } else {
                comparatorResult = -1;
              }
              break;
          }
          return comparatorResult * (sort.direction == 'asc' ? 1 : -1);
        });
      };
    return sortFunction;
  }

  private dialogRef: any;
  selectedClientId: number;
  subscriptionToClients: Subscription;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  selectedClient: any;
  clients: any[] = [];
  regions: any[];

  openNotesDialog(column, element) {
    if (column == 'commissionIMEINotes') {
      element.notes = element.commissionIMEINotes;
      this.dialogRef = this.dialog.open(NotesDialogComponent,
        {
          autoFocus: true,
          disableClose: false,
          panelClass: 'notes-component',
          data: { element: element, selectedClientId: this.selectedClientId, pageOrigin: 'monthly-commision' }
        });
      this.dialogRef.afterClosed().subscribe(result => {
        if (result.update === true) {
          this.getCommissionReportUpdated.next(); this.selectAll({ checked: false });

        }
      });
    }
    else if (column == 'chargebackDetail') {
      let parent: any = this.dataSource.data.filter((x: any) => x.id == element.commissionPostProcessedItemId);
      if (parent.length > 0) {
        parent[0].notes = parent[0].commissionIMEINotes
      }

      this.dialogRef = this.dialog.open(NotesDialogComponent,
        {
          autoFocus: true,
          disableClose: false,
          panelClass: 'notes-component',
          data: { element: parent[0], selectedClientId: this.selectedClientId, pageOrigin: 'monthly-commision' }
        });
      this.dialogRef.afterClosed().subscribe(result => {
        if (result.update === true) {
          this.getCommissionReportUpdated.next(); this.selectAll({ checked: false });

        }
      });
    }
  }
  private searchDialogRef: any;
  openHistoryDialog(element) {
    this.searchDialogRef = this.searchDialog.open(ProductSearchDialogComponent,
      {
        autoFocus: true,
        disableClose: false,
        panelClass: 'product-search-component',
        data: { element: element }
      });
  }
}

class ChargebackToUpdate {
  constructor(chargeback, employee) {
    this.id = chargeback.id;
    this.employeeId = employee.id;
    this.value = chargeback.chargebackAmountValue;
  }
  id: number;
  employeeId: number;
  value: number;
}
