import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotifierService } from 'angular-notifier';

declare const _;

@Injectable({
  providedIn: 'root'
})
export class InvoiceViewerService {
  private _baseUrl = '/api/invoice';

  constructor(private http: HttpClient,
    private _notifier: NotifierService, ) { }

  getInvoiceReport(locationId: number, date: string): Observable<any> {
    const param = locationId + '@' + date;
    return this.http.get<any[]>(this._baseUrl + '/' + param);
  }

  getInvoiceReportForSingleUser( date: string, user: number): Observable<any> {
    const param = user + '@' + date;
    return this.http.get<any[]>(this._baseUrl + '/' + param + '/userOnly');
  }
}
