import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { ClientService } from '../services/client.service';
import { LocationService } from '../services/location.service';
import { CommissionUserService } from '../services/commission-user.service';
import { EmployeeService } from '../services/employee.service';
import { FormulaService } from '../services/formula.service';
import { NotifierService } from 'angular-notifier';
import { CommissionUser } from '../models/commission-user';
import { CommissionGroup } from '../models/commission-group';
import { CommissionFormula } from '../models/commission-formula';
import { CommissionPeg } from '../models/commission-peg';
import { Location } from '../models/location';
import { Employee } from '../models/employee';
import { Formula } from '../models/formula';
import { LocationTypeCode } from '../models/location-type-code';
import { Action } from '../models/action';
import * as moment from 'moment';
import { Subscription } from 'rxjs/internal/Subscription';
import { SettingService } from '../users/services/setting.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { CopyCommissionDialogComponent } from './copy-commission-dialog-component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from '../dialogs/confirm/confirm-dialog.component';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { DurationService } from '../services/duration.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

const CONFIG_CLIENT_KEY = 'ConfigClient';
const CONFIG_REGION_KEY = 'ConfigRegion';
const CONFIG_DISTRICT_KEY = 'ConfigDistrict';
const CONFIG_STORE_KEY = 'ConfigStore';
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-commission-user',
  templateUrl: './commission-user.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
})
export class CommissionUserComponent implements OnInit, OnDestroy {
  clientId: number;
  clients: any[] = [];
  selectedClientId: number;
  selectedClient: any;
  regions: any[] = [];
  selectedRegionId: number;
  selectedRegion: any;
  districts: any[] = [];
  selectedDistrictId: number;
  selectedDistrict: any;
  stores: any[] = [];
  selectedStoreId: number;
  selectedStore: any;
  selectedDuration: any;
  columnsVisible: number;
  employees: Employee[];
  locationEmployees: Employee[];
  formulas: Formula[];
  drawOpts: any[] = [];
  growths: any[] = [];
  selectedEmployeeId: number;
  copiedCommissionUserEmployeeId: number;
  filteredLocations: Location[] = [];
  subscriptionToClients: Subscription;
  subscriptionToRegions: Subscription;
  subscriptionToDistricts: Subscription;
  subscriptionToStores: Subscription;


  copiedCommissionUserDuration: string;
  commissionUser: CommissionUser;
  commissionUsers: CommissionUser[];
  commissionGroup: CommissionGroup;
  commissionFormula: CommissionFormula;
  commissionPeg: CommissionPeg;
  currentCommissionUserAction: Action;
  currentCommissionGroupAction: Action;
  currentCommissionFormulaAction: Action;
  currentCommissionPegAction: Action;
  localStorageLoadingInProgress: boolean;
  loadingInProgress: boolean;
  clientName: string;
  dialog: any = {
    isOpen: false,
    headerText: '',
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    }
  };

  copyDialog: any = {
    isOpen: false,
    headerText: '',
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    }
  };

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private _locationService: LocationService,
    private _commissionUserService: CommissionUserService,
    private _employeeService: EmployeeService,
    private _formulaService: FormulaService,    private _durationService: DurationService,
    private _notifier: NotifierService,
    private _authService: AuthService,
    private _clientService: ClientService,
    private _settingService: SettingService,
    private _matDialog: MatDialog) { }

  ngOnInit() {
    this.getClients();
    this.subscriptionToClients = this._locationService.currentActualClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        this.selectedClientId = message;
        this.onClientSelected();
      });

    this.subscriptionToRegions = this._locationService.currentRegion
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        this.selectedRegionId = message;
      });

    this.subscriptionToDistricts = this._locationService.currentDistrict
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        this.selectedDistrictId = message;
        if (this.selectedDistrictId) {
          this._locationService.getLocationsByTypeAndParents(4, (response) => { this.stores = response; }, [this.selectedDistrictId]);
          this.waitForElement();
        }
      });

    this.subscriptionToStores = this._locationService.currentStore
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        this.selectedStoreId = message;
        this.waitForElement();
      });

    this.selectedDuration = moment().format('YYYY-MM');
    this.copiedCommissionUserDuration = moment().format('YYYY-MM');
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
    this.dialogRef?.close();
  }

  isSubmitButtonDisabled(): boolean {
    return !(this.selectedClientId !== undefined && +this.selectedClientId !== 0 &&
      this.selectedRegionId !== undefined && +this.selectedRegionId !== 0 &&
      this.selectedDistrictId !== undefined && +this.selectedDistrictId !== 0);
  }

  getCommissionUsers(locations: number[], duration: string) {
    this.loadingInProgress = true;
    this._commissionUserService.getCommissionUsers(this.selectedClientId, duration, (response) => {
      this.loadingInProgress = false;
      this.commissionUsers = response;
      this.updateDataSource(this.commissionUsers);
    }, locations);
  }

  getDrawOpts() {
    this._commissionUserService.getDrawOpts()
      .subscribe((response: any[]) => {
        this.drawOpts = response;
      }, (error) => this._notifier.notify('error', error.message), () => { });
  }

  getFormulas() {
    this._formulaService.getFormulas(this.selectedClientId, (response) => {
      this.formulas = response;
      this.formulas = this.formulas.filter(x => x.containsComplexFormula === false);
    }, true);
  }

  getFormulaGrowthList() {
    this._commissionUserService.getFormulaGrowths()
      .subscribe((response: any[]) => {
        this.growths = response;
      }, (error) => this._notifier.notify('error', error.message), () => { });
  }

  getClients() {
    this._locationService.getLocations(LocationTypeCode.Client, (response) => {
      this.clients = response;
    });
  }

  onClientSelected() {
    if (!this.selectedClientId || +this.selectedClientId === 0) {
      return;
    }
    this.commissionUsers = [];
    this.filteredLocations = [];
    this.getDrawOpts();
    this.getFormulas();
    this.getFormulaGrowthList();
    // this.selectedClient = this.clients.find(r => r.id === +this.selectedClientId);
    // this.clientId = this.selectedClient.clientId;
    // this.clientId = this.selectedClientId;

    if (this._authService.isSuperAdmin()) {
      this._locationService.getAllLocations().subscribe((response) => {
        this.filteredLocations = response.filter(x => x.clientId === this.selectedClientId);
      });
    } else {
      this._settingService.getLocationsForReport(this._authService.userId(), 9).subscribe((response) => {
        this.filteredLocations = response;
      });
    }
  }

  getEmployees(location?: number, locations?: number[]) {

    if (locations) {
      this._employeeService.getEmployeesByLocations(locations)
        .subscribe((response: Employee[]) => {
          this.locationEmployees = response;
          this.employees = response;
        }, (error) => this._notifier.notify('error', error.message), () => { });
    } else {
      this._employeeService.getEmployeesByLocationCallback((response) => {
        this.locationEmployees = response;
        this.employees = response;
      }, location);

      /*this._employeeService.getEmployeesByLocation(location)
        .subscribe((response: Employee[]) => {
          this.locationEmployees = response;
        }, (error) => this._notifier.notify('error', error.message), () => { });
        */
    }
  }

  addUser() {
    this.selectedEmployeeId = this.locationEmployees[0].id;
    this.commissionUser = { name: null, duration: moment().format('YYYY-MM'), commissionGroups: [] };
    this.commissionGroup = { name: null, basePercentage: 0, drawOpt: 'None', target: null, commissionFormulas: [] };
    this.commissionFormula = { growth: null, commissionPegs: [] };
    this.commissionPeg = { pay: null, goal: null };

    this.dialog.isOpen = true;
    this.dialog.headerText = 'Add Commission User';
    this.columnsVisible = 1;
    this.currentCommissionUserAction = Action.Create;
  }

  addGroup() {
    this.columnsVisible = 2;
    this.currentCommissionGroupAction = Action.Create;
    this.commissionGroup = { name: null, basePercentage: 0, drawOpt: 'None', target: null, commissionFormulas: [] };
    this.commissionFormula = { growth: null, commissionPegs: [] };
    this.commissionPeg = { pay: null, goal: null };
  }

  addFormula() {
    this.columnsVisible = 3;
    this.currentCommissionFormulaAction = Action.Create;
    this.commissionFormula = { growth: null, commissionPegs: [] };
  }

  addPeg() {
    this.columnsVisible = 4;
    this.currentCommissionPegAction = Action.Create;
    this.commissionPeg = { pay: null, goal: null };
  }

  saveUser() {
    if (this.commissionUser.commissionGroups.length === 0) {
      this._notifier.notify('warning', 'Please add commission groups');
      return;
    }

    switch (this.currentCommissionUserAction) {
      case Action.Create:
        const employee = this.locationEmployees.find(e => e.id === +this.selectedEmployeeId);
        this.commissionUser.name = employee.user.name;
        this.commissionUser.employeeId = employee.id;
        this.commissionUser.locationId = employee.locationId;
        const user = this.commissionUser;

        if (this._commissionUserService.isRecordAlreadyExists(user.duration, user.name)) {
          this._notifier.notify('warning', 'Commission user already exists.');
          return;
        }

        this._commissionUserService.addCommissionUser(user)
          .subscribe(() => {
            this._commissionUserService.updateCommissionUsersCollection(this.selectedClientId, this.selectedDuration, () => {
              if (+this.selectedStoreId === 0) {
                this.getCommissionUsers(this.stores.map(x => x.id), this.selectedDuration ? this.selectedDuration : null);
              } else {
                this.getCommissionUsers([+this.selectedStoreId], this.selectedDuration ? this.selectedDuration : null);
              }
              this._notifier.notify('success', 'Commission User saved successfully');
              this.dialog.isOpen = false;
            });
          }, (error) => this._notifier.notify('error', error.message), () => { });
        break;
      case Action.Update:
        this._commissionUserService.updateCommissionUser(this.commissionUser)
          .subscribe(() => {
            this._commissionUserService.updateCommissionUsersCollection(this.selectedClientId, this.selectedDuration, () => {
              if (+this.selectedStoreId === 0) {
                this.getCommissionUsers(this.stores.map(x => x.id), this.selectedDuration ? this.selectedDuration : null);
              } else {
                this.getCommissionUsers([+this.selectedStoreId], this.selectedDuration ? this.selectedDuration : null);
              }
              this._notifier.notify('success', 'Commission User saved successfully');
              this.dialog.isOpen = false;
            });
          }, (error) => this._notifier.notify('error', error.message), () => { });
        break;
    }
  }

  saveGroup() {
    if (this.commissionGroup.commissionFormulas.length === 0) {
      this._notifier.notify('warning', 'Please add commission formulas');
      return;
    }

    switch (this.currentCommissionGroupAction) {
      case Action.Create:
        this.commissionUser.commissionGroups.push(this.commissionGroup);
        break;
      case Action.Update:
        const index = this.commissionUser.commissionGroups.findIndex(user => user.id === this.commissionGroup.id);
        this.commissionUser.commissionGroups[index] = this.commissionGroup;
        break;
    }

    this.columnsVisible = 1;
  }

  saveFormula() {
    if (this.commissionFormula.commissionPegs.length === 0) {
      this._notifier.notify('warning', 'Please add commission pegs');
      return;
    }

    const selectedFormulaId = this.commissionFormula.formulaId;
    this.commissionFormula.formula = this.formulas.filter(formula => formula.id === +selectedFormulaId)[0];

    switch (this.currentCommissionFormulaAction) {
      case Action.Create:
        this.commissionGroup.commissionFormulas.push(this.commissionFormula);
        break;
      case Action.Update:
        const index = this.commissionGroup.commissionFormulas.findIndex(formula => formula.id === this.commissionFormula.id);
        this.commissionGroup.commissionFormulas[index] = this.commissionFormula;
        break;
    }

    this.columnsVisible = 2;
  }

  savePeg() {
    switch (this.currentCommissionPegAction) {
      case Action.Create:
        this.commissionFormula.commissionPegs.push(this.commissionPeg);
        break;
      case Action.Update:
        //const index = this.commissionFormula.commissionPegs.findIndex(peg => peg.id === this.commissionPeg.id);
        //this.commissionFormula.commissionPegs[index] = this.commissionPeg;
        break;
    }

    this.columnsVisible = 3;
  }

  async waitForElement() {
    if (typeof this.filteredLocations !== "undefined" && this.filteredLocations.length > 0) {
      //variable exists, do what you want
      //console.log("Waiting over");
      this.searchUsers();
    }
    else {
      //console.log("Waiting");
      await this.wait(100);
      this.waitForElement()
    }
  }

  wait(ms) {
    return new Promise((resolve, reject) => setTimeout(resolve, ms));
  }

  searchUsers() {

    // this.getFormulas();
    let locationsToSend: Location[] = [];
    if (this.selectedRegionId === 0) {
      locationsToSend = this.filteredLocations.filter(x => x.locationTypeCodeId === LocationTypeCode.Store);
    } else if (this.selectedDistrictId === 0) {
      const districts: number[] = this.filteredLocations.filter(x => x.parentId === this.selectedRegionId).map(x => x.id);
      locationsToSend = this.filteredLocations.filter(x => x.locationTypeCodeId === LocationTypeCode.Store &&
        districts.includes(x.parentId));
    } else if (this.selectedStoreId === 0) {
      locationsToSend = this.filteredLocations.filter(x => x.locationTypeCodeId === LocationTypeCode.Store &&
        x.parentId === this.selectedDistrictId);
    } else if (+this.selectedStoreId) {
      this.getCommissionUsers([+this.selectedStoreId], this.selectedDuration ? this.selectedDuration : null);
      this.getEmployees(+this.selectedStoreId);
    }
    if (locationsToSend.length > 0) {
      // this._locationService.getLocations(LocationTypeCode.Store, (locationResponse) => {
      // const locations = locationResponse.map(function (location) { return location.id; });
      this.getEmployees(undefined, locationsToSend.map(x => x.id));
      this.getCommissionUsers(locationsToSend.map(x => x.id), this.selectedDuration ? this.selectedDuration : null);
      // }, +this.selectedDistrictId);
    }
  }

  selectUser(user: CommissionUser) {
    this.commissionUser = user;
    this.selectedEmployeeId = this.commissionUser.employeeId;
    this.currentCommissionUserAction = Action.Update;
    this.dialog.isOpen = true;
    this.dialog.headerText = 'Edit Commission User';
    this.columnsVisible = 1;
    // this.getEmployees(+this.commissionUser.locationId);
  }

  selectGroup(group: CommissionGroup) {
    // if (this.stores.length === 0) {
    //  this._notifier.notify('warning', 'Please select Region & District first.');
    //  return;
    // }

    this.commissionGroup = group;
    this.columnsVisible = 2;
    this.currentCommissionGroupAction = Action.Update;
  }

  selectFormula(commissionFormula: CommissionFormula) {
    this.commissionFormula = commissionFormula;
    this.commissionFormula.formulaId = +this.commissionFormula.formulaId;
    this.currentCommissionFormulaAction = Action.Update;
    this.columnsVisible = 3;
  }

  selectPeg(peg: CommissionPeg) {
    this.commissionPeg = peg;
    this.currentCommissionPegAction = Action.Update;
    this.columnsVisible = 4;
  }

  cancelUser() {
    this.dialog.isOpen = false;
  }

  cancelGroup() {
    this.columnsVisible = 1;
  }

  cancelFormula() {
    this.columnsVisible = 2;
  }

  cancelPeg() {
    this.columnsVisible = 3;
  }



  deleteCommissionUser(event: Event, id: number) {
    event.stopPropagation(), event.preventDefault();
    this.dialogRef = this._matDialog.open(ConfirmDialogComponent,
      {
        autoFocus: true, disableClose: false, panelClass: 'copy-comm-component', data: { title: 'Confirm', message: "Are you sure you want to delete this user's commission?" }
      });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this._commissionUserService.deleteCommissionUser(id).subscribe((response) => {
          if (response > 0) {
            this._notifier.notify('success', 'Commission for user deleted successfully.');
            this.searchUsers();
          } else {
            this._notifier.notify('error', "User commission you attempted to delete was not found.");
          }
        }, (error) => this._notifier.notify('error', error.message), () => { });
      }
    });
  }

  deleteGroup(event: Event, group: CommissionGroup) {
    event.stopPropagation(), event.preventDefault();

    const index = this.commissionUser.commissionGroups.findIndex(g => g.id === group.id);
    if (index > -1) {
      this.commissionUser.commissionGroups.splice(index, 1);
    }
  }

  deleteFormula(event: Event, formula: CommissionFormula) {
    event.stopPropagation(), event.preventDefault();

    const index = this.commissionGroup.commissionFormulas.findIndex(f => f.id === formula.id);
    if (index > -1) {
      this.commissionGroup.commissionFormulas.splice(index, 1);
    }
  }

  deletePeg(event: Event, peg: CommissionPeg) {
    event.stopPropagation(), event.preventDefault();

    const index = this.commissionFormula.commissionPegs.findIndex(p => p.id === peg.id);
    if (index > -1) {
      this.commissionFormula.commissionPegs.splice(index, 1);
    }
  }

  copyUser(event: Event, user: CommissionUser) {
    event.stopPropagation(), event.preventDefault();
    this.commissionUser = user;
    this.copyDialog.isOpen = true;
    this.copyDialog.headerText = 'Copy Commission User';
    if (+this.selectedStoreId === 0) {
      // this.getEmployees(undefined, this.stores.map(x => x.id));
    } else {
      // this.getEmployees(+this.selectedStoreId);
    }
  }



  getDuration(duration) {
    return moment(duration + '-01').format('MMMM, YYYY');
  }

  getLocation(locationId: number) {
    return this._locationService.getLocationNameById(locationId);
  }

  private dialogRef: any;

  openCopyCommissionDialog(event: Event, element) {
    event.stopPropagation(), event.preventDefault();
    this.dialogRef = this._matDialog.open(CopyCommissionDialogComponent,
      {
        autoFocus: true,
        disableClose: false,
        panelClass: 'copy-comm-component',
        data: {
          commissionUser: element, locationEmployees: this.locationEmployees, selectedClientId: this.selectedClientId,
          copiedCommissionUserEmployeeId: this.copiedCommissionUserEmployeeId, copiedCommissionUserDuration: this.copiedCommissionUserDuration,
          selectedStoreId: this.selectedStoreId, stores: this.stores
        }
      });
    this.dialogRef.afterClosed().subscribe(result => {
      //console.log("afterClosed", result);
      if (result.update === true) {
        this.searchUsers();
      }
    });

  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource();
  columnDefinations = [
    { name: "Name", isSortable: true, mappedToProperty: "name", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Store", isSortable: true, mappedToProperty: "locationId", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Month/Year", isSortable: true, mappedToProperty: "duration", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Commission Groups", isSortable: true, mappedToProperty: "commissionGroups", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Actions", isSortable: true, mappedToProperty: "actions", class: "", isFilterable: true, sticky: true, hide: false },

  ];
  getDisplayedColumns(): string[] {
    return this.columnDefinations.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
  }
  getColumnDefinationByMappedToProperty(mappedToProperty) {
    var column = this.columnDefinations.find(x => x.mappedToProperty == mappedToProperty);
    return column;
  }
  updateDataSource(data: any) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  monthYear:any;
  date = new FormControl(moment());
  yearHandler(year: moment.Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(year.year());
    this.date.setValue(ctrlValue);
  }

  monthHandler(month: any, datepicker: MatDatepicker<moment.Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(month.month());
    ctrlValue.year(month.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
    let tempDate = new Date(month.startOf("month").format(this._durationService.dateStartFormat).toString());
    let monthNumber = tempDate.getMonth() + 1;
    this.monthYear = tempDate.getFullYear()+'-' + (monthNumber<10?'0'+monthNumber:monthNumber)  ;
    this.selectedDuration = this.monthYear;
    this.searchUsers();
  }
}
