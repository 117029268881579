import { ConfigFunctionsCalledInTable } from './config-function-sheet';
import { ConfigVariable } from './config-variable';

export class ConfigFunction {
    id?: number;
    name?: string;
    configFunctionsCalledInTable?: ConfigFunctionsCalledInTable[];
  configVariables?: ConfigVariable[];
  configUploadProfileTypeId?: number;
}
