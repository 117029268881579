<div class="col-md-12 bg-white p-3 mb-2">
  <div class="row">
    <div class="col-md-2">
      <mat-form-field style="" class="searchtab">
        <mat-label>Month and Year</mat-label>
        <input matInput [matDatepicker]="datepicker" [formControl]="date">
        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker startView="year" (yearSelected)="yearHandler($event)" (monthSelected)="monthHandler($event, datepicker)"></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-md-2">
      <mat-form-field style="" class="searchtab">
        <mat-select placeholder="" [(value)]="selectedRadio" (selectionChange)="changeRebateType();resetFilter(); ">
          <mat-option [value]="1">Device Commissions</mat-option>
          <mat-option [value]="2">Mobile Protection</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-2">  <mat-checkbox [(ngModel)]="pageObject.isDataLocked" [checked]="pageObject.isDataLocked" (change)="finalizeData()">
      <div class="text-wrap">
        Lock Data Imports and Finalize Chargebacks
      </div></mat-checkbox></div>
      <div class="col-md-2" *ngIf="doneLoading">
        RQ Expected<span class="setrq"> ${{imeiList.stats.rqExpected.toFixed(2)}}</span><br />
        Rebates Collected<span class="setrq"> ${{(imeiList.stats.rebatesCollected).toFixed(2)}}</span><br />
        Difference<span class="setrq"> ${{(imeiList.stats.difference).toFixed(2)}}</span><br />
      </div>
      <div class="col-md-2" *ngIf="doneLoading">
        Devices Expected<span class="setrq"> ${{(imeiList.stats.devicesExpected).toFixed(2)}}</span><br />
        Devices Collected<span class="setrq"> ${{(imeiList.stats.devicesReceived).toFixed(2)}}</span><br />
        Protection Expected<span class="setrq"> ${{(imeiList.stats.protectionExpected).toFixed(2)}}</span><br />
        Protection Collected<span class="setrq"> ${{(imeiList.stats.protectionReceived).toFixed(2)}}</span><br />

      </div>
      <div class="col-md-2" *ngIf="doneLoading">
        Dismissed<span class="setrq"> ${{(imeiList.stats.dismissed).toFixed(2)}}</span><br />
        ChargedBack<span class="setrq"> ${{(imeiList.stats.chargedBack).toFixed(2)}}</span><br />
        Discrepancy<span class="setrq"> ${{(imeiList.stats.discrepency).toFixed(2)}}</span><br />
        Discrepancy All Time<span class="setrq"> ${{(imeiList.stats.discrepencyAllTime).toFixed(2)}}</span><br />
      </div>
    </div>
  </div>
<div class="">
  <mat-tab-group mat-stretch-tabs #tabGroup mat-align-tabs="start"  (selectedTabChange)="tabClick($event)" [(selectedIndex)]="selectedTabIndex" backgroundColor="primary">
    <mat-tab id="tab0" label="All">
      <div >
        <all-data-table [tableData]="" (updateSelectedImei)="updateSelectedImei($event)" (getCommissionReportUpdated)="getCommissionReport()"></all-data-table>
      </div>
    </mat-tab>
    <mat-tab id="tab2" label="Dismissed">
      <div >
        <dismissed-data-table [tableData]="" (updateSelectedImei)="updateSelectedImei($event)" (getCommissionReportUpdated)="getCommissionReport()"></dismissed-data-table>
      </div>
    </mat-tab>
    <mat-tab id="tab3" label="Chargebacks">
      <div >
        <chargebacks-data-table [tableData]="" (updateSelectedImei)="updateSelectedImei($event)" (getCommissionReportUpdated)="getCommissionReport()"></chargebacks-data-table>
      </div>
    </mat-tab>
    <mat-tab id="tab4" label="Discrepancies">
      <div >
        <discrepancies-data-table [tableData]="" (updateSelectedImei)="updateSelectedImei($event)" (getCommissionReportUpdated)="getCommissionReport()"></discrepancies-data-table>
      </div>
    </mat-tab>
    <mat-tab id="tab5" label="Past Discrepancies">
      <div >
        <discrepancies-all-data-table [tableData]="" (updateSelectedImei)="updateSelectedImei($event)" (getCommissionReportUpdated)="getCommissionReport()"></discrepancies-all-data-table>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
