import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgChartsModule } from 'ng2-charts';
import { UserModule } from './users/user.module';
import { CoreModule } from '../core/core.module';
import { ClientService } from './services/client.service';
import { ImportService } from './services/import.service';
import { SkuGroupService } from './services/sku-group.service';
import { MaterialModule } from './material.module';
//import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb';
import { NotesDialogComponent } from './dialogs/notes/notes-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CopyCommissionDialogComponent } from './commission-user/copy-commission-dialog-component';
import { ErrorComponent } from './shared/error/error.component';
import { ConfirmDialogComponent } from './dialogs/confirm/confirm-dialog.component';
import { FinalizeDialogComponent } from './rewards-create/finalize-dialog.component';
import { FinalizeClaimComponent } from "./rewards-claim-management/finalize-claim.component";
import { DeleteClaimComponent } from "./rewards-claim-management/delete-claim.component";
import { ManualRewardComponent } from "./rewards-claim-management/manual-reward.component";
import { RewardsCreateDialogComponent } from "./rewards-create/rewards-create-dialog.component";
import { FormulaCreateDialogComponent } from "./formula/formula-create-dialog.component";
import { FormulaViewGroupCreateComponent } from './formula-view-group/formula-view-group-create-dialog.component';
import { DeleteFormulaViewGroupComponent } from "./formula-view-group/delete-formula-view-group.component";
import { SkuGroupCreateComponent } from "./sku-group/sku-group-create.component";
import { SkuFilterCreateComponent } from "./sku-group/sku-filter-create.component";
import { ColumnCreateDialogComponent } from "./rank-contest/column-create.component";
import { GroupCreateDialogComponent } from './rank-contest/group-create.component';
import { CreateRoleDialogComponent } from './users/role-manager/create-role.component';
import { ReportCreateDialogComponent } from "./rank-contest/report-create.component";
import { ClientCreateDialogComponent } from "./client/client-create-dialog.component";
import { UserCreateDialogComponent } from './users/user-lookup/user-create-dialog.component';

@NgModule(({
  imports: [
    CommonModule,
    FormsModule,
    CoreModule,
    HttpClientModule,
    NgxFileDropModule,
    HomeRoutingModule,
    MaterialModule,
    UserModule,
    NgChartsModule,
    ReactiveFormsModule,
    //NgDynamicBreadcrumbModule,
    FlexLayoutModule
  ],
  declarations: [
    HomeRoutingModule.components,
    NotesDialogComponent,
    ConfirmDialogComponent,
    FinalizeDialogComponent,
    FinalizeClaimComponent,
    DeleteClaimComponent,
    ManualRewardComponent,
    RewardsCreateDialogComponent,
    FormulaCreateDialogComponent,
    ErrorComponent,
    CopyCommissionDialogComponent,
    FormulaViewGroupCreateComponent,
    DeleteFormulaViewGroupComponent,
    SkuGroupCreateComponent,
    SkuFilterCreateComponent,
    ColumnCreateDialogComponent,
    GroupCreateDialogComponent,
    CreateRoleDialogComponent,
    ReportCreateDialogComponent,
    ClientCreateDialogComponent,
    UserCreateDialogComponent
  ],
  providers: [
    ClientService,
    ImportService,
    SkuGroupService,
    DatePipe
  ],
  entryComponents: [
    NotesDialogComponent,
    ConfirmDialogComponent,
    FinalizeDialogComponent,
    CopyCommissionDialogComponent,
    FinalizeClaimComponent,
    DeleteClaimComponent,
    ManualRewardComponent,
    RewardsCreateDialogComponent,
    FormulaCreateDialogComponent,
    FormulaViewGroupCreateComponent,
    DeleteFormulaViewGroupComponent,
    SkuGroupCreateComponent,
    SkuFilterCreateComponent,
    ColumnCreateDialogComponent,
    GroupCreateDialogComponent,
    CreateRoleDialogComponent,
    ReportCreateDialogComponent,
    ClientCreateDialogComponent,
    UserCreateDialogComponent
  ],

}) as any)
export class HomeModule { }
