<button mat-icon-button class="close-button" (click)="onDismiss()">
  <mat-icon class="close-icon" >close</mat-icon>
</button>
<h1 mat-dialog-title class="heading-text">{{title}}</h1>
<form #f="ngForm" novalidate>
  <div class="d-flex aling-items-center">
    <div class="custom-input-box create-form-group-width-set">
      <input matInput type="text" [(ngModel)]="newTableGroup.shortTitle" #name="ngModel" name="name" placeholder="ShortTitle" />
    </div>
    <div class="custom-input-box create-form-group-width-set ml-3">
      <mat-select placeholder="Select Formula" [(ngModel)]="newTableGroup.rankingFormulaId" #formulaId="ngModel" name="formulaId">
        <mat-option *ngFor="let formula of formulas" [value]="formula.id">{{formula.name}}</mat-option>
      </mat-select>
    </div>

  </div>
  <div class="d-flex align-items-center mt-4">
    <div class="custom-input-box create-form-group-width-set">
      <input matInput type="text" [(ngModel)]="newTableGroup.longTitle" #longTitle="ngModel" name="longTitle" placeholder="LongTitle" />
    </div>
    <div class="custom-input-box create-form-group-width-set ml-3">
      <input matInput type="number" [(ngModel)]="newTableGroup.rankingPercentage" #rankingPercentage="ngModel" name="rankingPercentage" placeholder="RankValue(%)" />
    </div>
  </div>
  <div class="mt-4">
    <div class="custom-textarea-box w-100">
      <textarea matInput type="text" [(ngModel)]="newTableGroup.description" #description="ngModel" name="description" placeholder="Description"></textarea>
    </div>
  </div>



</form>

<div class="d-flex align-items-center mt-4 pt-1">
  <button mat-raised-button color="primary" [disabled]="" class="submit-btn" (click)="onConfirm()">Submit</button>
  <button mat-button class="clear-btn ml-3" (click)="onDismiss()">Clear</button>
</div>
