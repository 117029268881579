import { Employee } from './employee';
import { Location } from './location';
import { SkuGroup } from './sku-group';

export class Seller {

  id: number;
  name: string;

  isLocation?: boolean;
  location?: Location;
  locationId?: number;
  employee?: Employee;
  employeeId?: number;

  skuGroups?: SkuGroup[];
  goals?: any[];
  formulas?: any[];



}
