import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(public loaderService: LoaderService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    ////this.loaderService.show();
    return next.handle(request).pipe((event => {
      if (event instanceof HttpResponse) {
        this.loaderService.hide();
      }
      return event;
    }));


    //  this.loaderService.show();
    //return next.handle(request)
    //  .pipe(finalize(() => this.loaderService.hide())
    //  );
  }
}
