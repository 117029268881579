
export class BillPaymentItemViewModel {
  mtn: string;
  Date: Date;
  RQCollected: number;
  DSCharged: number;
  Difference: number;
  User: string;
  LocationName: string;
  TransactionType: string;
  CtrlNum: string;
}

export class BillPaymentViewModel {
  Items: BillPaymentItemViewModel[] = [];
  TotalCharged: number;
  TotalCollected: number;
}
