import { Formula } from './formula';
import { Employee } from './employee';
import { Location } from './location';
export class Dashboard {
  id?: number;
  name?: string;
  skuGroupOrFormula: boolean;
  graphTypeId: number;
  dateTypeId: number;
  locationTierFocusId: number;

  defaultHideTier?: number;
  dashChart?: any;
  formulaId?: number;
  skuGroupId?: number;
  locations?: Location[];
  employees?: Employee[];
  dateDisplay?: string;
  end?: string;
  start?: string;
  formulaName?: string;
  locationForChart?: any[];
  targetFormula?: Formula;
  parentGroups?: any[];
  listOfChartParents?: Location[];
  locationForChartView?: any[];
  originalDataForChart?: any;
  currentDataForChart?: any;
  showonchartlocations?: Location[];
  sortCode?: number;
  position?: string;
  monthList?: string[];
}
