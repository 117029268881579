import { Inject, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'create-role',
  templateUrl: 'create-role.component.html',
})
export class CreateRoleDialogComponent implements OnInit {

  title: string;
  mainRolesList: any;
  myRole: any;newRole:any;
 
  copyFromRoleId:any;
  constructor(public dialogRef: MatDialogRef<CreateRoleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.mainRolesList = data.mainRolesList;
    this.myRole = data.myRole;
    this.newRole = data.newRole;
    this.copyFromRoleId = data.copyFromRoleId;
  }

  ngOnInit() { }

  onConfirm(): void { this.dialogRef.close({newRole:this.newRole,copyFromRoleId:this.copyFromRoleId}); }

  onDismiss(): void { this.dialogRef.close(false); }
}

