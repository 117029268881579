import { AfterViewInit, Component, Input, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: 'rq-vz-data-table',
  templateUrl: './rq-vz-data-table.component.html',
  providers: []
})

export class RqVzDataTableComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor() { }

  @Input()
  selectedImei: any;
  moneySymbol = '$';

  rqColumnDefinations = [
    { name: "Model", isSortable: true, mappedToProperty: "model", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Total Rebate", isSortable: true, mappedToProperty: "totalRebate", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Relevant Invoice", isSortable: true, mappedToProperty: "relevantInvoice", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Item", isSortable: true, mappedToProperty: "item", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Date", isSortable: true, mappedToProperty: "date", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Contract", isSortable: true, mappedToProperty: "contract", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Tracking Number", isSortable: true, mappedToProperty: "trackingNumber", class: "", isFilterable: true, sticky: false, hide: false },
  ];

  verizonColumnDefinations = [
    { name: "Model", isSortable: true, mappedToProperty: "model", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Mtn", isSortable: true, mappedToProperty: "mtn", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Imei", isSortable: true, mappedToProperty: "imei", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "DP", isSortable: true, mappedToProperty: "dp", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "DPSF", isSortable: true, mappedToProperty: "dpaServiceFee", class: "", isFilterable: true, sticky: false, hide: true },
    { name: "Comm", isSortable: true, mappedToProperty: "comm", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Start", isSortable: true, mappedToProperty: "start", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "End", isSortable: true, mappedToProperty: "end", class: "", isFilterable: true, sticky: false, hide: false },
  ];
  getVerizonDisplayedColumns(): string[] {
    return this.verizonColumnDefinations.filter(cd => !cd.hide).map(cd => cd.mappedToProperty); 8
  }


  getVerizonColumnDefinationByMappedToProperty(mappedToProperty) {
    var column = this.verizonColumnDefinations.find(x => x.mappedToProperty == mappedToProperty);
    return column;
  }

  getRqDisplayedColumns(): string[] {
    return this.rqColumnDefinations.filter(cd => !cd.hide).map(cd => cd.mappedToProperty); 8
  }

  getRqColumnDefinationByMappedToProperty(mappedToProperty) {
    var column = this.rqColumnDefinations.find(x => x.mappedToProperty == mappedToProperty);
    return column;
  }
  selectedVerizonColumns = this.getVerizonDisplayedColumns();
  selectedRqColumns = this.getRqDisplayedColumns();
  getTextBydata(element, columnDef) {
    var textToShow = '';

    switch (columnDef.name) {
      case 'Contract':
        textToShow = element[columnDef.mappedToProperty];
        break;
      case 'Difference':
      case 'VZ$':
      case 'RQ$':
      case 'Total Rebate':
      case 'DP':
      case 'DPSF':
      case 'Comm':
        textToShow = (element[columnDef.mappedToProperty]);
        //textToShow = (element[columnDef.mappedToProperty]).toFixed(2);
        //textToShow = this.moneySymbol + textToShow;
        break;
      case 'Relevant Invoice':
        textToShow = (element[columnDef.mappedToProperty]);
        //textToShow = (element[columnDef.mappedToProperty]['invoiceNumber']);
        break;
      case 'Date':
      case 'Start':
      case 'End':
        textToShow = (element[columnDef.mappedToProperty]);
        //let dt = new Date(element[columnDef.mappedToProperty]);
        //textToShow = (dt.getMonth() + 1) +
        //  "/" + dt.getDate() +
        //  "/" + dt.getFullYear();
        break;
      case 'Model':
      case 'item':
        textToShow = element[columnDef.mappedToProperty].substr(0, 30);
        break;
      case 'Notes':
        textToShow = element[columnDef.mappedToProperty].length;
        break;
      default:
        textToShow = element[columnDef.mappedToProperty];
        break;
    }
    return textToShow;
  }
  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

  }

  ngOnInit(): void {

  }
  updateSelectedImei(imei) {
    this.selectedImei = imei;
  }

}
