<style></style>
<div class="col-md-12 p-0 bg-white">
  <div class="row mb-3 p-3">
  
    <div class="col-md-2 m-au">
      <mat-radio-group style="" [(ngModel)]="pageObject.isClaimSelected" #isClaimSelected="ngModel" name="isClaimSelected" (change)="switchBetweenClaimsAndRewards()">
        <mat-radio-button [value]="true">Claims</mat-radio-button>
        <mat-radio-button style="margin-left:25px" [value]="false">EarnedRewards</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="col-md-2 m-au">
      <mat-checkbox *ngIf="pageObject.isClaimSelected" [(ngModel)]="filterMenuClaims.isComplete" (change)="applySelectedFilter()">IsCompleted</mat-checkbox>
      <mat-checkbox *ngIf="!pageObject.isClaimSelected" [(ngModel)]="filterMenuRewards.isClaimed" (change)="applySelectedFilter()">IsClaimed</mat-checkbox>

    </div>
    <div class="col-md-2">
      <mat-form-field *ngIf="pageObject.isClaimSelected"  class="searchtab">
        <mat-select placeholder="Created Date" [(value)]="filterMenuClaims.duration" name="durationFilter" (selectionChange)="applySelectedFilter()">
          <mat-option [value]="-1">All Time</mat-option>
          <mat-option *ngFor="let duration of durations" [value]="duration.value">{{duration.viewValue}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="!pageObject.isClaimSelected" class="searchtab">
        <mat-select placeholder="Created Date" [(value)]="filterMenuRewards.duration" name="durationFilter" (selectionChange)="applySelectedFilter()">
          <mat-option [value]="-1">All Time</mat-option>
          <mat-option *ngFor="let duration of durations" [value]="duration.value">{{duration.viewValue}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
   
    

  </div>
</div>
<div class="col-md-12 p-0 bg-white">
  <div class="row mb-3 p-3">
    <div class="col-md-2">
      <mat-form-field class="searchtab">
        <input matInput (blur)="applyFilter($event.target.value)" (keyup)="applyFilter($event.target.value)" placeholder="Search" [(ngModel)]="pageObject.searchTerm">
      </mat-form-field>
    </div>
    <div class="col-md-2">

      <mat-form-field *ngIf="pageObject.isClaimSelected" class="searchtab">
        <mat-select placeholder="Select columns" multiple [(value)]="selectedColumnsClaims" (selectionChange)="columnsChangedClaims($event)">
          <mat-select-trigger>
            {{selectedColumnsClaims ? getColumnDefinationByMappedToPropertyClaims(selectedColumnsClaims[0]).name : ''}}
            <span *ngIf="selectedColumnsClaims?.length > 1" class="example-additional-selection">(+{{selectedColumnsClaims.length - 1}} {{selectedColumnsClaims?.length === 2 ? 'other' : 'others'}})</span>
          </mat-select-trigger>
          <mat-option *ngFor="let column of columnDefinationsClaims" [value]="column.mappedToProperty">{{column.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="!pageObject.isClaimSelected" class="searchtab">
        <mat-select placeholder="Select columns" multiple [(value)]="selectedColumnsRewards" (selectionChange)="columnsChangedRewards($event)">
          <mat-select-trigger>
            {{selectedColumnsRewards ? getColumnDefinationByMappedToPropertyRewards(selectedColumnsRewards[0]).name : ''}}
            <span *ngIf="selectedColumnsRewards?.length > 1" class="example-additional-selection">(+{{selectedColumnsRewards.length - 1}} {{selectedColumnsRewards?.length === 2 ? 'other' : 'others'}})</span>
          </mat-select-trigger>
          <mat-option *ngFor="let column of columnDefinationsRewards" [value]="column.mappedToProperty">{{column.name}}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>
    <div class="col-md-2 m-au">
      <button *ngIf="!pageObject.isClaimSelected" color="primary" style="" mat-raised-button type="button" (click)="openManualRewardModal()"> Manual Reward </button>
    </div>
  </div>
  </div>

    <!-- CLAIMS TABLE -->
    <div [hidden]="!pageObject.isClaimSelected">
      <div class="table-container">
        <table #TABLE *ngIf="selectedColumnsClaims.length>0" mat-table [dataSource]="dataSourceClaims" matSort class="table table-striped table-hover small-txt"
               [hidden]="!tableready">
          <ng-container *ngFor="let columnClaims of getDisplayedColumnsClaims(); let colIndex = index;" [matColumnDef]="columnClaims">
            <th [hidden]="getColumnDefinationByMappedToPropertyClaims(columnClaims).hide" class="sort-column mat-sort-header-button-custom mat-header-cell" mat-header-cell *matHeaderCellDef mat-sort-header
                [matTooltip]="getColumnDefinationByMappedToPropertyClaims(columnClaims).name" [disabled]="!getColumnDefinationByMappedToPropertyClaims(columnClaims).isSortable">
              <span>{{getColumnDefinationByMappedToPropertyClaims(columnClaims).name}}</span>
            </th>

            <td mat-cell *matCellDef="let element" class="mat-cell" [matTooltip]="getTextByDataClaims(element,getColumnDefinationByMappedToPropertyClaims(columnClaims))">

              <span *ngIf="columnClaims !== 'manage' && columnClaims !== 'isHidden'">
                {{getTextByDataClaims(element,getColumnDefinationByMappedToPropertyClaims(columnClaims))}}
              </span>
              <span *ngIf="columnClaims == 'manage'">
                <a *ngIf="!element.isComplete" (click)="openFinalizeClaimModal(element.id)" class="icon-btn"><i class="fa fa-check-circle"></i></a>
              </span>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="getDisplayedColumnsClaims()"></tr>
          <tr mat-row *matRowDef="let row; columns: getDisplayedColumnsClaims(); ">
          </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="20" showFirstLastButtons [hidden]="!tableready"></mat-paginator>
      </div>
    </div>
    <!-- REWARDS TABLE -->
    <div [hidden]="pageObject.isClaimSelected">
      <div class="table-container">
        <table #TABLE *ngIf="selectedColumnsRewards.length>0" mat-table [dataSource]="dataSourceRewards" matSort class="table table-striped table-hover small-txt"
               [hidden]="!tableready">
          <ng-container *ngFor="let columnRewards of getDisplayedColumnsRewards(); let colIndex = index;" [matColumnDef]="columnRewards">
            <th [hidden]="getColumnDefinationByMappedToPropertyRewards(columnRewards).hide" class="sort-column mat-sort-header-button-custom mat-header-cell" mat-header-cell *matHeaderCellDef mat-sort-header
                [matTooltip]="getColumnDefinationByMappedToPropertyRewards(columnRewards).name" [disabled]="!getColumnDefinationByMappedToPropertyRewards(columnRewards).isSortable">
              <span>{{getColumnDefinationByMappedToPropertyRewards(columnRewards).name}}</span>
            </th>
            <td mat-cell *matCellDef="let element" class="mat-cell" [matTooltip]="getTextByDataRewards(element,getColumnDefinationByMappedToPropertyRewards(columnRewards))">

              <span *ngIf="columnRewards !== 'manage' && columnRewards !== 'isHidden'">
                {{getTextByDataRewards(element,getColumnDefinationByMappedToPropertyRewards(columnRewards))}}
              </span>
              <span *ngIf="columnRewards == 'manage'">
                <a *ngIf="!element.isClaimed && element.wasManualReward" (click)="editManualRewardModal(element)" class="icon-btn"><i class="fa fa-edit"></i></a>
                <a *ngIf="!element.isClaimed && element.wasManualReward" (click)="openDeleteManualModal(element.id)" class="icon-btn"><i class="fa fa-trash-alt"></i></a>
              </span>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="getDisplayedColumnsRewards()"></tr>
          <tr mat-row *matRowDef="let row; columns: getDisplayedColumnsRewards(); ">
          </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="20" showFirstLastButtons [hidden]="!tableready"></mat-paginator>
      </div>
    </div>
