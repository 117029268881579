import { ArrayDataSource } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Inject, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from "../models/location";

@Component({
  selector: 'report-create',
  templateUrl: 'report-create.component.html',
})
export class ReportCreateDialogComponent implements OnInit {

  title: string;
  report: any;
  reportTypes: any;
  selectedReport: any; selectedLocation: Location;
  locationsForSelectTree: any;
  treeControl = new NestedTreeControl<Location>(node => node.children);
  dataSource: any;

  hasChild = (_: number, node: Location) => !!node.children && node.children.length > 0;
  constructor(public dialogRef: MatDialogRef<ReportCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.reportTypes = data.reportTypes;
    this.report = data.report;
    this.selectedReport = data.selectedReport;
    this.selectedLocation = data.selectedLocation;

    this.locationsForSelectTree = data.locationsForSelectTree;
    this.dataSource = new ArrayDataSource(this.locationsForSelectTree);
    if (this.selectedLocation == undefined) {
      this.selectedLocation = new Location();
      this.selectedLocation.name = '';
    }
  }

  ngOnInit() { }

  getLevel(data, node: Location) {
    let path = data.find(branch => {
      return this.treeControl
        .getDescendants(branch)
        .some(n => n.name === node.name);
    });
    return path ? this.getLevel(path.children, node) + 1 : 0;
  }

  onConfirm(): void {
    if (this.selectedLocation !== undefined) {
      this.report.locationId = this.selectedLocation.id;
    }
    this.dialogRef.close({ report: this.report });
  }

  onDismiss(): void { this.dialogRef.close(); }

  selectNode(node: Location) { this.selectedLocation = node; }

}
