// dynamic-chart.component.ts
import { Component, Input, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-dynamic-chart',
  templateUrl: './dynamic-chart.component.html'
})
export class DynamicChartComponent {
  public barChartType = 'bar';
  @ViewChild(BaseChartDirective) chart: BaseChartDirective|undefined;
  @Input() chartOptions: ChartConfiguration['options'];
  @Input() chartData: ChartData;

}
