import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Observable, ReplaySubject, Subscription } from "rxjs";
import * as XLSX from 'xlsx';
import { RqVzDataTableComponent } from "../../rq-vz-data-table/rq-vz-data-table.component";
import { CommissionReconcileService } from "../../services/commission-reconcile.service";
import { NotesDialogComponent } from "../../dialogs/notes/notes-dialog.component";
import { ProductSearchDialogComponent } from "../../dialogs/product-search/product-search-dialog.component";
import { LoaderService } from "../../services/loader.service";
import { FormControl } from "@angular/forms";
import { map, startWith, takeUntil } from 'rxjs/operators';
import { MatDialog } from "@angular/material/dialog";
import { LocationService } from "../../services/location.service";

@Component({
  selector: 'all-data-table',
  templateUrl: './all-data-table.component.html',
  providers: []
})

export class AllDataTableComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(private _commissionReconcileService: CommissionReconcileService,
    private _loaderService: LoaderService, private dialog: MatDialog, private searchDialog: MatDialog, private _locationService: LocationService) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(RqVzDataTableComponent) rqVzDataTableComponent: RqVzDataTableComponent;
  @Input()
  tableData: any;
  @Output()
  getCommissionReportUpdated = new EventEmitter<string>();
  dataSource = new MatTableDataSource();
  searchTerm: string = "";
  searchTermDifference: string = "";
  myControl = new FormControl('');
  
  selectedFilter: string = "all";
  filteredOptions: Observable<number[]>;

  selectedRow = new Set<any>();
  selectedImei: any;
  debug = true;
  selectedItem = {};
  moneySymbol = '$';
  noData: Observable<boolean>;
  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    this.columnDefinations = null;
    this.dataSource = null;
    this.dialogRef?.close();
    this.searchDialogRef?.close();
  }

  ngOnInit(): void {
    this.subscriptionToClients = this._locationService.currentActualClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        this.selectedClientId = message; this.onClientSelected();
      });
  }
  
  onClientSelected() {
    if (!this.selectedClientId || +this.selectedClientId === 0) {
      return;
    }
    this.selectedClientId = +this.selectedClientId;
    this.selectedClient = this.clients.find(r => r.id === this.selectedClientId);
  }
  

  columnDefinations = [
    { name: "Select", isSortable: false, mappedToProperty: "isSelected", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Name", isSortable: true, mappedToProperty: "soldBy", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "RQ", isSortable: true, mappedToProperty: "rq", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "VZ", isSortable: true, mappedToProperty: "vz", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "IMEI", isSortable: true, mappedToProperty: "imei", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "MTN", isSortable: true, mappedToProperty: "mtn", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Customer", isSortable: true, mappedToProperty: "customerName", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Matched In RQ?", isSortable: true, mappedToProperty: "matchedInRQ", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Spiffs", isSortable: true, mappedToProperty: "spiffs", class: "", isFilterable: true, sticky: false, hide: true },
    { name: "Kick", isSortable: true, mappedToProperty: "kick", class: "", isFilterable: true, sticky: false, hide: true },
    { name: "Difference", isSortable: true, mappedToProperty: "difference", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Inspection", isSortable: true, mappedToProperty: "notes", class: "", isFilterable: true, sticky: false, hide: true },
    { name: "Description", isSortable: true, mappedToProperty: "description", class: "", isFilterable: true, sticky: false, hide: true },
    { name: "Notes", isSortable: false, mappedToProperty: "commissionIMEINotes", class: "", isFilterable: true, sticky: false, hide: false }
  ];


  exportTableToExcel() {
    let sheetData = [];
    let firstRow = [];
    let mapToList = [];
    this.columnDefinations.forEach(x => {
      if (!x.hide) {
        firstRow.push(x.name);
        mapToList.push(x.mappedToProperty);
      }
    });
    sheetData.push(firstRow);
    this.dataSource.filteredData.forEach((y: any) => {
      let thisRow = [];
      mapToList.forEach(m => {
        let textToShow='';
        switch(m){
         case 'commissionIMEINotes':          
          textToShow= this.getTextBydata(y,this.getColumnDefinationByMappedToProperty('commissionIMEINotes'));
          break;
          default:
            textToShow=y[m];
            break;
        }
        thisRow.push(textToShow);
      });
      sheetData.push(thisRow);
    });
    let ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'MonthlyCommission.xlsx');
  }

  selectedColumns = this.getDisplayedColumns();
  allAreSelected = false;

  getDisplayedColumns(): string[] {
    return this.columnDefinations.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
  }

  getColumnDefinationByMappedToProperty(mappedToProperty) {
    var column = this.columnDefinations.find(x => x.mappedToProperty == mappedToProperty);
    return column;
  }
  stats: any = null;
  setDifferenceList(stats) {
    this.stats = stats;
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value),
      ));
  }

  mtnCounts: { [key: string]: number } = {};

  preprocessDataForMtnCounts(data: any[]) {
    this.mtnCounts = data.reduce((counts, item) => {
      const mtnKey = item.mtn.trim().toLowerCase();
      counts[mtnKey] = (counts[mtnKey] || 0) + 1;
      return counts;
    }, {});
  }

  _filter(value: number): number[] {
    return this.stats.filter(x => x.toString().includes(value.toString()));
  }
  updateDataSource(data, type) {
    if (type == "Default" || type == "Protection") {
      this.columnDefinations.find(x => x.name == "Inspection").hide = true;
      this.columnDefinations.find(x => x.name == "Notes").hide = false;
      this.columnDefinations.find(x => x.name == "Description").hide = true;
      this.columnDefinations.find(x => x.name == "IMEI").hide = false;
    } else {
      this.columnDefinations.find(x => x.name == "Inspection").hide = false;
      this.columnDefinations.find(x => x.name == "Notes").hide = false;
      this.columnDefinations.find(x => x.name == "Description").hide = false;
      this.columnDefinations.find(x => x.name == "IMEI").hide = true;
    }
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.data.forEach((x: any) => { x.isSelected = this.allAreSelected; });
    if (data.length > 0) {
      this.selectedImei = data[0];
      this.selectedItem = this.selectedImei;
    }
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this._loaderService.hide();
    this.preprocessDataForMtnCounts(this.dataSource.data);
    this.dataSource.sortData = this.sortData();
    this.dataSource.filterPredicate = this.filterPredicate;
    this.applyFilter();
    this.noData = this.dataSource.connect().pipe(map(data => data.length === 0));
  }
  applyFilter() {
    // Create a filter object with current filter values
    const filterValues = {
      searchTerm: this.searchTerm.trim().toLowerCase(),
      searchTermDifference: this.searchTermDifference.toString().trim().toLowerCase(),
      selectedFilter: this.selectedFilter
    };
    
    // Convert the filter object to a JSON string
    this.dataSource.filter = JSON.stringify(filterValues);
  }


  //applyFilter() {
  //  if (this.searchTerm || this.searchTermDifference) {
      
  //    if (this.searchTerm) {
  //      this.dataSource.filter = this.searchTerm.trim().toLowerCase();
  //    }
  //    else if (this.searchTermDifference) {
  //      this.dataSource.filter = this.searchTermDifference.toString().trim().toLowerCase();
  //    } else {
  //      this.dataSource.filter = "";
  //    }
  //  } else {
  //    this.dataSource.filter = "";
  //  }

  //}


  
  //filterPredicate = (data: any): boolean => {
    //let filterValue = this.searchTerm.trim().toLowerCase();
    //let filterValueDiff = this.searchTermDifference.toString().trim().toLowerCase();
    //if ( (  filterValue == '' || (
    //  data.soldBy.trim().toLowerCase().indexOf(filterValue) !== -1
    //  ||
    //  data.rq.trim().toLowerCase().indexOf(filterValue) !== -1
    //  ||
    //  data.vz.trim().toLowerCase().indexOf(filterValue) !== -1
    //  ||
    //  data.imei.trim().toLowerCase().indexOf(filterValue) !== -1
    //  ||
    //  data.mtn.trim().toLowerCase().indexOf(filterValue) !== -1
    //  ||
    //  data.matchedInRQ.trim().toLowerCase().indexOf(filterValue) !== -1
    //)

    //) &&

    //  (filterValueDiff == ''
    //    ||
    //  (
    //    (!filterValueDiff.includes('-') && !data.difference.includes('-'))
    //    ||
    //    filterValueDiff.includes('-')
    //  )
    //    && ( data.difference.trim().toLowerCase().indexOf(filterValueDiff) !== -1))
    //) {
    //  return true;
    //}
    //return false;
  //};

  onFilterChange() {
    // Call applyFilter to set the filter on the data source
    if (this.selectedFilter === "mtnMatch") {
      this.dataSource.sort.active = 'mtn';
      this.dataSource.sort.direction = 'asc'; // or 'desc' if needed
      this.dataSource.sort.sortChange.emit({ active: 'mtn', direction: 'asc' });
    }
    this.applyFilter();
  }

  filterPredicate = (data: any, filter: string): boolean => {
    // Parse the JSON string into an object
    let filterValues = JSON.parse(filter);

    let searchTerm = filterValues.searchTerm;
    let searchTermDifference = filterValues.searchTermDifference;
    let selectedFilter = filterValues.selectedFilter;

    let matchesSearchTerm = (searchTerm === '' || (
      data.soldBy.trim().toLowerCase().includes(searchTerm) ||
      data.rq.trim().toLowerCase().includes(searchTerm) ||
      data.vz.trim().toLowerCase().includes(searchTerm) ||
      data.imei.trim().toLowerCase().includes(searchTerm) ||
      data.mtn.trim().toLowerCase().includes(searchTerm) ||
      data.matchedInRQ.trim().toLowerCase().includes(searchTerm)
    ));

    let matchesDifference = (searchTermDifference === '' ||
      (
        (!searchTermDifference.includes('-') && !data.difference.includes('-')) ||
        searchTermDifference.includes('-')
      ) && data.difference.trim().toLowerCase().includes(searchTermDifference)
    );

    let matchesSelectedFilter = true;
    const cleanedDifference = data.difference.substring(1);
    //const cleanedSearchTermDifference = parseFloat(searchTermDifference.replace(/[^0-9.-]/g, ''));

    switch (selectedFilter) {
      case 'positive':
        matchesSelectedFilter = parseFloat(cleanedDifference) > 0;
        break;
      case 'negative':
        matchesSelectedFilter = parseFloat(cleanedDifference) < 0;
        break;
      case 'mtnMatch':
        matchesSelectedFilter = this.mtnCounts[data.mtn.trim().toLowerCase()] > 1;
        break;
      case 'all':
      default:
        matchesSelectedFilter = true;
        break;
    }

    return matchesSearchTerm && matchesDifference && matchesSelectedFilter;
  };


  selectItem(imei) {
    this.selectedItem = imei;
    this.rqVzDataTableComponent.updateSelectedImei(imei);
  }

  selectImei(imei) {
    imei.isSelected = !imei.isSelected;
    this.selectedImei = imei;
    this.allAreSelected = this.isAllSelected();
  }

  selectAll(e) {
    this.allAreSelected = e.checked;
    this.dataSource.filteredData.forEach((x: any) => {
      x.isSelected = this.allAreSelected;
    });

  }

  isAllSelected() {
    var notSelectedItems = this.dataSource.data.filter(function (item: any) {
      return item.isSelected === false;
    });
    return !(notSelectedItems != null && notSelectedItems.length > 0);
  }

  checkboxLabel(row): string {
    if (row != null) {
      return `${this.allAreSelected ? 'deselect' : 'select'} all`;
    }
    return "";
  }

  sendListOfImeis(decision: number) {
    this._loaderService.show();
    const thislist: any[] = this.dataSource.data.filter(function (item: any) {
      return item.isSelected === true;
    }).map(function (item: any) {
      return item.id;
    });
    
    this._commissionReconcileService.AddImeiListCommand(thislist, decision).subscribe((response: any) => {
      this.getCommissionReportUpdated.next();
      //this.searchTermDifference = "";
      this.selectAll({ checked: false });
    });
  }

  getTextBydata(element, columnDef) {
    var textToShow = '';
    switch (columnDef.name) {
      case 'Notes':
        textToShow = element.commissionIMEINotes.length > 0 ? " (+" + (element.commissionIMEINotes.length) + " ...)" : "";
        break;
      default:
        textToShow = element[columnDef.mappedToProperty];
    }
    return textToShow;
  }


  sortData() {
    let sortFunction =
      (items: any[], sort: MatSort): any[] => {
        if (!sort.active || sort.direction === '' || sort.active == 'symbol') {
          return items;
        }
        return items.sort((a: any, b: any) => {
          let comparatorResult = 0;
          switch (sort.active) {
            case 'isSelected':
              comparatorResult = (a[sort.active] === b[sort.active]) ? 0 : a[sort.active] ? -1 : 1;
              break;
            case 'commissionIMEINotes':
              comparatorResult = (a[sort.active].length - b[sort.active].length);
              break;
            case 'rq':
            case 'vz':
            case 'difference':
            case 'spiffs':
            case 'kick':
              comparatorResult = parseInt(a[sort.active].replace('$', '')) - parseInt(b[sort.active].replace('$', ''));
              break;
            default:
              if (a[sort.active] && b[sort.active]) {
                if (a[sort.active].localeCompare instanceof Function) {
                  comparatorResult = a[sort.active].localeCompare(b[sort.active]);
                }
              } else {
                comparatorResult = -1;
              }
              break;
              
          }
          return comparatorResult * (sort.direction == 'asc' ? 1 : -1);
        });
      };
    return sortFunction;
  }
  private dialogRef: any;
  private searchDialogRef: any;
  selectedClientId: number;
  subscriptionToClients: Subscription;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  selectedClient: any;
  clients: any[] = [];
  regions: any[];

  openNotesDialog(column, element) {
    if (column == 'commissionIMEINotes') {
      element.notes = element.commissionIMEINotes;
      this.dialogRef = this.dialog.open(NotesDialogComponent,
        {
          autoFocus: true,
          disableClose: false,
          panelClass: 'notes-component',
          data: { element: element, selectedClientId: this.selectedClientId, pageOrigin: 'monthly-commision' }
        });
      this.dialogRef.afterClosed().subscribe(result => {
        if (result.update === true) {
          this.getCommissionReportUpdated.next(); this.selectAll({ checked: false });

        }
      });
    }
  }

  openHistoryDialog(element) {
    this.searchDialogRef = this.searchDialog.open(ProductSearchDialogComponent,
      {
        autoFocus: true,
        disableClose: false,
        panelClass: 'product-search-component',
        data: { element: element }
      });
  }
}
