<div class="flex-container p-3">
  <div class="flex-items pr-1">
    <mat-form-field class="searchtab">
      <mat-label>Month and Year</mat-label>
      <input matInput [matDatepicker]="datepicker" [formControl]="date">
      <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
      <mat-datepicker #datepicker startView="year" (yearSelected)="yearHandler($event)" (monthSelected)="monthHandler($event, datepicker)"></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="flex-items pr-1">
    <mat-form-field class="searchtab">
      <mat-select placeholder="Filters" [(value)]="selectedFilter" (selectionChange)="filterChanged($event)">
        <mat-option *ngFor="let filter of filters" [value]="filter.value">
          {{filter.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="selectedFilter===4" style="">
      <input matInput (keyup)="applySelectedFilter()" placeholder="Variance Filter" type="number" [(ngModel)]="varianceFilter">
    </mat-form-field>
  </div>
  <div class="flex-items pr-1">
    <div style="display: flex;flex-wrap: wrap;" class="margin-left-1em vertical-center">
      <label class="form-label semibold margin-left-1em margin-right-1em" for="selectedDayToFilter">Ignore Recent Days</label>
      <div class="" style="display:flex">
        <mat-icon color="warn" class="material-icons-round" mat-raised-button type="button" (click)="filterDays(1)">remove_circle</mat-icon>
        <span class="margin-right-1em margin-left-1em">{{ getDateFromIgnore()  }}</span>
        <mat-icon color="primary" class="material-icons-round" mat-raised-button type="button" (click)="filterDays(-1)">add_circle</mat-icon>
      </div>
    </div>
  </div>
  <div class="flex-items pr-1">
    Most Recent Order Data Date :
    <span style=" font-weight: bold; margin-right: 20px"> {{ recentDataDate  }}</span>
  </div>
</div>

<div class="col-md-12 bg-white mb-2 p-3">
  <div class="row">
    <div class="col-md-3">
      <mat-form-field style="" class="searchtab">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" [(ngModel)]="searchTerm">
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field style="" class="searchtab">
        <mat-select placeholder="Select columns" multiple [(value)]="selectedColumns" (selectionChange)="columnsChanged($event)">
          <mat-select-trigger>
            {{selectedColumns ? getColumnDefinationByMappedToProperty(selectedColumns[0]).name : ''}}
            <span *ngIf="selectedColumns?.length > 1" class="example-additional-selection">
              (+{{selectedColumns.length - 1}} {{selectedColumns?.length === 2 ? 'other' : 'others'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let column of columnDefinations" [value]="column.mappedToProperty">{{column.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-6 text-right pt-2">
      <button color="primary" style=" margin-right: 10px" mat-raised-button type="button" (click)="exportTableToExcel()"> Export </button>
      <button color="primary" style=" margin-right: 10px" *ngIf="selectedFilter === 1 || selectedFilter === 2 || selectedFilter === 4" mat-raised-button type="button" (click)="reconcileAllVisible()">Reconcile All </button>
      <button color="primary" style=" margin-right: 10px" *ngIf="(selectedFilter === 1 || selectedFilter === 2 || selectedFilter === 4) && toBeReconciled.size>0" mat-raised-button type="button" (click)="hideImeiMultiple(true)">Reconcile Selected </button>
      <button color="primary" style=" " *ngIf="selectedFilter === 3 && toBeUnreconciled.size>0" mat-raised-button type="button" (click)="hideImeiMultiple(false)">Unreconcile Selected </button>
      <button color="primary" style=" " *ngIf="selectedFilter === 3" mat-raised-button type="button" (click)="reconcileAllVisible()">Unreconcile All </button>
    </div>
  </div>
</div>

<div class="table-container">
  <table #TABLE *ngIf="selectedColumns.length>0 " mat-table [dataSource]="dataSource" matSort class="table table-stripped table-hover small-txt"
         [hidden]="!tableready">
    <ng-container *ngFor="let column of getDisplayedColumns(); let colIndex = index;" [matColumnDef]="column">
      <th [hidden]="getColumnDefinationByMappedToProperty(column).hide" class="sort-column mat-sort-header-button-custom mat-header-cell" mat-header-cell *matHeaderCellDef mat-sort-header
          [matTooltip]="getColumnDefinationByMappedToProperty(column).name" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip>
        <span>{{getColumnDefinationByMappedToProperty(column).name}}</span>
      </th>
      <td mat-cell *matCellDef="let element" class="mat-cell " style="" [matTooltip]="getTextBydata(element,getColumnDefinationByMappedToProperty(column))" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip [ngClass]="{'yellow' : selectedIMEI == element['serialNumber']}" (click)="openNotesDialog(column,element)">
        <span *ngIf="!isTrackingLink(element,getColumnDefinationByMappedToProperty(column)) && column !== 'isHidden' && column !== 'icon' && column !== 'notes'">{{getTextBydata(element,getColumnDefinationByMappedToProperty(column))}}</span>
        <a *ngIf="isTrackingLink(element,getColumnDefinationByMappedToProperty(column))" href="{{element['trackingLink']}}" target="_blank">
          {{getTextBydata(element,getColumnDefinationByMappedToProperty(column))}}
        </a>
        <span *ngIf="column === 'icon'">
          <div class="fas fa-circle" [ngStyle]="{'color': getIconColor(element)}"> </div>
        </span>
        <span *ngIf="column === 'notes'" style="margin-left:20px">
          <div class="fa fa-sticky-note">{{getNoteCountText(element)}}</div>
        </span>
        <mat-checkbox style="margin-left:20px" *ngIf="column === 'isHidden'" type="checkbox" [(ngModel)]="element['isHidden']" [checked]="element['isHidden']" (change)="collectToReconcile($event,element)"></mat-checkbox>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
    <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();" (click)="selectIMEI(row['serialNumber'])">
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="20" showFirstLastButtons [hidden]="!tableready"></mat-paginator>

</div>
