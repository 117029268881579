
<div class="d-flex align-items-center justify-content-between action-header ">
  <div class="d-flex align-items-center ">
    <div class="d-flex aling-items-center">
      <ng-container *ngFor="let mode of modes">
        <mat-radio-button *ngIf="+mode.name !== report.Compare"
                          class="margin-left-1em"
                          [value]="mode.name"
                          [checked]="selectedMode === mode.name"
                          (click)="onMTDReportModeSelected(mode.name)">
          {{mode.value}}
        </mat-radio-button>
      </ng-container>
    </div>
    <fieldset class="form-group ml-4" *ngIf="isTrendingVisible">
      <label class="form-label semibold text-center">Trending</label>
      <div class="trending-btn" (click)="toggleTrending()">
        {{trendingCounter}}<i class="fa" [ngClass]="isTrendingApplied ? 'fa-check' : 'fa-times'"></i>
      </div>
    </fieldset>
    <mat-form-field class="bottom-border-without-bg ml-4 filter-action-width">
      <mat-select placeholder="Duration" [(value)]="selectedDuration" [disabled]="localStorageLoadingInProgress" (selectionChange)="onDurationSelected()">
        <mat-option [value]="0">Select Duration</mat-option>
        <mat-option *ngFor="let duration of durations" [value]="duration.value">{{duration.text}}</mat-option>
      </mat-select>
    </mat-form-field>
    <fieldset class="bottom-border-without-bg ml-4 filter-action-width" *ngIf="singleOtherDurationSelection">
      <label class="form-label semibold" for="duration">Period</label>
      <input class="custom-input" type="month" [(ngModel)]="selectedOtherDuration" [disabled]="localStorageLoadingInProgress" />
    </fieldset>
    <fieldset class=" ml-4 " *ngIf="customDurationSelection">
      <label class="form-label semibold" for="duration">Custom Range</label>
      <div class="d-flex align-items-center">
        <div class="d-flex bottom-border-without-bg">
          <span class="mr-2"> From: </span> <input class="custom-input" type="date" [(ngModel)]="customRangeStartDate" />
        </div>
        <div class="ml-3 d-flex bottom-border-without-bg">
          <span class="mr-2"> To: </span><input class="custom-input" type="date" [(ngModel)]="customRangeEndDate" />
        </div>
      </div>
    </fieldset>
    <fieldset class="bottom-border-without-bg ml-4 filter-action-width" *ngIf="multipleOtherDurationSelection">
      <label class="form-label semibold" for="duration">Current vs Previous Period</label>
      <input class="form-control col-sm-1 inline-block-element" type="month" [(ngModel)]="selectedOtherCurrentDuration" />
      <input class="form-control col-sm-1 inline-block-element" type="month" [(ngModel)]="selectedOtherPreviousDuration" />
    </fieldset>

    <mat-form-field class="bottom-border-without-bg ml-4 filter-action-width" *ngIf="isFormulaViewGroupSelectionVisible">
      <mat-select placeholder="Select Formula View Groups" multiple [(value)]="selectedFormulaViewGroups">
        <mat-select-trigger>
          {{selectedFormulaViewGroups ?selectedFormulaViewGroups[0] : ''}}
          <span *ngIf="selectedFormulaViewGroups?.length > 1" class="example-additional-selection">
            (+{{selectedFormulaViewGroups.length - 1}} {{selectedFormulaViewGroups?.length === 2 ? 'other' : 'others'}})
          </span>
        </mat-select-trigger>
        <mat-option *ngFor="let formulaViewGroup of formulaViewGroups" [value]="formulaViewGroup.name" (click)="toggleFormulaViewGroup(formulaViewGroup)">{{formulaViewGroup.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="bottom-border-without-bg ml-4 filter-action-width" *ngIf="isFormulaViewGroupSelectionVisible||isFormulaSelectionVisible">
      <mat-select placeholder="Select Formulas" multiple [(value)]="selectedFormulas">
        <mat-select-trigger>
          {{selectedFormulas ?selectedFormulas[0] : ''}}
          <span *ngIf="selectedFormulas?.length > 1" class="example-additional-selection">
            (+{{selectedFormulas.length - 1}} {{selectedFormulas?.length === 2 ? 'other' : 'others'}})
          </span>
        </mat-select-trigger>
        <mat-option *ngFor="let formula of formulas" [value]="formula.name" (click)="toggleFormulas(formula)">{{formula.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <button mat-raised-button class="primary-action-btm ml-4 px-4" color="primary" type="button" (click)="searchReport();">Submit</button>
</div>
<div class="row mt-3">
  <div class="col-sm-12">
    <div *ngIf="fetchReportInProgress && +selectedMode === report.Store" class="overflow">
      <table class="table table-bordered table-hover small-txt" *ngFor="let i of [1, 2, 3]">
        <thead>
          <tr>
            <th class="nameColumn">Name</th>
            <th *ngFor="let col of columns" class="sort-column " style="vertical-align:top" [hidden]="!col.visible" style="vertical-align:top;">
              <div style="height:5px; border-radius:2px;" [ngStyle]="{'background-color': col.color } "></div>
              {{col.header}}
              <span *ngIf="col.sorting"></span>
              <a *ngIf="table.toggleColumns" (click)="hide(col)" class="icon-btn"><i class="fa fa-remove"></i></a>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr role="row">
            <td><span class="loading-span-sm"></span></td>
            <td *ngFor="let col of columns" [hidden]="!col.visible" style="vertical-align:top;"><span class="loading-span-sm"></span></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="!fetchReportInProgress && +selectedMode === report.Store && storeReportData" class="overflow">
      <mat-tab-group #tabGroup mat-align-tabs="start" backgroundColor="primary">
        <mat-tab label="Summary">
          <table #storeReportTable class="table table-bordered table-hover small-txt" style="margin-top:20px">
            <thead>
              <tr>
                <th class="nameColumn">Name</th>
                <th *ngFor="let col of columns, let i = index" (click)="sortTables( (i + 1))" class="sort-column" [hidden]="!col.visible" style="vertical-align:top;">
                  <div style="height:5px; border-radius:2px;" [ngStyle]="{'background-color': col.color } ">
                  </div>
                  {{col.header}}
                  <span *ngIf="col.sorting"></span>
                  <a *ngIf="table.toggleColumns" (click)="hide(col)" class="icon-btn"><i class="fa fa-remove"></i></a>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let district of storeReportData; let i=index" role="row" class="summary-row" >
                <td class="nameColumn" style="background-color: rgba(143, 173, 215, 0.4);">{{district.name}}</td>
                <td class="mtdsquare" *ngFor="let col of columns" [hidden]="!col.visible" [ngClass]="{'warning-limit': isFormulaUnderWarningLimit(district.formulas, col.name),
                  'average-limit': isFormulaUnderAverageLimit(district.formulas, col.name)}" style="vertical-align:top;" style="background-color: rgba(143, 173, 215, 0.4);">
                  <span *ngIf="isTrendingPossibleInFormula(district.formulas, col.name, 'trendingPossible')">
                    {{ getFormulaComputedPropertyValue(district.formulas, col.name, 'value', isTrendingApplied) }}
                  </span>
                  <span *ngIf="!isTrendingPossibleInFormula(district.formulas, col.name, 'trendingPossible')" style="font-weight:bold">
                    {{ getFormulaComputedPropertyValue(district.formulas, col.name, 'value') }}
                  </span>
                </td>
              </tr>
              <tr *ngFor="let store of storeReportData[0].stores; let i=index" role="row">
                <td class="nameColumn">{{store.name}}</td>
                <td class="mtdsquare" *ngFor="let col of columns" [hidden]="!col.visible" [ngClass]="{'warning-limit': isFormulaUnderWarningLimit(store.formulas, col.name),'average-limit': isFormulaUnderAverageLimit(store.formulas, col.name)}">
                  <span *ngIf="isTrendingPossibleInFormula(store.formulas, col.name, 'trendingPossible')">{{ getFormulaComputedPropertyValue(store.formulas, col.name, 'value', isTrendingApplied) }}</span>
                  <span *ngIf="!isTrendingPossibleInFormula(store.formulas, col.name, 'trendingPossible')">{{ getFormulaComputedPropertyValue(store.formulas, col.name, 'value') }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </mat-tab>
        <mat-tab *ngFor="let store of storeReportData[0].stores" label="{{store.name}}">
          <table style="margin-top:20px" #storeReportTable class="table table-bordered table-hover small-txt">
            <thead>
              <tr (click)="toggleCollapse(store);" class="cursor-pointer">
                <th [attr.colspan]="(columns.length + 2)"><i class="fas fa-store"></i> {{store.name}}</th>
              </tr>
              <tr *ngIf="!store.collapse">
                <th class="nameColumn">Name</th>
                <th *ngFor="let col of columns, let i = index" (click)="sortTables( (i + 1))" class="sort-column" style="vertical-align:top;" [hidden]="!col.visible">
                  <div style="height:5px; border-radius:2px;" [ngStyle]="{'background-color': col.color } ">
                  </div>
                  {{col.header}}
                  <span *ngIf="col.sorting"></span>
                  <a *ngIf="table.toggleColumns" (click)="hide(col)" class="icon-btn"><i class="fa fa-remove"></i></a>
                </th>
              </tr>
            </thead>
            <tbody *ngIf="!store.collapse && !fetchReportInProgress && +selectedMode === report.Store">
              <tr *ngFor="let employee of store.employees; let i=index" role="row">
                <td>{{employee.name}}</td>
                <td class="mtdsquare" *ngFor="let col of columns" [hidden]="!col.visible" [ngClass]="{'warning-limit': isFormulaUnderWarningLimit(employee.formulas, col.name),
                  'average-limit': isFormulaUnderAverageLimit(employee.formulas, col.name)}" style="vertical-align:top;">
                  <span *ngIf="isTrendingPossibleInFormula(employee.formulas, col.name, 'trendingPossible')" style="font-weight:bold">
                    {{ getFormulaComputedPropertyValue(employee.formulas, col.name, 'value', isTrendingApplied) }}
                  </span>
                  <span *ngIf="!isTrendingPossibleInFormula(employee.formulas, col.name, 'trendingPossible')" style="font-weight:bold">
                    {{ getFormulaComputedPropertyValue(employee.formulas, col.name, 'value') }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div *ngIf="fetchReportInProgress && +selectedMode === report.Employee" class="overflow">
      <table class="table table-bordered table-hover small-txt">
        <thead>
          <tr>
            <th>Name</th>
            <th *ngFor="let col of columns" class="sort-column" [hidden]="!col.visible" style="vertical-align:top;">
              <div style="height:5px; border-radius:2px;" [ngStyle]="{'background-color': col.color } "></div>
              {{col.header}}
              <span *ngIf="col.sorting">
                <i class="fa fa-caret-up" *ngIf="i == columnUnderSorting && isAscendingOrder"></i>
                <i class="fa fa-caret-down" *ngIf="i == columnUnderSorting && !isAscendingOrder"></i>
              </span>
              <a *ngIf="table.toggleColumns" (click)="hide(col)" class="icon-btn"><i class="fa fa-remove"></i></a>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr role="row">
            <td><span class="loading-span-sm"></span></td>
            <td *ngFor="let col of columns" [hidden]="!col.visible"><span class="loading-span-sm"></span></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="!fetchReportInProgress && +selectedMode === report.Employee && employeeReportData" class="overflow">
      <table #employeeReportTable class="table table-bordered table-hover small-txt">
        <thead>
          <tr>
            <th>Name</th>
            <th *ngFor="let col of columns, let i = index" (click)="sortTable( (i + 1), 2 )" class="sort-column"  [hidden]="!col.visible" style="vertical-align:top;">
              <div style="height:5px; border-radius:2px;" [ngStyle]="{'background-color': col.color } "></div>
              {{col.header}}
              <i class="fa fa-caret-up" *ngIf="i == columnUnderSorting && isAscendingOrder"></i>
              <i class="fa fa-caret-down" *ngIf="i == columnUnderSorting && !isAscendingOrder"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let employee of employeeReportData; let i=index" role="row">
            <td>{{employee.name}}</td>
            <td class="mtdsquare" *ngFor="let col of columns" [hidden]="!col.visible" [ngClass]="{'warning-limit': isFormulaUnderWarningLimit(employee.formulas, col.name),'average-limit': isFormulaUnderAverageLimit(employee.formulas, col.name)}" style="font-weight:bold" style="vertical-align:top;">
              {{ getFormulaComputedPropertyValue(employee.formulas, col.name, 'value', isTrendingPossibleInFormula(employee.formulas, col.name, 'trendingPossible') ? isTrendingApplied : false) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
