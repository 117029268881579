import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductCatalog } from '../models/product-catalog';

@Injectable({
  providedIn: 'root'
})
export class ProductCatalogService {
  private _baseUrl = '/api/productcatalogs';

  constructor(private http: HttpClient) {}

  getProductCatalogs(clientId: number): Observable<ProductCatalog[]> {
    return this.http.get<ProductCatalog[]>(this._baseUrl + '/' + clientId);
  }
}
