import { Inject, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RewardGameViewModel } from "../models/reward-game";
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { DurationService } from '../services/duration.service';
import { Duration } from '../models/duration';
import {  CurrencyPipe} from '@angular/common';

@Component({
  selector: 'rewards-create-dialog',
  templateUrl: 'rewards-create-dialog.component.html',
})
export class RewardsCreateDialogComponent implements OnInit {

  title: string;
  rewardGame: RewardGameViewModel;
  formulas: any;
  blockNonMonthly: boolean;
  employees: any;
  locations: any;
  filteredEmployees: any;
  isEdit: boolean;
  managerRewardGameTypesKeys: any;
  rewardGameTypesKeys: any;
  rewardGameTypes: any;
  createTemplate:any;
  rewardGameStatusesKeys:any;
  rewardGameStatuses:any;
  constructor(public dialogRef: MatDialogRef<RewardsCreateDialogComponent>, private _durationService: DurationService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.rewardGame = data.rewardGame;
    this.formulas = data.formulas;
    this.employees = data.employees;
    this.locations = data.locations;
    this.isEdit = data.isEdit;
    this.rewardGameTypesKeys = data.rewardGameTypesKeys;
    this.managerRewardGameTypesKeys = data.managerRewardGameTypesKeys;
    this.rewardGameTypes = data.rewardGameTypes;
    this.createTemplate = data.createTemplate;
    this.rewardGameStatusesKeys = data.rewardGameStatusesKeys;
    this.rewardGameStatuses = data.rewardGameStatuses;
  }
  //transformAmount(element){
  //  this.rewardGame.payPer = this.currencyPipe.transform(this.rewardGame.payPer, '$');

  //  element.target.value = this.rewardGame.payPer;
  //}

  onFormulaSelected(data: any) {
    var formula = this.formulas.find(x => x.id == this.rewardGame.formulaId);
    if (formula.formulaValue.includes("REBIZ") || formula.formulaValue.includes("HOURS")) {
      this.blockNonMonthly = true;
      this.rewardGame.monthly = true;
    } else {
      this.blockNonMonthly = false;
    }
  }
  selectEmployee(event) {
    console.log("selectEmployee ", event);
    if (event.value.includes(0)) {
      this.rewardGame.selectAllEmployees(this.employees, true);
    }
  }
  selectLocation(event) {
    console.log("selectLocation ", event);
    if (event.value.includes(0)) {
      this.rewardGame.selectAllStores(this.employees, true);
    }
  }

  changeGameType(event) {
    this.rewardGame.changeGameType();
    if (this.rewardGame.type === 2) {
      this.rewardGame.minimumCount = 1;
    }
  }

  cleanMath(number) {
    if ((number * 100 % 100) != 0) {
      let newNumber = parseFloat(number).toPrecision(2);
      return newNumber;
    }
    return number;
  }
  startDateChanged(type: string, event: MatDatepickerInputEvent<Date>) {
    var start = new Date(moment(event.value).format(this._durationService.dateStartFormat));
    this.rewardGame.startDate = new Date(start.getUTCDate() + start.getTime() - start.getTimezoneOffset() * 60 * 1000);
  }

  endDateChanged(type: string, event: MatDatepickerInputEvent<Date>) {
    var end = new Date(moment(event.value).format(this._durationService.dateEndFormat));
    this.rewardGame.endDate = new Date(end.getUTCDate() + end.getTime() - end.getTimezoneOffset() * 60 * 1000);
    this.createTemplate.startDate = this.createTemplate.startDate;
  }

  setDatesUsingMonth() {
    var dateRange = this._durationService.getDateRangeByYearWithUTC(moment(this.createTemplate.monthlyDate).format("YYYY-MM"));
    this.rewardGame.monthlyDuration = this.createTemplate.monthlyDate;
    this.rewardGame.startDate = new Date(dateRange.start.format(this._durationService.dateStartFormat).toString());
    this.rewardGame.startDate = new Date(this.rewardGame.startDate.getUTCDate() + this.rewardGame.startDate.getTime() - this.rewardGame.startDate.getTimezoneOffset() * 60 * 1000);
    this.rewardGame.endDate = new Date(dateRange.end.format(this._durationService.dateEndFormat).toString());
    this.rewardGame.endDate = new Date(this.rewardGame.endDate.getUTCDate() + this.rewardGame.endDate.getTime() - this.rewardGame.endDate.getTimezoneOffset() * 60 * 1000);
  }

  onValueChange(event: any) {
    if (parseInt(event.target.value) === 0 && this.rewardGameTypes[this.rewardGame.type] === 'Interval' ) {
      event.target.value = 1;
      this.rewardGame.minimumCount = 1;
    }
  }
  ngOnInit() { }

  onConfirm(): void {

   // let data = { rewardGame: this.rewardGame };
    this.dialogRef.close(this.rewardGame);
  }

  onDismiss(): void { this.dialogRef.close(null); }
}

