import { Inject, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'finalize-claim',
  templateUrl: 'finalize-claim.component.html',
})
export class FinalizeClaimComponent implements OnInit {

  title: string;
  finalizeClaimModal: any;

  constructor(public dialogRef: MatDialogRef<FinalizeClaimComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.finalizeClaimModal = data.finalizeClaimModal;
  }

  ngOnInit() { }

  onConfirm(): void { this.dialogRef.close(true); }

  onDismiss(): void { this.dialogRef.close(false); }
}

