
import { Employee } from "./employee";
import { RewardGameViewModel } from "./reward-game";






export class RewardEarnedViewModel {
  constructor() {

  }

  id: number;
  rewardGameId: number;
  rewardGame: RewardGameViewModel;
  wasManualReward: boolean = false;
  isClaimed: boolean;
  description: string;
  employeeId: number;
  employee: Employee;
  createdDate: Date;
  claimDate: Date;
  money: number;

}

