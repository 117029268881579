import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Employee } from '../models/employee';
import { Status } from '../users/models/status';
import { UserStatusCode } from '../users/models/userStatusCode';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  private _baseUrl = '/api/employees';
  employees: Employee[] = [];
  disabledEmployeesIncluded: Employee[] = [];

  constructor(private http: HttpClient) {
    this.getAllEmployees().subscribe((response: Employee[]) => {
      response = response.filter(employee => +employee.user.userStatusCode.id === Status.Active);
      this.employees = response.sort((a, b) => a.rqUsername.localeCompare(b.rqUsername));
    }, () => { }, () => { });
  }

  getAllEmployees(): Observable<Employee[]> {
    return this.http.get<Employee[]>(this._baseUrl);
  }

  loadNonbiasEmployees() {
    this.getAllEmployees().subscribe((response: Employee[]) => {
      this.disabledEmployeesIncluded = response.sort((a, b) => a.rqUsername.localeCompare(b.rqUsername));
    }, () => { }, () => { });
  }

  getEmployeeUserIdsByLocationsDisabled(locations: number[], callback: any) {
    callback(this.disabledEmployeesIncluded.filter(employee => locations
      .includes(employee.locationId)).map(function (e) { return e.userId; }));
  }

  getEmployeesByLocationCallback(callback: any, location?: number) {
    if (this.employees.length === 0) {
      this.getAllEmployees().subscribe((response) => {
        const result = (location) ? response.
          filter(employee => employee.locationId === location &&
            +employee.user.userStatusCode.id === Status.Active) : this.employees;
        callback(result);
      });
    } else {
      const result = (location) ? this.employees.filter(employee => employee.locationId === location) : this.employees;
      callback(result);
    }
  }

  getEmployeesByLocationsCallback(callback: any, locations: number[]) {
    if (this.employees.length === 0) {
      this.getAllEmployees().subscribe((response) => {
        const result = response.
          filter(employee => locations.includes(employee.locationId) &&
            +employee.user.userStatusCode.id === Status.Active);
        callback(result);
      });
    } else {
      const result = this.employees.filter(employee => locations.includes(employee.locationId));
      callback(result);
    }
  }

  getEmployeesByLocation(location?: number): Observable<Employee[]> {
    const result = (location) ? this.employees.filter(employee => employee.locationId === location) : this.employees;
    return of(result);
  }

  getEmployeesByLocations(locations: number[]): Observable<Employee[]> {
    const result = this.employees.filter(employee => locations.includes(employee.locationId));
    return of(result);
    // callback(this.employees.filter(employee => locations.includes(employee.locationId)).map(function (e) { return e; }));
  }

  getEmployeeUserIdsByLocations(locations: number[], callback: any) {
    callback(this.employees.filter(employee => locations.includes(employee.locationId)).map(function (e) { return e.userId; }));
  }

  getEmployeeUsersByLocations(locations: number[], callback: any) {
    if (this.employees.length === 0) {
      this.getAllEmployees().subscribe((response) => {
        callback(this.employees.filter(employee => locations.includes(employee.locationId)).map(function (e) { return e; }));
      });
    } else {
      callback(this.employees.filter(employee => locations.includes(employee.locationId)).map(function (e) { return e; }));
    }
  }

  getRecentEmployeeUsersByLocations(locations: number[], months: number, callback: any) {
    let dateLimit = new Date();
    dateLimit.setMonth(dateLimit.getMonth() - months);
    //if (this.employees.length === 0) {
    this.getAllEmployees().subscribe((response) => {
      response = response.sort((a, b) => a.rqUsername.localeCompare(b.rqUsername));
      callback(response.filter(employee => ((new Date(employee.terminationDate) > dateLimit) || employee.user.userStatusCodeId == '1') && locations.includes(employee.locationId)).map(function (e) { return e; }));
    });
    //} else {
    //  callback(this.employees.filter(employee => ((new Date(employee.hireDate) > dateLimit) || employee.user.userStatusCodeId == '1') && locations.includes(employee.locationId)).map(function (e) { return e; }));
    //}
  }

  getEmployeePrimaryLocationById(id: number): Observable<number> {
    const locationId = this.employees.find(employee => employee.id === id).locationId;
    return of(locationId);
  }
}
