import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RewardGameViewModel } from '../models/reward-game';
import { RewardGameDetailsViewModel } from '../models/reward-game-details';
import { RewardEarnedViewModel } from '../models/reward-earned';
import { forEach } from 'underscore';




@Injectable({
  providedIn: 'root'
})



export class RewardService {
  private _baseUrl = '/api/reward';


  constructor(private http: HttpClient) {

  }
  createRewardGame(rewardGame: RewardGameViewModel): Observable<any> {
    return this.http.post(this._baseUrl + "/Create", rewardGame);
  }

  deleteRewardGame(id: number): Observable<any> {
    return this.http.delete(this._baseUrl + "/Delete/" + id);
  }

  deleteManualReward(id: number): Observable<any> {
    return this.http.delete(this._baseUrl + "/DeleteManualReward/" + id);
  }
  getRewardGamesForAdmin(clientId: number): Observable<RewardGameViewModel[]> {
    return this.http.get<RewardGameViewModel[]>(this._baseUrl + "/GetRewardGamesForAdmin/" + clientId);
  }

  getRewardGamesForEmployee(userId: string, days: number): Observable<RewardGameDetailsViewModel[]> {
    return this.http.get<RewardGameDetailsViewModel[]>(this._baseUrl + "/GetRewardGamesForEmployees/" + userId + "/" + days);
  }

  finalizeGames(clientId: number, confirm: boolean): Observable<any> {
    return this.http.get<any>(this._baseUrl + "/FinalizeRewardGames/" + clientId + "/" + confirm);
  }

  getRewardGameSummary(game: RewardGameViewModel): string {
    let text = '';
    if (game) {
      if (game.type == 0) {
        game.winnerArray.forEach((prize) => {
          text += '#' + (prize.rank + 1) + ' winner - $' + prize.prize + ' \n ';
        })
      }
      else if (game.type == 1) {
        text += 'Earn $' + game.payPer + ' for every sale after ' + game.minimumCount + ' ' + game.formula.name + '. ';
        if (game.payMinimumCount) {
          text += " This does include the sales before the minimum count" + '. ';
        } else {
          text += " This does not include the sales before the minimum count" + '. ';
        }
        text += ' With a maximum of ' + game.maximumCount + '. ';
      }
      else if (game.type == 2) {
        text += 'Earn $' + game.payPer + ' for every ' + game.minimumCount + ' ' + game.formula.name + '. ';
        if (game.doNotPayBeforeIntervals > 0) {
          text += ' Starting after you pass ' + game.minimumCount * game.doNotPayBeforeIntervals + '. ';
        }
        if (game.maximumCount) {
          text += ' With a maximum of ' + game.minimumCount * game.maximumCount + '. ';
        }
      }
      else if (game.type == 3) {
        text += 'Earn $' + game.payPer + ' for getting atleast ' + game.minimumCount + ' ' + game.formula.name + '. ';
      }
      if (!game.multipleEmployees) {
        text += "All sales in the store will count towards this goal. ";
      }
    }
    return text;
  }


  getMoneyRewardsForUser(userId: string): Observable<RewardEarnedViewModel[]> {
    return this.http.get<RewardEarnedViewModel[]>(this._baseUrl + "/GetMoneyRewardsForUser/" + userId);
  }

  getRewardClaimsForUser(userId: string): Observable<any> {
    return this.http.get<any>(this._baseUrl + "/GetRewardClaimsForUser/" + userId);
  }

  getRewardClaimsForClient(clientId: number): Observable<any> {
    return this.http.get<any>(this._baseUrl + "/GetRewardClaimsForClient/" + clientId);
  }
  getMoneyRewardsForClient(clientId: number): Observable<RewardEarnedViewModel[]> {
    return this.http.get<RewardEarnedViewModel[]>(this._baseUrl + "/GetMoneyRewardsForClient/" + clientId);
  }

  sendRewardClaim(list: number[], optionId: number): Observable<any> {
    return this.http.post<any>(this._baseUrl + "/SendRewardClaim/" + optionId, list);
  }
  getRewardClaimOptions(clientId: number): Observable<any> {
    return this.http.get<any>(this._baseUrl + "/GetRewardClaimOptions/" + clientId);
  }

  sendFinalizeClaim(claimId: number): Observable<any> {
    return this.http.get<any>(this._baseUrl + "/FinalizeClaim/" + claimId);
  }

  createManualReward(rewardEarned: RewardEarnedViewModel): Observable<any> {
    return this.http.post<any>(this._baseUrl + "/CreateManualReward", rewardEarned);
  }

}
