import { Component, OnInit } from '@angular/core';
import { AssetService } from '../services/asset.service';

@Component({
  selector: 'app-column-content-layout',
  templateUrl: './column-content-layout.component.html'
})
export class ColumnContentLayoutComponent implements OnInit {

  constructor(private assetService: AssetService) {}

  ngOnInit() {
    //this.assetService.loadAssets(AssetType.SCRIPTS, ['jquery', 'tether', 'plugins', 'jquery-ui',
     // 'jquery-matchHeight', 'app', 'idle-timer'])
     // .then(data => { console.log('script loaded ', data); }).catch(error => console.log(error));
  }
}
