
import { DateRange } from "./date-range";
import { Employee } from "./employee";
import { Location } from "./location";
import { Formula } from "./formula";
import { RewardGameType } from "./reward-game-type";
import { RewardGameStatus } from "./reward-status";
import * as moment from "moment";
import { inherits } from "util";
import { User } from "../users/models/user";


export class WinnerPrize {
  constructor(public rank: number, public prize: number) { }
  public id: number;
}

export class EmployeeListObject {
  employeeId: number;
  employeeName: string;
}



export class RewardGameViewModel {
  constructor(
    public type: RewardGameType,
    public status: RewardGameStatus,
    public recurring: boolean,
    public monthly: boolean,
    public multipleEmployees: boolean,
    public winnerCount: number,
    public isEveryPerson = false,
    public isEveryStore = false,
    public target = 0) {
    this.updateWinnerCount();
    this.monthlyDuration = moment().format('YYYY-MM');
  }

  id: number;
  name: string;
  description: string;
  createdByUserName: string;
  createdDate: Date;
  monthlyDuration: string;
  startDate: Date;
  endDate: Date;
  FinalizeDate: Date;
  searchForEmployeeName: string = "";
  searchForStoreName: string = "";
  formulaId: number;
  achieved: number;
  formula: Formula;
  managerId: number;
  manager: Employee;
  managerLocationId: number;
  managerLocation: Location;
  payPer: number = 0;
  minimumCount: number = 0;
  maximumCount: number = 0;
  payMinimumCount: boolean = false;
  doNotPayBeforeIntervals: number = 0;
  employeeList: any[] = new Array(0);
  locationList: Location[] = new Array(0);

  winnerArray: WinnerPrize[] = new Array(0);

  rewardMoneyForEmployees: any[];

  selectAllEmployees(employees: Employee[], toAdd: boolean) {
    //console.log(employees);
    if (toAdd) {
      this.employeeList = new Array(0);
      employees.forEach(x => {
        this.employeeList.push(x);
        //this.employeeList.push(x.id);
      })
    } else {
      this.employeeList = new Array(0);
    }
    //console.log(this.employeeList);
  }

  selectAllStores(stores: Location[], toAdd: boolean) {
    if (toAdd) {
      this.locationList = new Array(0);
      stores.forEach(x => {
        this.locationList.push(x);
      })
    } else {
      this.locationList = new Array(0);
    }
    //console.log(this.locationList);
  }

  selectEmployee(id: Employee, toAdd: boolean) {
    if (toAdd) {
      this.employeeList.push(id);
    }
    else {
      let index = this.employeeList.indexOf(id);
      this.employeeList.splice(index, 1);
    }
  }


  selectLocation(id: Location, toAdd: boolean) {
    //console.log(this.locationList);
    if (toAdd) {
      this.locationList.push(id);
    }
    else {
      let index = this.locationList.indexOf(id);
      //console.log(index);
      this.locationList.splice(index, 1);
    }
    //console.log(this.locationList);
  }


  updateWinnerCount() {
    // New List is Shorter
    //if (this.winnerArray.length > this.winnerCount) {
    //  for (var i = this.winnerArray.length; i == this.winnerCount; i--) {
    //    this.winnerArray.pop();
    //  }
    //} else if (this.winnerArray.length < this.winnerCount) {
    //  this.winnerArray.length == this.
    //}
    //this.winnerArray = new Array[this.winnerCount];
    this.winnerArray.length = this.winnerCount;
    //console.log(this.winnerArray);
    for (var i = 0; i < this.winnerCount; i++) {
      if (this.winnerArray[i] == undefined) {
        this.winnerArray[i] = new WinnerPrize(i, 0);
      }
    }
  }

  resetEmployeeList() {
    if (this.multipleEmployees) {
      delete this.managerId;
      delete this.managerLocationId;
    } else {
      this.employeeList = new Array(0);
      this.locationList = new Array(0);
      if (this.type == RewardGameType.Competition) {
        this.type = RewardGameType.SingleGoal;
      }
    }
  }

  changeGameType() {
    this.payPer = 0;
    this.minimumCount = 0;
    this.maximumCount = 0;
    this.payMinimumCount = false;
  }

  //checkEmployee

  //RewardGameViewModel() {
  //  this.name = "Something";
  //  this.type = 0;
  //  this.status = RewardGameStatus.Active;
  //}
}

