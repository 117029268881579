<button mat-icon-button class="close-button" (click)="onDismiss()">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h1 class="heading-text" mat-dialog-title>{{title}}</h1>
<form #f="ngForm" class="sku-group-form">
  <div class="custom-input-box create-form-group-width-set">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput type="text" [(ngModel)]="skuGroup.name" #name="ngModel" name="name" required />
      <mat-error class="mt-1" *ngIf="name.invalid">Sku group name is required.</mat-error>
    </mat-form-field>
  </div>
</form>

<div class="d-flex align-items-center mt-4 pt-1">
  <button mat-raised-button color="primary" class="submit-btn" (click)="onConfirm()" [disabled]="!skuGroup.name">Submit</button>
  <button mat-button class="clear-btn ml-3" (click)="onDismiss()">Clear</button>
</div>
