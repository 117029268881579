<div class="row">
  <div class="col-4">
    <div class="card-container p-4 h-100">
      <div class="d-flex justify-content-center">
        <div>
          <button class="primary-action-btm" mat-raised-button color="primary" (click)="clearSelected()"> Clear Selection</button>
          <button class="primary-action-btm ml-4" mat-raised-button color="primary" (click)="openCreateRole()">New Role</button>
     
        </div>
      </div>
      <div style="margin-top:35px">
        <mat-tab-group mat-stretch-tabs #tabGroup mat-align-tabs="start"  backgroundColor="primary">
          <mat-tab id="tab0" label="Role">
            <div class="mt-4">
              <!-- main tree node -->
              <cdk-tree [dataSource]="dataSource" [treeControl]="treeControl">
                <!-- leaf node -->
                <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="example-tree-node" #nestedTreeNodeElement>
                  <button mat-icon-button disabled *ngIf="node.clientId != 0"></button>
                  <button mat-button (click)="onRoleSelected(node)" class="d-inline-flex" [ngClass]="{ 'selected-category': selectedRole?.id == node.id }" *ngIf="node.clientId != 0">{{node.displayName}}</button>
                </cdk-nested-tree-node>
                <!-- parent node -->
                <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChild" class="example-tree-node" #nestedTreeNodeElement>
                  <button mat-icon-button [attr.aria-label]="'Toggle ' + node.displayName" cdkTreeNodeToggle *ngIf="node.clientId != 0">
                    <mat-icon class="mat-icon-rtl-mirror">{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}</mat-icon>
                  </button>
                  <button mat-button (click)="onRoleSelected(node)" class="d-inline-flex" [ngClass]="{ 'selected-category': selectedRole?.id == node.id }" *ngIf="node.clientId != 0">{{node.displayName}}</button>
                  <div [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                    <ng-container cdkTreeNodeOutlet></ng-container>
                  </div>
                </cdk-nested-tree-node>
              </cdk-tree>
            </div>
          </mat-tab>
          <mat-tab id="tab0" label="Templates" *ngIf="_authService.isSuperAdmin()">
            <div class="mt-4">
              <cdk-tree [dataSource]="dataSource" [treeControl]="treeControl">
                <!-- leaf node -->
                <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="example-tree-node" #nestedTreeNodeElement>
                  <button mat-icon-button disabled *ngIf="node.clientId == 0"></button>
                  <button mat-button (click)="onRoleSelected(node)" class="d-inline-flex" [ngClass]="{ 'selected-category': selectedRole?.id == node.id }" *ngIf="node.clientId == 0">{{node.displayName}}</button>
                </cdk-nested-tree-node>
                <!-- parent node -->
                <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChild" class="example-tree-node" #nestedTreeNodeElement>
                  <button mat-icon-button [attr.aria-label]="'Toggle ' + node.displayName" cdkTreeNodeToggle *ngIf="node.clientId == 0">
                    <mat-icon class="mat-icon-rtl-mirror">{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}</mat-icon>
                  </button>
                  <button mat-button (click)="onRoleSelected(node)" class="d-inline-flex" [ngClass]="{ 'selected-category': selectedRole?.id == node.id }" *ngIf="node.clientId == 0">{{node.displayName}}</button>
                  <div [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                    <ng-container cdkTreeNodeOutlet></ng-container>
                  </div>
                </cdk-nested-tree-node>
              </cdk-tree>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
   
  </div>
  <div class="col-sm-8" >
   <div class="card-container p-4 h-100">
    <div class="d-flex justify-content-center">
       <div>
        <p class="text-center text-large m-0 fw-600">
          {{selectedRole ? selectedRole.name : 'Select a role to edit'}}
        </p>
        <div class="mt-4 d-flex align-items-center" *ngIf="selectedRole">
          <div [class]="optionRadioSelected === 'permissions'? 'custom-tab-active' :'custom-tab-unactive' " (click)="optionRadioSelected = 'permissions' ">
            <p class="m-0  fw-500">
              Permissions
            </p>
          </div>
          <div [class]="optionRadioSelected === 'settings'? 'custom-tab-active' :'custom-tab-unactive' " (click)="optionRadioSelected = 'settings' ">
            <p class="m-0 fw-500">
              Settings
            </p>
          </div>
        </div>
       </div>
    </div>
   
    <div *ngIf="selectedRole" class="mt-5">
      
      <div class="row" *ngIf="optionRadioSelected === 'permissions'">
        <div class="col-6">
          <ul >
            <li *ngFor="let permission of selectedPermissions.slice(0, selectedPermissions.length/2)">
              <mat-checkbox [(ngModel)]="permission.selected" color="primary" class="text-regular mb-4"> {{permission.description}}</mat-checkbox>
            </li>
          </ul>
        </div>
        <div class="col-6">
          <ul >
            <li *ngFor="let permission of selectedPermissions.slice(selectedPermissions.length/2)">
              <mat-checkbox [(ngModel)]="permission.selected" color="primary" class="text-regular mb-4"> {{permission.description}}</mat-checkbox>
            </li>
          </ul>
        </div>
      </div>
     
      <div *ngIf="optionRadioSelected === 'settings'">
        <table class="w-100">
          <tr>
            <th class="pb-4"> Description </th>
            <th class="pb-4"> Purpose </th>
            <th class="pb-4"> Option </th>
            <th class="pb-4"> AdditionalOption </th>
          </tr>
          <ng-container *ngFor="let setting of selectedSettings">
            <tr  *ngIf="!isSettingHidden(setting)">
              <td> {{setting.description}} </td>
              <td> {{setting.relevantPurpose}} </td>
              <td class="pb-4">
                <mat-form-field class="bottom-border width-set">
                  <mat-label>Select an option</mat-label>
                  <mat-select [(ngModel)]="setting.settingOptionGroupChoiceId" (selectionChange)="checkOption(setting);">
                    <mat-option [value]="0">Select Option</mat-option>
                    <mat-option *ngFor="let option of setting.settingOptionGroup.settingOptionGroupChoice" [value]="option.id">{{option.description}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td class="pb-4">
                <mat-form-field class="bottom-border width-set" *ngIf="setting.settingOptionGroupChoiceId !== 0 && setting.settingOptionGroupChoice.additionalValueSelectType === 'locationtypecode'">
                  <mat-label>Select a second option</mat-label>
                  <mat-select [(ngModel)]="setting.optionValue">
                    <mat-option [value]="0">Select Tier</mat-option>
                    <mat-option *ngFor="let code of locationTypeCodes" [value]="code.id">{{code.value}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </tr>
          </ng-container>
        </table>
      </div>
      <div class="d-flex justify-content-center m-t">
        <button mat-raised-button color="primary" class="primary-action-btm px-5" (click)="saveRoles()">Save Role</button>
      </div>
    </div>
   </div>
  </div>
</div>

