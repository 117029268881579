<button mat-icon-button class="close-button" (click)="onDismiss()">
  <mat-icon class="close-icon" >close</mat-icon>
</button>
<h1 class="heading-text" mat-dialog-title>{{title}}</h1>
<form #form="ngForm" >
  <div class="custom-input-box create-role-width-set">
    <input matInput type="text" [(ngModel)]="newRole.name" #name="ngModel" name="name" placeholder="Name" required />
  </div>
  <mat-error *ngIf="name.invalid">Name is required.</mat-error>
  <div class="custom-input-box create-role-width-set mt-4 pt-1">
    <mat-select placeholder="Parent Role" [(value)]="newRole.parentRoleId" #parentRole name="parent" id="parent">
      <mat-option value="''"> No Parent </mat-option>
      <mat-option [value]="myRole"> My Role </mat-option>
      <mat-option *ngFor="let role of mainRolesList" [value]="role.id">{{role.name}}</mat-option>
    </mat-select>
  </div>
  <div class="custom-input-box create-role-width-set mt-4 pt-1">
    <mat-select placeholder="Copy Settings From" [(value)]="copyFromRoleId" #copyFromRole name="copyFromRole" id="copyFromRole">
      <mat-option *ngFor="let role of mainRolesList" [value]="role.id">{{role.name}}</mat-option>
    </mat-select>
  </div>
</form>

<div class="d-flex align-items-center mt-4 pt-1">
  <button mat-raised-button color="primary" class="submit-btn" (click)="onConfirm()" [disabled]="form.pristine || form.invalid ">Submit</button>
  <button mat-button class="clear-btn ml-3" (click)="onDismiss()">Clear</button>
</div>

