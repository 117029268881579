<style>
  .mat-app-background {
    margin-right: 1em;
  }

  .cdk-overlay-pane {
    width: 40% !important;
  }

  .bootstrap-table .table, .fixed-table-body .table, .table {
    margin-bottom: 1em;
  }

  .mat-dialog-container {
    padding-top: 0 !important;
  }

  .mat-dialog-actions {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mat-dialog-title {
    margin-top: -24px !important;
    margin-right: -24px !important;
    margin-left: -24px !important;
    background-color: #3F51B5 !important;
  }

  .dialog-title {
    color: #ffffff;
    margin-left: 10px;
    margin-top: 5px;
    font-size: 16px
  }

  .dialog-font{
    font-size:14px
  }
</style>
<h6 mat-dialog-title><span class="dialog-title">Copy Commission </span></h6>


<div class="mat-app-background">
  <div class="mat-elevation-z8" style="padding-top: 1em; padding-left: 1em; padding-right:1em;">


    <div mat-dialog-content>
      <div class="row">
        <label class="col-2 form-control-label dialog-font">From:</label>
        <div class="col-10">
          <p class="form-control-static" style="font-size:16px">
            {{commissionUser.name}}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <label class=" form-control-label dialog-font">To:</label>
        </div>
        <div class="col-10">
          <mat-form-field class="location-dropdown margin-top-1em dialog-font" *ngIf="stores && stores.length > 0 ">
            <mat-select [ngClass]="[stores.length <= 1 ?  'mat-select-color-disabled' : 'mat-select-color']"  placeholder="Store" [(ngModel)]="selectedStoreId" [(value)]="selectedStoreId" (selectionChange)="onStoreSelected()" [disabled]=" stores.length <= 1">
              <mat-option [value]="0">Select All</mat-option>
              <mat-option *ngFor="let store of stores" [value]="store.id">{{store.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="location-dropdown margin-top-1em dialog-font" *ngIf="filteredEmployees && filteredEmployees.length > 0 ">
            <mat-select [formControl]="selectedEmployees" multiple [disabled]="filteredEmployees.length < 1" placeholder="Employee(s)" [ngClass]="[filteredEmployees.length <= 1 ?  'mat-select-color-disabled' : 'mat-select-color']">
              <mat-option *ngFor="let employee of filteredEmployees" [value]="employee">{{employee.user.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="form-group row">

        <!--<label class="col-sm-4 form-control-label">To:</label>
    <div class="col-sm-8">
      <p class="form-control-static">
        <select class="form-control" name="copiedCommissionUserEmployeeId" [(ngModel)]="copiedCommissionUserEmployeeId">
          <option *ngFor="let employee of locationEmployees" [value]="employee.id">{{employee.user.name}}</option>
        </select>
      </p>
    </div>-->
      </div>
      <div class="form-group row">
        <label class="col-2 form-control-label dialog-font">Month/Year</label>
        <div class="col-10">
          <mat-form-field >
            <mat-label class="form-label semibold" style="max-width:100px">Duration</mat-label>
            <input matInput type="month" style="max-width:150px" [(ngModel)]="copiedCommissionUserDuration" name="copiedCommissionUserDuration">
          </mat-form-field>
        </div>
        <!--<label class="col-sm-4 form-control-label">Month/Year</label>
      <div class="col-sm-8">
        <p class="form-control-static">
          <input type="month" class="form-control" [(ngModel)]="copiedCommissionUserDuration" name="copiedCommissionUserDuration" />
        </p>
      </div>-->
      </div>
      <div class="form-group row">
        <div class="col-sm-12">
          <p class="form-control-static">
            <button type="button" mat-raised-button color="primary" style="margin-left:30px;height:40px" [disabled]="(!selectedEmployees || !selectedEmployees.value || selectedEmployees.value.length == 0) || !copiedCommissionUserDuration" (click)="copyUserConfirm();">Copy</button>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
