import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  constructor() { }

  sorting(array, columns, order_by) {
    columns = [columns];
    order_by = [order_by];

    if (typeof columns === 'undefined') {
      columns = array.map((element, index) => index);
    }

    if (typeof order_by === 'undefined') {
      order_by = array.map(() => true); // true = ASC
    }

    function stringToAscii(s) {
      let ascii = '';
      if (s.length > 0) {
        for (let i = 0; i < s.length; i++) {
          let c = '' + s.charCodeAt(i);
          while (c.length < 3) {
            c = '0' + c;
          }
          ascii += c;
        }
      }
      return (ascii);
    }

    function sort_recursive(a, b, col, order, index) {

      const direction = order[index] === false ? 1 : 0; // false = DESC
      const is_numeric = !isNaN(a[col[index]] - b[col[index]]);

      let x = is_numeric ? a[col[index]] : a[col[index]].toLowerCase();
      let y = is_numeric ? b[col[index]] : b[col[index]].toLowerCase();

      if (!is_numeric) {
        x = stringToAscii(a[col[index]].toLowerCase()),
          y = stringToAscii(b[col[index]].toLowerCase());
      }

      if (x < y) {
        return direction === 0 ? -1 : 1;
      }

      if (x === y) {
        return col.length - 1 > index ? sort_recursive(a, b, col, order, index + 1) : 0;
      }

      return direction === 0 ? 1 : -1;
    }

    return array.sort(function (a, b) {
      return sort_recursive(a, b, columns, order_by, 0);
    });
  }
}
