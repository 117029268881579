import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../../core/services/auth.service';
import { ClientService } from '../services/client.service';
import { LocationService } from '../services/location.service';
import { CommissionReconcileService } from '../services/commission-reconcile.service';
import { LocationTypeCode } from '../models/location-type-code';
import { EmployeeService } from '../services/employee.service';
import { Employee } from '../models/employee';
import { ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TableFilterPipe } from '../../tools/table-filter.pipe';
import { DurationService } from '../services/duration.service';
import { Duration } from '../models/duration';
import { FormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from '../datascape-reconcile/datascape-reconcile.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatTabGroup } from '@angular/material/tabs';
import { MatSelectChange } from '@angular/material/select';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AllDataTableComponent } from './all-data-table/all-data-table.component';
import { SpiffsDataTableComponent } from './spiffs-data-table/spiffs-data-table.component';
import { ChargebacksDataTableComponent } from './chargebacks-data-table/chargebacks-data-table.component';
import { DismissedDataTableComponent } from './dismissed-data-table/dismissed-data-table.component';
import { DiscrepanciesAllDataTableComponent } from './discrepancies-all-data-table/discrepancies-all-data-table.component';
import { DiscrepanciesDataTableComponent } from './discrepancies-data-table/discrepancies-data-table.component';
import { ChargedNoInputDataTableComponent } from './charged-no-input-data-table/charged-no-input-data-table.component';
import { LoaderService } from '../services/loader.service';

enum ViewModes {
  All = 0,
  Spiffs = 1,
  Dismissed = 2,
  Chargebacks = 3,
  Discrepancies = 4,
  DiscrepanciesAll = 5
}
@Component({
  selector: 'app-commission-reconcile',
  templateUrl: './commission-reconcile.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }, TableFilterPipe
  ]
})
export class CommissionReconcileComponent implements OnInit, OnDestroy {

  @ViewChild("tabGroup") tabGroup: MatTabGroup;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(AllDataTableComponent) allDataTableComponent: AllDataTableComponent;
  @ViewChild(SpiffsDataTableComponent) spiffsDataTableComponent: SpiffsDataTableComponent;
  @ViewChild(ChargebacksDataTableComponent) chargebacksDataTableComponent: ChargebacksDataTableComponent;
  @ViewChild(DismissedDataTableComponent) dismissedDataTableComponent: DismissedDataTableComponent;
  @ViewChild(DiscrepanciesAllDataTableComponent) discrepanciesAllDataTableComponent: DiscrepanciesAllDataTableComponent;
  @ViewChild(DiscrepanciesDataTableComponent) discrepanciesDataTableComponent: DiscrepanciesDataTableComponent;
  @ViewChild(ChargedNoInputDataTableComponent) chargedNoInputDataTableComponent: ChargedNoInputDataTableComponent;

  viewModes = ViewModes;

  selectedRow = new Set<any>();


  debug = true;
  imeiList: any;
  savedImeiList: any;
  chargebackSummary: any;
  selectedImei: any;
  selectedRadio = 1;
  doneLoading = false;
  sumDifference = 0;
  selectedViewMode = 1;
  currentRebateList: any = [];
  savedRebateList: any = [];
  filterDifference: number;
  filterImei: number;
  filterMtn: number;
  chargeBackForm: number;
  monthYear: string;
  totalDismissed = 0;
  totalChargedBack = 0;
  totalKeepers = 0;
  dismissedList: any = [];
  chargebackList: any = [];
  keeperList: any = [];
  keeperOldList: any = [];
  allAreSelected = false;
  employeeList: Employee[] = [];
  storeSubscription: Subscription;
  clients: any[] = [];
  regions: any[];
  selectedClientId: number;
  selectedClient: any;
  selectedRegionId: number;
  selectedRegion: any;
  districts: any[];
  selectedDistrictId: number;
  selectedDistrict: any;
  stores: any[];
  selectedStoreId: number;
  selectedStore: any;
  lastSelectedDurationForStoreAndEmployeeReport: any;
  singleOtherDurationSelection: boolean;
  multipleOtherDurationSelection: boolean;
  customDurationSelection: boolean;
  fetchReportInProgress: boolean;
  selectedOtherDuration: any;
  customRangeStartDate: any;
  customRangeEndDate: any;
  uploadStatus: string;
  localStorageLoadingInProgress: boolean;
  invoiceIsLoading = false;
  isAscendingOrder = true;
  clientName: string;
  regionName: string;
  districtName: string;
  storeName: string;
  table: any = {
    sorting: true,
    toggleColumns: true
  };
  pageObject: any = {
    isDataLocked: false
  }

  soldByName = '';
  imei = '';
  mtn = '';
  difference = 0;

  subscriptionToLocations: Subscription;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private _notifier: NotifierService,
    private _locationService: LocationService,
    private _clientService: ClientService,
    private _employeeService: EmployeeService,
    private _commissionReconcileService: CommissionReconcileService,
    private _authService: AuthService,
    private _tableFilter: TableFilterPipe,
    private _durationService: DurationService,
    private _loaderService: LoaderService) {
  }

  durations: Duration[];
  months: any;
  duration: number = 0;
  selectedDuration: Duration;
  selectedMonth: any;

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
  }

  ngOnInit() {
    this._loaderService.show();
    this.durations = this._durationService.getDuration();
    this.months = this._durationService.getMonths();
    this.duration = 3;

    this.selectedDuration = this.durations.find(x => x.value === this.duration);
    let tempDate = new Date(this.selectedDuration.duration.FromDate);

    this.monthYear = '0' + (tempDate.getMonth() + 1) + tempDate.getFullYear();
    if (this.monthYear.length > 6) this.monthYear = this.monthYear.substring(1, 7);
    this.subscriptionToLocations = this._locationService.currentLocations
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        if (message != null) {
          this.selectedClientId = message[1];
          this.selectedRegionId = message[2];
          this.selectedDistrictId = message[3];
          this.selectedStoreId = message[4];
          this.onClientSelected();
          this.getCommissionReport();
        }
      });

  }



  //getEmployees(stores?: any[]) {
  //  this._employeeService.getEmployeeUsersByLocations(stores.map(function (store) { return store.id; }), (employeeResponse) => {
  //    this.employeeList = employeeResponse;
  //  });
  //}

  //getClients() {
  //  this._locationService.getLocations(LocationTypeCode.Client, (response) => {
  //    this.clients = response;
  //  });
  //}

  onClientSelected() {
    if (+this.selectedClientId === 0) {
      return;
    }
    this.selectedClientId = +this.selectedClientId;
    this.selectedClient = this.clients.find(r => r.id === this.selectedClientId);
    //this._locationService.getLocations(LocationTypeCode.Region, (response) => {
    //  this.regions = response;
    //}, this.selectedClientId);
  }

  /**Duration */

  durationChanged(event: MatSelectChange) {
    this.selectedDuration = this.durations.find(x => x.value === event.value);
    let tempDate = new Date(this.selectedDuration.duration.FromDate);
    this.monthYear = '0' + (tempDate.getMonth() + 1) + tempDate.getFullYear();
    if (this.monthYear.length > 6) this.monthYear = this.monthYear.substring(1, 7);
    if (this.duration != 5 && this.duration != 6) {
      this.getCommissionReport();
    }
  }

  date = new FormControl(moment());

  yearHandler(year: moment.Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(year.year());
    this.date.setValue(ctrlValue);
  }

  monthHandler(month: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(month.month());
    ctrlValue.year(month.year());
    this.date.setValue(ctrlValue);

    datepicker.close();
    this.selectedDuration.duration.FromDate = month.startOf("month").format(this._durationService.dateStartFormat).toString();
    this.selectedDuration.duration.ToDate = month.endOf("month").format(this._durationService.dateEndFormat).toString();
    let tempDate = new Date(this.selectedDuration.duration.FromDate);
    this.monthYear = '0' + (tempDate.getMonth() + 1) + tempDate.getFullYear();
    if (this.monthYear.length > 6) this.monthYear = this.monthYear.substring(1, 7);
    this.getCommissionReport();

  }
  getCommissionReportUpdated($event) {
    this.getCommissionReport();
  }
  selectedTabIndex: 0;

  tabClick($event) {
    this.tabGroup.realignInkBar();
    var tabLabel = $event.tab.textLabel;
    switch (tabLabel) {
      case "All":
        this.selectedViewMode = 1; break;
      case "Dismissed":
        this.selectedViewMode = 3; break;
      case "Chargebacks":
        this.selectedViewMode = 4; break;
      case "Discrepancies":
        this.selectedViewMode = 5; break;
      case "Past Discrepancies":
        this.selectedViewMode = 6; break;
      //case "Spiffs":
      //  this.selectedViewMode = 7; break;
      //case "ChargedNoInput":
      //  this.selectedViewMode = 8; break;
    }
    this.selectedImei = null;

    this.updateChildComponentsOnClick();
  }
  updateSelectedImei($event) {
    this.selectedImei = $event;
  }


  updateChildComponents() {
    this.tabGroup.realignInkBar();
    if (this.allDataTableComponent) {
      this.allDataTableComponent.updateDataSource(this.imeiList.devicesRemaining, "Default");
      this.allDataTableComponent.setDifferenceList(this.imeiList.stats.frequentDifferences);
    }
    if (this.dismissedDataTableComponent) {
      this.dismissedDataTableComponent.updateDataSource(this.imeiList.devicesDismissed);
    }
    if (this.chargebacksDataTableComponent) {
      this.chargebacksDataTableComponent.updateDataSource(this.imeiList.devicesChargeback, this.imeiList.devicesChargebackDetails);
    }

    if (this.discrepanciesDataTableComponent) {
      this.discrepanciesDataTableComponent.updateDataSource(this.imeiList.devicesDiscrepancy);
    }

    if (this.discrepanciesAllDataTableComponent) {
      this.discrepanciesAllDataTableComponent.updateDataSource(this.imeiList.devicesPendingDiscrepancy);
      this.discrepanciesAllDataTableComponent.setCurrentDuration(this.selectedDuration.duration.FromDate.substr(5, 2) + this.selectedDuration.duration.FromDate.substr(0, 4));
    }
  }

  updateChildComponentsOnClick() {
    this.tabGroup.realignInkBar();
    if (this.allDataTableComponent && this.selectedViewMode == 1) {
      if (this.selectedRadio == 1) {
        this.allDataTableComponent.updateDataSource(this.imeiList.devicesRemaining, "Default");
        this.allDataTableComponent.setDifferenceList(this.imeiList.stats.frequentDifferences);
      }
      if (this.selectedRadio == 2) {
        this.allDataTableComponent.updateDataSource(this.imeiList.protectionRemaining, "Protection");
        this.allDataTableComponent.setDifferenceList(this.imeiList.stats.frequentDifferencesTmp);
      }
      if (this.selectedRadio == 3) {
        this.allDataTableComponent.updateDataSource(this.imeiList.tradeRemaining, "Trades");
        this.allDataTableComponent.setDifferenceList(this.imeiList.stats.frequentDifferencesTrades);
      }
    }

    if (this.dismissedDataTableComponent && this.selectedViewMode == 3) {
      if (this.selectedRadio == 1) {
        this.dismissedDataTableComponent.updateDataSource(this.imeiList.devicesDismissed);

      }
      if (this.selectedRadio == 2) {
        this.dismissedDataTableComponent.updateDataSource(this.imeiList.protectionDismissed);

      }
      if (this.selectedRadio == 3) {
        this.dismissedDataTableComponent.updateDataSource(this.imeiList.tradeDismissed);

      }
    }

    if (this.chargebacksDataTableComponent && this.selectedViewMode == 4) {
      if (this.selectedRadio == 1) {
        this.chargebacksDataTableComponent.updateDataSource(this.imeiList.devicesChargeback, this.imeiList.devicesChargebackDetails);
      }
      if (this.selectedRadio == 2) {
        this.chargebacksDataTableComponent.updateDataSource(this.imeiList.protectionChargeback, this.imeiList.protectionChargebackDetails);
      }
      if (this.selectedRadio == 3) {
        this.chargebacksDataTableComponent.updateDataSource(this.imeiList.tradeChargeback, this.imeiList.tradeChargebackDetails);
      }
    }

    if (this.discrepanciesDataTableComponent && this.selectedViewMode == 5) {
      if (this.selectedRadio == 1) {
        this.discrepanciesDataTableComponent.updateDataSource(this.imeiList.devicesDiscrepancy);
      }
      if (this.selectedRadio == 2) {
        this.discrepanciesDataTableComponent.updateDataSource(this.imeiList.protectionDiscrepancy);
      }
      if (this.selectedRadio == 3) {
        this.discrepanciesDataTableComponent.updateDataSource(this.imeiList.tradeDiscrepancy);
      }
    }

    if (this.discrepanciesAllDataTableComponent && this.selectedViewMode == 6) {
      this.discrepanciesAllDataTableComponent.setCurrentDuration(this.selectedDuration.duration.FromDate.substr(5, 2) + this.selectedDuration.duration.FromDate.substr(0, 4));
      if (this.selectedRadio == 1) {
        this.discrepanciesAllDataTableComponent.updateDataSource(this.imeiList.devicesPendingDiscrepancy);
      }
      if (this.selectedRadio == 2) {
        this.discrepanciesAllDataTableComponent.updateDataSource(this.imeiList.protectionPendingDiscrepancy);
      }
      if (this.selectedRadio == 3) {
        this.discrepanciesAllDataTableComponent.updateDataSource(this.imeiList.tradePendingDiscrepancy);
      }
    }

  }


  selectOne(imei, e) {
    imei.isSelected = e.target.checked;
  }



  changeRebateType() {
    this.updateChildComponentsOnClick();
  }




  resetFilter() {
    this.filterDifference = null;
    this.filterMtn = null;
    this.filterImei = null;
  }


  selectImei(imei) {
    this.selectedImei = imei;
  }

  finalizeData() {
    this._commissionReconcileService.lockData(this.selectedClientId, this.selectedDuration.duration.FromDate.substr(5, 2) + this.selectedDuration.duration.FromDate.substr(0, 4), true).subscribe((response) => {
      if (response == false) {
        this.pageObject.isDataLocked = false;
        this._notifier.notify('error',
          "There was a problem finalizing the report. Check for Warnings in the chargeback details");
      } else {
        this._notifier.notify('success', "Locked data imports and finalize chargebacks.");
      }
      //this.pageObject.isDataLocked = response;
    },
      (error) => {
        this._loaderService.hide();
        this._notifier.notify('error', error.error);
      }
      , () => { this._loaderService.hide(); }
    );
  }

  getCommissionReport() {
    this._loaderService.show();
    if (this.selectedDuration) {
      const dateToSend = this.selectedDuration.duration.FromDate.substr(5, 2) + this.selectedDuration.duration.FromDate.substr(0, 4);
      let locationToSend = 0;
      if (this.selectedStoreId !== 0) {
        locationToSend = this.selectedStoreId;
      } else if (this.selectedDistrictId !== 0) {
        locationToSend = this.selectedDistrictId;
      } else if (this.selectedRegionId !== 0) {
        locationToSend = this.selectedRegionId;
      }
      this._commissionReconcileService.getCommissionReport(locationToSend, dateToSend)
        .subscribe((response: any[]) => {
          this.imeiList = response;
          this.doneLoading = true;
          this.updateChildComponentsOnClick();
          this.chargebacksDataTableComponent.setSummaryData([this.imeiList.devicesChargebackDetails, this.imeiList.protectionChargebackDetails, this.imeiList.tradeChargebackDetails])
          this._commissionReconcileService.getIsDataLocked(this.selectedClientId, this.selectedDuration.duration.FromDate.substr(5, 2) + this.selectedDuration.duration.FromDate.substr(0, 4), false).subscribe((response) => {
            this.pageObject.isDataLocked = response;
          })
        },
          (error) => {
            this._loaderService.hide();
            this._notifier.notify('error', error.error);
          }
          , () => { this._loaderService.hide(); }
        );


    }
  }







  getFormattedDateTime(unformattedDateTime) {
    this.uploadStatus = `${moment(unformattedDateTime).format('L')} ${moment(unformattedDateTime).format('LT')}`;
  }

}
