import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../../core/services/auth.service';
import { ClientService } from '../services/client.service';
import { LocationService } from '../services/location.service';
import { CommissionReconcileService } from '../services/commission-reconcile.service';
import { LocationTypeCode } from '../models/location-type-code';
import { ProductSearchDialogComponent } from "../dialogs/product-search/product-search-dialog.component";
import { EmployeeService } from '../services/employee.service';
import { LoaderService } from '../services/loader.service';
import { Employee } from '../models/employee';
import { ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelectChange } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { NotesDialogComponent } from '../dialogs/notes/notes-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ConfirmDialogComponent } from '../dialogs/confirm/confirm-dialog.component';
import { DurationService } from '../services/duration.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import { Duration } from "../models/duration";
import { MY_FORMATS } from "../datascape-reconcile/datascape-reconcile.component";
import { TableFilterPipe } from "../../tools/table-filter.pipe";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';

export class Filters {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-ccrs-reconcile',
  templateUrl: './ccrs-reconcile.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }, TableFilterPipe
  ]
})
export class CCRSReconcileComponent implements OnInit, OnDestroy {

  @ViewChild('CCRSTable', { read: ElementRef }) CCRSTable: ElementRef;
  searchTerm = '';
  columnUnderSorting = 0;
  daysToIgnore = 0;
  filterDate: Date;
  todayDate: Date;
  recentDataDate: string;
  imeiList: any;
  savedImeiList: any;
  selectedImei: any;
  selectedRadio = 1;
  doneLoading = false;
  sumDifference = 0;
  selectedViewMode = 1;
  currentRebateList: any = [];
  savedRebateList: any = [];
  filterDifference: number;
  filterImei: number;
  filterMtn: number;
  chargeBackForm: number;
  monthYear: string;
  totalDismissed = 0;
  totalChargedBack = 0;
  totalKeepers = 0;
  dismissedList: any = [];
  chargebackList: any = [];
  keeperList: any = [];
  keeperOldList: any = [];
  allAreSelected = false;
  employeeList: Employee[] = [];
  storeSubscription: Subscription;
  clients: any[] = [];
  durations: any[];
  regions: any[];
  selectedClientId: number;
  selectedClient: any;
  selectedRegionId: number;
  selectedRegion: any;
  districts: any[];
  selectedDistrictId: number;
  selectedDistrict: any;
  stores: any[];

  selectedStoreId: number;
  selectedStore: any;
  locationToSend: number;
  lastSelectedDurationForStoreAndEmployeeReport: any;
  singleOtherDurationSelection: boolean;
  multipleOtherDurationSelection: boolean;
  customDurationSelection: boolean;
  fetchReportInProgress: boolean;
  selectedOtherDuration: any;
  customRangeStartDate: any;
  customRangeEndDate: any;
  uploadStatus: string;
  localStorageLoadingInProgress: boolean;
  invoiceIsLoading = false;
  isAscendingOrder = true;
  clientName: string;
  regionName: string;
  districtName: string;
  storeName: string;
  table: any = {
    sorting: true,
    toggleColumns: true
  };
  noteToAdd: string;
  productName: '';
  imei: '';
  difference: 0;
  recentSaleDate: '';
  vzInstallments: 0;
  rqInstallments: 0;
  tmpExpectedQTY: 0;
  rqtmpRebatesQTY: 0;
  rqtmpRebates: 0;
  otherExpectedQTY: 0;
  rqOtherRebatesQTY: 0;
  rqOtherRebates: 0;
  showReconciled = false;
  isLoading = false;
  subscriptionToClients: Subscription;
  subscriptionToRegions: Subscription;
  subscriptionToDistricts: Subscription;
  subscriptionToStores: Subscription;
  lastFilter: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private _notifier: NotifierService,
    private _locationService: LocationService,
    private _clientService: ClientService,
    private _employeeService: EmployeeService,
    private _commissionReconcileService: CommissionReconcileService,
    private _authService: AuthService,
    private _loaderService: LoaderService, private dialog: MatDialog, private _durationService: DurationService) { }

  @ViewChild(MatPaginator) ccrsTablePaginator: MatPaginator;
  @ViewChild(MatSort) ccrsTableSort: MatSort;
  dataSource = new MatTableDataSource();
  rqDataSource = new MatTableDataSource();
  months: any;
  moneySymbol = '$';
  applyFilter(filterValue: string) {
    this.lastFilter = filterValue;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  iconDefinition = [
    { toolTip: "Missing Data", color: "orange", code: 0 },
    { toolTip: "Incorrect", color: "red", code: 1 },
    { toolTip: "Balanced", color: "green", code: 2 },
    { toolTip: "Extra", color: "cyan", code: 3 },
  ];

  columnDefinations = [
    { name: "Status", isSortable: true, mappedToProperty: "icon", class: "first-column", isFilterable: true, sticky: false, hide: false },
    { name: "Date", isSortable: true, mappedToProperty: "recentSaleDate", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Difference", isSortable: true, mappedToProperty: "difference", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Rebate", isSortable: true, mappedToProperty: "vzInstallments", class: "price", isFilterable: true, sticky: false, hide: false },
    { name: "Expected", isSortable: true, mappedToProperty: "rqInstallments", class: "price", isFilterable: true, sticky: false, hide: false },
    { name: "OtherExpected", isSortable: true, mappedToProperty: "rqOtherRebates", class: "price", isFilterable: true, sticky: false, hide: true },
    { name: "Name", isSortable: true, mappedToProperty: "productName", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "IMEI", isSortable: true, mappedToProperty: "imeiForDisplay", class: "", isFilterable: true, sticky: false, hide: false },



    { name: "Reconciled", isSortable: true, mappedToProperty: "hidden", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Notes", isSortable: true, mappedToProperty: "notes", class: "", isFilterable: true, sticky: false, hide: false }
    //  { name: "VZTmp#", isSortable: true, mappedToProperty: "tmpExpectedQTY", class: "", isFilterable: true, sticky: false, hide: false },
    //  { name: "RQTmp#", isSortable: true, mappedToProperty: "rqtmpRebatesQTY", class: "", isFilterable: true, sticky: false, hide: false },
    //  { name: "RQTmp$", isSortable: true, mappedToProperty: "rqtmpRebates", class: "", isFilterable: true, sticky: false, hide: false },
    //  { name: "VZOther#", isSortable: true, mappedToProperty: "otherExpectedQTY", class: "", isFilterable: true, sticky: false, hide: false },
    //  { name: "RQOther#", isSortable: true, mappedToProperty: "rqOtherRebatesQTY", class: "", isFilterable: true, sticky: false, hide: false },
    //  { name: "RQOther$", isSortable: true, mappedToProperty: "rqOtherRebates", class: "", isFilterable: true, sticky: false, hide: false }
  ];

  rqColumnDefinations = [
    { name: "Item", isSortable: true, mappedToProperty: "productName", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Total Rebate", isSortable: true, mappedToProperty: "totalRebate", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Relevant Invoice", isSortable: true, mappedToProperty: "vendorRebateInvoice", class: "", isFilterable: true, sticky: false, hide: false }, //todo toFixed(2)
    { name: "Model", isSortable: true, mappedToProperty: "relatedProductName", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Date", isSortable: true, mappedToProperty: "dateSold", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Contract", isSortable: true, mappedToProperty: "contractNumber", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Tracking Number", isSortable: true, mappedToProperty: "trackingNumber", class: "", isFilterable: true, sticky: false, hide: false },
  ];

  verizonColumnDefinations = [
    { name: "Model", isSortable: true, mappedToProperty: "model", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Mtn", isSortable: true, mappedToProperty: "mobileID", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Imei", isSortable: true, mappedToProperty: "imei", class: "", isFilterable: true, sticky: false, hide: false }, //todo toFixed(2)
    { name: "DP", isSortable: true, mappedToProperty: "devicePayment", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "DPSF", isSortable: true, mappedToProperty: "dpaServiceFee", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Start", isSortable: true, mappedToProperty: "activationString", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Deact", isSortable: true, mappedToProperty: "deactivationString", class: "", isFilterable: true, sticky: false, hide: false },
  ];



  findLatestDateOfNonPOSData(imeiList: any) {
    var tempDate;
    var newToDate;
    if (this.imeiList.items.length > 0) {
      //imeiList.forEach(x => {
      //  if (x.recentExternalSalesDate && (!tempDate || x.recentExternalSalesDate > tempDate))
      //    tempDate = x.recentExternalSalesDate;
      //});
      tempDate = this.imeiList.latestDate;
      newToDate = new Date(parseInt(tempDate.substr(0, 4)), parseInt(tempDate.substr(5, 2)) - 1, parseInt(tempDate.substr(8, 2)));
    }
    if (!newToDate || newToDate.getFullYear() == 1) {
      newToDate = this.convertStringToDate(this.selectedDuration.duration.FromDate);
      newToDate.setDate(1);
    }
    this.filterDate = newToDate;
    this.recentDataDate = moment(newToDate).format(this._durationService.format1);
  }

  findIconType(element: any) {
    //return element['vzInstallments'] > element['rqInstallments'] ? this.iconDefinition[3] : element['rqInstallments'] > 0 && element['vzInstallments'] == 0 ? this.iconDefinition[0] : element['difference'] > 0 ? this.iconDefinition[1] : element['difference'] == 0 ? this.iconDefinition[2] : this.iconDefinition[1];
    return element['vzInstallments'] == element['rqInstallments'] ? this.iconDefinition[2] : this.iconDefinition[1];
  }

  getIconColor(element: any) {
    return this.findIconType(element).color;
  }

  getIconHover(element: any) {
    return this.findIconType(element).toolTip;
  }

  getVerizonDisplayedColumns(): string[] {
    return this.verizonColumnDefinations.filter(cd => !cd.hide).map(cd => cd.mappedToProperty); 8
  }


  getVerizonColumnDefinationByMappedToProperty(mappedToProperty) {
    var column = this.verizonColumnDefinations.find(x => x.mappedToProperty == mappedToProperty);
    return column;
  }

  getRqDisplayedColumns(): string[] {
    return this.rqColumnDefinations.filter(cd => !cd.hide).map(cd => cd.mappedToProperty); 8
  }

  getRqColumnDefinationByMappedToProperty(mappedToProperty) {
    var column = this.rqColumnDefinations.find(x => x.mappedToProperty == mappedToProperty);
    return column;
  }

  getDisplayedColumns(): string[] {
    return this.columnDefinations.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
  }

  getColumnDefinationByMappedToProperty(mappedToProperty) {
    var column = this.columnDefinations.find(x => x.mappedToProperty == mappedToProperty);
    switch (column.name) {
      case 'Difference':
        column.name = 'Difference\n(Rebate-Expected)';
        break;
    }
    return column;
  }

  getMatCellClass(columnDef) {
    var cls = ''
    switch (columnDef.name) {
      case 'Name':
        cls = 'mat-column-ccrsName'
        break;
      default:
        cls = 'mat-cell';
        break;
    }
    return cls;
  }

  getNoteCountText(element) {
    var textToShow = '';
    textToShow = element.notes.length > 0 ? " (+" + (element.notes.length) + " ...)" : "";
    return textToShow;
  }

  getTextBydata(element, columnDef) {
    var textToShow = '';
    switch (columnDef.name) {
      case 'Status':
        textToShow = this.getIconHover(element);
        break;
      case 'Contract':
        textToShow = parseInt(element[columnDef.mappedToProperty]).toFixed(0);
        break;
      case 'Difference\n(Rebate-Expected)':
      case 'Rebate$':
      case 'Expected$':
      case 'Total Rebate':
      case 'DP':
      case 'DPSF':
        textToShow = (element[columnDef.mappedToProperty]).toFixed(2);
        textToShow = this.moneySymbol + textToShow;
        break;
      case 'Relevant Invoice':
        textToShow = (element[columnDef.mappedToProperty]['invoiceNumber']);
        break;
      case 'Date':
      case 'Start':
        let dt = new Date(element[columnDef.mappedToProperty]);
        textToShow = moment(dt).format(this._durationService.format1);
        //textToShow = (dt.getMonth() + 1) +
        //  "/" + dt.getDate() +
        //  "/" + dt.getFullYear();
        break;
      case 'Model':
      case 'item':
        textToShow = element[columnDef.mappedToProperty].substr(0, 30);
        break;
      case 'Notes':
        if (element.notes && element.notes.length > 0) {
          textToShow = element.notes[element.notes.length - 1].note;
        } else {
          textToShow = "...";
        }
        break;
      case 'IMEI':
        const parse = parseInt(element[columnDef.mappedToProperty]);
        if (isNaN(parse)) {
          return 0;
        }
        textToShow = parse.toFixed(0);
        break;
      case 'Name':
        textToShow = element[columnDef.mappedToProperty]?.substring(0, 15);
        break;
      default:
        textToShow = element[columnDef.mappedToProperty];
        break;
    }
    return textToShow;
  }

  convertStringToDate(stringDate: string) {
    return new Date(parseInt(stringDate.substr(0, 4)), parseInt(stringDate.substr(5, 2)), 0);

  }

  onDurationChanged() {
    let x = this.selectedDuration;
    let newToDate = this.convertStringToDate(this.selectedDuration.duration.FromDate);
    if (moment().month() == newToDate.getMonth()) {
      this.filterDate = this.todayDate;
    } else {
      this.filterDate = newToDate;
    }
    this.getCommissionReport();
  }

  onReportSelected() {
    this.getCommissionReport();
  }
  selectedVerizonColumns = this.getVerizonDisplayedColumns();
  selectedRqColumns = this.getRqDisplayedColumns();
  selectedColumns = this.getDisplayedColumns();
  columnsChanged(event: MatSelectChange) {
    for (var i = 0; i < this.columnDefinations.length; i++) {
      var column = this.selectedColumns.find(x => x == this.columnDefinations[i].mappedToProperty);
      if (column != null) {
        this.columnDefinations[i].hide = false;
      } else {
        this.columnDefinations[i].hide = true;
      }
    }
  }

  filterByProperty(collection, value, key) {
    return collection.filter(o => key.reduce((c, v) => c[v] || {}, o) === value);
  }

  notEqualToZero(element, index, array) {
    return (element.difference !== 0);
  }

  updateDataSource(data: any) {

    let filteredItems = data.items.filter(x => this.checkDates(x.recentSaleDate) && this.showReconciled === x.hidden);
    if (filteredItems) {
      this.selectedImei = filteredItems[0];

      this.dataSource = new MatTableDataSource(filteredItems);

      this.dataSource.paginator = this.ccrsTablePaginator;
      this.dataSource.sort = this.ccrsTableSort;

    }
  }

  // NEED TO MANUALLY DETERMINE EACH COLUMN INSTEAD OF HAVING THE LIST.
  //exportTableToExcel() {
  //  let sheetdata = [];
  //  let firstrow = [];
  //  firstrow.push('productName');
  //  firstrow.push('imeiForDisplay');
  //  firstrow.push('difference');
  //  firstrow.push('recentSaleDate');
  //  firstrow.push('vzInstallments');
  //  firstrow.push('rqInstallments');
  //  sheetdata.push(firstrow);
  //  this.imeiList.items.forEach(y => {
  //    if (this.checkDates(y['recentSaleDate'])) {
  //      let thisrow = [];
  //      thisrow.push(y['productName']);
  //      thisrow.push(y['imeiForDisplay']);
  //      thisrow.push(y['difference'].toFixed(2));
  //      thisrow.push(y['recentSaleDate'].substring(0, 10));
  //      thisrow.push(y['vzInstallments'].toFixed(2));
  //      thisrow.push(y['rqInstallments'].toFixed(2));
  //      sheetdata.push(thisrow);
  //    }
  //  });
  //  let ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetdata);
  //  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //  XLSX.writeFile(wb, 'dailycommissionreconcile.xlsx');
  //}

  private dialogRef: any;

  openNotesDialog(column, element) {
    if (column == 'notes') {
      this.dialogRef = this.dialog.open(NotesDialogComponent,
        {
          autoFocus: true,
          disableClose: false,
          panelClass: 'notes-component',
          data: { element: element, selectedClientId: this.selectedClientId, pageOrigin: 'ccrs' }
        });
      this.dialogRef.afterClosed().subscribe(result => {
        if (result && result.update === true) {
          this.getCommissionReport();
        }
      });
    }
  }


  exportTableToExcel() {
    let sheetData = [];
    let firstRow = [];
    let mapToList = [];
    this.columnDefinations.forEach(x => {
      if (!x.hide) {
        firstRow.push(x.name);
        mapToList.push(x.mappedToProperty);
      }
    });
    sheetData.push(firstRow);
    this.dataSource.filteredData.forEach((y: any) => {
      if (!this.checkDates(y.recentSaleDate)) {

      } else {
        let thisRow = [];
        mapToList.forEach(m => {
          let textToShow = '';
          switch (m) {
            case 'notes':
              textToShow = this.getTextBydata(y, this.getColumnDefinationByMappedToProperty('notes')) + this.getNoteCountText(y);
              break;
            case 'icon':
              textToShow = this.getIconHover(y);
              break;
            default:
              textToShow = y[m];
              break;
          }
          thisRow.push(textToShow);
        });
        sheetData.push(thisRow);
      }
    });
    let ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'BillpayReconciliation.xlsx');
  }


  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
    this.dialogRef?.close();
    this.searchDialogRef?.close();
  }

  filterDays(add: number) {
    this.daysToIgnore += add;
    this.filterDate.setDate(this.filterDate.getDate() - add);
    this.updateDataSource(this.imeiList);
  }
  getDateFromIgnore() {
    return moment(this.filterDate).format(this._durationService.format1);
    //return new Date(this.filterDate.getFullYear(), this.filterDate.getMonth(), this.filterDate.getDate());
  }
  checkDates(dateIn) {
    if (Date.parse(this.filterDate.toDateString()) >= Date.parse(dateIn)) {
      return true;
    }
    return false;
  }

  ngOnInit() {
    this.isLoading = true;
    this.durations = this._durationService.getDuration();
    this.duration = 3;
    this.selectedDuration = this.durations.find(x => x.value === this.duration);
    let tempDate = new Date(this.selectedDuration.duration.FromDate);
    this.monthYear = '0' + (tempDate.getMonth() + 1) + tempDate.getFullYear();
    if (this.monthYear.length > 6) this.monthYear = this.monthYear.substring(1, 7);
    this.filterDate = moment().toDate();
    this.todayDate = moment().toDate();
    this.subscriptionToLocations = this._locationService.currentLocations
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        if (message != null) {
          this.selectedClientId = message[1];
          this.selectedRegionId = message[2];
          this.selectedDistrictId = message[3];
          this.selectedStoreId = message[4];
          this.onClientSelected();
          this.getCommissionReport();
        }
      });


  }




  resetFilter() {
    this.filterDifference = null;
    this.filterMtn = null;
    this.filterImei = null;
  }


  selectImei(imei) {
    this.selectedImei = imei;
  }
  convertNumberToString(amount: number) {
    let response = '';
    response = amount.toFixed(2).toString();
    if (Math.sign(amount) === -1) {
      response = response.replace('-', '($') + ')';
    } else {
      response = '$' + response;
    }
    return response;
  }
  getCommissionReport() {
    if (/*this.selectedStoreId !== 0 &&*/ this.selectedDuration) {
      this.dataSource = new MatTableDataSource();
      if (this.selectedImei && this.selectedImei.vzItems) { this.selectedImei.vzItems = {}; }
      if (this.selectedImei && this.selectedImei.rqItems) { this.selectedImei.rqItems = {}; }
      this._loaderService.show();
      this.doneLoading = false;
      this.isLoading = true;
      let tempDate = new Date(this.selectedDuration.duration.FromDate);
      this.monthYear = '0' + (tempDate.getMonth() + 1) + tempDate.getFullYear();
      if (this.monthYear.length > 6) this.monthYear = this.monthYear.substring(1, 7);
      const dateToSend = this.monthYear;
      let locationToSend = 0;
      if (this.selectedStoreId !== 0 && this.selectedStoreId !== null) {
        locationToSend = this.selectedStoreId;
      } else if (this.selectedDistrictId !== 0) {
        locationToSend = this.selectedDistrictId;
      } else if (this.selectedRegionId !== 0) {
        locationToSend = this.selectedRegionId;
      }
      this.locationToSend = locationToSend;
      this._commissionReconcileService.getCCRSReport(locationToSend, dateToSend)
        .subscribe((response: any[]) => {
          this.imeiList = response;
          this.findLatestDateOfNonPOSData(this.imeiList.items);
          this.doneLoading = true;
          this.updateDataSource(this.imeiList);
        }, (error) => this._notifier.notify('error', error.error), () => { this.isLoading = false; this._loaderService.hide(); });
    }
  }
  getKickers(imei) {
    let sum = 0;
    imei.vzItems.forEach((x) => {
      sum = sum + x.kicker;
    });
    return sum;
  }
  getSpiffs(imei) {
    let sum = 0;
    imei.vzItems.forEach((x) => {
      sum = sum + x.spiff;
    });
    return sum;
  }
  getVzCollected(vzitem) {
    let sum = 0;
    sum = vzitem.basicComm1 + vzitem.basicComm2 + vzitem.devicePayment + vzitem.dpaServiceFee + vzitem.kicker + vzitem.spiff + vzitem.tmp;
    return sum;
  }

  getEmployees(stores?: any[]) {
    this._employeeService.getEmployeeUsersByLocations(stores.map(function (store) { return store.id; }), (employeezResponse) => {
      this.employeeList = employeezResponse;
    });
  }

  getClients() {
    this._locationService.getLocations(LocationTypeCode.Client, (response) => {
      this.clients = response;
    });
  }

  onClientSelected() {
    if (!this.selectedClientId || +this.selectedClientId === 0) {
      return;
    }
    this.selectedClientId = +this.selectedClientId;
    this.selectedClient = this.clients.find(r => r.id === this.selectedClientId);
    this._locationService.getLocations(LocationTypeCode.Region, (response) => {
      this.regions = response;
    }, this.selectedClientId);
  }

  getFormattedDateTime(unformattedDateTime) {
    this.uploadStatus = `${moment(unformattedDateTime).format('L')} ${moment(unformattedDateTime).format('LT')}`;
  }

  toBeReconciled = new Map<string, ToHideReconciled>();
  toBeUnreconciled = new Map<string, ToHideReconciled>();
  collectToReconcile(event: MatCheckboxChange, element) {
    let key = this.selectedClientId + element['imei'];
    let value: ToHideReconciled = { clientId: this.selectedClientId, imei: element['imei'], note: '' };
    if (event.checked) {
      this.toBeReconciled.set(key, value);
      this.toBeUnreconciled.delete(key);
    } else {
      this.toBeReconciled.delete(key);
      this.toBeUnreconciled.set(key, value);
    }
  }

  hideIMEI(serialNumber: any) {
    this._commissionReconcileService.hideIMEI(this.selectedClientId, serialNumber).subscribe((response) => { });
  }

  private reconcileDialog: any;

  openReconcileModal(isReconcile) {
    this.reconcileDialog = this.dialog.open(ConfirmDialogComponent,
      {
        autoFocus: true, disableClose: false, panelClass: '',
        data: { title: "Confirm", message: "Are you sure you want to reconcile selected?" }
      });
    this.reconcileDialog.afterClosed().subscribe(result => {
      if (result === true) {
        if (isReconcile) {
          this._commissionReconcileService.hideImeiMultiple(Array.from(this.toBeReconciled.values())).subscribe(
            (response) => {
              this._notifier.notify('success', 'Reconciled successfully!');
              this.toBeReconciled.clear();
              this.updateDataSource(this.imeiList);
            }, (errorResponse) => { this._notifier.notify('error', errorResponse.error); }, () => { this._loaderService.hide(); });
        } else {
          this._commissionReconcileService.hideImeiMultiple(Array.from(this.toBeUnreconciled.values())).subscribe(
            (response) => {
              this._notifier.notify('success', 'Unreconciled successfully!');
              this.toBeUnreconciled.clear();
              this.updateDataSource(this.imeiList);
            }, (errorResponse) => { this._notifier.notify('error', errorResponse.error); }, () => { this._loaderService.hide(); });
        }
      }
    });
  }
  private searchDialogRef: any;
  openHistoryDialog(element) {
    this.searchDialogRef = this.dialog.open(ProductSearchDialogComponent,
      {
        autoFocus: true,
        disableClose: false,
        panelClass: 'product-search-component',
        data: { element: element }
      });
  }

  addNote(imei: string) {
    this._commissionReconcileService.addNote(this.selectedClientId, imei, this.noteToAdd).subscribe((response) => {

      const thisSerial = this.imeiList.items.find(x => x.imei === imei);
      thisSerial['notes'].push({ 'id': response, 'note': this.noteToAdd });
      this.noteToAdd = "";
    },
      (error) => this._notifier.notify('error', error.error),
      () => { });
  }

  editNote(imei: string, note: string, noteId: number) {
    this._commissionReconcileService.editNote(noteId, note).subscribe((response) => {
      const thisSerial = this.imeiList.items.find(x => x.imei === imei);
      const thisNote = thisSerial['notes'].find(x => x.id === noteId);
      thisNote.note = note;
    },
      (error) => this._notifier.notify('error', error.error),
      () => { });
  }

  deleteNote(imei: string, noteId: number) {
    this._commissionReconcileService.deleteNote(noteId).subscribe((response) => {
      const thisSerial = this.imeiList.items.find(x => x.imei === imei);
      const thisNoteIndex = thisSerial['notes'].findIndex(x => x.id === noteId);
      thisSerial['notes'].splice(thisNoteIndex, 1);

      this.updateDataSource(this.imeiList);
    },
      (error) => this._notifier.notify('error', error.error),
      () => { });
  }

  duration: number = 0;
  subscriptionToLocations: Subscription;
  date = new FormControl(moment());
  selectedDuration: Duration;

  yearHandler(year: moment.Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(year.year());
    this.date.setValue(ctrlValue);
  }

  monthHandler(month: any, datepicker: MatDatepicker<moment.Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(month.month());
    ctrlValue.year(month.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.selectedDuration.duration.FromDate = month.startOf("month").format(this._durationService.dateStartFormat).toString();
    this.selectedDuration.duration.ToDate = month.endOf("month").format(this._durationService.dateEndFormat).toString();
    let tempDate = new Date(this.selectedDuration.duration.FromDate);
    this.monthYear = '0' + (tempDate.getMonth() + 1) + tempDate.getFullYear();
    if (this.monthYear.length > 6) this.monthYear = this.monthYear.substring(1, 7);
    this.getCommissionReport();
  }

}


type ToHideReconciled = {
  clientId: number;
  imei: string;
  note: string;
};
