import { Inject, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean {
    const isSubmitted = form && form.submitted;
    return (control && control.invalid);
  }
}

@Component({
  selector: 'manual-reward',
  templateUrl: 'manual-reward.component.html',
})
export class ManualRewardComponent implements OnInit {
  matcher = new MyErrorStateMatcher();
  title: string;
  earnedRewardModel: any;
  manualRewardDialog: any;
  employees: any;
  employee = new FormControl('', [Validators.required]);
  amount = new FormControl('', [Validators.required, Validators.max(50000)]);
  constructor(public dialogRef: MatDialogRef<ManualRewardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.earnedRewardModel = data.earnedRewardModel;
    this.manualRewardDialog = data.manualRewardDialog;
    this.employees = data.employees;
  }

  ngOnInit() { }

  onConfirm(): void {
    if (this.employee.valid && this.amount.valid) {
      this.data.earnedRewardModel = this.earnedRewardModel; this.data.manualRewardDialog = this.manualRewardDialog; this.dialogRef.close(this.data);
    }
  }

  onDismiss(): void { this.dialogRef.close(false); }
}

