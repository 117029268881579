import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChangePasswordComponent } from './changepassword/changepassword.component';
import { UserComponent } from './user/user.component';
import { RoleComponent } from './role/role.component';
import { AuthGuardService as AuthGaurd } from '../../core/services/auth-gaurd.service';
import { ColumnContentLayoutComponent } from '../../core/column-content-layout/column-content-layout.component';

const routes: Routes = [
  {
    path: '',
    component: ColumnContentLayoutComponent,
    children: [
      {
        path: 'changepassword', component: ChangePasswordComponent, canActivate: [AuthGaurd], data: {
          title: 'Change Password',
          breadcrumb: [
            {
              label: 'Change Password',
              url: ''
            }
          ]
        }, },
      {
        path: 'users', component: UserComponent, canActivate: [AuthGaurd], data: {
          title: 'Users',
          breadcrumb: [
            {
              label: 'User > Users',
              url: ''
            }
          ]
        }, },
      {
        path: 'roles', component: RoleComponent, canActivate: [AuthGaurd], data: {
          title: 'Roles',
          breadcrumb: [
            {
              label: 'User > Roles',
              url: ''
            }
          ]
        },}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
