<style>
  .mat-column-productName {
    max-width: fit-content;
  }
</style>
<div class="d-flex align-items-center action-header" style="flex-wrap: wrap;">
  <mat-form-field class="bottom-border-without-bg filter-action-width">
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" [(ngModel)]="invoiceSearchRequest.SearchTerm">
  </mat-form-field>
  <mat-form-field class="bottom-border-without-bg ml-5 filter-action-width">
    <mat-select placeholder="Duration" [(value)]="duration" (selectionChange)="durationChanged($event)">
      <mat-option *ngFor="let duration of durations" [value]="duration.value">{{duration.viewValue}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="bottom-border-without-bg ml-5 filter-action-width" *ngIf="duration==5">
    <mat-select placeholder="Month" [(value)]="month" (selectionChange)="monthChanged($event)">
      <mat-option *ngFor="let month of months.months" [value]="month">{{month}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="bottom-border-without-bg ml-5 filter-action-width" *ngIf="duration==6">
    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="startDate" [max]="today" placeholder="Choose a start date" (dateChange)="startDateChanged('change', $event)" readonly>
    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
    <mat-datepicker #startDate startView="year" [startAt]="minDate"></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="bottom-border-without-bg ml-5 filter-action-width" *ngIf="duration==6">
    <input matInput [min]="startDate" [max]="maxDate" [matDatepicker]="endDate" [max]="today" placeholder="Choose a end date" (dateChange)="endDateChanged('change', $event)" readonly>
    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
    <mat-datepicker #endDate startView="year" [startAt]="startDate"></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="bottom-border-without-bg ml-5 filter-action-width">
    <mat-select placeholder="Has" [(value)]="invoiceSearchRequest.HasFilter" (selectionChange)="updateDataByFilter()">
      <!--<mat-option [value]="0">Select Filter</mat-option>-->
      <mat-option [value]="0">No Filter</mat-option>
      <mat-option *ngFor="let skugroup of skuGroups" [value]="skugroup.name">{{skugroup.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="bottom-border-without-bg ml-5 filter-action-width">
    <mat-select placeholder="Missing" [(value)]="invoiceSearchRequest.HasNotFilter" (selectionChange)="updateDataByFilter()">
      <!--<mat-option [value]="0">Select Has Not Filter</mat-option>-->
      <mat-option [value]="0">No Filter</mat-option>
      <mat-option *ngFor="let skugroup of skuGroups" [value]="skugroup.name">{{skugroup.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-checkbox class="ml-5 mt-3" [(ngModel)]="invoiceSearchRequest.HideZeroGrossProfitItems" (change)="updateDataByFilter()" [color]="'primary'">Hide Zero GP Items</mat-checkbox>
  <p [hidden]="!tableready" class="ml-5 mt-4 total-invoices ">Invoices:<span class="total-invoices-value">{{invoices.length}}</span></p>
</div>
<div class="row mt-3 pt-1">
  <div [hidden]="!tableready" class="col-6">
    <div class="table-container">
      <table *ngIf="selectedColumns.length>0 && dataSource.data.length>0" mat-table [dataSource]="dataSource" matSort class="table table-bordered table-hover small-txt">
        <ng-container *ngFor="let column of getDisplayedColumns(); let colIndex = index;" [matColumnDef]="column">
          <th [hidden]="getColumnDefinationByMappedToProperty(column).hide" class="sort-column mat-sort-header-button-custom mat-header-cell" mat-header-cell *matHeaderCellDef mat-sort-header
              [matTooltip]="getColumnDefinationByMappedToProperty(column).name" [disabled]="!getColumnDefinationByMappedToProperty(column).isSortable">
            {{getColumnDefinationByMappedToProperty(column).name}}
          </th>
          <td mat-cell *matCellDef="let element" class="mat-cell" [matTooltip]="element[getColumnDefinationByMappedToProperty(column).mappedToProperty]"
              [ngClass]="{'selected-table-row' : selectedInvoice.invoiceNumber == element.invoiceNumber}" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip>
            {{getTextBydata(element, getColumnDefinationByMappedToProperty(column))}}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="getDisplayedColumns();sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();" (click)="selectInvoice(row)" [class.demo-row-is-clicked]="selectedRow.has(row)"></tr>
      </table>
      <mat-paginator *ngIf="selectedColumns.length>0 && dataSource.data.length>0" [pageSizeOptions]="[5, 10, 20]" [pageSize]="10" showFirstLastButtons></mat-paginator>
    </div>

  </div>
  <div class="col-6">
    <div class="columns" *ngIf="selectedInvoice" [hidden]="!tableready">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>Invoide details</mat-panel-title>
            <mat-panel-description>Invoice : <span> {{selectedInvoice.invoiceNumber}} </span></mat-panel-description>
          </mat-expansion-panel-header>
          <div class="d-flex justify-content-between invoice-header">
            <div>
              <span *ngFor="let sk of selectedInvoice.skuGroups">
                <span *ngIf="sk.name=='Diamond Bundles' && sk.qty > 0" class="icon-btn invoice-text fw-400">Bundle: <i style="color:deepskyblue" class="fa fa-gem"></i></span>
                <span *ngIf="sk.name=='Platinum Bundles' && sk.qty > 0" class="icon-btn invoice-text fw-400">Bundle: <i style="color:silver" class="fa fa-gem"></i></span>
                <span *ngIf="sk.name=='Bundles' && sk.qty == 0" class="icon-btn invoice-text fw-400">Bundle: <i style="color:red" class="fa fa-times-circle"></i></span>

                <span>
                  <span *ngIf="sk.name=='Hyla' && sk.qty > 0" class="icon-btn ml-2 invoice-text fw-400">  Trade In: <i style="color:green" class="fa fa-check-circle"></i></span>
                  <span *ngIf="sk.name=='Hyla' && sk.qty == 0" class="icon-btn ml-2 invoice-text fw-400">  Trade In: <i style="color:red" class="fa fa-times-circle"></i></span>
                </span>
              </span>
              <div class="invoice-margin-top">
                <span class="invoice-text fw-600"> Bill To</span> :<span class="invoice-text fw-400"> {{selectedInvoice.customerName}}</span>
                <ng-container *ngFor="let skug of selectedInvoice.skuGroups">
                  <div *ngIf="skug.qty>0 || skug.qty<0 " class="invoice-margin-top invoice-text fw-400">
                    {{skug.name}} : {{skug.qty}}
                  </div>
                </ng-container>
              </div>
            </div>
            <div>
              <div class="invoice-text fw-600"> Invoice : <span> {{selectedInvoice.invoiceNumber}} </span> </div>
              <div class="invoice-margin-top invoice-text fw-400"> Tendered on : <span> {{selectedInvoice.soldOn}} </span></div>
              <div class="invoice-margin-top invoice-text fw-400"> Sales Person  : <span> {{selectedInvoice.employeeName}} </span> </div>
              <div class="invoice-margin-top invoice-text fw-400"> Tendered By  : <span> {{selectedInvoice.tenderedByEmployeeName}} </span> </div>
              <div class="invoice-margin-top invoice-text fw-400"> Tendered At : <span> {{selectedInvoice.storeName}} </span></div>
              <div class="invoice-margin-top invoice-text fw-700">Total Gross Profit: {{convertNumberToString(selectedInvoice.grossProfit)}}</div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <div class="table-container invoice-margin-top">
        <table [dataSource]="selectedInvoice.invoicedItems" mat-table class="table table-bordered table-hover small-txt ">
          <ng-container *ngFor="let column of getDisplayedColumnsInvoice(); let colIndex = index;" [matColumnDef]="column">
            <th [hidden]="getColumnDefinationInvoiceByMappedToProperty(column).hide"
                class="sort-column mat-sort-header-button-custom mat-header-cell"
                mat-header-cell *matHeaderCellDef
                [matTooltip]="getColumnDefinationInvoiceByMappedToProperty(column).name"
                [disabled]="!getColumnDefinationInvoiceByMappedToProperty(column).isSortable">
              {{getColumnDefinationInvoiceByMappedToProperty(column).name}}
            </th>
            <ng-container *matCellDef="let element">
              <td mat-cell class="mat-cell" [matTooltip]="getTextBydataInvoice(element, getColumnDefinationInvoiceByMappedToProperty(column))" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip>
                {{getTextBydataInvoice(element, getColumnDefinationInvoiceByMappedToProperty(column))}}
              </td>
            </ng-container>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="getDisplayedColumnsInvoice();sticky: true" style="height:35px !important;"></tr>
          <tr mat-row *matRowDef="let row; columns: getDisplayedColumnsInvoice();" [class.hidden]="!((row.grossProfit !== 0 || row.totalCost !== 0) || !invoiceSearchRequest.HideZeroGrossProfitItems)" style="height:30px !important;">
          </tr>
        </table>
      </div>

    </div>
  </div>
</div>
