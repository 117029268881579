import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Client } from '../models/client';
import { NotifierService } from 'angular-notifier';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  private _baseUrl = '/api/clients';
  clients: Client[] = [];

  constructor(private http: HttpClient,
    private _notifier: NotifierService) {}

  getAllClients(): Observable<Client[]> {
    return this.http.get<Client[]>(this._baseUrl);
  }

  getClients(callback: any) {
    this.getAllClients().subscribe((response: Client[]) => {
      this.clients = response;
      callback(response);
    }, (error) => { this._notifier.notify('error', error.message); }, () => { });
  }

    /*if (this.clients.length === 0) {
      this.getAllClients().subscribe((response: Client[]) => {
        this.clients = response;
        callback(response);
      }, (error) => { this._notifier.notify('error', error.message); }, () => { });
    } else {
        callback(this.clients);
    }*/
  
  getClientNameById(id: number, callback: any) {
    if (this.clients.length === 0) {
      this.getAllClients().subscribe((response: Client[]) => {
        this.clients = response;
        callback(this.clients.find(c => c.id === id).name);
      }, (error) => { this._notifier.notify('error', error.message); }, () => { });
    } else {
      callback(this.clients.find(c => c.id === id).name);
    }
  }

  createClient(name: string, clientOwnerName: string, isActive: boolean,
    contactNumber: string, contactEmail: string, configUploadProfileTypeId: number, templateClientId: number): Observable<any> {

    const client = {
      name,
      clientOwnerName,
      isActive,
      contactNumber,
      contactEmail,
      configUploadProfileTypeId
    };

    return this.http.post(this._baseUrl + '/' + templateClientId, client);
  }
}
