<style>
  .example-tree-invisible {
    display: none;
  }

  .example-tree ul,
  .example-tree li {
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
  }

  .example-tree-node {
    display: block;
  }

    .example-tree-node .example-tree-node {
      padding-left: 40px;
    }

  .visibility-hidden {
    visibility: hidden;
  }
</style>
<button mat-icon-button class="close-button" (click)="onDismiss()">
  <mat-icon class="close-icon" >close</mat-icon>
</button>
<h1 mat-dialog-title class="heading-text">{{title}}</h1>
<form #f="ngForm" novalidate>
  <div class="d-flex aling-items-center">
    <div class="custom-input-box create-form-group-width-set">
      <input matInput type="text" [(ngModel)]="report.title" #name="ngModel" name="name" placeholder="Name" />
    </div>
    <div class="custom-input-box create-form-group-width-set ml-3">
      <mat-select placeholder="Report Type" [(ngModel)]="report.reportType" #reportType="ngModel" name="reportType" required>
        <mat-option *ngFor="let type of reportTypes" [value]="type">{{type}}</mat-option>
      </mat-select>
    </div>
  </div>


  <div class="mt-4">
    <div class="custom-textarea-box  w-100 ">
      <textarea matInput [value]="true" type="text" [(ngModel)]="report.message" #message="ngModel" name="message" placeholder="Message"></textarea>
    </div>
  </div>

  <div class="d-flex mt-4">
    <mat-label>Date Type</mat-label>
    <mat-radio-group [(ngModel)]="report.dateTypeId" #dateTypeId="ngModel" name="dateTypeId">
      <mat-radio-button class="margin-left-1em" [value]="1">MTD</mat-radio-button>
      <mat-radio-button class="margin-left-1em" [value]="5">Last Month</mat-radio-button>
    </mat-radio-group>
  </div>
<!-- 
  <div>
    <mat-form-field>
      <mat-label>Selected Team</mat-label>
      <input matInput type="text" [(ngModel)]="selectedReport.location.name" #selectedReportLocation="ngModel" name="selectedReportLocation" placeholder="Name" disabled="disabled" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>New Selection</mat-label>
      <input matInput type="text" [(ngModel)]="selectedLocation.name" disabled="disabled" />
    </mat-form-field>
   
  </div> -->

  <div class="mt-4">
    <mat-form-field>
      <mat-label>Location Tree</mat-label>
      <input matInput type="text" class="visibility-hidden" />
    </mat-form-field>
    <cdk-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="example-tree-node" #nestedTreeNodeElement><button mat-icon-button disabled></button> <span mat-button (click)="selectNode(node)">{{node.name}}</span></cdk-nested-tree-node>
      <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChild" class="example-tree-node" #nestedTreeNodeElement>
        <button mat-icon-button [attr.aria-label]="'Toggle ' + node.name" cdkTreeNodeToggle [ngClass]="[getLevel(dataSource._data,node)<2 ?  '' : 'visibility-hidden']">
          <mat-icon class="mat-icon-rtl-mirror">{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}</mat-icon>
        </button>
        <span mat-button (click)="selectNode(node)">{{node.name}}</span>
        <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" *ngIf="getLevel(dataSource._data,node)<report.locationTierFocusId">
          <ng-container cdkTreeNodeOutlet></ng-container>
        </div>
      </cdk-nested-tree-node>
    </cdk-tree>
  </div>
</form>
<div class="d-flex align-items-center mt-4 pt-1">
  <button mat-raised-button color="primary" [disabled]="" class="submit-btn" (click)="onConfirm()">Submit</button>
  <button mat-button class="clear-btn ml-3" (click)="onDismiss()">Clear</button>
</div>
