import { Component, OnDestroy, OnInit } from '@angular/core';
import { Permission } from '../models/permission';
import { Role } from '../models/role';
import { LocationService } from '../../services/location.service';
import { Setting } from '../models/setting';
import { PermissionService } from '../services/permission.service';
import { RoleService } from '../services/role.service';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../../../core/services/auth.service';
import { SettingService } from '../services/setting.service';
import { Action } from '../../models/action';
import { ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoaderService } from '../../services/loader.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateRoleDialogComponent } from './create-role.component';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ArrayDataSource } from '@angular/cdk/collections';

@Component({
  selector: 'app-role-manager',
  templateUrl: './role-manager.component.html'
})
export class RoleManagerComponent implements OnInit, OnDestroy {
 
  locationTypeCodes = [{ 'id': 1, 'value': 'Client' },
  { 'id': 2, 'value': 'Region' },
  { 'id': 3, 'value': 'District' },
  { 'id': 4, 'value': 'Store' }];

  newRole: Role = new Role();
  copyFromRoleId: string;
  model: any = {};
  selectedClientId: number;
  settings: Setting[] = [];
  selectedSettings: Setting[] = [];
  permissions: Permission[] = [];
  selectedPermissions: Permission[] = [];
  selectedRole: Role;
  roles: Role[] = [];
  mainRolesList: Role[] = [];
  roleId: string = null;
  optionRadioSelected = 'permissions';
  roleTypeSelected = 'role';
  permissionSelectSettings = {
    idField: 'id',
    textField: 'description'
  };
  currentAction: Action;
  dialog: any = {
    isOpen: false,
    headerText: '',
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    }
  };

  clientSubscription: Subscription;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  treeControl = new NestedTreeControl<Role>(node => node.children);
  dataSource: any;
  hasChild = (_: number, node: Role) => !!node.children && node.children.length > 0;

  constructor(
    private _permissionService: PermissionService,
    private _roleService: RoleService,
    public _authService: AuthService,
    private _settingService: SettingService,
    private _notifier: NotifierService,
    private _locationService: LocationService,
    private _loaderService: LoaderService,
    private matDialog: MatDialog) { }

  ngOnInit() {
    this.getPermissions();
    this.getSettings();

    this._locationService.currentActualClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe((message: any) => {
        if (message) {
          console.log(message);
          this.selectedClientId = message;
          this.getRoles(message);
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
    this.dialogRef?.close();
  }


  clearSelected() {
    this.selectedRole = null;
  }

  getPermissions() {
    this._permissionService.getPermissionsByRole(this._authService.roles())
      .subscribe((response: Permission[]) => {
        this.permissions = response;
      }, (error) => this._notifier.notify('error', error.error), () => { });
  }

  getSettings() {
    this._settingService.getSettings().subscribe((response) => {
      this.settings = response;
    });
  }

  checkOption(setting: Setting) {
    setting.settingOptionGroupChoice = setting.settingOptionGroup.settingOptionGroupChoice
      .find(x => setting.settingOptionGroupChoiceId === x.id);

  }

  getRoles(client?: number) {
    this._loaderService.show();
    let clientId: number = this._authService.clientId();
    if (client) {
      clientId = client;
    }
    this._roleService.getRoles(clientId, this._authService.roles()).subscribe((response) => {
      this.mainRolesList = response;
      let roleTree: Role[] = [];
      roleTree = response.filter(x => x.parentRoleId === '');
      roleTree.forEach(x => {
        x.children = this._roleService.findChildrenOfRole(response, x);
        x.childRoles = this._roleService.findChildrenOfRole(response, x);
      });
      this.roles = roleTree;

      this.dataSource = new ArrayDataSource(this.roles);
      this.treeControl.dataNodes = this.roles;
      setTimeout(() => { this.treeControl.expandAll(); }, 100);

      this._loaderService.hide();
    });

  }

  getLevel(data, node: Role) {
    let path = data.find(branch => {
      return this.treeControl
        .getDescendants(branch)
        .some(n => n.name === node.name);
    });
    return path ? this.getLevel(path.children, node) + 1 : 0;
  }

  getMyRole() {
    return this._authService.roles();
  }

  async onRoleSelected(role: Role) {
    this.selectedPermissions = this.permissions;
    await this.selectedPermissions.forEach(x => {
      if (role.permissions.find(y => y.id === x.id)) {
        x.selected = true;
      } else {
        x.selected = false;
      }
    });
    this.selectedRole = role;
    this.selectedSettings = this.settings;

    await this.selectedSettings.forEach(x => {
      if (role.settingRoleOption.find(y => y.settingId === x.id)) {
        x.settingOptionGroupChoiceId = role.settingRoleOption.find(y => y.settingId === x.id).settingOptionGroupChoiceId;
        x.settingOptionGroupChoice = role.settingRoleOption.find(y => y.settingId === x.id).settingOptionGroupChoice;
        x.selectedOptionChoice = role.settingRoleOption.find(y => y.settingId === x.id).settingOptionGroupChoice;
        x.optionValue = role.settingRoleOption.find(y => y.settingId === x.id).optionValue;
        if (Number.parseInt(x.optionValue)) {
          x.optionValue = Number.parseInt(x.optionValue);
        }
      } else {
        x.settingOptionGroupChoiceId = 0;
      }
    });
    this.selectedRole = role;
  }

  showPermissions() {
    const roleId = this.model.roleId;
    if (this.roles.filter(role => role.id === roleId)) {
      this.model.selectedPermissions = this.roles.filter(role => role.id === roleId)[0].permissions;
    } else {
      this.model.selectedPermissions = [];
    }
  }

  isSettingHidden(s: Setting) {

    if (this.selectedPermissions.find(x => x.routing === s.reportType.routing && x.selected)) {
      return false;
    } else {
      return true;
    }
  }

  saveRoles() {
    this.mainRolesList.forEach((role) => {
      if (role.id === this.selectedRole.id) {
        const permissions = this.selectedPermissions.filter(x => x.selected).map((permission) => {
          return { Permission: { Id: permission.id, Description: permission.description } };
        });
        const settings = [];
        this.selectedSettings.forEach((setting) => {
          if (setting.settingOptionGroupChoiceId !== 0) {
            settings.push({
              Id: setting.id,
              OptionValue: setting.optionValue,
              SettingId: setting.id,
              SettingOptionGroupChoiceId: +setting.settingOptionGroupChoiceId
            });
          } else {
            settings.push({
              Id: setting.id,
              OptionValue: setting.optionValue,
              SettingId: setting.id,
              SettingOptionGroupChoiceId: +5
            });
            this._notifier.notify('error', 'Some unselected options were set to Disabled');
          }
        });
        this._roleService.updateRole(role.id, role.name, this.selectedClientId, permissions, settings)
          .subscribe((response: string) => {
            this.getRoles(this.selectedClientId);
            this._notifier.notify('success', 'Update role permissions successfully.');
          }, (error) => this._notifier.notify('error', error.error), () => { });
      }
    });
  }
  dialogRef: any;
  createRole() {
    this._roleService.createRole(this.newRole, this.copyFromRoleId).subscribe((response) => {
      if (response) {
        this.getRoles();
      }
    });
  }

  openCreateRole() {
    this.newRole = new Role();
    this.newRole.clientId = this._authService.clientId();
    this.currentAction = Action.Create;
    let myRole = this.getMyRole();
    this.dialogRef = this.matDialog.open(CreateRoleDialogComponent, {
      autoFocus: true, disableClose: false, panelClass: '',
      data: { title: "Add role", mainRolesList: this.mainRolesList, myRole: myRole, newRole: this.newRole, copyFromRoleId: this.copyFromRoleId }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result !== true) {
        this.newRole = result.newRole;
        this.copyFromRoleId = result.copyFromRoleId;
        this.createRole();
      }
    });
  }
}
