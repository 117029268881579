import { Inject, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RewardGameViewModel } from "../models/reward-game";
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { DurationService } from '../services/duration.service';
import { Duration } from '../models/duration';
import { CurrencyPipe } from '@angular/common';
import { FormulaService } from '../services/formula.service';
import { NotifierService } from 'angular-notifier';


@Component({
  selector: 'formula-create-dialog',
  templateUrl: 'formula-create-dialog.component.html',
})
export class FormulaCreateDialogComponent implements OnInit {

  title: string;
  formula:any;
  decisions:any;
  formulaViewGroups:any;

  constructor(public dialogRef: MatDialogRef<FormulaCreateDialogComponent>,   private _notifier: NotifierService,private _formulaService: FormulaService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.formula = data.formula;
    this.decisions = data.decisions;
    this.formulaViewGroups = data.formulaViewGroups;
  }



  ngOnInit() { }

  onConfirm(): void { this.dialogRef.close(this.formula ); }

  onDismiss(): void { this.dialogRef.close(false); }

  isSubmitButtonDisabled() {
    return this.formula.name === null ||
      this.formula.list.length === 0 ||
      this.formula.color === null ||
      this.formula.decimalLength === null ||
      this.formula.formulaViewGroupId === null;
  }
  
  changeDecision() {
    if (!this.formula.currentDecision) {
      return;
    }

    this.formula.list.push(this.formula.currentDecision);

    this._formulaService.getNextDecisions(this.formula.currentDecision)
      .subscribe((response: any[]) => {
        this.decisions = response;
      }, (error) => this._notifier.notify('error', error.message), () => { });
  }

  clearDecisions() {
    this.formula.list = [];
    this.formula.currentDecision = null;
    this._formulaService.getNextDecisions()
      .subscribe((response: any[]) => {
        this.decisions = response;
      }, (error) => this._notifier.notify('error', error.message), () => { });
  }

  getFormula(){
    return this.formula.list.join(" ");
  }
}

