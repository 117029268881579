import { ConfigSheetTable } from './config-sheet-table';


export class ConfigSheet {
    id?: number;
  name?: string;
  keyword?: string;
  hasDate?: boolean;
  includeDateRange?: boolean;
  defaultAddOnClient?: boolean;
  hasLocation?: boolean;
  configSheetTables?: ConfigSheetTable[];
  configUploadProfileTypeId?: number;
}
