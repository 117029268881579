<div class="d-flex align-items-center justify-content-between action-header">
 
  <div *ngIf="!_authService.isSuperAdmin()" style="float: left">{{clientName}}</div>
  <mat-form-field class="bottom-border col-2" *ngIf="_authService.isSuperAdmin()">
    <mat-select  placeholder="Select Client" [(ngModel)]="selectedClientId" [disabled]="localStorageLoadingInProgress" #clientId="ngModel" name="clientId">
      <mat-option [value]="0">Select Client</mat-option>
      <mat-option *ngFor="let client of clients" [value]="client.id">{{client.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  
  <!-- <div style="float: left; margin-left: 50px">
    <span class="glyphicon glyphicon-question-sign">
      <span class="tooltiptext">
        ImportHelp <br />
        ACTCCS_MM_DD_YYYY.csv <br />
        UPGCCRS_MM_DD_YYYY.xls <br />
        VZWCOMM_MMYYYY.xls<br />
        CustomerConversion_MDYYYY_MDYYYY.xls<br />
        Datascape.xls
      </span>
    </span>
  </div> -->
  <div class="col-5">
    <ngx-file-drop dropZoneLabel="Select files to import" (onFileDrop)="dropped($event)" >
      <div class="file-drop-icon">
        <i class="fa fa-upload"></i>
      </div>
    </ngx-file-drop>
  </div>
</div>

<p class=" mt-4 text-regular fw-500" *ngIf="uploadReport">File upload finished at: <span>{{uploadReport.uploadTime}}</span></p>
<p class=" mt-4 text-regular fw-500" *ngIf="fileUploadInProgress">File upload in progress...</p>


<div class="table-responsive table-container mt-4">
  <table class="table table-hover">
    <tbody>
      <tr *ngFor="let item of files; let i=index">
        <td><strong>{{ item.relativePath }}</strong></td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="uploadReport" class="table-container mt-4">
  <table>
    <tbody>
      <tr> <td class="py-2"> {{uploadReport.fileUploadType }}</td></tr>
      <tr> <td class="py-2"> CompletionStatus {{uploadReport.completionString }}</td></tr>
      <tr *ngFor="let item of uploadReport.comments; let i=index">
        <td class="py-2" [ngStyle]="{'color': item.includes('FAILURE') || item.includes('REQUIRED') || item.includes('ERROR')? 'red' : 'black'  }" [innerHTML]="item"></td>
      </tr>
    </tbody>
  </table>
</div>
