<div class="flex-container">
  <div class="flex-items">
    <h1>
      <span>Error</span>
    </h1>
  </div>
  <div class="flex-items">
    <h2>
      <small>
        <span>{{message}}</span>
      </small>
    </h2>
  </div>
</div>

