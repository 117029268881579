import { Client } from './client';

export class Location {
  id?: number;
  color?: string;
  name?: string;
  clientId?: number;
  locationTypeCodeId?: number;
  parentId?: number;
  isVisible?: boolean;
  client?: Client;
  children?: Location[];
  selected?: boolean;
  isFiltered?: boolean;
  subclientVisibilitySettingOptionId = 0;
}
