import { Component, OnInit, OnDestroy, ViewChildren, ViewChild } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BaseChartDirective } from 'ng2-charts';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { NotifierService } from 'angular-notifier';
import { EmployeeService } from '../services/employee.service';
import { DashboardService } from '../services/dashboard.service';
import { AuthService } from '../../core/services/auth.service';
import { ClientService } from '../services/client.service';
import { FormulaService } from '../services/formula.service';
import { LocationService } from '../services/location.service';
import { SkuGroupService } from '../services/sku-group.service';
import { InventoryService } from '../services/inventory.service';
import { Formula } from '../models/formula';
import { Action } from '../models/action';
import { SkuGroup } from '../models/sku-group';
import { Location } from '../models/location';
import { Employee } from '../models/employee';
import { Dashboard } from '../models/dashboard';
import { Client } from '../models/client';
import * as moment from 'moment';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { FormControl, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DynamicChartComponent } from '../../dynamic-chart/dynamic-chart.component';
import { createComponentType } from '@angular/compiler/src/render3/view/compiler';
import { forEach } from 'underscore';
import { catchError, takeUntil } from 'rxjs/operators';

const CONFIG_CLIENT_KEY = 'ConfigClient';
Chart.register(ChartDataLabels);


@Component({

  selector: 'app-dashboard-single',
  templateUrl: './dashboard-single.component.html'
})
export class DashboardSingleComponent implements OnInit, OnDestroy {
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  formulaInput: string = "GrossProfit";
  filteredFormulas: any[] = [];
  filteredHiddenItems: string[] = [];
  colorPalette = [
    '#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#FF8C33',
    '#8C33FF', '#33FFD7', '#FFD733', '#C71585', '#20B2AA',
    '#FF6347', '#4682B4', '#D2691E', '#556B2F', '#9ACD32',
    '#6A5ACD', '#DB7093', '#B8860B', '#CD5C5C', '#008B8B',
    '#B03060', '#FF4500', '#2E8B57', '#483D8B', '#2F4F4F',
    '#8B4513', '#C0C0C0', '#FFD700', '#ADFF2F', '#87CEEB'
  ];

  favoritesKey = 'favoriteFormulas';
  favoriteFormulas: any[] = this.loadFavorites();
  debug: boolean = true;
  subscriptionToClients: Subscription;
  subscriptionToRegions: Subscription;
  subscriptionToDistricts: Subscription;
  subscriptionToStores: Subscription;
  clientId: number;
  regions: any[];
  selectedRegionId: number;
  selectedRegion: any;
  districts: any[];
  selectedDistrictId: number;
  selectedDistrict: any;
  stores: any[];
  selectedStoreId: number;
  selectedStore: any;


  dashboard: Dashboard = new Dashboard();
  dashboards: Dashboard[];
  newCharts: DynamicChartComponent[];
  currentAction: Action;
  formulas: Formula[] = [];
  skuGroups: SkuGroup[] = [];
  loadingInProgress: boolean;
  clients: Client[] = [];
  locations2: Location[];
  locations: Location[];
  employees: Employee[];
  treeData: any;
  selectedClientId: number;
  selectedClient: any;
  locationSearchString = null;
  presentUser: Employee;
  topLocationToShow: number;
  locationTreeToView: Location;
  locationsForSelectTree: Location[];
  userId: string;
  userRole: any;
  inventoryData: any;
  zoomed = false;
  isIpad = false;
  deptharray = [{ text: 'Region', value: 2 },
  { text: 'District', value: 3 },
  { text: 'Store', value: 4 }];
  depthview = [];
  depthSelection = 4;
  selectedLocationColor: any;
  root: any;
  animationSpeed = 1500;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);



  constructor(private _notifier: NotifierService,
    private _locationService: LocationService,
    private _clientService: ClientService,
    private _dashboardService: DashboardService,
    private _formulaService: FormulaService,
    private _skuGroupService: SkuGroupService,
    private _authService: AuthService,
    private _inventoryService: InventoryService,
    private _employeeService: EmployeeService,
    private _loaderService: LoaderService,
    private observer: BreakpointObserver) {
    this.observer.observe('(max-width: 1025px)').subscribe((result) => {
      console.log('max', result);
      if (result.matches) {
        this.isIpad = true;
      }
    });
  }
  forkedListened() {
  forkJoin({
    clients: this.getClients().pipe(catchError(() => of([]))),
      locations: this.getLocations().pipe(catchError(() => of([]))),
        employees: this.getEmployees().pipe(catchError(() => of([]))),
          skuGroups: this.getSkuGroups().pipe(catchError(() => of([]))),
            formulas: this.getFormulas().pipe(catchError(() => of([])))
  }).pipe(
              takeUntil(this.destroyed$)
  ).subscribe(results => {
    this.clients = results.clients;
    this.formulas = results.formulas;
    this.filteredFormulas = this.formulas;
    this.locations = results.locations;
    this.employees = results.employees;
    this.skuGroups = results.skuGroups;

              // Now start listening for store changes
              this.subscriptionToClients = this._locationService.currentActualClient
                .pipe(takeUntil(this.destroyed$))
                .subscribe(message => {
                  this.onClientSelected();
                });

              this.subscriptionToRegions = this._locationService.currentRegion
                .pipe(takeUntil(this.destroyed$))
                .subscribe(message => {
                  this.selectedRegionId = message;
                });

              this.subscriptionToDistricts = this._locationService.currentDistrict
                .pipe(takeUntil(this.destroyed$))
                .subscribe(message => {
                  if (this.debug) { console.log('actual district ' + message); }
                  this.selectedDistrictId = message;
                });

              this.subscriptionToStores = this._locationService.currentStore
                .pipe(takeUntil(this.destroyed$))
                .subscribe(message => {
                  if (message === null) {
                    return;
                  }
                  if (this.debug) { console.log('actual store ' + message); }
                  this.selectedStoreId = message;
                  this.getSingleDashboard();
                });
            });
}

  toggleFavorite(formula: any) {
    if (this.isFavorite(formula)) {
      this.favoriteFormulas = this.favoriteFormulas.filter(f => f.id !== formula.id);
    } else {
      this.favoriteFormulas.push(formula);
    }
    this.saveFavorites();
    this.filterFormulas('');
  }

  isFavorite(formula: any): boolean {
    return this.favoriteFormulas.some(f => f.id === formula.id);
  }

  saveFavorites() {
    localStorage.setItem(this.favoritesKey, JSON.stringify(this.favoriteFormulas));
  }

  loadFavorites() {
    const favorites = localStorage.getItem(this.favoritesKey);
    return favorites ? JSON.parse(favorites) : [];
  }

  clearFilter() {
    this.filteredFormulas = this.sortFormulas(this.formulas);
  }

  filterFormulas(query: string) {
    const filtered = this.formulas.filter(f => f.name.toLowerCase().includes(query.toLowerCase()));
    this.filteredFormulas = this.sortFormulas(filtered);
  }

  sortFormulas(formulas: any[]) {
    return formulas.sort((a, b) => {
      if (this.isFavorite(a) && !this.isFavorite(b)) return -1;
      if (!this.isFavorite(a) && this.isFavorite(b)) return 1;
      return a.name.localeCompare(b.name);
    });
  }


  saveOptions(): void {
    // Save current options to localStorage
    localStorage.setItem('dashboardOptions', JSON.stringify(this.dashboard));
    localStorage.setItem('dashboardHiddenUsers', JSON.stringify(this.hiddenItems));
  }

  onFormulaSelect(selectedFormula: any) {
    this.dashboard.formulaName = selectedFormula.name;
    this.dashboard.formulaId = selectedFormula.id;
    this.saveOptions();
    this.getSingleDashboard();
  }

  onFormulaChange(value: any): void {
    if (value == this.dashboard.formulaId) {
    }
    
    this.setFormulaName();
    
    
  }

  onLocationTierChange(value: any): void {
    this.dashboard.locationTierFocusId = value;
    this.saveOptions();
    this.getSingleDashboard();
  }

  onDateTypeChange(value: any): void {
    this.dashboard.dateTypeId = value;
    this.saveOptions();
    this.getSingleDashboard();
  }

  setFormulaName(): void {
    const selectedFormula = this.formulas.find(f => f.id === this.dashboard.formulaId);
    if (selectedFormula) {
      this.dashboard.formulaName = selectedFormula.name;
    }
  }



  getSingleDashboard() {
    this.dashboard.locations = [];
    this.dashboard.graphTypeId = 3;
    if (!this.dashboard.formulaId || !this.formulas.find(x => x.id == this.dashboard.formulaId)) {
      this.dashboard.formulaId = this.formulas.find(x => x.name == "GrossProfit").id;
    }
    if (!this.dashboard.locationTierFocusId) {
      this.dashboard.locationTierFocusId = 5;
    }
    if (!this.dashboard.dateTypeId) {
      this.dashboard.dateTypeId = 21;
    }
    if (this.selectedDistrictId == 0) {
    }
    if (this.selectedStoreId == 0 || this.dashboard.locationTierFocusId < 4) {
      this.dashboard.locations.push(this.locations.find(x => x.id == this.selectedDistrictId));
    } else {
      this.dashboard.locations.push(this.locations.find(x => x.id == this.selectedStoreId));
    }
    this._loaderService.show();
    this._dashboardService.getDashboardWithParams(this._authService.userId(),
      this.dashboard.formulaId, this.dashboard.graphTypeId,
      this.dashboard.dateTypeId, this.dashboard.locationTierFocusId,
      this.dashboard.locations)
      .subscribe((response: Dashboard) => {
        console.log(response);
        this.setupLinechart(response);
        this._loaderService.hide();
        this._notifier.notify('success', 'Dashboard Complete.');
      }, (error) => this._notifier.notify('error', error.message), () => { this._loaderService.hide(); });
  }

  public lineChartData: ChartData;
  private allDatasets = [];  // Store all datasets here
  private allLabels = [];  // Store all datasets here
  public lineChartOptions: ChartConfiguration<'line'>['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: window.devicePixelRatio,
    scales: {
      y: {
        position: 'left',
      },
    },
    plugins: {
      legend: {
        display: true,
        onClick: (event, legendItem, legend) => this.onLegendItemClick(event, legendItem, legend),
        labels: {
          generateLabels: (chart) => this.generateCustomLabels(chart),
          filter: (item, chart) => this.legendItemFilter(item, chart)
          
        }
      },
      datalabels: {
        display: true, // Hide data labels
        color: '#000000',
        formatter: (value, context) => {
          return (parseFloat(value).toFixed(this.formulas.find(x => x.id === this.dashboard.formulaId).decimalLength)).toString(); // Adjust the number of decimal places here
        },
      },

    },
  };

  clearHiddenFilter() {
    this.filteredHiddenItems = this.hiddenItems;
  }

  filterHiddenItems(query: string) {
    this.filteredHiddenItems = this.hiddenItems.filter(item => item.toLowerCase().includes(query.toLowerCase()));
  }

  onLegendItemClick(event: ChartEvent, legendItem: any, legend:any) {
    const label = legendItem.text;
    const dataset = this.allDatasets.find(ds => ds.label === label);
    if (this.hiddenItems.includes(label)) {
      this.hiddenItems = this.hiddenItems.filter(item => item !== label);
      dataset.hidden = false;
    } else {
      this.hiddenItems.push(label);
      dataset.hidden = true;
    }

    this.updateChartData();
  }

  showDataset(label: string) {
    const dataset = this.allDatasets.find(ds => ds.label === label);
    dataset.hidden = false;
    this.hiddenItems = this.hiddenItems.filter(item => item !== label);
    this.updateChartData();
  }

  private updateChartData() {

    this.lineChartData.datasets = this.allDatasets.filter(
      dataset => !this.hiddenItems.includes(dataset.label)
    );
    this.chart.update();
    
    
  }



  private legendItemFilter(item: any, chart: any) {
    return !this.hiddenItems.includes(item.text);
  }

  private generateCustomLabels(chart) {
    const data = chart.data;
    if (data.datasets.length) {
      return data.datasets.map((dataset, i) => ({
        text: dataset.label,
        fillStyle: dataset.borderColor,
        hidden: this.hiddenItems.includes(dataset.label),
        datasetIndex: i
      }));
    }
    return [];
  }
  
  public lineChartType: ChartType = 'line';
  public hiddenItems: string[] = [];
  public selectedHiddenItem: string | null = null;
  setupLinechart(dashboardz) {
    const labelList = dashboardz.monthList;
    const datasets = [];
    dashboardz.locationForChart.forEach((store, index) => {
      const dataList = [];
      for (let i = 0; i < store.stats[0].values.length; i++) {
        if (dashboardz.targetFormula.isPercentage) {
          dataList.push(this.getValueForFormulaWithIndex(dashboardz.targetFormula.formulaValue, store.stats, i).toFixed(2));
        } else {
          dataList.push(this.getValueForFormulaWithIndex(dashboardz.targetFormula.formulaValue, store.stats, i).toFixed(this.formulas.find(x => x.id === this.dashboard.formulaId).decimalLength));
        }
      }
      
      datasets.push({
        label: store.name,  // Name of the dataset
        data: dataList,    // Data points for this dataset
        backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent background for line chart
        borderColor: this.colorPalette[index % this.colorPalette.length], // Random color if not set
        pointBackgroundColor: this.colorPalette[index % this.colorPalette.length],
        pointBorderColor: '#ffffff',
        borderWidth: 3, // Thicker lines
        pointRadius: 5, // Larger points
        pointHoverRadius: 7, // Larger hover points
        hidden: this.hiddenItems.includes(store.name),
      });

    });

    this.allDatasets = datasets;
    this.allLabels = [...labelList];
    this.lineChartData = {
      datasets: datasets,
      labels: labelList
    };
  }

  ngOnInit() {
    const savedOptions = localStorage.getItem('dashboardOptions');
    const savedHiddenItems = localStorage.getItem('dashboardHiddenUsers');
    if (savedOptions) {
      this.dashboard = JSON.parse(savedOptions);
    } else {
      this.dashboard.formulaName = "GrossProfit";
    }
    if (savedHiddenItems) {
      this.hiddenItems = JSON.parse(savedHiddenItems);
    }
   // Chart.defaults.animation = { duration: this.animationSpeed };


    Chart.defaults.plugins.datalabels.display = true;
    Chart.defaults.plugins.datalabels.anchor = 'end';
    Chart.defaults.plugins.datalabels.align = 'bottom';
    Chart.defaults.plugins.datalabels.color = 'white';
    this.selectedLocationColor = '#808080';
    this.selectedClientId = this._authService.clientId();
    
    this.clientId = this._authService.clientId();
    this.userId = this._authService.userId();
    this.userRole = this._authService.roles();
    this.forkedListened();
    //this.getClients();
    //this.getLocations();
    //this.getEmployees();
    //this.getSkuGroups();
    //this.getFormulas();
    this.filteredFormulas = this.formulas;
    
    ////this.getLocationFullTree();
    this.topLocationToShow = 1;
    if (this.userRole === 'Sales Representative') {
      this.topLocationToShow = 2;
    }


    //this.subscriptionToClients = this._locationService.currentActualClient
    //  .pipe(takeUntil(this.destroyed$))
    //  .subscribe(message => {
        
    //    this.onClientSelected();
    //  });

    //this.subscriptionToRegions = this._locationService.currentRegion
    //  .pipe(takeUntil(this.destroyed$))
    //  .subscribe(message => {
    //    this.selectedRegionId = message;
    //  });
    //this.subscriptionToDistricts = this._locationService.currentDistrict
    //  .pipe(takeUntil(this.destroyed$))
    //  .subscribe(message => {
    //    if (this.debug) { console.log('actual district ' + message); }
    //    this.selectedDistrictId = message;
    //  });
    //this.subscriptionToStores = this._locationService.currentStore
    //  .pipe(takeUntil(this.destroyed$))
    //  .subscribe(message => {
    //    if (message === null) {
    //      return;
    //    }
    //    if (this.debug) { console.log('actual store ' + message); }
    //    this.selectedStoreId = message;
    //    this.getSingleDashboard();
    //  });
    
  }



  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
  }


  onClientSelected() {
    this.clientId = this.selectedClientId;
    this.getFormulas();
    this.getSkuGroups();
    this.getEmployees();
    this.getLocations();
    this.getLocationFullTree();

  }

  formattedValue(number: number) {
    return Math.floor(number).toLocaleString();
  }

  getFormulaComputedPropertyValue(value: number, formula: any) {
    var trendingValue = 1;
    return (formula.isMoney) ? ((trendingValue) ? '$' + this.formattedValue((value)) :
      '$' + this.formattedValue((value)))
      : (formula.isPercentage) ? ((trendingValue) ?
        this.formattedValue((value * 100)) + '%' :
        this.formattedValue((value * 100)) + '%')
        : ((trendingValue) ? this.formattedValue(value) : this.formattedValue(value));
  }

  searchLocation(array: any = null) {
    array = array ? array : this.locations2[0];

    for (const key in array) {

      if (key === 'name') {
        if (array.name.toLowerCase().indexOf(this.locationSearchString.toLowerCase()) > -1) {
          array.isFiltered = false;
        } else {
          array.isFiltered = true;
        }
        array.children.forEach((child) => { this.searchLocation(child); });
      }

    }
  }






  getValueForFormulaWithIndex(formulaObject: any, stats: any, index: number) {

    const formulaElements = formulaObject.split(',');
    let OpenCloseCount = 0;
    let stringFormula = '';
    let result = 0;
    for (let i = 0; i < formulaElements.length; i++) {


      const currentWord = formulaElements[i];
      const nextWord = (i < formulaElements.length - 1) ? formulaElements[i + 1] : '';
      if (currentWord === '(') {
        OpenCloseCount = OpenCloseCount + 1;
        stringFormula += currentWord;
      } else if (currentWord === ')' && OpenCloseCount > 0) {
        OpenCloseCount = OpenCloseCount - 1;
        stringFormula += currentWord;
      } else if (currentWord === 'QTY') {
        const skuGroup = stats.find(s => s.name === nextWord && s.gpOrQty === 2);
        if (skuGroup && skuGroup.values[index] >= 0) {
          stringFormula += '(' + skuGroup.values[index] + ')';
        } else {
          stringFormula = '0';
          break;
        }
      } else if (currentWord === 'GP') {
        const skuGroup = stats.find(s => s.name === nextWord && s.gpOrQty === 1);
        if (skuGroup && skuGroup.values[index] >= 0) {
          stringFormula += '(' + skuGroup.values[index] + ')';
        } else {
          stringFormula = '0';
          break;
        }
      }
      else if (currentWord === 'REBIZ') {
        const skuGroup = stats.find(s => s.name === nextWord && s.gpOrQty === 3);
        stringFormula += '(' + skuGroup.values[index] + ')';
      }
      else if (currentWord === 'HOURS') {
        const skuGroup = stats.find(s => s.name === nextWord && s.gpOrQty === 4);
        stringFormula += '(' + skuGroup.values[index] + ')';
      }
      else if (currentWord === '+' || currentWord === '-' || currentWord === '*' || currentWord === '/') {
        stringFormula += currentWord;
      }
    }
    try {
      result = this.evaluate(stringFormula);
    } catch (e) {
      result = 0;
    }

    if (isNaN(result)) {
      result = 0;
    }

    return result;
  }



  evaluate(code: string) {
    const f = new Function('return ' + code);
    return f();
  }





  getClients() {
    return this._clientService.getAllClients();

    this._clientService.getAllClients()
      .subscribe((response: Client[]) => {
        this.clients = response;
      }, (error) => this._notifier.notify('error', error.error), () => { });
    // this.clients = this._clientService.getAllClients();
  }

  getLocations() {
    return this._locationService.getAllLocations();
    this._locationService.getAllLocations()
      .subscribe((response: Location[]) => {
        this.locations = response;
      }, (error) => this._notifier.notify('error', error.message), () => { });

  }

  getEmployees() {
    return this._employeeService.getAllEmployees();
    this._employeeService.getAllEmployees()
      .subscribe((response: Employee[]) => {
        this.employees = response;
        this.presentUser = response.find
          (emp => emp.userId.toString() === this.userId.toString());
      }, (error) => this._notifier.notify('error', error.message), () => { });
  }

  //getFormulas() {
  //  this._formulaService.getFormulas(+this.clientId, (response) => {
      
  //    this.formulas = response.filter(x => !x.containsComplexFormula);
  //  }, true);
  //}

  //getSkuGroups() {
  //  this._skuGroupService.getSkuGroupsByClient(+this.clientId, (response: SkuGroup[]) => {
  //    this.skuGroups = response;
  //  });
  //}

  getFormulas(): Observable<Formula[]> {
    const subject = new BehaviorSubject<Formula[]>([]);
    this._formulaService.getFormulas(+this.clientId, (response) => {
      const filteredFormulas = response.filter(x => !x.containsComplexFormula);
      this.formulas = filteredFormulas;
      subject.next(filteredFormulas);
      subject.complete();
    }, true);
    return subject.asObservable();
  }

  getSkuGroups(): Observable<SkuGroup[]> {
    const subject = new BehaviorSubject<SkuGroup[]>([]);
    this._skuGroupService.getSkuGroupsByClient(+this.clientId, (response: SkuGroup[]) => {
      this.skuGroups = response;
      subject.next(response);
      subject.complete();
    });
    return subject.asObservable();
  }


  getLocationFullTree() {
    const tierId = 5;
    this._locationService.getLocationTree(+this.selectedClientId, tierId)
      .subscribe((response: Location[]) => {
        this.locationsForSelectTree = response;
      }, (error) => this._notifier.notify('error', error.error), () => { });
  }

  getInventoryTree() {
    const tierId = 5;
    this._inventoryService.getTreeWithCostValuesForStore()
      .subscribe((response: any[]) => {
        this.inventoryData = response;
      }, (error) => this._notifier.notify('error', error.error), () => { });
  }


}




