import { Component, OnDestroy, OnInit } from '@angular/core';
import { PermissionService } from '../services/permission.service';
import { RoleService } from '../services/role.service';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../../../core/services/auth.service';
import { LocationService } from '../../services/location.service';
import { Location } from '../../models/location';
import { User } from '../models/user';
import { UserService } from '../services/user.service';
import { EmployeeService } from '../../services/employee.service';
import { SettingService } from '../services/setting.service';
import { SubclientService } from '../../services/subclient.service';
import { Subclient } from '../../models/subclient';
import { ReportType } from '../models/reportType';
import { Action } from '../../models/action';
import { SubclientSettingOption } from '../../models/subclientSettingOption';
import { SubclientVisibilitySettingOption } from '../../models/subclientVisibilitySettingOption';
import { SubclientVisibilitySetting } from '../../models/subclientVisibilitySetting';
import { SubclientViewingSetting } from '../../models/subclientViewingSetting';
import { SubclientVisibilitySettingSubclient } from '../../models/subclientVisibilitySettingSubclient';
import { ReplaySubject, Subscription } from 'rxjs';
import { SubclientSetting } from '../../models/subclientSetting';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-location-manager',
  templateUrl: './location-manager.component.html'
})
export class LocationManagerComponent implements OnInit, OnDestroy {
  optionRadioSelected = 'subclient';
  locations: Location[];
  locations2: Location[];
  locationsForSelectedSubclient: Location[];
  topLocationToShow: number;
  locationTreeToView: Location;
  locationSearchString = null;
  locationsForSelectTree: Location[];
  reportTypes: ReportType[];
  selectedClientId: number;
  selectedActualClientId: number;
  selectedClient: Location;
  selectedLocation: Location;
  selectedSubclientId: number;
  selectedSubclient: Subclient = null;
  subclientCanControlAllReports = true;
  currentReportSubclientSettings: SubclientSetting = null;
  subclients: Subclient[] = [];
  selectedReportType: ReportType = null;
  selectedReportTypeId = 0;
  users: User[] = [];
  usersByLocation: User[] = [];
  selectedUser: User;
  isLoading: boolean;
  subclientSettingOptions: SubclientSettingOption[];
  subclientVisibilitySettingOptions: SubclientVisibilitySettingOption[];
  subclientIdsToAddForVisibility: SubclientVisibilitySettingSubclient[] = [];
  subclientViewingSettings: SubclientViewingSetting[];

  viewSettingLocationTree: Location[] = [];
  clientSubscription: Subscription;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  roleTemplateName: string;

  currentAction: Action;
  dialog: any = {
    isOpen: false,
    headerText: '',
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    }
  };

  constructor(
    private _permissionService: PermissionService,
    private _roleService: RoleService,
    private _authService: AuthService,
    private _locationService: LocationService,
    private _notifier: NotifierService,
    private _userService: UserService,
    private _settingService: SettingService,
    private _subclientService: SubclientService,
    private _employeeService: EmployeeService,
    ) { }

  async ngOnInit() {
    this.clientSubscription = this._locationService.currentClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => { this.onClientSelected(message); });
    //console.log('Location Manager onInIt');
    await this.getReportTypes();
    this.getTemplateRoleName();
    this._employeeService.getAllEmployees();
    // this.onClientSelected();
    /*this.getLocations();
    this.getLocationFullTree();
    this.topLocationToShow = 1;
    this.isLoading = true;
    this.getUsers(true);
    this.getSubclientSettingOptions();
    this.getSubclientVisibilitySettingOptions();*/
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
  }

  getTemplateRoleName() {
    this._roleService.getTemplateName(this._authService.userId()).subscribe((response) => {
      this.roleTemplateName = response;
    });
  }

  async onClientSelected(message: number) {
    if (message && message !== 0) {
      this.subclients = [];
      this.selectedClientId = +message;
      //console.log('Location Manager client Selected ' + this.selectedClientId);
      this.topLocationToShow = 1;
      await this.getLocationsAndFinishLoading();
    }
  }

  async getLocationsAndFinishLoading() {
    this._locationService.getAllLocations()
      .subscribe((response: Location[]) => {
        this.locations = response;
        this.selectedClient = this.locations.find(x => x.id === this.selectedClientId);
        this.selectedActualClientId = this.selectedClient.clientId;
        this.getLocationFullTree();
        this.isLoading = true;
        this.getUsers(true);
        this.getSubclientSettingOptions();
        this.getSubclientVisibilitySettingOptions();
        // this.updateVisibilitySetting();
      }, (error) => this._notifier.notify('error', error.error), () => { });
  }

  deleteSubclient(subclient: Subclient) {
    this._subclientService.deleteSubclient(subclient).subscribe((response) => {

    });
  }

  openEditSubclient(subclientId: number) {
    this.selectedSubclient = this.subclients.find(x => x.id === subclientId);
    this.dialog.isOpen = true;
    this.dialog.headerText = 'Edit Subclient';
    this.currentAction = Action.Update;
  }
  editSubclient() {
    this._subclientService.editSubclientSettings(this.selectedSubclient).subscribe((response) => {
      this.subclients = response;
    });
  }

  selectWhatWeSeeMenu() {

    this._subclientService.getSubclientVisibilityTree(this.selectedSubclientId, this.selectedReportTypeId).subscribe((response) => {
      this.viewSettingLocationTree = response;
      this._subclientService.getSubclientViewSettings(this.selectedSubclientId, this.selectedReportTypeId).subscribe((subViewSet) => {
        this.subclientViewingSettings = subViewSet;
      });
    });
  }

  selectWhatWeShowMenu() {
    // this.selectedReportTypeId = 0;
    this.onSubclientSelected();
  }

  lookUpViewingSetting(locationId: number) {
    if (this.subclientViewingSettings.length > 0) {
      const lookup = this.subclientViewingSettings.find(x => x.locationId === locationId);
      if (lookup) {
        return lookup.subclientViewingSettingOptionId;
      }
    }
    return 0;
  }
  updateVisibilitySetting() {
    const visibilitySetting = new SubclientVisibilitySetting();
    visibilitySetting.clientId = this.selectedClientId;
    visibilitySetting.locationId = this.selectedLocation.id;
    visibilitySetting.reportTypeId = this.selectedReportTypeId;
    visibilitySetting.subclientId = this.selectedSubclient.id;
    visibilitySetting.subclientVisibilitySettingOptionId = this.selectedLocation.subclientVisibilitySettingOptionId;

    if (this.selectedLocation.subclientVisibilitySettingOptionId === 4) {
      visibilitySetting.subclientVisibilitySettingSubclients = this.subclientIdsToAddForVisibility;
    }
      this._subclientService.setSubclientVisibilitySettingOptions(visibilitySetting).subscribe((response) => {
      this.subclients[this.subclients.findIndex(x => x.id === +this.selectedSubclientId)] = response;
      this.onSubclientSelected();
    });
  }
  /*
  updateVisibilitySettingSelectSubclients() {
    let visibilitySetting = new SubclientVisibilitySetting();
    visibilitySetting.clientId = this.selectedClientId;
    visibilitySetting.locationId = this.selectedLocation.id;
    visibilitySetting.reportTypeId = this.selectedReportTypeId;
    visibilitySetting.subclientId = this.selectedSubclient.id;
  }
  */

  addSubclientToListForVisibilitySetting(id: number, change: boolean) {
    const subclient = new SubclientVisibilitySettingSubclient();
    subclient.subclientId = id;
    if (change) {
      this.subclientIdsToAddForVisibility.push(subclient);
    } else {
      this.subclientIdsToAddForVisibility = this.subclientIdsToAddForVisibility.filter(x => x.subclientId !== id);
    }
  }


  getSubclientOptionForReport(reportType: ReportType) {

    if (this.selectedSubclient && this.selectedSubclient.subclientSetting &&
      this.selectedSubclient.subclientSetting.find(x => x.reportTypeId === reportType.id).subclientSettingOptionId === 2) {
      return true;
    }
    return false;
  }


  onSubclientSelected() {
    this.selectedSubclient = this.subclients.find(x => x.id === +this.selectedSubclientId);
    this.locationsForSelectedSubclient = this.trimFullTree(this.locations.find(y => y.id === this.selectedSubclient.locationId));
    this.selectedSubclient.subclientSetting.forEach(x => {
      if (x.subclientSettingOptionId === 2) {
        if (this.roleTemplateName === 'Client Owner' || this.roleTemplateName === 'Super Admin') {
          x.subclientSettingOptionId = 1;
        } else {
          this.subclientCanControlAllReports = false;
        }
      }
    });
    if (this.optionRadioSelected === 'subclient') {
      this.setVisibilityForSubclientLocations(this.locations2);
    } else {
      this.setVisibilityForSubclientLocations(this.locationsForSelectedSubclient);
    }
    this.currentReportSubclientSettings = this.selectedSubclient.subclientSetting.find(x => x.reportTypeId === this.selectedReportTypeId);
    if (this.optionRadioSelected === 'viewing') {
      if (this.selectedReportTypeId === 0) {
        this.currentReportSubclientSettings = null;
      }
      this.selectWhatWeSeeMenu();
    }
  }

  setVisibilityForSubclientLocations(locations: Location[]) {
    locations.forEach(x => {
      if (this.optionRadioSelected === 'subclient') {
        x.subclientVisibilitySettingOptionId = 0;
      } else if (this.selectedReportTypeId === 0) {
        const optionToLookFor = this.selectedSubclient.subclientVisibilitySetting.find(y => y.locationId === x.id)
          ? this.selectedSubclient.subclientVisibilitySetting.find(y => y.locationId === x.id).subclientVisibilitySettingOptionId
          : 0;
        if (optionToLookFor === 0) {
          x.subclientVisibilitySettingOptionId = 0;
        } else {
          x.subclientVisibilitySettingOptionId =
            this.selectedSubclient.subclientVisibilitySetting.filter(y => y.locationId === x.id
              && y.subclientVisibilitySettingOptionId === optionToLookFor).length === this.reportTypes.length
              ? this.selectedSubclient.subclientVisibilitySetting.find(y => y.locationId === x.id).subclientVisibilitySettingOptionId
              : 7;
            if (x.subclientVisibilitySettingOptionId === 4) {
              this.subclientIdsToAddForVisibility = [];
              if (this.selectedReportTypeId === 0) {
                let doTheyAllMatch = true;
                const subclientSettingsPerReport = this.selectedSubclient.subclientVisibilitySetting.filter(y => y.locationId === x.id);
                // .subclientVisibilitySettingSubclients;
                const firstSubclient = subclientSettingsPerReport[0];
                for (let i = 1; i < this.reportTypes.length; i++) {
                  if (doTheyAllMatch &&
                    subclientSettingsPerReport[i].subclientVisibilitySettingSubclients.length
                    === firstSubclient.subclientVisibilitySettingSubclients.length) {
                    firstSubclient.subclientVisibilitySettingSubclients.forEach(z => {
                      if (subclientSettingsPerReport[i].subclientVisibilitySettingSubclients.find(a => a.subclientId === z.subclientId)) {

                      } else {
                        doTheyAllMatch = false;
                      }
                    });
                  } else {
                    doTheyAllMatch = false;
                  }

                }
                if (!doTheyAllMatch) {
                  x.subclientVisibilitySettingOptionId = 7;
                }
              }
          }
        }
      } else {
        x.subclientVisibilitySettingOptionId = this.selectedSubclient.subclientVisibilitySetting
          .find(y => y.locationId === x.id && y.reportTypeId === this.selectedReportTypeId)
          ? this.selectedSubclient.subclientVisibilitySetting
            .find(y => y.locationId === x.id && y.reportTypeId === this.selectedReportTypeId).subclientVisibilitySettingOptionId
          : 0;
      }
      if (x.subclientVisibilitySettingOptionId === 0 || x.subclientVisibilitySettingOptionId === 7) {
        this.setVisibilityForSubclientLocations(x.children);
      }
    });
  }

  searchLocation(array?: any) {
    array = array ? array : this.locations2[0];

    for (const key in array) {

      if (key === 'name') {
        if (array.name.toLowerCase().indexOf(this.locationSearchString.toLowerCase()) > -1) {
          array.isFiltered = false;
        } else {
          array.isFiltered = true;
        }
        array.children.forEach((child) => { this.searchLocation(child); });
      }

    }
  }

  getUserNameById(id: string) {
    return this.users.find(x => x.id === id).name;
  }
  getLocationNameById(id: number) {
    return this.locations.find(x => x.id === id).name;
  }
  getReportNameById(id: number) {
    return this.reportTypes.find(x => x.id === id)?.name;
  }

  getReportTypes() {
    this._settingService.getReportTypes().subscribe((response) => {
      //console.log('Location Manager Reports Showed Up!');
      this.reportTypes = response.filter(x => x.hasLocationSettings);
    });
  }

  getSubclientSettingOptions() {
    this._subclientService.getSubclientSettingOptions().subscribe((response) => {
      this.subclientSettingOptions = response;
    });
  }

  getSubclientVisibilitySettingOptions() {
    this._subclientService.getSubclientVisibilitySettingOptions().subscribe((response) => {
      this.subclientVisibilitySettingOptions = response;
    });
  }

  getSubclients() {
    this._subclientService.getSubclientsByClientId(this.selectedActualClientId).subscribe((response) => {
      this.subclients = response;
      if (this.roleTemplateName !== 'Client Owner' && this.roleTemplateName !== 'Super Admin') {
        this._roleService.getSubclientOfUser(this._authService.userId()).subscribe((subId) => {
          this.selectedSubclientId = subId;
          this.selectedSubclient = this.subclients.find(x => x.id === this.selectedSubclientId);
          this.onSubclientSelected();
        });
      } else {
        this.selectedSubclientId = this.subclients[0].id;
        this.selectedSubclient = this.subclients[0];
      }
    });
  }

  createSubclient() {
    this._subclientService.createSubclient(this.selectedUser.id, this.selectedLocation.id)
      .subscribe((response) => { this.subclients = response; });
  }

  getLocationFullTree() {
    const tierId = 4;
    this._locationService.getLocationTree(this.selectedActualClientId, tierId)
      .subscribe((response: Location[]) => {
        this.locationsForSelectTree = response;
        this.locations2 = this.trimFullTree();
      }, (error) => this._notifier.notify('error', error.error), () => { });
  }
  getUsers(hardReload: boolean = false) {
    this._userService.getUsersByClient(async (response)  => {
      this.users = await response;
      if (this.selectedLocation) {
        let locs = this.locations.filter(x => x.id === this.selectedLocation.id);
        let tier = locs[0].locationTypeCodeId;
        while (tier < 4) {
          tier++;
          const tempLoc = [];
          locs.forEach(loc => {
            Array.prototype.push.apply(tempLoc, this.locations.filter(x => x.parentId === loc.id));
          });
          locs = tempLoc;
        }
        const locIds = locs.map(x => x.id);
        this._employeeService
          .getEmployeeUserIdsByLocations(locIds, (empResponse) => {
            const emps: string[] = empResponse;
            this._employeeService.getAllEmployees().subscribe((allEmpResponse) => {
              let notEmployeeUserList = this.users.filter(x => !allEmpResponse.map(y => y.userId).includes(x.id));
              this.usersByLocation = this.users.filter(x => emps.includes(x.id));
              notEmployeeUserList.forEach(z => {
                this.usersByLocation.push(z);
              });
            });
          });
      }
      this.getSubclients();

    }, this.selectedActualClientId, hardReload);
  }

  trimBottomOfTree(l: Location) {
    (l.locationTypeCodeId < 5) ?
      l.children.forEach((c) => { c = this.trimBottomOfTree(c); })
      : l.children = [];
    return l;
  }

  trimTopOfTree(l: Location, array: Location) {

    if (array.id === l.id && l.locationTypeCodeId !== 5) {
      this.locationTreeToView = l;
    } else {
      l.children.forEach((child) => this.trimTopOfTree(child, array));
    }

  }

  trimFullTree( topLocation?) {

    let locationResponse = [];
    this.locationTreeToView = this.cloneLocationTree(this.locationsForSelectTree[0]);
    this.trimBottomOfTree(this.locationTreeToView);

    if (topLocation) {
      this.trimTopOfTree(this.locationTreeToView, topLocation);
    }

    locationResponse = [this.markSelectedLocations(this.locationTreeToView)];
    return locationResponse;

  }


  cloneLocationTree(obj) {
    if (obj === null || typeof (obj) !== 'object') {
      return obj;
    }
    const temp = new obj.constructor();
    for (const key in obj) {
      if (true) {
        temp[key] = this.cloneLocationTree(obj[key]);
      }
    }
    return temp;
  }


  markSelectedLocations(lo) {
      lo.selected = false;
    if (typeof lo.children !== 'undefined') {
      lo.children.forEach((c) => {
        c = this.markSelectedLocations(c);
      });
    } else {
      lo.children = [];
    }
    return lo;
  }

  onLocationSelected(location: Location) {
    //console.log(location);
    this.selectedLocation = location;
    this.getUsers(true);
    //console.log('control all reports ' + this.subclientCanControlAllReports);
    //console.log('this.selectedReportTypeId' + this.selectedReportTypeId);
    //console.log(this.currentReportSubclientSettings);
    if (location.subclientVisibilitySettingOptionId === 4 &&
      this.selectedSubclient.subclientVisibilitySetting
        .find(y => y.locationId === location.id && y.reportTypeId === this.selectedReportTypeId)) {

      this.subclientIdsToAddForVisibility = [];

      const subclients = this.selectedSubclient.subclientVisibilitySetting
        .find(y => y.locationId === location.id && y.reportTypeId === this.selectedReportTypeId)
        .subclientVisibilitySettingSubclients;

        this.subclients.forEach(x => {
          if (subclients.find(y => y.subclientId === x.id)) {
            x.selected = true;
            this.addSubclientToListForVisibilitySetting(x.id, true);
          } else {
            x.selected = false;
          }
        });
    }
    if (this.optionRadioSelected === 'viewing' &&
      ((this.roleTemplateName === 'Client Owner' || this.roleTemplateName === 'Super Admin')
        || ((this.subclientCanControlAllReports && this.selectedReportTypeId === 0)
        || (this.currentReportSubclientSettings.subclientSettingOptionId === 1)))) {
      this._subclientService.setSubclientViewSettings(this.selectedSubclientId, this.selectedLocation.id, this.selectedReportTypeId)
        .subscribe((response) => {
        this.subclientViewingSettings = response;
      });
    }
    // location.selected = !location.selected;


    // HIDES AND UNHIDES TREES
      // this.updateChildrenAfterSelect(location);
    // this.updateChildrenAfterDeselect(this.selectedLocation);
  }

  updateChildrenAfterSelect(location: Location) {
    location.children.forEach((child) => {
      child.isVisible = false;
      this.updateChildrenAfterSelect(child);
    });
  }

  updateChildrenAfterDeselect(location: Location) {
    for (const locChild in location.children) {
      if (location.children[locChild].selected) {
        location.children[locChild].isVisible = true;
      } else {
        location.children[locChild].isVisible = true;
        this.updateChildrenAfterDeselect(location.children[locChild]);
      }
    }
  }

}
