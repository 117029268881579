<div class="box-typical box-typical-padding">
  <div class="table-container">
    <div class="d-flex align-items-center action-header">
      <mat-form-field class="bottom-border-without-bg filter-action-width">
        <input matInput placeholder="Name" [(ngModel)]="searchModel.name">
      </mat-form-field>
      <mat-form-field class="bottom-border-without-bg ml-5 filter-action-width">
        <input matInput placeholder="Username" [(ngModel)]="searchModel.userName">
      </mat-form-field>
      <mat-form-field class="bottom-border-without-bg ml-5 filter-action-width">
        <mat-select placeholder="Role" [(ngModel)]="searchModel.roleId" [(value)]="searchModel.roleId">
          <mat-option [value]="undefined">Select Role</mat-option>
          <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="bottom-border-without-bg ml-5 filter-action-width" style=" margin-right: 10px">
        <mat-select placeholder="Status" [(ngModel)]="searchModel.status" [(value)]="searchModel.status">
          <mat-option [value]="0">Select Status</mat-option>
          <mat-option *ngFor="let userStatusCode of userStatusCodes" [value]="userStatusCode.id">{{userStatusCode.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="table-container mt-3">
    <div class="d-flex align-items-center action-header justify-content-xl-center">
      <button mat-raised-button color="primary" class="mr-3" (click)="searchUsers(false)">Search Users</button><br /><br />
      <button mat-raised-button color="primary" class="mr-3" (click)="searchUsers(true)">Search Special Users</button><br /><br />
      <button mat-raised-button color="primary" class="mr-3" (click)="addUser()">Add User</button>
    </div>
  </div>
  <div class="row mt-3 pt-1">
    <div class="col-12">
      <div class="table-container">
        <table #table mat-table [dataSource]="dataSource" multiTemplateDataRows class="table table-bordered table-hover small-txt" matSort matSortActive="symbol" matSortDirection="asc">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="sort-column mat-sort-header-button-custom mat-header-cell" mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let item; let i = index;"><span style="margin-right:5px">{{item.name}}</span></td>
          </ng-container>
          <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef class="sort-column mat-sort-header-button-custom mat-header-cell" mat-sort-header>User name</th>
            <td mat-cell *matCellDef="let item; let i = index;"><span style="margin-right:5px">{{item.userName}}</span></td>
          </ng-container>
          <ng-container matColumnDef="client">
            <th mat-header-cell *matHeaderCellDef class="sort-column mat-sort-header-button-custom mat-header-cell">Client</th>
            <td mat-cell *matCellDef="let item; let i = index;"><span style="margin-right:5px">{{item.client.name}}</span></td>
          </ng-container>
          <ng-container matColumnDef="userStatusCode">
            <th mat-header-cell *matHeaderCellDef class="sort-column mat-sort-header-button-custom mat-header-cell">Status</th>
            <td mat-cell *matCellDef="let item; let i = index;"><span style="margin-right:5px">{{item.userStatusCode.name}}</span></td>
          </ng-container>
          <ng-container matColumnDef="userRoles">
            <th mat-header-cell *matHeaderCellDef class="sort-column mat-sort-header-button-custom mat-header-cell" mat-sort-header>Role</th>
            <td mat-cell *matCellDef="let item; ">{{(item.userRoles?.length > 0) ? item.userRoles[0].role.name : ''}}</td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="sort-column mat-sort-header-button-custom mat-header-cell">Actions</th>
            <td mat-cell *matCellDef="let item; let i = index;">
              <button mat-icon-button (click)="editUser(item)"><i class="fa fa-edit"></i></button>
              <button mat-icon-button (click)="deleteUser(item)"><mat-icon>delete</mat-icon></button>
              <button mat-icon-button (click)="resetPassword(item)"><i class="fa fa-unlock"></i></button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
          <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
        </table>
        <div *ngIf="noData | async" class="no-records">No records found</div>
        <mat-paginator #paginator="matPaginator" [pageSizeOptions]="[5, 10, 20]" [pageSize]="10" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
</div>

