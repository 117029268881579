<style>
  /* Ensure parent containers have 100% height */
  html, body, .tbl, .tbl-row, .tbl-cell, .section-header {
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .chart-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    height: calc(100vh - 300px); /* Adjust based on your header and footer height */
  }

  .responsive-chart {
    height: 100% !important;
    width: 100% !important;
  }

  .autocomplete-panel {
    min-height: 600px !important; /* Increase max-height as needed */
  }

  .mat-form-field {
    width: 100%; /* Ensure the form field takes the full width of its container */
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-bottom: 20px; /* Adds some space between form fields */
    padding: 10px; /* Adds padding inside the form field */
  }

  .mat-form-field-appearance-fill .mat-form-field-flex,
  .mat-select,
  .mat-select .mat-select-trigger {
    background-color: white;
  }
  ::ng-deep .mat-autocomplete-panel-long {
    min-height: 200px; /* Minimum height to ensure it starts expanded */
    max-height: 600px !important; /* Maximum height to control overflow */
    overflow-y: auto; /* Add scrollbar if content exceeds max-height */
  }

  ::ng-deep .mat-select-panel {
    max-height: 30vh !important;
  }

  /*.mat-autocomplete-panel {
    min-width: 300px;*/ /* Set a minimum width for the autocomplete panel */
    /*min-height: 600px !important;*/ /* Ensure the panel height is enforced */
    /*overflow-y: auto;*/ /* Add a scrollbar if the content exceeds the max-height */
  /*}*/

  .mat-option {
    white-space: normal; /* Allow text to wrap */
    height: auto; /* Adjust the height of each option */
    line-height: 1.5; /* Adjust line height if needed */
  }

  .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .mat-form-field {
    flex: 1;
    margin-right: 10px;
  }

    .mat-form-field:last-child {
      margin-right: 0;
    }

  .mat-form-field-appearance-fill .mat-form-field-wrapper {
    padding: 0 12px;
  }

  .mat-form-field-appearance-fill .mat-form-field-underline {
    background-color: transparent;
  }

    .mat-form-field-appearance-fill .mat-form-field-underline:after {
      background-color: #3f51b5; /* Change to your desired color */
    }

  .mat-select {
    background-color: white;
  }

    .mat-select .mat-select-trigger {
      background-color: white;
    }

  mat-form-field {
    position: relative;
  }

  mat-icon-button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
</style>

<header class="section-header">
  <div class="tbl">
    <div class="tbl-row">
      <div class="tbl-cell">
        <!--<h3>Dashboard</h3>-->
      </div>
      <div class="tbl-cell">
        <div class="row table-header">
          <!-- Formula Input with Autocomplete -->
          <mat-form-field style="flex: 0 0 30%; margin-right: 10px; position: relative; width: 100%;">
            <input type="text"
                   matInput
                   placeholder="Select Formula"
                   [matAutocomplete]="auto"
                   [(ngModel)]="dashboard.formulaName"
                   (input)="filterFormulas($event.target.value)"
                   (focus)="clearFilter()">
            <mat-autocomplete #auto="matAutocomplete" class="mat-autocomplete-panel-long" (optionSelected)="onFormulaSelect($event.option.value)">
              <mat-option *ngFor="let formula of filteredFormulas" [value]="formula">
                <span>{{formula.name}}</span>
                <button mat-icon-button matSuffix (click)="toggleFavorite(formula); $event.stopPropagation()">
                  <mat-icon>{{ isFavorite(formula) ? 'star' : 'star_border' }}</mat-icon>
                </button>
              </mat-option>
            </mat-autocomplete>
            <button mat-icon-button matSuffix (click)="auto.openPanel()">
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
          </mat-form-field>

          <!-- Location Tier -->
          <mat-form-field style="flex: 0 0 15%; margin-right: 10px;">
            <mat-select placeholder="Location Tier" [(value)]="dashboard.locationTierFocusId" (selectionChange)="onLocationTierChange($event.value);">
              <!--<mat-option [value]="2">Region</mat-option>-->
              <mat-option [value]="3">District</mat-option>
              <mat-option [value]="4">Store</mat-option>
              <mat-option [value]="5">Employee</mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Date Type -->
          <mat-form-field style="flex: 0 0 15%; margin-right: 10px;">
            <mat-select placeholder="Date Type" [(value)]="dashboard.dateTypeId" (selectionChange)="onDateTypeChange($event.value)">
              <mat-option [value]="21">6 Months</mat-option>
              <mat-option [value]="22">12 Months</mat-option>
              <mat-option [value]="25">24 Months</mat-option>
              <mat-option [value]="23">This Year</mat-option>
              <mat-option [value]="24">Last Year</mat-option>
              <mat-option [value]="28">3 Years</mat-option>
              <mat-option [value]="29">5 Years</mat-option>
            </mat-select>
          </mat-form-field>
          <!--<button mat-raised-button class="primary-action-btm ml-4 px-4" color="primary" type="button" (click)="getSingleDashboard();">Submit</button>-->
          <!-- Hidden Legend Items Dropdown -->
          <mat-form-field style="flex: 0 0 15%; margin-left: 10px;">
            <input type="text" matInput placeholder="Hidden Items" [matAutocomplete]="autoHidden" [(ngModel)]="selectedHiddenItem" (focus)="clearHiddenFilter()" (input)="filterHiddenItems($event.target.value)">
            <mat-autocomplete class=".mat-autocomplete-panel-long" #autoHidden="matAutocomplete" (optionSelected)="showDataset($event.option.value)">
              <mat-option *ngFor="let hiddenItem of filteredHiddenItems" [value]="hiddenItem">
                {{hiddenItem}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</header>

<div class="chart-container">
  <canvas baseChart
          [data]="lineChartData"
          [options]="lineChartOptions"
          [type]="lineChartType"
          class="responsive-chart">
  </canvas>
</div>

