
<button mat-icon-button class="close-button" (click)="onDismiss()">
  <mat-icon class="close-icon" >close</mat-icon>
</button>
<h1 mat-dialog-title class="heading-text">{{title}}</h1>
<form #f="ngForm" >
  <div class="d-flex ">
   <div>
    <div class="custom-input-box create-form-group-width-set">
      <input matInput type="text" [(ngModel)]="skuFilter.name" #name="ngModel" name="name" placeholder="Name" required />
    </div>
    <mat-error class="mt-1" *ngIf="name.invalid">Sku filter name is required.</mat-error>

   </div>
   <div class="custom-input-box create-form-group-width-set ml-3">
    <mat-select placeholder="Type" [(ngModel)]="skuFilter.skuFilterType.id" #skuFilterType="ngModel" name="skuFilterType" (selectionChange)="onSkuFilterTypeSelected()">
      <mat-option *ngFor="let type of skuFilterTypes" [value]="type.id">{{type.name}}</mat-option>
    </mat-select>
  </div>
  </div>
  <div class="form-control-static mt-3">
    <span class="virtual-input-box">{{ (+skuFilter.skuFilterType.id === 1) ? getProductCatalogName(+skuFilter.value) : getCategoryName(+skuFilter.value)}}</span>
    <div class="list-control" *ngIf="skuFilter.skuFilterType.id === 1">
      <input type="text" class="form-control" name="productCatalogSearchString" [(ngModel)]="productCatalogSearchString" (keyup)="searchProductCatalog()" />
      <ul>
        <li [ngClass]="{'d-block': productCatalog.isVisible, 'd-none': !productCatalog.isVisible}"
            *ngFor="let productCatalog of productCatalogs" (click)="selectProductCatalog(productCatalog)">
          {{productCatalog.productName}} ({{productCatalog.productSku}})
        </li>
      </ul>
    </div>
    <div class="tree-control" *ngIf="skuFilter.skuFilterType.id === 2">
      <input type="text" class="form-control" name="categorySearchString" [(ngModel)]="categorySearchString" (keyup)="searchCategory()">
      <ul>
        <li *ngFor="let category of categories">
          <ng-template [ngTemplateOutlet]="categoryTemplateRef"
                       [ngTemplateOutletContext]="{ $implicit: category }">
          </ng-template>
        </li>
      </ul>
    </div>
  </div>
</form>

<div class="d-flex align-items-center mt-4 pt-1">
  <button mat-raised-button color="primary" class="submit-btn" (click)="onConfirm()" [disabled]="!skuFilter.name">Submit</button>
  <button mat-button class="clear-btn ml-3" (click)="onDismiss()">Clear</button>
</div>

<ng-template #categoryTemplateRef let-category>
  <a [ngClass]="{'d-block': category.isVisible, 'd-none': !category.isVisible}" (click)="selectCategory(category)">
    <span *ngIf="category.children.length">
      <i class="fas fa-angle-down"></i>
    </span>
    {{ category.name }}
  </a>
  <ul>
    <li *ngIf="category.children.length">
      <ng-template ngFor
                   [ngForOf]="category.children"
                   [ngForTemplate]="categoryTemplateRef">
      </ng-template>
    </li>
  </ul>
</ng-template>

