<header class="section-header">
  <div class="tbl">
    <div class="tbl-row">
      <div class="tbl-cell">
        <h3>Inventory</h3>
      </div>
    </div>
  </div>
</header>

<div class="box-typical box-typical-padding" >
  <div class="row">
    <div class="col-sm-2">
      <input type="button" value="makegraph" (click)="buildComplicatedChart()" /> Make chart
      <!--
        <form>
          <div class="col-sm-12">
            <fieldset class="form-group">
              <label class="form-label semibold" for="selectedClient">Client:</label>
              <select *ngIf="_authService.isSuperAdmin()" class="form-control" name="selectedClientId" [(ngModel)]="selectedClientId" (change)="onClientSelected();" [disabled]="localStorageLoadingInProgress">
                <option [value]="0">Select Client</option>
                <option *ngFor="let client of clients" [value]="client.id">{{client.name}}</option>
              </select>
              <div *ngIf="!_authService.isSuperAdmin()">{{clientName}}</div>
            </fieldset>
          </div>
          <div class="col-sm-12">
            <fieldset class="form-group">
              <label class="form-label semibold" for="selectedRegion">Region:</label>
              <select class="form-control" name="selectedRegionId" [(ngModel)]="selectedRegionId" (change)="onRegionSelected();" [disabled]="localStorageLoadingInProgress">
                <option [value]="0">Select Region</option>
                <option *ngFor="let region of regions" [value]="region.id">{{region.name}}</option>
              </select>
            </fieldset>
          </div>
          <div class="col-sm-12">
            <fieldset class="form-group">
              <label class="form-label semibold" for="selectedDistrict">District:</label>
              <select class="form-control" name="selectedDistrictId" [(ngModel)]="selectedDistrictId" (change)="onDistrictSelected();" [disabled]="localStorageLoadingInProgress">
                <option [value]="0">Select District</option>
                <option *ngFor="let district of districts" [value]="district.id">{{district.name}}</option>
              </select>
            </fieldset>
          </div>
          <div class="col-sm-12">
            <fieldset class="form-group">
              <label class="form-label semibold" for="selectedStore">Store:</label>
              <select class="form-control" name="selectedStoreId" [(ngModel)]="selectedStoreId" (change)="onStoreSelected();" [disabled]="localStorageLoadingInProgress">
                <option [value]="0">Select Store</option>
                <option *ngFor="let store of stores" [value]="store.id">{{store.name}}</option>
              </select>
            </fieldset>
          </div>
        </form>
    -->
      <div class="tree-control">
        <input type="text" class="form-control" name="categorySearchString" [(ngModel)]="categorySearchString" (keyup)="searchCategory()">
        <span *ngIf="categoryLoadingInProgress" class="loading-status">Loading...</span>
        <ul *ngIf="!categoryLoadingInProgress">
          <li *ngFor="let category of categories">
            <ng-template [ngTemplateOutlet]="categoryTemplateRef"
                         [ngTemplateOutletContext]="{ $implicit: category }">
            </ng-template>
          </li>
        </ul>
      </div>

    </div>

    <div id="px" style=" margin-left:auto; margin-right:auto; min-height:100%; min-width: 100%;">


    </div>


    <!--
  <div class="col-sm-10">
    <div class="col-sm-12">
      <table class="table table-bordered table-hover">
        <thead>
          <tr *ngIf="selectedCategoryName">
            <th [attr.colspan]="attributes.length"><i class="fa fa-list-alt"></i> {{selectedCategoryName}}'s Inventory</th>
          </tr>
          <tr>
            <ng-container *ngFor="let col of attributes">
              <th *ngIf="isLocationVisible(col.header)" [ngClass]="{ 'active-column': selectedStore && ( col.header == selectedStore.name) }">

              {{col.header}}
            </th>
            </ng-container>
          </tr>
        </thead>
        <tbody *ngIf="inventoryLoadingInProgress">
          <tr role="row">
            <td *ngFor="let col of attributes"><span class="loading-span-sm"></span></td>
          </tr>
        </tbody>
        <tbody *ngIf="!inventoryLoadingInProgress">

          <tr *ngFor="let inventory of inventories; let i=index" role="row">
            <ng-container *ngFor="let col of attributes">
              <td *ngIf="isLocationVisible(col.header)" [ngClass]="{ 'active-column': selectedStore && ( col.header == selectedStore.name) }">
                {{ inventory[col.name] }}
              </td>
            </ng-container>
          </tr>

        </tbody>
        <thead *ngIf="!inventoryLoadingInProgress">
          <tr *ngFor="let total of inventoryTotals">
            <ng-container *ngFor="let col of attributes">
              <th *ngIf="isLocationVisible(col.header)" [ngClass]="{ 'active-column': selectedStore && ( col.header == selectedStore.name) }">
                {{ total[col.name] }}
              </th>
            </ng-container>
  </tr>
        </thead>
      </table>
    </div>
  </div>
    </div>
  </div>
      -->
    <ng-template #categoryTemplateRef let-category>
      <a [ngClass]="{ 'd-block': category.isVisible, 'd-none': !category.isVisible }" (click)="onCategorySelected(category)">
        <span *ngIf="category.children.length">
          <i class="fas fa-angle-down"></i>
        </span>
        {{ category.name }}
        <span *ngIf="(category.children.length == 0)" class="counter"> {{category.inventory}} </span>
      </a>
      <ul>
        <li *ngIf="category.children.length">
          <ng-template ngFor
                       [ngForOf]="category.children"
                       [ngForTemplate]="categoryTemplateRef">
          </ng-template>
        </li>
      </ul>
    </ng-template>

