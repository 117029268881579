<button mat-icon-button class="close-button" (click)="onDismiss()">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h1 class="heading-text" mat-dialog-title>{{title}}</h1>
<form #f="ngForm">
  <div class="custom-input-box create-role-width-set">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput type="text" [(ngModel)]="user.name" #name="ngModel" [ngClass]="{'is-invalid': f.submitted && name.invalid }" name="name" required />
      <mat-error *ngIf="name.invalid">Name is required</mat-error>
    </mat-form-field>
  </div>
  <div class="custom-input-box create-role-width-set mt-4 pt-1">
    <mat-form-field>
      <mat-label>User name</mat-label>
      <input matInput type="text" [(ngModel)]="user.userName" #userName="ngModel" [ngClass]="{'is-invalid': f.submitted && userName.invalid }" name="userName" required />
      <mat-error *ngIf="userName.invalid">User name is required</mat-error>
    </mat-form-field>
  </div>
  <div class="custom-input-box create-role-width-set mt-4 pt-1">
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input matInput type="email" [(ngModel)]="user.email" #email="ngModel" [ngClass]="{'is-invalid': f.submitted && email.invalid }" name="email" required />
      <mat-error *ngIf="email.invalid">Email is required</mat-error>
      <mat-error *ngIf="email.errors?.email">Email must be a valid email address.</mat-error>
    </mat-form-field>
  </div>
  <div *ngIf="!isEdit">
    <div class="custom-input-box create-role-width-set mt-4 pt-1">
      <mat-form-field>
        <mat-label>Password</mat-label>
        <input matInput type="password" [(ngModel)]="user.password" #password="ngModel" [ngClass]="{'is-invalid': f.submitted && password.invalid }" name="password" required minlength="8" />
        <mat-error *ngIf="password.invalid">Password is required</mat-error>
        <mat-error *ngIf="password.errors?.minlength">Password must be at least 8 characters.</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="custom-input-box create-role-width-set mt-4 pt-1">
    <mat-form-field>
      <mat-label>Select client</mat-label>
      <mat-select [ngClass]="{'is-invalid': f.submitted && clientId.invalid }" [(ngModel)]="user.clientId" #clientId="ngModel" name="clientId" required>
        <mat-option *ngFor="let client of clients" [value]="client.id">{{client.name}}</mat-option>
      </mat-select>
      <mat-error *ngIf="f.submitted && clientId.invalid">Client is required.</mat-error>
    </mat-form-field>
  </div>
  <div class="custom-input-box create-role-width-set mt-4 pt-1">
    <mat-form-field>
      <mat-label>Select status code</mat-label>
      <mat-select [ngClass]="{'is-invalid': f.submitted && userStatusCodeId.invalid }" [(ngModel)]="user.userStatusCodeId" #userStatusCodeId="ngModel" name="userStatusCodeId" required>
        <mat-option *ngFor="let userStatusCode of userStatusCodes" [value]="userStatusCode.id">{{userStatusCode.name}}</mat-option>
      </mat-select>
      <mat-error *ngIf="f.submitted && userStatusCodeId.invalid">User status code is required.</mat-error>
    </mat-form-field>
  </div>
  <div class="custom-input-box create-role-width-set mt-4 pt-1">
    <mat-form-field>
      <mat-label>Select role</mat-label>
      <mat-select [ngClass]="{'is-invalid': f.submitted && roleId.invalid }" [(ngModel)]="user.roleId" #roleId="ngModel" name="roleId" required>
        <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
      </mat-select>
      <mat-error *ngIf="f.submitted && roleId.invalid">Role is required.</mat-error>
    </mat-form-field>
  </div>
</form>
<div class="d-flex align-items-center mt-4 pt-1">
  <button mat-raised-button color="primary" class="submit-btn" (click)="onConfirm()">Submit</button>
  <button mat-button class="clear-btn ml-3" (click)="onDismiss()">Clear</button>
</div>
