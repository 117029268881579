<div class="col-md-12 bg-white mb-3 p-3">
  <div class="row">
    <div class="col-md-3">
      <mat-form-field style="" class="searchtab">
        <input matInput (keyup)="applyFilter()" placeholder="Search" [(ngModel)]="searchTerm">
      </mat-form-field>

    </div>
    <div class="col-md-2">
      <mat-form-field style="" class="searchtab">
        <input matInput (keyup)="onFilterChange()" placeholder="SearchDifference" [(ngModel)]="searchTermDifference" [formControl]="myControl" [matAutocomplete]="auto">
        <mat-autocomplete (optionSelected)="onFilterChange()" autoActiveFirstOption #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

    </div>
    <div class="col-md-1">
      <mat-form-field>
        <mat-label>Filter</mat-label>
        <mat-select [(value)]="selectedFilter" (selectionChange)="onFilterChange()">
          <mat-option value="all">All</mat-option>
          <mat-option value="positive">Positive</mat-option>
          <mat-option value="negative">Negative</mat-option>
          <mat-option value="mtnMatch">MTN Match</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
      <div class="col-md-6 text-right pt-2">
        <button mat-raised-button (click)="sendListOfImeis(2)" style=" margin-right: 10px" color="primary" class="charge-btn">Chargeback</button>&nbsp;
        <button mat-raised-button (click)="sendListOfImeis(1)" style=" margin-right: 10px" color="primary" class="dismiss-btn">Dismiss</button>&nbsp;
        <button mat-raised-button (click)="sendListOfImeis(3)" style=" margin-right: 10px" color="primary" class="discrep-btn">Discrepancy</button>&nbsp;
        <button mat-raised-button (click)="exportTableToExcel()" style=" margin-right: 10px" color="primary" class="export-btn">Export</button>

      </div>
    </div>
  </div>
<div class="row">
  <div class="col-md-6" >
    <div class="m-1">
      <div class="table-container">
        <table mat-table [dataSource]="dataSource" matSort matSortActive="symbol" matSortDirection="asc" class="table small-txt">
          <ng-container matColumnDef="{{column}}" *ngFor="let column of getDisplayedColumns(); let i = index;">
            <span *ngIf="i === 0">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-sort-header-button-custom">
              <mat-checkbox (change)="$event?selectAll($event):null" [checked]="allAreSelected" [disabled]="noData| async"></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row" [ngClass]="{'yellow':row?.imei==selectedItem?.imei && row?.mtn == selectedItem?.mtn}">
              <mat-checkbox (click)="$event.stopPropagation()"  (change)="$event ? selectImei(row):null" [checked]="row.isSelected"></mat-checkbox>
            </td>
            </span>
            <span *ngIf="i !== 0">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [matTooltip]="getColumnDefinationByMappedToProperty(column).name" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip  class="mat-sort-header-button-custom">
              {{getColumnDefinationByMappedToProperty(column).name}}
            </th>
            <ng-container *ngIf="column !== 'commissionIMEINotes' && column !== 'imei'">
              <td mat-cell *matCellDef="let element" [matTooltip]="element[getColumnDefinationByMappedToProperty(column).mappedToProperty]" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip
                  [ngClass]="{'yellow':element.imei==selectedItem.imei && element.mtn == selectedItem.mtn}">
                {{getTextBydata(element,getColumnDefinationByMappedToProperty(column))}}
              </td>
            </ng-container>
            <ng-container *ngIf="column === 'imei'">
              <td mat-cell *matCellDef="let element" [matTooltip]="getTextBydata(element,getColumnDefinationByMappedToProperty(column))" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip
                  [ngClass]="{'yellow':element.imei==selectedItem.imei && element.mtn == selectedItem.mtn}">
                <span *ngIf="getTextBydata(element,getColumnDefinationByMappedToProperty(column)).length > 0" (click)="openHistoryDialog(element)"><a>{{getTextBydata(element,getColumnDefinationByMappedToProperty(column))}}</a></span>
              </td>
            </ng-container>
            <ng-container *ngIf="column === 'commissionIMEINotes'">
              <td mat-cell *matCellDef="let element"  [matTooltip]="getTextBydata(element,getColumnDefinationByMappedToProperty(column))" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip
                  [ngClass]="{'yellow':element.imei==selectedItem.imei && element.mtn == selectedItem.mtn}"
                  (click)="openNotesDialog(column,element)">
                <span><div class="fa fa-sticky-note">{{getTextBydata(element,getColumnDefinationByMappedToProperty(column))}}</div></span>
              </td>
            </ng-container>
            </span>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
          <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();" (click)="selectItem(row)"></tr>
        </table>
  
        <div *ngIf="noData | async" class="no-records">
          No records found
        </div>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="10" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  
  </div>
  <div class="col-md-6 pr-0">
    <div class="table-container w-100 m-1">
      <mat-divider [vertical]="true" *ngIf="selectedImei" class="margin-left-1em"></mat-divider>
      <div class="" *ngIf="selectedImei">
        <rq-vz-data-table [selectedImei]="selectedImei" *ngIf="selectedImei"></rq-vz-data-table>
      </div>
    </div>
  </div>
</div>
