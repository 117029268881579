import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRoutingModule } from './user-routing.module';
import { UserComponent } from './user/user.component';
import { RoleComponent } from './role/role.component';
import { ChangePasswordComponent } from './changepassword/changepassword.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { UserService } from './services/user.service';
import { RoleService } from './services/role.service';
import { UserStatusCodeService } from './services/user-status-code.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    UserRoutingModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    UserService,
    RoleService,
    UserStatusCodeService,
  ],
  declarations: [
    UserComponent,
    RoleComponent,
    ChangePasswordComponent
  ]
})
export class UserModule { }
