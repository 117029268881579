<button mat-icon-button class="close-button" (click)="onDismiss()">
  <mat-icon class="close-icon" >close</mat-icon>
</button>
<h1 mat-dialog-title class="heading-text">{{title}}</h1>
<div mat-dialog-content class="mt-3">{{message}}</div>
<div class="d-flex align-items-center mt-4 pt-1">
  <button mat-raised-button class="submit-btn" color="primary" (click)="onConfirm()">Delete</button>
  <button mat-button class="clear-btn ml-3" (click)="onDismiss()">Cancel</button>

</div>
