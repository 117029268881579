import { Inject, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'delete-formula-view-group',
  templateUrl: 'delete-formula-view-group.component.html',
})
export class DeleteFormulaViewGroupComponent implements OnInit {

  title: string;
  message: any;

  constructor(public dialogRef: MatDialogRef<DeleteFormulaViewGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.message = data.message;
  }

  ngOnInit() { }

  onConfirm(): void { this.dialogRef.close(true); }

  onDismiss(): void { this.dialogRef.close(false); }
}

