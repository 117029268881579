import { Injectable } from '@angular/core';
import { Duration } from '../models/duration';
import * as Moment from 'moment';
import { DateRange, extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

@Injectable({
  providedIn: 'root'
})
/*export class Time { hour: Number; minute: Number; seconds: Number; };*/
export class DurationService {

  dateStartFormat = 'YYYY-MM-DD 00:00:00';
  dateEndFormat = 'YYYY-MM-DD 23:59:59';
  format1 = 'MM-DD-YY';
  constructor() {
  }

  getMomentByDate(date) {
    return moment(date);
  }

  getDuration(): any {
    //console.log("getDuration");
    // #region variables
    const currentMonth = moment().format('MMMM');
    const lastMonth = moment().subtract(1, 'month').format('MMMM');
    let momentStart = moment().startOf('day');
    let momentEnd = moment().endOf('day');

    const today_duration: DateRange = moment.range(momentStart, momentEnd);

    const yesterdaystart = momentStart.subtract(1, 'day');
    const yesterdayend = momentEnd.subtract(1, 'day');
    const yesterday_duration: DateRange = moment.range(yesterdaystart, yesterdayend);
    momentStart = moment().startOf('day');
    momentEnd = moment().endOf('day');

    const previous_weekstart = momentStart.weekday(-6);
    const previous_weekend = momentEnd.weekday(0);
    const previous_week_duration: DateRange = moment.range(previous_weekstart, previous_weekend);
    momentStart = moment().startOf('day');
    momentEnd = moment().endOf('day');

    const current_month_start = momentStart.startOf('month');
    const current_month_end = momentEnd.endOf('month');
    const current_month_duration: DateRange = moment.range(current_month_start, current_month_end);
    momentStart = moment().startOf('day');
    momentEnd = moment().endOf('day');

    const previous_month_start = momentStart.subtract(1, 'months').startOf('month');
    const previous_month_end = momentEnd.subtract(1, 'months').endOf('month');
    const previous_month_duration: DateRange = moment.range(previous_month_start, previous_month_end);
    momentStart = moment().startOf('day');
    momentEnd = moment().endOf('day');

    //#endregion variables

    var durationList: Duration[] = [];
    durationList.push({ value: 0, viewValue: "Yesterday", duration: { FromDate: yesterday_duration.start.format(this.dateStartFormat).toString(), ToDate: yesterday_duration.end.format(this.dateEndFormat).toString() } });
    durationList.push({ value: 1, viewValue: "Today", duration: { FromDate: today_duration.start.format(this.dateStartFormat).toString(), ToDate: today_duration.end.format(this.dateEndFormat).toString() } });
    durationList.push({ value: 2, viewValue: "Last week", duration: { FromDate: previous_week_duration.start.format(this.dateStartFormat).toString(), ToDate: previous_week_duration.end.format(this.dateEndFormat).toString() } });
    durationList.push({ value: 3, viewValue: currentMonth /*current month*/, duration: { FromDate: current_month_duration.start.format(this.dateStartFormat).toString(), ToDate: current_month_duration.end.format(this.dateEndFormat).toString() } });
    durationList.push({ value: 4, viewValue: lastMonth /*previous month*/, duration: { FromDate: previous_month_duration.start.format(this.dateStartFormat).toString(), ToDate: previous_month_duration.end.format(this.dateEndFormat).toString() } });
    durationList.push({ value: 5, viewValue: "Other Months" /*other months*/, duration: { FromDate: null, ToDate: null } });
    durationList.push({ value: 6, viewValue: "Custom date range" /*custom date range*/, duration: { FromDate: null, ToDate: null } });

    return durationList;
  }

  getDurationExtra(): any {


    const currentMonth = moment().format('MMMM');
    const lastMonth = moment().subtract(1, 'month').format('MMMM');
    const nextMonth = moment().add(1, 'month').format('MMMM');
    let momentStart = moment().startOf('day');
    let momentEnd = moment().endOf('day');

    const today_duration: DateRange = moment.range(momentStart, momentEnd);


    momentStart = moment().startOf('day');
    momentEnd = moment().endOf('day');
    const days_ago_30 = momentStart.subtract(30, 'days');
    const days_ago_30_duration: DateRange = moment.range(days_ago_30, momentEnd);


    momentStart = moment().startOf('day');
    momentEnd = moment().endOf('day');
    const previous_weekstart = momentStart.subtract(7, 'days');
    const previous_week_duration: DateRange = moment.range(previous_weekstart, momentEnd);


    momentStart = moment().startOf('day');
    momentEnd = moment().endOf('day');
    const this_weekstart = momentStart.day(0);
    const this_weekend = momentEnd.day(6);
    const this_week_duration: DateRange = moment.range(this_weekstart, this_weekend);

    const previous_wholeweekstart = momentStart.day(-7);
    const previous_wholeweekend = momentEnd.day(-1);
    const previous_wholeweek_duration: DateRange = moment.range(previous_wholeweekstart, previous_wholeweekend);

    const this_yearstart = momentStart.startOf('year');
    const this_yearend = momentEnd.endOf('year');
    const this_year_duration: DateRange = moment.range(this_yearstart, this_yearend);


    momentStart = moment().startOf('day');
    momentEnd = moment().endOf('day');

    const current_month_start = momentStart.startOf('month');
    const current_month_end = momentEnd.endOf('month');
    const current_month_duration: DateRange = moment.range(current_month_start, current_month_end);
    momentStart = moment().startOf('day');
    momentEnd = moment().endOf('day');

    const previous_month_start = momentStart.subtract(1, 'months').startOf('month');
    const previous_month_end = momentEnd.subtract(1, 'months').endOf('month');
    const previous_month_duration: DateRange = moment.range(previous_month_start, previous_month_end);
    momentStart = moment().startOf('day');
    momentEnd = moment().endOf('day');
    const next_month_start = momentStart.add(1, 'months').startOf('month');
    const next_month_end = momentEnd.add(1, 'months').endOf('month');
    const next_month_duration: DateRange = moment.range(next_month_start, next_month_end);


    //#endregion variables

    var durationList: Duration[] = [];

    durationList.push({ value: 0, viewValue: "Today", duration: { FromDate: today_duration.start.format(this.dateStartFormat).toString(), ToDate: today_duration.end.format(this.dateEndFormat).toString() } });
    durationList.push({ value: 1, viewValue: "This week", duration: { FromDate: this_week_duration.start.format(this.dateStartFormat).toString(), ToDate: this_week_duration.end.format(this.dateEndFormat).toString() } });
    durationList.push({ value: 2, viewValue: currentMonth /*current month*/, duration: { FromDate: current_month_duration.start.format(this.dateStartFormat).toString(), ToDate: current_month_duration.end.format(this.dateEndFormat).toString() } });
    durationList.push({ value: 3, viewValue: "This Year", duration: { FromDate: this_year_duration.start.format(this.dateStartFormat).toString(), ToDate: this_year_duration.end.format(this.dateEndFormat).toString() } });
    durationList.push({ value: 4, viewValue: "Last 7 days", duration: { FromDate: previous_week_duration.start.format(this.dateStartFormat).toString(), ToDate: previous_week_duration.end.format(this.dateEndFormat).toString() } });
    durationList.push({ value: 5, viewValue: "Last 30 days", duration: { FromDate: days_ago_30_duration.start.format(this.dateStartFormat).toString(), ToDate: days_ago_30_duration.end.format(this.dateEndFormat).toString() } });
    durationList.push({ value: 6, viewValue: "Last week", duration: { FromDate: previous_wholeweek_duration.start.format(this.dateStartFormat).toString(), ToDate: previous_wholeweek_duration.end.format(this.dateEndFormat).toString() } });
    durationList.push({ value: 7, viewValue: lastMonth /*previous month*/, duration: { FromDate: previous_month_duration.start.format(this.dateStartFormat).toString(), ToDate: previous_month_duration.end.format(this.dateEndFormat).toString() } });
    durationList.push({ value: 8, viewValue: nextMonth /*previous month*/, duration: { FromDate: next_month_duration.start.format(this.dateStartFormat).toString(), ToDate: next_month_duration.end.format(this.dateEndFormat).toString() } });


    return durationList;
  }

  getDateRangeByMonth(month: number): DateRange {
    month = month + 1;
    var x = moment(month, "MM").startOf("month");
    var y = moment(month, "MM").endOf("month");
    const range = moment.range(x, y);
    return range;
  }

  getDateRangeByYear(year: string): DateRange {
    var x = moment(year, "YYYY MM").startOf("month");
    //x.set("year", year);
    var y = moment(year, "YYYY MM").endOf("month");
    const range = moment.range(x, y);
    return range;
  }

  getDateRangeByYearWithUTC(year: string): DateRange {
    var x = moment(year, "YYYY MM").startOf("month");
    //x.set("year", year);
    var y = moment(year, "YYYY MM").endOf("month");
    const range = moment.range(x, y);
    return range;
  }

  getMonths() {
    var monthsShorts = moment.monthsShort();
    var months = moment.months();
    return { monthsShort: monthsShorts, months: months };
  }

}
