import { RolePermission } from './rolePermission';
import { Permission } from './permission';

export class Role {
  id?: string;
  name: string;
  displayName: string;
  rolePermissions: RolePermission[];
  permissions: Permission[];
  childRoles: Role[];
  parentRoleId: string;
  settingRoleOption: any[];
  clientId: number;
  children:Role[];
}
