import { Injectable } from '@angular/core';
import { LocalStorage } from '../decorators/local-storage';

const TOKEN_KEY = 'AuthToken';

@Injectable()
export class TokenStorage {
    @LocalStorage
    rememberMe;

    constructor() {
    }

    saveToken(authResult: any) {
        if (this.rememberMe === 'true') {
            localStorage.setItem(TOKEN_KEY, authResult.token);
        } else {
            sessionStorage.setItem(TOKEN_KEY, authResult.token);
        }
    }

    getToken(): string {
        if (this.rememberMe === 'true') {
            return localStorage.getItem(TOKEN_KEY);
        } else {
            return sessionStorage.getItem(TOKEN_KEY);
        }
    }

    clearToken(): void {
        sessionStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(TOKEN_KEY);
    }
}
