<header class="section-header">
  <div class="tbl">
    <div class="tbl-row">
      <div class="tbl-cell">
        <h3>Role Permissions</h3>
      </div>
    </div>
  </div>
</header>
<div class="box-typical box-typical-padding">
  <form #f="ngForm" (ngSubmit)="f.form.valid && saveRoles()" novalidate>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">Select Role</label>
      <div class="col-sm-10">
        <p class="form-control-static">
          <select class="form-control width-30" [(ngModel)]="model.roleId" #roleId="ngModel" 
          [ngClass]="{'is-invalid': f.submitted && roleId.invalid }" name="roleId" 
          (change)="showPermissions()" required>
            <option *ngFor="let role of roles" [value]="role.id">{{role.name}}</option>
          </select>
          <span *ngIf="f.submitted && roleId.invalid" class="invalid-feedback">
            <span *ngIf="roleId.errors.required">Role is required.</span>
          </span>
        </p>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">Select Permissions</label>
      <div class="col-sm-10">
        <p class="form-control-static">
          <ng-multiselect-dropdown name="permissions" [settings]="permissionSelectSettings"
              [placeholder]="'Select Permissions'" [data]="permissions" 
              [(ngModel)]="model.selectedPermissions" #selectedPermissions="ngModel" 
              required>
          </ng-multiselect-dropdown>
          <span *ngIf="f.submitted && selectedPermissions.invalid" class="invalid-feedback">
            <span *ngIf="selectedPermissions.errors.required">Permissions is required.</span>
          </span>
        </p>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label"></label>
      <div class="col-sm-10">
        <p class="form-control-static">
          <button type="submit" class="btn btn-inline">Submit</button>
        </p>
      </div>
    </div>
  </form>
</div>
