import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgotpassword/forgotpassword.component';
import { ResetPasswordComponent } from './resetpassword/resetpassword.component';
import { CenterContentLayoutComponent } from '../core/center-content-layout/center-content-layout.component';
import { LoginGaurdService as LoginGaurd } from '../core/services/login-gaurd.service';

const routes: Routes = [
  {
    path: '',
    component: CenterContentLayoutComponent,
    children: [
      { path: '', component: LoginComponent, pathMatch: 'full', canActivate: [LoginGaurd] },
      { path: 'login', component: LoginComponent, canActivate: [LoginGaurd] },
      { path: 'forgotpassword', component: ForgotPasswordComponent },
      { path: 'resetpassword', component: ResetPasswordComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
