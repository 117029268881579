<style>
  /* .mat-column-reportfrom, .mat-column-date, .mat-column-parentdetail, .mat-column-dollarvalue, .mat-column-employee, .mat-column-notes {
    width: 16%;
  } */

  /* .mat-cell, .mat-header-cell {
    flex: auto;
  } */

  /* .cdk-overlay-pane {
    width: 40% !important;
  } */

  /* .bootstrap-table .table, .fixed-table-body .table, .table {
    margin-bottom: 1em;
  } */

  /* .mat-dialog-container {
    padding-top: 0 !important;
  }

  .mat-dialog-actions {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mat-dialog-title {
    margin-top: -24px !important;
    margin-right: -24px !important;
    margin-left: -24px !important;
    background-color: #3f51b5 !important;
  } */

  /* .dialog-title {
    color: #ffffff;
    margin-left: 10px;
    margin-top: 5px;
    font-size: 16px
  } */

  tr.detail-row {
    height: 0 !important;
  }

</style>

<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" >close</mat-icon>
</button>
<h6 mat-dialog-title><span class="dialog-title">Product History </span></h6>
<div class="mat-app-background">
  <div class="mat-elevation-z8" style=" min-width:50vw; padding-top: 1em; padding-left: 1em; padding-right:1em;">
    <div mat-dialog-content>
      <table #table mat-table [dataSource]="dataSource" multiTemplateDataRows class="table table-bordered table-hover small-txt" *ngIf="dataSource.data.length>0" matSort matSortActive="symbol" matSortDirection="asc">
        <ng-container matColumnDef="reportFrom">
          <th mat-header-cell *matHeaderCellDef class="sort-column mat-sort-header-button-custom mat-header-cell" mat-sort-header>Report</th>
          <td mat-cell *matCellDef="let item; let i = index;">{{item.reportFrom}}</td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef class="sort-column mat-sort-header-button-custom mat-header-cell" mat-sort-header>Date</th>
          <td mat-cell *matCellDef="let item; let i = index;"><span style="margin-right:5px">{{item.date}}</span></td>
        </ng-container>
        <ng-container matColumnDef="employee">
          <th mat-header-cell *matHeaderCellDef class="sort-column mat-sort-header-button-custom mat-header-cell" mat-sort-header>Employee</th>
          <td mat-cell *matCellDef="let item; let i = index;"><span style="margin-right:5px">{{item.employee}}</span></td>
        </ng-container>
        <ng-container matColumnDef="parentDetail">
          <th mat-header-cell *matHeaderCellDef class="sort-column mat-sort-header-button-custom mat-header-cell" mat-sort-header>Detail</th>
          <td mat-cell *matCellDef="let item; let i = index;"><span style="margin-right:5px">{{item.parentDetail}}</span></td>
        </ng-container>
        <ng-container matColumnDef="dollarValue">
          <th mat-header-cell *matHeaderCellDef class="sort-column mat-sort-header-button-custom mat-header-cell" mat-sort-header>Amount</th>
          <td mat-cell *matCellDef="let item; let i = index;"><span style="margin-right:5px">{{item.dollarValue}}</span></td>
        </ng-container>
        <ng-container matColumnDef="notes">
          <th mat-header-cell *matHeaderCellDef class="sort-column mat-sort-header-button-custom mat-header-cell" >Note</th>
          <td mat-cell *matCellDef="let item; let i = index;"><span *ngIf="item.commentItems.length > 0" style="margin-right:5px"><i style="margin-left:5px" class="fa fa-plus">{{item.commentItems.length}}</i></span></td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" class="example-detail-row" [class.hidden]="element.commentItems == 0 || element !== expandedElement" [attr.colspan]="displayedColumns.length">
            <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <table *ngIf="element.commentItems.length>0" mat-table [dataSource]="element.commentItems" class="table table-bordered table-hover small-txt">

                <ng-container matColumnDef="author">
                  <th mat-header-cell *matHeaderCellDef>User</th>
                  <td mat-cell *matCellDef="let item; let i = index;"><span style="margin-right:5px">{{item.author}}</span></td>
                </ng-container>
                <ng-container matColumnDef="dateTime">
                  <th mat-header-cell *matHeaderCellDef>Date</th>
                  <td mat-cell *matCellDef="let item; let i = index;"><span style="margin-right:5px">{{item.dateTime}}</span></td>
                </ng-container>
                <ng-container matColumnDef="message">
                  <th mat-header-cell *matHeaderCellDef>Note</th>
                  <td mat-cell *matCellDef="let item; let i = index;"><span style="margin-right:5px">{{item.message}}</span></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsNotes"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsNotes;">
              </table>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.example-expanded-row]="expandedElement === row"
            (click)="selectItem(row)"></tr>
        <tr mat-row *matRowDef="let dog; columns: ['expandedDetail']" class="detail-row"></tr>
      </table>
    </div>
  </div>
</div>

