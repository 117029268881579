
import { Inject, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { CommissionUser } from '../models/commission-user';
import { Employee } from '../models/employee';
import * as cloneDeep from 'lodash/cloneDeep';
import { CommissionUserService } from '../services/commission-user.service';
import { Location } from '../../home/models/location';
import { LocationService } from '../../home/services/location.service';
import { EmployeeService } from '../../home/services/employee.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'copy-commission-dialog',
  templateUrl: 'copy-commission-dialog-component.html',
})
export class CopyCommissionDialogComponent implements OnInit {
  commissionUser: CommissionUser
  locationEmployees: Employee[];
  copiedCommissionUserEmployeeId: number;
  copiedCommissionUserDuration: string;
  employees: Employee[];
  selectedClientId: number;
  selectedStoreId: number;
  selectedStore: Location;
  stores: Location[];
  selectedEmployeeId: number;
  selectedEmployee: Employee;
  filteredEmployees: Employee[] = [];
  selectedEmployees = new FormControl();

  constructor(private dialogRef: MatDialogRef<CopyCommissionDialogComponent>, private _notifier: NotifierService,
    private _commissionUserService: CommissionUserService, private _locationService: LocationService,
    private _employeeService: EmployeeService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit() {
    this.commissionUser = this.data.commissionUser;
    this.selectedEmployeeId = this.commissionUser.employeeId;
    //console.log('com user ' + this.commissionUser);
    this.locationEmployees = this.filteredEmployees = this.data.locationEmployees;
    this.copiedCommissionUserEmployeeId = this.data.copiedCommissionUserEmployeeId;
    this.copiedCommissionUserDuration = this.data.copiedCommissionUserDuration;
    this.selectedClientId = this.data.selectedClientId;
    this.selectedStoreId = this.data.selectedStoreId;
    this.stores = this.data.stores;
    this._employeeService.getEmployeeUsersByLocations(
      this.stores.map(x => x.id), (response) => {
        this.employees = response;
    });
    
  }

  onStoreSelected() {
    this.selectedStoreId = +this.selectedStoreId;
    this.selectedStore = this.stores.find(s => s.id === this.selectedStoreId);
    this.trickleThroughLocationChange(4, 5);
    
  }

  trickleThroughLocationChange(tierId: number, limitToGo: number) {
    if (tierId < 5 && limitToGo > 4) {
      if (this.selectedStoreId === 0) {
        this._employeeService.getEmployeeUsersByLocations(
          this.stores.map(x => x.id), (response) => {
            this.filteredEmployees = response;
          });
      } else {
        this.filteredEmployees = this.employees.filter(x => x.locationId === this.selectedStoreId);
      }
      this.copiedCommissionUserEmployeeId = this.selectedEmployeeId = this.filteredEmployees[0].id; 
    }
  }

  copyUserConfirm() {
    if (this.commissionUser.commissionGroups.length === 0) {
      this._notifier.notify('warning', 'Please add commission groups');
      return;
    }

    this.selectedEmployees.value.forEach((y) => {
      const employee = this.employees.find(e => e.id === y.id);
      const user = cloneDeep(this.commissionUser);
      user.employeeId = employee.id;
      user.name = employee.user.name;
      user.locationId = employee.locationId;
      user.duration = this.copiedCommissionUserDuration;

      if (this._commissionUserService.isRecordAlreadyExists(user.duration, user.name)) {
        this._notifier.notify('warning', 'Record already existed for: ' + employee.user.name);
      } else {
        this._commissionUserService.addCommissionUser(user)
          .subscribe(() => {
            this._commissionUserService.updateCommissionUsersCollection(this.selectedClientId, user.duration, () => {
              var dialogResponse = { update: true, response: 'Commission User saved successfully' };
              this._notifier.notify('success', dialogResponse.response);
              this.dialogRef.close(dialogResponse);
            });
          }, (error) => this._notifier.notify('error', error.message), () => { });
      }
    });
  
  }
}
