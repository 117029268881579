import { Inject, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'sku-group-create-dialog',
  templateUrl: 'sku-group-create.component.html',
})
export class SkuGroupCreateComponent implements OnInit {

  title: string;
  skuGroup: any;

  constructor(public dialogRef: MatDialogRef<SkuGroupCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.skuGroup= data.skuGroup;
  }

  ngOnInit() { }

  onConfirm(): void { this.dialogRef.close(this.skuGroup); }

  onDismiss(): void { this.dialogRef.close(false); }
}
