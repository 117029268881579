<div class="action-header">
  <div class="d-flex align-items-center">
    <mat-radio-group [(ngModel)]="filterMenu.isCurrentFilter" #isCurrentFilter="ngModel" name="isCurrentFilter" (change)="applySelectedFilter()">
      <mat-radio-button [value]="true" class="text-regular fw-500">Current Games</mat-radio-button>
      <mat-radio-button  class="text-regular fw-500 ml-4" [value]="false">Games From The Past 60 days</mat-radio-button>
    </mat-radio-group>
    <mat-form-field class="bottom-border-without-bg ml-5 ">
      <mat-select placeholder="Select columns" multiple [(value)]="selectedColumns" (selectionChange)="columnsChanged($event)">
        <mat-select-trigger>
          {{selectedColumns ? getColumnDefinationByMappedToProperty(selectedColumns[0]).name : ''}}
          <span *ngIf="selectedColumns?.length > 1" class="example-additional-selection">
            (+{{selectedColumns.length - 1}} {{selectedColumns?.length === 2 ? 'other' : 'others'}})
          </span>
        </mat-select-trigger>
        <mat-option *ngFor="let column of columnDefinations" [value]="column.mappedToProperty">{{column.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div>


  <div [hidden]="!tableready" class="row mt-3 pt-1">
    <div class="col-7">
      <div class="table-container" >
        <table #TABLE *ngIf="selectedColumns.length>0 " mat-table [dataSource]="dataSource" matSort class="table table-bordered table-hover small-txt" [hidden]="!tableready">
          <ng-container *ngFor="let column of getDisplayedColumns(); let colIndex = index;" [matColumnDef]="column">
            <th [hidden]="getColumnDefinationByMappedToProperty(column).hide" class="sort-column mat-sort-header-button-custom mat-header-cell" mat-header-cell *matHeaderCellDef
                [matTooltip]="getColumnDefinationByMappedToProperty(column).name" [disabled]="!getColumnDefinationByMappedToProperty(column).isSortable">
              <div>
                <span mat-sort-header>{{getColumnDefinationByMappedToProperty(column).name}}</span>
                <div [ngSwitch]="getColumnDefinationByMappedToProperty(column).name">
                  <div *ngSwitchCase="'Type'">
                    <mat-select placeholder="Select reward type" [(value)]="filterMenu.type" (selectionChange)="applySelectedFilter()">
                      <mat-option [value]="-1">All</mat-option>
                      <mat-option *ngFor="let type of rewardGameTypesKeys" [value]="type">{{rewardGameTypes[type]}}</mat-option>
                    </mat-select>
                  </div>
                  <div *ngSwitchCase="'Metric'">
                    <mat-select placeholder="Select metric" [(value)]="filterMenu.formula" (selectionChange)="applySelectedFilter()">
                      <mat-option [value]="-1">All</mat-option>
                      <mat-option *ngFor="let formula of formulas" [value]="formula.id">{{formula.name}}</mat-option>
                    </mat-select>
                  </div>
                  <div *ngSwitchCase="'StartDate'">
                    <mat-select placeholder="Select Date" [(value)]="filterMenu.duration" (selectionChange)="applySelectedFilter()">
                      <mat-option [value]="-1">All</mat-option>
                      <mat-option *ngFor="let duration of durations" [value]="duration.value">{{duration.viewValue}}</mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>
            </th>
            <td mat-cell *matCellDef="let element" class="mat-cell" [ngClass]="{'yellow' : selectedRewardGameId == element['rewardGame'].id}" [matTooltip]="getTextBydata(element,getColumnDefinationByMappedToProperty(column), true)"><span *ngIf="column !== 'isHidden'">{{getTextBydata(element,getColumnDefinationByMappedToProperty(column), false)}}</span></td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
          <tr mat-row *matRowDef="let row; columns: getDisplayedColumns(); " (click)="selectRewardGame(row)">
          </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10]" [pageSize]="10" showFirstLastButtons [hidden]="!tableready"></mat-paginator>
      </div>

      <div class="table-container mt-3 pt-1" >
        <p class="m-0 p-3 text-small fw-700">Top Reward Earners From The Past 60 Days (Click to see details)</p>
        <table #TABLE *ngIf="selectedColumns.length>0 " mat-table [dataSource]="dataSourceSalesPeople" matSort class="table table-bordered table-hover small-txt" [hidden]="!tableready">
          <ng-container *ngFor="let columnSalesPeople of getDisplayedColumnsSalesPeople(); let colIndex = index;" [matColumnDef]="columnSalesPeople">
            <th [hidden]="getColumnDefinationByMappedToPropertySalesPeople(columnSalesPeople).hide" class="sort-column mat-sort-header-button-custom mat-header-cell" mat-header-cell *matHeaderCellDef mat-sort-header
                [matTooltip]="getColumnDefinationByMappedToPropertySalesPeople(columnSalesPeople).name" [disabled]="!getColumnDefinationByMappedToPropertySalesPeople(columnSalesPeople).isSortable">
              <span>{{getColumnDefinationByMappedToPropertySalesPeople(columnSalesPeople).name}}</span>
            </th>
            <td mat-cell *matCellDef="let element; let z = index;" class="mat-cell" [ngClass]="{'yellow' : selectedTopSalesId == element['employeeId']}" [matTooltip]="getTextByDataSalesPeople(element,getColumnDefinationByMappedToPropertySalesPeople(columnSalesPeople))">
              <span *ngIf="columnSalesPeople == 'place'">#{{(z+1)+(pageEvent.pageSize*pageEvent.pageIndex)}}</span>
              <span *ngIf="columnSalesPeople !== 'place' && column !== 'isHidden'">{{getTextByDataSalesPeople(element,getColumnDefinationByMappedToPropertySalesPeople(columnSalesPeople))}}</span>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="getDisplayedColumnsSalesPeople()"></tr>
          <tr mat-row *matRowDef="let row; columns: getDisplayedColumnsSalesPeople();" (click)="selectSalesPerson(row)">
          </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5]" [pageSize]="5" showFirstLastButtons [hidden]="!tableready" (page)="pageEvent = $event"></mat-paginator>
      </div>
    </div>
    <div class="col-5">
     <div class="table-container">
     <p  class="m-0 p-3 text-small fw-700">  Details</p>
      <div *ngIf="showingGameDetails" >
        <table #TABLE mat-table [dataSource]="dataSourceGameDetails" matSort class="table table-bordered table-hover small-txt"
               [hidden]="!tableready">
          <ng-container *ngFor="let columnGameDetails of getDisplayedColumnsGameDetails(); let colIndex = index;" [matColumnDef]="columnGameDetails">
            <th [hidden]="getColumnDefinationByMappedToPropertyGameDetails(columnGameDetails).hide" class="sort-column mat-sort-header-button-custom mat-header-cell" mat-header-cell *matHeaderCellDef mat-sort-header
                [matTooltip]="getColumnDefinationByMappedToPropertyGameDetails(columnGameDetails).name" [disabled]="!getColumnDefinationByMappedToPropertyGameDetails(columnGameDetails).isSortable">
              <span>{{getColumnDefinationByMappedToPropertyGameDetails(columnGameDetails).name}}</span>
            </th>
            <td mat-cell *matCellDef="let element; let z = index;" class="mat-cell" [matTooltip]="getTextByDataGameDetails(element,getColumnDefinationByMappedToPropertyGameDetails(columnGameDetails))">
              <span *ngIf="columnGameDetails == 'place'">#{{z+1}}</span>
              <span *ngIf="columnGameDetails !== 'place' && columnGameDetails !== 'isHidden'">{{getTextByDataGameDetails(element,getColumnDefinationByMappedToPropertyGameDetails(columnGameDetails))}}</span>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="getDisplayedColumnsGameDetails()"></tr>
          <tr mat-row *matRowDef="let row; columns: getDisplayedColumnsGameDetails(); " [hidden]="row.score == 0">
          </tr>
        </table>
      </div>
      <div *ngIf="showingSalesPeople" >
        <table #TABLE *ngIf="selectedColumnsTopSalesDetails.length>0 " mat-table [dataSource]="dataSourceTopSalesDetails" matSort class="table table-bordered table-hover small-txt"
               [hidden]="!tableready">
          <ng-container *ngFor="let columnTopSalesDetails of getDisplayedColumnsTopSalesDetails(); let colIndex = index;" [matColumnDef]="columnTopSalesDetails">
            <th [hidden]="getColumnDefinationByMappedToPropertyTopSalesDetails(columnTopSalesDetails).hide" class="sort-column mat-sort-header-button-custom mat-header-cell" mat-header-cell *matHeaderCellDef mat-sort-header
                [matTooltip]="getColumnDefinationByMappedToPropertyTopSalesDetails(columnTopSalesDetails).name" [disabled]="!getColumnDefinationByMappedToPropertyTopSalesDetails(columnTopSalesDetails).isSortable">
              <span>{{getColumnDefinationByMappedToPropertyTopSalesDetails(columnTopSalesDetails).name}}</span>
            </th>
            <td mat-cell *matCellDef="let element" class="mat-cell" [matTooltip]="getTextByDataTopSalesDetails(element,getColumnDefinationByMappedToPropertyTopSalesDetails(columnTopSalesDetails))">
              <span *ngIf="columnTopSalesDetails !== 'isHidden'">{{getTextByDataTopSalesDetails(element,getColumnDefinationByMappedToPropertyTopSalesDetails(columnTopSalesDetails))}}</span>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="getDisplayedColumnsTopSalesDetails()"></tr>
          <tr mat-row *matRowDef="let row; columns: getDisplayedColumnsTopSalesDetails(); ">
          </tr>
        </table>
      </div>
     </div>
    </div>
  </div>
</div>
