import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ErrorHandleService } from '../../error-handle.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandleInterceptorService implements HttpInterceptor {

  constructor(private _errorHandlerService: ErrorHandleService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(tap(
        (event) => { },
        (error: HttpErrorResponse) => {
          this._errorHandlerService.handleError(error);
        }));
  }
}
