import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginGaurdService {

  constructor(private _auth: AuthService, private _router: Router) { }

  canActivate(): boolean {
    if (!this._auth.isAuthenticated()) {
      return true;
    } else {
      this._router.navigate(['welcome']);
      return false;
    }
  }
}
