
<div class="main-container">
  <div class="d-flex align-items-center justify-content-between action-header ">
    <div class="d-flex align-items-center ">
      <mat-form-field class="bottom-border width-set">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" [(ngModel)]="searchTerm">
      </mat-form-field>
      <div class="standard-margin-left">
        <mat-form-field class="bottom-border width-set">
          <mat-select placeholder="Select columns" multiple [(value)]="selectedColumns" (selectionChange)="columnsChanged($event)">
            <mat-select-trigger>
              {{selectedColumns ? getColumnDefinationByMappedToProperty(selectedColumns[0]).name : ''}}
              <span *ngIf="selectedColumns?.length > 1" class="example-additional-selection">(+{{selectedColumns.length - 1}} {{selectedColumns?.length === 2 ? 'other' : 'others'}})</span>
            </mat-select-trigger>
            <mat-option *ngFor="let column of formulaAttributes" [value]="column.mappedToProperty">{{column.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <button color="primary" class="primary-action-btm" mat-raised-button type="button" (click)="addFormula()"> Add Formula </button>
  </div>

  <div class="table-margin-top table-container">
    <table *ngIf="selectedColumns.length>0 && doneLoading" [dataSource]="dataSource" mat-table matSort class="table table-bordered table-hover small-txt">
      <ng-container *ngFor="let column of getDisplayedColumns(); let colIndex = index;" [matColumnDef]="column">
        <th [hidden]="getColumnDefinationByMappedToProperty(column).hide" class="sort-column mat-sort-header-button-custom mat-header-cell " mat-header-cell *matHeaderCellDef mat-sort-header
            [disabled]="!getColumnDefinationByMappedToProperty(column).isSortable">
          {{getColumnDefinationByMappedToProperty(column).name}}
        </th>
        <ng-container *matCellDef="let element; let i = index">
          <td [hidden]="getColumnDefinationByMappedToProperty(column).hide" mat-cell  class="mat-cell ">
            <div class="cell-circle" *ngIf="column==='color'" [ngStyle]="{'background-color': element['color']}"></div>
            <span *ngIf="column !== 'Actions'">{{getTextBydata(element,getColumnDefinationByMappedToProperty(column), i+1)}}</span>
            <span *ngIf="column === 'actions'">
              <a color="primary" type="button" (click)="editFormula(element)" class="icon-btn"><i class="fa fa-edit"></i></a>
              <a color="primary" type="button" (click)="deleteFormula(element)" class="icon-btn"><i class="fa fa-trash-alt"></i></a>
            </span>
          </td>
        </ng-container>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
      <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();">
      </tr>
    </table>
    <mat-paginator #ccrsTablePaginator="matPaginator" [pageSizeOptions]="[5, 10, 20, 50]" [pageSize]="10" showFirstLastButtons></mat-paginator>
 
  </div>
</div>
