import { Employee } from './employee';
import { Location } from '../models/location';
import { ReportType } from './report-type';
import { SellingGroup } from './selling-group';
import { ReportTableGroup } from './report-table-group';
import { ReportTableGroupColumn } from './report-table-group-column';
export class RankContest {

  id?: number;
  title?: string;
  message?: string;
  reportType: ReportType;
  dateTypeId: number;
  location?: any;
  locationId: number;
  locationTierFocusId: number;
  locations?: Location[];
  employees?: Employee[];
  GroupA?: SellingGroup;
  GroupB?: SellingGroup;
  numberOfSubGroups?: number;
  reportTableGroup?: ReportTableGroup[];
  newSubGroup?: ReportTableGroup;
  totalSubGroupRank?: number;
  totalQuantityColumns?: number;
  listOfColumns?: ReportTableGroupColumn[];
  dateDisplay?: string;
  start?: string;
  end?: string;
}
