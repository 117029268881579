import { Component } from '@angular/core';
import {
  Router,
  // import as RouterEvent to avoid confusion with the DOM Event
  Event as RouterEvent, NavigationEnd, NavigationCancel, NavigationError, GuardsCheckEnd
} from '@angular/router';
import { LoaderService } from './home/services/loader.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-component',
  templateUrl: './app.component.html'
})
export class AppComponent {
  unsubscribe$ = new Subject();
  constructor(private router: Router, private loaderService: LoaderService) {
    router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event: RouterEvent) => { this.navigationInterceptor(event); });
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof GuardsCheckEnd) {
      //this.loaderService.show();
    }
    if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
      //this.loaderService.hide(); console.log(event);
    }
  }

}
