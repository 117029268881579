import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormulaService } from '../services/formula.service';
import { SkuGroupService } from '../services/sku-group.service';
import { FormulaViewGroupService } from '../services/formula-view-group.service';
import { NotifierService } from 'angular-notifier';
import { Formula } from '../models/formula';
import { FormulaViewGroup } from '../models/formula-view-group';
import { Action } from '../models/action';
import { TableService } from '../services/table.service';
import { AuthService } from '../../core/services/auth.service';
import { ClientService } from '../services/client.service';
import { Client } from '../models/client';
import { ReplaySubject, Subscription } from 'rxjs';
import { LocationService } from '../services/location.service';
import { takeUntil } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { LoaderService } from '../services/loader.service';
import { FormulaCreateDialogComponent } from "./formula-create-dialog.component";
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from "../dialogs/confirm/confirm-dialog.component";

const CONFIG_CLIENT_KEY = 'ConfigClient';
@Component({
  selector: 'app-formula',
  templateUrl: './formula.component.html'
})
export class FormulaComponent implements OnInit, OnDestroy {
  formula: Formula;
  formulas: Formula[] = [];
  formulaViewGroups: FormulaViewGroup[] = [];
  selectedFormula: Formula;
  decisions: string[] = [];
  currentAction: Action;
  formulaValueBeforeUpdate: string = null;
  loadingInProgress: boolean;
  clientId: number;
  clients: Client[] = [];
  subscriptionToClients: Subscription;
  table: any = {
    sorting: true,
    toggleColumns: true
  };
  lastFilter: any;
  searchTerm = '';
  dialog: any = {
    isOpen: false,
    headerText: '',
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    }
  };
  doneLoading: boolean;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  formulaAttributes: any = [
    { mappedToProperty: 'index', name: 'S.No', ascending: true, isSortable: false, hide: false },
    { mappedToProperty: 'color', name: 'Color', ascending: true, isSortable: false, hide: false },
    { mappedToProperty: 'name', name: 'Name', ascending: true, isSortable: true, hide: false },
    { mappedToProperty: 'position', name: 'Position', ascending: true, isSortable: true, hide: false },
    { mappedToProperty: 'trendingPossible', name: 'Trending', ascending: true, isSortable: true, hide: false },
    { mappedToProperty: 'isPercentage', name: 'Percentage', ascending: true, isSortable: true, hide: false },
    { mappedToProperty: 'averageAchievedForRewards', name: 'AverageForRewards', ascending: true, isSortable: true, hide: true },
    { mappedToProperty: 'isMoney', name: 'Show $', ascending: true, isSortable: true, hide: false },
    { mappedToProperty: 'decimalLength', name: 'Decimal Length', ascending: true, isSortable: true, hide: false },
    { mappedToProperty: 'belowAverageLimit', name: 'Below Average Limit', ascending: true, isSortable: true, hide: false },
    { mappedToProperty: 'warningLimit', name: 'Warning Limit', ascending: true, isSortable: true, hide: false },
    { mappedToProperty: 'formulaViewGroupId', name: 'FormulaViewGroup', ascending: true, isSortable: false, hide: false },
    { mappedToProperty: 'actions', name: 'Actions', ascending: true, isSortable: false, hide: false },
  ];

  selectedColumns = this.getDisplayedColumns();
  columnsChanged(event: MatSelectChange) {
    for (var i = 0; i < this.formulaAttributes.length; i++) {
      var column = this.selectedColumns.find(x => x == this.formulaAttributes[i].mappedToProperty);
      if (column != null) {
        this.formulaAttributes[i].hide = false;
      } else {
        this.formulaAttributes[i].hide = true;
      }
    }
  }

  applyFilter(filterValue: string) {
    this.lastFilter = filterValue;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getDisplayedColumns(): string[] {
    return this.formulaAttributes.map(cd => cd.mappedToProperty);;
  }

  getTextBydata(element, columnDef, index) {
    var textToShow = '';

    switch (columnDef.name) {
      case 'Trending':
      case 'Percentage':
      case 'AverageForRewards':
      case 'Show $':
        textToShow = element[columnDef.mappedToProperty] == true ? 'Yes' : 'No';
        break;
      case 'S.No':
        textToShow = index;
        break;
      case 'Color':
        textToShow = ''
        break;
      default:
        textToShow = element[columnDef.mappedToProperty];
        break;
    }
    return textToShow;
  }

  getColumnDefinationByMappedToProperty(mappedToProperty) {
    var column = this.formulaAttributes.find(x => x.mappedToProperty == mappedToProperty);
    return column;
  }

  updateDataSource(data) {

    if (data && data.length > 0) {
      this.dataSource = new MatTableDataSource(data);
      setTimeout(() => { this.dataSource.paginator = this.paginator; this.dataSource.sort = this.sort; });
    }
  }

  constructor(private _formulaService: FormulaService,
    private _skuGroupService: SkuGroupService,
    private _notifier: NotifierService,
    private _formulaViewGroupService: FormulaViewGroupService,
    private _tableService: TableService,
    private _authService: AuthService,
    private _clientService: ClientService,
    private _locationService: LocationService,
    private _loaderService: LoaderService, private matDialog: MatDialog) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource();

  ngOnInit() {
    this.subscriptionToClients = this._locationService.currentActualClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        this.clientId = message;
        this.doneLoading = false;
        if (this.clientId) {
          this.onClientSelected();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
    this.dialogRef?.close();
  }

  reload() {
    this.clientId = +this.clientId;
    this.getFormulas(true);
    this.getFormulaViewGroups();
    this.getDecisionsData();
    this._formulaService.getSkuGroupsAsDecisions(this.clientId);
    this._formulaService.getFormulasAsDecisions(this.clientId);
  }

  onClientSelected() {
    this.reload();
  }



  getFormulas(forceLoading: boolean = false) {
    this.doneLoading = false;
    this._loaderService.show();
    this.loadingInProgress = true;
    this._formulaService.getFormulas(this.clientId, (response) => {
      this.loadingInProgress = false;
      this.formulas = response;
      this.updateDataSource(this.formulas);
      this.doneLoading = true;
      this._loaderService.hide();
    }, forceLoading);
  }

  getFormulaViewGroups() {
    this._formulaViewGroupService.getFormulaViewGroups(this.clientId, (response) => {
      this.loadingInProgress = false;
      this.formulaViewGroups = response;
    });
  }

  getDecisionsData() {
    this._formulaService.getNextDecisions()
      .subscribe((response: string[]) => {
        this.decisions = response;
      }, (error) => this._notifier.notify('error', error.message), () => { });
  }

  saveFormula() {
    this.formula.position = this.formula.position ? this.formula.position : 0;
    this.formula.belowAverageLimit = this.formula.belowAverageLimit ? this.formula.belowAverageLimit : 0;
    this.formula.warningLimit = this.formula.warningLimit ? this.formula.warningLimit : 0;

    const searchedFormulas = this.formulas.filter(f => +f.position === +this.formula.position && this.formula.position !== 0);

    switch (this.currentAction) {
      case Action.Create:
        if (searchedFormulas.length > 0) {
          this._notifier.notify('warning', 'The position provided already exists in another formula.');
          return;
        }

        this.formula.formulaValue = this.formula.list.join(',');
        this._formulaService.createFormula(this.formula.name, this.clientId, this.formula.formulaValue,
          this.formula.color, this.formula.position.toString(),
          this.formula.trendingPossible, this.formula.isPercentage, this.formula.averageAchievedForRewards,
          this.formula.isMoney,
          this.formula.isDefaultVisibleOnEmployeeAndCompareMTDReport,
          this.formula.decimalLength.toString(), this.formula.belowAverageLimit.toString(),
          this.formula.warningLimit.toString(), this.formula.formulaViewGroupId, this.formula.containsComplexFormula)
          .subscribe((response: boolean) => {
            this.dialog.isOpen = false;
            this.getFormulas(true);
            this._notifier.notify('success', 'Formula created successfully.');
          }, (error) => {
            this.getFormulas(true);
            if (error.error != null) {
              this._notifier.notify('error', error.error)
            } else {
              this._notifier.notify('error', error.message)
            }
          }, () => { });
        break;
      case Action.Update:
        if (searchedFormulas.length > 1) {
          this._notifier.notify('warning', 'The position provided already exists in another formula.');
          return;
        }

        this.formula.formulaValue = (this.formula.list.length === 0) ? this.formulaValueBeforeUpdate : this.formula.list.join(',');
        this._formulaService.updateFormula(this.formula.id, this.formula.name, this.formula.formulaValue,
          this.formula.color, this.formula.position.toString(),
          this.formula.trendingPossible, this.formula.isPercentage, this.formula.averageAchievedForRewards,
          this.formula.isMoney,
          this.formula.isDefaultVisibleOnEmployeeAndCompareMTDReport,
          this.formula.decimalLength.toString(), this.formula.belowAverageLimit.toString(),
          this.formula.warningLimit.toString(), this.formula.formulaViewGroupId, this.formula.containsComplexFormula)
          .subscribe((response: boolean) => {
            this.dialog.isOpen = false;
            this.getFormulas(true);
            this._notifier.notify('success', 'Formula updated successfully.');
          }, (error) => {
            this.getFormulas(true);
            if (error.error != null) {
              this._notifier.notify('error', error.error)
            } else {
              this._notifier.notify('error', error.message)
            }
          }, () => { });
        break;
    }
  }
  private dialogRef: any;
  addFormula() {
    this.formula = {
      name: null, formulaValue: null, clientId: this.clientId, position: 0, trendingPossible: false,
      isPercentage: false, averageAchievedForRewards: false, isMoney: false, color: null,
      decimalLength: 0, isDefaultVisibleOnEmployeeAndCompareMTDReport: false, warningLimit: 0,
      belowAverageLimit: 0, list: [], formulaViewGroupId: null, containsComplexFormula: false
    };

    this.dialogRef = this.matDialog.open(FormulaCreateDialogComponent, {
      autoFocus: true, disableClose: false, panelClass: '',
      data: {
        formula: this.formula, title: "Add Formula", decisions: this.decisions, formulaViewGroups: this.formulaViewGroups
      }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result !== null) {
        this.formula = result; this.saveFormula();
      }
    });
    this.dialog.isOpen = true;
    this.dialog.headerText = 'Add Formula';
    this.currentAction = Action.Create;
  }

  editFormula(formula) {
    this.formula = formula;
    this.dialog.isOpen = true;
    this.dialog.headerText = 'Edit Formula';
    this.formula.list = this.formula.formulaValue.split(',');
    this.currentAction = Action.Update;
    this.formulaValueBeforeUpdate = this.formula.formulaValue;

    this.dialogRef = this.matDialog.open(FormulaCreateDialogComponent, {
      autoFocus: true, disableClose: false, panelClass: '',
      data: {
        formula: this.formula, title: "Edit Formula", decisions: this.decisions, formulaViewGroups: this.formulaViewGroups
      }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result !== null) {
        this.formula = result; this.saveFormula();
      }
    });
  }

  deleteFormula(formula) {
    this.dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      autoFocus: true, disableClose: false, panelClass: '',
      data: {
        message: "Are you sure you want to delete this formula?", title: "Delete Formula",
      }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this._formulaService.deleteFormula(formula.id)
          .subscribe((response: boolean) => {
            this.dialog.isOpen = false;
            this.getFormulas(true);
            this._notifier.notify('success', 'Formula deleted successfully.');
          }, (error) => this._notifier.notify('error', error.message), () => { });
      }
    });
  }



  changeToNumber(value: any): number {
    if (typeof value === 'string') {
      return parseInt(value, 10);
    }

    return value;
  }
}
