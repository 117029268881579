interface Scripts {
  name: string;
  src: string;
}
export const ScriptStore: Scripts[] = [
//  { name: 'jquery', src: 'ClientApp/dist/assets/js/lib/jquery/jquery.min.js'},
//  { name: 'tether', src: 'ClientApp/dist/assets/js/lib/tether/tether.min.js'},
  { name: 'bootstrap', src: 'ClientApp/dist/assets/js/lib/bootstrap/bootstrap.min.js'},
 // { name: 'jquery-ui', src: 'ClientApp/dist/assets/js/lib/jqueryui/jquery-ui.min.js'},
  { name: 'lobipanel', src: 'ClientApp/dist/assets/js/lib/lobipanel/lobipanel.min.js'},
 // { name: 'jquery-matchHeight', src: 'ClientApp/dist/assets/js/lib/match-height/jquery.matchHeight.min.js' },
  { name: 'table-edit', src: 'ClientApp/dist/assets/js/lib/table-edit/jquery.tabledit.min.js' },
 // { name: 'plugins', src: 'ClientApp/dist/assets/js/plugins.js'},
  //{ name: 'app', src: 'ClientApp/dist/assets/js/app.js' },
  //{ name: 'idle-timer', src: 'ClientApp/dist/assets/js/lib/jquery-idletimer/idle-timer.js' }
];
