import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '../models/user';
import { Role } from '../models/role';
import { UserStatusCode } from '../models/userStatusCode';
import { UserService } from '../services/user.service';
import { RoleService } from '../services/role.service';
import { UserStatusCodeService } from '../services/user-status-code.service';
import { Client } from '../../models/client';
import { ClientService } from '../../services/client.service';
import { NotifierService } from 'angular-notifier';
import { TableService } from '../../services/table.service';
import { LocationService } from '../../services/location.service';
import { EmployeeService } from '../../services/employee.service';
import { LocationTypeCode } from '../../models/location-type-code';
import { AuthService } from '../../../core/services/auth.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html'
})
export class UserComponent implements OnInit, OnDestroy {
  users: User[] = [];
  clients: Client[] = [];
  roles: Role[] = [];
  userStatusCodes: UserStatusCode[] = [];
  isCreateUser = false;
  isUpdateUser = false;
  model: any = {};
  searchModel: any = {};
  roleId: string;
  name: string;
  userName: string;
  email: string;
  password: string;
  clientId: number;
  userStatusCodeId: number;
  loadingInProgress: boolean;
  regions: any[] = [];
  districts: any[] = [];
  stores: any[] = [];
  isSearchVisible = false;
  noResultFound = false;
  usersToSearch: string[] = [];
  subscriptionToClients: Subscription;
  subscriptionToRegions: Subscription;
  subscriptionToDistricts: Subscription;
  subscriptionToStores: Subscription;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  table: any = {
    sorting: true
  };

  dialog: any = {
    isOpen: false,
    headerText: '',
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    }
  };

  userAttributes: any = [{ name: 'name', header: 'Name', ascending: true, sorting: true },
  { name: 'username', header: 'User Name', ascending: true, sorting: true },
  { name: 'client', header: 'Client', ascending: true, sorting: false },
  { name: 'statusCode', header: 'Status', ascending: true, sorting: false },
  { name: 'role', header: 'Role', ascending: true, sorting: false }];

  constructor(private _locationService: LocationService,
    private _userService: UserService,
    private _employeeService: EmployeeService,
    private _clientService: ClientService,
    private _roleService: RoleService,
    private _userStatusCodeService: UserStatusCodeService,
    private _authService: AuthService,
    private _notifier: NotifierService,
    private _tableService: TableService) {
  }

  ngOnInit() {
    this.subscriptionToClients = this._locationService.currentActualClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
      this.searchModel.clientId = message; this.onClientSelected();
    });

    this.subscriptionToRegions = this._locationService.currentRegion
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
      this.searchModel.regionId = message; this.onRegionSelected();
    });

    this.subscriptionToDistricts = this._locationService.currentDistrict
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
      this.searchModel.districtId = message; this.onDistrictSelected();
    });

    this.subscriptionToStores = this._locationService.currentStore
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
      this.searchModel.storeId = message; this.onStoreSelected();
    });

    this.searchModel.clientId = this._authService.clientId();
    this.searchModel.status = 1;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
  }

  addUser() {

    this.isCreateUser = true;
    this.isUpdateUser = false;
    this.name = null;
    this.userName = null;
    this.email = null;
    this.clientId = null;
    this.userStatusCodeId = null;
    this.roleId = null;
    this.dialog.isOpen = true;
    this.dialog.headerText = 'Add User';
  }

  editUser(user: User) {
    this.getClients();
    this.getUserStatusCodes();
    this.getRoles();

    this.model.id = user.id;
    this.model.name = user.name;
    this.model.userName = user.userName;
    this.model.email = user.email;
    this.model.clientId = user.client.id;
    this.model.userStatusCodeId = user.userStatusCode.id;
    this.model.roleId = (user.userRoles.length > 0) ? user.userRoles[0].role.id : '';

    this.isUpdateUser = true;
    this.isCreateUser = false;
    this.dialog.isOpen = true;
    this.dialog.headerText = 'Edit User';
  }

  getUsers(hardReload: boolean = true) {
    this.loadingInProgress = true;
    this._userService.getUsersByClientNonbias((response) => {
      this.loadingInProgress = false;
      this.users = response.filter(x => x.client.id === this.searchModel.clientId);
    }, this.clientId, hardReload);
  }

  getRoles() {
    if (this._authService.isSuperAdmin) {
      this._roleService.getRoles(this.searchModel.clientId, this._authService.roles())
        .subscribe((response: Role[]) => {

          this.roles = response.filter(x => x.clientId != 0);
        }, (error) => this._notifier.notify('error', error.error), () => { });
    } else {
      this._roleService.getRoles(this._authService.clientId(), this._authService.roles())
        .subscribe((response: Role[]) => {
          this.roles = response.filter(x => x.clientId != 0);
        }, (error) => this._notifier.notify('error', error.error), () => { });
    }
  }

  getClients() {
    this._clientService.getClients((response) => {
      this.clients = response;
    });
  }

  getUserStatusCodes() {
    this._userStatusCodeService.getUserStatusCodes()
      .subscribe((response: UserStatusCode[]) => {
        this.userStatusCodes = response;
      }, (error) => this._notifier.notify('error', error.error), () => {});
  }

  saveUser() {
    this._userService.updateUser(this.model.id, this.model.name, this.model.email, this.model.userName,
      this.model.clientId, this.model.userStatusCodeId, this.model.roleId)
      .subscribe((response: string) => {
        this.isUpdateUser = false;
        this.dialog.isOpen = false;
        this.getUsers(true);
        this._notifier.notify('success', 'User updated successfully.');
      }, (error) => this._notifier.notify('error', error.error), () => {});
  }

  createUser() {
    this._userService.createUser(this.model.name, this.model.email, this.model.userName, this.model.password,
      this.model.clientId, this.model.userStatusCodeId, this.model.roleId)
      .subscribe((response: string) => {
        this.isCreateUser = false;
        this.dialog.isOpen = false;
        this.getUsers(true);
        this._notifier.notify('success', 'User created successfully.');
      }, (error) => this._notifier.notify('error', error.error), () => {});
  }

  deleteUser(user) {
    if (!confirm('Are you sure you want to delete this user?')) {
      return false;
    }

    this._userService.deleteUser(user.id)
      .subscribe((response: string) => {
        this.isCreateUser = false;
        this.getUsers(true);
        this._notifier.notify('success', 'User deleted successfully.');
      }, (error) => this._notifier.notify('error', error.error), () => {});
  }

  sorting(field, order) {
    if (!this.table.sorting) {
      return;
    }

    this.users = this._tableService.sorting(this.users, field, order);
    this.userAttributes.filter(col => col.name === field)[0].ascending = !order;
  }

  onClientSelected() {
    this.searchModel.clientId = +this.searchModel.clientId;

   // this.searchUsers();
    this.getClients();
    this.getUserStatusCodes();
    this.getRoles();
    this._employeeService.loadNonbiasEmployees();
    this._userService.getUsersByClientNonbias(() => { }, this.searchModel.clientId, true);
    this._locationService.getLocationsByTypeAndClientId(LocationTypeCode.Region, (response) => {
      this.regions = response;
      this.searchModel.regionId = this.searchModel.districtId = this.searchModel.storeId = 0;
    }, this.searchModel.clientId);
  }

  onRegionSelected() {
    this.searchModel.regionId = +this.searchModel.regionId;
    this._locationService.getLocations(LocationTypeCode.District, (response) => {
      this.districts = response;
      this.searchModel.districtId = this.searchModel.storeId = 0;
    }, this.searchModel.regionId);
  }

  onDistrictSelected() {
    this.searchModel.districtId = +this.searchModel.districtId;
    this._locationService.getLocations(LocationTypeCode.Store, (response) => {
      this.stores = response;
      this.searchModel.storeId = 0;
    }, this.searchModel.districtId);
  }

  onStoreSelected() {
    this.searchModel.storeId = +this.searchModel.storeId;
  }

  onStatusSelected() {
    this.searchModel.statusCodeId = +this.searchModel.statusCodeId;
  }

  searchUsers(usersWithNoLocations: boolean) {
    this.loadingInProgress = true;
    this.noResultFound = false;
    let checkedForStore = false;
    this.getRoles();
    if (usersWithNoLocations) {
      if (this.searchModel.clientId) {
        this._locationService.getLocationsByTypeAndClientId(LocationTypeCode.Store, (locationResponse) => {
          this._employeeService.getEmployeeUserIdsByLocations(locationResponse.map(function (store) {
            return store.id;
          }), (response) => {
            this.usersToSearch = response;
          });
        }, this.searchModel.clientId);
      }

      this.users = this._userService.searchUser({
        'client': this.searchModel.clientId,
        'regionId': 999999,
        'regionDistrictAndStore': this.usersToSearch,
        'name': this.searchModel.name,
        'userName': this.searchModel.userName,
        'role': this.searchModel.roleId,
        'status': +this.searchModel.status,
      });
      this.loadingInProgress = false;
    } else {


      if (this.searchModel.clientId) {
        this._locationService.getLocationsByTypeAndClientId(LocationTypeCode.Store, (locationResponse) => {
          this._employeeService.getEmployeeUserIdsByLocationsDisabled(locationResponse.map(function (store) {
            return store.id;
          }), (response) => {
            this.usersToSearch = response;
          });
        }, this.searchModel.clientId);
      }

      if (this.searchModel.regionId && this.searchModel.regionId !== 999999) {
        this._locationService.getLocationsByTypeAndParents(LocationTypeCode.Store, (locationResponse) => {
          this._employeeService.getEmployeeUserIdsByLocationsDisabled(locationResponse.map(function (store) {
            return store.id;
          }), (response) => {
            this.usersToSearch = response;
          });
        }, this.districts.map(function (district) { return district.id; }));
      }
      if (this.searchModel.districtId) {
        this._employeeService.getEmployeeUserIdsByLocationsDisabled(this.stores.map(function (store) {
          return store.id;
        }), (response) => {
          this.usersToSearch = response;
          checkedForStore = true;
        });
      }
      if (this.searchModel.storeId) {
        this._employeeService.getEmployeeUserIdsByLocationsDisabled([this.searchModel.storeId], (response) => {
          this.usersToSearch = response;
          checkedForStore = true;
        });
      }

      if (checkedForStore && this.usersToSearch.length === 0) {
        this.users = undefined;
      } else {
        this.users = this._userService.searchUser({
          'client': this.searchModel.clientId,
          'regionId': this.searchModel.regionId,
          'regionDistrictAndStore': this.usersToSearch,
          'name': this.searchModel.name,
          'userName': this.searchModel.userName,
          'role': this.searchModel.roleId,
          'status': +this.searchModel.status,
        });
      }
      this.loadingInProgress = false;
    }
    if (this.users.length === 0) {
      this.noResultFound = true;
    }
  }

  cancelSearch() {
    this.isSearchVisible = false;
    this.getUsers();
  }

  showSearchUser() {
    this.isSearchVisible = true;
  }
}
