import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import * as d3 from 'd3';
import { NotifierService } from 'angular-notifier';
import { EmployeeService } from '../services/employee.service';
import { ReportService } from '../services/report.service';
import { AuthService } from '../../core/services/auth.service';
import { ClientService } from '../services/client.service';
import { FormulaService } from '../services/formula.service';
import { FormulaViewGroupService } from '../services/formula-view-group.service';
import { LocationService } from '../services/location.service';
import { SkuGroupService } from '../services/sku-group.service';
import { CommissionUserService } from '../services/commission-user.service';
import { UploadStatusService } from '../services/upload-status.service';
import { UploadStatus } from '../models/upload-status';
import { Formula } from '../models/formula';
import { Action } from '../models/action';
import { SkuGroup } from '../models/sku-group';
import { Location } from '../models/location';
import { Employee } from '../models/employee';
import { Dashboard } from '../models/dashboard';
import { Client } from '../models/client';
import * as moment from 'moment';
import { RankContest } from '../models/rank-contest';
import { ReportType } from '../models/report-type';
import { FormulaViewGroup } from '../models/formula-view-group';
import { ReportTableGroup } from '../models/report-table-group';
import { ReportTableGroupColumn } from '../models/report-table-group-column';
import { SellingGroup } from '../models/selling-group';
import { CommissionUser } from '../models/commission-user';
import { Seller } from '../models/seller';
import { Subscription } from 'rxjs/internal/Subscription';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { MatRadioChange } from '@angular/material/radio';
import { LoaderService } from '../services/loader.service';
import { MatDialog } from '@angular/material/dialog';
import { ColumnCreateDialogComponent } from './column-create.component';
import { ConfirmDialogComponent } from '../dialogs/confirm/confirm-dialog.component';
import { FormulaCreateDialogComponent } from '../formula/formula-create-dialog.component';
import { GroupCreateDialogComponent } from './group-create.component';
import { ReportCreateDialogComponent } from './report-create.component';

@Component({
  selector: 'app-rank-contest',
  templateUrl: './rank-contest.component.html'
})
export class RankContestComponent implements OnInit, OnDestroy {

  // #region this.

  columnUnderSorting = -2;
  isAscendingOrder = false;
  copyOfSalesReport: any;
  clientId: number;
  table: any = {
    sorting: true,
    toggleColumns: true
  };
  formulaViewGroups: FormulaViewGroup[] = [];
  @ViewChild('GroupAReportTable', { read: ElementRef }) GroupAReportTable: ElementRef;
  @ViewChild('GroupBReportTable', { read: ElementRef }) GroupBReportTable: ElementRef;
  groupBLowestRank: number;
  groupALowestRank: number;
  uploadStatus: string;
  disableModifyButton = false;
  showModifyButtons = true;
  rankReportSalesObject: any;
  reportDoneLoading = true;
  goalCollection: any;
  chartNumber: number;
  numberOfGroups: number;
  commissionUsers: CommissionUser[];
  report: RankContest;
  reports: RankContest[];
  reportTypes: string[];
  selectedReportId: number;
  selectedReport: RankContest;
  reportIsSelected: boolean;
  newTableGroup: ReportTableGroup;
  newTableGroupColumn: ReportTableGroupColumn;
  newReportFormula: Formula;
  formula: Formula;
  formulaValueBeforeUpdate: string = null;
  sizeOfReportOnPage = 74;
  decisions: string[] = [];
  selectedDistrictId: number;
  selectedRegionId: number;
  selectedLocation: Location;
  dashboard: Dashboard;
  dashboards: Dashboard[];
  currentAction: Action;
  formulaAction: Action;
  formulas: Formula[] = [];
  skuGroups: SkuGroup[] = [];
  loadingInProgress: boolean;
  clients: Client[] = [];
  locations2: Location[];
  locations: Location[];
  employees: Employee[];
  treeData: any;
  selectedClientId: number;
  selectedClientLocationId: number;
  selectedClient: any;
  locationSearchString = null;
  presentUser: Employee;
  topLocationToShow: number;
  locationTreeToView: Location;
  locationsForSelectTree: Location[];
  userId: string;
  userRole: any;
  inventoryData: any;
  zoomed = false;
  subscribeCooldown = false;
  dateNow = new Date();
  optionRadioSelected = '3';
  subscriptionToClientsLocationId: Subscription;
  subscriptionToClients: Subscription;
  subscriptionToRegions: Subscription;
  subscriptionToDistricts: Subscription;
  selectedDuration: any;
  deptharray = [{ text: 'Region', value: 2 },
  { text: 'District', value: 3 },
  { text: 'Store', value: 4 }];
  depthview = [];
  depthSelection = 4;
  selectedLocationColor: any;
  root: any;
  firstLoad = true;
  dialog: any = {
    isOpen: false,
    headerText: '',
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    }
  };

  addGroupDialog: any = {
    isOpen: false,
    headerText: '',
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    }
  };
  colordialog: any = {
    isOpen: false,
    headerText: 'Edit Color',
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    }
  };
  locationSelect: any = {
    isOpen: false,
    headerText: '',
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    },
    refresh: () => {
      this.preEditPostEditFunction();
    }
  };

  addFormulaDialog: any = {
    isOpen: false,
    headerText: '',
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    }
  };
  addColumnDialog: any = {
    isOpen: false,
    headerText: '',
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    }
  };
  private dialogRef: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  // #endregion this.

  constructor(private _notifier: NotifierService,
    private _locationService: LocationService,
    private _clientService: ClientService,
    private _reportService: ReportService,
    private _formulaService: FormulaService,
    private _formulaViewGroupService: FormulaViewGroupService,
    private _skuGroupService: SkuGroupService,
    private _uploadStatusService: UploadStatusService,
    private _authService: AuthService,
    private _employeeService: EmployeeService,
    private _commissionUser: CommissionUserService,
    private _loaderService: LoaderService, private matDialog: MatDialog) {
  }

  // #region Init / Destroy

  ngOnDestroy() {
    this.subscriptionToClientsLocationId.unsubscribe();
    this.subscriptionToClients.unsubscribe();
    this.subscriptionToRegions.unsubscribe();
    this.subscriptionToDistricts.unsubscribe();
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
    this.dialogRef?.close();
  }

  ngOnInit() {
    this.selectedDuration = moment().format('YYYY-MM');
    this.reportTypes = Object.keys(ReportType);
    this.getDecisionsData();
    // this._formulaService.getSkuGroupsAsDecisions();
    this.reportIsSelected = false;
    const myLinkWithTooltip = document.getElementById('myTooltip');
    this.selectedLocationColor = '#808080';
    this.selectedClientId = this._authService.clientId();
    this.userId = this._authService.userId();
    this.userRole = this._authService.roles();
    if (!this._authService.hasEditRankPermission()) {
      this.disableModifyButton = true;
      this.showModifyButtons = false;
    }
    this.subscriptionToClients = this._locationService.currentActualClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        this.selectedClientId = message; this.initCall();
      });
    this.subscriptionToClientsLocationId = this._locationService.currentClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => { this.selectedClientLocationId = message; });
    this.subscriptionToRegions = this._locationService.currentRegion
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => { this.selectedRegionId = message; });
    this.subscriptionToDistricts = this._locationService.currentDistrict
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => { this.selectedDistrictId = message; });
  }

  initCall() {
    if (this.selectedClientId && this.selectedClientId !== 0 && this.reportDoneLoading && !this.subscribeCooldown) {
      this.subscribeCooldown = true;
      // not a great solution, better understanding needed why the header is sending the client twice if the page has already been loaded once.
      setTimeout(() => { this.subscribeCooldown = false; }, 2000);
      this.getUploadStatus();
      this.getClients();
      this.getLocations();
      this.getEmployees();
      this.getSkuGroups();
      this.getFormulas();
      this.getLocationFullTree();
      this.getFormulaViewGroups();
      this.reportDoneLoading = true;
      // Removed Two functions from here. I am not using Await correctly,
      // I do not load the page in a proper way.They have been moved to getEmployees
      const numberofGroups = 4;
      const chartNumberArray = [];
      chartNumberArray.push(0);
      const chartNumber1 = 14;
      const chartNumber2 = 80 / numberofGroups;
      const chartNumber3 = 6;
      const chartNumber4 = 4;
      const chartNumber5 = 80 / numberofGroups;
      const chartCounts = 5;
      this.topLocationToShow = 1;
      // if (this.userRole === 'Sales Reperesentative') {
      // this.topLocationToShow = 2;
      // }
    }
  }

  // #endregion Init / Destroy

  /*onClientSelected() {
    this.reportTypes = Object.keys(ReportType);
    this.getDecisionsData();
    this.reportIsSelected = false;
    this.selectedLocationColor = '#808080';
    this.getUploadStatus();
    this.getLocations();
    this.getEmployees();
    this.getSkuGroups();
    this.getFormulas();
    this.getLocationFullTree();
    this.getFormulaViewGroups();
    this.getReportsFirstLoad();
    this.getCommissionUsers();
    const chartNumberArray = [];
    chartNumberArray.push(0);
  }*/

  /*
  toggleShowModifyButtons() {
   this.showModifyButtons = !this.showModifyButtons;
  }
  */

  // #region Formatting/Coloring

  isFormulaUnderWarningLimit(num1, num2, column) {
    if (column.formula.isPercentage) { num2 = num2 / 100; }
    if (num1 < num2) { return '#f50000'; }
  }

  formattedValue(number: number) {
    return Math.floor(number).toLocaleString();
  }

  formattedValue2(number: number, decimal: number) {
    return number.toFixed(decimal).toString();
  }

  getFormattedValue(formula: any, property: number) {
    if (formula.formula.isMoney) {
      return '$' + this.formattedValue2(property, formula.formula.decimalLength);
    } else if (formula.formula.isPercentage) {
      return this.formattedValue2(property * 100, formula.formula.decimalLength) + '%';
    } else {
      return this.formattedValue2(property, formula.formula.decimalLength);
    }
  }

  getFormulaComputedPropertyValue(formula: any, seller: any, groupCode: any) {
    const property = this.evaluateFormula(formula, seller, groupCode);
    if (formula.formula.isMoney) {
      return '$' + this.formattedValue2(property, formula.formula.decimalLength);
    } else if (formula.formula.isPercentage) {
      return this.formattedValue2(property * 100, formula.formula.decimalLength) + '%';
    } else {
      return this.formattedValue2(property, formula.formula.decimalLength);
    }
  }

  perc2color(num1, num2) {
    const perc = 100 - ((num1 / num2) * 100);
    let r, g, b = 0;
    if (perc < 10) {
      r = 255;
      g = 80;
      b = 60;
    } else if (perc < 50) {
      r = 255;
      g = Math.round(5.3 * perc);
      if (g > 255) { g = 255; }
    } else {
      g = 255;
      r = Math.round(510 - 5.10 * perc);
    }
    const h = r * 0x10000 + g * 0x100 + b * 0x1;
    return '#' + ('000000' + h.toString(16)).slice(-6);
  }

  // #endregion Formatting/Coloring

  // #region SortTables

  sortTableB(index) {
    this.columnUnderSorting = index - 3;
    let table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = this.GroupBReportTable.nativeElement;
    switching = true;
    dir = 'asc';
    while (switching) {
      switching = false;
      rows = table.tBodies[0].rows;
      for (i = 0; i < (rows.length - 1); i++) {
        shouldSwitch = false;
        x = rows[i].getElementsByTagName('td')[+index];
        y = rows[i + 1].getElementsByTagName('td')[+index];
        x = Number(x.innerText.replace(/,/g, '').replace(/%/g, '').replace('$', ''));
        y = Number(y.innerText.replace(/,/g, '').replace(/%/g, '').replace('$', ''));
        if (dir === 'asc') {
          if (x > y) {
            shouldSwitch = true;
            break;
          }
        } else if (dir === 'desc') {
          if (x < y) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount === 0 && dir === 'asc') {
          dir = 'desc';
          switching = true;
        }
      }
    }
    this.isAscendingOrder = (dir === 'asc');
  }

  async sortTable(index) {
    this.columnUnderSorting = index - 3;
    let table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = await this.GroupAReportTable.nativeElement;
    switching = true;
    dir = 'asc';
    while (switching) {
      switching = false;
      rows = table.tBodies[0].rows;
      for (i = 0; i < (rows.length - 1); i++) {
        shouldSwitch = false;
        x = rows[i].getElementsByTagName('td')[+index];
        y = rows[i + 1].getElementsByTagName('td')[+index];
        x = Number(x.innerText.replace(/,/g, '').replace(/%/g, '').replace('$', ''));
        y = Number(y.innerText.replace(/,/g, '').replace(/%/g, '').replace('$', ''));
        if (dir === 'asc') {
          if (x > y) {
            shouldSwitch = true;
            break;
          }
        } else if (dir === 'desc') {
          if (x < y) {
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        switchcount++;
      } else {
        if (switchcount === 0 && dir === 'asc') {
          dir = 'desc';
          switching = true;
        }
      }
    }
    this.isAscendingOrder = (dir === 'asc');
  }

  // #endregion sortTables

  // #region Seller Groups (Employees / Location Lists)

  getGroupASellers(locationId: number) {
    const sellerList: Seller[] = [];
    for (const location in this.locations) {
      if (this.locations[location].id === locationId || locationId === 0) {
        for (const location2 in this.locations) {
          if (this.locations[location2].parentId === this.locations[location].id) {
            const thisStore: Location = this.locations[location2];
            const thisSeller = new Seller();
            thisSeller.isLocation = true;
            thisSeller.name = thisStore.name;
            thisSeller.locationId = thisStore.id;
            sellerList.push(thisSeller);
          }
        }
      }
    }
    return sellerList;
  }

  getGroupBSellers(isLocation: boolean, locationList: Seller[]) {
    const sellerList: Seller[] = [];
    locationList.forEach((location) => {
      if (isLocation) {
        const storeId = location.locationId;
        const storeList = this.locations.filter(loc => loc.parentId === storeId);
        storeList.forEach((store) => {
          const thisSeller = new Seller();
          const thisStore = store;
          thisSeller.isLocation = true;
          thisSeller.name = thisStore.name;
          thisSeller.locationId = thisStore.id;
          sellerList.push(thisSeller);
        });
      } else {
        const storeId = location.locationId;
        const empList = this.employees.filter(emp => emp.locationId === storeId && emp.user.userStatusCode.name !== 'Disabled');
        empList.forEach((employee) => {
          const thisSeller = new Seller();
          const thisEmp = employee;
          thisSeller.isLocation = false;
          thisSeller.name = thisEmp.user.name;
          thisSeller.employeeId = thisEmp.id;
          thisSeller.goals = [];
          sellerList.push(thisSeller);
        });
      }
    });
    return sellerList;
  }

  // #endregion Seller Groups

  // #region Find Rank

  findYourRankOrFormulaRaw(seller, column) {
    let response = 0;
    seller.formulas.formulas.forEach((formula) => {
      const theseGroups = formula;
      if (theseGroups.formulaId === column.formula.id && theseGroups.nameValueOverride === column.nameValueOverride) {
        response = theseGroups.value;
      }
    });
    return response;
  }

  findYourRankOrFormula(seller, column) {
    let response = '0';
    seller.formulas.formulas.forEach((formula) => {
      const theseGroups = formula;
      if (theseGroups.formulaId === column.formula.id && theseGroups.nameValueOverride === column.nameValueOverride) {
        response = this.getFormattedValue(column, theseGroups.value);
      }
    });
    return response;
  }

  // #endregion Find Rank

  // #region Formula Building 

  getDecisionsData() {
    this._formulaService.getNextDecisions()
      .subscribe((response: string[]) => {
        this.decisions = response;
      }, (error) => this._notifier.notify('error', error.message), () => { });
  }

  changeDecision() {
    if (!this.newReportFormula.currentDecision) {
      return;
    }
    this.newReportFormula.list.push(this.newReportFormula.currentDecision);
    this._formulaService.getNextDecisions(this.newReportFormula.currentDecision)
      .subscribe((response: any[]) => {
        this.decisions = response;
      }, (error) => this._notifier.notify('error', error.message), () => { });
  }

  clearDecisions() {
    this.newReportFormula.list = [];
    this._formulaService.getNextDecisions()
      .subscribe((response: any[]) => {
        this.decisions = response;
      }, (error) => this._notifier.notify('error', error.message), () => { });
  }

  // #endregion Formula Building

  // #region Add / Edit / Save Reports


  editReport(report: RankContest) {
    this.dialog.isOpen = true;
    this.dialog.headerText = 'Edit Report';
    this.currentAction = Action.Update;
    this.report = report;
    this.trimFullTree();
    this.dialogRef = this.matDialog.open(ReportCreateDialogComponent, {
      autoFocus: true, disableClose: false, panelClass: '',
      data: {
        reportTypes: this.reportTypes, title: "Edit Report", report: this.report, selectedReport: this.selectedReport, selectedLocation: this.selectedLocation, locationSelect: this.locationSelect, locationsForSelectTree: this.locationsForSelectTree
      }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.report = result.report;
        this.saveReport();

      }
    });

  }
  editReportTableGroup(reportTableGroup: ReportTableGroup) {
    this.addGroupDialog.headerText = 'Edit Group';
    this.currentAction = Action.Update;
    this.newTableGroup = reportTableGroup;
    this.dialogRef = this.matDialog.open(GroupCreateDialogComponent, {
      autoFocus: true, disableClose: false, panelClass: '',
      data: {
        newTableGroup: this.newTableGroup, title: "Edit group", formulas: this.formulas // decisions: this.decisions, formulaViewGroups: this.formulaViewGroups
      }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result !== null) {

        switch (result.action) {
          case 'Save':
            this.newTableGroupColumn = result.newTableGroupColumn;
            this.saveReportTableGroup();
            break;
          case 'Add_Formula':
            this.addNewReportFormula();
            break;
          case 'Edit_Formula':
            this.newTableGroupColumn = result.newTableGroupColumn;
            this.editFormula(result.id);
            break;
          default:
            break;
        }
      }
    });
  }

  editReportTableGroupColumn(reportTableGroupColumn: ReportTableGroupColumn) {
    this.addColumnDialog.headerText = 'Edit Column';
    this.currentAction = Action.Update;
    this.newTableGroupColumn = reportTableGroupColumn;
    this.dialogRef = this.matDialog.open(ColumnCreateDialogComponent, { autoFocus: true, disableClose: false, panelClass: '', data: { newTableGroupColumn: this.newTableGroupColumn, formulas: this.formulas, title: 'Create new Column' } });
    this.dialogRef.afterClosed().subscribe(result => {
      switch (result.action) {
        case 'Save':
          this.newTableGroupColumn = result.newTableGroupColumn;
          this.saveReportTableColumn();
          break;
        case 'Add_Formula':
          this.addNewReportFormula();
          break;
        case 'Edit_Formula':
          this.newTableGroupColumn = result.newTableGroupColumn;
          this.editFormula(result.id);
          break;
        default:
          break;
      }
    });

  }
  addNewReport() {
    this.report = {
      title: '',
      message: '',
      dateTypeId: 1,
      locationTierFocusId: 3,
      reportType: ReportType.Rank,
      locationId: 0
    };
    setTimeout(() => { this.trimFullTree(); }, 500);
    this.dialog.isOpen = true;
    this.dialog.headerText = 'Create new Report';
    this.currentAction = Action.Create;
    this.dialogRef = this.matDialog.open(ReportCreateDialogComponent, {
      autoFocus: true, disableClose: false, panelClass: '',
      data: {
        reportTypes: this.reportTypes, title: "Add Report", report: this.report, selectedReport: this.selectedReport, selectedLocation: this.selectedLocation, locationSelect: this.locationSelect, locationsForSelectTree: this.locationsForSelectTree
      }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) { this.report = result.report; this.saveReport(); }
    });
  }

  addNewReportTableColumn(rtg: ReportTableGroup) {
    this.newTableGroupColumn = {
      displayPosition: 0,
      formulaId: 0,
      reportTableGroupId: rtg.id,
      redValueGroup1: null,
      redValueGroup2: null
    };
    this.addColumnDialog.headerText = 'Create new Column';
    this.currentAction = Action.Create;

    this.dialogRef = this.matDialog.open(ColumnCreateDialogComponent, { autoFocus: true, disableClose: false, panelClass: '', data: { newTableGroupColumn: this.newTableGroupColumn, formulas: this.formulas, title: 'Create new Column' } });
    this.dialogRef.afterClosed().subscribe(result => {

      switch (result.action) {
        case 'Save':
          this.newTableGroupColumn = result.newTableGroupColumn;
          this.saveReportTableColumn();
          break;
        case 'Add_Formula':
          this.addNewReportFormula();
          break;
        case 'Edit_Formula':
          this.newTableGroupColumn = result.newTableGroupColumn;
          this.editFormula(result.id);
          break;
        default:
          break;
      }
    });
  }

  editFormula(formulaId) {
    const formy = this.formulas.find(x => formulaId === x.id);
    this.newReportFormula = formy;
    this.addFormulaDialog.headerText = 'Edit Formula';
    this.newReportFormula.list = this.newReportFormula.formulaValue.split(',');
    this.formulaAction = Action.Update;
    this.formulaValueBeforeUpdate = this.newReportFormula.formulaValue;
    this.dialogRef = this.matDialog.open(FormulaCreateDialogComponent, {
      autoFocus: true, disableClose: false, panelClass: '',
      data: {
        formula: this.newReportFormula, title: "Edit Formula", decisions: this.decisions, formulaViewGroups: this.formulaViewGroups
      }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result !== null) {
        this.newReportFormula.name = result.name;
        this.newReportFormula.currentDecision = result.currentDecision;
        this.newReportFormula.position = result.position;
        this.newReportFormula.containsComplexFormula = result.containsComplexFormula;
        this.newReportFormula.trendingPossible = result.trendingPossible;
        this.newReportFormula.isDefaultVisibleOnEmployeeAndCompareMTDReport = result.isDefaultVisibleOnEmployeeAndCompareMTDReport;
        this.newReportFormula.isPercentage = result.isPercentage;
        this.newReportFormula.averageAchievedForRewards = result.averageAchievedForRewards;
        this.newReportFormula.isMoney = result.isMoney;
        this.newReportFormula.decimalLength = result.decimalLength;
        this.newReportFormula.warningLimit = result.warningLimit;
        this.newReportFormula.belowAverageLimit = result.belowAverageLimit;
        this.newReportFormula.formulaViewGroupId = result.formulaViewGroupId;


        this.saveReportFormula();
      }
    });
  }

  addNewReportFormula() {
    this.newReportFormula = {
      name: '',
      clientId: this.clientId,
      formulaValue: '',
      trendingPossible: false,
      color: '#e9921b',
      decimalLength: 0,
      isMoney: false,
      isPercentage: false,
      averageAchievedForRewards: false,
      currentDecision: '',
      list: [],
      position: 0,
      formulaViewGroupId: 0,
      warningLimit: 0,
      belowAverageLimit: 0,
      isDefaultVisibleOnEmployeeAndCompareMTDReport: false
    };
    this.addFormulaDialog.headerText = 'Create a new Formula';
    this.formulaAction = Action.Create;

    this.dialogRef = this.matDialog.open(FormulaCreateDialogComponent, {
      autoFocus: true, disableClose: false, panelClass: '',
      data: {
        formula: this.newReportFormula, title: "Add Formula", decisions: this.decisions, formulaViewGroups: this.formulaViewGroups
      }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result !== null) {
        this.newReportFormula.name = result.name;
        this.newReportFormula.currentDecision = result.currentDecision;
        this.newReportFormula.position = result.position;
        this.newReportFormula.containsComplexFormula = result.containsComplexFormula;
        this.newReportFormula.trendingPossible = result.trendingPossible;
        this.newReportFormula.isDefaultVisibleOnEmployeeAndCompareMTDReport = result.isDefaultVisibleOnEmployeeAndCompareMTDReport;
        this.newReportFormula.isPercentage = result.isPercentage;
        this.newReportFormula.averageAchievedForRewards = result.averageAchievedForRewards;
        this.newReportFormula.isMoney = result.isMoney;
        this.newReportFormula.decimalLength = result.decimalLength;
        this.newReportFormula.warningLimit = result.warningLimit;
        this.newReportFormula.belowAverageLimit = result.belowAverageLimit;
        this.newReportFormula.formulaViewGroupId = result.formulaViewGroupId;


        this.saveReportFormula();
      }
    });
  }
  addNewReportTableGroup() {
    this.newTableGroup = {
      reportId: this.selectedReportId,
      shortTitle: '',
      longTitle: '',
      description: '',
      displayPosition: 0,
      rankingPercentage: 0.123,
      rankingFormulaId: 0
    };
    this.addGroupDialog.headerText = 'Create add Group';
    this.currentAction = Action.Create;
    this.dialogRef = this.matDialog.open(GroupCreateDialogComponent, {
      autoFocus: true, disableClose: false, panelClass: '',
      data: {
        newTableGroup: this.newTableGroup, title: "Add group", formulas: this.formulas // decisions: this.decisions, formulaViewGroups: this.formulaViewGroups
      }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result !== null) {
        switch (result.action) {
          case 'Save':
            this.newTableGroupColumn = result.newTableGroupColumn;
            this.saveReportTableGroup();
            break;
          case 'Add_Formula':
            this.addNewReportFormula();
            break;
          case 'Edit_Formula':
            this.newTableGroupColumn = result.newTableGroupColumn;
            this.editFormula(result.id);
            break;
          default:
            break;
        }
      }
    });
  }
  saveReportTableColumnRedValue(columns: any) {
    this._reportService.updateColumnRedValue(columns.id, columns.redValueGroup1, columns.redValueGroup2).
      subscribe((response: number) => {
        this.getReports();
        this._notifier.notify('success', 'Column Added successfully.');
      }, (error) => this._notifier.notify('error', error.message), () => { });
  }
  saveReportTableGroupRedValue(columns: any) {
    this._reportService.updateGroupRedValue(columns.reportTableGroupId, columns.redValueGroup1, columns.redValueGroup2).
      subscribe((response: number) => {
        this.getReports();
        this._notifier.notify('success', 'Group Added successfully.');
      }, (error) => this._notifier.notify('error', error.message), () => { });
  }
  saveReportTableColumn() {
    switch (this.currentAction) {
      case Action.Create:
        this._reportService.addReportTableGroupColumn(
          this.newTableGroupColumn.reportTableGroupId,
          this.newTableGroupColumn.formulaId,
          this.newTableGroupColumn.displayPosition
        )
          .subscribe((response: number) => {
            this.addColumnDialog.isOpen = false;
            this.getReports();
            this._notifier.notify('success', 'Column Added successfully.');
          }, (error) => this._notifier.notify('error', error.message), () => { });
        break;
      case Action.Update:
        this._reportService.updateReportTableGroupColumn(
          this.newTableGroupColumn.id,
          this.newTableGroupColumn.reportTableGroupId,
          this.newTableGroupColumn.formulaId,
          this.newTableGroupColumn.displayPosition
        ).subscribe((response: number) => {
          this.addColumnDialog.isOpen = false;
          this.getReports();
          this._notifier.notify('success', 'Column Edited successfully.');
        }, (error) => this._notifier.notify('error', error.message), () => { });
        break;
    }
  }
  saveReportFormula() {
    switch (this.formulaAction) {
      case Action.Create:
        this.newReportFormula.formulaValue = this.newReportFormula.list.join(',');
        this._formulaService.createFormula(this.newReportFormula.name, this.clientId, this.newReportFormula.formulaValue,
          this.newReportFormula.color, this.newReportFormula.position.toString(),
          this.newReportFormula.trendingPossible, this.newReportFormula.isPercentage, this.newReportFormula.averageAchievedForRewards,
          this.newReportFormula.isMoney,
          this.newReportFormula.isDefaultVisibleOnEmployeeAndCompareMTDReport,
          this.newReportFormula.decimalLength.toString(), this.newReportFormula.belowAverageLimit.toString(),
          this.newReportFormula.warningLimit.toString(),
          this.newReportFormula.formulaViewGroupId, this.newReportFormula.containsComplexFormula)
          .subscribe((response: boolean) => {
            this.addFormulaDialog.isOpen = false;
            this.getAllFormulas();
            this.getReports();
            this._notifier.notify('success', 'Formula created successfully.');
          }, (error) => this._notifier.notify('error', error.message), () => { });
        break;
      case Action.Update:
        this.newReportFormula.formulaValue = (this.newReportFormula.list.length === 0) ?
          this.formulaValueBeforeUpdate : this.newReportFormula.list.join(',');
        this._formulaService.updateFormula(this.newReportFormula.id, this.newReportFormula.name, this.newReportFormula.formulaValue,
          this.newReportFormula.color, this.newReportFormula.position.toString(),
          this.newReportFormula.trendingPossible, this.newReportFormula.isPercentage, this.newReportFormula.averageAchievedForRewards,
          this.newReportFormula.isMoney,
          this.newReportFormula.isDefaultVisibleOnEmployeeAndCompareMTDReport,
          this.newReportFormula.decimalLength.toString(), this.newReportFormula.belowAverageLimit.toString(),
          this.newReportFormula.warningLimit.toString(),
          this.newReportFormula.formulaViewGroupId, this.newReportFormula.containsComplexFormula)
          .subscribe((response: boolean) => {
            this.addFormulaDialog.isOpen = false;
            this.getAllFormulas();
            this.getReports();
            this._notifier.notify('success', 'Formula updated successfully.');
          }, (error) => this._notifier.notify('error', error.message), () => { });
        break;
    }
  }

  saveReportTableGroup() {
    switch (this.currentAction) {
      case Action.Create:
        this._reportService.addReportTableGroup(
          this.selectedReportId,
          this.newTableGroup.shortTitle, this.newTableGroup.longTitle, this.newTableGroup.description,
          this.newTableGroup.displayPosition, this.newTableGroup.rankingPercentage, this.newTableGroup.rankingFormulaId
        )
          .subscribe((response: number) => {
            this.addGroupDialog.isOpen = false;
            this.getReports();
            this._notifier.notify('success', 'Group Added successfully.');
          }, (error) => this._notifier.notify('error', error.message), () => { });
        break;
      case Action.Update:
        this._reportService.updateReportTableGroup(this.newTableGroup.id,
          this.selectedReportId,
          this.newTableGroup.shortTitle, this.newTableGroup.longTitle, this.newTableGroup.description,
          this.newTableGroup.displayPosition, this.newTableGroup.rankingPercentage, this.newTableGroup.rankingFormulaId
        )
          .subscribe((response: number) => {
            this.addGroupDialog.isOpen = false;
            this.getReports();
            this._notifier.notify('success', 'Group Updated successfully.');
          }, (error) => this._notifier.notify('error', error.message), () => { });
        break;
    }
  }

  saveReport() {
    switch (this.currentAction) {
      case Action.Create:
        this._reportService.createReport(this._authService.userId(),
          this.report.title, this.report.message, this.report.dateTypeId, this.report.locationTierFocusId, this.report.reportType,
          this.report.locationId)
          .subscribe((response: number) => {
            this.dialog.isOpen = false;
            this.getReports();
            this._notifier.notify('success', 'Report created successfully.');
          }, (error) => this._notifier.notify('error', error.message), () => { });
        break;
      case Action.Update:
        this._reportService.updateReport(this.report.id, this._authService.userId(),
          this.report.title, this.report.message, this.report.dateTypeId, this.report.locationTierFocusId, this.report.reportType,
          this.report.locationId)
          .subscribe((response: number) => {
            this.dialog.isOpen = false;
            this.getReportsFullReload();
            this._notifier.notify('success', 'Dashboard created successfully.');
          }, (error) => this._notifier.notify('error', error.message), () => { });
        break;
    }
  }

  // #endregion Add/Edit/Save Report

  // #region Report Selected / Core Report Loading Algo

  onReportSelectedLimited() {
    this.reports.forEach((report) => {
      if (report.id === parseInt(this.selectedReportId.toString(), 10)) {
        this.selectedReport = report;
        this.selectedReport.GroupA = new SellingGroup();
        this.selectedReport.GroupA.sellers = [];
        this.selectedReport.GroupA.sellers = this.getGroupASellers(this.selectedDistrictId); // this.selectedReport.locationId
        this.selectedReport.GroupA.locationTierEnum = +this.optionRadioSelected + 1; // this.selectedReport.locationTierFocusId + 1;
        this.selectedReport.GroupB = new SellingGroup();
        this.selectedReport.GroupB.sellers = [];
        this.selectedReport.GroupB.locationTierEnum = +this.optionRadioSelected + 2; // this.selectedReport.locationTierFocusId + 2;
        if (+this.optionRadioSelected < 3) { // REMOVE FALSE
          this.selectedReport.GroupB.sellers = this.getGroupBSellers(true, this.selectedReport.GroupA.sellers);
        } else
          if (+this.optionRadioSelected === 3) { // REMOVE TRUE
            this.selectedReport.GroupB.sellers = this.getGroupBSellers(false, this.selectedReport.GroupA.sellers);
          }
        this.selectedReport.totalQuantityColumns = 0;
        //console.log(this.selectedReport);
        this.selectedReport.listOfColumns = [];
        if (this.selectedReport.reportTableGroup.length) {
          this.selectedReport.numberOfSubGroups = this.selectedReport.reportTableGroup.length;
          this.selectedReport.totalQuantityColumns = this.selectedReport.totalQuantityColumns + (this.selectedReport.numberOfSubGroups * 2);
          this.selectedReport.reportTableGroup.forEach((tableGroup) => {
            const thisGroup = tableGroup;
            thisGroup.numberOfColumns = 0;

            if (thisGroup.reportTableGroupColumn.length) {
              thisGroup.numberOfColumns = thisGroup.reportTableGroupColumn.length + thisGroup.numberOfColumns;
              thisGroup.reportTableGroupColumn.forEach((columns) => {
                this.selectedReport.totalQuantityColumns = this.selectedReport.totalQuantityColumns + 1;
                this.selectedReport.listOfColumns.push(columns);
              });
            }
            const rankColumn = new ReportTableGroupColumn();
            rankColumn.reportTableGroupId = thisGroup.id;
            rankColumn.displayPosition = 0;
            rankColumn.formula = this.formulas.find(x => x.id === thisGroup.rankingFormulaId);
            rankColumn.formulaId = thisGroup.rankingFormulaId;
            rankColumn.nameValueOverride = 'Rank';
            rankColumn.groupALowestRank = 1;
            rankColumn.groupBLowestRank = 1;
            const rankColumn2 = new ReportTableGroupColumn();
            rankColumn2.reportTableGroupId = thisGroup.id;
            rankColumn2.displayPosition = 0;
            rankColumn2.redValueGroup1 = thisGroup.redValueGroup1;
            rankColumn2.redValueGroup2 = thisGroup.redValueGroup2;
            rankColumn2.formulaId = thisGroup.rankingFormulaId;
            rankColumn2.formula = this.formulas.find(x => x.id === thisGroup.rankingFormulaId);
            rankColumn2.nameValueOverride = 'Ranking';
            this.selectedReport.listOfColumns.push(rankColumn);
            this.selectedReport.listOfColumns.push(rankColumn2);
          });
        }
      }
      this.rankReportSalesObject = this.copyOfSalesReport;
      this.rankReportSalesObject.sellingA.sellers.forEach((sellA) => {
        const thisSeller = sellA;
        sellA.formulas = [];
        this.selectedReport.listOfColumns.forEach((column) => {
          const thisCol = column;
          const formy = { value: 0, formulaId: 0, nameValueOverride: '' };
          formy.value = this.evaluateFormula(thisCol, thisSeller, 'A');
          formy.formulaId = thisCol.formula.id;
          formy.nameValueOverride = thisCol.nameValueOverride;
          sellA.formulas.push(formy);
        });
        this.selectedReport.GroupA.sellers.forEach((reportSellA) => {
          const selectedReportMatch = reportSellA;
          if (thisSeller.locationId === selectedReportMatch.locationId) {
            selectedReportMatch.formulas = sellA;
          }
        });
      });
      this.rankReportSalesObject.sellingB.sellers.forEach((sellB) => {
        const thisSeller = sellB;
        sellB.formulas = [];
        this.selectedReport.listOfColumns.forEach((column) => {
          const thisCol = column;
          const formy = { value: 0, formulaId: 0, nameValueOverride: '' };
          formy.value = this.evaluateFormula(thisCol, thisSeller, 'B');
          formy.formulaId = thisCol.formula.id;
          formy.nameValueOverride = thisCol.nameValueOverride;
          sellB.formulas.push(formy);
        });
        this.selectedReport.GroupB.sellers.forEach((reportSellB) => {
          const selectedReportMatch = reportSellB;
          if (thisSeller.locationId === selectedReportMatch.locationId || thisSeller.employeeId === selectedReportMatch.employeeId) {
            selectedReportMatch.formulas = sellB;
          }
        });
      });
    });

    this.reportDoneLoading = true;
  }

  onRadioButtonChanged(event: MatRadioChange) {
    this.optionRadioSelected = event.value;
    this.onReportSelected();
  }
  onReportSelected() {
    this.groupBLowestRank = 0;
    this.groupALowestRank = 0;
    this.columnUnderSorting = -2;
    if (this.reportDoneLoading) this._loaderService.show();
    if (this.reports && this.reportDoneLoading) {
      this.reportDoneLoading = false;

      this.reports.forEach((report) => {
        if (report.id === parseInt(this.selectedReportId.toString(), 10)) {
          let locationToSend = 0;
          let secondLocationToSend = 0;
          if (+this.optionRadioSelected === 3) {
            locationToSend = this.selectedDistrictId;
          } else if (+this.optionRadioSelected === 2) {
            locationToSend = this.selectedRegionId;
          } else if (+this.optionRadioSelected === 1) {
            locationToSend = this.selectedClientLocationId;
          }
          if (+this.optionRadioSelected === 2) {
            secondLocationToSend = this.selectedDistrictId;
          } else if (+this.optionRadioSelected === 1) {
            secondLocationToSend = this.selectedRegionId;
          }
          this.selectedReport = report;
          this.selectedReport.GroupA = new SellingGroup();
          this.selectedReport.GroupA.sellers = [];
          this.selectedReport.GroupA.sellers = this.getGroupASellers(locationToSend);
          // this.getGroupASellers(this.selectedReport.locationId);
          this.selectedReport.GroupA.locationTierEnum = +this.optionRadioSelected + 1; // this.selectedReport.locationTierFocusId + 1;
          this.selectedReport.GroupB = new SellingGroup();
          this.selectedReport.GroupB.sellers = [];
          this.selectedReport.GroupB.locationTierEnum = +this.optionRadioSelected + 2; // this.selectedReport.locationTierFocusId + 2;
          if (+this.optionRadioSelected < 3) { // REMOVE FALSE
            this.selectedReport.GroupB.sellers = this.getGroupBSellers(true, this.selectedReport.GroupA.sellers);
          } else
            if (+this.optionRadioSelected === 3) { // REMOVE TRUE
              this.selectedReport.GroupB.sellers = this.getGroupBSellers(false, this.selectedReport.GroupA.sellers);
            }
          this.selectedReport.totalQuantityColumns = 0;
          this.selectedReport.listOfColumns = [];
          if (this.selectedReport.reportTableGroup.length) {
            this.selectedReport.numberOfSubGroups = this.selectedReport.reportTableGroup.length;
            this.selectedReport.totalQuantityColumns = this.selectedReport.totalQuantityColumns
              + (this.selectedReport.numberOfSubGroups * 2);
            this.selectedReport.reportTableGroup.forEach((tableGroup) => {
              const thisGroup = tableGroup;
              thisGroup.numberOfColumns = 0;
              if (thisGroup.reportTableGroupColumn.length) {
                thisGroup.numberOfColumns = thisGroup.numberOfColumns + thisGroup.reportTableGroupColumn.length;
                thisGroup.reportTableGroupColumn.forEach((column) => {
                  this.selectedReport.totalQuantityColumns = this.selectedReport.totalQuantityColumns + 1;
                  this.selectedReport.listOfColumns.push(column);
                });
              }
              const rankColumn = new ReportTableGroupColumn();
              rankColumn.reportTableGroupId = thisGroup.id;
              rankColumn.displayPosition = 0;
              rankColumn.groupALowestRank = 0;
              rankColumn.groupBLowestRank = 0;
              rankColumn.formulaId = thisGroup.rankingFormulaId;
              rankColumn.formula = this.formulas.find(x => x.id === thisGroup.rankingFormulaId);
              rankColumn.nameValueOverride = 'Rank';
              const rankColumn2 = new ReportTableGroupColumn();
              rankColumn2.nameValueOverride = 'Ranking';
              rankColumn2.redValueGroup1 = thisGroup.redValueGroup1;
              rankColumn2.redValueGroup2 = thisGroup.redValueGroup2;
              rankColumn2.reportTableGroupId = thisGroup.id;
              rankColumn2.displayPosition = 0;
              rankColumn2.formulaId = thisGroup.rankingFormulaId;
              rankColumn2.formula = this.formulas.find(x => x.id === thisGroup.rankingFormulaId);
              this.selectedReport.listOfColumns.push(rankColumn);
              this.selectedReport.listOfColumns.push(rankColumn2);
            });
          }

          if (locationToSend !== null && locationToSend !== 0) {
            this._reportService.requestRankReportOverrideLocation(this.selectedReport.id, locationToSend,
              this.selectedDuration).subscribe(async (response: any) => {
                this.copyOfSalesReport = response;
                this.rankReportSalesObject = response;
                this.rankReportSalesObject.sellingA.sellers.forEach((sellA) => {
                  const thisSeller = sellA;
                  sellA.formulas = [];
                  this.selectedReport.listOfColumns.forEach((column) => {
                    const thisCol = column;
                    const formy = { value: 0, formulaId: 0, nameValueOverride: '' };
                    formy.value = this.evaluateFormula(thisCol, thisSeller, 'A');
                    formy.formulaId = thisCol.formula.id;
                    formy.nameValueOverride = thisCol.nameValueOverride;
                    sellA.formulas.push(formy);
                  });
                  this.selectedReport.GroupA.sellers.forEach((reportSellA) => {
                    const selectedReportMatch = reportSellA;
                    if (thisSeller.locationId === selectedReportMatch.locationId) {
                      selectedReportMatch.formulas = sellA;
                    }
                  });
                });
                this.rankReportSalesObject.sellingB.sellers.forEach((sellB) => {
                  const thisSeller = sellB;
                  sellB.formulas = [];
                  this.selectedReport.listOfColumns.forEach((column) => {
                    const thisCol = column;
                    const formy = { value: 0, formulaId: 0, nameValueOverride: '' };
                    formy.value = this.evaluateFormula(thisCol, thisSeller, 'B');
                    formy.formulaId = thisCol.formula.id;
                    formy.nameValueOverride = thisCol.nameValueOverride;
                    sellB.formulas.push(formy);
                  });
                  this.selectedReport.GroupB.sellers.forEach((reportSellB) => {
                    const selectedReportMatch = reportSellB;
                    if (thisSeller.locationId === selectedReportMatch.locationId || thisSeller.employeeId === selectedReportMatch.employeeId) {
                      selectedReportMatch.formulas = sellB;
                    }
                  });
                });


                this.reportDoneLoading = true;
                this.firstLoad = false;
                this._notifier.notify('success', 'Report Loaded!');
                setTimeout(() => { this.sortTable(1); }, 500);
                setTimeout(() => { this.sortTableB(1); }, 500);
              }, (error) => this._notifier.notify('error', error.message), () => { this._loaderService.hide() });
          }
        }
      });
    }
  }

  getReportsFullReload() {

    this._reportService.getReportsByUser(this.selectedClientId).subscribe((response: RankContest[]) => {
      this.reports = response;
      //console.log(this.reports);
      if (typeof this.selectedReport !== 'undefined') {
        this.onReportSelected();
      }
    }, (error) => this._notifier.notify('error', error.message), () => { });
  }

  getReports() {
    //console.log('on Get report');
    this._reportService.getReportsByUser(this.selectedClientId).subscribe((response: RankContest[]) => {
      this.reports = response;
      //console.log(this.reports);
      if (typeof this.selectedReport !== 'undefined') {
        this.onReportSelectedLimited();
      }
    }, (error) => this._notifier.notify('error', error.message), () => { });
  }

  getReportsFirstLoad() {
    this._loaderService.show();

    if (this.selectedClientId && this.selectedClientId !== 0) {
      this._reportService.getReportsByUser(this.selectedClientId).subscribe((response: RankContest[]) => {
        this.reports = response;
        //console.log(this.reports);
        this.selectedReport = this.reports[0];
        this.selectedReportId = this.reports[0].id;
        this.onReportSelected();
      }, (error) => this._notifier.notify('error', error.message), () => { });
    }
  }

  // #endregion Report Select / Load Report

  // #region Delete Parts of Report

  deleteReport(report) {
    this.dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      autoFocus: true, disableClose: false, panelClass: '',
      data: { title: 'Delete report', message: 'Are you sure you want to delete this report?' }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this._reportService.deleteReport(report.id)
          .subscribe((response: boolean) => {
            this.dialog.isOpen = false;
            this.getReportsFirstLoad();
            this._notifier.notify('success', 'Report deleted successfully.');
          }, (error) => this._notifier.notify('error', error.message), () => { });
      }
    });
  }

  deleteReportTableGroup(report) {
    this.dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      autoFocus: true, disableClose: false, panelClass: '',
      data: { title: 'Delete table group', message: 'Are you sure you want to delete this table group?' }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this._reportService.deleteReportTableGroup(report.id)
          .subscribe((response: boolean) => {
            this.addGroupDialog.isOpen = false;
            this.getReports();
            this._notifier.notify('success', 'Table group deleted successfully.');
          }, (error) => this._notifier.notify('error', error.message), () => { });
      }
    });
  }

  deleteReportTableGroupColumn(report) {
    this.dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      autoFocus: true, disableClose: false, panelClass: '',
      data: { title: 'Delete table group column', message: 'Are you sure you want to delete this table group column?' }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this._reportService.deleteReportTableGroupColumn(report.id)
          .subscribe((response: boolean) => {
            this.addColumnDialog.isOpen = false;
            this.getReports();
            this._notifier.notify('success', 'Table group column deleted successfully.');
          }, (error) => this._notifier.notify('error', error.message), () => { });
      }
    });
  }

  deleteRankContest(dashboard) {
    if (!confirm('Are you sure you want to delete this report!?')) {
      return false;
    }
  }

  // #endregion Delete Report Parts

  // #region Evaluate Formula/Rank/Score

  getValueForFormula(formulaObject: any, stats: any) {
    const formulaElements = formulaObject.split(',');
    let OpenCloseCount = 0;
    let stringFormula = '';
    let result = 0;
    for (let i = 0; i < formulaElements.length; i++) {
      const currentWord = formulaElements[i];
      const nextWord = (i < formulaElements.length - 1) ? formulaElements[i + 1] : '';
      if (currentWord === '(') {
        OpenCloseCount = OpenCloseCount + 1;
        stringFormula += currentWord;
      } else if (currentWord === ')' && OpenCloseCount > 0) {
        OpenCloseCount = OpenCloseCount - 1;
        stringFormula += currentWord;
      } else if (currentWord === 'QTY') {
        const skuGroup = stats.find(s => s.name === nextWord && s.gpOrQty === 2);
        if (skuGroup && skuGroup.value > 0) {
          stringFormula += '(' + skuGroup.value + ')';
        } else {
          stringFormula = '0';
          break;
        }
      } else if (currentWord === 'GP') {
        const skuGroup = stats.find(s => s.name === nextWord && s.gpOrQty === 1);
        if (skuGroup && skuGroup.value > 0) {
          stringFormula += '(' + skuGroup.value + ')';
        } else {
          stringFormula = '0';
          break;
        }
      } else if (currentWord === '+' || currentWord === '-' || currentWord === '*' || currentWord === '/') {
        stringFormula += currentWord;
      }
    }
    try {
      result = this.evaluate(stringFormula);
    } catch (e) {
      result = 0;
    }
    if (isNaN(result)) {
      result = 0;
    }
    return result;
  }

  evaluateFinalRank(seller: any, groupCode: any) {
    const RankingObject = [];
    let mySum = 0;
    let tempValue = 1;
    if (groupCode === 'A') {
      this.selectedReport.GroupA.sellers.forEach((c) => {
        RankingObject.push([c.locationId, this.evaluateScore(c, groupCode)]);
        if (c.locationId === seller.locationId) {
          mySum = this.evaluateScore(c, groupCode);
        }
      });
    } else if (+this.optionRadioSelected !== 3 && groupCode === 'B') {
      this.selectedReport.GroupB.sellers.forEach((c) => {
        RankingObject.push([c.locationId, this.evaluateScore(c, groupCode)]);
        if (c.locationId === seller.locationId) {
          mySum = this.evaluateScore(c, groupCode);
        }
      });
    } else if (+this.optionRadioSelected === 3 && groupCode === 'B') {
      this.selectedReport.GroupB.sellers.forEach((c) => {
        RankingObject.push([c.employeeId, this.evaluateScore(c, groupCode)]);
        if (c.employeeId === seller.employeeId) {
          mySum = this.evaluateScore(c, groupCode);
        }

      });
    }
    let wasLowest = true;
    RankingObject.forEach((x) => {
      if (mySum <= x[1]) {
        if (mySum < x[1]) {
          wasLowest = false;
        }
      } else {
        tempValue++;
      }
    });
    if (wasLowest && this.groupALowestRank <= tempValue && groupCode === 'A') {
      this.groupALowestRank = tempValue;
    }
    if (wasLowest && this.groupBLowestRank <= tempValue && groupCode === 'B') {
      this.groupBLowestRank = tempValue;
    }
    return tempValue;
  }

  evaluateScore(seller: any, groupCode: any) {
    let sum = 0;
    this.selectedReport.reportTableGroup.forEach((c) => {
      const newFormula: ReportTableGroupColumn = new ReportTableGroupColumn();
      newFormula.formula = new Formula();
      newFormula.formula.id = c.rankingFormulaId;
      newFormula.nameValueOverride = 'Rank';
      sum = sum + (this.findYourRankOrFormulaRaw(seller, newFormula) * c.rankingPercentage);
    });
    return sum;
  }

  evaluateFormula(formula: any, seller: any, groupCode: any) {
    const formulaId = formula.formulaId;
    const name = formula.nameValueOverride;
    if (name === 'Rank') {
      let myValue = 0;
      let tempValue = 1;
      const RankingObject = [];
      const newFormula: ReportTableGroupColumn = new ReportTableGroupColumn();
      newFormula.formulaId = formulaId;
      newFormula.nameValueOverride = '';
      if (groupCode === 'A') {
        this.selectedReport.GroupA.sellers.forEach((c) => {
          if (c.locationId === seller.locationId) {
            myValue = this.evaluateFormula(newFormula, c, 'A');
          }
          RankingObject.push([c.locationId, this.evaluateFormula(newFormula, c, 'A')]);
        });
        let wasLowest = true;
        RankingObject.forEach((x) => {
          if (myValue >= x[1]) {
            if (myValue > x[1] && myValue > 0) {
              wasLowest = false;
            }
          } else {
            tempValue++;
          }
        });
        if (wasLowest && formula.groupALowestRank <= tempValue) {
          formula.groupALowestRank = tempValue;
        }
      } else if (groupCode === 'B') {
        this.selectedReport.GroupB.sellers.forEach((c) => {
          if (+this.optionRadioSelected !== 3) {
            if (c.locationId === seller.locationId) {
              myValue = this.evaluateFormula(newFormula, c, 'B');
            }
            RankingObject.push([c.locationId, this.evaluateFormula(newFormula, c, 'B')]);
          } else {
            if (c.employeeId === seller.employeeId) {
              myValue = this.evaluateFormula(newFormula, c, 'B');
            }
            RankingObject.push([c.employeeId, this.evaluateFormula(newFormula, c, 'B')]);
          }
        });
        let wasLowest = true;
        RankingObject.forEach((x) => {
          if (myValue >= x[1]) {
            if (myValue > x[1] && myValue > 0) {
              wasLowest = false;
            }
          } else {
            tempValue++;
          }
        });
        if (wasLowest && formula.groupBLowestRank <= tempValue) {
          formula.groupBLowestRank = tempValue;
        }
      }
      return tempValue;
    }
    if (formulaId === 0) {
      return 0;
    }
    const formulaObject = this.formulas.find(x => x.id === formulaId).formulaValue;
    let sellerObject = null;
    let goalObject = null;
    let conversionData = null;
    let hoursWorkedData = null;
    let sellerId = 0;
    if (groupCode === 'A') {
      sellerId = seller.locationId;
      sellerObject = this.rankReportSalesObject.sellingA.sellers.find(x => x.locationId === sellerId).skuGroups;
      goalObject = this.rankReportSalesObject.sellingA.sellers.find(x => x.locationId === sellerId).goalList;
      conversionData = this.rankReportSalesObject.sellingA.sellers.find(x => x.locationId === sellerId).conversionData;
      hoursWorkedData = this.rankReportSalesObject.sellingA.sellers.find(x => x.locationId === sellerId).hoursWorkedData;
    } else if (groupCode === 'B') {
      if (+this.optionRadioSelected !== 3) {
        sellerId = seller.locationId;
        sellerObject = this.rankReportSalesObject.sellingB.sellers.find(x => x.locationId === sellerId).skuGroups;
        goalObject = this.rankReportSalesObject.sellingB.sellers.find(x => x.locationId === sellerId).goalList;
        conversionData = this.rankReportSalesObject.sellingB.sellers.find(x => x.locationId === sellerId).conversionData;
        hoursWorkedData = this.rankReportSalesObject.sellingB.sellers.find(x => x.locationId === sellerId).hoursWorkedData;
      } else {
        sellerId = seller.employeeId;
        sellerObject = this.rankReportSalesObject.sellingB.sellers.find(x => x.employeeId === sellerId).skuGroups;
        goalObject = this.rankReportSalesObject.sellingB.sellers.find(x => x.employeeId === sellerId).goalList;
        conversionData = this.rankReportSalesObject.sellingB.sellers.find(x => x.employeeId === sellerId).conversionData;
        hoursWorkedData = this.rankReportSalesObject.sellingB.sellers.find(x => x.employeeId === sellerId).hoursWorkedData;
      }
    }
    const formulaElements = formulaObject.split(',');
    let OpenCloseCount = 0;
    let stringFormula = '';
    let result = 0;
    for (let i = 0; i < formulaElements.length; i++) {
      const currentWord = formulaElements[i];
      const nextWord = (i < formulaElements.length - 1) ? formulaElements[i + 1] : '';
      if (currentWord === '(') {
        OpenCloseCount = OpenCloseCount + 1;
        stringFormula += currentWord;
      } else if (currentWord === ')' && OpenCloseCount > 0) {
        OpenCloseCount = OpenCloseCount - 1;
        stringFormula += currentWord;
      } else if (currentWord === 'QTY') {
        const skuGroup = sellerObject.find(s => s.name === nextWord);
        if (skuGroup && skuGroup.quantity > 0) {
          stringFormula += '(' + skuGroup.quantity + ')';
        } else {
          stringFormula = '0';
          break;
        }
      } else if (currentWord === 'GP') {
        const skuGroup = sellerObject.find(s => s.name === nextWord);
        if (skuGroup && skuGroup.profit > 0) {
          stringFormula += '(' + skuGroup.profit + ')';
        } else {
          stringFormula = '0';
          break;
        }
      } else if (currentWord === 'GOAL') {
        if (goalObject) {
          const thisGoal = goalObject.find(s => s.name === nextWord);
          if (thisGoal && thisGoal.goalValue > 0) {
            stringFormula += '(' + thisGoal.goalValue + ')';
          } else {
            stringFormula = '0';
            break;
          }
        }
      } else if (currentWord === 'REBIZ') {
        if (conversionData) {
          switch (nextWord) {
            case 'Boxes':
              stringFormula += '(' + conversionData.boxes + ')';
              break;
            case 'Traffic':
              stringFormula += '(' + conversionData.traffic + ')';
              break;
            case 'GrossProfit':
              stringFormula += '(' + conversionData.grossProfit + ')';
              break;
            default:
              stringFormula += 0;
              break;
          }
        }
      } else if (currentWord === 'HOURS') {
        if (hoursWorkedData) {
          switch (nextWord) {
            case 'Worked':
              stringFormula += '(' + hoursWorkedData.hoursWorked + ')';
              break;
            default:
              stringFormula += 0;
              break;
          }
        }
      }
      else if (currentWord === 'DATE') {
        const currentMonth = moment().format('MMMM');
        const lastMonth = moment().subtract(1, 'month').format('MMMM');
        const secondLastMonth = moment().subtract(2, 'month').format('MMMM');
        const today = moment();
        const yesterday = moment().subtract(1, 'day');
        const previousWeekMonday = moment().weekday(-6);
        const twoWeekBeforeMonday = moment().weekday(-6).weekday(-6);
        const previousWeekSunday = moment().weekday(0).subtract(7, 'days');
        const currentWeekSunday = moment().weekday(0);
        const currentMonthFirstDay = moment().startOf('month');
        const currentMonthLastDay = moment().endOf('month');
        const currentYearFirstDay = moment().startOf('year');
        const previousMonthFirstDay = moment().subtract(1, 'months').startOf('month');
        const twoMonthBackFirstDay = moment().subtract(2, 'months').startOf('month');
        const twoMonthBackLastDay = moment().subtract(2, 'months').endOf('month');
        const previousMonthSameDay = moment().subtract(1, 'months');
        const previousMonthLastDay = moment().subtract(1, 'months').endOf('month');
        const previousYearFirstDay = moment().subtract(1, 'year').startOf('year');
        const previousYearSameDay = moment().subtract(1, 'year');
        const previousYearSameMonthFirstDay = moment().subtract(1, 'year').startOf('month');
        const passed: number = today.date();
        const remaining: number = currentMonthLastDay.date() - today.date();
        const daysinmonth: number = currentMonthLastDay.date();
        if (this.selectedReport.dateTypeId === 1) {
          if (nextWord === 'Passed') {
            stringFormula += '(' + passed + ')';
          } else if (nextWord === 'Remaining') {
            stringFormula += '(' + remaining + ')';
          } else if (nextWord === 'DaysInMonth') {
            stringFormula += '(' + daysinmonth + ')';
          } else {
            stringFormula = '0';
            break;
          }
        } else if (this.selectedReport.dateTypeId === 5) {
          stringFormula = '0';
        }
      } else if (currentWord === '+' || currentWord === '-' || currentWord === '*' || currentWord === '/') {
        stringFormula += currentWord;
      }
    }
    try {
      result = this.evaluate(stringFormula);
    } catch (e) {
      result = 0;
    }
    if (isNaN(result)) {
      result = 0;
    }
    return result;
  }

  evaluate(code: string) {
    const f = new Function('return ' + code);
    return f();
  }

  // #endregion Evaluate Rank/Score/Formula

  // #region LocationTreeFunctions / Select Locations (MOSTLY DEAD CODE I THINK)

  findParent(location: Location) {
    let newLocation: Location = location;
    while (this.topLocationToShow < newLocation.locationTypeCodeId) {
      newLocation = this.locations.find(x => x.id === newLocation.parentId);
    }
    return newLocation;
  }

  parseLocationsByTier(roots) {
    if (roots.depth === this.depthSelection - 1) {
      roots.ignored = true;
      roots._children = roots.children;
      roots.children = null;
    } else if (typeof roots.children !== 'undefined' && roots.children !== null) {
      roots.children.forEach((c) => { this.parseLocationsByTier(c); });
    }
  }

  hideDepth() {
    this.parseLocationsByTier(this.root);
    this.buildLocationTreeInterface(this.root);
  }

  markSelectedRoot(roots) {
    const locationArray = this.report.locations.find((element) => element.id === roots.data.id && roots.depth < 4);
    const employeeArray = this.report.employees.find((element) => element.id === roots.data.id && roots.depth === 5);
    if (locationArray) {
      roots.selected = true;
    } else if (employeeArray) {
      roots.selected = true;
    } else {
      roots.selected = false;
    }
    if (typeof roots.children !== 'undefined' && roots.children !== null) { roots.children.forEach((c) => { this.markSelectedRoot(c); }); }
  }

  buildLocationTreeInterface(sometimes?: any) {
    // ************** Generate the tree diagram	 *****************
    const margin = { top: 100, right: 90, bottom: 30, left: 200 },
      width = screen.width - 300 - margin.left - margin.right,
      height = screen.height - 400 - margin.top - margin.bottom;
    document.getElementById('p2').innerHTML = '';
    // append the svg object to the body of the page
    // appends a 'group' element to 'svg'
    // moves the 'group' element to the top left margin

    const svg = d3.select('#p2').append('svg')
      .attr('width', width + margin.right + margin.left)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate('
        + margin.left + ',' + margin.top + ')');
    let i = 0;
    const duration = 750;
    let root;
    // declares a tree layout and assigns the size
    const treemap = d3.tree().size([height, width]);
    // Assigns parent, children, height, depth
    root = d3.hierarchy(this.locationTreeToView, function (d) { return d.children; });
    root.x0 = height / 2;
    root.y0 = 0;
    if (sometimes) {
      root = this.root;
    }

    this.root = root;
    if (sometimes) {
      root = this.root;
    }
    update(root);

    const updateLocationDialog = (nodeData) => {
      this.report.locationId = nodeData.data.id;
      this.selectedLocation = this.locations.find(loc => loc.id === nodeData.data.id);
      this.report.locationTierFocusId = this.selectedLocation.locationTypeCodeId;
      this.locationSelect.cancel();
    };

    const updateDashboardLocationList = (nodeData, addOrRemove: boolean) => {
      let filteredArray = [];
      if (addOrRemove) {
        this.report.locations.push(this.locations.find(emp => emp.id === nodeData.data.id));
      } else {
        filteredArray = this.report.locations.filter((element) => element.id !== nodeData.data.id);
        this.report.locations = filteredArray;
      }
    };
    const updateDashboardEmployeeList = (nodeData, addOrRemove: boolean) => {
      let filteredArray = [];
      if (addOrRemove) {
        this.report.employees.push(this.employees.find(emp => emp.id === nodeData.data.id));
      } else {
        filteredArray = this.report.employees.filter((element) => element.id !== nodeData.data.id);
        this.report.employees = filteredArray;
      }
    };
    const finecallout = ((d) => {
      if (d.data.locationTypeCodeId < 5) {
        this.trimFullTree(d.data);
        this.buildLocationTreeInterface();
        this.zoomed = true;
      }
    });

    function update(source) {

      // Assigns the x and y position for the nodes
      const treeData = treemap(root);

      // Compute the new tree layout.
      const nodes = treeData.descendants(),
        links = treeData.descendants().slice(1);

      // Normalize for fixed-depth.
      nodes.forEach(function (d) { d.y = d.depth * 180; });

      // ****************** Nodes section ***************************

      // Update the nodes...
      const node = svg.selectAll('g.node')
        .data(nodes, function (d) { return d.id || (d.id = ++i); });

      // Enter any new modes at the parent's previous position.
      const nodeEnter = node.enter().append('g')
        .attr('class', 'node')
        .attr('transform', function (d) {
          return 'translate(' + source.y0 + ',' + source.x0 + ')';
        })

        .on('click', click); // .on('contextmenu', rightclick);


      // Add Circle for the nodes
      nodeEnter.append('circle')
        .attr('class', 'node')
        .attr('r', 1e-6)
        .style('fill', function (d) {
          return d._children ? 'lightsteelblue' : '#fff';
        });

      // Add labels for the nodes
      nodeEnter.append('text')
        .attr('dy', '.35em')
        .attr('x', function (d) {
          return d.children || d._children ? -13 : 13;
        })
        .attr('text-anchor', function (d) {
          return d.children || d._children ? 'end' : 'start';
        })
        .text(function (d) { return d.data.name; });

      // UPDATE
      const nodeUpdate = nodeEnter.merge(node);

      // Transition to the proper position for the node
      nodeUpdate.transition()
        .duration(duration)
        .attr('transform', function (d) {
          return 'translate(' + d.y + ',' + d.x + ')';
        });

      // Update the node attributes and style
      nodeUpdate.select('circle.node')
        .attr('r', 10)
        .style('fill', function (d) {
          return d.ignored ? '#ed008c' : d.selected ? '#34c70e' : '#fff';
        })
        .attr('cursor', 'pointer');
      // Remove any exiting nodes
      const nodeExit = node.exit().transition()
        .duration(duration)
        .attr('transform', function (d) {
          return 'translate(' + source.y + ',' + source.x + ')';
        })
        .remove();
      // On exit reduce the node circles size to 0
      nodeExit.select('circle')
        .attr('r', 1e-6);

      // On exit reduce the opacity of text labels
      nodeExit.select('text')
        .style('fill-opacity', 1e-6);

      // ****************** links section ***************************

      // Update the links...
      const link = svg.selectAll('path.link')
        .data(links, function (d) { return d.id; });

      const linkEnter = link.enter().insert('path', 'g')
        .attr('class', 'link')
        .attr('d', function (d) {
          const o = { x: source.x0, y: source.y0 };
          return diagonal(o, o);
        });

      const linkUpdate = linkEnter.merge(link);
      linkUpdate.transition()
        .duration(duration)
        .attr('d', function (d) { return diagonal(d, d.parent); });
      const linkExit = link.exit().transition()
        .duration(duration)
        .attr('d', function (d) {
          const o = { x: source.x, y: source.y };
          return diagonal(o, o);
        })
        .remove();
      nodes.forEach(function (d) {
        d.x0 = d.x;
        d.y0 = d.y;
      });
      function diagonal(s, d) {

        const path = `M ${s.y} ${s.x}
              C ${(s.y + d.y) / 2} ${s.x},
                ${(s.y + d.y) / 2} ${d.x},
                ${d.y} ${d.x}`;
        return path;
      }
      function click(d) {
        updateLocationDialog(d);
        update(d);
      }
    }
  }

  AddSelectedLocationList(nodeData: Location, addOrRemove: boolean) {
    const key = nodeData.id;
    if (nodeData.locationTypeCodeId === 5) {
      if (addOrRemove) {
        this.report.employees.push(this.employees.find(loc => loc.id === nodeData.id));
      } else {
        const filteredArray = this.report.employees.filter((element) => {
          return element.id !== nodeData.id;
        });
        this.report.employees = filteredArray;
      }
    } else {
      if (addOrRemove) {
        this.report.locations.push(this.locations.find(loc => loc.id === nodeData.id));
      } else {
        const filteredArray = this.report.locations.filter((element) => {
          return element.id !== nodeData.id;
        });
        this.report.locations = filteredArray;
      }
    }
  }

  onLocationSelected(location: Location) {
    location.selected = !location.selected;
    if (location.selected) {
      this.AddSelectedLocationList(location, true);
      this.updateChildrenAfterSelect(location);
    } else {
      this.AddSelectedLocationList(location, false);
      this.updateChildrenAfterDeselect(location);
    }
  }

  updateChildrenAfterDeselect(location: Location) {
    for (const dog in location.children) {
      if (location.children[dog].selected) {
        location.children[dog].isVisible = true;
      } else {
        location.children[dog].isVisible = true;
        this.updateChildrenAfterDeselect(location.children[dog]);
      }
    }
  }

  updateChildrenAfterSelect(location: Location) {
    location.children.forEach((child) => {
      child.isVisible = false;
      this.updateChildrenAfterSelect(child);
    });
  }

  searchLocation(array: any = null) {
    array = array ? array : this.locations2[0];
    for (const key in array) {
      if (key === 'name') {
        if (array.name.toLowerCase().indexOf(this.locationSearchString.toLowerCase()) > -1) {
          array.isFiltered = false;
        } else {
          array.isFiltered = true;
        }
        array.children.forEach((child) => { this.searchLocation(child); });
      }

    }
  }

  openLocationTree() {
    this.locationSelect.isOpen = true;
    this.locationSelect.headerText = 'Left click on location';
    this.depthview = [];
    const loctier = this.report.locationTierFocusId;
    for (let x = 2; x < loctier; x++) {
      this.depthview.push(this.deptharray[x - 2]);
    }
    this.depthSelection = 3;
    const cancelZoom = (d) => {
      if (this.zoomed) {
        this.trimFullTree();
        this.buildLocationTreeInterface();
        this.zoomed = false;
      }
      document.getElementById('cancelZoom').addEventListener('contextmenu',
        function (ev) {
          ev.preventDefault();
          cancelZoom(ev);
        });
    };
  }



  markSelectedLocations(lo) {
    const locationArray = this.report.locations.find((element) => element.id === lo.id && lo.locationTypeCodeId < 5);
    const employeeArray = this.report.employees.find((element) => element.id === lo.id && lo.locationTypeCodeId === 5);
    if (locationArray) {
      lo.selected = true;
    } else if (employeeArray) {
      lo.selected = true;
    } else {
      lo.selected = false;
    }
    if (typeof lo.children !== 'undefined') {
      lo.children.forEach((c) => {
        c = this.markSelectedLocations(c);
      });
    } else {
      lo.children = [];
    }
    return lo;
  }

  preEditPostEditFunction() {
    this.trimFullTree();
  }

  trimBottomOfTreeDistrict(l: Location) {
    (l.locationTypeCodeId < 3) ?
      l.children.forEach((c) => { c = this.trimBottomOfTreeDistrict(c); })
      : l.children = [];
    return l;
  }

  trimBottomOfTree(l: Location) {
    (l.locationTypeCodeId < this.report.locationTierFocusId) ?
      l.children.forEach((c) => { c = this.trimBottomOfTree(c); })
      : l.children = [];
    return l;
  }

  trimTopOfTree(l: Location, array: Location) {
    if (array.id === l.id && l.locationTypeCodeId !== 5) {
      this.locationTreeToView = l;
    } else {
      l.children.forEach((child) => this.trimTopOfTree(child, array));
    }

  }

  trimFullTree(topLocation?) {
    this.locationTreeToView = this.cloneLocationTree(this.locationsForSelectTree[0]);
    this.trimBottomOfTreeDistrict(this.locationTreeToView);
    if (topLocation) {
      this.trimTopOfTree(this.locationTreeToView, topLocation);
    } else if (this.presentUser) {
      const response: Location = this.findParent(this.presentUser.location);
      this.trimTopOfTree(this.locationTreeToView, response);
    }
  }

  cloneLocationTree(obj) {
    if (obj === null || typeof (obj) !== 'object') {
      return obj;
    }
    const temp = new obj.constructor();
    for (const key in obj) {
      if (true) {
        temp[key] = this.cloneLocationTree(obj[key]);
      }
    }
    return temp;
  }

  // #endregion LocationTree Functions

  // #region Get Subscribes


  getUploadStatus() {
    this._uploadStatusService.getUploadStatus(this.selectedClientId)
      .subscribe((response: UploadStatus) => {
        this.uploadStatus = response.sheetName;
      }, (error) => this._notifier.notify('error', error.error), () => { });
  }

  getClients() {
    this._clientService.getAllClients()
      .subscribe((response: Client[]) => {
        this.clients = response;
      }, (error) => this._notifier.notify('error', error.error), () => { });
  }

  getCommissionUsers() {
    this._commissionUser.getAllCommissionUsers(this.selectedClientId, this.selectedDuration)
      .subscribe((response: CommissionUser[]) => {
        this.commissionUsers = response;
      }, (error) => this._notifier.notify('error', error.message), () => { });
  }

  getLocations() {
    this._locationService.getAllLocations()
      .subscribe((response: Location[]) => {
        this.locations = response;
      }, (error) => this._notifier.notify('error', error.error), () => { });
  }

  getEmployees() {
    this._employeeService.getAllEmployees()
      .subscribe((response: Employee[]) => {
        this.employees = response;

        this.presentUser = response.find
          (emp => emp.userId.toString() === this.userId.toString());
        this.getReportsFirstLoad();
        this.getCommissionUsers();
      }, (error) => this._notifier.notify('error', error.message), () => { });
  }

  getAllFormulas() {
    this._formulaService.getAllFormulas().subscribe((response) => {
      this.formulas = response;
    });
  }

  getFormulas(forceLoading: boolean = true) {
    this.loadingInProgress = true;
    this._formulaService.getFormulas(this.selectedClientId, (response) => {
      this.loadingInProgress = false;
      this.formulas = response;
    }, forceLoading);
  }

  getSkuGroups() {
    this._skuGroupService.getSkuGroups()
      .subscribe((response: SkuGroup[]) => {
        this.skuGroups = response;
      }, (error) => this._notifier.notify('error', error.message), () => { });
  }

  getLocationFullTree() {
    const tierId = 5;
    this._locationService.getLocationTree(this._authService.clientId(), tierId)
      .subscribe((response: Location[]) => {
        this.locationsForSelectTree = response;
      }, (error) => this._notifier.notify('error', error.error), () => { });
  }

  getFormulaViewGroups() {
    this._formulaViewGroupService.getFormulaViewGroups(this.selectedClientId, (response) => {
      this.formulaViewGroups = response;
    });
  }

  //#endregion Get Subscribe

  // #region CouldBeEnums

  locationTypeCodeName(num) {
    if (num === 5) {
      return 'Employees';
    }
    if (num === 4) {
      return 'Stores';
    }
    if (num === 3) {
      return 'Districts';
    }
    if (num === 2) {
      return 'Regions';
    }
  }

  getDateTypeString(dateType) {
    if (dateType === 1) {
      return 'MTD';
    }
    if (dateType === 5) {
      return 'LastMonth';
    }
  }

  // #endregion CouldBeEnums

}
