<button mat-icon-button class="close-button" (click)="onDismiss()">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h1 class="heading-text" mat-dialog-title>{{title}}</h1>
<form #f="ngForm" class="client-form">
  <div class="d-flex">
    <div class="custom-input-box create-form-group-width-set">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" [(ngModel)]="client.name" #name="ngModel" name="name" required />
        <mat-error class="mt-1" *ngIf="name.invalid">Client name is required.</mat-error>
      </mat-form-field>
    </div>
    <div class="custom-input-box create-form-group-width-set ml-3">
      <mat-form-field>
        <mat-label>Client owner name</mat-label>
        <input matInput type="text" [(ngModel)]="client.clientOwnerName" #clientOwnerName="ngModel" name="clientOwnerName" required />
        <mat-error class="mt-1" *ngIf="clientOwnerName.invalid">Client owner name is required.</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="d-flex mt-4">
    <div class="custom-input-box create-form-group-width-set">
      <mat-form-field>
        <mat-label>Contact number</mat-label>
        <input matInput type="text" [(ngModel)]="client.contactNumber" #contactNumber="ngModel" name="contactNumber" required />
        <mat-error class="mt-1" *ngIf="contactNumber.invalid">Contact number is required.</mat-error>
      </mat-form-field>
    </div>
    <div class="custom-input-box create-form-group-width-set ml-3">
      <mat-form-field>
        <mat-label>Contact email</mat-label>
        <input matInput type="email" [(ngModel)]="client.contactEmail" #contactEmail="ngModel" name="contactEmail" required />
        <mat-error class="mt-1" *ngIf="contactEmail.invalid">Contact email is required.</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="d-flex mt-4">
    <div class="custom-input-box create-form-group-width-set">
      <mat-form-field>
        <mat-label>Upload type</mat-label>
        <mat-select [(ngModel)]="client.configUploadProfileTypeId" #configUploadProfileTypeId="ngModel" name="configUploadProfileTypeId" required>
          <mat-option *ngFor="let profile of uploadProfiles" [value]="profile.id">{{profile.type}}</mat-option>
        </mat-select>
        <mat-error class="mt-1" *ngIf="configUploadProfileTypeId.errors?.required">Upload type is required.</mat-error>
      </mat-form-field>
    </div>
    <div class="custom-input-box create-form-group-width-set ml-3">
      <mat-form-field>
        <mat-label>Client template</mat-label>
        <mat-select [(ngModel)]="templateClientId" #templateClient="ngModel" name="templateClient" required>
          <mat-option *ngFor="let client of clients" [value]="client.id">{{client.name}}</mat-option>
        </mat-select>
        <mat-error class="mt-1" *ngIf="templateClient.errors?.required">Client template is required.</mat-error>
      </mat-form-field>
    </div>
  </div>
</form>

<div class="d-flex align-items-center mt-4 pt-1">
  <button mat-raised-button color="primary" class="submit-btn" (click)="onConfirm()" [disabled]="!client.name || templateClient.invalid || configUploadProfileTypeId.invalid">Submit</button>
  <button mat-button class="clear-btn ml-3" (click)="onDismiss()">Clear</button>
</div>
