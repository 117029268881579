import { Inject, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { ShipmentReconciliationService } from '../../services/shipment-reconciliation.service';
import { DatascapeService } from '../../services/datascape.service';
import { CommissionReconcileService } from '../../services/commission-reconcile.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'notes-dialog',
  templateUrl: 'notes-dialog.component.html',
})
export class NotesDialogComponent implements OnInit {

  noteToAdd: string;
  notes: any;
  displayedColumns: string[] = ['note', 'userName', 'dateTime'];

  constructor(private dialogRef: MatDialogRef<NotesDialogComponent>, private _shipmentReconciliationService: ShipmentReconciliationService, private _datascapeService: DatascapeService, private _notifier: NotifierService,
    private _commissionReconcileService: CommissionReconcileService, private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.notes = this.data.element.notes;
  }

  getFormattedDate(date) {
   return this.datePipe.transform(date, 'dd-MMM-yyyy HH:mm');
  }

  addNote() {
    switch (this.data.pageOrigin) {
      case 'shipment':
        this._shipmentReconciliationService
          .addNote(this.data.selectedClientId, this.data.element['serialNumber'], this.noteToAdd).subscribe(
            (response) => {
              var dialogResponse = { update: true, newNote: response };
              this._notifier.notify('success', "Note added successfully.");
              this.dialogRef.close(dialogResponse);
            }, (error) => this._notifier.notify('error', error.message), () => { });
        break;
      case 'datascape':
        let yearMonth = this.data.monthYear.substring(2, 6) + this.data.monthYear.substring(0, 2);
        this._datascapeService
          .addNote(this.data.element['locationId'], this.data.element['mtn'], yearMonth, this.noteToAdd)
          .subscribe((response) => {
            var dialogResponse = { update: true, id: response };
            this._notifier.notify('success', "Note added successfully.");
            this.dialogRef.close(dialogResponse);
          }, (error) => this._notifier.notify('error', error.message), () => { });
        break;
      case 'ccrs':
        this._commissionReconcileService.addNote(this.data.selectedClientId, this.data.element['imei'], this.noteToAdd)
          .subscribe((response) => {
            var dialogResponse = { update: true, id: response };
            this._notifier.notify('success', "Note added successfully.");
            this.dialogRef.close(dialogResponse);
          }, (error) => this._notifier.notify('error', error.message), () => { });
        break;
      case 'monthly-commision':
        this._commissionReconcileService.addNoteMonthlyCommission(this.data.selectedClientId, this.data.element['id'], this.noteToAdd).subscribe((response: any) => {
          var dialogResponse = { update: true, id: response };
          this._notifier.notify('success', "Note added successfully.");
          this.dialogRef.close(dialogResponse);
        }, (error) => this._notifier.notify('error', error.message), () => { });
        break;
    }
  }
}
