
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UploadStatus } from '../models/upload-status';

@Injectable({
  providedIn: 'root'
})
export class UploadStatusService {
  private _baseUrl = '/api/uploadStatus';

  constructor(private http: HttpClient) {}
  getUploadStatus(clientId: number): Observable<UploadStatus> {
    return this.http.get<UploadStatus>(this._baseUrl + '/' + clientId +'/getSalesUploadTime');
  }
  getUploadLog(clientId:number): Observable<any> {
    return this.http.get<any>(this._baseUrl+'/'+clientId);
  }
}
