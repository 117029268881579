import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Client } from '../models/client';
import { ClientService } from '../services/client.service';
import { TableService } from '../services/table.service';
import { NotifierService } from 'angular-notifier';
import { UploadConfigService } from '../services/upload-config.service';
import { ConfigUploadProfileType } from '../models/config-upload-profile-type';
import { Action } from '../models/action';
import { LocationService } from '../services/location.service';
import { Observable, ReplaySubject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from "@angular/material/table";
import { map } from 'rxjs/operators';
import { ClientCreateDialogComponent } from "./client-create-dialog.component";


@Component({
  selector: 'app-clients',
  templateUrl: './client.component.html'
})

export class ClientComponent implements OnInit, OnDestroy {
  clients: Client[] = [];
  uploadProfiles: ConfigUploadProfileType[] = [];
  loadingInProgress: boolean;
  client: Client;
  table: any = {
    sorting: true
  };
  templateClientId: number;
  currentAction: Action;
  dialog: any = {
    isOpen: false,
    headerText: '',
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    }
  };

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  clientAttributes: any = [
    { name: 'name', header: 'Name', ascending: true, sorting: true },
    { name: 'isActive', header: 'Active', ascending: true, sorting: true },
    { name: 'clientOwnerName', header: 'Client Owner Name', ascending: true, sorting: true },
    { name: 'contactNumber', header: 'Contact Number', ascending: true, sorting: true },
    { name: 'contactEmail', header: 'Contact Email', ascending: true, sorting: true },
    { name: 'configUploadProfileTypeId', header: 'Upload Type', ascending: true, sorting: true }];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource();
  noData: Observable<boolean>;
  private dialogRef: any;

  constructor(private _clientService: ClientService,
    private _tableService: TableService,
    private _notifier: NotifierService,
    private _uploadConfigService: UploadConfigService,
    private _locationService: LocationService, private matDialog: MatDialog) {
  }
  ngOnInit() {
    this.getClients();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
    this.columnDefinations = null;
    this.dataSource = null;
    this.dialogRef?.close();
  }
  columnDefinations = [
    // { name: "#", isSortable: false, mappedToProperty: "isSelected", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Name", isSortable: true, mappedToProperty: "name", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Active", isSortable: true, mappedToProperty: "isActive", class: "", isFilterable: true, sticky: true, hide: false },
    { name: 'Client Owner Name', isSortable: true, mappedToProperty: "clientOwnerName", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Contact Number", isSortable: true, mappedToProperty: "contactNumber", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Contact Email", isSortable: true, mappedToProperty: "contactEmail", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Upload Type", isSortable: true, mappedToProperty: "configUploadProfileTypeId", class: "", isFilterable: true, sticky: false, hide: false },
  ];

  getDisplayedColumns(): string[] {
    return this.columnDefinations.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
  }
  getColumnDefinationByMappedToProperty(mappedToProperty) {
    var column = this.columnDefinations.find(x => x.mappedToProperty == mappedToProperty);
    return column;
  }
  getTextBydata(element, columnDef) {
    var textToShow = '';
    switch (columnDef.name) {
      default:
        textToShow = element[columnDef.mappedToProperty];
    }
    return textToShow;
  }
  getClients() {
    this.loadingInProgress = true;
    this._clientService.getAllClients().subscribe((response) => {
      this._uploadConfigService.getUploadProfiles().subscribe((response2) => {
        this.loadingInProgress = false;
        this.uploadProfiles = response2;

      });
      // this.loadingInProgress = false;
      this.clients = response;
      this.dataSource = new MatTableDataSource(response);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.noData = this.dataSource.connect().pipe(map(data => data.length === 0));
    });
  }

  addClient() {
    this.client = {
      name: null, isActive: true, clientOwnerName: null, contactNumber: null, contactEmail: null, configUploadProfileTypeId: null
    };
    this.dialog.isOpen = true;
    this.dialog.headerText = 'Add Client';
    this.currentAction = Action.Create;
    this.dialogRef = this.matDialog.open(ClientCreateDialogComponent, {
      autoFocus: true, disableClose: false, panelClass: '', data: { title: 'Add Client', client: this.client, clients: this.clients, uploadProfiles: this.uploadProfiles, }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result !== false) {
        this.client = result.client;
        this.templateClientId = result.templateClientId; this.saveClient();
      }
    });
  }

  convertProfileIdToName(id: number) {
    return this.uploadProfiles.find(x => x.id === id).type;
  }

  saveClient() {
    this._clientService.createClient(this.client.name,
      this.client.clientOwnerName, this.client.isActive, this.client.contactNumber.toString(),
      this.client.contactEmail, this.client.configUploadProfileTypeId, this.templateClientId)
      .subscribe((response: boolean) => {
        this.dialog.isOpen = false;
        this.getClients();
        this._notifier.notify('success', 'Client created successfully.');
      }, (error) => this._notifier.notify('error', error.error), () => { });
  }

  sorting(field, order) {
    if (!this.table.sorting) {
      return;
    }

    this.clients = this._tableService.sorting(this.clients, field, order);
    this.clientAttributes.filter(col => col.name === field)[0].ascending = !order;
  }

  lastFilter: any;
  searchTerm = '';
  applyFilter(filterValue: string) {
    this.lastFilter = filterValue;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
