
<div class="row">
  <div class="col-3">
    <button color="primary" class="primary-action-btm" mat-raised-button type="button" (click)="addSkuGroup()">Add Sku Group</button>
  </div>
  <div class="col-9">
    <div class="card-container d-flex align-items-center justify-content-between py-2 px-4">
      <p class="m-0 text-medium fw-600 "> <span *ngIf="selectedSkuGroup">{{selectedSkuGroup.name}}'s </span>Sku Filters</p>
      <button color="primary" class="primary-action-btm" mat-raised-button type="button" (click)="addSkuFilter()">Add Sku Filter</button>
    </div>
  </div>
</div>

<div class="row mt-2">
  <div class="col-3">
    <div class="card-container scroll-table">
      <table class=" w-100 font-size-14">
        <ng-container>
          <tr class="mat-row border">
            <th class="mat-header-cell ">No. </th>
            <th class="mat-header-cell "> Name </th>
          </tr>
        </ng-container>
        <ng-container *ngFor="let skuGroup of skuGroups;let index = index;">
          <tr (click)="selectSkuGroup(skuGroup.id)" class="mat-row border">
            <td class="mat-cell " [ngClass]="{'selected-table-row yellow' : selectedSkuGroup?.id == skuGroup.id}"> <span class="name">{{ index+1 }}</span></td>
            <td class="mat-cell " [ngClass]="{'selected-table-row yellow' : selectedSkuGroup?.id == skuGroup.id}" style="overflow:inherit;">
              <p class="m-0 ">
                <span class="name">{{ skuGroup.name }}</span>
                <span class="counter">{{ skuGroup.skuFilters.length }}</span>
                <span *ngIf="skuGroup.isRequired" class="required">Required*</span>
              </p>
            </td>
            <td class="mat-cell " [ngClass]="{'selected-table-row yellow' : selectedSkuGroup?.id == skuGroup.id}">
              <a mat-icon-button color="primary" type="button" (click)="deleteSkuGroup(skuGroup)" class="icon-btn" style="float:right;"><i class="fa fa-trash-alt"></i></a>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>
  <div class="col-9">
    <div class="table-container">
      <table *ngIf="selectedColumns.length>0 " [dataSource]="dataSource" mat-table matSort class="table table-bordered table-hover small-txt">
        <ng-container *ngFor="let column of getDisplayedColumns(); let colIndex = index;" [matColumnDef]="column">
          <th [hidden]="getColumnDefinationByMappedToProperty(column).hide" class="sort-column mat-sort-header-button-custom mat-header-cell" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!getColumnDefinationByMappedToProperty(column).isSortable">
            {{getColumnDefinationByMappedToProperty(column).name}}
          </th>
          <ng-container *matCellDef="let element; let i = index">
            <td [hidden]="getColumnDefinationByMappedToProperty(column).hide" mat-cell class="mat-cell">
              <span *ngIf="column == 'index'">{{getTextBydata(element,getColumnDefinationByMappedToProperty(column),i+1)}}</span>
              <span *ngIf="column == 'name'">{{getTextBydata(element,getColumnDefinationByMappedToProperty(column),i+1)}}</span>
              <span *ngIf="column === 'skuFilterType.id'">
                <span *ngIf="getTextBydata(element,getColumnDefinationByMappedToProperty(column), i+1)=== 1">
                  <strong>Product Catalog:</strong>{{getProductCatalogName(element.value)}}
                </span>
                <span *ngIf="getTextBydata(element,getColumnDefinationByMappedToProperty(column), i+1)=== 2">
                  <strong>Category:</strong>{{getCategoryName(element.value)}}
                </span>
              </span>
              <span *ngIf="column == 'skuFilterType.name'">{{getTextBydata(element,getColumnDefinationByMappedToProperty(column),i+1)}}</span>
              <span *ngIf="column == 'actions'">
                <a color="primary" type="button" (click)="editSkuFilter(element)" class="icon-btn"><i class="fa fa-edit"></i></a>
                <a color="primary" type="button" (click)="deleteSkuFilter(element)" class="icon-btn"><i class="fa fa-trash-alt"></i></a>
              </span>
            </td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
        <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();">
        </tr>
      </table>
      <mat-paginator style="margin-left: 5px;" #ccrsTablePaginator="matPaginator" [pageSizeOptions]="[5, 10, 20, 50]" [pageSize]="10" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>
