<div>
  <div class="font-250-percent"> Welcome<span style="font-weight: bold"> {{loggedInUserName}}, {{roles}}</span></div>
  <!--<br />
  <span>Current Role: {{roles}}</span>-->

  <div class="font-125-percent">
    <mat-icon>info</mat-icon>
    <span style="vertical-align: super;">Please check the default Region/District/Store on the top right corner. You can update them if needed.</span>
  </div>
</div>

<mat-grid-list cols="2" rowHeight="2:1" style="margin-right: 15%; margin-left: 15%; margin-bottom: auto;margin-top: auto" gutterSize="1em">
  <mat-grid-tile class="mat-elevation-z8">
    <mat-grid-tile-header>Employee</mat-grid-tile-header>
    <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/performance-summary']"
       *ngIf="_authService.hasEmployeesMenuPermission() && _authService.hasPerformanceSummaryPermission()">
      <mat-icon class="thumbnail material-icons-round">portrait</mat-icon><br />Performance Summary
    </a>
    <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/forecast']"
       *ngIf="_authService.hasEmployeeCommissionsSpiffPermission()">
      <mat-icon class="thumbnail material-icons-round">attach_money</mat-icon><br />Employee Commissions
    </a>
    <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/rewards-dashboard']"
       *ngIf="_authService.hasRewardDashboardPermission()">
      <mat-icon class="thumbnail material-icons-round">videogame_asset</mat-icon><br />Rewards Dashboard
    </a>
  </mat-grid-tile>
  <mat-grid-tile class="mat-elevation-z8">
    <mat-grid-tile-header>Reconciliation</mat-grid-tile-header>
    <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/ccrs-reconcile']"
       *ngIf="_authService.hasCommissionMenuPermission() && _authService.hasDailyCommissionReconcilePermission()">
      <mat-icon class="thumbnail material-icons-round">assignment_turned_in</mat-icon><br />Daily Commissions
    </a>
    <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/datascape-reconcile']" *ngIf="_authService.hasDatascapeReconcilePermission()">
      <mat-icon class="thumbnail material-icons-round">monetization_on</mat-icon><br />Bill Payments
    </a>
    <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/shipment-reconciliation']">
      <mat-icon class="thumbnail material-icons-round">smartphone</mat-icon><br />Devices
    </a>
    <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/rewards-claim-management']" *ngIf="_authService.hasRewardReconcilePermission()">
      <mat-icon class="thumbnail material-icons-round">redeem</mat-icon><br />Reward Claims
    </a>
  </mat-grid-tile>
  <mat-grid-tile class="mat-elevation-z8">
    <mat-grid-tile-header>Administration</mat-grid-tile-header>
    <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/import']"
       *ngIf="(_authService.isSuperAdmin() || _authService.hasAdminMenuPermission())&& _authService.hasImportPermission()">
      <mat-icon class="thumbnail material-icons-round">import_export</mat-icon><br />Import
    </a>
    <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/formulas']" *ngIf="_authService.hasFormulasPermission()">
      <mat-icon class="thumbnail material-icons-round">functions</mat-icon><br />Formulas
    </a>
    <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/rewards-create']" *ngIf="_authService.hasRewardGameCreatePermission()">
      <mat-icon class="thumbnail material-icons-round">gamepad</mat-icon>
      <br />Create Game
    </a>
  </mat-grid-tile>
  <mat-grid-tile class="mat-elevation-z8">
    <mat-grid-tile-header>Analytics</mat-grid-tile-header>
    <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/dashboard']" *ngIf="_authService.hasDashboardPermission()">
      <mat-icon class="thumbnail material-icons-round">dashboard</mat-icon><br />Dashboard
    </a>
    <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/rank-contest']" *ngIf="_authService.hasRankPermission()">
      <mat-icon class="thumbnail material-icons-round">star</mat-icon><br />Rank
    </a>
    <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/mtd-report']"
       *ngIf="_authService.hasSalesMenuPermission() && _authService.hasSalesMTDPermission()">
      <mat-icon class="thumbnail material-icons-round">toc</mat-icon><br />Month To Date (MTD)
    </a>
  </mat-grid-tile>
</mat-grid-list>

<style>
  .mat-grid-tile-header {
    background-color: #3f51b5 !important;
  }

  .mat-list-item {
    padding: 1em;
  }

  .thumbnail {
    font-size: 500%;
    height: auto;
    width: auto;
  }

  .mat-grid-tile {
    text-align: center;
    /*padding: 1em;*/
    border: none;
  }

  .font-125-percent {
    font-size: 125%
  }

  .font-250-percent {
    font-size: 250%
  }
</style>
