<div class="d-flex align-items-center justify-content-between action-header ">
  <div class="d-flex align-items-center ">
    <mat-form-field class="bottom-border width-set">
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" [(ngModel)]="searchTerm">
    </mat-form-field>
    <div class="standard-margin-left"></div>
  </div>
  <button mat-raised-button (click)="addClient()" class="primary-action-btm" color="primary">Add Client</button>
</div>

<div class="table-container mt-4">
  <div class="table table-responsive">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="symbol" matSortDirection="asc" class="table table-stripped table-hover small-txt">
      <ng-container matColumnDef="{{column}}" *ngFor="let column of getDisplayedColumns(); let i = index;">
        <th class="sort-column mat-sort-header-button-custom mat-header-cell" mat-header-cell *matHeaderCellDef mat-sort-header [matTooltip]="getColumnDefinationByMappedToProperty(column).name">{{getColumnDefinationByMappedToProperty(column).name}}</th>
        <ng-container>
          <td mat-cell *matCellDef="let element" class="mat-cell" [matTooltip]="element[getColumnDefinationByMappedToProperty(column).mappedToProperty]">{{getTextBydata(element,getColumnDefinationByMappedToProperty(column))}}</td>
        </ng-container>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
      <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
    </table>
    <div *ngIf="noData | async" class="no-records">No records found</div>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="10" showFirstLastButtons></mat-paginator>
  </div>
</div>
