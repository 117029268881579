import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList } from '@angular/core';
import * as moment from 'moment';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../../core/services/auth.service';
import { LocationService } from '../services/location.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DurationService } from '../services/duration.service';
import { Duration } from '../models/duration';
import { RewardsCreateSearchRequest } from '../models/rewards-create-search-request';
import { DateRange } from '../models/date-range';
import { MatSelectChange } from '@angular/material/select';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { LoaderService } from '../services/loader.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { RewardGameViewModel } from '../models/reward-game';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { EmployeeService } from '../services/employee.service';
import { Employee } from '../models/employee';
import { Location } from '../models/location';
import { FormulaService } from '../services/formula.service';
import { Formula } from '../models/formula';
import { RewardGameType } from '../models/reward-game-type';
import { RewardGameStatus } from '../models/reward-status';
import { isString } from '@ng-bootstrap/ng-bootstrap/util/util';
import { Status } from '../users/models/status';
import { RewardService } from '../services/reward-service';
import { forEach, object } from 'underscore';
//import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { Location as loc } from '@angular/common';
import { RewardGameDetailsViewModel } from '../models/reward-game-details';
import { Title } from '@angular/platform-browser';

export class Filters {
  value: number;
  viewValue: string;
}

export class EmployeeRewardListItem {
  employeeId: number;
  rewarded: number;
}



@Component({
  selector: 'app-rewards-dashboard',
  templateUrl: './rewards-dashboard.component.html'
})

export class RewardsDashboardComponent implements OnInit, OnDestroy {

  constructor(private _notifier: NotifierService,
    private _authService: AuthService,
    private _rewardService: RewardService,
    private _locationService: LocationService,
    private _employeeService: EmployeeService,
    private _formulaService: FormulaService,
    private _durationService: DurationService,
    private _loaderService: LoaderService,
    private observer: BreakpointObserver,
    private titleService: Title,//private _bcService: NgDynamicBreadcrumbService,
    private _loc: loc

  ) {

    this.observer.observe('(max-width: 1025px)').subscribe((result) => {
      //console.log('max', result);
      if (result.matches) {
        this.columnDefinations = [
          { name: "Name", isSortable: false, mappedToProperty: "name", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Type", isSortable: false, mappedToProperty: "type", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Summary", isSortable: false, mappedToProperty: "summary", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Description", isSortable: false, mappedToProperty: "description", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Stores", isSortable: false, mappedToProperty: "locationList", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Status", isSortable: false, mappedToProperty: "status", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Metric", isSortable: false, mappedToProperty: "formula", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "StartDate", isSortable: false, mappedToProperty: "startDate", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "EndDate", isSortable: false, mappedToProperty: "endDate", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Reward", isSortable: false, mappedToProperty: "reward", class: "", isFilterable: true, sticky: true, hide: false }
        ];
        this.selectedColumns = this.columnDefinations.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
      }
    });
  }
  updatePageTitle(title) {
    this.titleService.setTitle(title);
  }

  columnDefinations = [
    { name: "Name", isSortable: true, mappedToProperty: "name", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Type", isSortable: true, mappedToProperty: "type", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Summary", isSortable: false, mappedToProperty: "summary", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Description", isSortable: false, mappedToProperty: "description", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Stores", isSortable: false, mappedToProperty: "locationList", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Status", isSortable: true, mappedToProperty: "status", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Metric", isSortable: true, mappedToProperty: "formula", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "StartDate", isSortable: true, mappedToProperty: "startDate", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "EndDate", isSortable: true, mappedToProperty: "endDate", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Reward", isSortable: true, mappedToProperty: "reward", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "TotalRewards", isSortable: true, mappedToProperty: "totalRewards", class: "", isFilterable: true, sticky: true, hide: false },
  ];
  selectedColumns = this.getDisplayedColumns();

  getDisplayedColumns(): string[] {
    return this.columnDefinations.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
  }

  getColumnDefinationByMappedToProperty(mappedToProperty) {
    var column = this.columnDefinations.find(x => x.mappedToProperty == mappedToProperty);

    return column;
  }

  getTextBydata(element, columnDef, isTooltip) {
    var textToShow = '';
    if (!this.tableready) return "";

    switch (columnDef.name) {
      case 'Name':
        textToShow = element.rewardGame[columnDef.mappedToProperty];
        break;
      case 'Type':
        textToShow = RewardGameType[element.rewardGame.type];
        break;
      case 'Summary':
        textToShow = this.getRewardGameSummary(element.rewardGame);
        break;
      case 'Description':
        textToShow = element.rewardGame[columnDef.mappedToProperty];
        break;
      case 'Stores':
        if (element.rewardGame.managerLocationId) {
          textToShow = this.locations.find(x => x.id == element.rewardGame.managerLocationId).name;
        } else {


          if (element.rewardGame.locationList.length == 1) {
            textToShow = this.locations.find(x => x.id == element.rewardGame.locationList[0].locationId).name;
          } else if (element.rewardGame.isEveryStore) {
            textToShow = "All Stores";
          }
          else {
            if (isTooltip) {
              element.rewardGame.locationList.forEach((location) => {
                textToShow += this.locations.find(x => x.id == location.locationId).name + ' \n ';
              });

            } else {
              textToShow = element.rewardGame.locationList.length + " Stores";
            }
          }
        }
        break;
      case 'Status':
        textToShow = RewardGameStatus[element.rewardGame.status];
        break;
      case 'Sales Person':
        textToShow = element.employeeList.length;
        break;
      case 'Metric':
        textToShow = element.rewardGame[columnDef.mappedToProperty].name;
        break;
      case 'Reward':
        let es = element.employeeScores.find(x => x.employeeId == this.selectedEmployeeId);
        if (es) {
          textToShow = "$" + es.moneyEarned;
        }
        break;
      case 'TotalRewards':
        textToShow = "$" + element.employeeScores.map(x => x.moneyEarned).reduce((a, b) => a + b, 0);
        break;
      case 'StartDate':
      case 'EndDate':
        textToShow = moment(element.rewardGame[columnDef.mappedToProperty]).format(this._durationService.format1);
        break;
      default:
        textToShow = element[columnDef.mappedToProperty];
    }

    return textToShow;
  }

  columnDefinationsGameDetails = [
    { name: "Place", isSortable: false, mappedToProperty: "place", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Name", isSortable: false, mappedToProperty: "name", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Score", isSortable: false, mappedToProperty: "score", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Reward", isSortable: false, mappedToProperty: "moneyEarned", class: "", isFilterable: true, sticky: true, hide: false }
  ];
  selectedColumnsGameDetails = this.getDisplayedColumnsGameDetails();

  getDisplayedColumnsGameDetails(): string[] {
    return this.columnDefinationsGameDetails.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
  }
  getColumnDefinationByMappedToPropertyGameDetails(mappedToProperty) {
    var column = this.columnDefinationsGameDetails.find(x => x.mappedToProperty == mappedToProperty);
    return column;
  }

  getTextByDataGameDetails(element, columnDef) {
    var textToShow = '';
    switch (columnDef.name) {
      case 'Name':
        textToShow = this.employees.find(x => x.id == element.employeeId).user.name;
        break;
      case 'Reward':
        textToShow = "$" + element.moneyEarned;
        break;
      case 'Score':
        if (this.selectedRewardGame.formula.isPercentage) {
          textToShow = (element.score * 100).toFixed(2) + "%";
        } else {
          textToShow = (element.score).toFixed(2);
        }
        break;
      default:
        textToShow = element[columnDef.mappedToProperty];
    }

    return textToShow;
  }



  columnDefinationsTopSalesDetails = [
    { name: "Name", isSortable: false, mappedToProperty: "name", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Type", isSortable: false, mappedToProperty: "type", class: "", isFilterable: true, sticky: true, hide: true },
    { name: "Summary", isSortable: false, mappedToProperty: "summary", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Status", isSortable: false, mappedToProperty: "status", class: "", isFilterable: true, sticky: true, hide: true },
    { name: "Metric", isSortable: false, mappedToProperty: "formula", class: "", isFilterable: true, sticky: true, hide: true },
    { name: "StartDate", isSortable: false, mappedToProperty: "startDate", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "EndDate", isSortable: false, mappedToProperty: "endDate", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Reward", isSortable: false, mappedToProperty: "reward", class: "", isFilterable: true, sticky: true, hide: false }
  ];

  selectedColumnsTopSalesDetails = this.getDisplayedColumnsTopSalesDetails();

  getDisplayedColumnsTopSalesDetails(): string[] {
    return this.columnDefinationsTopSalesDetails.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
  }

  getColumnDefinationByMappedToPropertyTopSalesDetails(mappedToProperty) {
    var column = this.columnDefinationsTopSalesDetails.find(x => x.mappedToProperty == mappedToProperty);
    return column;
  }



  getTextByDataTopSalesDetails(element, columnDef) {
    var textToShow = '';
    if (!this.tableready) return "";

    switch (columnDef.name) {
      case 'Name':
        textToShow = element.rewardGame[columnDef.mappedToProperty];
        break;
      case 'Type':
        textToShow = RewardGameType[element.rewardGame.type];
        break;
      case 'Summary':
        textToShow = this.getRewardGameSummary(element.rewardGame);
        break;
      case 'Status':
        textToShow = RewardGameStatus[element.rewardGame.status];
        break;
      case 'Sales Person':
        textToShow = element.employeeList.length;
        break;
      case 'Metric':
        textToShow = element.rewardGame[columnDef.mappedToProperty].name;
        break;
      case 'Reward':
        let es = element.employeeScores.find(x => x.employeeId == this.selectedTopSalesId);
        if (es) {
          textToShow = "$" + es.moneyEarned;
        }
        break;
      case 'StartDate':
      case 'EndDate':
        textToShow = moment(element.rewardGame[columnDef.mappedToProperty]).format(this._durationService.format1);
        break;
      default:
        textToShow = element[columnDef.mappedToProperty];
    }

    return textToShow;
  }

  columnsChangedTopSalesDetails(event: MatSelectChange) {
    for (var i = 0; i < this.columnDefinationsTopSalesDetails.length; i++) {
      var column = this.selectedColumnsTopSalesDetails.find(x => x == this.columnDefinationsTopSalesDetails[i].mappedToProperty);
      if (column != null) {
        this.columnDefinationsTopSalesDetails[i].hide = false;
      } else {
        this.columnDefinationsTopSalesDetails[i].hide = true;
      }
    }
  }

  showingDashboard = true;
  showingSalesPeople = false;
  showingGameDetails = false;
  setBreadcrumb(selection: number) {
    this.selectedRewardGameId = 0;
    this.selectedRewardGame = null;
    this.selectedTopSalesId = 0;
    if (selection == 1) {
      this.showingSalesPeople = true;
      this.showingGameDetails = false;
      this.showingDashboard = false;
      this.updatePageTitle('Sales Person Dashboard');
    }else if (selection == 2) {
      this.showingSalesPeople = false;
      this.showingGameDetails = true;
      this.showingDashboard = false;
      this.updatePageTitle('Reward Game Details');
    }else if (selection == 0) {
      this.showingDashboard = true;
      this.showingSalesPeople = false;
      this.showingGameDetails = false;
      this.updatePageTitle('Rewards Dashboard');
    }
  }

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  selectedFilter: number = 0;
  createTemplate = { startDate: new Date(), endDate: new Date(), monthlyDate: moment().format('YYYY-MM') };
  startDate: Date;
  endDate: Date;
  minDate: Date;
  maxDate: Date;
  months: any;
  durations: Duration[];
  subscriptionToLocations: Subscription;
  selectedRegionId: number;
  selectedRegion: any;
  districts: any[];
  selectedDistrictId: number;
  selectedDistrict: any;
  stores: any[];
  selectedStoreId: number;
  selectedStore: any;
  selectedEmployeeId: number;
  selectedEmployee: any;
  selectedClientId: number;
  clientLocationId: number;
  filteredLocations: Location[] = [];
  locations: Location[];
  formulas: Formula[];
  employees: Employee[];
  private debug = true;
  monthlyDuration: string;
  selectedDuration: Duration;
  createdByList: string[];
  rewardSearchRequest = new RewardsCreateSearchRequest();



  rewardGameList: RewardGameDetailsViewModel[];
  pastRewardGameList: RewardGameDetailsViewModel[];
  ngOnInit() {
    this._loc.onUrlChange((newUrl) => {
      if (newUrl.includes("People")) {
        this.showingDashboard = false;
        this.showingGameDetails = false;
        this.showingSalesPeople = true;
      } else if (newUrl.includes("Game")) {
        this.showingDashboard = false;
        this.showingSalesPeople = false;
        this.showingGameDetails = true;
      } else {
        this.setBreadcrumb(0);
        this.showingDashboard = true;
        this.showingSalesPeople = false;
        this.showingGameDetails = false;
      }
    })

    this.selectedFilter;
    this.durations = this._durationService.getDurationExtra();
    this.selectedDuration = this.durations.find(x => x.value === 0);
    this.months = this._durationService.getMonths();
    this.selectedClientId = this._authService.clientId();


    this.subscriptionToLocations = this._locationService.currentLocations
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        if (message != null && typeof message[5] !== 'undefined') {
          this.selectedClientId = message[1];
          this.selectedRegionId = message[2];
          this.selectedDistrictId = message[3];
          this.selectedStoreId = message[4];
          this.selectedEmployeeId = message[5];
          this.onClientSelected();

        }
      });


  }
  dataSourceSalesPeople = new MatTableDataSource();
  dataSourceGameDetails = new MatTableDataSource();
  selectedRewardGameId = 0;
  selectedRewardGame;
  selectedTopSalesId = 0;
  selectRewardGame(element) {
    this.setBreadcrumb(2);
    this.selectedRewardGameId = element.rewardGame.id;
    this.selectedRewardGame = element.rewardGame;

    setTimeout(x => {
        this.dataSourceGameDetails = new MatTableDataSource(element.employeeScores);
      },
      200);
  }



  columnsChanged(event: MatSelectChange) {
    for (var i = 0; i < this.columnDefinations.length; i++) {
      var column = this.selectedColumns.find(x => x == this.columnDefinations[i].mappedToProperty);
      if (column != null) {
        this.columnDefinations[i].hide = false;
      } else {
        this.columnDefinations[i].hide = true;
      }
    }
  }

  filterByProperty(collection, value, key) {
    return collection.filter(o => key.reduce((c, v) => c[v] || {}, o) === value);
  }

  topSalesList: EmployeeRewardListItem[] = new Array(0);

  async getRewards() {
    this.tableready = false;
    this._loaderService.show();

    this._rewardService.getRewardGamesForEmployee(this.employees.find(x => x.id == this.selectedEmployeeId).userId, 0)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async (message) => {
        if (message) {
          this.rewardGameList = message;
          await this.applySelectedFilter();

          this._rewardService.getRewardGamesForEmployee(this.employees.find(x => x.id == this.selectedEmployeeId).userId, 60)
            .pipe(takeUntil(this.destroyed$))
            .subscribe(async (message) => {
              if (message) {
                this.topSalesList = new Array(0);
                this.pastRewardGameList = message;
                for (let i = 0; i < this.pastRewardGameList.length; i++) {
                  for (let e = 0; e < this.pastRewardGameList[i].rewardGame.rewardMoneyForEmployees.length; e++) {
                    let thisEmployee = this.topSalesList.find(x => x.employeeId == this.pastRewardGameList[i].rewardGame.rewardMoneyForEmployees[e].employeeId);
                    if (!thisEmployee) {
                      thisEmployee = new EmployeeRewardListItem();
                      thisEmployee.employeeId = this.pastRewardGameList[i].rewardGame.rewardMoneyForEmployees[e].employeeId;
                      thisEmployee.rewarded = this.pastRewardGameList[i].rewardGame.rewardMoneyForEmployees[e].money;
                      this.topSalesList.push(thisEmployee);
                    } else {
                      thisEmployee.rewarded += this.pastRewardGameList[i].rewardGame.rewardMoneyForEmployees[e].money;

                    }
                  }
                }
                this.topSalesList.sort((a, b) => {
                  return b.rewarded - a.rewarded;
                })
                this.dataSourceSalesPeople = new MatTableDataSource(this.topSalesList);
                this.dataSourceSalesPeople.paginator = this.paginator.toArray()[1];
                this.tableready = true;
                this._loaderService.hide();
              }
            });
        }
      });


  }

  getFormulas() {
    this._formulaService.getFormulas(this.selectedClientId, (response) => {
      this.formulas = response.filter(x => x.containsComplexFormula === false).sort((a, b) => a.name.localeCompare(b.name));
    }, true);
  }

  onClientSelected() {
    this._employeeService.getAllEmployees().subscribe((response) => {
      this.employees = response.filter(x => x.location.clientId == this.selectedClientId);
      this.getFormulas();
      this._locationService.getLocationsByClientId(this.selectedClientId, (response) => {
        this.locations = response.filter(x => x.locationTypeCodeId == 4);
        this.getRewards();
      });
    });
  }





  ngOnDestroy() {
    this.subscriptionToLocations.unsubscribe();
    this.columnDefinations = null;
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
  }




  async filterChanged(event: MatSelectChange) {
    this.selectedFilter = event.value;
    this.applySelectedFilter();
  }
  openFilterMenu() {
    this.filterMenu.isOpen = true;

  }
  pageEvent = { pageSize: 10, pageIndex: 0 }
  filterMenu: any = {
    isOpen: false,
    cancelButton: true,
    isCurrentFilter: true,
    type: -1,
    formula: -1,
    duration: -1,
    createdBy: 'Everyone',
    cancel: function () {
      this.isOpen = false;
    }
  };
  async applySelectedFilter() {
    if (this.filterMenu.isCurrentFilter) {
      this.data = this.rewardGameList;
    } else {
      this.data = this.pastRewardGameList;
    }

    if (this.filterMenu.type != -1) {
      this.data = this.data.filter(x => x.rewardGame.type == this.filterMenu.type);
    }
    if (this.filterMenu.formula != -1) {
      this.data = this.data.filter(x => x.rewardGame.formulaId == this.filterMenu.formula);
    }
    if (this.filterMenu.duration != -1) {
      this.data = this.data.filter(x => (
        (x.rewardGame.startDate <= this.durations[this.filterMenu.duration].duration.FromDate && x.rewardGame.endDate >= this.durations[this.filterMenu.duration].duration.FromDate) ||
        (x.rewardGame.startDate <= this.durations[this.filterMenu.duration].duration.ToDate && x.rewardGame.endDate >= this.durations[this.filterMenu.duration].duration.ToDate) ||
        (x.rewardGame.startDate >= this.durations[this.filterMenu.duration].duration.FromDate && x.rewardGame.endDate <= this.durations[this.filterMenu.duration].duration.ToDate)
      )
      );
    }
    this.updateDataSource(this.data);
  }


  tableready = false;
  dataSource = new MatTableDataSource();
  dataSourceTopSalesDetails = new MatTableDataSource();
  data: any;

  updateDataSource(data) {
    let filteredItems = data;
    if (filteredItems) {
      this.dataSource = new MatTableDataSource(filteredItems);
      this.dataSource.sort = this.sort.toArray()[0];
      this.dataSource.sortingDataAccessor = (item: any, property) => {
        switch (property) {
          case 'formula':
            return item.rewardGame.formula.name;
          case 'name':
            return item.rewardGame.name;
          case 'type':
            return item.rewardGame.type;
          case 'type':
            return item.rewardGame.type;
          case 'startDate':
          case 'endDate':
            return moment(item.rewardGame[property]).format(this._durationService.format1);
          case 'reward':
            let es = item.employeeScores.find(x => x.employeeId == this.selectedEmployeeId);
            if (es) {
              return es.moneyEarned;
            }
          default: return item[property];
        }
      };
      this.dataSource.paginator = this.paginator.toArray()[0];
      this.applyFilter(this.rewardSearchRequest.SearchTerm);
      this.selectRewardGame(filteredItems[0]);
    }
  }

  applyFilter(filterValue: string) {
    if (filterValue) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    } else {
      this.dataSource.filter = "";
    }
  }



  getRewardGameSummary(game) {
    return this._rewardService.getRewardGameSummary(game);
  }




  rewardGame: RewardGameViewModel;
  rewardGameTypes = RewardGameType;
  rewardGameTypesKeys = Object.values(RewardGameType).filter(key => !isNaN(Number(key)));

  managerRewardGameTypesKeys = Object.values(RewardGameType).filter(key => !isNaN(Number(key)) && key !== 0);

  rewardGameStatuses = RewardGameStatus;
  rewardGameStatusesKeys = Object.values(RewardGameStatus).filter(key => !isNaN(Number(key)));



  // SalesPeopleTable

  columnDefinationsSalesPeople = [
    { name: "Place", isSortable: false, mappedToProperty: "place", class: "", isFilterable: false, sticky: true, hide: false },
    { name: "Name", isSortable: false, mappedToProperty: "name", class: "", isFilterable: false, sticky: true, hide: false },
    { name: "TotalRewards", isSortable: false, mappedToProperty: "totalRewards", class: "", isFilterable: false, sticky: true, hide: false }
  ];


  getTextByDataSalesPeople(element, columnDef) {
    var textToShow = '';
    switch (columnDef.name) {
      case 'Name':
        textToShow = this.employees.find(x => x.id == element.employeeId).user.name;
        break;
      case 'TotalRewards':
        textToShow = "$" + element.rewarded;
        break;

      default:
        textToShow = element[columnDef.mappedToProperty];
    }

    return textToShow;
  }

  selectedColumnsSalesPeople = this.getDisplayedColumnsSalesPeople();

  getDisplayedColumnsSalesPeople(): string[] {
    return this.columnDefinationsSalesPeople.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
  }

  getColumnDefinationByMappedToPropertySalesPeople(mappedToProperty) {
    var column = this.columnDefinationsSalesPeople.find(x => x.mappedToProperty == mappedToProperty);
    return column;
  }

  selectSalesPerson(element) {
    this.setBreadcrumb(1);
    this.selectedTopSalesId = element.employeeId;
    if (this.selectedTopSalesId == this.selectedEmployeeId) {
      this.dataSourceTopSalesDetails = new MatTableDataSource(this.pastRewardGameList);

    } else {
      let tempPastGamesList = this.pastRewardGameList.filter(x => x.rewardGame.multipleEmployees && x.rewardGame.employeeList.map(x => x.employeeId).includes(this.selectedTopSalesId));
      this.dataSourceTopSalesDetails = new MatTableDataSource(tempPastGamesList);
    }

  }

}
