import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotifierService } from 'angular-notifier';
import { ConfigUploadProfileType } from '../models/config-upload-profile-type';
import { ConfigSheet } from '../models/config-sheet';
import { ConfigSheetTable } from '../models/config-sheet-table';
import { ConfigTableColumn } from '../models/config-table-column';
import { ConfigFunctionsCalledInTable } from '../models/config-function-sheet';
import { ConfigVariable } from '../models/config-variable';
import { ConfigFunction } from '../models/config-function';
import { ConfigTableColumnVariable } from '../models/config-table-column-variable';

declare const _;

@Injectable({
  providedIn: 'root'
})
export class UploadConfigService {
  private _baseUrl = '/api/uploadconfig';

  constructor(private http: HttpClient,
    private _notifier: NotifierService, ) { }

  getUploadConfigObject(): Observable<any> {
    return this.http.get<any[]>(this._baseUrl + '/' + 1);
  }
  getUploadProfiles(): Observable<any> {
    return this.http.get<any[]>(this._baseUrl + '/' + 1 + '/getUploadList');
  }
  updateProfile(user: ConfigUploadProfileType): Observable<any> {
  return this.http.put(this._baseUrl + '/' + 1 + '/editProfile', user);
  }
  copyProfile(numbir : number): Observable<any> {
    return this.http.get(this._baseUrl + '/' + numbir + '/copyProfile');
  }
  getAllSheets(): Observable<ConfigSheet[]> {
    return this.http.get<ConfigSheet[]>(this._baseUrl + '/getSheets');
  }
  getAllSheetTables(): Observable<ConfigSheetTable[]> {
    return this.http.get<ConfigSheetTable[]>(this._baseUrl + '/getSheetTables');
  }
  getAllTableColumns(): Observable<ConfigTableColumn[]> {
    return this.http.get<ConfigTableColumn[]>(this._baseUrl + '/getTableColumns');
  }
  getAllFunctionsCalledInTable(): Observable<ConfigFunctionsCalledInTable[]> {
    return this.http.get<ConfigFunctionsCalledInTable[]>(this._baseUrl + '/getFunctionsCalledInTable');
  }
  getAllFunctions(): Observable<ConfigFunction[]> {
    return this.http.get<ConfigFunction[]>(this._baseUrl + '/getFunctions');
  }
  getAllVariables(): Observable<ConfigVariable[]> {
    return this.http.get<ConfigVariable[]>(this._baseUrl + '/getVariables');
  }
  getAllTableColumnVariables(): Observable<ConfigTableColumnVariable[]> {
    return this.http.get<ConfigTableColumnVariable[]>(this._baseUrl + '/getTableColumnVariables');
  }
  getAllUploadProfiles(): Observable<ConfigUploadProfileType[]> {
    return this.http.get<ConfigUploadProfileType[]>(this._baseUrl);
  }
}
