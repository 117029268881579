<button mat-icon-button class="close-button" (click)="onDismiss()">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h1 class="heading-text" mat-dialog-title>{{title}}</h1>
<form #f="ngForm" novalidate>
  <div>
    <div class="d-flex">
      <mat-form-field class="custom-input-box create-form-group-width-set">
        <mat-label>Name*</mat-label>
        <input matInput type="text" [(ngModel)]="formula.name" #name="ngModel" name="name" required />
        <mat-error class="mt-1" *ngIf="name.invalid">Formula name is required.</mat-error>
      </mat-form-field>
      <mat-form-field class="custom-input-box create-form-group-width-set ml-3">
        <mat-label>Formula Value</mat-label>
        <mat-select [(ngModel)]="formula.currentDecision" #currentDecision="ngModel" name="currentDecision" (selectionChange)="changeDecision()">
          <mat-option *ngFor="let decision of decisions" [value]="decision">{{decision}}</mat-option>
        </mat-select>
        <button mat-button mat-icon-button matSuffix (click)="clearDecisions()" *ngIf="formula.currentDecision" style="z-index: 1000"><mat-icon>close</mat-icon></button>
      </mat-form-field>
    </div>

    <mat-label *ngIf="formula.list?.length > 0">Formula Value: {{getFormula()}}</mat-label>

    <div class="d-flex mt-3">
      <mat-form-field class="custom-input-box create-form-group-width-set">
        <mat-label>Position</mat-label>
        <input matInput type="number" [(ngModel)]="formula.position" #position="ngModel" name="position" />
      </mat-form-field>
      <mat-form-field class="custom-input-box create-form-group-width-set ml-3">
        <mat-label for="colorPicker">Color*</mat-label>
        <input id="colorPicker" matInput type="color" [(ngModel)]="formula.color" #color="ngModel" name="color" required />
        <mat-error class="mt-1" *ngIf="color.invalid">Color is required.</mat-error>
      </mat-form-field>
    </div>

    <div class="mt-4">
      <mat-label>Contains Goals</mat-label>
      <mat-radio-group [(ngModel)]="formula.containsComplexFormula" #containsComplexFormula="ngModel" name="containsComplexFormula">
        <mat-radio-button class="margin-left-1em" [value]="true">Yes</mat-radio-button>
        <mat-radio-button class="margin-left-1em" [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="mt-4">
      <mat-label>Trending Possible</mat-label>
      <mat-radio-group [(ngModel)]="formula.trendingPossible" #trendingPossible="ngModel" name="trendingPossible">
        <mat-radio-button class="margin-left-1em" [value]="true">Yes</mat-radio-button>
        <mat-radio-button class="margin-left-1em" [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="mt-4">
      <mat-label>Default Visibility (Employee/Compare MTD Report)</mat-label>
      <mat-radio-group [(ngModel)]="formula.isDefaultVisibleOnEmployeeAndCompareMTDReport" #isDefaultVisibleOnEmployeeAndCompareMTDReport="ngModel" name="isDefaultVisibleOnEmployeeAndCompareMTDReport">
        <mat-radio-button class="margin-left-1em" [value]="true">Yes</mat-radio-button>
        <mat-radio-button class="margin-left-1em" [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="mt-4">
      <mat-label>Percentage</mat-label>
      <mat-radio-group [(ngModel)]="formula.isPercentage" #isPercentage="ngModel" name="isPercentage">
        <mat-radio-button class="margin-left-1em" [value]="true">Yes</mat-radio-button>
        <mat-radio-button class="margin-left-1em" [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="mt-4">
      <mat-label>Average Achieved For Rewards</mat-label>
      <mat-radio-group [(ngModel)]="formula.averageAchievedForRewards" #averageAchievedForRewards="ngModel" name="averageAchievedForRewards">
        <mat-radio-button class="margin-left-1em" [value]="true">Yes</mat-radio-button>
        <mat-radio-button class="margin-left-1em" [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="mt-4">
      <mat-label>Show $</mat-label>
      <mat-radio-group [(ngModel)]="formula.isMoney" #isMoney="ngModel" name="isMoney">
        <mat-radio-button class="margin-left-1em" [value]="true">Yes</mat-radio-button>
        <mat-radio-button class="margin-left-1em" [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="mt-4 d-flex">
      <mat-form-field class="custom-input-box create-form-group-width-set">
        <mat-label>Decimal Length*</mat-label>
        <input matInput type="number" [(ngModel)]="formula.decimalLength" #decimalLength="ngModel" name="decimalLength" required />
        <mat-error *ngIf="decimalLength.invalid">Decimal length is required.</mat-error>
      </mat-form-field>
      <mat-form-field class="custom-input-box create-form-group-width-set ml-3">
        <mat-label>Below Average Limit</mat-label>
        <input matInput type="number" [(ngModel)]="formula.belowAverageLimit" #belowAverageLimit="ngModel" name="belowAverageLimit" />
      </mat-form-field>
    </div>

    <div class="mt-3 d-flex">
      <mat-form-field class="custom-input-box create-form-group-width-set">
        <mat-label>Warning Limit</mat-label>
        <input matInput type="number" [(ngModel)]="formula.warningLimit" #warningLimit="ngModel" name="warningLimit" />
      </mat-form-field>
      <mat-form-field class="custom-input-box create-form-group-width-set ml-3">
        <mat-label>Formula View Group*</mat-label>
        <mat-select [(ngModel)]="formula.formulaViewGroupId" #formulaViewGroupId="ngModel" name="formulaViewGroupId" required>
          <mat-option *ngFor="let viewgroup of formulaViewGroups" [value]="viewgroup.id">{{viewgroup.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="f.submitted && formulaViewGroupId.invalid">Formula View Group is required.</mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
<div class="d-flex align-items-center mt-4 pt-1">
  <button mat-raised-button color="primary" class="submit-btn" (click)="onConfirm()">Submit</button>
  <button mat-button class="clear-btn ml-3" (click)="onDismiss()">Clear</button>
</div>
