import { Inject, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'finalize-dialog',
  templateUrl: 'finalize-dialog.component.html',
})
export class FinalizeDialogComponent implements OnInit {

  title: string;
  finalizeMessage: any;

  constructor(public dialogRef: MatDialogRef<FinalizeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.finalizeMessage = data.finalizeMessage;
  }

  ngOnInit() { }

  onConfirm(): void { this.dialogRef.close(true); }

  onDismiss(): void { this.dialogRef.close(false); }
}

