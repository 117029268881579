
<!--<div class="action-header">
 
</div>-->
<div class="row mt-4">
  <div class="col-sm-3">
    <div class="tree-control">
      <!-- Input for search -->
      <input type="text" class="form-control" name="categorySearchString" [(ngModel)]="categorySearchString" (keyup)="searchCategory(dataSourceTree.data)">
      <!-- in progress span -->
      <span *ngIf="categoryLoadingInProgress" class="loading-status">Loading...</span>
      <!-- main tree node -->
      <cdk-tree [dataSource]="dataSourceTree" [treeControl]="treeControl">
        <!-- leaf node -->
        <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="example-tree-node" #nestedTreeNodeElement>
          <button mat-button (click)="onCategorySelected(node)" [ngClass]="{ 'd-block': node.isVisible, 'd-none': !node.isVisible, 'selected-category': selectedCategoryId == node.id }">{{node.name}} : 
            <span class="counter ml-2">{{node.inventory}}</span>
          </button>
        </cdk-nested-tree-node>
        <!-- parent node -->
        <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChild" class="example-tree-node" #nestedTreeNodeElement>
          <button mat-icon-button [attr.aria-label]="'Toggle ' + node.name" cdkTreeNodeToggle [ngClass]="{ 'd-inline-flex': node.isVisible, 'd-none': !node.isVisible }">
            <mat-icon class="mat-icon-rtl-mirror" [ngClass]="{ 'd-inline-flex': node.isVisible, 'd-none': !node.isVisible }">{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}</mat-icon>
          </button>
          <button mat-button (click)="onCategorySelected(node)" [ngClass]="{ 'd-inline-flex': node.isVisible, 'd-none': !node.isVisible ,'selected-category': selectedCategoryId == node.id }">{{node.name}}</button>
          <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" *ngIf="getLevel(dataSourceTree._data,node)>=0">
            <ng-container cdkTreeNodeOutlet></ng-container>
          </div>
        </cdk-nested-tree-node>
      </cdk-tree>
    </div>
  </div>
  <div class="col-sm-9 action-header">
    <mat-form-field class="bottom-border col-3 " style="margin-bottom:1em;">
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" [(ngModel)]="searchTerm">
    </mat-form-field>
    <div *ngIf="inventories && inventories.length>0 " class="table-container">

      <table mat-table matSort class="table table-bordered table-hover small-txt" [dataSource]="dataSource">
        <ng-container matColumnDef="{{column}}" *ngFor="let column of getDisplayedColumns(); let colIndex = index;">
          <span *ngIf="getColumnDefinationByMappedToProperty(column).header ==='Product'">
          <th class="sort-column mat-sort-header-button-custom mat-header-cell" mat-header-cell *matHeaderCellDef mat-sort-header>{{getColumnDefinationByMappedToProperty(column).header}}</th>
          </span>
          <span *ngIf="getColumnDefinationByMappedToProperty(column).header !=='Product'">
          <th class="sort-column mat-sort-header-button-custom mat-header-cell" mat-header-cell *matHeaderCellDef mat-sort-header>{{getColumnDefinationByMappedToProperty(column).header}}</th>
          </span>
          <td mat-header-cell [matTooltip]="element[column]" [ngClass]="{ 'active-column': selectedStore && ( getColumnDefinationByMappedToProperty(column).header == selectedStore.name) }" *matCellDef="let element" style="background-color: #FAFAFA;">
            <span [ngClass]="{ 'active-column': selectedStore && ( getColumnDefinationByMappedToProperty(column).header == selectedStore.name) }">{{element[column]}}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="blank1"><td mat-header-cell *matHeaderCellDef> </td></ng-container>
        <ng-container matColumnDef="blank2">
          <td mat-header-cell *matHeaderCellDef class="mat-sort-header mat-total-header mat-header-cell"> Total Quantity </td>
        </ng-container>
        <ng-container *ngFor="let loc of stores; let storeIndex = index;" matColumnDef="total{{storeIndex}}">
          <td mat-header-cell *matHeaderCellDef class="mat-sort-header mat-total-header mat-header-cell" [ngClass]="{ 'active-column': selectedStore && ( loc.name == selectedStore.name) }">
            <span>{{inventoryTotals[0][attributes[storeIndex+2].name]}}</span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
        <tr mat-header-row *matHeaderRowDef="alternateHeaderAttributes"></tr>
        <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();">
        </tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="20" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>
