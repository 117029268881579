<header class="section-header">
    <div class="tbl">
        <div class="tbl-row">
            <div class="tbl-cell">
                <h3>Upload Status</h3>
            </div>
        </div>
    </div>
</header>
<div class="box-typical box-typical-padding">
  <div class="row">
    <div class="col-sm-3">
      <table style="width:1600px">
        <tr>
          <th>Function</th>
          <th>FileName</th>
          <th>Time</th>
          <th>TimeSince</th>

        </tr>
        <ng-container *ngIf="stati">
          <tr *ngFor="let status of stati">
            <td>{{status.functionType}}</td>
            <td>{{status.sheetName}}</td>
            <td>{{getFormattedDateTime(status.time)}}</td>
            <td>{{getTimeDifference(status.time)}}</td>

          </tr>
        </ng-container>
      </table>
     
    </div>
  </div>
</div>


