import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { DatePipe } from '@angular/common';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RqVzDataTableComponent } from "../../rq-vz-data-table/rq-vz-data-table.component";
import { CommissionReconcileService } from "../../services/commission-reconcile.service";
import { LoaderService } from "../../services/loader.service";
import { NotesDialogComponent } from "../../dialogs/notes/notes-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocationService } from "../../services/location.service";
import { LocationTypeCode } from "../../models/location-type-code";
import { ProductSearchDialogComponent } from "../../dialogs/product-search/product-search-dialog.component";
import { ConfirmDialogComponent } from "../../dialogs/confirm/confirm-dialog.component";

@Component({
  selector: 'discrepancies-all-data-table',
  templateUrl: './discrepancies-all-data-table.component.html',
  providers: []
})

export class DiscrepanciesAllDataTableComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(private _commissionReconcileService: CommissionReconcileService, private searchDialog: MatDialog,
    private _loaderService: LoaderService, private dialog: MatDialog, private _locationService: LocationService) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(RqVzDataTableComponent) rqVzDataTableComponent: RqVzDataTableComponent;

  @Input()
  tableData: any;
  @Output()
  getCommissionReportUpdated = new EventEmitter<string>();
  dataSource = new MatTableDataSource();
  searchTerm: string = "";
  selectedRow = new Set<any>();
  selectedImei: any;
  selectedItem = {};
  moneySymbol = '$';
  allSelected = false;
  debug = true;
  noData: Observable<boolean>;
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dataSource.paginator = this.paginator; this.dataSource.sort = this.sort;
    }, 0);
  }

  ngOnDestroy(): void {
    this.columnDefinations = null;
    this.dataSource = null;
    this.dialogRef?.close();
    this.searchDialogRef?.close();
  }

  ngOnInit(): void {
    this.subscriptionToClients = this._locationService.currentActualClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        this.selectedClientId = message; this.onClientSelected();
      });
    this.allSelected = false;
  }

  onClientSelected() {
    if (!this.selectedClientId || +this.selectedClientId === 0) {
      return;
    }
    this.selectedClientId = +this.selectedClientId;
    this.selectedClient = this.clients.find(r => r.id === this.selectedClientId);
    this._locationService.getLocations(LocationTypeCode.Region, (response) => {
      this.regions = response;
    }, this.selectedClientId);
  }

  applyFilter() {
    this.dataSource.filter = this.searchTerm.trim().toLowerCase();
  }

  columnDefinations = [
    { name: "Select", isSortable: true, mappedToProperty: "isSelected", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Sold by", isSortable: true, mappedToProperty: "soldBy", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Origin", isSortable: true, mappedToProperty: "monthYear", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Equipment Type", isSortable: true, mappedToProperty: "equipmentType", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "IMEI", isSortable: true, mappedToProperty: "imei", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "MTN", isSortable: true, mappedToProperty: "mtn", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Original Discrep", isSortable: true, mappedToProperty: "difference", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Paired$", isSortable: true, mappedToProperty: "pairedAmount", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Final Difference", isSortable: true, mappedToProperty: "pairedDifference", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Is Paired", isSortable: true, mappedToProperty: "isPaired", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Notes", isSortable: true, mappedToProperty: "commissionIMEINotes", class: "", isFilterable: true, sticky: false, hide: false }
  ];

  selectedColumns = this.getDisplayedColumns();


  getDisplayedColumns(): string[] {
    return this.columnDefinations.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
  }

  getColumnDefinationByMappedToProperty(mappedToProperty) {
    var column = this.columnDefinations.find(x => x.mappedToProperty == mappedToProperty);
    return column;
  }

  currentDuration = "";
  setCurrentDuration(monthYear: string) {
    this.currentDuration = monthYear;
  }

  updateDataSource(data) {
    this.allSelected = false;
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.data.forEach((x: any) => { x.isSelected = this.allSelected; });
    this.selectedImei = data[0];
    this.selectedItem = this.selectedImei;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this._loaderService.hide();
    this.dataSource.sortData = this.sortData();
    this.noData = this.dataSource.connect().pipe(map(data => data.length === 0));
    this.applyFilter();
  }

  selectItem(imei) {
    this.selectedItem = imei;
    this.rqVzDataTableComponent.updateSelectedImei(imei);
  }

  selectImei(imei) {
    imei.isSelected = !imei.isSelected;
    this.selectedImei = imei;
    this.allSelected = this.isAllSelected();
  }

  selectAll(e) {
    this.allSelected = e.checked;
    this.dataSource.filteredData.forEach((x: any) => {
      x.isSelected = this.allSelected;
    });

  }

  isAllSelected() {
    var notSelectedItems = this.dataSource.data.filter(function (item: any) {
      return item.isSelected === false;
    });
    return !(notSelectedItems != null && notSelectedItems.length > 0);
  }

  checkboxLabel(row): string {
    if (row != null) {
      return `${this.allSelected ? 'deselect' : 'select'} all`;
    }
    return "";
  }

  sendListOfImeis(decision: number) {
    let messageToShow = "";
    if (decision === 5) {
      messageToShow = "Are you sure you want to reconcile selected?";
    }
    else if (decision === 2) {
      messageToShow = "Are you sure you want to chargeback selected?";
    }
      this.dialogRef = this.dialog.open(ConfirmDialogComponent,
        {
          autoFocus: true, disableClose: false, panelClass: '',
          data: { title: "Confirm", message: messageToShow }
        });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this._loaderService.show();
        const thislist: any[] = this.dataSource.data.filter((item: any) => item.isSelected === true);
        let newList = [];
        thislist.forEach(x => { newList.push(x.id);if (decision == 5 && x.pairedId != 0) { newList.push(x.pairedId); } });
        this._commissionReconcileService.AddImeiListCommand(newList, decision, this.currentDuration).subscribe((response: any) => {
          this.getCommissionReportUpdated.next(); this.selectAll({ checked: false });
        });
      }
    });
  }

  getTextByDataDismissed(element, columnDef) {
    var textToShow = '';
    switch (columnDef.name) {
      case 'Origin':
        textToShow = element[columnDef.mappedToProperty].substring(2, 6) + ' ' + element[columnDef.mappedToProperty].substring(0, 2);
        break;
      case 'Notes':
        textToShow = element.commissionIMEINotes.length > 0 ? " (+" + (element.commissionIMEINotes.length) + " ...)" : "";
        break;
      default:
        textToShow = element[columnDef.mappedToProperty];
    }
    return textToShow;
  }

  private dialogRef: any;
  selectedClientId: number;
  subscriptionToClients: Subscription;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  selectedClient: any;
  clients: any[] = [];
  regions: any[];

  openNotesDialog(column, element) {
    if (column == 'commissionIMEINotes') {
      element.notes = element.commissionIMEINotes;
      this.dialogRef = this.dialog.open(NotesDialogComponent,
        {
          autoFocus: true,
          disableClose: false,
          panelClass: 'notes-component',
          data: { element: element, selectedClientId: this.selectedClientId, pageOrigin: 'monthly-commision' }
        });
      this.dialogRef.afterClosed().subscribe(result => {
        if (result.update === true) {
          this.getCommissionReportUpdated.next(); this.selectAll({ checked: false });

        }
      });
    }
  }

  sortData() {
    let sortFunction =
      (items: any[], sort: MatSort): any[] => {
        if (!sort.active || sort.direction === '' || sort.active == 'symbol') {
          return items;
        }
        return items.sort((a: any, b: any) => {
          let comparatorResult = 0;
          if (!a[sort.active] || !b[sort.active]) {
            comparatorResult = -1;
            return comparatorResult * (sort.direction == 'asc' ? 1 : -1);
          }
          switch (sort.active) {
            case 'isSelected':
              comparatorResult = (a[sort.active] === b[sort.active]) ? 0 : a[sort.active] ? -1 : 1;
              break;
            case 'commissionIMEINotes':
              comparatorResult = (a[sort.active].length - b[sort.active].length);
              break;
            case 'pairedDifference':
            case 'pairedAmount':
            case 'difference':
              comparatorResult = parseInt(a[sort.active].replace('$', '')) - parseInt(b[sort.active].replace('$', ''));
              break;
            default:
              if (a[sort.active] && b[sort.active]) {
                if (a[sort.active].localeCompare instanceof Function) {
                  comparatorResult = a[sort.active].localeCompare(b[sort.active]);
                }
              } else {
                comparatorResult = -1;
              }

              break;
          }
          return comparatorResult * (sort.direction == 'asc' ? 1 : -1);
        });
      };
    return sortFunction;
  }
  private searchDialogRef: any;
  openHistoryDialog(element) {
    this.searchDialogRef = this.searchDialog.open(ProductSearchDialogComponent,
      {
        autoFocus: true,
        disableClose: false,
        panelClass: 'product-search-component',
        data: { element: element }
      });
  }

}
