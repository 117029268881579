import { Component, OnInit, OnDestroy, ComponentFactoryResolver, Injector, ApplicationRef,  ViewChildren  } from '@angular/core';
import * as d3 from 'd3';
import { BaseChartDirective } from 'ng2-charts';
import { ChartConfiguration, ChartData, ChartType  } from 'chart.js';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { NotifierService } from 'angular-notifier';
import { EmployeeService } from '../services/employee.service';
import { DashboardService } from '../services/dashboard.service';
import { AuthService } from '../../core/services/auth.service';
import { ClientService } from '../services/client.service';
import { FormulaService } from '../services/formula.service';
import { LocationService } from '../services/location.service';
import { SkuGroupService } from '../services/sku-group.service';
import { InventoryService } from '../services/inventory.service';
import { Formula } from '../models/formula';
import { Action } from '../models/action';
import { SkuGroup } from '../models/sku-group';
import { Location } from '../models/location';
import { Employee } from '../models/employee';
import { Dashboard } from '../models/dashboard';
import { Client } from '../models/client';
import * as moment from 'moment';
import { ReplaySubject } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { FormControl, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DynamicChartComponent } from '../../dynamic-chart/dynamic-chart.component';
//import { DynamicLineChartComponent } from '../../dynamic-chart/dynamic-chart-line.component';
import { createComponentType } from '@angular/compiler/src/render3/view/compiler';
import { forEach } from 'underscore';

const CONFIG_CLIENT_KEY = 'ConfigClient';
Chart.register(ChartDataLabels);


@Component({

  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChildren(BaseChartDirective) basecharts: BaseChartDirective[] | undefined;
  clientId: number;
  dashboard: Dashboard;
  dashboards: Dashboard[];
  newCharts: DynamicChartComponent[];
  currentAction: Action;
  formulas: Formula[] = [];
  skuGroups: SkuGroup[] = [];
  loadingInProgress: boolean;
  clients: Client[] = [];
  locations2: Location[];
  locations: Location[];
  employees: Employee[];
  treeData: any;
  selectedClientId: number;
  selectedClient: any;
  locationSearchString = null;
  presentUser: Employee;
  topLocationToShow: number;
  locationTreeToView: Location;
  locationsForSelectTree: Location[];
  userId: string;
  userRole: any;
  inventoryData: any;
  zoomed = false;
  isIpad = false;
  deptharray = [{ text: 'Region', value: 2 },
  { text: 'District', value: 3 },
  { text: 'Store', value: 4 }];
  depthview = [];
  depthSelection = 4;
  selectedLocationColor: any;
  root: any;
  animationSpeed = 1500;
  overrideObject: any = {
    isDefault: true,
    monthlyDate: moment().format('YYYY-MM')
  };

  changeIsDefaultRange() {
    if (this.overrideObject.isDefault) {
      this.getDashboards();
    }
  }

  dialog: any = {
    isOpen: false,
    headerText: '',
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    }
  };
  colordialog: any = {
    isOpen: false,
    headerText: 'Edit Color',
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    }
  };
  locationSelect: any = {
    isOpen: false,
    headerText: '',
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    },
    refresh: () => {
      this.preEditPostEditFunction();
    }
  };

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);



  constructor(private _notifier: NotifierService,
    private _locationService: LocationService,
    private _clientService: ClientService,
    private _dashboardService: DashboardService,
    private _formulaService: FormulaService,
    private _skuGroupService: SkuGroupService,
    private _authService: AuthService,
    private _inventoryService: InventoryService,
    private _employeeService: EmployeeService,
    private _loaderService: LoaderService,
    private observer: BreakpointObserver,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector, private appRef: ApplicationRef ) {
    this.observer.observe('(max-width: 1025px)').subscribe((result) => {
      console.log('max', result);
      if (result.matches) {
        this.isIpad = true;
      }
    });
  }

  ngOnInit() {

   // Chart.defaults.animation = { duration: this.animationSpeed };


    Chart.defaults.plugins.datalabels.display = true;
    Chart.defaults.plugins.datalabels.anchor = 'end';
    Chart.defaults.plugins.datalabels.align = 'bottom';
    Chart.defaults.plugins.datalabels.color = 'white';
    this.selectedLocationColor = '#808080';
    this.selectedClientId = this._authService.clientId();
    this.initializeDialogControls(false);
    this.clientId = this._authService.clientId();
    this.userId = this._authService.userId();
    this.userRole = this._authService.roles();
    this._loaderService.show();
    this.getClients();
    this.getLocations();
    this.getEmployees();
    this.getSkuGroups();
    this.getFormulas();
    this.getLocationFullTree();
    // this.getInventoryTree();
    this.getDashboards();
    this.topLocationToShow = 1;
    // this.buildComplicatedChart();
    if (this.userRole === 'Sales Representative') {
      this.topLocationToShow = 2;
    }

  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
  }

  graphTypeSelected(graphType) {
    if (graphType == 1) {
      this.dashboard.dateTypeId = 1;
    } else {
      this.dashboard.dateTypeId = 11;
    }
  }

  onClientSelected() {
    this.clientId = this.selectedClientId;
    this.getFormulas();
    this.getSkuGroups();
    this.getEmployees();
    this.getLocations();
    this.getLocationFullTree();

    this.dashboard = {
      skuGroupOrFormula: false,
      name: '',
      locations: [],
      employees: [],
      graphTypeId: 1,
      dateTypeId: 1,
      locationTierFocusId: 3,
      formulaId: 1
    };
    setTimeout(() => { this.trimFullTree(); }, 500);
  }

  buildComplicatedChart() {
    /*

    const data = this.inventoryData;
    console.log(data);
    const width = 975;
    const radius = width / 2;
    function autoBox() {
      const { x, y, width, height } = this.getBBox();
      return [x, y, width, height];
    }
    const arc = d3.arc()
      .startAngle(d => d.x0)
      .endAngle(d => d.x1)
      .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(radius / 2)
      .innerRadius(d => d.y0)
      .outerRadius(d => d.y1 - 1);

    const partition = data => d3.partition()
      .size([2 * Math.PI, radius])
      (d3.hierarchy(data)
        .sum(d => d.value)
        .sort((a, b) => b.value - a.value));

    const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, data.children.length + 1));

    const format = d3.format(',d');









      const root = partition(data);

      const svg = d3.select('#px').append('svg')
        .style('max-width', '40%')
        .style('height', 'auto')
        .style('font', '10px sans-serif')
        .style('margin', '5px');

      svg.append('g')
        .attr('fill-opacity', 0.6)
        .selectAll('path')
        .data(root.descendants().filter(d => d.depth))
        .enter().append('path')
        .attr('fill', d => { while (d.depth > 1) { d = d.parent; } return color(d.data.name); })
        .attr('d', arc)
        .append('title')
        .text(d => `${d.ancestors().map(d => d.data.name).reverse().join('/')}\n$${format(d.value)}`);

      svg.append('g')
        .attr('pointer-events', 'none')
        .attr('text-anchor', 'middle')
        .selectAll('text')
        .data(root.descendants().filter(d => d.depth && (d.y0 + d.y1) / 2 * (d.x1 - d.x0) > 10))
        .enter().append('text')
        .attr('transform', function (d) {
          const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
          const y = (d.y0 + d.y1) / 2;
          return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
        })
        .attr('dy', '0.35em')
        .text(d => d.data.name);

      svg.node();

    svg.attr('viewBox', autoBox);


*/
  }
  setColorForLocation() {

    const selectedLocation: Location = this.locations.find(x => x.id === this.selectedLocationColor.id);
    selectedLocation.color = this.selectedLocationColor.color;
    this._dashboardService.changeColor(selectedLocation).subscribe((response: any) => {
      this.colordialog.isOpen = false; this.generateChart();
      this._notifier.notify('success', 'Color updated successfully.');
    }, (error) => this._notifier.notify('error', error.message), () => { });
  }


  leftClickOnColorLocation(store) {
    this.selectedLocationColor = store;
    this.colordialog.isOpen = true;
  }

  parseLocationsByTier(roots) {
    if (roots.depth === this.depthSelection - 1) {
      roots.ignored = true;
      roots._children = roots.children;
      roots.children = null;
    } else if (typeof roots.children !== 'undefined' && roots.children !== null) {
      roots.children.forEach((c) => { this.parseLocationsByTier(c); });
    }
  }

  hideDepth() {

    this.parseLocationsByTier(this.root);
    this.buildLocationTreeInterface(this.root);

  }

  markSelectedRoot(roots) {

    const locationArray = this.dashboard.locations.find((element) => element.id === roots.data.id && roots.depth < 4);
    const employeeArray = this.dashboard.employees.find((element) => element.id === roots.data.id && roots.depth === 5);
    if (locationArray) {
      roots.selected = true;
    } else if (employeeArray) {
      roots.selected = true;
    } else {
      roots.selected = false;
    }
    if (typeof roots.children !== 'undefined' && roots.children !== null) { roots.children.forEach((c) => { this.markSelectedRoot(c); }); }
  }

  buildLocationTreeInterface(sometimes?: any) {
    // ************** Generate the tree diagram	 *****************
    const margin = { top: 100, right: 90, bottom: 30, left: 200 },
      width = screen.width - 300 - margin.left - margin.right,
      height = screen.height - 400 - margin.top - margin.bottom;
    document.getElementById('p2').innerHTML = '';
    // append the svg object to the body of the page
    // appends a 'group' element to 'svg'
    // moves the 'group' element to the top left margin

    const svg = d3.select('#p2').append('svg')
      .attr('width', width + margin.right + margin.left)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate('
        + margin.left + ',' + margin.top + ')');
    let i = 0;
    const duration = 750;
    let root;

    // declares a tree layout and assigns the size
    const treemap = d3.tree().size([height, width]);

    // Assigns parent, children, height, depth
    root = d3.hierarchy(this.locationTreeToView, function (d) { return d.children; });


    root.x0 = height / 2;
    root.y0 = 0;

    // Collapse after the second level
    // root.children.forEach(collapse);
    if (sometimes) {
      root = this.root;
    }
    this.markSelectedRoot(root);

    this.root = root;
    if (sometimes) {
      root = this.root;
    }
    update(root);



    // Collapse the node and all it's children
    // function collapse(d) {
    //   if(d.children) {
    //     d._children = d.children
    //     d._children.forEach(collapse)
    //     d.children = null
    //   }
    // }
    const updateDashboardLocationList = (nodeData, addOrRemove: boolean) => {
      let filteredArray = [];
      if (addOrRemove) {
        this.dashboard.locations.push(this.locations.find(emp => emp.id === nodeData.data.id));

      } else {
        filteredArray = this.dashboard.locations.filter((element) => element.id !== nodeData.data.id);
        this.dashboard.locations = filteredArray;


      }


    };

    const updateDashboardEmployeeList = (nodeData, addOrRemove: boolean) => {
      let filteredArray = [];
      if (addOrRemove) {
        this.dashboard.employees.push(this.employees.find(emp => emp.id === nodeData.data.id));

      } else {
        filteredArray = this.dashboard.employees.filter((element) => element.id !== nodeData.data.id);
        this.dashboard.employees = filteredArray;

      }


    };
    const finecallout = ((d) => {
      if (d.data.locationTypeCodeId < 5) {
        this.trimFullTree(d.data);

        this.buildLocationTreeInterface();
        this.zoomed = true;
      }
    });

    function update(source) {

      // Assigns the x and y position for the nodes
      const treeData = treemap(root);

      // Compute the new tree layout.
      const nodes = treeData.descendants(),
        links = treeData.descendants().slice(1);

      // Normalize for fixed-depth.
      nodes.forEach(function (d) { d.y = d.depth * 180; });

      // ****************** Nodes section ***************************

      // Update the nodes...
      const node = svg.selectAll('g.node')
        .data(nodes, function (d) { return d.id || (d.id = ++i); });

      // Enter any new modes at the parent's previous position.
      const nodeEnter = node.enter().append('g')
        .attr('class', 'node')
        .attr('transform', function (d) {
          return 'translate(' + source.y0 + ',' + source.x0 + ')';
        })
        .on('click', click).on('contextmenu', rightclick);

      // Add Circle for the nodes
      nodeEnter.append('circle')
        .attr('class', 'node')
        .attr('r', 1e-6)
        .style('fill', function (d) {
          return d._children ? 'lightsteelblue' : '#fff';
        });

      // Add labels for the nodes
      nodeEnter.append('text')
        .attr('dy', '.35em')
        .attr('x', function (d) {
          return d.children || d._children ? -13 : 13;
        })
        .attr('text-anchor', function (d) {
          return d.children || d._children ? 'end' : 'start';
        })
        .text(function (d) { return d.data.name; });

      // UPDATE
      const nodeUpdate = nodeEnter.merge(node);

      // Transition to the proper position for the node
      nodeUpdate.transition()
        .duration(duration)
        .attr('transform', function (d) {
          return 'translate(' + d.y + ',' + d.x + ')';
        });

      // Update the node attributes and style
      nodeUpdate.select('circle.node')
        .attr('r', 10)
        .style('fill', function (d) {
          return d.ignored ? '#ed008c' : d.selected ? '#34c70e' : '#fff';
        })
        .attr('cursor', 'pointer');


      // Remove any exiting nodes
      const nodeExit = node.exit().transition()
        .duration(duration)
        .attr('transform', function (d) {
          return 'translate(' + source.y + ',' + source.x + ')';
        })
        .remove();


      // On exit reduce the node circles size to 0
      nodeExit.select('circle')
        .attr('r', 1e-6);

      // On exit reduce the opacity of text labels
      nodeExit.select('text')
        .style('fill-opacity', 1e-6);

      // ****************** links section ***************************

      // Update the links...
      const link = svg.selectAll('path.link')
        .data(links, function (d) { return d.id; });

      // Enter any new links at the parent's previous position.
      const linkEnter = link.enter().insert('path', 'g')
        .attr('class', 'link')
        .attr('d', function (d) {
          const o = { x: source.x0, y: source.y0 };
          return diagonal(o, o);
        });

      // UPDATE
      const linkUpdate = linkEnter.merge(link);

      // Transition back to the parent element position
      linkUpdate.transition()
        .duration(duration)
        .attr('d', function (d) { return diagonal(d, d.parent); });

      // Remove any exiting links
      const linkExit = link.exit().transition()
        .duration(duration)
        .attr('d', function (d) {
          const o = { x: source.x, y: source.y };
          return diagonal(o, o);
        })
        .remove();

      // Store the old positions for transition.
      nodes.forEach(function (d) {
        d.x0 = d.x;
        d.y0 = d.y;
      });

      // Creates a curved (diagonal) path from parent to the child nodes
      function diagonal(s, d) {

        const path = `M ${s.y} ${s.x}
              C ${(s.y + d.y) / 2} ${s.x},
                ${(s.y + d.y) / 2} ${d.x},
                ${d.y} ${d.x}`;

        return path;
      }

      // Toggle children on click.
      function rightclick(d) {

        setTimeout(function () { finecallout(d); }, 500);

        //console.log(d);
        update(d);
      }

      function click(d) {

        if (d.children) {
          d._children = d.children;
          d.children = null;
          d.selected = !d.selected;
        } else {
          if (!d._children) {
            d.selected = !d.selected;
          } else if (d.selected) {
            d.ignored = !d.ignored;
            d.selected = !d.selected;
          } else if (d.ignored) {
            d.ignored = !d.ignored;
            d.children = d._children;
            d._children = null;
          }
        }

        const hasChildren = false;

        if (d.selected) {
          if (d.depth === 4) {
            updateDashboardEmployeeList(d, true);

          } else {
            updateDashboardLocationList(d, true);
          }
        } else if (!d.selected || d.ignored) {
          if (d.depth === 4) {
            updateDashboardEmployeeList(d, false);
          } else {
            updateDashboardLocationList(d, false);
          }
        }
        /*
        if (d.data.children || d._children) {
          hasChildren = true;
        }
        if (!hasChildren && d.selected) {
          updateDashboardLocationList(d, true);
        }
        else if (!hasChildren && !d.selected) {

          updateDashboardLocationList(d, false);
        }
        else if (hasChildren && d.selected) {
          d._children.forEach(function (child) {
            if (!child.children && !child._children) {
              updateDashboardLocationList(d, true);
            }
            else {


            }
          }
          );
        }
        */
        update(d);
      }



    }



  }

  leftclickonlocation(loc: Location, db: Dashboard) {

    const determineAction = db.showonchartlocations.find((element) => {
      return element.id === loc.id;

    });
    const locationListTitle = document.getElementById(db.id + '-' + loc.name);
    if (determineAction) {
      // remove
      locationListTitle.style.textDecoration = 'line-through';
      const filteredArray = db.showonchartlocations.filter((element) => {
        return element.id !== loc.id;

      });
      db.showonchartlocations = filteredArray;
    } else {
      // add
      locationListTitle.style.textDecoration = 'none';
      db.showonchartlocations.push(loc);


    }

    if (db.graphTypeId === 3) {
      db.dashChart.instance.chart.update();
      return;
    }

    this.sortGraphStuff(db, db.sortCode);


  }

  filterLocationsFirst(db: Dashboard) {
    const dataList: any[] = [];
    const backgroundColor: any[] = [];

    db.locationForChart.forEach((store) => {

      if (db.showonchartlocations.find(x => x.id === store.id)) {
        
        db.dashChart.instance.chartData.data.labels[db.dashChart.instance.chartData.data.labels.length] = store.name;
        if (db.targetFormula.isPercentage) {
          dataList.push(this.getValueForFormula(db.targetFormula.formulaValue, store.stats).toFixed(2));
        } else {
          dataList.push(this.getValueForFormula(db.targetFormula.formulaValue, store.stats).toFixed(0));
        }
        if (db.parentGroups.length > 1) {

          backgroundColor.push(db.parentGroups.find(x => x.name === store.parentsName).color);
        } else if (db.parentGroups.length === 1) {
          backgroundColor.push(this.locations.find(x => x.name === store.name).color);
        }
      }
    });
    db.dashChart.instance.chartData.data.datasets[0].backgroundColor = backgroundColor;
    db.dashChart.instance.chartData.data.datasets[0].data = dataList;
  }
  // if (dashboardz.showonchartlocations.find(x => x.id == store.id)) {




  sortGraphStuff(db: Dashboard, sortType: number) {
    db.sortCode = sortType;
    const dataList: any[] = [];
    const dataList2: any[] = [];
    const backgroundColor: any[] = [];
    const backgroundColor2: any[] = [];
    const labels: any[] = [];
    const parents: any[] = [];

    db.locationForChart.forEach((store) => {
      if (db.showonchartlocations.find(x => x.name === store.parentsName)) {

        labels.push(store.name);
        if (db.targetFormula.isPercentage) {
          dataList.push(this.getValueForFormula(db.targetFormula.formulaValue, store.stats).toFixed(2));
          dataList2.push(this.getValueForFormula2(db.targetFormula.formulaValue, store.stats).toFixed(2));
        } else {
          dataList.push(this.getValueForFormula(db.targetFormula.formulaValue, store.stats).toFixed(0));
          dataList2.push(this.getValueForFormula2(db.targetFormula.formulaValue, store.stats).toFixed(0));
        }
        parents.push(store.parentsName);
        if (db.parentGroups.length > 1) {
          backgroundColor.push(db.parentGroups.find(x => x.name === store.parentsName).color);
          backgroundColor2.push(this.ColorLuminance(db.parentGroups.find(x => x.name === store.parentsName).color, -0.2));

        } else if (db.parentGroups.length === 1) {
          backgroundColor.push(this.locations.find(x => x.name === store.name).color);
          backgroundColor2.push(this.ColorLuminance(this.locations.find(x => x.name === store.name).color, -0.2));
        }

      }
    });

    //db.dashChart.data.datasets[0].backgroundColor = backgroundColor;
    
    const arrayData2 = dataList2;
    const arrayLabel = labels;
    const arrayData = dataList;
    const arrayColors = backgroundColor;
    const arrayColors2 = backgroundColor2;
    const arrayParent = parents;



    const arrayOfObj = arrayLabel.map(function (d, i) {
      return {
        label: d,
        data: arrayData[i] || 0,
        data2: arrayData2[i] || 0,
        color: arrayColors[i],
        color2: arrayColors2[i],
        parent: arrayParent[i]
      };
    });
    let sortedArrayOfObj;
    const newArrayLabel = [];
    const newArrayData = [];
    const newArrayData2 = [];
    const newArrayColor = [];
    const newArrayColor2 = [];
    const newArrayParent = [];


    if (sortType === 1) {
      sortedArrayOfObj = arrayOfObj.sort(function (a, b) {
        return b.data - a.data;
      });
    } else if (sortType === 2) {
      sortedArrayOfObj = arrayOfObj.sort(function (a, b) {
        return a.data - b.data;
      });
    } else if (sortType === 3) {
      sortedArrayOfObj = arrayOfObj.sort(function (a, b) {
        return a.data;
      });
    }

    let count = 0;
    sortedArrayOfObj.forEach(function (d) {
      if (count < 12 || (db.graphTypeId === 2 && count < 6)) {
        newArrayLabel.push(d.label);
        newArrayData.push(d.data);
        newArrayData2.push(d.data2);
        newArrayColor.push(d.color);
        newArrayColor2.push(d.color2);
        newArrayParent.push(d.parent);
        count++;
      }
    });
    db.dashChart.instance.chartData.datasets[0].backgroundColor = newArrayColor;
    db.dashChart.instance.chartData.labels = newArrayLabel;
    db.dashChart.instance.chartData.datasets[0].data = newArrayData;
    if (db.graphTypeId === 2) {
      db.dashChart.instance.chartData.datasets[1].data = newArrayData2;
      db.dashChart.instance.chartData.datasets[0].backgroundColor = newArrayColor2;
      db.dashChart.instance.chartData.datasets[1].backgroundColor = newArrayColor;
    }

    db.dashChart.instance.chart.update();
  }

  generateChart() {

    Chart.defaults.font.weight = 'bold';
    let uW = 100;
    let fontSize = '10px';
    let fontSize1 = 14;
    let fontSize2 = '15px';
    let widthEnhance = 1;
    if (screen.width < 600) {
      uW = 190;
      fontSize = '8px';
      fontSize1 = 8;
      fontSize2 = '12px';
      widthEnhance = 1.5;

    }
    const uH = 100;
    const that = this;

    const totalWidth = (.46 * uW).toString() + 'vw';
    const leftWidth = (.38 * uW).toString() + 'vw';
    const dashHeight = (.52 * uH).toString() + 'vh';
    const rightWidth = (.06 * widthEnhance * uW).toString() + 'vw';
    const titleAreaHeight = (.07 * uH).toString() + 'vh';
    const canvasHeight = (.40 * uH).toString() + 'vh';
    const boxHeight = (.01 * uH).toString() + 'vh';
    const paddingWidth = (.07 * uW).toString() + 'px';
    let containerIndex = 0
    console.log(this.dashboards);
    this.newCharts = [];
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DynamicChartComponent);

    this.dashboards.forEach((dashboardz) => {
      dashboardz.sortCode = 3;
      const uniqueListOfParents = [];
      const uniqueListOfParentsString = [];
      let storeCount = dashboardz.locationForChart.length;

      dashboardz.locationForChart.forEach((store) => {


        if (!uniqueListOfParentsString.includes(store.parentsName)) {
          const loccy = this.locations.find(x => x.name === store.parentsName);
          uniqueListOfParentsString.push(store.parentsName);
          uniqueListOfParents.push({ name: store.parentsName, color: loccy.color, id: loccy.id });
        }

      });
      dashboardz.parentGroups = uniqueListOfParents;
      dashboardz.showonchartlocations = uniqueListOfParents;
      // var canv: HTMLCanvasElement = new HTMLCanvasElement();
      const totalArea = document.createElement('div');
      totalArea.id = dashboardz.id.toString();
      if (!this.isIpad) {
        totalArea.style.minWidth = totalWidth;
        totalArea.style.minHeight = dashHeight;
        totalArea.style.maxWidth = totalWidth;
        totalArea.style.maxHeight = dashHeight;
      }

      totalArea.style.cssFloat = 'left';
      totalArea.style.display = 'inline-block';
      totalArea.style.verticalAlign = 'top';
      totalArea.style.borderColor = dashboardz.targetFormula.color;
      totalArea.style.borderStyle = 'none';
      totalArea.style.borderWidth = '0px';
      totalArea.style.paddingLeft = '0px';
      totalArea.style.paddingRight = '0px';
      totalArea.style.paddingTop = '0px';
      totalArea.style.paddingBottom = '0px';
      // leftArea.style.paddingRight = paddingWidth;
      const leftArea = document.createElement('span');
      leftArea.id = dashboardz.id.toString();
      if (!this.isIpad) {
        leftArea.style.minWidth = leftWidth;
        leftArea.style.minHeight = dashHeight;
        leftArea.style.maxWidth = leftWidth;
        leftArea.style.maxHeight = dashHeight;
      }
      leftArea.style.cssFloat = 'left';
      leftArea.style.display = 'inline-block';
      leftArea.style.verticalAlign = 'top';

      const rightArea = document.createElement('span');
      rightArea.id = dashboardz.id.toString();
      if (!this.isIpad) {
        rightArea.style.minWidth = rightWidth;
        rightArea.style.minHeight = dashHeight;
        rightArea.style.maxWidth = rightWidth;
        rightArea.style.maxHeight = dashHeight;
      }
      rightArea.style.cssFloat = 'left';
      rightArea.style.display = 'inline-block';
      rightArea.style.verticalAlign = 'top';
      const titleArea = document.createElement('span');
      titleArea.id = 'title' + dashboardz.id.toString();
      if (!this.isIpad) {
        titleArea.style.minWidth = leftWidth;
        titleArea.style.minHeight = titleAreaHeight;
        titleArea.style.maxWidth = leftWidth;
        titleArea.style.maxHeight = titleAreaHeight;
      }
      const groupArea = document.createElement('ul');
      groupArea.id = 'group' + dashboardz.id.toString();
      groupArea.style.display = this.isIpad ? "flex" : "inline-grid";
      groupArea.style.flexWrap = this.isIpad ? "wrap" : "";
      //  groupArea.style.display = "grid";
      if (!this.isIpad) {
        // groupArea.style.minWidth = '6vw';
        // groupArea.style.maxWidth = '6vw';
        //groupArea.style.maxHeight = '52vh';
      }
      groupArea.style.verticalAlign = 'top';
      const penIcon = document.createElement('i');
      penIcon.className = 'fa fa-edit';
      const editButton = document.createElement('a');
      editButton.className = 'icon-btn';
      editButton.appendChild(penIcon);
      editButton.style.cssFloat = 'right';
      editButton.addEventListener('click', (e: Event) => this.editDashboard(dashboardz));
      const trashIcon = document.createElement('i');
      trashIcon.className = 'fa fa-trash';
      const deleteButton = document.createElement('a');
      deleteButton.className = 'icon-btn';
      deleteButton.appendChild(trashIcon);
      deleteButton.style.cssFloat = 'right';
      deleteButton.addEventListener('click', (e: Event) => this.deleteDashboard(dashboardz));
      // stupidButton3.onclick = (this.deleteDashboard(dashboardz));

      const titleText = document.createElement('p');
      let showThisInstead = dashboardz.dateDisplay;

      if (dashboardz.dateDisplay === 'MTD') {
        showThisInstead = moment().format('MMMM');
      } else if (dashboardz.dateDisplay === 'Last MTD') {
        showThisInstead = moment().subtract(1, 'month').format('MMMM');
      } else if (dashboardz.dateDisplay === 'Lastweek') {
        showThisInstead = moment().subtract(7, 'days').month() + '/' + moment().subtract(7, 'days').date() +
          ' to ' + moment().subtract(1, 'days').month() + '/' + moment().subtract(1, 'days').date();

      } else if (!this.overrideObject.isDefault && dashboardz.graphTypeId == 1) {
        showThisInstead = moment(this.overrideObject.monthlyDate).format('MMMM yyyy');
      }

      titleText.textContent = dashboardz.targetFormula.name + ' -- ' + showThisInstead;
      titleText.style.cssFloat = 'center';
      titleText.style.textAlign = 'center';
      titleText.style.color = '#003366';
      titleText.style.display = 'block';
      titleText.style.fontWeight = 'bold';
      titleText.style.fontSize = fontSize2;
      titleText.appendChild(deleteButton);
      titleText.appendChild(editButton);

      const nameText = document.createElement('p');
      nameText.textContent = dashboardz.name;
      // nameText.style.cssFloat = "left";
      nameText.style.fontWeight = 'bold';
      nameText.style.textAlign = 'center';
      nameText.style.color = '#072F5F';
      nameText.style.fontSize = fontSize2;

      const lengthParentGroups = 100 / dashboardz.parentGroups.length;
      const numParentGroups = dashboardz.parentGroups.length;

      if (storeCount > 1) {
        // reappend to each button every time? 
        var tooltip = d3.select("body")
          .append("div")
          .attr("class", "tooltip")
          .style("opacity", 0)
          .on("mouseover", function () {
            d3.select(this).interrupt();
          }).on("mouseout", function () {
            d3.select(this).transition()
              .delay(1000)
              .style("opacity", 0)
          })


        const sortHigh = document.createElement('button');
        sortHigh.textContent = '↑';
        sortHigh.className = "tooltip"
        sortHigh.addEventListener("mouseover", function (d) {
          tooltip.interrupt();
          //tooltip.html("Sort UP! ")
          //  .style("position", 'relative')
          //  .style("display", 'inline-block')
          //  .style("opacity", 1);
        });
        sortHigh.addEventListener("mouseout", function () {
          tooltip.transition()
            .delay(1000)
            .style("opacity", 0)
        });


        sortHigh.style.cssFloat = 'left';
        sortHigh.className = 'btn btn-success';
        sortHigh.addEventListener('click', () => {
          this.sortGraphStuff(dashboardz, 1);
        });
        const sortLow = document.createElement('button');
        sortLow.textContent = '↓';
        sortLow.style.cssFloat = 'left';
        sortLow.className = 'btn btn-danger';

        sortLow.addEventListener("mouseover", function (d) {
          var xPos = parseFloat(d3.select(this).attr('cx')) + 14;
          var yPos = parseFloat(d3.select(this).attr('cy')) - 22;
          tooltip.interrupt();
          //tooltip.html("Sort DOWN! ")
          //  .style("opacity", 1);
        });
        sortLow.addEventListener("mouseout", function () {
          tooltip.transition()
            .delay(1000)
            .style("opacity", 0)
        });

        sortLow.addEventListener('click', () => {
          this.sortGraphStuff(dashboardz, 2);
        });
        const originalSort = document.createElement('button');
        originalSort.textContent = '«';
        originalSort.className = 'btn btn-secondary';
        originalSort.style.cssFloat = 'left';
        originalSort.addEventListener('click', () => {
          this.sortGraphStuff(dashboardz, 3);

        });
        if (dashboardz.graphTypeId === 1 || dashboardz.graphTypeId === 2) {
          titleArea.appendChild(sortHigh);
          titleArea.appendChild(sortLow);
          titleArea.appendChild(originalSort);
        }
      }
      titleArea.appendChild(titleText);

      /*const filteredArray = db.showonchartlocations.filter((element) => {
        return element.id !== loc.id;

      });*/

      if (numParentGroups > 10) {

      } else {
        const flipGroups = dashboardz.parentGroups.reverse();

        flipGroups.forEach((salesGroup) => {
          const groupBox = document.createElement('li');
          // groupBox.id = dashboardz.id + "-" + salesGroup.name;
          //groupBox.style.cssFloat = 'left';
          // groupBox.style.minHeight = "100%";
          // groupBox.style.maxWidth = lengthParentGroups + "%";
          // groupBox.style.maxHeight = "100%";
          groupBox.style.display = 'flex'
          //groupBox.style.whiteSpace = ''
          groupBox.style.whiteSpace = 'nowrap'
          groupBox.style.lineHeight = '30px'
          groupBox.style.lineBreak = 'normal'
          groupBox.className = 'li-item';
          const groupColor = document.createElement('span');
          groupColor.className = 'dashboardsquare';
          groupColor.style.float = 'none';
          groupColor.addEventListener('click', () => { this.leftClickOnColorLocation(salesGroup); });
          groupColor.style.backgroundColor = salesGroup.color;
          groupColor.style.minWidth = boxHeight;
          // groupColor.style.minHeight = (((40 * lengthParentGroups / 100) +3 ).toFixed(2) + "px");
          groupColor.style.maxWidth = boxHeight;
          // groupColor.style.maxHeight = (((40 * lengthParentGroups / 100) + 3).toFixed(2) + "px");
          const groupTitle = document.createElement('span');
          groupTitle.id = dashboardz.id + '-' + salesGroup.name;
          groupTitle.textContent = salesGroup.name;
          groupTitle.style.fontSize = fontSize;
          groupTitle.addEventListener('click', () => { this.leftclickonlocation(salesGroup, dashboardz); });

          // groupTitle.style.fontSize = (lengthParentGroups / 2).toString();
          // groupTitle.style.cssFloat = "left";
          groupBox.appendChild(groupColor);
          groupBox.appendChild(groupTitle);


          groupArea.appendChild(groupBox);

        });
      }
      // titleArea.appendChild(nameText);
      const mycanvas = document.createElement('span');
      mycanvas.id = dashboardz.id.toString(); mycanvas.style.minWidth = '100%';
      mycanvas.style.minHeight = '100%';
      mycanvas.style.maxWidth = '100%';
      mycanvas.style.maxHeight = '100%';
      if (!this.isIpad) {

      }
      mycanvas.style.cssFloat = 'bottom';
      if (this.isIpad) {
        mycanvas.style.width = '100%';
        mycanvas.style.height = '100%';
      }

      leftArea.appendChild(titleArea);
      leftArea.appendChild(mycanvas);
      rightArea.appendChild(groupArea);
      totalArea.appendChild(leftArea);
      totalArea.appendChild(rightArea);
      const container = document.getElementById('chartContainer' + containerIndex);
      container.innerHTML = '';
      container.appendChild(totalArea);
      containerIndex++;
      let dashChart = null;

      let barChartOptions: ChartConfiguration<'bar'>['options'] = {};
      let barChartData: ChartData<'bar'> = {
        labels: [],
        datasets:[]
      };
      let lineChartOptions: ChartConfiguration<'line'>['options'] = {};
      let lineChartData: ChartData<'line'> = {
        labels: [],
        datasets: []
      };

      if (dashboardz.graphTypeId === 1) {

        barChartOptions = {
          scales: {
            y: {
              beginAtZero: true,
              ticks: {

                color: '#000001',
                font: {
                  size: fontSize1, weight: 'normal'
                },
                callback: function (value: any) {
                  return value == 0 ? ""
                    : that.getFormulaComputedPropertyValue(value, dashboardz.targetFormula)
                }
              },
            }
            ,
            x: {
              ticks: {
                font: {
                  size: fontSize1, weight: 'normal'
                },
                color: '#000001',
              }
            }
          },
          plugins: {
            legend: {
              display: false,
              labels: {
                color: '#000001',
              }
            },
            datalabels: {
              formatter: function (value, context) {
                return value == 0 ? ""
                  : that.getFormulaComputedPropertyValue(value, dashboardz.targetFormula);
              }
            }
          }
        }
        //dashChart = {
        //  type: 'bar',
        //  data: {
        //    labels: [],
        //    datasets: [{
        //      data: [],
        //      backgroundColor: [],
        //      borderColor: [
        //        'rgba(255, 255, 255, 1)',
        //      ],
        //      borderWidth: 1,
        //      borderRadius: 5,
        //      borderSkipped: false,

        //    }]
        //  },
        //  options: {
        //    responsive: true,
        //    animation: {
        //      duration: 1500,
        //    },


        //    //  responsive: true,

        //  }
        //};
      }
      else if (dashboardz.graphTypeId === 2) {
        let displayDateOld = '2 Weeks Ago';
        let displayDateNew = '1 Week Ago';
        let time = moment();
        if (dashboardz.dateTypeId === 11) {

          if (!this.overrideObject.isDefault) {
            time = moment(this.overrideObject.monthlyDate);
          }
          displayDateOld = moment(time).subtract(1, 'year').format('MMM YYYY');
          displayDateNew = moment(time).format('MMM YYYY');
          //displayDateOld = moment().subtract(1, 'year').format('MMM YYYY');
          //displayDateNew = moment().format('MMM YYYY');
        } else if (dashboardz.dateTypeId === 12) {
          if (!this.overrideObject.isDefault) {
            time = moment(this.overrideObject.monthlyDate);
          }
          displayDateOld = moment(time).subtract(1, 'month').format('MMM YYYY');
          displayDateNew = moment(time).format('MMM YYYY');
        } else if (dashboardz.dateTypeId === 14) {
          if (!this.overrideObject.isDefault) {
            time = moment(this.overrideObject.monthlyDate);
          }
          displayDateOld = moment(time).subtract(1, 'year').format('YYYY');
          displayDateNew = moment(time).format('YYYY');
        }

        const mycanvas2 = document.createElement('canvas');
        mycanvas2.id = dashboardz.id.toString(); mycanvas2.style.minWidth = '100%';
        mycanvas2.style.minHeight = '100%';
        mycanvas2.style.maxWidth = '100%';
        mycanvas2.style.maxHeight = '100%';
        if (!this.isIpad) {

        }
        mycanvas2.style.cssFloat = 'bottom';
        if (this.isIpad) {
          mycanvas2.style.width = '100%';
          mycanvas2.style.height = '100%';
        }



        barChartOptions = {
          scales: {

            y: {
              beginAtZero: true,
              ticks: {
                color: '#000001',
                font: {
                  size: fontSize1, weight: 'normal'
                },
                callback: function (value: any) {
                  return value == 0 ? ""
                    : that.getFormulaComputedPropertyValue(value, dashboardz.targetFormula)
                }
              },
            }
            ,

            x: {
              ticks: {
                font: {
                  size: fontSize1, weight: 'normal'
                },
                color: '#000001',
              }
            }
          },

          plugins: {
            datalabels: {
              formatter: function (value) {
                return value == 0 ? "" : that.getFormulaComputedPropertyValue(value, dashboardz.targetFormula)
              }, rotation: 270
            },
            tooltip: {
              callbacks: {
                beforeTitle: function (t) {
                  if (t[0].datasetIndex === 0) {
                    return displayDateOld;
                  } else if (t[0].datasetIndex === 1) {
                    return displayDateNew;
                  } else { return ""; }
                }
              }
            },

            legend: {
              display: false,
              labels: {
                color: '#000001',

              }
            },
          }

        };





      }
      if (dashboardz.graphTypeId === 3) {

        lineChartOptions = {
          scales: {
            y: {
              beginAtZero: true,
              ticks: {

                color: '#000001',
                font: {
                  size: fontSize1, weight: 'normal'
                },
                callback: function (value: any) {
                  return value == 0 ? ""
                    : that.getFormulaComputedPropertyValue(value, dashboardz.targetFormula)
                }
              },
            }
            ,
            x: {
              ticks: {
                font: {
                  size: fontSize1, weight: 'normal'
                },
                color: '#000001',
              }
            }
          },
          plugins: {
            legend: {
              display: false,
              labels: {
                color: '#000001',
              }
            },
            datalabels: {
              formatter: function (value, context) {
                return value == 0 ? ""
                  : that.getFormulaComputedPropertyValue(value, dashboardz.targetFormula);
              }
            }
          }
        }
      }
      const compRef = componentFactory.create(this.injector);

      if (dashboardz.graphTypeId === 3) {

        lineChartData =
        {
          labels: [],

          datasets: [{
            data: [],
            backgroundColor: [],
            borderColor: [
              'rgba(255, 255, 255, 1)',

            ],
            borderWidth: 1

          }]
        };
        compRef.instance.barChartType = "line";
        compRef.instance.chartOptions = lineChartOptions;
        compRef.instance.chartData = lineChartData;
        console.log(dashboardz);
        const backgroundColor = [];
        
        const parentList = [];
        const labelList = dashboardz.monthList;
        dashboardz.parentGroups = uniqueListOfParents;
        const datasets = [];
        dashboardz.locationForChart.forEach((store) => {
          const dataList = [];
          for (let i = 0; i < store.stats[0].values.length; i++) {
            if (dashboardz.targetFormula.isPercentage) {
              dataList.push(this.getValueForFormulaWithIndex(dashboardz.targetFormula.formulaValue, store.stats, i).toFixed(2));
            } else {
              dataList.push(this.getValueForFormulaWithIndex(dashboardz.targetFormula.formulaValue, store.stats, i).toFixed(0));
            }
          }
          parentList.push(store.parentsName);
          if (uniqueListOfParents.length > 1 || dashboardz.locationTierFocusId === 5) {

            backgroundColor.push(uniqueListOfParents.find(x => x.name === store.parentsName).color);
          } else if (uniqueListOfParents.length === 1) {
            backgroundColor.push(this.locations.find(x => x.name === store.name).color);
          }
          let randomColor = "#"+Math.floor(Math.random() * 16777215).toString(16);
          datasets.push({
              label: store.name,  // Name of the dataset
              data: dataList,    // Data points for this dataset
              backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent background for line chart
              borderColor: store.color || randomColor, // Random color if not set
              borderWidth: 1
            });
          
        });

        
        compRef.instance.chartData = {
          datasets: datasets,
          labels: labelList
        };
        
      }

      if (dashboardz.graphTypeId === 1 || dashboardz.graphTypeId === 2) {
        barChartData =
        {
          labels: [],

          datasets: [{
            data: [],
            backgroundColor: [],
            borderColor: [
              'rgba(255, 255, 255, 1)',

            ],
            borderWidth: 1,
            borderRadius: 5,
            borderSkipped: false

          }]
        };

        if (dashboardz.graphTypeId === 2) {
          barChartData =
          {
            labels: [],

            datasets: [{
              data: [],
              backgroundColor: [],
              borderColor: [
                'rgba(255, 255, 255, 1)',

              ],
              borderWidth: 1,
              borderRadius: 5,
              borderSkipped: false

            }, {
              data: [],
              backgroundColor: [],
              borderColor: [
                'rgba(255, 255, 255, 1)',

              ],
              borderWidth: 1,
              borderRadius: 5,
              borderSkipped: false

            }]
          };
        }
        compRef.instance.chartOptions = barChartOptions;
        compRef.instance.chartData = barChartData;


        const backgroundColor = [];
        const dataList = [];
        const dataList2 = [];
        const parentList = [];
        const labelList = [];
        dashboardz.parentGroups = uniqueListOfParents;
        dashboardz.locationForChart.forEach((store) => {


          labelList.push(store.name);
          // labelList[dashChart.data.labels.length] = store.name;
          if (dashboardz.targetFormula.isPercentage) {
            dataList.push(this.getValueForFormula(dashboardz.targetFormula.formulaValue, store.stats).toFixed(2));
            dataList2.push(this.getValueForFormula2(dashboardz.targetFormula.formulaValue, store.stats).toFixed(2));
          } else {
            dataList.push(this.getValueForFormula(dashboardz.targetFormula.formulaValue, store.stats).toFixed(0));
            dataList2.push(this.getValueForFormula2(dashboardz.targetFormula.formulaValue, store.stats).toFixed(0));
          }
          parentList.push(store.parentsName);
          if (uniqueListOfParents.length > 1 || dashboardz.locationTierFocusId === 5) {

            backgroundColor.push(uniqueListOfParents.find(x => x.name === store.parentsName).color);
          } else if (uniqueListOfParents.length === 1) {
            backgroundColor.push(this.locations.find(x => x.name === store.name).color);
          }
        });

        dashboardz.originalDataForChart = {};
        dashboardz.originalDataForChart.data2 = dataList2;
        dashboardz.originalDataForChart.backgroundColor = backgroundColor;
        dashboardz.originalDataForChart.data = dataList;
        dashboardz.originalDataForChart.labels = labelList;
        dashboardz.originalDataForChart.parents = parentList;


        const arrayLabel = labelList;
        const arrayData = dataList;
        const arrayData2 = dataList2;
        const arrayColors = backgroundColor;
        const arrayParents = parentList;
        const arrayOfObj = arrayLabel.map(function (d, i) {
          return {
            label: d,
            data: arrayData[i] || 0,
            data2: arrayData2[i] || 0,
            color: arrayColors[i],
            parent: arrayParents[i]
          };
        });

        const newArrayLabel = [];
        const newArrayData = [];
        const newArrayData2 = [];
        const newArrayColor = [];
        const newArrayParents = [];
        let count = 0;
        arrayOfObj.forEach(function (d) {
          if (count < 12 || (dashboardz.graphTypeId === 2 && count < 6)) {
            newArrayLabel.push(d.label);
            newArrayData.push(d.data);
            newArrayData2.push(d.data2);
            newArrayColor.push(d.color);
            newArrayParents.push(d.parent);
            count++;
          }
        });
        compRef.instance.chartData.datasets[0].backgroundColor = newArrayColor;
        compRef.instance.chartData.labels = newArrayLabel;
        compRef.instance.chartData.datasets[0].data = newArrayData;
        if (dashboardz.graphTypeId === 2) {
          compRef.instance.chartData.datasets[1].data = newArrayData2;
          compRef.instance.chartData.datasets[1].backgroundColor = newArrayColor;
        }

        
      }
      this.appRef.attachView(compRef.hostView);
      mycanvas.appendChild((compRef.hostView as any).rootNodes[0]);
      dashboardz.dashChart = compRef;
    });



  }

  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  formattedValue(number: number) {
    return Math.floor(number).toLocaleString();
  }

  getFormulaComputedPropertyValue(value: number, formula: any) {

    var trendingValue = 1;
    return (formula.isMoney) ? ((trendingValue) ? '$' + this.formattedValue((value)) :
      '$' + this.formattedValue((value)))
      : (formula.isPercentage) ? ((trendingValue) ?
        this.formattedValue((value * 100)) + '%' :
        this.formattedValue((value * 100)) + '%')
        : ((trendingValue) ? this.formattedValue(value) : this.formattedValue(value));
  }

  getDashboards() {

    let datestring = "0";
    if (!this.overrideObject.isDefault) {
      datestring = this.overrideObject.monthlyDate;
    }
    this._loaderService.show();
    this._dashboardService.getDashboardsByUser(this._authService.userId(), datestring).subscribe((response: Dashboard[]) => {
      this.dashboards = response;

      setTimeout(() => { this.generateChart(); }, 300);



    }, (error) => { this._notifier.notify('error', error.Message); this._loaderService.hide(); }, () => { setTimeout(() => { this._loaderService.hide(); }, 1000); });

  }

  generateNewDataset() {
    return {
      data: [],
      backgroundColor: '',
      borderColor: [
        'rgba(255, 255, 255, 1)',
      ],
      borderWidth: 2
    };
  }







  AddSelectedLocationList(nodeData: Location, addOrRemove: boolean) {
    const key = nodeData.id;

    if (nodeData.locationTypeCodeId === 5) {
      if (addOrRemove) {
        this.dashboard.employees.push(this.employees.find(loc => loc.id === nodeData.id));


      } else {
        const filteredArray = this.dashboard.employees.filter((element) => {
          return element.id !== nodeData.id;

        });
        this.dashboard.employees = filteredArray;

      }
    } else {


      if (addOrRemove) {

        this.dashboard.locations.push(this.locations.find(loc => loc.id === nodeData.id));


      } else {
        const filteredArray = this.dashboard.locations.filter((element) => {
          return element.id !== nodeData.id;

        });


        this.dashboard.locations = filteredArray;

      }
    }


  }

  onLocationSelected(location: Location) {
    location.selected = !location.selected;
    if (location.selected) {

      this.AddSelectedLocationList(location, true);
      // for (const dog in location.children) {
      this.updateChildrenAfterSelect(location);
      // }
    } else {

      this.AddSelectedLocationList(location, false);
      // for (const dog in location.children) {

      this.updateChildrenAfterDeselect(location);
      // }
    }
  }

  updateChildrenAfterDeselect(location: Location) {
    for (const dog in location.children) {
      if (location.children[dog].selected) {
        location.children[dog].isVisible = true;


      } else {
        location.children[dog].isVisible = true;
        this.updateChildrenAfterDeselect(location.children[dog]);
      }


    }
  }

  updateChildrenAfterSelect(location: Location) {

    location.children.forEach((child) => {
      child.isVisible = false;
      this.updateChildrenAfterSelect(child);
    });
  }

  searchLocation(array: any = null) {
    array = array ? array : this.locations2[0];

    for (const key in array) {

      if (key === 'name') {
        if (array.name.toLowerCase().indexOf(this.locationSearchString.toLowerCase()) > -1) {
          array.isFiltered = false;
        } else {
          array.isFiltered = true;
        }
        array.children.forEach((child) => { this.searchLocation(child); });
      }

    }
  }







  addDashboard() {
    this.initializeDialogControls(false);
    this.dashboard = {
      skuGroupOrFormula: false,
      name: '',
      locations: [],
      employees: [],
      graphTypeId: 1,
      dateTypeId: 1,
      locationTierFocusId: 3,
      formulaId: 1
    };
    setTimeout(() => { this.trimFullTree(); }, 500);

    this.dialog.isOpen = true;
    this.dialog.headerText = 'Add Dashboard Graph';


    this.currentAction = Action.Create;
  }

  saveDashboard() {
    this._loaderService.show();
    switch (this.currentAction) {
      case Action.Create:
        this._dashboardService.createDashboard(this._authService.userId(),
          this.dashboard.name, this.dashboard.skuGroupOrFormula,
          this.dashboard.formulaId, this.dashboard.graphTypeId,
          this.dashboard.dateTypeId, this.dashboard.locationTierFocusId,
          this.dashboard.locations, this.dashboard.employees)
          .subscribe((response: number) => {
            this.dialog.isOpen = false;

            this.getDashboards();
            // this.dashboards = response.dashboards;
            // this.generateChart();
            // this.getSkuGroups();

            this._notifier.notify('success', 'Dashboard created successfully.');
          }, (error) => this._notifier.notify('error', error.message), () => { this._loaderService.hide(); });
        break;
      case Action.Update:
        this._dashboardService.updateDashboard(this.dashboard.id, this._authService.userId(),
          this.dashboard.name, this.dashboard.skuGroupOrFormula,
          this.dashboard.formulaId, this.dashboard.graphTypeId,
          this.dashboard.dateTypeId, this.dashboard.locationTierFocusId,
          this.dashboard.locations, this.dashboard.employees)
          .subscribe((response: number) => {
            this.dialog.isOpen = false;

            this.getDashboards();
            // this.dashboards = response.dashboards;
            // this.generateChart();
            // this.getSkuGroups();

            this._notifier.notify('success', 'Dashboard created successfully.');
          }, (error) => this._notifier.notify('error', error.message), () => { this._loaderService.hide(); });
        break;
    }
    this._loaderService.hide();
  }

  openLocationTree() {
    this.locationSelect.isOpen = true;
    this.locationSelect.headerText = 'Right Click to Focus on Location';

    this.depthview = [];
    const loctier = this.dashboard.locationTierFocusId;
    for (let x = 2; x < loctier; x++) {
      this.depthview.push(this.deptharray[x - 2]);
    }
    this.depthSelection = this.deptharray[this.dashboard.locationTierFocusId - 3].value;
    const cancelZoom = (d) => {
      if (this.zoomed) {
        this.trimFullTree();
        this.buildLocationTreeInterface();
        this.zoomed = false;
      }
      document.getElementById('cancelZoom').addEventListener('contextmenu',
        function (ev) {
          ev.preventDefault();
          cancelZoom(ev);
        });
    };
  }

  getValueForFormulaWithIndex(formulaObject: any, stats: any, index: number) {

    const formulaElements = formulaObject.split(',');
    let OpenCloseCount = 0;
    let stringFormula = '';
    let result = 0;
    for (let i = 0; i < formulaElements.length; i++) {


      const currentWord = formulaElements[i];
      const nextWord = (i < formulaElements.length - 1) ? formulaElements[i + 1] : '';
      if (currentWord === '(') {
        OpenCloseCount = OpenCloseCount + 1;
        stringFormula += currentWord;
      } else if (currentWord === ')' && OpenCloseCount > 0) {
        OpenCloseCount = OpenCloseCount - 1;
        stringFormula += currentWord;
      } else if (currentWord === 'QTY') {
        const skuGroup = stats.find(s => s.name === nextWord && s.gpOrQty === 2);
        if (skuGroup && skuGroup.values[index] >= 0) {
          stringFormula += '(' + skuGroup.values[index] + ')';
        } else {
          stringFormula = '0';
          break;
        }
      } else if (currentWord === 'GP') {
        const skuGroup = stats.find(s => s.name === nextWord && s.gpOrQty === 1);
        if (skuGroup && skuGroup.values[index] >= 0) {
          stringFormula += '(' + skuGroup.values[index] + ')';
        } else {
          stringFormula = '0';
          break;
        }
      }
      else if (currentWord === 'REBIZ') {
        const skuGroup = stats.find(s => s.name === nextWord && s.gpOrQty === 3);
        stringFormula += '(' + skuGroup.values[index] + ')';
      }
      else if (currentWord === 'HOURS') {
        const skuGroup = stats.find(s => s.name === nextWord && s.gpOrQty === 4);
        stringFormula += '(' + skuGroup.values[index] + ')';
      }
      else if (currentWord === '+' || currentWord === '-' || currentWord === '*' || currentWord === '/') {
        stringFormula += currentWord;
      }
    }
    try {
      result = this.evaluate(stringFormula);
    } catch (e) {
      result = 0;
    }

    if (isNaN(result)) {
      result = 0;
    }

    return result;
  }

  getValueForFormula(formulaObject: any, stats: any) {



    const formulaElements = formulaObject.split(',');
    let OpenCloseCount = 0;
    let stringFormula = '';
    let result = 0;
    for (let i = 0; i < formulaElements.length; i++) {


      const currentWord = formulaElements[i];
      const nextWord = (i < formulaElements.length - 1) ? formulaElements[i + 1] : '';
      if (currentWord === '(') {
        OpenCloseCount = OpenCloseCount + 1;
        stringFormula += currentWord;
      } else if (currentWord === ')' && OpenCloseCount > 0) {
        OpenCloseCount = OpenCloseCount - 1;
        stringFormula += currentWord;
      } else if (currentWord === 'QTY') {
        const skuGroup = stats.find(s => s.name === nextWord && s.gpOrQty === 2);
        if (skuGroup && skuGroup.value >= 0) {
          stringFormula += '(' + skuGroup.value + ')';
        } else {
          stringFormula = '0';
          break;
        }
      } else if (currentWord === 'GP') {
        const skuGroup = stats.find(s => s.name === nextWord && s.gpOrQty === 1);
        if (skuGroup && skuGroup.value >= 0) {
          stringFormula += '(' + skuGroup.value + ')';
        } else {
          stringFormula = '0';
          break;
        }
      }
      else if (currentWord === 'REBIZ') {
        const skuGroup = stats.find(s => s.name === nextWord && s.gpOrQty === 3);
        stringFormula += '(' + skuGroup.value + ')';
        //switch (nextWord) {
        //  case 'Boxes':
        //    stringFormula += '(' + conversionData.boxes + ')';
        //    break;
        //  case 'Traffic':
        //    stringFormula += '(' + conversionData.traffic + ')';
        //    break;
        //  case 'GrossProfit':
        //    stringFormula += '(' + conversionData.grossProfit + ')';
        //    break;
        //  default:
        //    stringFormula += 0;
        //    break;
        //}
      }
      else if (currentWord === 'HOURS') {
        const skuGroup = stats.find(s => s.name === nextWord && s.gpOrQty === 4);
        stringFormula += '(' + skuGroup.value + ')';
      }
      else if (currentWord === '+' || currentWord === '-' || currentWord === '*' || currentWord === '/') {
        stringFormula += currentWord;
      }
    }
    try {
      result = this.evaluate(stringFormula);
    } catch (e) {
      result = 0;
    }

    if (isNaN(result)) {
      result = 0;
    }

    return result;
  }

  getValueForFormula2(formulaObject: any, stats: any) {



    const formulaElements = formulaObject.split(',');
    let OpenCloseCount = 0;
    let stringFormula = '';
    let result = 0;
    for (let i = 0; i < formulaElements.length; i++) {


      const currentWord = formulaElements[i];
      const nextWord = (i < formulaElements.length - 1) ? formulaElements[i + 1] : '';
      if (currentWord === '(') {
        OpenCloseCount = OpenCloseCount + 1;
        stringFormula += currentWord;
      } else if (currentWord === ')' && OpenCloseCount > 0) {
        OpenCloseCount = OpenCloseCount - 1;
        stringFormula += currentWord;
      } else if (currentWord === 'QTY') {
        const skuGroup = stats.find(s => s.name === nextWord && s.gpOrQty === 2);
        if (skuGroup && skuGroup.value2 >= 0) {
          stringFormula += '(' + skuGroup.value2 + ')';
        } else {
          stringFormula = '0';
          break;
        }
      } else if (currentWord === 'GP') {
        const skuGroup = stats.find(s => s.name === nextWord && s.gpOrQty === 1);
        if (skuGroup && skuGroup.value2 >= 0) {
          stringFormula += '(' + skuGroup.value2 + ')';
        } else {
          stringFormula = '0';
          break;
        }
      } else if (currentWord === 'REBIZ') {
        const skuGroup = stats.find(s => s.name === nextWord && s.gpOrQty === 3);
        stringFormula += '(' + skuGroup.value2 + ')';
        //switch (nextWord) {
        //  case 'Boxes':
        //    stringFormula += '(' + conversionData.boxes + ')';
        //    break;
        //  case 'Traffic':
        //    stringFormula += '(' + conversionData.traffic + ')';
        //    break;
        //  case 'GrossProfit':
        //    stringFormula += '(' + conversionData.grossProfit + ')';
        //    break;
        //  default:
        //    stringFormula += 0;
        //    break;
        //}
      }
      else if (currentWord === 'HOURS') {
        const skuGroup = stats.find(s => s.name === nextWord && s.gpOrQty === 4);
        stringFormula += '(' + skuGroup.value2 + ')';
      }
      else if (currentWord === '+' || currentWord === '-' || currentWord === '*' || currentWord === '/') {
        stringFormula += currentWord;
      }
    }
    try {
      result = this.evaluate(stringFormula);
    } catch (e) {
      result = 0;
    }

    if (isNaN(result)) {
      result = 0;
    }

    return result;
  }


  evaluate(code: string) {
    const f = new Function('return ' + code);
    return f();
  }






  markSelectedLocations(lo) {

    const newResponsewow = lo;
    const locationArray = this.dashboard.locations.find((element) => element.id === lo.id && lo.locationTypeCodeId < 5);
    const employeeArray = this.dashboard.employees.find((element) => element.id === lo.id && lo.locationTypeCodeId === 5);

    if (locationArray) {
      lo.selected = true;
    } else if (employeeArray) {
      lo.selected = true;
    } else {
      lo.selected = false;
    }

    const newChildren = [];
    if (typeof lo.children !== 'undefined') {


      lo.children.forEach((c) => {
        c = this.markSelectedLocations(c);
      });


    } else {
      lo.children = [];
    }
    return lo;
  }

  editDashboard(dashboard: Dashboard) {
    this.dialog.isOpen = true;
    this.dialog.headerText = 'Edit Formula';
    this.currentAction = Action.Update;
    this.dashboard = dashboard;
    let that = this;
    setTimeout(function () {
      that.trimFullTree();
      that.initializeDialogControls(true);
    }, 100);
  }

  preEditPostEditFunction() {
    //console.log('CLOSED OUT!');
    //console.log(this.dashboard);
    // this.dashboard.locations.forEach((d) => { this.dashboard.locationById.push(d.id); });
    // this.dashboard.employees.forEach((d) => { this.dashboard.employeeById.push(d.id); });
    this.trimFullTree();
    // this.locations2 = [this.markSelectedLocations(this.locationTreeToView)];
    //console.log(this.locationTreeToView);

  }


  deleteDashboard(dashboard) {
    if (!confirm('Are you sure you want to delete this formula?')) {
      return false;
    }

    this._dashboardService.deleteDashboard(dashboard.id)
      .subscribe((response: boolean) => {
        this.dialog.isOpen = false;
        this.getDashboards();
        this._notifier.notify('success', 'Formula deleted successfully.');
      }, (error) => this._notifier.notify('error', error.message), () => { });
  }


  trimBottomOfTree(l: Location) {
    (l.locationTypeCodeId < this.dashboard.locationTierFocusId) ?
      l.children.forEach((c) => { c = this.trimBottomOfTree(c); })
      : l.children = [];
    return l;
  }
  trimTopOfTree(l: Location, array: Location) {

    if (array.id === l.id && l.locationTypeCodeId !== 5) {
      this.locationTreeToView = l;
    } else {
      l.children.forEach((child) => this.trimTopOfTree(child, array));
    }

  }

  something(location: Location) {

    let newLocation: Location = location;

    while (this.topLocationToShow < newLocation.locationTypeCodeId) {
      newLocation = this.locations.find(x => x.id === newLocation.parentId);
    }
    return newLocation;
  }


  trimFullTree(topLocation?) {
    //console.log('trimTree');

    this.locationTreeToView = this.cloneLocationTree(this.locationsForSelectTree[0]);
    this.trimBottomOfTree(this.locationTreeToView);
    // this.trimTopOfTree(this.locationTreeToView, false);
    if (topLocation) {
      this.trimTopOfTree(this.locationTreeToView, topLocation);
    } else if (this.presentUser) {
      const response: Location = this.something(this.presentUser.location);

      this.trimTopOfTree(this.locationTreeToView, response);
    }

    //    this.locations2 = [this.locationTreeToView];
    this.locations2 = [this.markSelectedLocations(this.locationTreeToView)];

  }


  cloneLocationTree(obj) {
    if (obj === null || typeof (obj) !== 'object') {
      return obj;
    }
    const temp = new obj.constructor();
    for (const key in obj) {
      if (true) {
        temp[key] = this.cloneLocationTree(obj[key]);
      }
    }
    return temp;
  }







  getClients() {
    this._clientService.getAllClients()
      .subscribe((response: Client[]) => {
        this.clients = response;
      }, (error) => this._notifier.notify('error', error.error), () => { });
    // this.clients = this._clientService.getAllClients();
  }

  getLocations() {
    this._locationService.getAllLocations()
      .subscribe((response: Location[]) => {
        this.locations = response;
      }, (error) => this._notifier.notify('error', error.message), () => { });

  }

  getEmployees() {
    this._employeeService.getAllEmployees()
      .subscribe((response: Employee[]) => {
        this.employees = response;
        this.presentUser = response.find
          (emp => emp.userId.toString() === this.userId.toString());
      }, (error) => this._notifier.notify('error', error.message), () => { });
  }

  getFormulas() {
    this._formulaService.getFormulas(+this.clientId, (response) => {
      this.formulas = response;
    }, true);
  }

  getSkuGroups() {
    this._skuGroupService.getSkuGroupsByClient(+this.clientId, (response: SkuGroup[]) => {
      this.skuGroups = response;
    });
  }

  getLocationFullTree() {
    const tierId = 5;
    this._locationService.getLocationTree(+this.selectedClientId, tierId)
      .subscribe((response: Location[]) => {
        this.locationsForSelectTree = response;
      }, (error) => this._notifier.notify('error', error.error), () => { });
  }

  getInventoryTree() {
    const tierId = 5;
    this._inventoryService.getTreeWithCostValuesForStore()
      .subscribe((response: any[]) => {
        this.inventoryData = response;
      }, (error) => this._notifier.notify('error', error.error), () => { });
  }


  drop(event: CdkDragDrop<any>) {

    this.dashboards[event.previousContainer.data.index] = event.container.data.item;
    this.dashboards[event.container.data.index] = event.previousContainer.data.item;
    let currentIndex = event.container.data.index;
    let previousIndex = event.previousContainer.data.index

    if (currentIndex == previousIndex)
      return;
    const dashboardPositionList: any[] = [];
    dashboardPositionList.push({ 'id': this.dashboards[event.container.data.index].id, 'position': currentIndex });
    dashboardPositionList.push({ 'id': this.dashboards[event.previousContainer.data.index].id, 'position': previousIndex });

    this.dashboards[event.previousContainer.data.index].position = currentIndex;
    this.dashboards[event.container.data.index].position = previousIndex;

    this._dashboardService.updateDashboardPosition(dashboardPositionList).subscribe(() => { }, (error) => this._notifier.notify('error', error.message), () => { });

  }
  ColorLuminance(hex, lum) {

    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    let rgb = '#', c, i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ('00' + c).substr(c.length);
    }

    return rgb;
  }

  dashboardClient: any;
  dashboardFormula: any;
  dashboardSkuGroups: any;
  initializeDialogControls(isEdit) {
    if (!isEdit) {
      this.dashboardClient = new FormControl(this.selectedClientId, [Validators.required]);
      this.dashboardFormula = new FormControl("", [Validators.required]);
      this.dashboardSkuGroups = new FormControl("", [Validators.required]);
    }
    else {
      this.dashboardClient = new FormControl(this.selectedClientId, [Validators.required]);
      this.dashboardFormula = new FormControl(this.dashboard.formulaId, [Validators.required]);
      this.dashboardSkuGroups = new FormControl(this.dashboard.skuGroupId, [Validators.required]);
    }
  }

  getErrorMessage(controlName) {
    switch (controlName) {
      case "dashboardClient": return this.dashboardClient.hasError('required') ? 'Client is required' : '';
      case "dashboardFormula": return this.dashboardFormula.hasError('required') ? 'Formula(s) are required' : '';
      case "dashboardSkuGroups": return this.dashboardSkuGroups.hasError('required') ? 'SkuGroup(s) are required' : '';
    }
  }

  checkError(controlName) {
    return this[controlName].hasError('required');
  }

  getTextToShowInDropdown() {
    var textToShow = '';
    var selectedSkuGroups = this.skuGroups.filter(x => x['isSelected'] == true).map(y => y.name);
    if (selectedSkuGroups.length > 1) {
      textToShow = selectedSkuGroups[0] + "(+" + selectedSkuGroups.length + (selectedSkuGroups.length === 2 ? ' other' : ' others') + ')';
    }
    else {
      textToShow = selectedSkuGroups[0];
    }
    return textToShow;
  }

  // data = this._inventoryService.getTreeWithCostValuesForStore();
}




