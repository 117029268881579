import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../../core/services/auth.service';
import { MtdReportService } from '../services/mtd-report.service';
import { LocationService } from '../services/location.service';
import { SkuGroupService } from '../services/sku-group.service';
import { InvoiceViewerService } from '../services/invoice-viewer.service';
import { UploadStatusService } from '../services/upload-status.service';
import { LocationTypeCode } from '../models/location-type-code';
import { MTDReport } from '../models/mtd-report';
import { UploadStatus } from '../models/upload-status';
import { SkuGroup } from '../models/sku-group';
import { Subscription } from 'rxjs/internal/Subscription';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DurationService } from '../services/duration.service';
import { Duration } from '../models/duration';
import { InvoiceViewerSearchRequest } from '../models/invoice-viewer-search-request';
import { DateRange } from '../models/date-range';
import { MatSelectChange } from '@angular/material/select';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { LoaderService } from '../services/loader.service';

@Component({
  selector: 'app-invoice-viewer',
  templateUrl: './invoice-viewer.component.html'
})
export class InvoiceViewerComponent implements OnInit, OnDestroy {
  selectedHasNotFilter = '0';
  selectedHasFilter = '0';
  skuGroups: SkuGroup[] = [];
  invoicesUnedited: any[] = [];
  invoicesHalfFiltered: any[] = [];
  invoices: any[] = [];
  invoice: any;
  selectedInvoice: any;
  clients: any[] = [];
  invoiceSearchRequest = new InvoiceViewerSearchRequest();
  regions: any[];
  clientId: number;
  selectedClientId: number;
  selectedClient: any;
  selectedRegionId: number;
  selectedRegion: any;
  districts: any[];
  selectedDistrictId: number;
  selectedDistrict: any;
  stores: any[];
  selectedStoreId: number;
  selectedStore: any;
  employees: any[];
  selectedEmployeeId: number;
  selectedEmployee: any;
  lastSelectedDurationForStoreAndEmployeeReport: any;
  singleOtherDurationSelection: boolean;
  multipleOtherDurationSelection: boolean;
  customDurationSelection: boolean;
  fetchReportInProgress: boolean;
  selectedOtherDuration: any;
  customRangeStartDate: any;
  customRangeEndDate: any;
  uploadStatus: string;
  localStorageLoadingInProgress: boolean;
  loadingStarted = false;
  initComplete = false;
  isAscendingOrder = true;
  clientName: string;
  regionName: string;
  districtName: string;
  storeName: string;
  table: any = {
    sorting: true,
    toggleColumns: true
  };
  clientIsSelected = false;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  debug = false;
  subscriptionToClients: Subscription;
  subscriptionToRegions: Subscription;
  subscriptionToDistricts: Subscription;
  subscriptionToStores: Subscription;
  subscriptionToEmployees: Subscription;

  months: any;
  durations: Duration[];
  duration: number = 0;
  selectedDuration: Duration;
  selectedMonth: any;
  dataSource = new MatTableDataSource();
  searchTerm: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  startDate: Date;
  endDate: Date;
  minDate: Date;
  maxDate: Date;
  selectedRow = new Set<any>();
  tableready = false;
  today = new Date(); 

  constructor(private _notifier: NotifierService,
    private _locationService: LocationService,
    private _mtdReportService: MtdReportService,
    private _uploadStatusService: UploadStatusService,
    private _invoiceViewerService: InvoiceViewerService,
    private _skuGroupService: SkuGroupService,
    private _authService: AuthService,
    private _durationService: DurationService,
    private _loaderService: LoaderService) { }

  ngOnInit() {
    if (this.debug) { console.log("Invoice Viewer : INIT"); }
    if (this.debug) { console.log("Are we subscribed to Client?! "); }
    if (this.debug) { console.log(this.subscriptionToClients); }

    this.subscriptionToClients = this._locationService.currentActualClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        if (this.debug) { console.log('actual client ' + message); }
        this.onClientSelected(+message);
      });

    this.subscriptionToRegions = this._locationService.currentRegion
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        if (this.debug) { console.log('actual region ' + message); }
        this.selectedRegionId = message;
        this.delayedInvoiceLoad();
      });
    this.subscriptionToDistricts = this._locationService.currentDistrict
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        if (this.debug) { console.log('actual district ' + message); }
        this.selectedDistrictId = message;
        this.delayedInvoiceLoad();
      });
    this.subscriptionToStores = this._locationService.currentStore
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        if (this.debug) { console.log('actual store ' + message); }
        this.selectedStoreId = message;
        this.delayedInvoiceLoad();
      });
    this.subscriptionToEmployees = this._locationService.currentEmployee
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        if (this.debug) { console.log('actual employee ' + message); }
        this.selectedEmployeeId = message;
        this.delayedInvoiceLoad();
      });

    this.getDurations();
    this._locationService.getLocations(LocationTypeCode.Client, (clientResponse) => {
      this.clients = clientResponse;
    });

    this.durations = this._durationService.getDuration();
    this.months = this._durationService.getMonths();
    this.resetSearchRequest();
    this.selectedDuration = this.durations.find(x => x.value === 0);
    this.selectedDuration.duration = this.selectedDuration.duration;
    //this.getInvoices();
  }

  wait(ms) {
    return new Promise((resolve, reject) => setTimeout(resolve, ms));
  }

  async initLoader() {
    let loader = false;
    if (this.debug) { console.log("initLoader Called"); }
    while (!loader) {
      await this.wait(250);
      if ((this.selectedEmployeeId !== null && this.selectedStoreId !== null && this.selectedDistrictId !== null && this.selectedRegionId !== null)) {
        if (this.debug) { console.log("Invoice Viewer: No Location is Null"); }
        this.initComplete = true;
        loader = true;

        this.delayedInvoiceLoad();
      }
    }
  }

  delayedInvoiceLoad() {
    setTimeout(() => {
      if (this.debug) { console.log("Delayed Invoice"); }
      if (!this.loadingStarted && this.initComplete) {
        this.loadingStarted = true;
        this.getInvoices();
      } else {
      }

    }, 150);
  }

  resetSearchRequest() {
    this.invoiceSearchRequest.HasFilter = '0';
    this.invoiceSearchRequest.HasNotFilter = '0';
    this.invoiceSearchRequest.HideZeroGrossProfitItems = true;
    this.invoiceSearchRequest.SearchTerm = '';
    this.invoiceSearchRequest.DateRange = new DateRange();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
    this.subscriptionToClients.unsubscribe();
    this.subscriptionToDistricts.unsubscribe();
    this.subscriptionToRegions.unsubscribe();
    this.subscriptionToStores.unsubscribe();
    this.subscriptionToEmployees.unsubscribe();
  }

  updateDataSource(data) {
    this.dataSource = new MatTableDataSource(data);
    setTimeout(() => { this.dataSource.paginator = this.paginator; this.dataSource.sort = this.sort; });
    this.dataSource.sortingDataAccessor = (item: any, property: string) => {
      switch (property) {
        case 'soldOnNoYear':
          return new Date(item.sortDate);
        default:
          return item[property];
      }
    };
    this.selectedInvoice = data[0];
  }

  durationChanged(event: MatSelectChange) {
    this.searchTerm = "";
    this.updateDataSource([]);
    this.selectedDuration = this.durations.find(x => x.value === event.value);
    this.selectedDuration.duration = this.selectedDuration.duration;
    this.resetSearchRequest();

    if (this.duration != 5 && this.duration != 6) {
      this.getInvoices();
    }
  }

  startDateChanged(type: string, event: MatDatepickerInputEvent<Date>) {
    this.startDate = event.value;
    this.selectedDuration.duration.FromDate = this._durationService.getMomentByDate(this.startDate).format(this._durationService.dateStartFormat).toString();
  }

  endDateChanged(type: string, event: MatDatepickerInputEvent<Date>) {
    this.endDate = event.value;
    this.selectedDuration.duration.ToDate = this._durationService.getMomentByDate(this.endDate).format(this._durationService.dateEndFormat).toString();
    this.getInvoices();
  }

  monthChanged(event: MatSelectChange) {
    this.selectedMonth = this.months.months.indexOf(event.value);
    var dateRange = this._durationService.getDateRangeByMonth(this.selectedMonth);
    this.selectedDuration.duration.FromDate = dateRange.start.format(this._durationService.dateStartFormat).toString();
    this.selectedDuration.duration.ToDate = dateRange.end.format(this._durationService.dateEndFormat).toString();
    this.getInvoices();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  columnDefinationsInvoice = [
    { name: "Product SKU", isSortable: true, mappedToProperty: "productSku", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Product Name", isSortable: true, mappedToProperty: "productName", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Tracking #", isSortable: true, mappedToProperty: "trackingNumber", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Qty", isSortable: true, mappedToProperty: "quantity", class: "price", isFilterable: true, sticky: false, hide: false },
    { name: "Your Price", isSortable: true, mappedToProperty: "soldFor", class: "price", isFilterable: true, sticky: false, hide: false },
    { name: "Gross Profit", isSortable: true, mappedToProperty: "grossProfit ", class: "price", isFilterable: true, sticky: false, hide: false },
  ];

  getColumnDefinationInvoiceByMappedToProperty(mappedToProperty) {
    var column = this.columnDefinationsInvoice.find(x => x.mappedToProperty == mappedToProperty);
    return column;
  }

  getDisplayedColumnsInvoice(): string[] {
    return this.columnDefinationsInvoice.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
  }

  getTextBydataInvoice(element, columnDef) {
    var textToShow = '';
    switch (columnDef.name) {
      case "Product SKU":
        textToShow = element.productCatalog.productSku;
        break;
      case "Product Name":
        textToShow = element.productCatalog.productName;
        break;
      case "Your Price":
        textToShow = this.convertNumberToString(element.soldFor);
        break;
      case "Gross Profit":
        textToShow = this.convertNumberToString(element.grossProfit);
        break;
      default:
        textToShow = element[columnDef.mappedToProperty];
    }
    return textToShow;
  }

  columnDefinations = [
    { name: "Invoice#", isSortable: true, mappedToProperty: "invoiceNumber", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Store", isSortable: true, mappedToProperty: "storeName", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Date", isSortable: true, mappedToProperty: "soldOnNoYear", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Sold By", isSortable: true, mappedToProperty: "employeeName", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Customer", isSortable: true, mappedToProperty: "customerName", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Gross Profit", isSortable: true, mappedToProperty: "grossProfit", class: "price", isFilterable: true, sticky: false, hide: false },
  ];

  getColumnDefinationByMappedToProperty(mappedToProperty) {
    var column = this.columnDefinations.find(x => x.mappedToProperty == mappedToProperty);
    return column;
  }

  getDisplayedColumns(): string[] {
    return this.columnDefinations.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
  }

  selectedColumns = this.getDisplayedColumns();
  columnsChanged(event: MatSelectChange) {
    for (var i = 0; i < this.columnDefinations.length; i++) {
      var column = this.selectedColumns.find(x => x == this.columnDefinations[i].mappedToProperty);
      if (column != null) {
        this.columnDefinations[i].hide = false;
      } else {
        this.columnDefinations[i].hide = true;
      }
    }
  }

  getTextBydata(element, columnDef) {
    var textToShow = '';
    switch (columnDef.name) {
      case 'Gross Profit':
        textToShow = '$' + element[columnDef.mappedToProperty].toFixed(2);
        break;
      default:
        textToShow = element[columnDef.mappedToProperty];
    }
    return textToShow;
  }

  filterByGrossProfit(collection, key) {
    return collection.filter((object) => {
      return object[key] != 0;
    })
  }

  updateDataByFilter() {
    this.invoices = this.invoicesUnedited;
    if (this.debug) { console.log("Update Data by filter"); }
    if (+this.invoiceSearchRequest.HasFilter !== 0) {
      this.invoices = this.invoices.filter(iu => iu.skuGroups.find(sk => sk.name === this.invoiceSearchRequest.HasFilter).qty !== 0);
    }
    if (+this.invoiceSearchRequest.HasNotFilter !== 0) {
      this.invoices = this.invoices.filter(i => i.skuGroups.find(sk => sk.name === this.invoiceSearchRequest.HasNotFilter).qty === 0);
    }
    if (this.invoiceSearchRequest.HideZeroGrossProfitItems == true) {
      this.invoices = this.invoices.filter((object) => {
        return object["grossProfit"] != 0;
      });
    }
    this.updateDataSource(this.invoices);
  }

  selectInvoice(invoice) {
    this.selectedInvoice = invoice;
  }

  getInvoices() {
    if (this.debug) { console.log("Getting Invoices"); }
    let locationToSend = +this.selectedEmployeeId;
    if (+this.selectedEmployeeId === 0) {
      locationToSend = this.selectedStoreId;
    }
    if (this.selectedStoreId === 0) {
      locationToSend = this.selectedDistrictId;
    }
    if (this.selectedDistrictId === 0) {
      locationToSend = this.selectedRegionId;
    }
    if (this.selectedRegionId === 0) {
      locationToSend = this.selectedClientId;
    }
    let duration = null;
    if (this.selectedDuration == null || this.selectedDuration.duration == null) {
      //this._loaderService.hide(); 
      return;
    }
    this.tableready = false;
    this._loaderService.show();
    duration = this.selectedDuration.duration.FromDate + "|" + this.selectedDuration.duration.ToDate;
    if (this.selectedEmployeeId && +this.selectedEmployeeId !== 0) {
      this._invoiceViewerService.getInvoiceReportForSingleUser(duration, this.selectedEmployeeId)
        .subscribe((response: any) => {
          this.invoicesUnedited = response;
          this.invoices = response;
          this.updateDataByFilter();
          this.tableready = true;
        }, (error) => { this._notifier.notify('error', error.error); }, () => { setTimeout(() => { this._loaderService.hide(); this.loadingStarted = false; }, 100); });
    } else if (locationToSend && +this.selectedEmployeeId === 0) {
      this._invoiceViewerService.getInvoiceReport(+locationToSend, duration)
        .subscribe((response: any) => {
          this.invoicesUnedited = response;
          this.invoices = response;
          this.updateDataByFilter();
          this.tableready = true;
        }, (error) => { this._notifier.notify('error', error.error); }, () => { setTimeout(() => { this._loaderService.hide(); this.loadingStarted = false; }, 100); });
    }

  }

  convertNumberToString(amount: number) {
    let response = '';
    response = amount.toFixed(2).toString();
    if (Math.sign(amount) === -1) {
      response = response.replace('-', '($') + ')';
    } else {
      response = '$' + response;
    }
    return response;
  }

  getSkuGroups() {
    if (this.debug) { console.log("SkuGroups Load Called"); }
    this._skuGroupService.getSkuGroupsByClient(this.clientId, (response: SkuGroup[]) => {
      this.skuGroups = response;
      this.initLoader();
    });
  }


  onClientSelected(clientId: number) {
    if (this.debug) { console.log("Check if Client is the same" + clientId + " --  " + this.clientId); }
    if (!this.clientIsSelected && clientId && clientId !== 0 && clientId !== this.clientId) {
      this.clientIsSelected = true;
      if (this.debug) { console.log("Client Validated and Start Loading"); }
      this.selectedClientId = +clientId;
      this.clientId = +clientId;
      this.getSkuGroups();
    }
  }




  getDurations() {
    this._mtdReportService.getDurations(MTDReport.Store, (response) => {
      this.durations = response;
      if (this.lastSelectedDurationForStoreAndEmployeeReport) {
        this.selectedDuration = this.lastSelectedDurationForStoreAndEmployeeReport;
      } else {
        this.selectedDuration = this.lastSelectedDurationForStoreAndEmployeeReport = this._mtdReportService.currentDayDuration;
      }
    });
  }

  getFormattedDateTime(unformattedDateTime) {
    this.uploadStatus = `${moment(unformattedDateTime).format('L')} ${moment(unformattedDateTime).format('LT')}`;
  }

  getUploadStatus() {
    this._uploadStatusService.getUploadStatus(this.selectedClientId)
      .subscribe((response: UploadStatus) => {
        this.getFormattedDateTime(response.time);
      }, (error) => this._notifier.notify('error', error.error), () => { });
  }
}
