import { Formula } from './formula';


export class ReportTableGroupColumn {
  id?: number;
  displayPosition?: number;
  reportTableGroupId: number;
  formulaId: number;
  formula?: Formula;
  groupALowestRank?: number;
  groupBLowestRank?: number;
  redValueGroup1?: number;
  redValueGroup2?: number;
  nameValueOverride?: string;
}
