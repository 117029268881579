<style>
  tr.yellowBackGround td {
    background-color: #ffd800 !important;
    color: black !important;
    z-index: 1;
    position: relative;
  }

  .forecast-graph-container {
    margin-top: 1em;
  }

  @media (min-device-width: 767px) and (max-device-width: 1025px) {
    .flex-grow {
      flex-grow: 1;
    }
  }
</style>
<div class="box-typical box-typical-padding">

  <form>
    <div class="row">
      <div class="col-sm-2">
        <mat-form-field >
          <mat-label class="form-label semibold" style="max-width:100px">Duration</mat-label>
          <input matInput type="month" style="max-width:150px" name="selectedDuration" [(ngModel)]="selectedDuration" (change)="onDurationChanged();">
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="" style=" display: flex; flex-flow: wrap;">
    <div class="flex-grow">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Group</th>
            <th>Base(%)</th>
            <th>Earned</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let group of groups; let i=index" role="row" (click)="selectGroup(group)"
              [ngClass]="{'yellowBackGround' : selectedGroup && selectedGroup.id == group.id}">
            <td>{{group.name}}</td>
            <td>{{(group.basePercentage * 100) | number : '1.2-2'}} %</td>
            <td>
              <span *ngIf="+group.earnedPay === 0">0%</span>
              <span *ngIf="!(+group.earnedPay === 0)">{{(group.earnedPay * 100) | number : '1.2-2'}} %</span>
            </td>
            <td>
              <span *ngIf="+group.totalEarned === 0">$0.00</span>
              <span *ngIf="!(+group.totalEarned === 0)">{{group.totalEarned | currency}}</span>
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th>Total</th>
            <th></th>
            <th></th>
            <th>
              <span *ngIf="+totalCommissionGroupsEarning === 0">{{totalCommissionGroupsEarning | currency}}</span>
              <span *ngIf="!(+totalCommissionGroupsEarning === 0)">{{totalCommissionGroupsEarning | currency}}</span>
            </th>
          </tr>
        </thead>

      </table>
    </div>
    <div class="forecast-graph-container">
      <div *ngFor="let formula of selectedFormulas; let j=index" class="forecast-graph">
        <div class="dropdown">
          <button class="dropdown-toggle float-right" type="button" id="dropdownMenuButton"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" mat-raised-button color="primary">
            {{formula.formula.name}}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Pay</th>
                  <th>Goal</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let peg of formula.commissionPegs; let i=index" role="row">
                  <td>{{(peg.pay * 100) | number : '1.2-2'}} %</td>
                  <td>{{peg.goal}}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>Estimated</th>
                  <th>Current</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{(formula.estimatedPay * 100) | number : '1.2-2'}} %</td>
                  <td>{{formula.currentGoal}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <canvas baseChart *ngIf="formula.chartData"
                [data]="formula.chartData.data"
                [options]="formula.chartData.options"
                [type]="formula.chartData.type"
                ></canvas>
        <!--<canvas id="canvas-{{j}}">{{ formula.chartData }}</canvas>-->
      </div>
    </div>
  </div>
</div>
