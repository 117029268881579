import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserStatusCode } from '../models/userStatusCode';

@Injectable({
  providedIn: 'root'
})
export class UserStatusCodeService {
  private _baseUrl = '/api/user-status-codes';

  constructor(private http: HttpClient) {}

  getUserStatusCodes(): Observable<UserStatusCode[]> {
    return this.http.get<UserStatusCode[]>(this._baseUrl);
  }
}
