<style>
  .commission-group {
    background-color: #3F51B5;
    color: #ffffff;
    line-height: 35px;
    border-radius: 5px;
    border-color: #3F51B5;
  }

  .td-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #FAFAFA;
    padding: 2px 2px 2px 2px;
    hyphens: auto;
    white-space: nowrap;
  }
</style>

<div class="d-flex align-items-center justify-content-between action-header">
  <mat-form-field class="bottom-border ">
    <mat-label>Month and Year</mat-label>
    <input matInput [matDatepicker]="datepicker" [formControl]="date">
    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
    <mat-datepicker #datepicker startView="year" (yearSelected)="yearHandler($event)"
      (monthSelected)="monthHandler($event, datepicker)"></mat-datepicker>
  </mat-form-field>
  <button type="button" mat-raised-button color="primary" class="primary-action-btm" (click)="addUser();">Add commission
    user</button>
</div>
<div class="table-container table-margin-top">
  <table #table mat-table [dataSource]="dataSource" multiTemplateDataRows
    class="table table-bordered table-hover small-txt" matSort matSortActive="symbol" matSortDirection="asc">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="sort-column mat-sort-header-button-custom mat-header-cell"
        mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let item; let i = index;"><span style="margin-right:5px">{{item.name}}</span></td>
    </ng-container>
    <ng-container matColumnDef="locationId">
      <th mat-header-cell *matHeaderCellDef class="sort-column mat-sort-header-button-custom mat-header-cell"
        mat-sort-header>Store</th>
      <td mat-cell *matCellDef="let item; let i = index;"><span
          style="margin-right:5px">{{getLocation(item.locationId)}}</span></td>
    </ng-container>
    <ng-container matColumnDef="duration">
      <th mat-header-cell *matHeaderCellDef class="sort-column mat-sort-header-button-custom mat-header-cell">Month/Year
      </th>
      <td mat-cell *matCellDef="let item; let i = index;"><span
          style="margin-right:5px">{{getDuration(item.duration)}}</span></td>
    </ng-container>
    <ng-container matColumnDef="commissionGroups">
      <th mat-header-cell *matHeaderCellDef class="sort-column mat-sort-header-button-custom mat-header-cell">Commission
        Groups</th>
      <td mat-cell *matCellDef="let item; let i = index;">
        <ul>
          <li class="counter commission-group" *ngFor="let commissionGroup of item.commissionGroups">
            {{commissionGroup.name}}</li>
        </ul>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="sort-column mat-sort-header-button-custom mat-header-cell">Actions
      </th>
      <td mat-cell *matCellDef="let item; let i = index;">
        <a color="primary" type="button" (click)="openCopyCommissionDialog($event, item)" class="icon-btn"><i class="fa fa-clone"></i></a>
        <a color="primary" type="button" (click)="deleteCommissionUser($event, item.id)" class="icon-btn"><i class="fa fa-trash-alt"></i></a>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
    <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();" (click)="selectUser(row)"></tr>
  </table>
  <mat-paginator #paginator="matPaginator" [pageSizeOptions]="[5, 10, 20]" [pageSize]="10"
    showFirstLastButtons></mat-paginator>
</div>
<div class="dialog multi-cols" [hidden]="!dialog.isOpen" [ngClass]="{'cols-1': (columnsVisible == 1), 'cols-2': (columnsVisible == 2),
     'cols-3': (columnsVisible == 3), 'cols-4': (columnsVisible == 4) }"
  [ngStyle]="{'width': 12 + columnsVisible * 18 +'vw', 'min-width': 12 + columnsVisible * 18 +'vw', 'max-width':  12 + columnsVisible * 18 +'vw', 'left' : 50 - columnsVisible * 3 +'%'}">
  <header style="background-color: #3F51B5" class="pl-3">
    <span *ngIf="dialog.headerText"> {{dialog.headerText}}</span>
    <a *ngIf="dialog.cancelButton" class="cancel-btn" (click)="dialog.cancel()"><i style="color:white"
        class="fa fa-times"></i></a>
  </header>
  <section *ngIf="commissionUser" class="h-100">
    <div class="p-3">
      <form (submit)="saveCommissionUser()">
        <h5 class="fw-bold">Commission User</h5>
        <div class="d-flex align-items-center ">
          <div class="col-3 px-0">
            <span class="form-control-label">Name</span>

          </div>
          <div class="custom-input-box col-9">
            <mat-select placeholder="Name" name="name" #name="ngModel" [(ngModel)]="selectedEmployeeId">
              <mat-option *ngFor="let employee of locationEmployees"
                [value]="employee.id">{{employee.user.name}}</mat-option>
            </mat-select>
          </div>
        </div>
        <div class="d-flex mt-4 align-items-center">
          <div class="col-3 px-0">
            <span class="form-control-label">Month/Year</span>
          </div>
          <div class="custom-input-box col-9">
            <input matInput type="month" [(ngModel)]="commissionUser.duration" #name="ngModel" name="duration">
          </div>
        </div>
        <div class="mt-5">
          <p class="form-control-static">
            <button type="button" mat-raised-button color="primary" style="margin-left:5px"
              [disabled]="commissionUser.commissionGroups.length === 0" (click)="saveUser();">Save</button>
            <button type="button" mat-raised-button color="primary" style="margin-left:10px"
              [disabled]="!selectedEmployeeId || !commissionUser.duration" (click)="addGroup();">Group(+)</button>
            <button type="button" mat-raised-button color="primary" style="margin-left:10px"
              (click)="cancelUser();">Cancel</button>
          </p>
        </div>
      </form>
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th style="max-width:4.5vw">Name</th>
            <th>Base(%)</th>
            <th style="max-width:6vw">Target</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let commissionGroup of commissionUser.commissionGroups; let i=index" role="row"
            (click)="selectGroup(commissionGroup);">
            <td class="td-cell" style="max-width:4.5vw">{{commissionGroup.name}}</td>
            <td class="td-cell">{{commissionGroup.basePercentage}}</td>
            <td class="td-cell" style="max-width:6vw">{{commissionGroup.target}}</td>
            <td><a (click)="deleteGroup($event, commissionGroup)" class="icon-btn"><i class="fa fa-trash"></i></a></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="(columnsVisible == 2 || columnsVisible == 3 || columnsVisible == 4 )" style="width:18vw" class="p-3">
      <form #f="ngForm">
        <h5 class="fw-bold">Commission Group</h5>
        <div class="d-flex align-items-center ">
          <div class="col-3 px-0">
            <span class="form-control-label">Name</span>

          </div>
          <div class="custom-input-box col-9">
            <input type="text" matInput [(ngModel)]="commissionGroup.name" #name="ngModel" name="name"
              placeholder="Name" />
          </div>
        </div>
        <div class="d-flex align-items-center mt-4">
          <div class="col-3 px-0">
            <span class="form-control-label">Base(%)</span>

          </div>
          <div class="custom-input-box col-9">
            <input type="number" matInput [(ngModel)]="commissionGroup.basePercentage" #basePercentage="ngModel"
              name="basePercentage" />
          </div>
        </div>
        <div class="d-flex align-items-center mt-4">
          <div class="col-3 px-0">
            <span class="form-control-label">Target</span>

          </div>
          <div class="custom-input-box col-9">
            <mat-select name="target" placeholder="Target" #target="ngModel" [(ngModel)]="commissionGroup.target">
              <mat-option value="self">Self</mat-option>
              <mat-option *ngFor="let store of stores" [value]="store.name">{{store.name}}</mat-option>
            </mat-select>
          </div>
        </div>

        <div class="mt-5">
          <p class="form-control-static">
            <button type="button" mat-raised-button color="primary" style="margin-left:5px"
              [disabled]="commissionGroup.commissionFormulas.length === 0" (click)="saveGroup();">Save</button>
            <button type="button" mat-raised-button color="primary" style="margin-left:10px"
              [disabled]="!commissionGroup.name || commissionGroup.basePercentage == null || !commissionGroup.target || !commissionGroup.drawOpt"
              (click)="addFormula();">Formula(+)</button>
            <button type="button" mat-raised-button color="primary" style="margin-left:10px"
              (click)="cancelGroup();">Cancel</button>
          </p>
        </div>
      </form>
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Name</th>
            <th>Growth(%)</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let commissionFormula of commissionGroup.commissionFormulas; let i=index" role="row"
            (click)="selectFormula(commissionFormula);">
            <td>{{commissionFormula.formula.name}}</td>
            <td>{{commissionFormula.growth}}</td>
            <td><a (click)="deleteFormula($event, commissionFormula)" class="icon-btn"><i class="fa fa-trash"></i></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="(columnsVisible == 3 || columnsVisible == 4 )" style="width:18vw" class="p-3">
      <form #f="ngForm">
        <h5 class="fw-bold">Commission Formula</h5>
        <div class="d-flex align-items-center">
          <div class="col-3 px-0">
            <span class="form-control-label">Name</span>

          </div>
          <div class="custom-input-box col-9">
            <mat-select style="font-size:14px" placeholder="Name" name="formulaId" #formulaId="ngModel"
              [(ngModel)]="commissionFormula.formulaId">
              <mat-option *ngFor="let formula of formulas" [value]="formula.id">{{formula.name}}</mat-option>
            </mat-select>
          </div>
        </div>
        <div class="d-flex align-items-center mt-4">
          <div class="col-3 px-0">
            <span class="form-control-label">Growth(%)</span>

          </div>
          <div class="custom-input-box col-9">
            <mat-select style="font-size:14px" placeholder="Growth(%)" name="name" #growth="ngModel"
              [(ngModel)]="commissionFormula.growth">
              <mat-option *ngFor="let growth of growths" [value]="growth.value">{{growth.text}}</mat-option>
            </mat-select>
          </div>
        </div>

        <div class="mt-5">
          <p class="form-control-static">
            <button type="button" mat-raised-button color="primary" style="margin-left:5px"
              [disabled]="commissionFormula.commissionPegs.length === 0" (click)="saveFormula();">Save</button>
            <button type="button" mat-raised-button color="primary" style="margin-left:10px"
              [disabled]="!commissionFormula.formulaId || !commissionFormula.growth" (click)="addPeg();">Peg(+)</button>
            <button type="button" mat-raised-button color="primary" style="margin-left:10px"
              (click)="cancelFormula();">Cancel</button>
          </p>
        </div>
      </form>
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Pay</th>
            <th>Goal</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let commissionPeg of commissionFormula.commissionPegs; let i=index" role="row"
            (click)="selectPeg(commissionPeg);">
            <td>{{commissionPeg.pay}}</td>
            <td>{{commissionPeg.goal}}</td>
            <td><a (click)="deletePeg($event, commissionPeg)" class="icon-btn"><i class="fa fa-trash"></i></a></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="(columnsVisible == 4)" style="width:18vw" class="p-3">
      <form #f="ngForm">
        <h5 class="fw-bold">Commission Peg</h5>
        <div class="d-flex align-items-center">
          <div class="col-3 px-0">
            <span class="form-control-label">Pay</span>

          </div>
          <div class="custom-input-box col-9">
            <input type="number" matInput [(ngModel)]="commissionPeg.pay" #pay="ngModel" name="pay" />
          </div>
        </div>
        <div class="d-flex align-items-center mt-4">
          <div class="col-3 px-0">
            <span class="form-control-label">Goal</span>

          </div>
          <div class="custom-input-box col-9">
            <input type="number" matInput [(ngModel)]="commissionPeg.goal" #work="ngModel" name="work" />
          </div>
        </div>
          <div class="mt-5">
            <p class="form-control-static">
              <button type="button" mat-raised-button color="primary" style="margin-left:5px"
                [disabled]="!commissionPeg.goal || commissionPeg.pay == null" (click)="savePeg();">Save</button>
              <button type="button" mat-raised-button color="primary" style="margin-left:10px"
                (click)="cancelPeg();">Cancel</button>
            </p>
          </div>
      </form>
    </div>
  </section>
</div>
<div class="dialog-overlay" [hidden]="!dialog.isOpen"></div>
<div class="dialog-overlay" [hidden]="!copyDialog.isOpen"></div>
