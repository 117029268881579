import { Component, OnInit } from '@angular/core';

import { NotifierService } from 'angular-notifier';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html'
})
export class ResetPasswordComponent implements OnInit {
  model: any = {};
  resetCode: string;
  newPassword: string;
  confirmPassword: string;
  isPasswordValidationFailed: boolean;
  errorArray: string[];

  constructor(
    private _authService: AuthService,
    private _routeService: Router,
    private _notifier: NotifierService) { }

  ngOnInit() {
    if (this._routeService.url.indexOf("?") > 0) {
      this.resetCode = this._routeService.url.substr(this._routeService.url.indexOf("?") + 1).replace("=", "");
    }
  }

  

  checkPasswordValidity(value){
    const isNonWhiteSpace = /^\S*$/;
    let errorArray = [];
    if (!isNonWhiteSpace.test(value)) {
      errorArray.push("Must not contain Whitespaces.");
    }

    const regExA = new RegExp("^(?=.*?[A-Z]).*$");
    if (!regExA.test(value)) {
      errorArray.push("Must have at least one upper case.");
    }

    const regExa = new RegExp("^(?=.*?[a-z]).*$");
    if (!regExa.test(value)) {
      errorArray.push("Must have at least one lower case.");
    }

    const regExNum = new RegExp('^(?=.*?[0-9]).*$');
    if (!regExNum.test(value)) {
      errorArray.push("Must have at least one number.");
    }

    const regExsp = new RegExp('^(?=.*?[#?!@$%^&*-]).*$');
    if (!regExsp.test(value)) {
      errorArray.push("Must have at least one special character.");
    }

    const regExlen = new RegExp('^.{8,}$');
    if (!regExlen.test(value)) {
      errorArray.push("Must be atleast 8 characters.");
    }
    return errorArray;
  }



  resetPassword() {
    this.isPasswordValidationFailed = false;
    const regEx = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    if (this.model.newPassword !== this.model.confirmPassword) {
      this._notifier.notify('warning', 'New password and confirm password should be same.');
      return;
    }



    //if (!regEx.test(this.model.newPassword)) {
    //  this.isPasswordValidationFailed = true;
    //  return;
    //}
    let response = this.checkPasswordValidity(this.model.newPassword);
    if (response.length > 0) {
      this.errorArray = response;
      this.isPasswordValidationFailed = true;
      return;
    }
    this._authService.resetPassword(this.resetCode, this.model.newPassword)
      .subscribe((response: string) => {
        if (response.includes('Bad')) {
          this._notifier.notify('error', response);
        } else {
          this._notifier.notify('success', 'Password changed successfully, Please login again to continue.');
          this._routeService.navigate(['login']);
        }
      }, (error) => this._notifier.notify('error', error.error), () => { });

    
  }
  
}
