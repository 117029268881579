import { Component, OnDestroy, OnInit} from '@angular/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as moment from 'moment';

import { CommissionUserService } from '../services/commission-user.service';
import { MtdReportService } from '../services/mtd-report.service';
import { FormulaService } from '../services/formula.service';
import { LocationService } from '../services/location.service';
import { AuthService } from '../../core/services/auth.service';
import { NotifierService } from 'angular-notifier';
import { CommissionUser } from '../models/commission-user';
import { CommissionGroup } from '../models/commission-group';
import { CommissionFormula } from '../models/commission-formula';
import { CommissionPeg } from '../models/commission-peg';
import { LocationTypeCode } from '../models/location-type-code';
import { Formula } from '../models/formula';
import { EmployeeService } from '../services/employee.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';

Chart.register(ChartDataLabels);
const CONFIG_CLIENT_KEY = 'ConfigClient';
const CONFIG_REGION_KEY = 'ConfigRegion';
const CONFIG_DISTRICT_KEY = 'ConfigDistrict';
const CONFIG_STORE_KEY = 'ConfigStore';
const CONFIG_EMPLOYEE_KEY = 'ConfigEmployee';
@Component({
  selector: 'app-forecast',
  templateUrl: './forecast.component.html'
})
export class ForecastComponent implements OnInit, OnDestroy {
  clientId: number;
  actualClientId: number;
  selectedDuration: string;
  selectedCommissionUser: any;
  selectedForecastUserId: string;
  selectedForecastUser: any;
  forecastUsers: any[];
  formulas: Formula[];
  users: CommissionUser[];
  groups: CommissionGroup[];
  pegs: CommissionPeg[];
  selectedFormulas: CommissionFormula[];
  commissionUsersGroupIds: string;
  isAdmin: boolean;
  commissionUserName: string;
  totalCommissionGroupsEarning: number;

  clients: any[] = [];
  selectedClientId: number;
  selectedClient: any;
  clientName: string;
  regions: any[] = [];
  selectedRegionId: number;
  selectedRegion: any;
  districts: any[] = [];
  selectedDistrictId: number;
  selectedDistrict: any;
  stores: any[] = [];
  selectedStoreId: number;
  selectedStore: any;
  currentMonthYear: any;
  employees: any[] = [];
  selectedEmployee: any;
  selectedEmployeeId: number;
  subscriptionToClients: Subscription;
  subscriptionToActualClients: Subscription;
  subscriptionToStores: Subscription;
  subscriptionToEmployees: Subscription;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  permission1 = false;
  permission2 = false;
  permission3 = false;
  permission4 = false;
  permission5 = false;
  permission6 = false;
  selectedGroup: any;
  tooltipCallbacks: any = {
    title: function () {
      return 'Pay / Goal';
    },
    label: function (tooltipItem) {
      const index = tooltipItem['dataIndex'];
      const datasetIndex = tooltipItem['datasetIndex'];
      const dataSet = tooltipItem.dataset;

      const labels = tooltipItem.dataset.label.split('-');
      for (let i = 0; i <= labels.length - 1; i++) {
        labels[i] = parseFloat(labels[i]).toFixed(2);
      }

      return (labels[index] * 100).toFixed(2) + '% / ' + (dataSet['data'][index]).toFixed(2);
    }
  };

  constructor(private _commissionUserService: CommissionUserService,
    private _mtdReportService: MtdReportService,
    private _formulaService: FormulaService,
    private _notifier: NotifierService,
    private _locationService: LocationService,
    private _authService: AuthService,
    private _employeeService: EmployeeService,
    private _loaderService: LoaderService) { }

  ngOnInit() {
    this.getActualClientId();
    this.getClients();
    Chart.defaults.plugins.datalabels.display = false;
    this.isAdmin = this._authService.isSuperAdmin() ? this._authService.isSuperAdmin() : false;
    this.waitForForecastUsers();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
  }

  waitForForecastUsers() {
    if (typeof this.forecastUsers !== 'undefined') {
      this._loaderService.show();
      this.subscriptionToEmployees = this._locationService.currentEmployee
        .pipe(takeUntil(this.destroyed$))
        .subscribe(message => {
          this.selectedEmployeeId = message; this.findForecastUserByEmployee();
        });
    } else {
      setTimeout(() => {
        this.waitForForecastUsers();
      }, 250);
    }
  }


  postClientId() {
    this._employeeService.getAllEmployees().subscribe((response) => {
      this.employees = response;
      this._locationService.getLocationsByClientId(this.clientId, () => {
        this.subscriptionToStores = this._locationService.currentStore
          .pipe(takeUntil(this.destroyed$))
          .subscribe(message => {
            this.selectedStoreId = message; this.onStoreSelected();
          });
      });
    });
  }

  getClients() {
    this._locationService.getLocations(LocationTypeCode.Client, (response) => {
      this.clients = response;
      this.subscriptionToClients = this._locationService.currentClient
        .pipe(takeUntil(this.destroyed$))
        .subscribe(message => {
          this.selectedClientId = message; this.onClientSelected();
        });
    });
  }

  getActualClientId() {

    this.subscriptionToActualClients = this._locationService.currentActualClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        this.actualClientId = message;
      });
  }

  onClientSelected() {
    //console.log("Client selected on forecast user... o.o")
    if (this.selectedClientId) {
      this.selectedClient = this.clients.find(r => r.id === +this.selectedClientId);
      this.clientId = this.selectedClient.clientId;

      this._locationService.getLocations(LocationTypeCode.Region, (response) => {
        this.regions = response;

      }, this.selectedClientId);
      this._employeeService.getAllEmployees().subscribe((response) => {
        this.employees = response;
        this._locationService.getLocationsByClientId(this.clientId, () => {
          this.postClientId();
          this.commissionUserName = this._authService.decodedToken()['unique_name'];
          this.selectedDuration = moment().format('YYYY-MM');
          this.getFormulas();
          this.selectedFormulas = null;
        });
      });
    }
  }
  onStoreSelected() {
    if (this.selectedStoreId) {
      if (+this.selectedStoreId === 0) {
        return;
      }
      this.getForecastUsers();
    }
  }

  getFormulas() {
    this._formulaService.getFormulas(this.clientId, (response) => {
      this.formulas = response;
      this.formulas = this.formulas.filter(x => x.containsComplexFormula === false);
      // this.getForecastUsers();
    });
  }

  getGrossProfitFormula() {
    return '(,GP,GrossProfit,)';
    //return this.formulas.find(f => f.formulaValue === 'GrossProfit').formulaValue;
  }

  async onDurationChanged() {
    this.getForecastUsers();
  }


  getForecastUsers() {
    //console.log('getforecastuser')
    let locations: number[] = [];
    if (this.selectedStoreId !== 0) {
      this._commissionUserService.getCommissionUsers(this.actualClientId, this.selectedDuration, (response) => {
        this.users = response;
        const forecastUsers = this.users.map(function (user) {
          return { id: user.id, name: user.name, employeeId: user.employeeId };
        });
        this.forecastUsers = forecastUsers.sort((a, b) => a.name.localeCompare(b.name));
        this.findForecastUserByEmployee();
      }, [+this.selectedStoreId]);
    } else if (this.selectedDistrictId !== 0) {
      this._locationService.getLocationsByTypeAndParents(LocationTypeCode.Store, (storeResponse) => {
        locations = storeResponse.map(s => s.id);
        this._commissionUserService.getCommissionUsers(this.selectedClientId, this.selectedDuration, (response) => {
          this.users = response;
          const forecastUsers = this.users.map(function (user) {
            return { id: user.id, name: user.name };
          });
          this.forecastUsers = forecastUsers.sort((a, b) => a.name.localeCompare(b.name));
          this.findForecastUserByEmployee();
        }, locations);
      }, [this.selectedDistrictId]);
    }

  }

  async findForecastUserByEmployee() {
    //console.log('findforecaseUser')
    let hideLoader = true;
    if (this.selectedEmployeeId) {
      this.totalCommissionGroupsEarning = 0;
      this.selectedFormulas = null;
      this.groups = [];
      if (this.employees && this.employees.length > 0) {
        this.selectedEmployee = this.employees.find(x => x.id === this.selectedEmployeeId);
        this.selectedForecastUser = this.forecastUsers.find(x => x.employeeId === this.selectedEmployeeId);
        if (this.selectedForecastUser) {
          this.selectedForecastUserId = this.selectedForecastUser.id;
          if (this.selectedForecastUser) {
            hideLoader = false;
            this.searchForecast();
          }
        }
      }
    }
    if (hideLoader)
      this._loaderService.hide();

  }


  searchForecast() {

    //console.log('show in searchForecast')
    this._loaderService.show();
    this.totalCommissionGroupsEarning = 0;
    this.selectedCommissionUser = this.users.find(u => u.id === +this.selectedForecastUserId);
    this.groups = this.selectedCommissionUser.commissionGroups;
    this.groups.forEach((group) => { group.earnedPay = 0; });
    this.commissionUsersGroupIds = this.groups.map(g => g.id).join('|');
    const duration = this._mtdReportService.getDurationForOneMonth(this.selectedDuration);
    this._mtdReportService.getCommissionGroupReport(duration, this.selectedCommissionUser.employeeId, this.commissionUsersGroupIds)
      .subscribe((response: any[]) => {
        const groupReport = response['commissionGroups'];
        this.groups.forEach((group) => {
          const skuGroups = groupReport.find(gr => gr.id === group.id).skuGroups;
          const conversionData = groupReport.find(gr => gr.id === group.id).conversionData;
          const hoursWorkedData = groupReport.find(gr => gr.id === group.id).hoursWorkedData;
          let earnedPay = group.basePercentage;
          group.commissionFormulas.forEach((f, index) => {
            f.commissionPegs = f.commissionPegs.sort((a, b) => a.goal < b.goal ? -1 : a.goal > b.goal ? 1 : 0);
            f.currentGoal = +this._mtdReportService.CalculateFormulaValue(skuGroups, conversionData, f.formula.formulaValue, hoursWorkedData).toFixed(2);
            f.estimatedPay = this.calculateEstimatedPay(f.commissionPegs, f.growth, f.currentGoal);
            earnedPay += f.estimatedPay;
          });

          group.earnedPay = earnedPay;
          const grossProfitValueForGroup =
            +this._mtdReportService.CalculateFormulaValue(skuGroups, conversionData, this.getGrossProfitFormula(), hoursWorkedData).toFixed(2);
          group.totalEarned = +(group.earnedPay * grossProfitValueForGroup).toFixed(2);
          this.totalCommissionGroupsEarning += group.totalEarned;
        });
        if (this.groups.length > 0) {
          this.selectGroup(this.groups[0]);
          this.selectedGroup = this.groups[0];
        }
      }, (error) => this._notifier.notify('error', error.error), () => { this._loaderService.hide(); });
  }

  calculateGoalAndPayData(pegs: CommissionPeg[], currentPeg: any, isTrending: boolean): any {
    currentPeg = { 'goal': currentPeg.goal, 'pay': currentPeg.pay };
    var hourFraction = (20 - moment().hour()) / 20;
    if (hourFraction < 0) hourFraction = 0;
    const trendingCounter = +((moment().daysInMonth()) / (moment().date() - hourFraction)).toFixed(2);
    //const trendingCounter = +(moment().daysInMonth() / moment().date()).toFixed(2);
    const defaultPeg = { goal: 0, pay: 0 };
    const lastPeg: any = { 'goal': pegs[pegs.length - 1].goal, 'pay': pegs[pegs.length - 1].pay };
    const trendingPeg: any = { 'goal': currentPeg.goal * (trendingCounter - 1), 'pay': currentPeg.pay * trendingCounter };
    const innerData: any[] = [];
    let outerData: any[];
    const result: any[] = [];

    for (let i = 0; i <= pegs.length - 1; i++) {
      innerData.push((i === 0) ? { 'goal': pegs[i].goal, 'pay': pegs[i].pay } :
        { 'goal': (pegs[i].goal - pegs[i - 1].goal), 'pay': (pegs[i].pay - pegs[i - 1].pay) });
    }

    if (isTrending && this._mtdReportService.isCurrentMonthSelected(this.selectedDuration)) {
      if (currentPeg.goal >= lastPeg.goal) {
        outerData = [lastPeg, defaultPeg, defaultPeg];
      } else if (trendingPeg.goal + currentPeg.goal >= lastPeg.goal) {
        outerData = [currentPeg, { goal: (lastPeg.goal - currentPeg.goal), pay: (lastPeg.pay - currentPeg.pay) }, defaultPeg];
      } else {
        outerData = [currentPeg, trendingPeg,
          { goal: (lastPeg.goal - currentPeg.goal - trendingPeg.goal), pay: (lastPeg.pay - currentPeg.pay - trendingPeg.pay) }];
      }
    } else {
      if (currentPeg.goal >= lastPeg.goal) {
        outerData = [lastPeg, defaultPeg, defaultPeg];
      } else {
        outerData = [currentPeg, defaultPeg, { goal: (lastPeg.goal - currentPeg.goal), pay: (lastPeg.pay - currentPeg.pay) }];
      }
    }

    result.push(outerData), result.push(innerData);
    return result;
  }

  getChartData(data: any[], part: string) {
    const result: any[] = [];
    const outerData: any[] = [];
    const innerData: any[] = [];

    for (let i = 0; i <= data[0].length - 1; i++) {
      outerData.push(data[0][i][part]);
    }

    for (let i = 0; i <= data[1].length - 1; i++) {
      innerData.push(data[1][i][part]);
    }

    result.push(outerData), result.push(innerData);

    return result;
  }

  calculateEstimatedPay(pegs: CommissionPeg[], growth: string, currentPerformance: number): number {
    let estimatedPay = 0;
    switch (growth) {
      case 'Tier':
        for (let i = 0; i <= pegs.length - 1; i++) {
          if (currentPerformance >= pegs[i].goal) {
            estimatedPay = pegs[i].pay;
          }
        }
        break;
      case 'Gradual':
        if (currentPerformance >= pegs[pegs.length - 1].goal) {
          estimatedPay = pegs[pegs.length - 1].pay;
        } else if (currentPerformance < pegs[0].goal) {
          estimatedPay = 0;
        } else {
          let isGreaterThanLastGoal = false;
          for (let i = 0; i <= pegs.length - 1; i++) {
            if (currentPerformance >= pegs[i].goal && currentPerformance < pegs[i + 1].goal) {
              isGreaterThanLastGoal = true;
            }

            if (isGreaterThanLastGoal) {
              const nextPeg = pegs[i + 1];
              const prevPeg = pegs[i];
              estimatedPay =
                ((nextPeg.pay - prevPeg.pay) * ((currentPerformance - prevPeg.goal) / (nextPeg.goal - prevPeg.goal))) + prevPeg.pay;
            }
          }
        }
        break;
    }
    return estimatedPay;
  }



  selectGroup(group: CommissionGroup) {
    this.selectedGroup = group;
    const self = this;
    this.selectedFormulas = group.commissionFormulas;
    //this._zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.selectedFormulas = this.selectedFormulas.map((f) => {
          const currentPeg = { 'goal': f.currentGoal, 'pay': f.estimatedPay };
          const payAndGoalData = self.calculateGoalAndPayData(f.commissionPegs, currentPeg, f.formula.trendingPossible);
          const goals = self.getChartData(payAndGoalData, 'goal');
          const pays = self.getChartData(payAndGoalData, 'pay');
          f.chartData = {
            type: 'doughnut',
            data: {
              labels: [],
              datasets: [
                {
                  data: goals[0],
                  label: pays[0].join('-'),
                  backgroundColor: ['#00f', '#036903', '#ccc']
                  //backgroundColor: ['#20488A', '#3878E0', '#8EB9FF']
                },
                {
                  data: goals[1],
                  label: pays[1].join('-'),
                  backgroundColor: ['#F00', '#f90', '#fff700']
                  //backgroundColor: ['#20488A', '#3878E0', '#8EB9FF']
                },
              ]
            },
            options: {
              responsive: true,
              aspectRatio: 1.4,
              plugins: {
                legend: {
                  display: false
                },
                tooltip: {
                  displayColors: false,
                  backgroundColor: '#FFF',
                  titleColor: '#0066ff',
                  titleFont: {
                    size: 16
                  },
                  bodyColor: '#000',
                  bodyFont: {
                    size: 14
                  }
                  ,
                  callbacks: self.tooltipCallbacks
                }
              },
            }
          }
          return f;
        })
      }, 1000);
  }

  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    //console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    //console.log(event, active);
  }
}


