import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Role } from '../models/role';
import { Setting } from '../models/setting';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private _baseUrl = '/api/roles';

  constructor(private http: HttpClient) {}

  getRoles(clientId: number, roleId: string): Observable<Role[]> {
    return this.http.get<Role[]>(this._baseUrl + '/' + clientId + '/' + roleId);
  }

  findChildrenOfRole(roleList: Role[], parent: Role) {
    const children = roleList.filter(x => x.parentRoleId === parent.id);
    if (children.length > 0) {
      children.forEach(x => {
        x.childRoles = this.findChildrenOfRole(roleList, x);
        x.children = this.findChildrenOfRole(roleList, x);
      });
    }
    return children;
  }
  /*
  getRolesAsTree(clientId: number, roleId: string, callback: any) {
    let roleTree: Role[];
    this.getRoles(clientId, roleId).subscribe((response) => {
      roleTree = response.filter(x => x.parentRoleId === "");
      roleTree.forEach(x => {
        x.childRoles = this.findChildrenOfRole(response, x);
      });
      callback(roleTree);
    });
  }*/

  updateRole(id: string, name: string, clientId: number, rolePermissions: any[], settings: Setting[]): Observable<any> {
    const role = {
      Id: id,
      Name: name,
      RolePermissions: rolePermissions,
      ClientId: clientId,
      SettingRoleOption: settings
    };
    return this.http.put(this._baseUrl, role);
  }

  getTemplateName(userid: string): Observable<any> {
    return this.http.get(this._baseUrl + '/' + userid + '/gettemplatename');
  }

  getSubclientOfUser(userid: string): Observable<any> {
    return this.http.get(this._baseUrl + '/' + userid + '/getsubclientid');
  }

  createRole(newRole: Role, roleToCopy: string) {
    return this.http.put(this._baseUrl + '/' + roleToCopy, newRole);
  }
}
