import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../../core/services/auth.service';
import { LocationService } from '../services/location.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DurationService } from '../services/duration.service';
import { Duration } from '../models/duration';
import { RewardsCreateSearchRequest } from '../models/rewards-create-search-request';
import { MatSelectChange } from '@angular/material/select';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, MatSortable, MatSortModule } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { LoaderService } from '../services/loader.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { RewardGameViewModel } from '../models/reward-game';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { EmployeeService } from '../services/employee.service';
import { Employee } from '../models/employee';
import { Location } from '../models/location';
import { FormulaService } from '../services/formula.service';
import { Formula } from '../models/formula';
import { RewardGameType } from '../models/reward-game-type';
import { RewardGameStatus } from '../models/reward-status';
import { isString } from '@ng-bootstrap/ng-bootstrap/util/util';
import { Status } from '../users/models/status';
import { RewardService } from '../services/reward-service';
import { object } from 'underscore';
import { ConfirmDialogComponent } from '../dialogs/confirm/confirm-dialog.component';
import { FinalizeDialogComponent } from './finalize-dialog.component';
import { RewardsCreateDialogComponent } from './rewards-create-dialog.component';

export class Filters {
  value: number;
  viewValue: string;
}



@Component({
  selector: 'app-rewards-create',
  templateUrl: './rewards-create.component.html'
})

export class RewardsCreateComponent implements OnInit, OnDestroy {

  constructor(private _notifier: NotifierService,
    private _authService: AuthService,
    private _rewardService: RewardService,
    private _locationService: LocationService,
    private _employeeService: EmployeeService,
    private _formulaService: FormulaService,
    private _durationService: DurationService,
    private _loaderService: LoaderService,
    private observer: BreakpointObserver, private matDialog: MatDialog
  ) {

    this.observer.observe('(max-width: 1025px)').subscribe((result) => {
      //console.log('max', result);
      if (result.matches) {
        this.columnDefinations = [
          { name: "Name", isSortable: true, mappedToProperty: "name", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Description", isSortable: true, mappedToProperty: "description", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Summary", isSortable: true, mappedToProperty: "summary", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Type", isSortable: true, mappedToProperty: "type", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Status", isSortable: true, mappedToProperty: "status", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Sales Person", isSortable: true, mappedToProperty: "employeeList", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Metric", isSortable: true, mappedToProperty: "formula", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Target", isSortable: true, mappedToProperty: "target", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Achieved", isSortable: true, mappedToProperty: "achieved", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Total Pay", isSortable: true, mappedToProperty: "totalPayout", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "CreatedDate", isSortable: true, mappedToProperty: "createdDate", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "StartDate", isSortable: true, mappedToProperty: "startDate", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "EndDate", isSortable: true, mappedToProperty: "endDate", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Manage", isSortable: true, mappedToProperty: "manage", class: "", isFilterable: true, sticky: true, hide: false }
        ];
        this.selectedColumns = this.columnDefinations.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
      }
    });
  }

  columnDefinations = [
    { name: "Name", isSortable: true, mappedToProperty: "name", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Description", isSortable: true, mappedToProperty: "description", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Summary", isSortable: true, mappedToProperty: "summary", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Type", isSortable: true, mappedToProperty: "type", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Status", isSortable: true, mappedToProperty: "status", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Sales Person", isSortable: true, mappedToProperty: "employeeList", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Metric", isSortable: true, mappedToProperty: "formula", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Target", isSortable: true, mappedToProperty: "target", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Achieved", isSortable: true, mappedToProperty: "achieved", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Total Pay", isSortable: true, mappedToProperty: "totalPayout", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "CreatedDate", isSortable: true, mappedToProperty: "createdDate", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "StartDate", isSortable: true, mappedToProperty: "startDate", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "EndDate", isSortable: true, mappedToProperty: "endDate", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Manage", isSortable: true, mappedToProperty: "manage", class: "", isFilterable: true, sticky: true, hide: false }
  ];

  finalizeMessage: string;
  finalizeRewardGames() {
    this._rewardService.finalizeGames(this.selectedClientId, false)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        //this.finalizeMessage = response;
        this.dialogRef = this.matDialog.open(FinalizeDialogComponent, { autoFocus: true, disableClose: false, panelClass: '', data: { title: "Finalize", finalizeMessage: "Finalize all games until today?" } });
        this.dialogRef.afterClosed().subscribe(result => { if (result === true) { this.confirmFinalize(); } });
      });
  }


  confirmFinalize() {
    this._rewardService.finalizeGames(this.selectedClientId, true)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.finalizeMessage = null;
        this.getRewards();
      });
  }

  openFilterMenu() {
    this.filterMenu.isOpen = true;

  }

  filterMenu: any = {
    isOpen: false,
    cancelButton: true,
    status: 0,
    type: -1,
    formula: -1,
    duration: -1,
    createdBy: 'Everyone',
    cancel: function () {
      this.isOpen = false;
    }
  };

  getTextBydata(element, columnDef) {
    var textToShow = '';
    if (!this.tableready) return;
    switch (columnDef.name) {
      case 'Type':
        textToShow = RewardGameType[element.type];
        break;
      case 'Status':
        textToShow = RewardGameStatus[element.status];
        break;
      case 'Summary':
        textToShow = this.getRewardGameSummary(element);
        break;
      case 'Sales Person':
        if (element.managerId && this.employees) {
          textToShow = "Manager: " + this.employees.find(x => x.id == element.managerId).user.name;
        } else if (element.employeeList.length == 1) {
          textToShow = element.employeeList[0];
        } else if (element.isEveryPerson) {
          textToShow = "Everyone";
        }
        else {
          textToShow = element.employeeList.length + " Employees";
        }
        break;
      case 'Metric':
        textToShow = element[columnDef.mappedToProperty].name;
        break;
      case 'Target':
        if (element.formula.isPercentage) {
          textToShow = element.target + "%";
        } else {
          textToShow = element.target;
        }
        break;
      case 'Achieved':
        if (element.formula.isPercentage) {
          textToShow = (element.achieved * 100).toFixed(2) + "%";
        } else {
          textToShow = (Math.round(element.achieved * 100) / 100).toString();
        }
        break;
      case 'CreatedDate':
      case 'StartDate':
      case 'EndDate':
        textToShow = moment(element[columnDef.mappedToProperty]).format(this._durationService.format1);
        break;
      default:
        textToShow = element[columnDef.mappedToProperty];
    }
    return textToShow;
  }

  getRewardGameSummary(game) {
    return this._rewardService.getRewardGameSummary(game);
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  selectedFilter: number = 0;
  createTemplate = { startDate: new Date(), endDate: new Date(), monthlyDate: moment().format('YYYY-MM') };
  startDate: Date;
  endDate: Date;
  minDate: Date;
  maxDate: Date;
  months: any;
  durations: Duration[];
  subscriptionToClients: Subscription;
  selectedClientId: number;
  clientLocationId: number;
  filteredLocations: Location[] = [];
  locations: Location[];
  formulas: Formula[];
  employees: Employee[];
  private debug = false;
  monthlyDuration: string;
  selectedDuration: Duration;
  createdByList: string[];
  rewardSearchRequest = new RewardsCreateSearchRequest();

  rewardGameList: RewardGameViewModel[];

  ngOnInit() {

    this.selectedFilter;
    this.durations = this._durationService.getDurationExtra();
    this.selectedDuration = this.durations.find(x => x.value === 0);

    this.months = this._durationService.getMonths();
    this.selectedClientId = this._authService.clientId();

    this.subscriptionToClients = this._locationService.currentActualClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        if (message) {
          this.selectedClientId = message;
          this.onClientSelected();
        }
      });

    this.subscriptionToClients = this._locationService.currentClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        if (message) {
          this.clientLocationId = message;
        }
      });
  }

  selectedColumns = this.getDisplayedColumns();
  columnsChanged(event: MatSelectChange) {
    for (var i = 0; i < this.columnDefinations.length; i++) {
      var column = this.selectedColumns.find(x => x == this.columnDefinations[i].mappedToProperty);
      if (column != null) {
        this.columnDefinations[i].hide = false;
      } else {
        this.columnDefinations[i].hide = true;
      }
    }
  }

  filterByProperty(collection, value, key) {
    return collection.filter(o => key.reduce((c, v) => c[v] || {}, o) === value);
  }

  async getRewards() {
    this._loaderService.show();
    this.tableready = false;
    this._loaderService.show();
    this._rewardService.getRewardGamesForAdmin(this.selectedClientId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async (message) => {
        if (message) {
          this.rewardGameList = message;
          this.data = message;
          await this.applySelectedFilter();
          this.tableready = true;
          this.createdByList = this.rewardGameList.map(item => item.createdByUserName)
            .filter((value, index, self) => self.indexOf(value) === index);
          this._loaderService.hide();
        }
      });;
  }

  getFormulas() {
    this._formulaService.getFormulas(this.selectedClientId, (response) => {
      this.formulas = response.filter(x => !x.formulaValue.includes("GOAL") && !x.formulaValue.includes("DATE"));
      this.formulas = this.formulas.filter(x => x.containsComplexFormula === false).sort((a, b) => a.name.localeCompare(b.name));
    }, true);
  }

  onFormulaSelected() {
    var formula = this.formulas.find(x => x.id == this.rewardGame.formulaId);
    console.log(formula);
    if (formula.formulaValue.includes("REBIZ") || formula.formulaValue.includes("HOURS")) {
      this.dialog.blockNonMonthly = true;
      this.rewardGame.monthly = true;
    } else {
      this.dialog.blockNonMonthly = false;
    }
  }

  onClientSelected() {

    this._employeeService.getEmployeesByLocationCallback((response) => {
      this.employees = response.filter(x => x.location.clientId == this.selectedClientId && x.user.userStatusCode.id === Status.Active);
      this.getFormulas();
      this.getRewards();
      this._locationService.getLocationsByClientId(this.selectedClientId, (response) => {
        this.locations = response.filter(x => x.locationTypeCodeId == 4);
      });
    });
  }





  getDisplayedColumns(): string[] {
    return this.columnDefinations.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
  }


  ngOnDestroy() {
    this.columnDefinations = null;
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
    this.dialogRef?.close();
  }

  getColumnDefinationByMappedToProperty(mappedToProperty) {
    var column = this.columnDefinations.find(x => x.mappedToProperty == mappedToProperty);
    switch (column.name) {
      case 'Cost Variance':
        column.name = 'Cost Variance\n(Received-Purchase)';
        break;
    }
    return column;
  }

  async filterChanged(event: MatSelectChange) {
    this.selectedFilter = event.value;
    this.applySelectedFilter();
  }

  async applySelectedFilter() {
    this.data = this.rewardGameList;
    if (this.filterMenu.status != -1) {
      this.data = this.data.filter(x => x.status == this.filterMenu.status);
    }
    if (this.filterMenu.type != -1) {
      this.data = this.data.filter(x => x.type == this.filterMenu.type);
    }
    if (this.filterMenu.formula != -1) {
      this.data = this.data.filter(x => x.formulaId == this.filterMenu.formula);
    }
    if (this.filterMenu.createdBy != 'Everyone') {
      this.data = this.data.filter(x => x.createdByUsername == this.filterMenu.createdBy);
    }
    if (this.filterMenu.duration != -1) {

      this.data = this.data.filter(x => (

        (x.startDate <= this.durations[this.filterMenu.duration].duration.FromDate && x.endDate >= this.durations[this.filterMenu.duration].duration.FromDate) ||
        (x.startDate <= this.durations[this.filterMenu.duration].duration.ToDate && x.endDate >= this.durations[this.filterMenu.duration].duration.ToDate) ||
        (x.startDate >= this.durations[this.filterMenu.duration].duration.FromDate && x.endDate <= this.durations[this.filterMenu.duration].duration.ToDate)

      )

      );
    }
    this.updateDataSource(this.data);
  }


  tableready = false;
  dataSource = new MatTableDataSource();
  data: any;
  updateDataSource(data) {
    let filteredItems = data;
    if (filteredItems) {
      this.dataSource = new MatTableDataSource(filteredItems);

      this.dataSource.sortingDataAccessor = (item: any, property) => {
        switch (property) {
          case 'formula':
            return item.formula.name;
          default: return item[property];
        }
      };
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.applyFilter(this.rewardSearchRequest.SearchTerm);
    }
  }

  applyFilter(filterValue: string) {
    if (filterValue) {
      this.dataSource.filterPredicate = this.filterPredicate;
      this.dataSource.filter = filterValue.trim().toLowerCase();

    } else {
      this.dataSource.filter = "";
    }

  }

  filterPredicate = (data: any): boolean => {
    let filterValue = this.rewardSearchRequest.SearchTerm.toLowerCase();
    if (
      RewardGameType[data.type].trim().toLowerCase().indexOf(filterValue) !== -1
      ||
      data.formula.name.trim().toLowerCase().indexOf(filterValue) !== -1
      ||
      RewardGameStatus[data.status].trim().toLowerCase().indexOf(filterValue) !== -1
      ||
      (data.manager && this.employees.find(x => x.id == data.managerId).user.name.trim().toLowerCase().indexOf(filterValue) !== -1)
      ||
      (data.startDate.trim().toLowerCase().indexOf(filterValue) !== -1)
      ||
      (data.endDate.trim().toLowerCase().indexOf(filterValue) !== -1)
      ||
      (data.createdDate.trim().toLowerCase().indexOf(filterValue) !== -1)
      ||
      (data.employeeList.filter(x => x.rqUsername.trim().toLowerCase().indexOf(filterValue) !== -1).length > 0)
      ||
      data.name.trim().toLowerCase().indexOf(filterValue) !== -1
      ||
      data.totalPayout.toString().trim().toLowerCase().indexOf(filterValue) !== -1
      ||
      data.target.toString().trim().toLowerCase().indexOf(filterValue) !== -1
      ||
      data.achieved.toString().trim().toLowerCase().indexOf(filterValue) !== -1
    ) {
      return true;
    }
    return false;
  };

  filterByFormulaName(filter: string): any {
    const dataFiltered = this.data.filter((item) => {
      return item.formula.name.indexOf(filter) > -1
    })
    return dataFiltered;
  }

  dialog: any = {
    isOpen: false,
    headerText: '',
    blockNonMonthly: false,
    isEdit: false,
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    }
  };

  saveRewardGame() {
    this._rewardService.createRewardGame(this.rewardGame).subscribe((response: any) => {
      this.dialog.isOpen = false;
      this._rewardService.getRewardGamesForAdmin(this.selectedClientId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(message => {
          if (message) {
            this.rewardGameList = message;
            this.applySelectedFilter();
            this.tableready = true;
          }
        });;
    }, (error) => { console.log(error); this._notifier.notify('error', error.error) }, () => { this._notifier.notify('success', "The game has been saved successfully") });
  }


  rewardGame: RewardGameViewModel;
  rewardGameTypes = RewardGameType;
  rewardGameTypesKeys = Object.values(RewardGameType).filter(key => !isNaN(Number(key)));

  managerRewardGameTypesKeys = Object.values(RewardGameType).filter(key => !isNaN(Number(key)) && key !== 0);

  rewardGameStatuses = RewardGameStatus;
  rewardGameStatusesKeys = Object.values(RewardGameStatus).filter(key => !isNaN(Number(key)));

  editReward(element: RewardGameViewModel) {

  }

  confirmDeleteModal: any = {
    isOpen: false,
    rewardGameToDelete: null,
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    }
  }
  private dialogRef: any;
  openDeleteModal(element: RewardGameViewModel) {
    this.dialogRef = this.matDialog.open(ConfirmDialogComponent,
      {
        autoFocus: true, disableClose: false, panelClass: '',
        data: { title: "Confirm", message: "Are you sure you would like to delete this Game permanently?" }
      });
    this.dialogRef.afterClosed().subscribe(result => { if (result === true) { this.deleteRewardGame(); } });
    this.confirmDeleteModal.rewardGameToDelete = element;
  }


  deleteRewardGame() {
    this._rewardService.deleteRewardGame(this.confirmDeleteModal.rewardGameToDelete.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this._rewardService.getRewardGamesForAdmin(this.selectedClientId)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(message => {
            if (message) {
              this.confirmDeleteModal.isOpen = false;
              this.rewardGameList = message;
              this.applySelectedFilter();
              this.tableready = true;
            }
          });;
      }, (error) => this._notifier.notify('error', error.message), () => { this._notifier.notify('success', "The game has been deleted successfully") });
  }

  openCreateModal() {
    this.rewardGame = new RewardGameViewModel(RewardGameType.Competition, RewardGameStatus.Active, false, true, true, 3);
    this.dialogRef = this.matDialog.open(RewardsCreateDialogComponent,
      {
        autoFocus: true, disableClose: true, panelClass: '',
        data: {
          title: 'Create New Reward Game', rewardGame: this.rewardGame, formulas: this.formulas, employees: this.employees, locations: this.locations, isEdit: false,
          managerRewardGameTypesKeys: this.managerRewardGameTypesKeys, rewardGameTypesKeys: this.rewardGameTypesKeys, rewardGameTypes: this.rewardGameTypes,
          createTemplate: this.createTemplate, rewardGameStatusesKeys: this.rewardGameStatusesKeys, rewardGameStatuses: this.rewardGameStatuses
        }
      });
    this.dialogRef.afterClosed().subscribe(result => { if (result !== null) { this.saveRewardGame(); } });

    this.dialog.isOpen = true;
    this.dialog.isEdit = false;
    this.dialog.headerText = 'Create New Reward Game';
    this.setDatesUsingMonth();
  }

  openEditModal(element: RewardGameViewModel) {
    //#region setup
    this.rewardGame = new RewardGameViewModel(element.type, element.status, element.recurring, element.monthly, element.multipleEmployees, element.winnerCount);
    this.rewardGame.id = element.id;
    this.rewardGame.name = element.name;
    this.rewardGame.description = element.description;
    this.rewardGame.formulaId = element.formulaId;
    this.rewardGame.employeeList = element.employeeList;
    this.rewardGame.winnerArray = element.winnerArray;
    this.rewardGame.payPer = element.payPer;
    this.rewardGame.payMinimumCount = element.payMinimumCount;
    this.rewardGame.minimumCount = element.minimumCount;
    this.rewardGame.maximumCount = element.maximumCount;
    this.rewardGame.doNotPayBeforeIntervals = element.doNotPayBeforeIntervals;
    this.rewardGame.target = element.target;
    this.rewardGame.isEveryPerson = element.isEveryPerson;
    this.rewardGame.isEveryStore = element.isEveryStore;

    this.rewardGame.startDate = new Date(element.startDate);
    this.rewardGame.endDate = new Date(element.endDate);
    this.rewardGame.monthlyDuration = moment(element.monthlyDuration).format('YYYY-MM');
    this.onFormulaSelected();
    //this.monthlyDuration = moment(element.monthlyDuration).format('YYYY-MM');

    var empList: Employee[] = new Array();
    element.employeeList.forEach((emp) => {
      empList.push(this.employees.find(x => x.id == emp.id));
    });
    var locList: Location[] = new Array();
    element.locationList.forEach((loc) => {
      locList.push(this.locations.find(x => x.id == loc.id));
    });
    this.rewardGame.selectAllEmployees(empList, true);
    this.rewardGame.selectAllStores(locList, true);

    this.rewardGame.managerId = element.managerId;
    this.rewardGame.managerLocationId = element.managerLocationId;
    //#endregion setup

    this.dialog.isOpen = true;
    this.dialog.headerText = 'Edit Reward Game';
    this.dialog.isEdit = true;
    this.createTemplate.endDate = new Date(element.endDate);
    this.createTemplate.startDate = new Date(element.startDate);
    this.createTemplate.monthlyDate = element.monthlyDuration;
    if (this.rewardGame.monthly == true) {
      this.setDatesUsingMonth();
    }

    this.rewardGame.updateWinnerCount();
    this.dialogRef = this.matDialog.open(RewardsCreateDialogComponent,
      {
        autoFocus: true, disableClose: true, panelClass: '',
        data: {
          title: 'Edit Reward Game', rewardGame: this.rewardGame, formulas: this.formulas, employees: this.employees, locations: this.locations, isEdit: true,
          managerRewardGameTypesKeys: this.managerRewardGameTypesKeys, rewardGameTypesKeys: this.rewardGameTypesKeys, rewardGameTypes: this.rewardGameTypes,
          createTemplate: this.createTemplate, rewardGameStatusesKeys: this.rewardGameStatusesKeys, rewardGameStatuses: this.rewardGameStatuses
        }
      });
    this.dialogRef.afterClosed().subscribe(result => { if (result !== null) { this.saveRewardGame(); } });
  }

  durationChanged(event: MatSelectChange) {
    //this.loadSelectedMonth();
    //this.onDurationChanged();
  }

  setDatesUsingMonth() {
    var dateRange = this._durationService.getDateRangeByYearWithUTC(moment(this.createTemplate.monthlyDate).format("YYYY-MM"));
    this.rewardGame.monthlyDuration = this.createTemplate.monthlyDate;
    this.rewardGame.startDate = new Date(dateRange.start.format(this._durationService.dateStartFormat).toString());
    this.rewardGame.startDate = new Date(this.rewardGame.startDate.getUTCDate() + this.rewardGame.startDate.getTime() - this.rewardGame.startDate.getTimezoneOffset() * 60 * 1000);
    this.rewardGame.endDate = new Date(dateRange.end.format(this._durationService.dateEndFormat).toString());
    this.rewardGame.endDate = new Date(this.rewardGame.endDate.getUTCDate() + this.rewardGame.endDate.getTime() - this.rewardGame.endDate.getTimezoneOffset() * 60 * 1000);
  }

  startDateChanged(type: string, event: MatDatepickerInputEvent<Date>) {
    var start = new Date(moment(event.value).format(this._durationService.dateStartFormat));
    this.rewardGame.startDate = new Date(start.getUTCDate() + start.getTime() - start.getTimezoneOffset() * 60 * 1000);
  }

  endDateChanged(type: string, event: MatDatepickerInputEvent<Date>) {
    var end = new Date(moment(event.value).format(this._durationService.dateEndFormat));
    this.rewardGame.endDate = new Date(end.getUTCDate() + end.getTime() - end.getTimezoneOffset() * 60 * 1000);
    this.createTemplate.startDate = this.createTemplate.startDate;
  }

  cleanMath(number) {
    if ((number * 100 % 100) != 0) {
      let newNumber = parseFloat(number).toPrecision(2);
      return newNumber;
    }
    return number;
  }
}
