import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';
import { CategoryService } from '../services/category.service';
import { ClientService } from '../services/client.service';
import { LocationService } from '../services/location.service';
import { TableService } from '../services/table.service';
import { AuthService } from '../../core/services/auth.service';
import { InventoryService } from '../services/inventory.service';
import { LocationTypeCode } from '../models/location-type-code';
import { NotifierService } from 'angular-notifier';
import { Location } from '../models/location';
import { Category } from '../models/category';
import { SettingService } from '../../home/users/services/setting.service';
import { ReportType } from '../../home/users/models/reportType';
const CONFIG_CLIENT_KEY = 'ConfigClient';
const CONFIG_REGION_KEY = 'ConfigRegion';
const CONFIG_DISTRICT_KEY = 'ConfigDistrict';
const CONFIG_STORE_KEY = 'ConfigStore';

@Component({
  selector: 'app-inventory-sunburst',
  templateUrl: './inventory-sunburst.component.html'
})
export class InventorySunburstComponent implements OnInit {
  clients: any[] = [];
  clientId: number;
  selectedClientId: number;
  selectedClient: any;
  regions: any[] = [];
  selectedRegionId: number;
  selectedRegion: any;
  districts: any[] = [];
  selectedDistrictId: number;
  selectedDistrict: any;
  stores: any[] = [];
  selectedStore: any;
  selectedStoreId: number;
  categories: Category[] = [];
  selectedCategory: Category;
  selectedCategoryId: number;
  attributes: any[] = [];
  inventories: any[] = [];
  inventoryTotals: any[] = [];
  categorySearchString = null;
  selectedCategoryName = null;
  categoryLoadingInProgress: boolean;
  localStorageLoadingInProgress: boolean;
  inventoryLoadingInProgress: boolean;
  clientName: string;
  reportTypes: ReportType[] = [];
  selectedReportType: ReportType;
  filteredLocations: Location[] = [];
  locations: Location[] = [];
  inventoryData: any;

  constructor(private _locationService: LocationService,
    private _categoryService: CategoryService,
    private _inventoryService: InventoryService,
    private _notifier: NotifierService,
    private _tableService: TableService,
    private _clientService: ClientService,
    private _authService: AuthService,
    private _settingService: SettingService) { }


  getInventoryTree() {
    const tierId = 5;
    this._inventoryService.getTreeWithCostValuesForStore()
      .subscribe((response: any[]) => {
        this.inventoryData = response;
      }, (error) => this._notifier.notify('error', error.error), () => { });
  }

  buildComplicatedChart() {
    const data = this.inventoryData;
    console.log(data);
    const width2 = 975;
    const radius = width2 / 2;
    function autoBox() {
      const { x, y, width, height } = this.getBBox();
      return [x, y, width, height];
    }
    const arc = d3.arc()
      .startAngle(d => d.x0)
      .endAngle(d => d.x1)
      .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(radius / 2)
      .innerRadius(d => d.y0)
      .outerRadius(d => d.y1 - 1);

    const partition = dataB => d3.partition()
      .size([2 * Math.PI, radius])
      (d3.hierarchy(dataB)
        .sum(d => d.value)
        .sort((a, b) => b.value - a.value));

    const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, data.children.length + 1));

    const format = d3.format(',d');









      const root = partition(data);

      const svg = d3.select('#px').append('svg')
        .style('max-width', '40%')
        .style('height', 'auto')
        .style('font', '10px sans-serif')
        .style('margin', '5px');

      svg.append('g')
        .attr('fill-opacity', 0.6)
        .selectAll('path')
        .data(root.descendants().filter(d => d.depth))
        .enter().append('path')
        .attr('fill', d => { while (d.depth > 1) { d = d.parent; } return color(d.data.name); })
        .attr('d', arc)
        .append('title')
        .text(d => `${d.ancestors().map(e => e.data.name).reverse().join('/')}\n$${format(d.value)}`);

      svg.append('g')
        .attr('pointer-events', 'none')
        .attr('text-anchor', 'middle')
        .selectAll('text')
        .data(root.descendants().filter(d => d.depth && (d.y0 + d.y1) / 2 * (d.x1 - d.x0) > 10))
        .enter().append('text')
        .attr('transform', function (d) {
          const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
          const y = (d.y0 + d.y1) / 2;
          return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
        })
        .attr('dy', '0.35em')
        .text(d => d.data.name);

      svg.node();

    svg.attr('viewBox', autoBox);



  }

  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  ngOnInit() {
    this.getInventoryTree();
    if (!this._authService.isSuperAdmin()) {
      this._clientService.getClientNameById(this._authService.clientId(), (name) => { this.clientName = name; });
    }
    this._locationService.getLocations(LocationTypeCode.Client, (clientResponse) => {
      this.clients = clientResponse;
    });
    // this._locationService.currentClient.subscribe(message => this.onClientSelected(message));
    // this._locationService.currentActualClient.subscribe(message => this.reload(message));
    // this._locationService.currentRegion.subscribe(message => this.onRegionSelected(message));
    // this._locationService.currentDistrict.subscribe(message => this.onDistrictSelected(message));
    // this._locationService.currentStore.subscribe(message => message));
  }

  reload(clientId: number) {
    this.clientId = clientId;
    this._locationService.getAllLocations().subscribe((response) => {
      this.locations = response;

      this._settingService.getReportTypes().subscribe((reports) => {
        this.reportTypes = reports;
        this.selectedReportType = this.reportTypes.find(x => x.routing === '/inventory');
        if (this._authService.isSuperAdmin()) {
          this.filteredLocations = this.locations.filter(location =>
            location.clientId === this.clientId);
        } else {
          this._settingService.getLocationsForReport(this._authService.userId(), this.selectedReportType.id).subscribe((locs) => {
            this.filteredLocations = locs;
          });
        }
      });
    });
  }
  isLocationVisible(name: string) {
    if (name === 'Sku' || name === 'Product') {
      return true;
    }
    return this.filteredLocations.map(x => x.name).includes(name);
  }
    /*
    if (localStorage.getItem(CONFIG_CLIENT_KEY)) {
      this.localStorageLoadingInProgress = true;
      this._locationService.getLocations(LocationTypeCode.Client, (clientResponse) => {
        this.clients = clientResponse;
        this.selectedClientId = +localStorage.getItem(CONFIG_CLIENT_KEY);
        if (!this._authService.isSuperAdmin()) {
          this.selectedClientId = +this.clients.find(x => x.clientId === +this._authService.clientId()).id;
        }
        if (this.selectedClientId) {
          this._locationService.getLocationsByTypeAndParents(LocationTypeCode.Region, (regionResponse) => {
            this.regions = regionResponse;
            if (localStorage.getItem(CONFIG_REGION_KEY)) {
              this.selectedRegionId = +localStorage.getItem(CONFIG_REGION_KEY);
              if (this.selectedRegionId) {
                this._locationService.getLocations(LocationTypeCode.District, (districtResponse) => {
                  this.districts = districtResponse;
                  if (localStorage.getItem(CONFIG_DISTRICT_KEY)) {
                    this.selectedDistrictId = +localStorage.getItem(CONFIG_DISTRICT_KEY);
                    if (this.selectedDistrictId) {
                      this.selectedDistrict = this.districts.find(x => x.id === this.selectedDistrictId);
                      this._locationService.getLocations(LocationTypeCode.Store, (storeResponse) => {
                        this.stores = storeResponse;
                        this.localStorageLoadingInProgress = false;
                        this.setInventoryAttributes();
                        if (localStorage.getItem(CONFIG_STORE_KEY)) {
                          this.selectedStoreId = +localStorage.getItem(CONFIG_STORE_KEY);
                          if (this.selectedStoreId) {
                            this.selectedStore = this.stores.find(x => x.id === this.selectedStoreId);
                            this.getCategories(this.selectedStoreId, this.selectedStore.clientId);
                          } else {
                            this.selectedStoreId = 0;
                            this.getCategories(this.selectedDistrictId, this.selectedDistrict.clientId);
                          }
                        } else {
                          this.selectedStoreId = 0;
                          this.getCategories(this.selectedDistrictId, this.selectedDistrict.clientId);
                        }
                      }, this.selectedDistrictId);
                    } else {
                      this.localStorageLoadingInProgress = false;
                      this.selectedDistrictId = 0, this.selectedStoreId = 0;
                    }
                  } else {
                    this.localStorageLoadingInProgress = false;
                    this.selectedDistrictId = 0, this.selectedStoreId = 0;
                  }
                }, this.selectedRegionId);
              } else {
                this.localStorageLoadingInProgress = false;
                this.selectedRegionId = 0, this.selectedDistrictId = 0, this.selectedStoreId = 0;
              }
            } else {
              this.localStorageLoadingInProgress = false;
              this.selectedRegionId = 0, this.selectedDistrictId = 0, this.selectedStoreId = 0;
            }
          }, [this.selectedClientId]);
        } else {
          this.localStorageLoadingInProgress = false;
          this.selectedClientId = 0, this.selectedRegionId = 0, this.selectedDistrictId = 0, this.selectedStoreId = 0;
        }
      });
    } else {
      if (this._authService.isSuperAdmin()) {
        this.getClients();
        this.selectedClientId = 0, this.selectedRegionId = 0, this.selectedDistrictId = 0, this.selectedStoreId = 0;
      } else {
        this.selectedClientId = this._authService.clientId();
        this._locationService.getLocationsByTypeAndClientId(LocationTypeCode.Region, (regionResponse) => {
          this.regions = regionResponse;
          this.selectedRegionId = 0, this.selectedDistrictId = 0, this.selectedStoreId = 0;
        }, this.selectedClientId);
      }
    }
   */
  getClients() {
    this._locationService.getLocations(LocationTypeCode.Client, (response) => {
      this.clients = response;
    });
  }

  onClientSelected(id: number) {
    // if (+this.selectedClientId === 0) {
      // return;
    // }

    if (id !== 0) {

      this.categorySearchString = null;
      this.selectedClientId = +id;
      this.selectedRegionId = 0, this.selectedDistrictId = 0, this.selectedStoreId = 0;
      this.selectedClient = this.clients.find(r => r.id === this.selectedClientId);
      this._locationService.getLocations(LocationTypeCode.Region, (response) => {
        this.regions = response;
      }, this.selectedClient.id);
    }
  }

  onRegionSelected(id: number) {
    // if (+this.selectedRegionId === 0) {
      // return;
    // }

    if (id !== 0) {
      this.categorySearchString = null;
      this.selectedRegionId = +id;
      this.selectedDistrictId = 0, this.selectedStoreId = 0;
      this.selectedRegion = this.regions.find(r => r.id === this.selectedRegionId);
      this._locationService.getLocations(LocationTypeCode.District, (response) => {
        this.districts = response;
      }, this.selectedRegion.id);
    }
  }

  onDistrictSelected(id: number) {
    // if (+this.selectedDistrictId === 0) {
     // return;
    // }
    if (id !== 0) {
      console.log('DISTRICT ' + id);
      this.categorySearchString = null;
      this.selectedStoreId = 0, this.selectedDistrictId = +this.selectedDistrictId;
      this.selectedDistrictId = id;
      this.selectedDistrict = this.districts.find(r => r.id === this.selectedDistrictId);
      this._locationService.getLocations(LocationTypeCode.Store, (response) => {
        this.stores = response;
        this.setInventoryAttributes();
      }, this.selectedDistrict.id);


      // this.getCategories(this.selectedDistrictId, this.selectedDistrict.clientId);
    }
  }

  setInventoryAttributes() {
    this.attributes = [{ name: 'sku', header: 'Sku', ascending: true, sorting: true },
      { name: 'name', header: 'Product', ascending: true, sorting: true }];

    this.stores.forEach((store) => {
      this.attributes.push({
        name: this.getLocationKey(store.name),
        header: store.name
      });
    });
  }

  onStoreSelected(id: number) {
   // if (false && +this.selectedStoreId === 0) {
     // this.onDistrictSelected(0);
    // } else {
    if (+id !== 0) {
      console.log('STORE ' + id);
      // this.selectedStoreId = +this.selectedStoreId;
      this.selectedStoreId = +id;
        this.selectedStore = this.stores.find(s => s.id === this.selectedStoreId);
        this.getCategories(this.selectedStoreId, this.selectedStore.clientId);
     // }
    }
  }

  getCategories(location: number, clientId: number) {
    this.categoryLoadingInProgress = true;
    this._categoryService.getCategoryTreeWithInventoryCount(location, clientId)
      .subscribe((response: Category[]) => {
        this.categories = response;
        this.categoryLoadingInProgress = false;
        if (this.categories.length === 0) {
          this.selectedStoreId = 0;
        }
      }, (error) => this._notifier.notify('error', error.error), () => { });

    localStorage.setItem(CONFIG_CLIENT_KEY, (this.selectedClientId ? +this.selectedClientId : 0).toString());
    localStorage.setItem(CONFIG_REGION_KEY, (this.selectedRegionId ? +this.selectedRegionId : 0).toString());
    localStorage.setItem(CONFIG_DISTRICT_KEY, (this.selectedDistrictId ? +this.selectedDistrictId : 0).toString());
    localStorage.setItem(CONFIG_STORE_KEY, (this.selectedStoreId ? +this.selectedStoreId : 0).toString());
  }

  onCategorySelected(category) {
    this.selectedCategoryName = category.name;
    this.selectedCategoryId = +category.id;
    this.inventoryLoadingInProgress = true;
    this._inventoryService.getInventory(this.selectedDistrictId, this.selectedCategoryId)
      .subscribe((response: any[]) => {
        this.inventoryLoadingInProgress = false;
        this.inventories = response['products'];
        const total = {};
        total['sku'] = null, total['name'] = null;
        this.inventories.forEach((product) => {
          product.locations.forEach((location) => {
            product[this.getLocationKey(location.name)] = location.quantity;
            total[this.getLocationKey(location.name)] = (total[this.getLocationKey(location.name)]) ?
              total[this.getLocationKey(location.name)] + location.quantity :
              location.quantity;
          });
        });
        this.inventoryTotals.length = 0, this.inventoryTotals.push(total);
      }, (error) => this._notifier.notify('error', error.error), () => { });
  }

  getLocationKey(name: string) {
    return name.split(' ').join('_').toLowerCase();
  }

  searchCategory(array: any = null) {
    array = array ? array : this.categories;

    for (const key in array) {
      if (typeof array[key] === 'object') {
        this.searchCategory(array[key]);
      } else {
        if (key === 'name') {
          if (array.name.toLowerCase().indexOf(this.categorySearchString.toLowerCase()) > -1) {
            array.isVisible = true;
          } else {
            array.isVisible = false;
          }
        }
      }
    }
  }
}
