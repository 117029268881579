import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../../core/services/auth.service';
import { ClientService } from '../services/client.service';
import { UploadConfigService } from '../services/upload-config.service';
import { LocationService } from '../services/location.service';
import { LocationTypeCode } from '../models/location-type-code';
import { ConfigUploadProfileType } from '../models/config-upload-profile-type';
import { ConfigSheet } from '../models/config-sheet';
import { ConfigSheetTable } from '../models/config-sheet-table';
import { ConfigTableColumn } from '../models/config-table-column';
import { ConfigFunctionsCalledInTable } from '../models/config-function-sheet';
import { ConfigVariable } from '../models/config-variable';
import { ConfigFunction } from '../models/config-function';
import { ConfigTableColumnVariable } from '../models/config-table-column-variable';
import { ReplaySubject } from 'rxjs';



@Component({
  selector: 'app-upload-config',
  templateUrl: './upload-config.component.html'
})
export class UploadConfigComponent implements OnInit, OnDestroy {

  listOfAllSheets: ConfigSheet[] = [];
  listOfAllFunctions: ConfigFunction[] = [];
  listOfAllSheetTables: ConfigSheetTable[] = [];
  listOfAllTableColumns: ConfigTableColumn[] = [];
  listOfAllFunctionsCalledInTable: ConfigFunctionsCalledInTable[] = [];
  listOfAllVariables: ConfigVariable[] = [];
  listOfAllTableColumnVariables: ConfigTableColumnVariable[] = [];
  uploadConfigProfiles: ConfigUploadProfileType[] = [];
  uploadProfiles: any = [];
  selectedProfile: ConfigUploadProfileType = null;
  selectedSheet: ConfigSheet = null;
  selectedSheetTable: ConfigSheetTable = null;
  selectedFunction: ConfigFunction = null;
  addingNewFunction: ConfigFunction = null;
  addingNewVariable: ConfigVariable = null;
  addingNewColumn: ConfigTableColumn = null;
  selectedFunctionCalledInTable: ConfigFunctionsCalledInTable = null;
  selectedVariable: ConfigVariable = null;
  notUsedVariables: ConfigVariable[] = [];
  selectedOption = 0;
  clients: any = [];
  doneLoading = false;
  table: any = {
    sorting: true,
    toggleColumns: true
  };

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private _notifier: NotifierService,
    private _clientService: ClientService,
    private _uploadConfigService: UploadConfigService,
    private _locationService: LocationService,
    public _authService: AuthService) {  }


  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
  }

  getAllProfiles() {
    this.doneLoading = false;
    this._uploadConfigService.getAllUploadProfiles().subscribe((response: ConfigUploadProfileType[]) => {
      this.uploadConfigProfiles = response;
      this.doneLoading = true;
      this.selectedProfile = this.uploadConfigProfiles[0];
      this.selectedOption = 1;
      this._notifier.notify('success', 'Loaded successfully.');
    }, (error) => this._notifier.notify('error', error.message), () => { });
  }
  getAllSheets() {
    this._uploadConfigService.getAllSheets().subscribe((response: ConfigSheet[]) => {
      this.listOfAllSheets = response;
    });
  }
  getAllSheetTables() {
    this._uploadConfigService.getAllSheetTables().subscribe((response: ConfigSheetTable[]) => {
      this.listOfAllSheetTables = response;

    });
  }
  getAllTableColumn() {
    this._uploadConfigService.getAllTableColumns().subscribe((response: ConfigTableColumn[]) => {
      this.listOfAllTableColumns = response;
    });
  }
  getAllFunctions() {
    this._uploadConfigService.getAllFunctions().subscribe((response: ConfigFunction[]) => {
      this.listOfAllFunctions = response;
    });
  }
  getAllVariables() {
    this._uploadConfigService.getAllVariables().subscribe((response: ConfigVariable[]) => {
      this.listOfAllVariables = response;
    });
  }
  getAllFunctionsCalledInTable() {
    this._uploadConfigService.getAllFunctionsCalledInTable().subscribe((response: ConfigFunctionsCalledInTable[]) => {
      this.listOfAllFunctionsCalledInTable = response;
    });
  }
  getAllTableColumnVariable() {
    this._uploadConfigService.getAllTableColumnVariables().subscribe((response: ConfigTableColumnVariable[]) => {
      this.listOfAllTableColumnVariables = response;
    });
  }

  addSheet() {
    const newSheet = new ConfigSheet();
    newSheet.configSheetTables = [];
    newSheet.id = this.findNextIDInList(this.listOfAllSheets);
    newSheet.configUploadProfileTypeId = this.selectedProfile.id;
    this.selectedProfile.configSheets.push(newSheet);
    this.listOfAllSheets.push(newSheet);
  }

  addTable() {
    const newTable = new ConfigSheetTable();
    newTable.configFunctionsCalledInTable = [];
    newTable.configTableColumns = [];
    newTable.startingRow = 3;
    newTable.configSheetId = this.selectedSheet.id;
    newTable.id = this.findNextIDInList(this.listOfAllSheetTables);
    this.selectedProfile.configSheets.find(x => x.id === this.selectedSheet.id).configSheetTables.push(newTable);
    this.listOfAllSheetTables.push(newTable);

  }
  addTableColumn() {
    const newColumn = new ConfigTableColumn();
    newColumn.configTableColumnVariables = [];
    //console.log(this.selectedSheetTable);
    newColumn.configSheetTableId = this.selectedSheetTable.id;
    newColumn.id = this.findNextIDInList(this.listOfAllTableColumns);
    this.selectedProfile.configSheets.find(x => x.id === this.selectedSheet.id).configSheetTables
      .find(y => y.id === this.selectedSheetTable.id).configTableColumns.push(newColumn);
    this.listOfAllTableColumns.push(newColumn);
  }

  addFunctionToTable() {
    const newFCT = new ConfigFunctionsCalledInTable();
    newFCT.configTableColumnVariables = [];
    newFCT.configFunctionId = this.addingNewFunction.id;
    newFCT.configSheetTableId = this.selectedSheetTable.id;
    newFCT.configFunctionName = this.addingNewFunction.name;
    newFCT.id = this.findNextIDInList(this.listOfAllFunctionsCalledInTable);
    this.selectedProfile.configSheets.find(x => x.id === this.selectedSheet.id).configSheetTables
      .find(y => y.id === this.selectedSheetTable.id)
      .configFunctionsCalledInTable.push(newFCT);
    this.listOfAllFunctionsCalledInTable.push(newFCT);
  }

  addFunction() {
    const newFunction = new ConfigFunction();
    newFunction.configVariables = [];
    newFunction.configFunctionsCalledInTable = [];
    newFunction.configUploadProfileTypeId = this.selectedProfile.id;
    newFunction.id = this.findNextIDInList(this.listOfAllFunctions);
    this.selectedProfile.configFunctions.push(newFunction);
    this.listOfAllFunctions.push(newFunction);

  }
  findNextIDInList(list: any[]) {
    let count = 1;
    do {
      if (!list.find(x => x.id === count)) {
        return count;
      }
      count++;
    }
    while (count !== 0);
  }
  calculateUnusedVariables() {
    const functionId = this.selectedFunctionCalledInTable.configFunctionId;
    const totalListVariables = this.selectedProfile.configFunctions.find(f => f.id === functionId).configVariables;
    this.notUsedVariables = [];
    totalListVariables.forEach((x) => {
      if (!this.selectedFunctionCalledInTable.configTableColumnVariables.find(y => y.configVariableName === x.name)) {
        this.notUsedVariables.push(x);
      }
    });
  }

  addVariable() {
    const newVariable = new ConfigVariable();
    newVariable.id = this.findNextIDInList(this.listOfAllVariables);
    newVariable.configFunctionId = this.selectedFunction.id;
    this.selectedProfile.configFunctions.find(x => x.id === this.selectedFunction.id).configVariables.push(newVariable);
    this.listOfAllVariables.push(newVariable);
  }
  addFCV() {
    const newVariable = new ConfigTableColumnVariable();
    newVariable.id = this.findNextIDInList(this.listOfAllTableColumnVariables);
    newVariable.configTableColumnName = this.addingNewColumn.column;
    newVariable.configVariableName = this.addingNewVariable.name;
    newVariable.configFunctionsCalledInTableId = this.selectedFunctionCalledInTable.id;
    newVariable.configTableColumnId = this.addingNewColumn.id;
    newVariable.configVariableId = this.addingNewVariable.id;
    this.selectedProfile.configSheets.find(x => x.id === this.selectedSheet.id)
      .configSheetTables.find(y => y.id === this.selectedSheetTable.id)
      .configFunctionsCalledInTable.find(z => z.id === this.selectedFunctionCalledInTable.id).configTableColumnVariables.push(newVariable);
    this.listOfAllTableColumnVariables.push(newVariable);
  }

  saveProfile() {
    this._uploadConfigService.updateProfile(this.selectedProfile).subscribe((response) => {
      this._notifier.notify('success', 'Profile Updated');
      this.reloadProfile();
    }, (error) => this._notifier.notify('error', error.error), () => { });
  }

  copyProfile() {
    this._uploadConfigService.copyProfile(this.selectedProfile.id).subscribe((response) => {
      this._notifier.notify('success', 'Profile Copied');
      this.reloadProfile();
    }, (error) => this._notifier.notify('error', error.error), () => { });
  }

  reloadProfile() {
    this.getAllProfiles();
    this.resetSelections(1);
    this.getAllSheets();
    this.getAllSheetTables();
    this.getAllTableColumn();
    this.getAllTableColumnVariable();
    this.getAllVariables();
    this.getAllFunctionsCalledInTable();
    this.getAllFunctions();

  }

  resetSelections(limit: number) {
    if (limit === 1) {
      this.selectedSheet = null;
      this.selectedSheetTable = null;
      this.selectedFunction = null;
      this.addingNewFunction = null;
      this.selectedFunctionCalledInTable = null;
      this.selectedVariable = null;
    } else if (limit === 2) {
      this.selectedSheetTable = null;
      this.addingNewFunction = null;
      this.selectedFunctionCalledInTable = null;
    } else if (limit === 3) {
      this.addingNewFunction = null;
      this.selectedFunctionCalledInTable = null;
    }
  }
  getConfigUploadProfile() {
    this._uploadConfigService.getUploadConfigObject()
      .subscribe((response: any) => {
        this.uploadConfigProfiles = response;
        //console.log(response);
        this.selectedProfile = response[0];
      }, (error) => this._notifier.notify('error', error.error), () => { });
  }
  ngOnInit() {
    this.getAllProfiles();
    this.getAllSheets();
    this.getAllSheetTables();
    this.getAllTableColumn();
    this.getAllTableColumnVariable();
    this.getAllVariables();
    this.getAllFunctionsCalledInTable();
    this.getAllFunctions();
  }

  getClients() {
    this._locationService.getLocations(LocationTypeCode.Client, (response) => {
      this.clients = response;
    });
  }
}
