<div class="col-md-12 bg-white mb-3 p-3">
  <div class="row">
    <div class="col-md-3">
      <mat-form-field style="" class="searchtab">
        <input matInput (keyup)="applyFilter()" placeholder="Search" [(ngModel)]="searchTerm">
      </mat-form-field>
    </div>
    <div class="col-md-9 pt-2 text-right">
      <button mat-raised-button (click)="sendListOfImeis(5)" style=" margin-right: 10px" color="primary" class="charge-btn">Reconcile</button>&nbsp;
      <button mat-raised-button (click)="sendListOfImeis(2)" style=" margin-right: 10px" color="primary" class="dismiss-btn">Chargeback</button>
     
    </div>

  </div>
</div>

<div class="">
  <div class="row m-0">
  <div class="col-md-6 pl-0" style="">
    <div class="table-container m-1">
   
   <table mat-table [dataSource]="dataSource" matSort matSortActive="symbol" matSortDirection="asc" class="table table-bordered table-hover small-txt">
      <ng-container matColumnDef="{{column}}" *ngFor="let column of selectedColumns; let i = index;">
        <ng-container *ngIf="i === 0">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-sort-header-button-custom">
            <mat-checkbox (change)="$event?selectAll($event):null" [checked]="allSelected" [disabled]="noData| async" ></mat-checkbox>
          </th>
        </ng-container>
        <ng-container *ngIf="i !== 0">
          <th class="mat-sort-header-button-custom" mat-header-cell *matHeaderCellDef mat-sort-header [matTooltip]="getColumnDefinationByMappedToProperty(column).name" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip>
            {{getColumnDefinationByMappedToProperty(column).name}}
          </th>
        </ng-container>
        <ng-container *ngIf="i === 0">
          <td mat-cell *matCellDef="let row"   [ngClass]="{'yellow':row?.imei==selectedItem?.imei && row?.mtn == selectedItem?.mtn}">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectImei(row):null" [checked]="row.isSelected"></mat-checkbox>
          </td>
        </ng-container>
        <ng-container *ngIf="column !== 'commissionIMEINotes'  && column !== 'imei'">
          <td mat-cell *matCellDef="let element"  [matTooltip]="getTextByDataDismissed(element,getColumnDefinationByMappedToProperty(column))" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip
              [ngClass]="{'yellow':element.imei==selectedItem.imei && element.mtn == selectedItem.mtn}">
            {{getTextByDataDismissed(element,getColumnDefinationByMappedToProperty(column))}}
          </td>
        </ng-container>
        <ng-container *ngIf="column === 'imei'">
          <td mat-cell *matCellDef="let element"  [matTooltip]="getTextByDataDismissed(element,getColumnDefinationByMappedToProperty(column))" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip
              [ngClass]="{'yellow':element.imei==selectedItem.imei && element.mtn == selectedItem.mtn}">
            <span *ngIf="getTextByDataDismissed(element,getColumnDefinationByMappedToProperty(column)).length > 0" (click)="openHistoryDialog(element)"><a>{{getTextByDataDismissed(element,getColumnDefinationByMappedToProperty(column))}}</a></span>
          </td>
        </ng-container>
        <!--<ng-container *ngIf="i > 0 && i < 10">
          <td mat-cell *matCellDef="let element" class="mat-cell" [matTooltip]="getTextByDataDismissed(element,getColumnDefinationByMappedToProperty(column))"
              [ngClass]="{'yellow':element.imei==selectedItem.imei && element.mtn == selectedItem.mtn}"
              (click)="openNotesDialog(column,element)">
            {{getTextByDataDismissed(element,getColumnDefinationByMappedToProperty(column))}}
          </td>
        </ng-container>-->
        <ng-container *ngIf="column === 'commissionIMEINotes'">


          <td mat-cell *matCellDef="let element"  [matTooltip]="getTextByDataDismissed(element,getColumnDefinationByMappedToProperty(column))" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip
              [ngClass]="{'yellow':element.imei==selectedItem.imei && element.mtn == selectedItem.mtn}"
              (click)="openNotesDialog(column,element)">
            <span><div class="fa fa-sticky-note">{{getTextByDataDismissed(element,getColumnDefinationByMappedToProperty(column))}}</div></span>
          </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="selectedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: selectedColumns;" (click)="selectItem(row)"></tr>
    </table>
    <div *ngIf="noData | async" class="no-records">
      No records found
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="10" showFirstLastButtons></mat-paginator>
  </div>
  
</div>
<div class="col-md-6 pr-0">
  <div class="table-container m-1">
  <mat-divider [vertical]="true" *ngIf="selectedImei" class="margin-left-1em"></mat-divider>
  <div class="column margin-left-1em rq-vz-data-table" *ngIf="selectedImei">
    <rq-vz-data-table [selectedImei]="selectedImei" *ngIf="selectedImei"></rq-vz-data-table>
  </div>
</div>

</div>
</div>
</div>
