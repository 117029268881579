import { animate, state, style, transition, trigger } from '@angular/animations';
import { Inject, OnInit, ViewChild,AfterViewInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotifierService } from 'angular-notifier';
import { ProductSearchService } from '../../services/product-search.service';

@Component({
  selector: 'product-search-dialog',
  templateUrl: 'product-search-dialog.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ProductSearchDialogComponent implements OnInit,AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  expandedElement: any;
  historyItems: any;
  displayedColumns: string[] = ['reportFrom', 'date', 'employee', 'parentDetail', 'dollarValue', 'notes'];

  displayedColumnsNotes: string[] = ['author', 'dateTime', 'message'];

  constructor(private dialogRef: MatDialogRef<ProductSearchDialogComponent>, private _productSearchService: ProductSearchService, private _notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dataSource.paginator = this.paginator; this.dataSource.sort = this.sort;
    },0);
  }
  ngOnInit() {
    this.searchItem();
  }
  selectItem(row) {
    this.expandedElement = this.expandedElement === row ? null : row;
    console.log(this.expandedElement);
  }
  dataSource = new MatTableDataSource([]);
  searchItem() {
    console.log(this.data);
    this._productSearchService.getProductHistory(this.data.element.imei).subscribe((response) => {
      console.log(response);
      this.dataSource = new MatTableDataSource(response);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator; this.dataSource.sort = this.sort;
      },0);
    });
  }

}
