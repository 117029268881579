import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { CategoryService } from '../services/category.service';
import { ClientService } from '../services/client.service';
import { LocationService } from '../services/location.service';
import { TableService } from '../services/table.service';
import { AuthService } from '../../core/services/auth.service';
import { InventoryService } from '../services/inventory.service';
import { LocationTypeCode } from '../models/location-type-code';
import { NotifierService } from 'angular-notifier';
import { Location } from '../models/location';
import { Category } from '../models/category';
import { SettingService } from '../../home/users/services/setting.service';
import { ReportType } from '../../home/users/models/reportType';
import { ReplaySubject, Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { takeUntil } from 'rxjs/operators';
import { ArrayDataSource } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { LoaderService } from '../services/loader.service';

const CONFIG_CLIENT_KEY = 'ConfigClient';
const CONFIG_REGION_KEY = 'ConfigRegion';
const CONFIG_DISTRICT_KEY = 'ConfigDistrict';
const CONFIG_STORE_KEY = 'ConfigStore';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html'
})
export class InventoryComponent implements OnInit, OnDestroy {
  clients: any[] = [];
  clientId: number;
  selectedClientId: number;
  selectedClient: any;
  regions: any[] = [];
  selectedRegionId: number;
  selectedRegion: any;
  districts: any[] = [];
  selectedDistrictId: number;
  selectedDistrict: any;
  stores: any[] = [];
  selectedStore: any;
  selectedStoreId: number;
  categories: Category[] = [];
  selectedCategory: Category;
  selectedCategoryId: number;
  attributes: any[] = [];
  alternateHeaderAttributes: any[] = [];
  inventories: any[] = [];
  inventoryTotals: any[] = [];
  categorySearchString = null;
  selectedCategoryName = null;
  categoryLoadingInProgress: boolean;
  localStorageLoadingInProgress: boolean;
  inventoryLoadingInProgress: boolean;
  clientName: string;
  reportTypes: ReportType[] = [];
  selectedReportType: ReportType;
  filteredLocations: Location[] = [];
  locations: Location[] = [];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  subscriptionToStores: Subscription;
  subscriptionToDistricts: Subscription;
  subscriptionToRegions: Subscription;
  subscriptionToActualClient: Subscription;
  subscriptionToClients: Subscription;
  firstLoad = true;
  sku = '';
  name = '';

  constructor(private _locationService: LocationService,
    private _categoryService: CategoryService,
    private _inventoryService: InventoryService,
    private _notifier: NotifierService,
    private _tableService: TableService,
    private _clientService: ClientService,
    private _authService: AuthService,
    private _settingService: SettingService,
    private _loaderService: LoaderService) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource();



  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();


    const total = {};
    total['sku'] = null, total['name'] = null;
    this.dataSource.filteredData.forEach((product: any) => {
      product.locations.forEach((location) => {
        product[this.getLocationKey(location.name)] = location.quantity;
        total[this.getLocationKey(location.name)] = (total[this.getLocationKey(location.name)]) ?
          total[this.getLocationKey(location.name)] + location.quantity :
          location.quantity;
      });
    });
    this.inventoryTotals.length = 0, this.inventoryTotals.push(total);
  }

  getDisplayedColumns(): string[] {
    return this.attributes.map(cd => cd.name);;
  }

  getColumnDefinationByMappedToProperty(name) {
    var column = this.attributes.find(x => x.name == name);
    return column;
  }

  searchTerm: string;


  updateDataSource(data) {

    this.dataSource = new MatTableDataSource(data);
    setTimeout(() => { this.dataSource.paginator = this.paginator; this.dataSource.sort = this.sort; });
  }

  ngOnInit() {
    //console.log("Inventory : INIT");
    if (!this._authService.isSuperAdmin()) {
      this._clientService.getClientNameById(this._authService.clientId(), (name) => { this.clientName = name; });
    }
    this._locationService.getLocations(LocationTypeCode.Client, (clientResponse) => {
      this.clients = clientResponse;
    });
    /*
    this.subscriptionToClients = this._locationService.currentClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        this.onClientSelected(message);
      });

      this.subscriptionToRegions = this._locationService.currentRegion
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        this.onRegionSelected(message);
      });
      */
    this.subscriptionToActualClient = this._locationService.currentActualClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        this.reload(message);
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
  }

  reload(clientId: number) {
    if (clientId && +clientId !== 0) {
      this.clientId = clientId;
      this._locationService.getAllLocations().subscribe((response) => {
        this.locations = response;

        this._settingService.getReportTypes().subscribe((reports) => {
          this.reportTypes = reports;
          this.selectedReportType = this.reportTypes.find(x => x.routing === '/inventory');
          if (this._authService.isSuperAdmin()) {
            this.filteredLocations = this.locations.filter(location =>
              location.clientId === this.clientId);
            if (this.firstLoad) {
              this.firstLoad = false;
              this.subscriptionToDistricts = this._locationService.currentDistrict
                .pipe(takeUntil(this.destroyed$))
                .subscribe(message => {
                  this.onDistrictSelected(message);
                });

              this.subscriptionToStores = this._locationService.currentStore
                .pipe(takeUntil(this.destroyed$))
                .subscribe(message => {
                  this.onStoreSelected(message);
                });
            }
          } else {
            this._settingService.getLocationsForReport(this._authService.userId(), this.selectedReportType.id).subscribe((locs) => {
              this.filteredLocations = locs;
              if (this.firstLoad) {
                this.firstLoad = false;
                this.subscriptionToDistricts = this._locationService.currentDistrict
                  .pipe(takeUntil(this.destroyed$))
                  .subscribe(message => {
                    this.onDistrictSelected(message);
                  });

                this.subscriptionToStores = this._locationService.currentStore
                  .pipe(takeUntil(this.destroyed$))
                  .subscribe(message => {
                    this.onStoreSelected(message);
                  });
              }
            });
          }
        });
      });
    }
  }
  isLocationVisible(name: string) {
    if (name === 'Sku' || name === 'Product') {
      return true;
    }
    return this.filteredLocations.map(x => x.name).includes(name);
  }

  getClients() {
    this._locationService.getLocations(LocationTypeCode.Client, (response) => {
      this.clients = response;
    });
  }

  onDistrictSelected(id: number) {
    if (id && id !== 0) {
      this.categorySearchString = null;
      this.selectedStoreId = 0, this.selectedDistrictId = +this.selectedDistrictId;
      this.selectedDistrictId = id;
      this.selectedDistrict = this.filteredLocations.find(r => r.id === this.selectedDistrictId);
      this.stores = this.filteredLocations.filter(r => r.parentId === this.selectedDistrictId);
      this.setInventoryAttributes();

      /* this._locationService.getLocations(LocationTypeCode.Store, (response) => {
         this.stores = response;
       }, this.selectedDistrict.id);*/
    }
  }

  setInventoryAttributes() {
    this.setTotalCountHeaderAttributes();
    this.attributes = [{ name: 'sku', header: 'Sku', ascending: true, sorting: true },
    { name: 'name', header: 'Product', ascending: true, sorting: true }];

    this.stores.forEach((store) => {
      this.attributes.push({
        name: this.getLocationKey(store.name),
        header: store.name
      });
    });
  }

  setTotalCountHeaderAttributes() {
    this.alternateHeaderAttributes = ['blank1', 'blank2']
    let count = 0;
    this.stores.forEach((store) => {

      this.alternateHeaderAttributes.push('total' + count);
      count++;
    });
  }

  onStoreSelected(id: number) {
    if (id && +id !== 0) {
      this.selectedStoreId = +id;
      this.selectedStore = this.filteredLocations.find(s => s.id === this.selectedStoreId);
      this.getCategories(this.selectedStoreId, this.selectedStore.clientId);
    }
  }
  treeControl = new NestedTreeControl<any>(node => node.children);
  dataSourceTree: any;
  hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;
  getLevel(data, node: any) {
    let path = data?.find(branch => { return this.treeControl.getDescendants(branch).some(n => n.name === node.name); });
    return path ? this.getLevel(path.children, node) + 1 : 0;
  }
  getCategories(location: number, clientId: number) {
    this.categoryLoadingInProgress = true;
    this._categoryService.getLimitedCategoryTreeWithInventoryCount(location, clientId)
      .subscribe((response: Category[]) => {
        this.categories = response;
        this.dataSourceTree = new ArrayDataSource(this.categories);
        this.treeControl.dataNodes = this.categories;
        setTimeout(() => { this.treeControl.expandAll(); }, 100);
        this.categoryLoadingInProgress = false;
        if (this.categories.length === 0) {
          this.selectedStoreId = 0;
        }
      }, (error) => this._notifier.notify('error', error.error), () => { });
  }

  onCategorySelected(category) {
    console.log('onCategorySelected ', category);
    this.selectedCategoryName = category.name;
    this.selectedCategoryId = +category.id;
    this.inventoryLoadingInProgress = true;
    this._loaderService.show();
    this._inventoryService.getInventory(this.selectedDistrictId, this.selectedCategoryId)
      .subscribe((response: any[]) => {
        this.inventoryLoadingInProgress = false;
        this.inventories = response['products'];

        const total = {};
        total['sku'] = null, total['name'] = null;
        this.inventories.forEach((product) => {
          product.locations.forEach((location) => {
            product[this.getLocationKey(location.name)] = location.quantity;
            total[this.getLocationKey(location.name)] = (total[this.getLocationKey(location.name)]) ?
              total[this.getLocationKey(location.name)] + location.quantity :
              location.quantity;
          });
        });
        this.updateDataSource(this.inventories);
        this.inventoryTotals.length = 0, this.inventoryTotals.push(total);
        //console.log('inventory total ' + JSON.stringify(this.inventoryTotals));
      }, (error) => this._notifier.notify('error', error.error), () => { this._loaderService.hide(); });
  }

  getLocationKey(name: string) {
    return name.split(' ').join('_').toLowerCase();
  }

  searchCategory(array: any = null) {
    array = array ? array : this.categories;

    for (const key in array) {
      if (typeof array[key] === 'object') {
        this.searchCategory(array[key]);
      } else {
        if (key === 'name') {
          if (array.name.toLowerCase().indexOf(this.categorySearchString.toLowerCase()) > -1) {
            array.isVisible = true;
          } else {
            array.isVisible = false;
          }
        }
      }
    }
  }
}
