<button mat-icon-button class="close-button" (click)="onDismiss()">
  <mat-icon class="close-icon" >close</mat-icon>
</button>
<h1 class="heading-text" mat-dialog-title>{{title}}</h1>
<form #f="ngForm">
  <div class="custom-input-box create-form-group-width-set">
    <input class="custom-input" type="text" [(ngModel)]="formulaViewGroup.name"
           #name="ngModel" [ngClass]="{'is-invalid': f.submitted && name.invalid }"
           name="name" placeholder="Name*" required />
  </div>
  <mat-error class="mt-1" *ngIf="name.invalid">Formula View Group name is required.</mat-error>

  <div class="mt-4">
    <mat-label>Selected</mat-label>
    <mat-radio-group [(ngModel)]="formulaViewGroup.isSelected" #selected="ngModel" name="selected" required>
      <mat-radio-button class="margin-left-1em" [value]="true">Yes</mat-radio-button>
      <mat-radio-button class="margin-left-1em" [value]="false">No</mat-radio-button>
    </mat-radio-group>
    <mat-error *ngIf="selected.invalid">Selected is required.</mat-error>
  </div>
</form>
<div class="d-flex mt-4">
  <button mat-raised-button color="primary" class="submit-btn" (click)="onConfirm()" [disabled]="!formulaViewGroup.name">Submit</button>
  <button mat-button class="clear-btn ml-3" (click)="onDismiss()">Clear</button>

</div>
