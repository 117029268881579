import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { SkuGroupService } from '../services/sku-group.service';
import { SkuFilterService } from '../services/sku-filter.service';
import { NotifierService } from 'angular-notifier';
import { SkuGroup } from '../models/sku-group';
import { SkuFilter } from '../models/sku-filter';
import { Category } from '../models/category';
import { SkuFilterType } from '../models/sku-filter-type';
import { ProductCatalog } from '../models/product-catalog';
import { TableService } from '../services/table.service';
import { ProductCatalogService } from '../services/product-catalog.service';
import { CategoryService } from '../services/category.service';
import { Action } from '../models/action';
import { AuthService } from '../../core/services/auth.service';
import { ClientService } from '../services/client.service';
import { Client } from '../models/client';
import { LocationService } from '../services/location.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../dialogs/confirm/confirm-dialog.component';
import { SkuGroupCreateComponent } from "./sku-group-create.component";
import { SkuFilterCreateComponent } from './sku-filter-create.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
const CONFIG_CLIENT_KEY = 'ConfigClient';

@Component({
  selector: 'app-sku-group',
  templateUrl: './sku-group.component.html'
})
export class SkuGroupComponent implements OnInit, OnDestroy {
  selectedRow = new Set<any>();
  private dialogRef: any;
  clientId: number;
  clients: Client[] = [];
  skuFilterTypes: SkuFilterType[];
  skuGroup: SkuGroup;
  skuGroups: SkuGroup[];
  skuFilter: SkuFilter;
  skuFilters: SkuFilter[];
  productCatalogs: ProductCatalog[];
  currentAction: Action;
  displaySkuGroupDialog = false;
  displaySkuFilterDialog = false;
  productCatalogSearchString: string;
  selectedSkuGroupId: number;
  isSkuListFilterTypeSelected: boolean;
  categorySearchString = null;
  selectedCategory: Category = null;
  categories: Category[] = [];
  loadingInProgress: boolean;
  selectedSkuGroup: SkuGroup;
  subscriptionToClients: Subscription;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  skuGroupAttributes: any = [{ name: 'name', header: 'Name', ascending: true }];
  skuFilterAttributes: any = [
    { mappedToProperty: 'index', name: 'S.No', ascending: true, isSortable: false, hide: false },
    { mappedToProperty: 'name', name: 'Name', ascending: true, isSortable: true, hide: false },
    { mappedToProperty: 'skuFilterType.id', name: 'Value', ascending: true, isSortable: false, hide: false },
    { mappedToProperty: 'skuFilterType.name', name: 'Filter Type', ascending: true, isSortable: false, hide: false },
    { mappedToProperty: 'actions', name: 'Actions', ascending: true, isSortable: false, hide: false }
  ];
  selectedColumns = this.getDisplayedColumns();
  doneLoading = false;
  skuGroupTable: any = {
    sorting: true,
    toggleColumns: false
  };

  skuFilterTable: any = {
    toggleColumns: false
  };

  skuGroupDialog: any = {
    isOpen: false,
    headerText: '',
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    }
  };

  skuFilterDialog: any = {
    isOpen: false,
    headerText: '',
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    }
  };
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource();
  constructor(private _skuGroupService: SkuGroupService,
    private _skuFilterService: SkuFilterService,
    private _productCatalogService: ProductCatalogService,
    private _categoryService: CategoryService,
    private _notifier: NotifierService,
    private _tableService: TableService,
    private _authService: AuthService,
    private _clientService: ClientService,
    private _locationService: LocationService, private dialog: MatDialog) { }

  ngOnInit() {
    this.subscriptionToClients = this._locationService.currentActualClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        this.clientId = message; this.onClientSelected();
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
    this.dialogRef?.close();
  }

  getDisplayedColumns(): string[] {
    return this.skuFilterAttributes.map(cd => cd.mappedToProperty);;
  }
  getTextBydata(element, columnDef, index) {
    var textToShow = '';
    switch (columnDef.name) {
      case 'S.No':
        textToShow = element['index'];
        break;
      case 'Value':
        textToShow = element['skuFilterType']['id'];
        break;
      case 'Filter Type': textToShow = element['skuFilterType']['name'];
        break;
      default:
        textToShow = element[columnDef.mappedToProperty];
        break;
    }
    return textToShow;
  }

  getColumnDefinationByMappedToProperty(mappedToProperty) {
    var column = this.skuFilterAttributes.find(x => x.mappedToProperty == mappedToProperty);
    return column;
  }
  updateDataSource(data) {

    if (data) {
      this.dataSource = new MatTableDataSource(data);
      setTimeout(() => { this.dataSource.paginator = this.paginator; this.dataSource.sort = this.sort; }, 10);
    }
  }

  reload() {
    this.getSkuGroups();
    this.getSkuFilterTypes();
    this.getProductCatalogs();
    this.getCategories();
  }

  onClientSelected() {
    this.selectedSkuGroup = undefined;
    this.skuFilters = undefined;
    this.reload();
  }

  getCategories() {
    this._categoryService.getCategoryTree(+this.clientId)
      .subscribe((response: Category[]) => {
        this.categories = response;
      }, (error) => this._notifier.notify('error', error.error), () => { });
  }

  searchCategoryTree(tree, nodesProp, prop, value) {
    let i, f = null;
    if (Array.isArray(tree)) {
      for (i = 0; i < tree.length; i++) {
        f = this.searchCategoryTree(tree[i], nodesProp, prop, value);
        if (f) {
          return f;
        }
      }
    } else if (typeof tree === 'object') {
      if (tree[prop] !== undefined && tree[prop] === value) {
        return tree;
      }
    }
    if (tree[nodesProp] !== undefined && tree[nodesProp].length > 0) {
      return this.searchCategoryTree(tree[nodesProp], nodesProp, prop, value);
    } else {
      return null;
    }
  }

  getCategoryName(id: number) {
    const category = this.searchCategoryTree(this.categories, 'children', 'id', +id);
    return category ? category.name : null;
  }

  getProductCatalogs() {
    this._productCatalogService.getProductCatalogs(+this.clientId)
      .subscribe((response: ProductCatalog[]) => {
        this.productCatalogs = response.map(function (catalog) {
          return {
            'id': catalog.id,
            'productName': catalog.productName,
            'productSku': catalog.productSku,
            'isVisible': true
          };
        });
      }, (error) => this._notifier.notify('error', error.error), () => { });
  }

  getProductCatalogName(id: number) {
    const productCatalog = this.productCatalogs.find(p => p.id === +id);
    return productCatalog ? productCatalog.productName : null;
  }

  getSkuGroups() {
    this.loadingInProgress = true;
    this._skuGroupService.getSkuGroupsByClient
      (+this.clientId, (response: SkuGroup[]) => {
        this.loadingInProgress = false;
        this.skuGroups = response;
        if (this.selectedSkuGroup) {
          this.selectSkuGroup(this.selectedSkuGroup.id);
        }
      }); // (error) => this._notifier.notify('error', error.message), () => {});
  }

  getSkuFilters(id: number) {
    this.skuFilters = this.skuGroups.find(group => group.id === id).skuFilters;
  }

  getSkuFilterTypes() {
    this._skuGroupService.getSkuFilterTypes()
      .subscribe((response: SkuFilterType[]) => {
        this.skuFilterTypes = response;
      }, (error) => this._notifier.notify('error', error.message), () => { });
  }

  addSkuGroup() {
    this.skuGroup = { name: null, skuFilters: [], clientId: this.clientId, isRequired: false };
    this.skuGroupDialog.isOpen = true;
    this.skuGroupDialog.headerText = 'Add SkuGroup';
    this.dialogRef = this.dialog.open(SkuGroupCreateComponent, { autoFocus: true, disableClose: false, data: { title: 'Add SkuGroup', skuGroup: this.skuGroup } });
    this.dialogRef.afterClosed().subscribe(result => { if (result !== false) { this.skuGroup = result; this.saveSkuGroup(); } });
  }

  addSkuFilter() {
    if (!this.selectedSkuGroup) {
      this._notifier.notify('warning', 'Please select sku group.');
      return;
    }

    this.skuFilter = {
      name: null, value: null, skuFilterType: { id: null, name: null },index:0
    };

    this.skuFilterDialog.isOpen = true;
    this.skuFilterDialog.headerText = 'Add SkuFilter';
    this.currentAction = Action.Create;
    this.dialogRef = this.dialog.open(SkuFilterCreateComponent, { autoFocus: true, disableClose: false, data: { title: 'Add Sku filter', skuFilter: this.skuFilter, skuFilterTypes: this.skuFilterTypes, productCatalogs: this.productCatalogs, categories: this.categories } });
    this.dialogRef.afterClosed().subscribe(result => { if (result !== false) { this.skuGroup = result; this.saveSkuFilter(); } });
  }

  editSkuFilter(skuFilter) {
    this.skuFilter = skuFilter;
    this.skuFilterDialog.isOpen = true;
    this.skuFilterDialog.headerText = 'Edit SkuFilter';
    this.currentAction = Action.Update;
    this.isSkuListFilterTypeSelected = (+this.skuFilter.skuFilterType.id === 1) ? true : false;
    this.productCatalogSearchString = null;
    this.categorySearchString = null;
    this.dialogRef = this.dialog.open(SkuFilterCreateComponent, { autoFocus: true, disableClose: false, data: { title: 'Edit Sku filter', skuFilter: this.skuFilter, skuFilterTypes: this.skuFilterTypes, productCatalogs: this.productCatalogs, categories: this.categories } });
    this.dialogRef.afterClosed().subscribe(result => { if (result !== false) { this.skuGroup = result; this.saveSkuFilter(); } });
  }

  selectSkuGroup(id: number) {
    if (this.currentAction === Action.Delete) {
      return;
    }

    this.selectedSkuGroup = this.skuGroups.filter(group => group.id === id)[0];
    this.selectedSkuGroup.skuFilters.forEach(function(row, index) {
      row.index = index+1;
    });
    this.skuFilters = this.selectedSkuGroup.skuFilters;
    this.updateDataSource(this.skuFilters);
  }

  saveSkuGroup() {
    this._skuGroupService.createSkuGroup(this.skuGroup.name, this.clientId)
      .subscribe((response: boolean) => {
        this.skuGroupDialog.isOpen = false;
        this.getSkuGroups();
        this._notifier.notify('success', 'Sku group created successfully.');
      }, (error) => {
        if (error.error != null) {
          this._notifier.notify('error', error.error)
        } else {
          this._notifier.notify('error', error.message)
        }
      }, () => { });
  }

  saveSkuFilter() {
    switch (this.currentAction) {
      case Action.Create:
        this._skuFilterService.createSkuFilter(this.selectedSkuGroup.id, this.skuFilter.name, this.skuFilter.value,
          this.skuFilter.skuFilterType.id)
          .subscribe((response: boolean) => {
            this.skuFilterDialog.isOpen = false;
            this.getSkuGroups();
            this._notifier.notify('success', 'Sku filter created successfully.');
          }, (error) => this._notifier.notify('error', error.message), () => { });
        break;
      case Action.Update:
        this._skuFilterService.updateSkuFilter(this.skuFilter.id, this.selectedSkuGroup.id, this.skuFilter.name, this.skuFilter.value,
          this.skuFilter.skuFilterType.id)
          .subscribe((response: boolean) => {
            this.skuFilterDialog.isOpen = false;
            this.getSkuGroups();
            this._notifier.notify('success', 'Sku filter updated successfully.');
          }, (error) => this._notifier.notify('error', error.message), () => { });
        break;
    }
  }

  deleteSkuGroup(skuGroup) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: true, disableClose: false, data: { title: 'Delete Sku Group', message: 'Are you sure you want to delete this sku group?' } });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this._skuGroupService.deleteSkuGroup(skuGroup.id)
          .subscribe((response: boolean) => {
            if (this.selectedSkuGroup && this.selectedSkuGroup.id === skuGroup.id) {
              this.selectedSkuGroup = null;
              this.skuFilters = [];
            }
            this.getSkuGroups();
            this._notifier.notify('success', 'Sku group deleted successfully.');
          }, (error) => { if (error.error != null) { this._notifier.notify('error', error.error) } else { this._notifier.notify('error', error.message) } }, () => { });
      }
    });
    this.currentAction = Action.Delete;
  }

  deleteSkuFilter(skuFilter) {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, { autoFocus: true, disableClose: false, data: { title: 'Delete Sku filter', message: 'Are you sure you want to delete this sku filter?' } });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this._skuFilterService.deleteSkuFilter(skuFilter.id)
          .subscribe((response: boolean) => { this.getSkuGroups(); this._notifier.notify('success', 'Sku filter deleted successfully.'); }, (error) => this._notifier.notify('error', error.message), () => { });
      }
    });
  }

  skuGroupSorting(field, order) {
    if (!this.skuGroupTable.sorting) {
      return;
    }

    this.skuGroups = this._tableService.sorting(this.skuGroups, field, order);
    this.skuGroupAttributes.filter(col => col.name === field)[0].ascending = !order;
  }

  skuFilterSorting(field, order) {
    if (!this.skuGroupTable.sorting) {
      return;
    }

    this.skuFilters = this._tableService.sorting(this.skuFilters, field, order);
    this.skuFilterAttributes.filter(col => col.name === field)[0].ascending = !order;
  }

  onSkuFilterTypeSelected() {
    this.isSkuListFilterTypeSelected = (+this.skuFilter.skuFilterType.id === 1) ? true : false;
  }

  searchProductCatalog() {
    if (this.productCatalogSearchString !== null) {
      this.productCatalogs.forEach((catalog) => {
        if (catalog.productName.toLowerCase().indexOf(this.productCatalogSearchString.toLowerCase()) > -1 ||
          catalog.productSku.toLowerCase().indexOf(this.productCatalogSearchString.toLowerCase()) > -1) {
          catalog.isVisible = true;
        } else {
          catalog.isVisible = false;
        }
      });
    } else {
      this.productCatalogs.forEach((catalog) => {
        catalog.isVisible = true;
      });
    }
  }

  selectProductCatalog(productCatalog) {
    this.skuFilter.value = productCatalog.id;
  }

  selectCategory(category) {
    this.skuFilter.value = category.id;
  }

  searchCategory(array: any = null) {
    array = array ? array : this.categories;

    for (const key in array) {
      if (typeof array[key] === 'object') {
        this.searchCategory(array[key]);
      } else {
        if (key === 'name') {
          if (array.name.toLowerCase().indexOf(this.categorySearchString.toLowerCase()) > -1) {
            array.isVisible = true;
          } else {
            array.isVisible = false;
          }
        }
      }
    }
  }
}
