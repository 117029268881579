import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList } from '@angular/core';
import * as moment from 'moment';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../../core/services/auth.service';
import { LocationService } from '../services/location.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DurationService } from '../services/duration.service';
import { Duration } from '../models/duration';
import { RewardsCreateSearchRequest } from '../models/rewards-create-search-request';
import { DateRange } from '../models/date-range';
import { MatSelectChange } from '@angular/material/select';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { LoaderService } from '../services/loader.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { RewardGameViewModel } from '../models/reward-game';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { EmployeeService } from '../services/employee.service';
import { Employee } from '../models/employee';
import { Location } from '../models/location';
import { FormulaService } from '../services/formula.service';
import { Formula } from '../models/formula';
import { RewardGameType } from '../models/reward-game-type';
import { RewardGameStatus } from '../models/reward-status';
import { isString } from '@ng-bootstrap/ng-bootstrap/util/util';
import { Status } from '../users/models/status';
import { RewardService } from '../services/reward-service';
import { object } from 'underscore';
import { UserService } from '../users/services/user.service';
import { RewardEarnedViewModel } from '../models/reward-earned';
import { FinalizeClaimComponent } from "./finalize-claim.component";
import { DeleteClaimComponent } from "./delete-claim.component";
import { ManualRewardComponent } from "./manual-reward.component";

export class Filters {
  value: number;
  viewValue: string;
}



@Component({
  selector: 'app-rewards-claim-management',
  templateUrl: './rewards-claim-management.component.html'
})

export class RewardsClaimManagementComponent implements OnInit, OnDestroy {

  constructor(private _notifier: NotifierService,
    private _authService: AuthService,
    private _rewardService: RewardService,
    private _locationService: LocationService,
    private _employeeService: EmployeeService,
    private _formulaService: FormulaService,
    private _durationService: DurationService,
    private _loaderService: LoaderService,
    private observer: BreakpointObserver,
    private _userService: UserService, private matDialog: MatDialog
  ) {

    this.observer.observe('(max-width: 1025px)').subscribe((result) => {
      //console.log('max', result);
      if (result.matches) {
        this.columnDefinationsClaims = [
          { name: "IsComplete", isSortable: true, mappedToProperty: "isComplete", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Employee", isSortable: true, mappedToProperty: "employee", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "AuthorizedBy", isSortable: true, mappedToProperty: "authorizedBy", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "RewardClaimOption", isSortable: true, mappedToProperty: "rewardClaimOption", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "CreatedDate", isSortable: true, mappedToProperty: "createdDate", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "FinalizeDate", isSortable: true, mappedToProperty: "finalizeDate", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Money", isSortable: true, mappedToProperty: "money", class: "", isFilterable: true, sticky: true, hide: false },
          { name: "Manage", isSortable: false, mappedToProperty: "manage", class: "", isFilterable: true, sticky: true, hide: false }
        ];
        this.selectedColumnsClaims = this.columnDefinationsClaims.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
      }
    });
  }
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  selectedFilter: number = 0;
  createTemplate = { startDate: new Date(), endDate: new Date(), monthlyDate: moment().format('YYYY-MM') };
  startDate: Date;
  endDate: Date;
  minDate: Date;
  maxDate: Date;
  months: any;
  durations: Duration[];
  subscriptionToClients: Subscription;
  selectedClientId: number;
  clientLocationId: number;
  filteredLocations: Location[] = [];
  locations: Location[];
  formulas: Formula[];
  employees: Employee[];
  employeesForMenus: Employee[];
  private debug = false;
  monthlyDuration: string;
  selectedDuration: Duration;
  createdByList: string[];
  rewardSearchRequest = new RewardsCreateSearchRequest();
  earnedRewardModel = new RewardEarnedViewModel();
  rewardGameList: RewardGameViewModel[];
  claimOptionList: any[];


  columnDefinationsClaims = [
    { name: "IsComplete", isSortable: true, mappedToProperty: "isComplete", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Employee", isSortable: true, mappedToProperty: "employee", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "AuthorizedBy", isSortable: true, mappedToProperty: "authorizedBy", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "RewardClaimOption", isSortable: true, mappedToProperty: "rewardClaimOption", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "CreatedDate", isSortable: true, mappedToProperty: "createdDate", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "FinalizeDate", isSortable: true, mappedToProperty: "finalizeDate", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Money", isSortable: true, mappedToProperty: "money", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Manage", isSortable: false, mappedToProperty: "manage", class: "", isFilterable: true, sticky: true, hide: false }
  ];
  columnDefinationsRewards = [
    { name: "IsClaimed", isSortable: true, mappedToProperty: "isClaimed", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "WasManualReward", isSortable: true, mappedToProperty: "wasManualReward", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Game", isSortable: true, mappedToProperty: "game", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Description", isSortable: true, mappedToProperty: "description", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Employee", isSortable: true, mappedToProperty: "employee", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "StartDate", isSortable: true, mappedToProperty: "startDate", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "EndDate", isSortable: true, mappedToProperty: "endDate", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "CreatedDate", isSortable: true, mappedToProperty: "createdDate", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "ClaimDate", isSortable: true, mappedToProperty: "claimDate", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Money", isSortable: true, mappedToProperty: "money", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Manage", isSortable: false, mappedToProperty: "manage", class: "", isFilterable: true, sticky: true, hide: false }
  ];

  selectedColumnsClaims = this.getDisplayedColumnsClaims();
  columnsChangedClaims(event: MatSelectChange) {
    for (var i = 0; i < this.columnDefinationsClaims.length; i++) {
      var column = this.selectedColumnsClaims.find(x => x == this.columnDefinationsClaims[i].mappedToProperty);
      if (column != null) {
        this.columnDefinationsClaims[i].hide = false;
      } else {
        this.columnDefinationsClaims[i].hide = true;
      }
    }
  }

  getDisplayedColumnsClaims(): string[] {
    return this.columnDefinationsClaims.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
  }

  getColumnDefinationByMappedToPropertyClaims(mappedToProperty) {
    var column = this.columnDefinationsClaims.find(x => x.mappedToProperty == mappedToProperty);
    return column;
  }

  selectedColumnsRewards = this.getDisplayedColumnsRewards();
  columnsChangedRewards(event: MatSelectChange) {
    for (var i = 0; i < this.columnDefinationsClaims.length; i++) {
      var column = this.selectedColumnsRewards.find(x => x == this.columnDefinationsRewards[i].mappedToProperty);
      if (column != null) {
        this.columnDefinationsRewards[i].hide = false;
      } else {
        this.columnDefinationsRewards[i].hide = true;
      }
    }
  }

  getDisplayedColumnsRewards(): string[] {
    return this.columnDefinationsRewards.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
  }

  getColumnDefinationByMappedToPropertyRewards(mappedToProperty) {
    var column = this.columnDefinationsRewards.find(x => x.mappedToProperty == mappedToProperty);
    return column;
  }
  tableready = false;
  dataSourceClaims = new MatTableDataSource();
  dataSourceRewards = new MatTableDataSource();
  dataClaims: any;
  dataRewards: RewardEarnedViewModel[];



  pageObject: any = {
    isClaimSelected: true,
    searchTerm: ''

  };

  switchBetweenClaimsAndRewards() {
    if (this.pageObject.isClaimSelected) {
      this.filterMenuRewards.isOpen = false;
    } else {
      this.filterMenuClaims.isOpen = false;
    }
    this.applySelectedFilter();
  }

  finalizeClaim() {
    this._rewardService.sendFinalizeClaim(this.finalizeClaimModal.claimId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.finalizeClaimModal.cancel();
        this.getClaimsAndRewards();
        this._notifier.notify('success','Finalized the claim.');
      });
  }
  private dialogRef: any;
  openFinalizeClaimModal(claimId: number) {
    let claim = this.claimList.find(x => x.id == claimId);
    let employee = this.employees.find(x => x.id == claim.employeeId);
    let temp = employee != undefined ? employee.user.name : '';
    this.finalizeClaimModal.body = ' \n Reward For :' + temp + ' \n  $: ' + claim.money + ' \n Gift Card:' + this.claimOptionList.find(x => x.id == claim.rewardClaimOptionId).name;
    this.finalizeClaimModal.open(claimId);

    this.dialogRef = this.matDialog.open(FinalizeClaimComponent, { autoFocus: true, disableClose: false, panelClass: '', data: { finalizeClaimModal: this.finalizeClaimModal } });
    this.dialogRef.afterClosed().subscribe(result => { if (result === true) { this.finalizeClaim(); } });
  }

  finalizeClaimModal: any = {
    isOpen: false,
    claimId: 0,
    header: '',
    body: '',
    open: function (id: number) {
      this.header = "Finalize Claim?";
      this.isOpen = true;
      this.claimId = id;
    },
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
      this.claimId = 0;
    }
  };

  deleteManualReward() {
    this._rewardService.deleteManualReward(this.deleteManualModal.rewardId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.deleteManualModal.cancel();
        this.getClaimsAndRewards();
        this._notifier.notify('success','Manual reward deleted successfully.');
      });
  }

  openDeleteManualModal(rewardId: number) {
    this.deleteManualModal.isOpen = true;
    let reward = this.moneyRewardList.find(x => x.id == rewardId);
    let employee = this.employees.find(x => x.id == reward.employeeId);
    let temp = employee != undefined ? employee.user.name : '';
    this.deleteManualModal.body = ' \n For :' + temp + ' \n  $: ' + reward.money;
    this.deleteManualModal.open(rewardId);

    this.dialogRef = this.matDialog.open(DeleteClaimComponent, { autoFocus: true, disableClose: false, panelClass: '', data: { deleteManualModal: this.deleteManualModal } });
    this.dialogRef.afterClosed().subscribe(result => { if (result === true) { this.deleteManualReward(); } });
  }

  deleteManualModal: any = {
    isOpen: false,
    rewardId: 0,
    header: '',
    body: '',
    open: function (id: number) {
      this.header = "Delete Manual Reward?";
      this.isOpen = true;
      this.rewardId = id;
    },
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
      this.rewardId = 0;
    }

  }

  saveManualReward() {
    this._rewardService.createManualReward(this.earnedRewardModel)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.manualRewardDialog.isOpen = false;
        this.getClaimsAndRewards();
        this._notifier.notify('success', 'Manual reward saved successfully.');
      });
  }

  openManualRewardModal() {
    this.manualRewardDialog.isOpen = true;
    this.manualRewardDialog.isEdit = false;
    this.manualRewardDialog.headerText = "Create Manual Reward";
    this.earnedRewardModel = new RewardEarnedViewModel();
    this.earnedRewardModel.wasManualReward = true;
    this.earnedRewardModel.money = 0;
    this.earnedRewardModel.description = "";
    this.earnedRewardModel.employeeId = 0;
    this.earnedRewardModel.isClaimed = false;

    this.dialogRef = this.matDialog.open(ManualRewardComponent, { autoFocus: true, disableClose: false, panelClass: '', data: { earnedRewardModel: this.earnedRewardModel, manualRewardDialog: this.manualRewardDialog, employees: this.employees } });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result !== false) {
        this.earnedRewardModel = result.earnedRewardModel; this.manualRewardDialog = result.manualRewardDialog; this.saveManualReward();
      }
    });

  }

  editManualRewardModal(element: RewardEarnedViewModel) {
    this.manualRewardDialog.isOpen = true;
    this.manualRewardDialog.isEdit = true;
    this.manualRewardDialog.headerText = "Edit Reward";
    this.earnedRewardModel.id = element.id;
    this.earnedRewardModel.money = element.money;
    this.earnedRewardModel.description = element.description;
    this.earnedRewardModel.employeeId = element.employeeId;

    this.dialogRef = this.matDialog.open(ManualRewardComponent, { autoFocus: true, disableClose: false, panelClass: '', data: { earnedRewardModel: this.earnedRewardModel, manualRewardDialog: this.manualRewardDialog, employees: this.employees } });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result !== false) {
        this.earnedRewardModel = result.earnedRewardModel; this.manualRewardDialog = result.manualRewardDialog; this.saveManualReward();
      }
    });
  }

  manualRewardDialog: any = {
    isOpen: false,
    isEdit: false,
    headerText: '',
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    }
  };

  openFilterMenu() {
    if (this.pageObject.isClaimSelected) {
      this.filterMenuClaims.isOpen = true;
    } else {
      this.filterMenuRewards.isOpen = true;
    }

  }

  filterMenuClaims: any = {
    isOpen: false,
    cancelButton: true,
    isComplete: false,
    duration: -1,
    cancel: function () {
      this.isOpen = false;
    }
  };

  filterMenuRewards: any = {
    isOpen: false,
    cancelButton: true,
    isClaimed: false,
    wasManualReward: false,
    duration: -1,
    cancel: function () {
      this.isOpen = false;
    }
  };


  getTextByDataClaims(element, columnDef) {
    if (!this.tableready) return;
    var textToShow = '';
    switch (columnDef.name) {
      case 'Money':
        textToShow = '$' + element.money;
        break;
      case 'IsComplete':
        if (element.isComplete) {
          textToShow = "Yes";
        } else {
          textToShow = "No";
        }
        break;
      case 'Employee':
        let emp = this.employees.find(x => x.id == element.employeeId);
        textToShow = emp ? emp.user.name : "";
        break;
      case 'RewardClaimOption':
        if (this.claimOptionList) {
          textToShow = this.claimOptionList.find(x => x.id == element.rewardClaimOptionId).name;
        }
        break;
      case 'AuthorizedBy':
        textToShow = element.authorizedById;
        break;
      case 'CreatedDate':
      case 'FinalizeDate':
        if ('0001-01-01T00:00:00' == element[columnDef.mappedToProperty]) {
          textToShow = "N/A";
        } else {
          textToShow = moment(element[columnDef.mappedToProperty]).format(this._durationService.format1);
        }
        break;
      default:
        textToShow = element[columnDef.mappedToProperty];
    }

    return textToShow;
  }
  getTextByDataRewards(element, columnDef) {
    if (!this.tableready) return;
    var textToShow = '';
    switch (columnDef.name) {
      case 'Money':
        textToShow = '$' + element.money;
        break;
      case 'IsClaimed':
      case 'WasManualReward':
        if (element[columnDef.mappedToProperty]) {
          textToShow = "Yes";
        } else {
          textToShow = "No";
        }
        break;
      case 'Employee':
        let emp = this.employees.find(x => x.id == element.employeeId);
        textToShow = emp ? emp.user.name : "";
        break;
      case 'Game':
        if (element.rewardGame) {
          textToShow = element.rewardGame.name;
        } else {
          textToShow = "ManualReward";
        }
        break;
      case 'Description':
        if (element.wasManualReward) {
          textToShow = element.description;
        }
        else {
          textToShow = element.rewardGame.description;
        }

        break;
      case 'StartDate':
        if (element.rewardGame) {
          textToShow = moment(element.rewardGame.startDate).format(this._durationService.format1);
        } else {
          textToShow = "ManualReward";
        }
        break;
      case 'EndDate':
        if (element.rewardGame) {
          textToShow = moment(element.rewardGame.endDate).format(this._durationService.format1);
        } else {
          textToShow = "ManualReward";
        }
        break;
      case 'CreatedDate':
      case 'ClaimDate':
        if ('0001-01-01T00:00:00' == element[columnDef.mappedToProperty]) {
          textToShow = "N/A";
        } else {
          textToShow = moment(element[columnDef.mappedToProperty]).format(this._durationService.format1);
        }
        break;
      default:
        textToShow = element[columnDef.mappedToProperty];
    }

    return textToShow;
  }


  ngOnInit() {
    this.selectedFilter;
    this.durations = this._durationService.getDurationExtra();
    this.selectedDuration = this.durations.find(x => x.value === 0);
    this.months = this._durationService.getMonths();
    this.selectedClientId = this._authService.clientId();

    this.subscriptionToClients = this._locationService.currentActualClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        if (message) {
          this.selectedClientId = message;
          this.onClientSelected();
          this.getClaimsAndRewards();
        }
      });

    this.subscriptionToClients = this._locationService.currentClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        if (message) {
          this.clientLocationId = message;
        }
      });
    this._rewardService.getRewardClaimOptions(this.selectedClientId).subscribe((response) => {
      this.claimOptionList = response;
    });

  }



  filterByProperty(collection, value, key) {
    return collection.filter(o => key.reduce((c, v) => c[v] || {}, o) === value);
  }
  moneyRewardList: any[] = new Array(0);
  claimList: any[] = new Array(0);
  async getClaimsAndRewards() {
    if (this.selectedClientId > 0) {
      this.tableready = false;
      this._loaderService.show();
      this._rewardService.getMoneyRewardsForClient(this.selectedClientId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(async (message) => {
          if (message) {
            this.moneyRewardList = message;
            this.dataRewards = message;
            await this.applySelectedFilter();
          }
        });
      this._rewardService.getRewardClaimsForClient(this.selectedClientId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(async (message) => {
          if (message) {
            this.claimList = message;
            this.dataClaims = message;
            await this.applySelectedFilter();
            this._loaderService.hide();
          }
        });
    }
  }


  onClientSelected() {
    this._employeeService.getEmployeesByLocationCallback((response) => {
      this.employeesForMenus = response.filter(x => x.location.clientId == this.selectedClientId && x.user.userStatusCode.id === Status.Active);
      this.employees = response.filter(x => x.location.clientId == this.selectedClientId);
      this._locationService.getLocationsByClientId(this.selectedClientId, (response) => {
        this.locations = response.filter(x => x.locationTypeCodeId == 4);
      });
    });
  }





  ngOnDestroy() {
    this.columnDefinationsClaims = null;
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
    this.dialogRef?.close();
  }

  async filterChangedRewards(event: MatSelectChange) {
    this.selectedFilter = event.value;
    this.applySelectedFilter();
  }



  async filterChangedClaims(event: MatSelectChange) {
    this.selectedFilter = event.value;
    this.applySelectedFilter();
  }

  async applySelectedFilter() {
    if (this.pageObject.isClaimSelected) {
      this.dataClaims = this.claimList;
      this.dataClaims = this.dataClaims.filter(x => x.isComplete == this.filterMenuClaims.isComplete);
      if (this.filterMenuClaims.duration != -1) {
        this.dataClaims = this.dataClaims.filter(x => (
          (x.createdDate >= this.durations[this.filterMenuClaims.duration].duration.FromDate && x.createdDate <= this.durations[this.filterMenuClaims.duration].duration.ToDate)
        )
        );
      }
      this.updateDataSource(this.dataClaims);
    } else {
      this.dataRewards = this.moneyRewardList;
      this.dataRewards = this.dataRewards.filter(x => x.isClaimed == this.filterMenuRewards.isClaimed);
      if (this.filterMenuRewards.duration != -1) {
        var from = new Date(this.durations[this.filterMenuRewards.duration].duration.FromDate);
        var to = new Date(this.durations[this.filterMenuRewards.duration].duration.ToDate);
        this.dataRewards = this.dataRewards.filter(x => (
          (new Date(x.createdDate) >= from && new Date(x.createdDate) <= to)
        )
        );
      }
      this.updateDataSource(this.dataRewards);
    }

  }



  updateDataSource(data) {
    if (this.pageObject.isClaimSelected) {
      let filteredItems = data;
      if (filteredItems) {
        this.dataSourceClaims = new MatTableDataSource(filteredItems);
        this.dataSourceClaims.sort = this.sort.toArray()[0];
        this.dataSourceClaims.sortingDataAccessor = (item: any, property) => {
          switch (property) {
            case 'employee':
              let emp = this.employees.find(x => x.id == item.employeeId);
              return emp ? emp.user.name : "";
            case 'authorizedBy':
              return item.authorizedById;
            case 'rewardClaimOption':
              return this.claimOptionList.find(x => x.id == item.rewardClaimOptionId).name;
            case 'type':
              return item.rewardGame.type;
            case 'createdDate':
            case 'finalizeDate':
              return moment(item[property]).format(this._durationService.format1);

            default: return item[property];
          }
        };
        this.dataSourceClaims.paginator = this.paginator.toArray()[0];
        this.applyFilter(this.pageObject.searchTerm);
      }
    } else {
      let filteredItems = data;
      if (filteredItems) {
        this.dataSourceRewards = new MatTableDataSource(filteredItems);
        this.dataSourceRewards.sort = this.sort.toArray()[1];
        this.dataSourceRewards.sortingDataAccessor = (item: any, property) => {
          switch (property) {
            case 'employee':
              let emp = this.employees.find(x => x.id == item.employeeId);
              return emp ? emp.user.name : "";
            case 'game':
              if (item.rewardGame) {
                return item.rewardGame.name;
              }
              return 0;
            case 'description':
              if (item.wasManualReward && item.description) {
                return item.description;
              } else if (item.rewardGame) {
                return item.rewardGame.description;
              }
              return 0;
            case 'type':
              return item.rewardGame.type;
            case 'endDate':
            case 'startDate':
              if (!item.wasManualReward) {
                return moment(item.rewardGame[property]).format(this._durationService.format1);
              }
              return 0;
            case 'createdDate':
            case 'claimDate':
              return moment(item[property]).format(this._durationService.format1);

            default: return item[property];
          }
        };
        this.dataSourceRewards.paginator = this.paginator.toArray()[1];
        this.applyFilter(this.pageObject.searchTerm);
      }
    }
  }

  applyFilter(filterValue: string) {
    if (this.pageObject.isClaimSelected) {
      if (filterValue) {
        this.dataSourceClaims.filterPredicate = this.filterPredicateClaims;
        this.dataSourceClaims.filter = filterValue.trim().toLowerCase();
      } else {
        this.dataSourceClaims.filter = "";
      }
    } else {
      if (filterValue) {
        this.dataSourceRewards.filterPredicate = this.filterPredicateRewards;
        this.dataSourceRewards.filter = filterValue.trim().toLowerCase();
      } else {
        this.dataSourceRewards.filter = "";
      }
    }
    this.tableready = true;
  }


  filterPredicateClaims = (data: any): boolean => {
    const filterValue = this.pageObject.searchTerm.trim().toLowerCase();
    let authUser = null;
    if (data.authorizedById) {
      authUser = data.authorizedById;
    }

    const checkString = (str: string) =>
      str.trim().toLowerCase().indexOf(filterValue) !== -1;

    const employee = this.employees.find((x) => x.id == data.employeeId);
    const employeeName = employee ? employee.user.name : '';
    const claimOption = this.claimOptionList.find(
      (x) => x.id == data.rewardClaimOptionId
    );
    const claimOptionName = claimOption ? claimOption.name : '';

    return (
      (data.isComplete ? checkString('yes') : checkString('no')) ||
      checkString(employeeName) ||
      checkString(claimOptionName) ||
      (data.authorizedById && authUser && checkString(authUser)) ||
      checkString(data.finalizeDate) ||
      checkString(data.createdDate) ||
      checkString(data.money.toString())
    );
  };

  filterPredicateRewards = (data: any): boolean => {
    const filterValue = this.pageObject.searchTerm.trim().toLowerCase();
    const checkString = (str: string) =>
      str.trim().toLowerCase().indexOf(filterValue) !== -1;
    const employee = this.employees.find((x) => x.id == data.employeeId);
    const employeeName = employee ? employee.user.name : '';

    const rewardGameName = data.rewardGame ? data.rewardGame.name : '';

    return (
      (data.isClaimed ? checkString('yes') : checkString('no')) ||
      (data.wasManualReward ? checkString('yes') : checkString('no')) ||
      (data.description && checkString(data.description)) ||
      checkString(employeeName) ||
      checkString(rewardGameName) ||
      checkString(data.claimDate) ||
      checkString(data.createdDate) ||
      checkString(data.money.toString())
    );
  };


  rewardGame: RewardGameViewModel;
  rewardGameTypes = RewardGameType;
  rewardGameTypesKeys = Object.values(RewardGameType).filter(key => !isNaN(Number(key)));

  managerRewardGameTypesKeys = Object.values(RewardGameType).filter(key => !isNaN(Number(key)) && key !== 0);

  rewardGameStatuses = RewardGameStatus;
  rewardGameStatusesKeys = Object.values(RewardGameStatus).filter(key => !isNaN(Number(key)));

}
