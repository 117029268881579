import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TokenStorage } from './token-storage.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PermissionCode } from '../models/permission-code';
import { LoggedInUserDetails } from '../../core/services/user-details.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

declare var $: any;

const _ = require("underscore");
@Injectable()
export class AuthService {
  private _baseUrl = '/api/account';

  constructor(private _http: HttpClient,
    private _token: TokenStorage,
    private _jwtHelper: JwtHelperService,
    private dialog: MatDialog,
    private _router: Router,

    private _userDetailsService: LoggedInUserDetails) { }

  attemptAuth(userName: string, password: string): Observable<any> {
    const credentials = { userName: userName, password: password };
    return this._http.post<any>(this._baseUrl, credentials).pipe(
      tap(res => this.setSession));
  }

  private setSession(authResult) {
    this._token.saveToken(authResult);
  }

  logout(): void {
    this._token.clearToken();
    this._userDetailsService.clearUserName();
    this._router.navigate(['login']);
    $(document).idleTimer('destroy');
  }

  isClientActive(): Observable<any> {
    return this._http.get<any>(this._baseUrl + '/' + this.clientId());
  }

  isAuthenticated(): boolean {
    return !this._jwtHelper.isTokenExpired(this._token.getToken());
  }

  forgotPassword(email: string): Observable<any> {
    const emailToSend = {
      Email: email
    }
    return this._http.put(this._baseUrl + '/ForgotPassword', emailToSend);
  }

  resetPassword(forgotPasswordCode: string, newPassword: string): Observable<any> {


    const yobjict = {
      ForgotCode: forgotPasswordCode,
      NewPassword: newPassword
    };

    return this._http.put(this._baseUrl + '/ChangePasswordWithCode', yobjict);
  }

  permissions(): PermissionCode[] {
    let permissions: any[] = this._jwtHelper.decodeToken(this._token.getToken())['permission'];

    permissions = permissions.map(function (permission) {
      const p = (_.invert(PermissionCode))[permission];
      return PermissionCode[p];
    });

    return permissions;
  }

  initializeIdleSessionTimeout(idleTimeLimit) {
    ($(document)).idleTimer({
      timeout: idleTimeLimit * 60 * 1000, //convert from minutes to milliseconds
      idle: false
    });

    $(document).on('idle.idleTimer', (event, elem, obj) => {
      console.log('on timeout')
      var that = this;
      // function to fire when the user goes idle 
      window.onbeforeunload = null;
      this.logout();
      this._router.navigate(['login']);

    });
  };

  hasSalesMenuPermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.SalesMenu);
  }

  hasInventoryMenuPermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.InventoryMenu);
  }

  hasEmployeesMenuPermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.EmployeesMenu);
  }

  hasAdminMenuPermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.AdminMenu);
  }

  hasRankPermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.Rank);
  }
  hasEditRankPermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.EditRank);
  }

  hasInvoicePermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.SalesByInvoice);
  }

  hasDashboardPermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.Dashboard);
  }

  hasDashboardSinglePermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.DashboardSingle);
  }

  hasSalesMTDPermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.SalesMTD);
  }

  hasInventoryPermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.Inventory);
  }

  hasPerformanceSummaryPermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.PerformanceSummary);
  }

  hasEmployeeCommissionsSpiffPermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.EmployeeCommissionsSpiff);
  }

  hasUsersPermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.Users);
  }

  hasCommissionUserPermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.CommissionUser);
  }

  hasRoleManagerPermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.RoleManager);
  }

  hasImportPermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.Import);
  }

  hasRewardGameCreatePermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.CreateRewardGame);
  }

  hasRewardReconcilePermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.RewardsReconcile);
  }

  hasRewardDashboardPermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.RewardsDashboard);
  }

  hasCommissionMenuPermission(): boolean {
    if (this.hasCommissionReconcilePermission() || this.hasDailyCommissionReconcilePermission() || this.hasDatascapeReconcilePermission() || this.hasShippingReconcilePermission()) {
      return true;
    }
    return false;
  }

  hasCommissionReconcilePermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.CommissionReconcile);
  }

  hasDailyCommissionReconcilePermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.DailyCommissionReconcile);
  }

  hasDatascapeReconcilePermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.DatascapeReconcile);
  }

  hasShippingReconcilePermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.ShippingReconcile);
  }

  hasFormulaViewGroupsPermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.FormulaViewGroups);
  }

  hasFormulasPermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.Formulas);
  }

  hasSkuGroupsPermission(): boolean {
    return this.permissions().some(p => p === PermissionCode.SkuGroups);
  }

  roles() {
    return this.decodedToken()['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
  }

  userId() {
    return this.decodedToken()['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
  }

  decodedToken() {
    return this._jwtHelper.decodeToken(this._token.getToken());
  }

  clientId() {
    return this.decodedToken().clientId;
  }

  isSuperAdmin() {
    return this.roles() === 'Super Admin';
  }

  isAdmin() {
    return this.roles() === 'Operations Manager';
  }
}
