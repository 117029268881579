<header class="site-header">
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <div style="display: flex; flex-wrap: wrap; align-items: center;">
        <button mat-icon-button (click)="sidenav.toggle()"><mat-icon>menu</mat-icon></button>
        <a class="navbar-brand" [routerLink]="['/welcome']"><h1>Qretails</h1></a>
      </div>
      <div class="flex-spacer"></div>
      <div class="d-flex align-items-center justify-content-end margin-top-1em">
        <mat-form-field class="location-dropdown  width-10" *ngIf="_authService.isSuperAdmin() && selectedReportType && selectedReportType.lowestLocationToShow > 0">
          <mat-select [ngClass]="[localStorageLoadingInProgress ?  'mat-select-color-disabled' : 'mat-select-color']" placeholder="Client" [(ngModel)]="selectedClientId" [(value)]="selectedClientId" (selectionChange)="onClientSelected()" [disabled]="localStorageLoadingInProgress">
            <mat-option *ngFor="let client of clients" [value]="client.id">{{client.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="location-dropdown header-option-margin-left width-10" *ngIf="regions && regions.length > 0 && selectedReportType && selectedReportType.lowestLocationToShow > 1">
          <mat-select [ngClass]="[localStorageLoadingInProgress || regions.length <= 1 ?  'mat-select-color-disabled' : 'mat-select-color']" placeholder="Region" [(ngModel)]="selectedRegionId" [(value)]="selectedRegionId" (selectionChange)="onRegionSelected()" [disabled]="localStorageLoadingInProgress || regions.length <= 1">
            <mat-option *ngIf="selectedReportType.allowUnselectedLocationUpTo <= 2" [value]="0">Select All</mat-option>
            <mat-option *ngFor="let region of regions" [value]="region.id">{{region.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="location-dropdown header-option-margin-left width-10" *ngIf="districts && districts.length > 0 && selectedReportType && selectedReportType.lowestLocationToShow > 2">
          <mat-select [ngClass]="[localStorageLoadingInProgress || districts.length <= 1 ?  'mat-select-color-disabled' : 'mat-select-color']" placeholder="District" [(ngModel)]="selectedDistrictId" [(value)]="selectedDistrictId" (selectionChange)="onDistrictSelected()" [disabled]="localStorageLoadingInProgress || districts.length <= 1">
            <mat-option *ngIf="selectedReportType.allowUnselectedLocationUpTo <= 3" [value]="0">Select All</mat-option>
            <mat-option *ngFor="let district of districts" [value]="district.id">{{district.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="location-dropdown header-option-margin-left width-10" *ngIf="stores && stores.length > 0 && selectedReportType && selectedReportType.lowestLocationToShow > 3">
          <mat-select [ngClass]="[localStorageLoadingInProgress || stores.length <= 1 ?  'mat-select-color-disabled' : 'mat-select-color']" placeholder="Store" [(ngModel)]="selectedStoreId" [(value)]="selectedStoreId" (selectionChange)="onStoreSelected()" [disabled]="localStorageLoadingInProgress || stores.length <= 1">
            <mat-option *ngIf="selectedReportType.allowUnselectedLocationUpTo <= 4" [value]="0">Select All</mat-option>
            <mat-option *ngFor="let store of stores" [value]="store.id">{{store.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="location-dropdown header-option-margin-left  width-10" *ngIf="filteredEmployees && filteredEmployees.length > 0 && selectedReportType && selectedReportType.lowestLocationToShow > 4">
          <mat-select [ngClass]="[localStorageLoadingInProgress || filteredEmployees.length <= 1 ?  'mat-select-color-disabled' : 'mat-select-color']" placeholder="Employee" [(ngModel)]="selectedEmployeeId" [(value)]="selectedEmployeeId" (selectionChange)="onEmployeeSelected()" [disabled]="localStorageLoadingInProgress || filteredEmployees.length <= 1">
            <mat-option *ngIf="selectedReportType.allowUnselectedLocationUpTo <= 5" [value]="0">Select All</mat-option>
            <mat-option *ngFor="let employee of filteredEmployees" [value]="employee.id">{{employee.user.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="header-option-margin-left location-dropdown">
          <div class="d-flex align-items-center tab " [matMenuTriggerFor]="userProfile" matTooltip="{{loggedInUserName}}">
            <img src="assets/img/admin-logo.svg" alt="" class="admin-logo">
            <mat-label class="client-name mx-3">{{clientName}}</mat-label>
            <mat-icon>arrow_drop_down</mat-icon>
          </div>
          <mat-menu #userProfile="matMenu">
            <a mat-menu-item [routerLink]="['/changepassword']">Change Password</a>
            <a mat-menu-item href="#" (click)="logout();" matTooltip="{{loggedInUserName}}">Logout {{loggedInUserName}}</a>
          </mat-menu>

        </div>

      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <p class="heading-text m-0 app-heading">{{ pageTitle }}</p>
  <mat-sidenav-container>

    <mat-sidenav #sidenav id="visibilityContainer">
      <div class="docs-component-viewer-nav">
        <div class="docs-component-viewer-nav-content">

          <mat-accordion>
            <!--<mat-expansion-panel >
              <mat-expansion-panel-header><mat-panel-title>Welcome</mat-panel-title></mat-expansion-panel-header>
              <mat-nav-list>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/welcome']"  (click)="sidenav.toggle()">Welcome</a>
              </mat-nav-list>
            </mat-expansion-panel>-->

            <mat-expansion-panel *ngIf="_authService.hasSalesMenuPermission()">
              <mat-expansion-panel-header><mat-panel-title>Sales</mat-panel-title></mat-expansion-panel-header>
              <mat-nav-list>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/dashboard']" *ngIf="_authService.hasDashboardPermission()" (click)="sidenav.toggle()">Dashboard</a>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/invoice-viewer']" *ngIf="_authService.hasInvoicePermission()" (click)="sidenav.toggle()">Invoices</a>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/mtd-report']" *ngIf="_authService.hasSalesMTDPermission()" (click)="sidenav.toggle()">Month To Date (MTD)</a>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/rank-contest']" *ngIf="_authService.hasRankPermission()" (click)="sidenav.toggle()">Rank</a>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/dashboard-single']" *ngIf="_authService.hasDashboardSinglePermission()" (click)="sidenav.toggle()">LineChart</a>
              </mat-nav-list>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="_authService.hasInventoryMenuPermission()">
              <mat-expansion-panel-header><mat-panel-title>Inventory</mat-panel-title></mat-expansion-panel-header>
              <mat-nav-list>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/inventory']" (click)="sidenav.toggle()">Inventory</a>
              </mat-nav-list>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="_authService.hasEmployeesMenuPermission()">
              <mat-expansion-panel-header><mat-panel-title>Employees</mat-panel-title></mat-expansion-panel-header>
              <mat-nav-list>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/performance-summary']" *ngIf="_authService.hasPerformanceSummaryPermission()" (click)="sidenav.toggle()">Performance Summary</a>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/forecast']" *ngIf="_authService.hasEmployeeCommissionsSpiffPermission()" (click)="sidenav.toggle()">Employee Commissions</a>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/rewards-dashboard']" *ngIf="_authService.hasRewardDashboardPermission()" (click)="sidenav.toggle()">Rewards Dashboard</a>
              </mat-nav-list>
            </mat-expansion-panel>

            <mat-expansion-panel *ngIf="_authService.hasCommissionMenuPermission()">
              <mat-expansion-panel-header><mat-panel-title>Reconcile</mat-panel-title></mat-expansion-panel-header>
              <mat-nav-list>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/commission-reconcile']" *ngIf="_authService.hasCommissionReconcilePermission()" (click)="sidenav.toggle()">Monthly Commissions</a>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/ccrs-reconcile']" *ngIf="_authService.hasDailyCommissionReconcilePermission()" (click)="sidenav.toggle()">Daily Commissions</a>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/datascape-reconcile']" *ngIf="_authService.hasDatascapeReconcilePermission()" (click)="sidenav.toggle()">Bill Payments</a>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/shipment-reconciliation']" *ngIf="_authService.hasShippingReconcilePermission()" (click)="sidenav.toggle()">Shipments</a>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/monthly-shipment-reconciliation']" *ngIf="_authService.hasShippingReconcilePermission()" (click)="sidenav.toggle()">Monthly Shipments</a>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/rewards-claim-management']" *ngIf="_authService.hasRewardReconcilePermission()" (click)="sidenav.toggle()">Reconcile Rewards</a>
              </mat-nav-list>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="_authService.isSuperAdmin() || _authService.hasAdminMenuPermission() || _authService.hasUsersPermission() || _authService.hasCommissionUserPermission()">
              <mat-expansion-panel-header><mat-panel-title>Users</mat-panel-title></mat-expansion-panel-header>
              <mat-nav-list>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/role-manager']" (click)="sidenav.toggle()">Role Manager</a>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/user-lookup']" *ngIf="_authService.hasUsersPermission()" (click)="sidenav.toggle()">User Look Up</a>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/commission-user']" *ngIf="_authService.hasCommissionUserPermission()" (click)="sidenav.toggle()">Setup Commissions</a>
              </mat-nav-list>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="_authService.hasSkuGroupsPermission() || _authService.hasFormulasPermission() || _authService.hasFormulaViewGroupsPermission()">
              <mat-expansion-panel-header><mat-panel-title>Formulas</mat-panel-title></mat-expansion-panel-header>
              <mat-nav-list>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/sku-group']" *ngIf="_authService.hasSkuGroupsPermission()" (click)="sidenav.toggle()">Sku Groups</a>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/formulas']" *ngIf="_authService.hasFormulasPermission()" (click)="sidenav.toggle()">Formulas</a>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/formula-view-group']" *ngIf="_authService.hasFormulaViewGroupsPermission()" (click)="sidenav.toggle()">Formula View Groups</a>
              </mat-nav-list>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="_authService.isSuperAdmin() || _authService.hasAdminMenuPermission()">
              <mat-expansion-panel-header><mat-panel-title>Admin</mat-panel-title></mat-expansion-panel-header>
              <mat-nav-list>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/rewards-create']" *ngIf="_authService.hasRewardGameCreatePermission()" (click)="sidenav.toggle()">Reward Game Manager</a>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/location-manager']" (click)="sidenav.toggle()">Location Manager</a>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/import']" *ngIf="_authService.hasImportPermission()" (click)="sidenav.toggle()">Import</a>
              </mat-nav-list>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="_authService.isSuperAdmin()">
              <mat-expansion-panel-header><mat-panel-title>SuperAdmin</mat-panel-title></mat-expansion-panel-header>
              <mat-nav-list>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/clients']" *ngIf="_authService.isSuperAdmin()" (click)="sidenav.toggle()">Clients</a>
                <a mat-list-item class="mat-list-item" routerLinkActive="docs-component-viewer-sidenav-item-selected" [routerLink]="['/upload-config']" *ngIf="_authService.isSuperAdmin()" (click)="sidenav.toggle()">Config Import</a>
              </mat-nav-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="body-container">
        <div class="mat-app-background">
          <div class="mat-elevation-z8">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</header>
<style>
</style>
