<div class="col-md-12 bg-white p-0">
  <div class="row pt-20" *ngIf="!invoiceIsLoading">
    <div class="col-md-3">
      <mat-form-field style="" class="searchtab margin-left-1em">
        <mat-label>Month and Year</mat-label>
        <input matInput [matDatepicker]="datepicker" [formControl]="date">
        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker startView="year" (yearSelected)="yearHandler($event)" (monthSelected)="monthHandler($event, datepicker)"></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <div style="display: flex;" class="margin-left-1em vertical-center">
        <label class="form-label semibold margin-left-1em margin-right-1em" for="selectedDayToFilter">Ignore Recent Days</label>
        <mat-icon color="warn" class="material-icons-round" mat-raised-button type="button" (click)="filterDays(1)">remove_circle</mat-icon>
        <span class="margin-right-1em margin-left-1em">{{ getDateFromIgnore()  }}</span>
        <mat-icon color="primary" class="material-icons-round" mat-raised-button type="button" (click)="filterDays(-1)">add_circle</mat-icon>
      </div>
    </div>
    <div class="col-md-3">
      <div class="row">
        <div class="col-md-12">
          <mat-checkbox class="margin-left-1em vertical-center" [(ngModel)]="showReconciled" [checked]="showReconciled" (change)="updateDataSource(imeiList)" [labelPosition]="'before'">Show Reconciled</mat-checkbox>
        </div>
      </div>
    </div>
    <div class="col-md-3" *ngIf="doneLoading">
      <div class="flex-items" style="min-width: 12vw; display: inline-block;">
        RQ Installments :
        <span style="text-align: right; float: right; font-weight: bold; margin-right: 20px"> ${{imeiList.totalExpectedInstallments.toFixed(2) }}</span>
        <br />
        VZ Installments :
        <span style="text-align: right; float: right; font-weight: bold; margin-right: 20px"> ${{ imeiList.totalCollectedInstallments.toFixed(2) }}</span>
        <br />
        Most Recent Rebate Data Date :
        <span style="text-align: right; float: right; font-weight: bold; margin-right: 20px"> {{ recentDataDate  }}</span>
      </div>
    </div>
  </div>
</div>
<div class="flex-container" *ngIf="!invoiceIsLoading">
  <div class="flex-items pt-20">
  
  </div>
  <div class="flex-items pt-20">
   
  </div>
  <div class="flex-items pt-20">
   
  </div>
  <div class="flex-items margin-left-1em vertical-center">
   
  </div>

</div>
<div class="col-md-12 bg-white mb-2 p-3">
  <div class="row">
    <div class="col-md-3">
      <mat-form-field class="searchtab">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" [(ngModel)]="searchTerm">
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field class="margin-left-1em searchtab">
        <mat-select placeholder="Select columns" multiple [(value)]="selectedColumns" (selectionChange)="columnsChanged($event)">
          <mat-select-trigger>
            {{selectedColumns ? getColumnDefinationByMappedToProperty(selectedColumns[0]).name : ''}}
            <span *ngIf="selectedColumns?.length > 1" class="example-additional-selection">
              (+{{selectedColumns.length - 1}} {{selectedColumns?.length === 2 ? 'other' : 'others'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let column of columnDefinations" [value]="column.mappedToProperty">{{column.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md-6 text-right pt-2">
      <button color="primary" class="margin-left-1em" mat-raised-button type="button" *ngIf="doneLoading" (click)="exportTableToExcel()"> Export </button>
      <button *ngIf="toBeReconciled.size>0" mat-raised-button color="primary" class="margin-left-1em" type="button" (click)="openReconcileModal(true)">Reconcile selected</button>
      <button *ngIf="showReconciled && toBeUnreconciled.size>0" mat-raised-button color="primary" class="margin-left-1em" type="button" (click)="openReconcileModal(false)">Unreconcile selected</button>

    </div>

  </div>
</div>


<div class="row ">
  <div class="col-md-6 table-responsive pb-2">
    <div [hidden]="!doneLoading" class="table-container">
      <table *ngIf="selectedColumns.length>0" mat-table [dataSource]="dataSource" #ccrsTableSort="matSort" matSort class="table table-stripped table-hover small-txt">
        <ng-container *ngFor="let column of getDisplayedColumns(); let colIndex = index;" [matColumnDef]="column">
          <th [hidden]="getColumnDefinationByMappedToProperty(column).hide" class="sort-column mat-sort-header-button-custom mat-header-cell " mat-header-cell *matHeaderCellDef mat-sort-header
              [matTooltip]="getColumnDefinationByMappedToProperty(column).name"
              [disabled]="!getColumnDefinationByMappedToProperty(column).isSortable" [ngClass]="getColumnDefinationByMappedToProperty(column).class">
            <pre>{{getColumnDefinationByMappedToProperty(column).name}}</pre>
          </th>
          <ng-container *matCellDef="let element">
            <td class="text-center" mat-cell [matTooltip]="getTextBydata(element,getColumnDefinationByMappedToProperty(column))" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip (click)="openNotesDialog(column,element)"
                [ngClass]="[selectedImei.imei == element.imei ? 'yellow' : 'white', getMatCellClass(getColumnDefinationByMappedToProperty(column))]">
              <span *ngIf="column !== 'hidden' && column !== 'notes' && column !== 'icon' && column !== 'imeiForDisplay' && column !== 'productName'">
                {{getTextBydata(element,getColumnDefinationByMappedToProperty(column))}}
              </span>
              <span *ngIf="column === 'productName'">
                {{getTextBydata(element,getColumnDefinationByMappedToProperty(column))}}
              </span>
              <span *ngIf="column === 'imeiForDisplay' && getTextBydata(element,getColumnDefinationByMappedToProperty(column)).length > 0" (click)="openHistoryDialog(element)">
                <a>{{getTextBydata(element,getColumnDefinationByMappedToProperty(column))}}</a>
              </span>

              <span *ngIf="column === 'notes'"><div class="fa fa-sticky-note">{{getNoteCountText(element)}}</div></span>
              <span *ngIf="column === 'icon'">
                <div class="fas fa-circle" [ngStyle]="{'color': getIconColor(element)}"> </div>
              </span>
              <mat-checkbox *ngIf="column === 'hidden'" type="checkbox" [(ngModel)]="element['hidden']" [checked]="element['hidden']" (change)="collectToReconcile($event,element) "></mat-checkbox>
            </td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
        <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();" (click)="selectImei(row)">
        </tr>
      </table>
      <mat-paginator #ccrsTablePaginator="matPaginator" [pageSizeOptions]="[5, 10, 20]" [pageSize]="10" showFirstLastButtons></mat-paginator>
    </div>

  </div>
  <div class="col-md-6" *ngIf="selectedImei && doneLoading">
    <div class=" bg-white pt-2" style="font-size: 20px;padding:10px; border-bottom: #ded7d7 1px solid;" *ngIf="doneLoading && selectedImei && selectedImei.rqItems && selectedImei.rqItems.length > 0">
      <span style="margin-right:25px">RQ Items</span>
    </div>
    <div class="table-container">
      <table [dataSource]="selectedImei.rqItems" mat-table class="table table-stripped table-hover small-txt" *ngIf="doneLoading && selectedImei && selectedImei.rqItems && selectedImei.rqItems.length > 0">
        <ng-container *ngFor="let column of getRqDisplayedColumns(); let colIndex = index;" [matColumnDef]="column">
          <th [hidden]="getRqColumnDefinationByMappedToProperty(column).hide" class="sort-column mat-sort-header-button-custom mat-header-cell" mat-header-cell *matHeaderCellDef
              [matTooltip]="getRqColumnDefinationByMappedToProperty(column).name" [disabled]="!getRqColumnDefinationByMappedToProperty(column).isSortable"
              [ngClass]="getRqColumnDefinationByMappedToProperty(column).class">
            {{getRqColumnDefinationByMappedToProperty(column).name}}
          </th>
          <ng-container *matCellDef="let element">
            <td mat-cell class="mat-cell" [matTooltip]="element[getRqColumnDefinationByMappedToProperty(column).mappedToProperty]" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip [ngClass]="getRqColumnDefinationByMappedToProperty(column).class">
              {{getTextBydata(element, getRqColumnDefinationByMappedToProperty(column))}}
            </td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="getRqDisplayedColumns()"></tr>
        <tr mat-row *matRowDef="let row; columns: getRqDisplayedColumns();">
        </tr>
      </table>

      <div style="font-size: 20px;text-align:right;margin-top:10px; margin-right:50px; width:100%" *ngIf="doneLoading && selectedImei && selectedImei.vzItems && selectedImei.vzItems.length >0">
        <span style="margin-right:25px"> Verizon Items</span>  </div>
      <table *ngIf="selectedVerizonColumns.length > 0 && selectedImei.vzItems.length >0" mat-table [dataSource]="selectedImei.vzItems" class="table table-stripped table-hover small-txt">
        <ng-container *ngFor="let column of getVerizonDisplayedColumns(); let colIndex = index;" [matColumnDef]="column">
          <th [hidden]="getVerizonColumnDefinationByMappedToProperty(column).hide" class="sort-column mat-sort-header-button-custom mat-header-cell" *matHeaderCellDef
              [matTooltip]="getVerizonColumnDefinationByMappedToProperty(column).name" [disabled]="!getVerizonColumnDefinationByMappedToProperty(column).isSortable">
            {{getVerizonColumnDefinationByMappedToProperty(column).name}}
          </th>
          <ng-container *matCellDef="let element">
            <td mat-cell class="mat-cell" [matTooltip]="element[getVerizonColumnDefinationByMappedToProperty(column).mappedToProperty]" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip>
              {{getTextBydata(element, getVerizonColumnDefinationByMappedToProperty(column))}}
            </td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="getVerizonDisplayedColumns()"></tr>
        <tr mat-row *matRowDef="let row; columns: getVerizonDisplayedColumns();">
        </tr>
      </table>
      <div style="height:10px"></div>
    </div>
  </div>
</div>

<div class="row" style="margin-left:-12px;margin-right:1px">

</div>

