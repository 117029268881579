<style>

  #container {
    height: 15vh;
    column-count: 2;
    column-fill: auto;
  }

    #container > div {
      width: 10vw;
      height: 2vh;
    }

  #option-box {
    min-width: 10vw;
    max-width: 10vw;
    max-height: 5vh;
    min-height: 5vh;
    margin: 0.4vh;
  }
  #radio-box {
    min-width: 30vw;
    max-width: 30vw;
    max-height: 5vh;
    min-height: 5vh;
    margin: 0.4vh;
  }

  #option {
    max-width: 200px;
    max-height: 3vh;
    font-size: 12px;
    padding: 0.2vh;
  }

  #date-option {
    max-width: 125px;
    max-height: 25px;
    font-size: 12px;
    padding: 0px;
  }

  #invoiced-item {
    padding: 0px;
    margin: 3px;
    height: 10px;
    overflow: hidden;
  }

  #listItem {
    padding: 4px;
    margin: 0px;
    height: 2vh;
    text-align: center;
    overflow-x: hidden;
    white-space: nowrap;
  }

  .yellow {
    background-color: #ffd800;
    padding: 4px;
    margin: 0px;
    height: 2vh;
    text-align: center;
    overflow-x: hidden;
    white-space: nowrap;
  }

  .white {
    padding: 4px;
    margin: 0px;
    height: 2vh;
    text-align: center;
    overflow-x: hidden;
    white-space: nowrap;
    background-color: #ffffff;
  }
  .table-displays{
    display:inline-block;
    float:left;
    padding:25px;
  }

  .table-spacing {
    border-spacing: 0px 10px;
    border-collapse: separate;
  }

  .row-border {
    border-top: none;
    border-top-color: #ffffff;
  }

  .align-bottom {
    vertical-align: bottom;
    position: absolute;
    bottom: 0;
  }
</style>

<div class="container-fluid mtd-report">
  <div class="box-typical box-typical-padding" style="height:90vh">
    <div style="height:30vh">
      <div class="col-sm-2 table-displays" id="option-box">

        <fieldset class="form-group">
          <label class="form-label semibold" style="font-size:12px;" for="selectedProfile">Profile:</label>
          <select *ngIf="_authService.isSuperAdmin() || _authService.isAdmin()" id="option" class="form-control" name="selectedProfile" [(ngModel)]="selectedProfile" (change)="onProfileSelected();">
            <option [ngValue]="0">Select Profile</option>
            <option *ngFor="let profile of uploadConfigProfiles" [ngValue]="profile">{{profile.type}}</option>
          </select>
        </fieldset>
        <button (click)="saveProfile()"> Save Profile </button>
        <button (click)="reloadProfile()"> Reload Profile </button>
        <button (click)="copyProfile()"> Copy Profile </button>
      </div>
      <div class="col-sm-2 table-displays" id="radio-box">
        <label class="col-sm-4 form-control-label">Section</label>
        <div class="col-sm-8">
          <p class="form-control">
            <input type="radio" #selectedOptions="ngModel" [value]="1" name="selectedOptions" [(ngModel)]="selectedOption" />&nbsp;Sheets&nbsp;
            <input type="radio" #selectedOptions="ngModel" [value]="2" name="selectedOptions" [(ngModel)]="selectedOption" />&nbsp;Functions&nbsp;
            <button (click)="selectedSheet = null">Unselect</button>
          </p>
        </div>
      </div>
      <div class="table-displays col-sm-2" *ngIf="selectedOption == 1 && doneLoading">
        <table class="table-bordered table-hover small-txt ">
          <thead>
            <tr>
              <th> Name </th>
              <th> Keyword </th>
              <th> Location </th>
              <th> Date </th>
              <th> DateRange </th>
              <th> defaultAdd </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let sheet of selectedProfile.configSheets">
              <tr *ngIf="selectedSheet ? selectedSheet.id == sheet.id : true"
                  [ngClass]="!selectedSheet ? 'white' : selectedSheet.id == sheet.id ? 'yellow' : 'white'"
                  (click)="selectedSheet = sheet; resetSelections(2)">
                <td> <input type="text" #name="ngModel" name="message" [(ngModel)]="sheet.name"> </td>
                <td> <input type="text" #keyword="ngModel" name="keyword" [(ngModel)]="sheet.keyword"> </td>
                <td>
                  <select #hasLocation="ngModel" name="hasLocation" [(ngModel)]="sheet.hasLocation">
                    <option [ngValue]="false">false</option>
                    <option [ngValue]="true">true</option>
                  </select>
                </td>
                <td>
                  <select #hasDate="ngModel" name="hasDate" [(ngModel)]="sheet.hasDate">
                    <option [ngValue]="false">false</option>
                    <option [ngValue]="true">true</option>
                  </select>
                </td>
                <td>
                  <select #includeDateRange="ngModel" name="includeDateRange" [(ngModel)]="sheet.includeDateRange">
                    <option [ngValue]="false">false</option>
                    <option [ngValue]="true">true</option>
                  </select>
                </td>
                <td>
                  <select #defaultAddOnClient="ngModel" name="defaultAddOnClient" [(ngModel)]="sheet.defaultAddOnClient">
                    <option [ngValue]="false">false</option>
                    <option [ngValue]="true">true</option>
                  </select>
              </tr>
            </ng-container>
            <tr><td><button (click)="addSheet()">Add Sheet</button></td></tr>
          </tbody>
        </table>
      </div>
    </div>

    <br />

    <div *ngIf="selectedOption == 1 && doneLoading" style="height:60vh">
      <table *ngIf="selectedSheet" class="table-bordered table-hover small-txt table-displays ">
        <thead>
          <tr><th>{{selectedSheet.name}}</th></tr>
          <tr><th> SheetName on XLS </th></tr>
        </thead>
        <tbody>
          <tr *ngFor="let table of selectedSheet.configSheetTables"
              [ngClass]="!selectedSheetTable ? 'white' : selectedSheetTable.id == table.id ? 'yellow' : 'white'"
              (click)="selectedSheetTable = table; resetSelections(3)">
            <td> <input type="text" #tablename="ngModel" name="tablename" [(ngModel)]="table.name"> </td>
          </tr>
          <tr><td><button (click)="addTable()">Add Table</button></td></tr>

        </tbody>
      </table>
      <table *ngIf="selectedSheetTable" class="table-bordered table-hover small-txt table-displays ">

        <thead>
          <tr><th>{{selectedSheetTable.name}}</th></tr>
          <tr>
            <th> Column Value </th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let column of selectedSheetTable.configTableColumns">
            <td> <input type="text" #columncolumn="ngModel" name="columncolumn" [(ngModel)]="column.column"></td>
          </tr>
          <tr><td><button (click)="addTableColumn()">Add Column</button></td></tr>
        </tbody>
      </table>

      <table *ngIf="selectedSheetTable" class="table-bordered table-hover small-txt table-displays ">
        <thead>
          <tr>
            <th> Functions That Use This </th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let function of selectedSheetTable.configFunctionsCalledInTable"
              [ngClass]="!selectedFunctionCalledInTable ? 'white' : selectedFunctionCalledInTable.id == function.id ? 'yellow' : 'white'"
              (click)="selectedFunctionCalledInTable = function; calculateUnusedVariables();">
            <td>
              {{function.configFunctionName}}
            </td>
          </tr>

          <tr>
            <td>
              <select #addingNewFunct="ngModel" name="addingNewFunct" [(ngModel)]="addingNewFunction" (change)="addFunctionToTable(addingNewFunction)">
                <option *ngFor="let func of selectedProfile.configFunctions" [ngValue]="func">{{func.name}}</option>
              </select>
            </td>
          </tr>



        </tbody>
      </table>



      <table *ngIf="selectedFunctionCalledInTable" class="table-bordered table-hover small-txt table-displays ">

        <thead>
          <tr><th>{{selectedFunctionCalledInTable.name}}</th></tr>
          <tr>
            <th> Variable Value </th>
            <th> Column Value </th>


          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let column of selectedFunctionCalledInTable.configTableColumnVariables">

            <td> {{column.configVariableName}}</td>
            <td> {{column.configTableColumnName}}</td>
          </tr>
          <tr></tr>
          <tr>
            <!--<td><select #addingNewFunctioncat="ngModel" name="addingNewFunctioncat" [(ngModel)]="addingNewVariable" (change)="addVariableToFCV(addingNewVariable)">-->
            <td>
              <select #addingNewVar="ngModel" name="addingNewVar" [(ngModel)]="addingNewVariable">
                <option> </option>
                <option *ngFor="let variab of notUsedVariables" [ngValue]="variab">{{variab.name}}</option>
              </select>

            </td>
            <td>
              <select #addingNewCol="ngModel" name="addingNewCol" [(ngModel)]="addingNewColumn">
                <option *ngFor="let column of selectedSheetTable.configTableColumns" [ngValue]="column">{{column.column}}</option>
              </select>
            </td>
          </tr>
          <tr><td><button (click)="addFCV();calculateUnusedVariables(); ">Add</button></td><td></td></tr>
        </tbody>
      </table>

    </div>
    <div *ngIf="selectedOption == 2">

      <table class="table-bordered table-hover small-txt table-displays ">
        <thead>
          <tr>
            <th> Name </th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let function of selectedProfile.configFunctions" (click)="selectedFunction = function;">
            <td><input type="text" #functionname="ngModel" name="functionname" [(ngModel)]="function.name"> </td>
          </tr>
          <tr><td><button (click)="addFunction()">addFunction</button></td></tr>



        </tbody>
      </table>
      <table *ngIf="selectedFunction" class="table-bordered table-hover small-txt table-displays">
        <thead>
          <tr>
            <th> Name </th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let variable of selectedFunction.configVariables">
            <td><input type="text" #variablename="ngModel" name="variablename" [(ngModel)]="variable.name"> </td>
          </tr>
          <tr><td><button (click)="addVariable()">addVariable</button></td></tr>


        </tbody>
      </table>


    </div>
  </div>

</div>

<!---
<div >
  <div >
    <div class="columns" style=" max-width : 20vw; float:left; display:inline-block ">
      <div style="width:100%; height:27vh">
        <div class="columns" style=" max-width : 10vw; float:left; display:inline-block;">
          <div class="col-sm-2" id="option-box">
            <fieldset class="form-group">
              <label class="form-label semibold" style="font-size:12px;" for="selectedClient">Client:</label>
              <select *ngIf="_authService.isSuperAdmin() || _authService.isAdmin()" id="option" class="form-control" name="selectedClientId" [(ngModel)]="selectedClientId" (change)="onClientSelected();">
                <option [ngValue]="0">Select Client</option>
                <option *ngFor="let client of clients" [value]="client.id">{{client.name}}</option>
              </select>
              <div *ngIf="!_authService.isSuperAdmin()">{{clientName}}</div>
            </fieldset>
          </div>
          <div class="col-sm-2" id="option-box">
            <fieldset class="form-group">
              <label class="form-label semibold" style="font-size:12px;" for="selectedRegion">Region:</label>
              <select *ngIf="_authService.isSuperAdmin() || _authService.isAdmin() " id="option" class="form-control" name="selectedRegionId" [(ngModel)]="selectedRegionId" (change)="onRegionSelected();">
                <option [ngValue]="0">Select Region</option>
                <option *ngFor="let region of regions" [value]="region.id">{{region.name}}</option>
              </select>
              <div *ngIf="!_authService.isSuperAdmin() && !_authService.isAdmin() ">{{regionName}}</div>
            </fieldset>
          </div>
          <div class="col-sm-2" id="option-box">
            <fieldset class="form-group">
              <label class="form-label semibold" style="font-size:12px;" for="selectedDistrict">District:</label>
              <select *ngIf="_authService.isSuperAdmin() || _authService.isAdmin() " id="option" class="form-control" name="selectedDistrictId" [(ngModel)]="selectedDistrictId" (change)="onDistrictSelected();">
                <option [ngValue]="0">All Districts</option>
                <option *ngFor="let district of districts" [value]="district.id">{{district.name}}</option>
              </select>
              <div *ngIf="!_authService.isSuperAdmin() && !_authService.isAdmin() ">{{districtName}}</div>
            </fieldset>
          </div>
          <div class="col-sm-2" id="option-box">
            <fieldset class="form-group">
              <label class="form-label semibold" style="font-size:12px;" for="selectedStore">Store:</label>
              <select id="option" class="form-control" name="selectedStoreId" [(ngModel)]="selectedStoreId" (change)="onStoreSelected();">
                <option [ngValue]="0">All Stores</option>
                <option *ngFor="let store of stores" [value]="store.id">{{store.name}}</option>
              </select>
              <div *ngIf="!_authService.isSuperAdmin() && !_authService.isAdmin() ">{{storeName}}</div>
            </fieldset>
          </div>
          <div class="col-sm-2" id="option-box">
            <fieldset class="form-group">
              <label class="form-label semibold" style="font-size:12px;" for="duration">Duration</label>
              <select id="option" class="form-control" name="duration" [(ngModel)]="selectedDuration" (change)="onDurationSelected();" [disabled]="localStorageLoadingInProgress">
                <option [ngValue]="0">Select Duration</option>
                <option *ngFor="let duration of durations" [value]="duration.value">{{duration.text}}</option>
              </select>
            </fieldset>
          </div>
        </div>
        <div class="columns" style=" max-width : 10vw; float:left;  display:inline-block;">
          <div class="col-sm-2" id="option-box">
            <fieldset class="form-group">
              <label class="form-label semibold" style="font-size:12px;">Has</label>
              <select id="option" class="form-control" name="selectedHasFilter" [(ngModel)]="selectedHasFilter" (change)="onHasFilterSelected();">
                <option [value]="0">Select Filter</option>
                <option *ngFor="let skugroup of skuGroups" [value]="skugroup.name">{{skugroup.name}}</option>
              </select>
            </fieldset>
          </div>
          <div class="col-sm-2" id="option-box">
            <fieldset class="form-group">
              <label class="form-label semibold" style="font-size:12px;">Missing</label>
              <select id="option" class="form-control" name="selectedHasNotFilter" [(ngModel)]="selectedHasNotFilter" (change)="onHasFilterSelected();">
                <option [value]="0">Select Has Not Filter</option>
                <option *ngFor="let skugroup of skuGroups" [value]="skugroup.name">{{skugroup.name}}</option>
              </select>
            </fieldset>
          </div>
          <div class="col-sm-2" id="option-box">
            <fieldset class="form-group">
              <label class="form-label semibold" style="font-size:12px;">Hide Zero GP Items</label>
              <input type="checkbox" [(ngModel)]="hideZeroProfitItems" />
            </fieldset>
          </div>
          <div class="col-sm-2" id="option-box">
            <fieldset class="form-group">
              <label class="form-label semibold" style="font-size:12px;">Invoices</label>
              <div>{{invoices.length}}
              </div>
            </fieldset>
          </div>
          <div class="col-sm-2" id="option-box" style="position:relative">
            <fieldset class="form-group" style="border-collapse: collapse;">
              <button *ngIf="!invoiceIsLoading" type="button" class="btn align-bottom" (click)="getInvoices()">Submit</button>
              <button *ngIf="invoiceIsLoading" disabled type="button" class="btn align-bottom" (click)="getInvoices()">Submit</button>
            </fieldset>
          </div>
        </div><br />
      </div>
      <div style=" max-width : 20vw; float:left; bottom:100%; display:block; margin-top: 1vh; ">
        <div class="col-sm-2" *ngIf="singleOtherDurationSelection" style=" max-width : 200px;">
          <fieldset class="form-group">
            <label class="form-label semibold" for="duration" style="font-size:12px">Period</label>
            <input class="form-control" type="month" id="option" [(ngModel)]="selectedOtherDuration"
                   [disabled]="localStorageLoadingInProgress" />
          </fieldset>
        </div>
        <div class="col-sm-12" *ngIf="customDurationSelection" style=" max-width : 20vw;  margin-top: 1vh; ">
          <fieldset class="form-group" style="font-size:12px;">
            <div style="max-width: 9vw; display:inline-block; float:left;">
              From: <br /> <input class="form-control col-sm-2 inline-block-element" id="date-option" type="date" [(ngModel)]="customRangeStartDate" />
            </div>
            <div style="max-width: 9vw; display:inline-block; float:right;">
              To:<br /> <input class="form-control col-sm-2 inline-block-element" id="date-option" type="date" [(ngModel)]="customRangeEndDate" />
            </div>
          </fieldset>
        </div>
      </div>
      <div style=" display:inline-block; margin-top:2vh; ">
        <div style="height:40vh; overflow: auto">
          <table #invoiceTable class="table table-bordered table-hover small-txt ">
            <thead>
              <tr>
                <th (click)="sortTableInvoice( );" style=" text-align:center"> Invoice # </th>
                <th (click)="sortTableStore(  );" style=" text-align:center">  Store </th>
                <th (click)="sortTableDate(  );" style=" text-align:center"> Date </th>
                <th (click)="sortTableEmployee(  );" style=" text-align:center"> Sold By </th>
                <th (click)="sortTableCustomer();" style=" text-align:center"> Customer </th>
                <th (click)="sortTableGP();" style=" text-align:center"> GrossProfit </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let invoice of invoices">
                <tr [ngClass]="selectedInvoice.invoiceNumber == invoice.invoiceNumber ? 'yellow' : 'white'"
                    (click)="selectInvoice(invoice)" id="invoice">
                  <td id="invoice">{{invoice.invoiceNumber}}</td>
                  <td id="invoice">{{invoice.storeName}}</td>
                  <td id="invoice">{{invoice.soldOnNoYear}}</td>
                  <td id="invoice">{{invoice.employeeName.substr(0,20)}}</td>
                  <td id="invoice">{{invoice.customerName.substr(0,18)}}</td>
                  <td id="invoice">{{convertNumberToString(invoice.grossProfit)}}</td>
               </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="columns" style=" max-width :45vw; float:left; display:inline-block; margin-left:21vw " *ngIf="selectedInvoice">
      <div style=" max-width: 45vw; ">
        <div style="float:left; display:inline-block; width:29vw ">
          <span *ngFor="let sk of selectedInvoice.skuGroups">
            <div style="display: inline-block; float:left">
              <a *ngIf="sk.name=='Diamond Bundles' && sk.qty > 0" class="icon-btn">&nbsp;&nbsp;Bundle:&nbsp;<i style="color:deepskyblue" class="fa fa-gem"></i></a>
              <a *ngIf="sk.name=='Platinum Bundles' && sk.qty > 0" class="icon-btn">&nbsp;&nbsp;Bundle:&nbsp;<i style="color:silver" class="fa fa-gem"></i></a>
              <a *ngIf="sk.name=='Bundles' && sk.qty == 0" class="icon-btn">&nbsp;&nbsp;Bundle:&nbsp;<i style="color:red" class="fa fa-times-circle"></i></a>
            </div>
            <div style="display: inline-block; float:left">
              <a *ngIf="sk.name=='Hyla' && sk.qty > 0" class="icon-btn">  Trade In:&nbsp;<i style="color:green" class="fa fa-check-circle"></i></a>
              <a *ngIf="sk.name=='Hyla' && sk.qty == 0" class="icon-btn">  Trade In:&nbsp;<i style="color:red" class="fa fa-times-circle"></i></a>
            </div>
          </span><br />
          <div>
            <span style="font-weight:bold;"> Bill To</span> :<span> {{selectedInvoice.customerName}}</span><br />
            <div>
              <div id="container">
                <span *ngFor="let skug of selectedInvoice.skuGroups">
                  <div *ngIf="skug.qty>0 || skug.qty<0 " style="font-size:.8em">
                    {{skug.name}} : {{skug.qty}} <br />
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div style="float:left; display:inline-block;  text-align:left; width: 16vw">
          <div> Invoice : <span> {{selectedInvoice.invoiceNumber}} </span> </div>
          <br />
          <div> Tendered on : <span> {{selectedInvoice.soldOn}} </span></div>
          <div> Sales Person  : <span> {{selectedInvoice.employeeName}} </span> </div>
          <div> Tendered At : <span> {{selectedInvoice.storeName}} </span></div>
        </div>
      </div>
      <div>
        <table class="table table-hover small-txt table-spacing" style="width:45vw; border:none">
          <thead>
            <tr>
              <th> Product SKU </th>
              <th> Product Name </th>
              <th> Tracking # </th>
              <th> Qty </th>
              <th> Your Price </th>
              <th> Gross Profit </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of selectedInvoice.invoicedItems">

              <tr *ngIf="(item.grossProfit !== 0 || item.totalCost !== 0) || !hideZeroProfitItems" id="invoiced-item" class="row-border">
                <td class="row-border" id="invoiced-item">{{item.productCatalog.productSku}}</td>
                <td class="row-border" id="invoiced-item">{{item.productCatalog.productName}}</td>
                <td class="row-border" id="invoiced-item">{{item.trackingNumber}}</td>
                <td class="row-border" id="invoiced-item">{{item.quantity}}</td>
                <td class="row-border" id="invoiced-item" style="text-align:right"><span>{{convertNumberToString(item.soldFor)}}</span></td>
                <td class="row-border" id="invoiced-item" style="text-align:right"><span style="text-align:right">{{convertNumberToString(item.grossProfit)}}</span></td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <div style="float:right; font-weight:bold">Total Gross Profit: {{convertNumberToString(selectedInvoice.grossProfit)}}</div>
      </div>
    </div>
  </div>
</div>
    -->
