import { Inject, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'client-create-dialog',
  templateUrl: 'client-create-dialog.component.html',
})
export class ClientCreateDialogComponent implements OnInit {

  title: string;
  client: any;uploadProfiles:any;clients:any;templateClientId:any;

  constructor(public dialogRef: MatDialogRef<ClientCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.client= data.client;
    this.uploadProfiles= data.uploadProfiles;
    this.clients= data.clients;
  }

  ngOnInit() { }

  onConfirm(): void { this.dialogRef.close({client:this.client,templateClientId:this.templateClientId}); }

  onDismiss(): void { this.dialogRef.close(false); }
}
