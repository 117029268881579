<div class="col-md-12 bg-white mb-3 p-3">
  <div class="row">
    <div class="col-md-3">
      <mat-form-field [hidden]="showingDetails" style="" class="searchtab">
        <input matInput (keyup)="applyFilter()" placeholder="Search" [(ngModel)]="searchTerm">
      </mat-form-field>
      <mat-form-field [hidden]="!showingDetails || this.chargebackDetailsIdFilter !== 0" style="" class="searchtab">
        <input matInput (keyup)="applyFilter()" placeholder="Search" [(ngModel)]="searchTerm2">
      </mat-form-field>
    </div>
    <div class="col-md-9 pt-2 text-right">
      <button mat-raised-button [hidden]="showingDetails" (click)="sendListOfImeis(4)" style=" margin-right: 10px" color="primary" class="charge-btn">Reset selected</button>&nbsp;
      <button mat-raised-button [hidden]="!showingDetails" (click)="sendListOfChargebacks()" style=" margin-right: 10px" color="primary" class="dismiss-btn">Update</button>&nbsp;
      <button [hidden]="showingDetails" mat-raised-button (click)="changeTableBeingShown()" style=" margin-right: 10px" color="primary" class="discrep-btn">Details</button>&nbsp;
      <button [hidden]="!showingDetails " mat-raised-button (click)="returnToChargebackMain()" style=" margin-right: 10px" color="primary" class="back-btn">Back</button>&nbsp;
      <button mat-raised-button (click)="exportTableToExcel()" style=" margin-right: 10px" color="primary" class="export-btn">Export</button>&nbsp;
      <button mat-raised-button (click)="exportDetailsTableToExcel()" style=" margin-right: 10px" color="primary">ExportDetails</button>&nbsp;
      <button mat-raised-button (click)="exportSummaryTableToExcel()" style=" margin-right: 10px" color="primary" class="back-btn">ExportSummary</button>

    </div>
  </div>
</div>


<div class="">
  <div class="row m-0">
    <div class="col-md-6 pl-0" style="">
      <div class="table-container m-1">
        <span [hidden]="showingDetails">
          <table mat-table [dataSource]="dataSource" matSort matSortActive="symbol" matSortDirection="asc" class="table table-stripped table-hover small-txt">
            <ng-container matColumnDef="{{column}}" *ngFor="let column of getDisplayedColumns(); let i = index;">
              <span *ngIf="i === 0">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-sort-header-button-custom">
                <mat-checkbox (change)="$event?selectAll($event):null" [checked]="allAreSelected" [disabled]="noData| async" style="display:block; text-align: center;"></mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row" [ngClass]="{'yellow':row?.imei==selectedItem?.imei && row?.mtn == selectedItem?.mtn}">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectImei(row):null" [checked]="row.isSelected"></mat-checkbox>
              </td>
        </span>
        <span *ngIf="(i >= 0)">
          <th class="mat-sort-header-button-custom" mat-header-cell *matHeaderCellDef mat-sort-header [matTooltip]="getColumnDefinationByMappedToProperty(column).name" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip>
            {{getColumnDefinationByMappedToProperty(column).name}}
          </th>
          <ng-container *ngIf="column !== 'commissionIMEINotes' && column !== 'details' && column !== 'warning'  && column !== 'imei'">
            <td mat-cell *matCellDef="let element" [matTooltip]="element[getColumnDefinationByMappedToProperty(column).mappedToProperty]" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip
                [ngClass]="{'yellow':element.imei==selectedItem.imei && element.mtn == selectedItem.mtn}">
              {{getTextBydata(element,getColumnDefinationByMappedToProperty(column))}}
            </td>
          </ng-container>
          <ng-container *ngIf="column === 'commissionIMEINotes'">
            <td mat-cell *matCellDef="let element" [matTooltip]="getTextBydata(element,getColumnDefinationByMappedToProperty(column))" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip
                [ngClass]="{'yellow':element.imei==selectedItem.imei && element.mtn == selectedItem.mtn}"
                (click)="openNotesDialog(column,element)">
              <span><div class="fa fa-sticky-note">{{getTextBydata(element,getColumnDefinationByMappedToProperty(column))}}</div></span>
            </td>
          </ng-container>
          <ng-container *ngIf="column === 'imei'">
            <td mat-cell *matCellDef="let element" [matTooltip]="getTextBydata(element,getColumnDefinationByMappedToProperty(column))"
                [ngClass]="{'yellow':element.imei==selectedItem.imei && element.mtn == selectedItem.mtn}">
              <span *ngIf="getTextBydata(element,getColumnDefinationByMappedToProperty(column)).length > 0" (click)="openHistoryDialog(element)"><a>{{getTextBydata(element,getColumnDefinationByMappedToProperty(column))}}</a></span>
            </td>
          </ng-container>
          <ng-container *ngIf="column === 'details'">
            <td mat-cell *matCellDef="let element;" [matTooltip]="getTextBydata(element,getColumnDefinationByMappedToProperty(column))" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip (click)="changeTableBeingShown(element.id)"
                [ngClass]="{'yellow':element.imei==selectedItem.imei && element.mtn == selectedItem.mtn}">
              <i style="margin-left:30px" class="fa fa-plus"></i>
            </td>
          </ng-container>
          <ng-container *ngIf="column === 'warning'">
            <td mat-cell *matCellDef="let element;" [matTooltip]="element.warning ? 'Chargeback Sum Does not Match Difference Or Requires Assigned Employee' :'No problem detected' " [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip
                [ngClass]="{'yellow':element.imei==selectedItem.imei && element.mtn == selectedItem.mtn}">
              <span *ngIf="element.warning"><mat-icon>warning</mat-icon></span>
            </td>
          </ng-container>
        </span>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
        <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();" (click)="selectItem(row)"></tr>
        </table>
        <div *ngIf="noData | async" class="no-records">
          No records found
        </div>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="10" showFirstLastButtons></mat-paginator>
        </span>

        <span [hidden]="!showingDetails">
          <table #TABLE mat-table [dataSource]="dataSourceDetails" matSort class="table table-bordered table-hover small-txt">
            <ng-container *ngFor="let columnDetails of getDisplayedColumnsDetails(); let i = index;" [matColumnDef]="columnDetails">
              <span *ngIf="i === 0">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="mat-sort-header-button-custom">
                <mat-checkbox (change)="$event?selectAllChargebacks($event.checked):null" [(ngModel)]="allChargebacksAreSelected" [checked]="allChargebacksAreSelected" [disabled]="noData| async"></mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectChargeback(row):null" [checked]="row.isSelected"></mat-checkbox>
              </td>
        </span>
        <span *ngIf="i !== 0 ">
          <th class="mat-sort-header-button-custom" mat-header-cell *matHeaderCellDef mat-sort-header [matTooltip]="getColumnDefinationByMappedToPropertyDetails(columnDetails).name" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip>
            {{getColumnDefinationByMappedToPropertyDetails(columnDetails).name}}
          </th>
          <ng-container *ngIf=" columnDetails !== 'isHidden' && columnDetails !== 'chargeEmployee' && columnDetails !== 'chargebackAmountValue' && columnDetails !== 'commissionIMEINotes'  && columnDetails !== 'imei'">
            <td mat-cell *matCellDef="let element; let z = index;" [matTooltip]="getTextByDataDetails(element,getColumnDefinationByMappedToPropertyDetails(columnDetails))" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip>
              <span>{{getTextByDataDetails(element,getColumnDefinationByMappedToPropertyDetails(columnDetails))}}</span>
            </td>
          </ng-container>
        </span>

        <ng-container *ngIf="columnDetails === 'chargeEmployee'">
          <td mat-cell *matCellDef="let element" [matTooltip]="element[getColumnDefinationByMappedToPropertyDetails(columnDetails).mappedToProperty]" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip>
            <mat-select class="chargebacks-mat-form-field-type-mat-select" [(ngModel)]="element.chargeEmployee" [(value)]="element.chargeEmployee">
              <input matInput style="margin-left:25px; min-height:50px" placeholder="filter.." #filteremp>
              <mat-option *ngFor="let user of employeeList" [value]="user.user.name" [hidden]="!user.user.name.toLowerCase().includes(filteremp.value.toLowerCase())">{{user.user.name}}</mat-option>
            </mat-select>
          </td>
        </ng-container>
        <ng-container *ngIf="columnDetails === 'chargebackAmountValue'">

          <td mat-cell *matCellDef="let element" [matTooltip]="element[getColumnDefinationByMappedToPropertyDetails(columnDetails).mappedToProperty]" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip>
            <input matInput step=".01" style="  width:60px;  border: 2px solid black; box-sizing: border-box;"
                   type="number" placeholder="0.00" [value]="element.chargebackAmountValue" [(ngModel)]="element.chargebackAmountValue"
                   (change)="element.chargebackAmountValue = element.chargebackAmountValue.toFixed(2);">
          </td>
        </ng-container>


        <ng-container *ngIf="columnDetails === 'commissionIMEINotes'">
          <td mat-cell *matCellDef="let element" [matTooltip]="getTextByDataDetails(element,getColumnDefinationByMappedToPropertyDetails(columnDetails))" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip
              (click)="openNotesDialog('chargebackDetail',element)">
            <span><div class="fa fa-sticky-note">{{getTextByDataDetails(element,getColumnDefinationByMappedToPropertyDetails(columnDetails))}}</div></span>
          </td>
        </ng-container>

        <ng-container *ngIf="columnDetails === 'imei'">
          <td mat-cell *matCellDef="let element" [matTooltip]="getTextByDataDetails(element,getColumnDefinationByMappedToPropertyDetails(columnDetails))" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip>
            <span *ngIf="getTextByDataDetails(element,getColumnDefinationByMappedToPropertyDetails(columnDetails)).length > 0" (click)="openHistoryDialog(element)"><a>{{getTextByDataDetails(element,getColumnDefinationByMappedToPropertyDetails(columnDetails))}}</a></span>
          </td>
        </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="getDisplayedColumnsDetails()"></tr>
        <tr mat-row *matRowDef="let row; columns: getDisplayedColumnsDetails(); ">
        </tr>
        </table>
        <div *ngIf="noData | async" class="no-records">No records found</div>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="10" showFirstLastButtons></mat-paginator>
        </span>
      </div>
    </div>
    <div class="col-md-6 pr-0">
      <div class="table-container m-1">
        <mat-divider [vertical]="true" *ngIf="selectedImei" class="margin-left-1em"></mat-divider>
        <div class="column margin-left-1em rq-vz-data-table" *ngIf="selectedImei && !showingDetails">
          <rq-vz-data-table [selectedImei]="selectedImei" *ngIf="selectedImei"></rq-vz-data-table>
        </div>
      </div>
    </div>
  </div>
</div>
