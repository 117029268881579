import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FormulaViewGroup } from '../models/formula-view-group';
import { NotifierService } from 'angular-notifier';

@Injectable({
  providedIn: 'root'
})
export class FormulaViewGroupService {
  private _baseUrl = '/api/formulaviewgroups';
  private formulaViewGroups: FormulaViewGroup[] = [];

  constructor(private http: HttpClient,
    private _notifier: NotifierService) {}

  getAllFormulaViewGroups(): Observable<FormulaViewGroup[]> {
    return this.http.get<FormulaViewGroup[]>(this._baseUrl);
  }

  getFormulaViewGroups(clientId: number, callback: any) {
      this.getAllFormulaViewGroups().subscribe((response: FormulaViewGroup[]) => {
        this.formulaViewGroups = response.filter(x => x.clientId === clientId);
        callback(this.formulaViewGroups);
      }, (error) => { this._notifier.notify('error', error.message); }, () => { });
  }

  createFormulaViewGroup(name: string, isSelected: boolean, clientId: number): Observable<any> {
    const formulaViewGroup = {
      name,
      isSelected,
      clientId
    };

    return this.http.post(this._baseUrl, formulaViewGroup);
  }

  updateFormulaViewGroup(id: string, name: string, isSelected: boolean): Observable<any> {
    const formulaViewGroup = {
      name,
      isSelected
    };

    return this.http.put(this._baseUrl + '/' + id, formulaViewGroup);
  }

  deleteFormulaViewGroup(id: string): Observable<any> {
    return this.http.delete(this._baseUrl + '/' + id);
  }
}
