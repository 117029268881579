import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
@Injectable()
export class LoaderService {
  private timeoutId: any;


  constructor(private _notifier: NotifierService) {
  }

  debug = false;
  isLoading = new Subject<boolean>();
  isOpen = false;
  show() {

    if (this.debug) console.log("The Actual Show is running");
    this.isLoading.next(true);
    this.isOpen = true;
    this.timeoutId = setTimeout(() => {
      if (this.isOpen) {
        this.isOpen = false;
        if (this.debug) console.log("A timer is closing it");
        //this._notifier.notify('error', "Something went wrong");
      }
      this.isLoading.next(false);
    }, 45000)
    if (this.debug) console.log(this.timeoutId + " Timer was started");
  }
  hide() {
    if (this.debug) console.log("The Actual Hide is running");
    this.isLoading.next(false);
    this.isOpen = false;
    if (this.timeoutId) {
      if (this.debug) console.log(this.timeoutId + " Timer was stopped");
      clearTimeout(this.timeoutId);
      this.timeoutId = null; // Reset the timeout ID
    }
  }
}
