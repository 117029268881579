import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableFilter'
})
export class TableFilterPipe implements PipeTransform {

  transform(list: any[], filters: any) {
    if (filters.isSelected) {
      delete filters.isSelected;
    }
    const keys = Object.keys(filters).filter(key => filters[key]);
    const filterUser = imei => keys.every(key => imei[key].toString().toLowerCase().includes(filters[key].toLowerCase()));

    return keys.length ? list.filter(filterUser) : list;
  }

}
