import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImportComponent } from './import/import.component';
import { AuthGuardService as AuthGaurd } from '../core/services/auth-gaurd.service';
import { ClientComponent } from './client/client.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardSingleComponent } from './dashboard-single/dashboard-single.component';
import { RankContestComponent } from './rank-contest/rank-contest.component';
import { MtdReportComponent } from './mtd-report/mtd-report.component';
import { FormulaComponent } from './formula/formula.component';
import { ColumnContentLayoutComponent } from '../core/column-content-layout/column-content-layout.component';
import { SkuGroupComponent } from './sku-group/sku-group.component';
import { FormulaViewGroupComponent } from './formula-view-group/formula-view-group.component';
import { CommissionUserComponent } from './commission-user/commission-user.component';
import { ForecastComponent } from './forecast/forecast.component';
import { InventoryComponent } from './inventory/inventory.component';
import { InventorySunburstComponent } from './inventory-sunburst/inventory-sunburst.component';
import { InvoiceViewerComponent } from './invoice-viewer/invoice-viewer.component';
import { CommissionReconcileComponent } from './commission-reconcile/commission-reconcile.component';
import { CCRSReconcileComponent } from './ccrs-reconcile/ccrs-reconcile.component';
import { DatascapeReconcileComponent } from './datascape-reconcile/datascape-reconcile.component';
import { UploadConfigComponent } from './upload-config/upload-config.component';
import { PerformanceSummaryComponent } from './performance-summary/performance-summary.component';
import { RoleManagerComponent } from './users/role-manager/role-manager.component';
import { LocationManagerComponent } from './users/location-manager/location-manager.component';
import { UserLookupComponent } from './users/user-lookup/user-lookup.component';
import { TableFilterPipe } from '../tools/table-filter.pipe';
import { RewardsCreateComponent } from './rewards-create/rewards-create.component';
import { RewardsDashboardComponent } from './rewards-dashboard/rewards-dashboard.component';
import { RewardsClaimManagementComponent } from './rewards-claim-management/rewards-claim-management.component';
import { ShipmentReconciliationComponent } from './shipment-reconciliation/shipment-reconciliation.component';
import { MonthlyShipmentReconciliationComponent } from './monthly-shipment-reconciliation/monthly-shipment-reconciliation.component';
//import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb';
import { SpiffsDataTableComponent } from './commission-reconcile/spiffs-data-table/spiffs-data-table.component';
import { ChargebacksDataTableComponent } from './commission-reconcile/chargebacks-data-table/chargebacks-data-table.component';
import { DismissedDataTableComponent } from './commission-reconcile/dismissed-data-table/dismissed-data-table.component';
import { DiscrepanciesAllDataTableComponent } from './commission-reconcile/discrepancies-all-data-table/discrepancies-all-data-table.component';
import { AllDataTableComponent } from './commission-reconcile/all-data-table/all-data-table.component';
import { ChargedNoInputDataTableComponent } from './commission-reconcile/charged-no-input-data-table/charged-no-input-data-table.component';
import { DiscrepanciesDataTableComponent } from './commission-reconcile/discrepancies-data-table/discrepancies-data-table.component';
import { UploadStatusComponent } from './upload-status/upload-status.component';
import { RqVzDataTableComponent } from './rq-vz-data-table/rq-vz-data-table.component';
import { NotesDialogComponent } from './dialogs/notes/notes-dialog.component';
import { ProductSearchDialogComponent } from './dialogs/product-search/product-search-dialog.component'
import { CopyCommissionDialogComponent } from './commission-user/copy-commission-dialog-component';
import { WelcomeComponent } from "./welcome/welcome.component";
import { ErrorComponent } from './shared/error/error.component';
import { ConfirmDialogComponent } from './dialogs/confirm/confirm-dialog.component';
import { FinalizeDialogComponent } from './rewards-create/finalize-dialog.component';
import { FinalizeClaimComponent } from "./rewards-claim-management/finalize-claim.component";
import { DeleteClaimComponent } from "./rewards-claim-management/delete-claim.component";
import { ManualRewardComponent } from "./rewards-claim-management/manual-reward.component";
import { RewardsCreateDialogComponent } from "./rewards-create/rewards-create-dialog.component";
import { FormulaCreateDialogComponent } from "./formula/formula-create-dialog.component";
import { ClientCreateDialogComponent } from "./client/client-create-dialog.component";
import { UserCreateDialogComponent } from './users/user-lookup/user-create-dialog.component';

const routes: Routes = [
  {
    path: '',
    component: ColumnContentLayoutComponent,
    children: [

      {
        path: 'welcome', component: WelcomeComponent, canActivate: [AuthGaurd], data: {
          title: 'Welcome',
          breadcrumb: [
            {
              label: 'Welcome ',
              url: ''
            }
          ]
        },
      },
      {
        path: 'clients', component: ClientComponent, canActivate: [AuthGaurd], data: {
          title: 'Clients',
          breadcrumb: [
            {
              label: 'Super Admin ',
              url: ''
            },
            {
              label: 'Clients',
              url: ''
            }
          ]
        },
      },
      {
        path: 'import', component: ImportComponent, canActivate: [AuthGaurd], data: {
          title: 'Import',
          breadcrumb: [
            {
              label: 'Admin',
              url: ''
            },
            {
              label: 'Import',
              url: ''
            }
          ]
        },
      },
      {
        path: 'dashboard', component: DashboardComponent, canActivate: [AuthGaurd], data: {
          title: 'Dashboard',
          breadcrumb: [
            {
              label: 'Sales',
              url: ''
            },
            {
              label: 'Dashboard',
              url: ''
            }
          ]
        },
      },
      {
        path: 'dashboard-single', component: DashboardSingleComponent, canActivate: [AuthGaurd], data: {
          title: 'LineChart',
          breadcrumb: [
            {
              label: 'Sales',
              url: ''
            },
            {
              label: 'LineChart',
              url: ''
            }
          ]
        },
      },
      {
        path: 'rank-contest', component: RankContestComponent, canActivate: [AuthGaurd], data: {
          title: 'Rank',
          breadcrumb: [
            {
              label: 'Sales',
              url: ''
            },
            {
              label: 'Rank',
              url: ''
            }
          ]
        },
      },
      {
        path: 'mtd-report', component: MtdReportComponent, canActivate: [AuthGaurd], data: {
          title: 'MTD Report',
          breadcrumb: [
            {
              label: 'Sales',
              url: ''
            },
            {
              label: 'Month To Date',
              url: ''
            }
          ]
        },
      },
      {
        path: 'formulas', component: FormulaComponent, canActivate: [AuthGaurd], data: {
          title: 'Formulas',
          breadcrumb: [
            {
              label: 'Admin',
              url: ''
            },
            {
              label: 'Formulas',
              url: ''
            }
          ]
        },
      },
      {
        path: 'sku-group', component: SkuGroupComponent, canActivate: [AuthGaurd], data: {
          title: 'SKU Group',
          breadcrumb: [
            {
              label: 'Admin',
              url: ''
            },
            {
              label: 'SKU Group',
              url: ''
            }
          ]
        },
      },
      {
        path: 'formula-view-group', component: FormulaViewGroupComponent, canActivate: [AuthGaurd], data: {
          title: 'Formular View Group',
          breadcrumb: [
            {
              label: 'Admin',
              url: ''
            },
            {
              label: 'Formular View Group',
              url: ''
            }
          ]
        },
      },
      {
        path: 'commission-user', component: CommissionUserComponent, canActivate: [AuthGaurd], data: {
          title: 'Commission Users',
          breadcrumb: [
            {
              label: 'Admin',
              url: ''
            },
            {
              label: 'Commission Users',
              url: ''
            }
          ]
        },
      },
      {
        path: 'forecast', component: ForecastComponent, canActivate: [AuthGaurd], data: {
          title: 'Forecast',
          breadcrumb: [
            {
              label: 'Employees',
              url: ''
            },
            {
              label: 'Employee Commissions',
              url: ''
            }
          ]
        },
      },
      {
        path: 'performance-summary', component: PerformanceSummaryComponent, canActivate: [AuthGaurd], data: {
          title: 'Performance Summary',
          breadcrumb: [
            {
              label: 'Employees',
              url: ''
            },
            {
              label: 'Performance Summary',
              url: ''
            }
          ]
        },
      },
      {
        path: 'inventory', component: InventoryComponent, canActivate: [AuthGaurd], data: {
          title: 'Inventory',
          breadcrumb: [
            {
              label: 'Inventory',
              url: ''
            },
            {
              label: 'Inventory',
              url: ''
            }
          ]
        },
      },
      {
        path: 'invoice-viewer', component: InvoiceViewerComponent, canActivate: [AuthGaurd], data: {
          title: 'Invoice Viewer',
          breadcrumb: [
            {
              label: 'Sales ',
              url: ''
            },
            {
              label: 'Invoices',
              url: ''
            }
          ]
        },
      },
      {
        path: 'commission-reconcile', component: CommissionReconcileComponent, canActivate: [AuthGaurd], data: {
          title: 'Monthly Commission',
          breadcrumb: [
            {
              label: 'Admin',
              url: ''
            },
            {
              label: 'Monthly Commission',
              url: ''
            }
          ]
        },
      },
      {
        path: 'ccrs-reconcile', component: CCRSReconcileComponent, canActivate: [AuthGaurd], data: {
          title: 'Daily commissions',
          breadcrumb: [
            {
              label: 'Admin',
              url: ''
            },
            {
              label: 'Daily commissions',
              url: ''
            }
          ]
        },
      },
      {
        path: 'datascape-reconcile', component: DatascapeReconcileComponent, canActivate: [AuthGaurd], data: {
          title: 'Bill payments',
          breadcrumb: [
            {
              label: 'Admin',
              url: ''
            },
            {
              label: 'Bill payments',
              url: ''
            }
          ]
        },
      },
      {
        path: 'upload-config', component: UploadConfigComponent, canActivate: [AuthGaurd], data: {
          title: 'Upload Config',
          breadcrumb: [
            {
              label: 'Super Admin',
              url: ''
            },
            {
              label: 'Config Import',
              url: ''
            }
          ]
        },
      },
      {
        path: 'role-manager', component: RoleManagerComponent, canActivate: [AuthGaurd], data: {
          title: 'Role Manager',
          breadcrumb: [
            {
              label: 'Admin',
              url: ''
            },
            {
              label: 'Role Manager',
              url: ''
            }
          ]
        },
      },
      {
        path: 'location-manager', component: LocationManagerComponent, canActivate: [AuthGaurd], data: {
          title: 'Location Manager',
          breadcrumb: [
            {
              label: 'Admin',
              url: ''
            },
            {
              label: 'Location Manager',
              url: ''
            }
          ]
        },
      },
      {
        path: 'inventory-sunburst', component: InventorySunburstComponent, canActivate: [AuthGaurd], data: {
          title: 'Inventory Sunburst',
          breadcrumb: [
            {
              label: 'Inventory',
              url: ''
            },
            {
              label: 'Inventory',
              url: ''
            }
          ]
        },
      },
      {
        path: 'shipment-reconciliation', component: ShipmentReconciliationComponent, canActivate: [AuthGaurd], data: {
          title: 'Shipments',
          breadcrumb: [
            {
              label: 'Admin',
              url: ''
            },
            {
              label: 'Shipments',
              url: ''
            }
          ]
        },
      },
      {
        path: 'monthly-shipment-reconciliation', component: MonthlyShipmentReconciliationComponent, canActivate: [AuthGaurd], data: {
          title: 'MonthlyShipments',
          breadcrumb: [
            {
              label: 'Admin',
              url: ''
            },
            {
              label: 'MonthlyShipments',
              url: ''
            }
          ]
        },
      },
      {
        path: 'user-lookup', component: UserLookupComponent, canActivate: [AuthGaurd], data: {
          title: 'User Lookup',
          breadcrumb: [
            {
              label: 'Admin',
              url: ''
            },
            {
              label: 'User Lookup',
              url: ''
            }
          ]
        },
      },
      {
        path: 'upload-status', component: UploadStatusComponent, canActivate: [AuthGaurd], data: {
          title: 'Upload Status',
          breadcrumb: [
            {
              label: 'Super Admin',
              url: ''
            },
            {
              label: 'Upload Status',
              url: ''
            }
          ]
        },
      },
      {
        path: 'rewards-create', component: RewardsCreateComponent, canActivate: [AuthGaurd], data: {
          title: 'Reward Game Manager',
          breadcrumb: [
            {
              label: 'Admin',
              url: ''
            },
            {
              label: 'Reward Game Manager',
              url: ''
            }
          ]
        },
      },
      {
        path: 'rewards-dashboard', component: RewardsDashboardComponent, canActivate: [AuthGaurd], data: {
          title: 'RewardsDashboard',
          breadcrumb: [
            {
              label: 'Employees',
              url: ''
            },
            {
              label: 'Rewards Dashboard',
              url: ''
            }
          ]
        },
      },
      {
        path: 'rewards-claim-management', component: RewardsClaimManagementComponent, canActivate: [AuthGaurd], data: {
          title: 'Reward Reconciliation',
          breadcrumb: [
            {
              label: 'Admin',
              url: ''
            },
            {
              label: 'Reward Reconciliation',
              url: ''
            }
          ]
        },
      },
      {
        path: 'error', component: ErrorComponent, canActivate: [AuthGaurd], data: {
          title: 'Error',
          breadcrumb: [
            {
              label: 'Error ',
              url: ''
            }
          ]
        },
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes),
   // NgDynamicBreadcrumbModule
  ],
  declarations: [TableFilterPipe],
  exports: [RouterModule, TableFilterPipe]
})
export class HomeRoutingModule {
  static components = [
    WelcomeComponent,
    ClientComponent,
    ImportComponent,
    DashboardComponent,
    DashboardSingleComponent,
    RankContestComponent,
    MtdReportComponent,
    SkuGroupComponent,
    FormulaComponent,
    FormulaViewGroupComponent,
    CommissionUserComponent,
    ForecastComponent,
    InventoryComponent,
    InventorySunburstComponent,
    InvoiceViewerComponent,
    CommissionReconcileComponent,
    AllDataTableComponent,
    DiscrepanciesAllDataTableComponent,
    SpiffsDataTableComponent,
    ChargebacksDataTableComponent,
    DismissedDataTableComponent,
    DiscrepanciesDataTableComponent,
    ChargedNoInputDataTableComponent,
    RqVzDataTableComponent,
    CCRSReconcileComponent,
    DatascapeReconcileComponent,
    UploadConfigComponent,
    PerformanceSummaryComponent,
    RoleManagerComponent,
    LocationManagerComponent,
    UserLookupComponent,
    ShipmentReconciliationComponent,
    MonthlyShipmentReconciliationComponent,
    UploadStatusComponent,
    NotesDialogComponent,
    ProductSearchDialogComponent,
    ConfirmDialogComponent,
    FinalizeDialogComponent,
    FinalizeClaimComponent,
    ManualRewardComponent,
    RewardsCreateDialogComponent,
    DeleteClaimComponent,
    FormulaCreateDialogComponent,
    CopyCommissionDialogComponent,
    RewardsCreateComponent,
    RewardsDashboardComponent,
    RewardsClaimManagementComponent,
    ClientCreateDialogComponent,
    UserCreateDialogComponent
  ];
}
