import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  private _baseUrl = '/api/inventory';
  inventory: any[] = [];

  constructor(private http: HttpClient) {}

  getInventory(district: number, category: number): Observable<any[]> {
    return this.http.get<any[]>(this._baseUrl + '/' + district + '/' + category);
  }

  getTreeWithCostValuesForStore(): Observable<any> {


    return this.http.get(this._baseUrl + '/' + 1 + '/getTree');

  }
}
