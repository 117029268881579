import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SkuFilter } from '../models/sku-filter';

@Injectable({
  providedIn: 'root'
})
export class SkuFilterService {
  private _baseUrl = '/api/skufilters';

  constructor(private http: HttpClient) { }

  getSkuFilters(skuGroupId: string): Observable<SkuFilter[]> {
    return this.http.get<SkuFilter[]>(this._baseUrl + '/' + skuGroupId);
  }

  createSkuFilter(skuGroupId: number, name: string, value: string, skuFilterTypeId: number): Observable<any> {
    const skuFilter = {
      name,
      value,
      skuGroupId,
      skuFilterTypeId
    };

    return this.http.post(this._baseUrl, skuFilter);
  }

  updateSkuFilter(id: number, skuGroupId: number, name: string, value: string, skuFilterTypeId: number): Observable<any> {
    const skuFilter = {
      name,
      value,
      skuGroupId,
      skuFilterTypeId
    };

    return this.http.put(this._baseUrl + '/' + id, skuFilter);
  }

  deleteSkuFilter(id: string): Observable<any> {
    return this.http.delete(this._baseUrl + '/' + id);
  }
}
