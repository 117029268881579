import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { NotifierService } from 'angular-notifier';
import { Location } from '../models/location';
import { ClientService } from './client.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private _baseUrl = '/api/locations';

  private locationSource = new BehaviorSubject(null);
  private employeeSource = new BehaviorSubject(null);
  private storeSource = new BehaviorSubject(null);
  private districtSource = new BehaviorSubject(null);
  private regionSource = new BehaviorSubject(null);
  private clientSource = new BehaviorSubject(null);
  private actualClientSource = new BehaviorSubject(null);

  currentLocations: Observable<any>;
  currentEmployee: Observable<any>;
  currentStore: Observable<any>;
  currentDistrict: Observable<any>;
  currentRegion: Observable<any>;
  currentClient: Observable<any>;
  currentActualClient: Observable<any>;


  selectedStoreId;
  selectedDistrictId;
  selectedRegionId;

  isDestroyed = false;

  locations: Location[] = [];
  debug = false;
  constructor(private http: HttpClient,
    private _notifier: NotifierService,
    private _clientService: ClientService
  ) {
    this.currentLocations = this.locationSource.asObservable();
    this.currentEmployee = this.employeeSource.asObservable();
    this.currentStore = this.storeSource.asObservable();
    this.currentDistrict = this.districtSource.asObservable();
    this.currentRegion = this.regionSource.asObservable();
    this.currentClient = this.clientSource.asObservable();
    this.currentActualClient = this.actualClientSource.asObservable();
  }
  changeLocations(message: number[]) {
    if (message != null) {
      if (this.debug) { console.log('Broadcasting Location Change: ' + message); }
      this.locationSource.next(message);
    }
  }

  changeEmployee(message: number) {
    if (message != null) {
      if (this.debug) { console.log('Broadcasting Employee Change: ' + message); }
      this.employeeSource.next(message);
    }
  }
  changeStore(message: number) {
    if (this.debug) { console.log('Broadcasting Store Change: ' + message); }
    if (message != null) {
      this.selectedStoreId = message;
      this.storeSource.next(message);
    }
  }
  changeDistrict(message: number) {
    if (this.debug) { console.log('Broadcasting District Change: ' + message); }
    if (message != null) {
      this.selectedDistrictId = message;
      this.districtSource.next(message);
    }
  }
  changeRegion(message: number) {
    if (this.debug) { console.log('Broadcasting Region Change: ' + message); }
    if (message != null) {
      this.selectedRegionId = message;
      this.regionSource.next(message);
    }
  }
  changeClient(message: number) {
    if (this.debug) { console.log('Broadcasting Client Change: ' + message); }
    if (message != null) {
      this.clientSource.next(message);
      this.actualClientSource.next(this.locations.find(x => x.id === message).clientId);
    }
  }

  getAllLocations(): Observable<Location[]> {
    return this.http.get<Location[]>(this._baseUrl);
  }
  getLocationTree(clientid, tierid): Observable<Location[]> {
    return this.http.get<Location[]>(this._baseUrl + '/' + clientid + '/' + tierid);
  }

  getLocations(locationTypeCode: number, callback: any, parentId?: number) {
    if (this.locations.length === 0) {
      this.getAllLocations().subscribe((response: Location[]) => {
        this.locations = response.sort((a, b) => a.name.localeCompare(b.name));
        const result = (parentId) ? this.filterLocationsByTypeAndParent(locationTypeCode, parentId) :
          this.filterLocationsByType(locationTypeCode);
        callback(result);
      }, (error) => { this._notifier.notify('error', error.message); }, () => { });
    } else {
      const result = (parentId) ? this.filterLocationsByTypeAndParent(locationTypeCode, parentId) :
        this.filterLocationsByType(locationTypeCode);
      callback(result);
    }
  }



  getLocationsByTypeAndClientId(locationTypeCode: number, callback: any, clientId: number) {
    if (this.locations.length === 0) {
      this.getAllLocations().subscribe((response: Location[]) => {
        this.locations = response.sort((a, b) => a.name.localeCompare(b.name));
        callback(this.filterLocationsByTypeAndClient(locationTypeCode, clientId));
      }, (error) => { this._notifier.notify('error', error.message); }, () => { });
    } else {
      callback(this.filterLocationsByTypeAndClient(locationTypeCode, clientId));
    }
  }
  getLocationsByClientIdPromise(clientId: number): Promise<Location[]> {

    // return new Promise((resolve, reject) => {
    if (this.locations.length === 0) {
      this.getAllLocations().subscribe((response: Location[]) => {
        this.locations = response.sort((a, b) => a.name.localeCompare(b.name));
        // return this.filterLocationsByClient(clientId);
        return new Promise(resolve => {
          resolve(this.locations.filter(location =>
            location.clientId === clientId));
          // resolve(this.filterLocationsByClient(clientId));
        });
        // resolve();
        // callback(this.filterLocationsByClient(clientId));
      }, (error) => { this._notifier.notify('error', error.message); }, () => { });
    } else {
      return new Promise(resolve => {
        resolve(this.filterLocationsByClient(clientId));
      });
      // callback(this.filterLocationsByClient(clientId));
    }

    // });

  }




  getLocationsByClientId(clientId: number, callback: any) {
    // if (this.locations.length === 0) {
    this.getAllLocations().subscribe(async (response: Location[]) => {
      this.locations = response.sort((a, b) => a.name.localeCompare(b.name));
      const filtered = await this.filterLocationsByClient(clientId);
      callback(filtered);
      // callback( this.filterLocationsByClient(clientId));
    }, (error) => { this._notifier.notify('error', error.message); }, () => { });
    //  } else {
    //  callback(this.filterLocationsByClient(clientId));
    // }
  }
  getLocationsByTypeAndParents(locationTypeCode: number, callback: any, parents: number[] = []) {
    if (this.locations.length === 0) {
      this.getAllLocations().subscribe((response: Location[]) => {
        this.locations = response.sort((a, b) => a.name.localeCompare(b.name));
        const result = (parents.length > 0) ? this.filterLocationsByTypeAndParents(locationTypeCode, parents) :
          this.filterLocationsByType(locationTypeCode);
        callback(result);
      }, (error) => { this._notifier.notify('error', error.message); }, () => { });
    } else {
      const result = (parents.length > 0) ? this.filterLocationsByTypeAndParents(locationTypeCode, parents) :
        this.filterLocationsByType(locationTypeCode);
      callback(result);
    }
  }

  filterLocationsByTypeAndClient(locationTypeCode: number, clientId: number) {
    return this.locations.filter(location =>
      location.locationTypeCodeId === locationTypeCode && location.clientId === clientId);
    /*.map(function (location) {
      return {
        'id': location.id,
        'name': location.name,
        'clientId': location.clientId
      };
    });*/
  }

  async filterLocationsByClient(clientId: number) {
    const resp = this.locations.filter(location =>
      location.clientId === clientId);
    if (!resp) {
    }
    return await resp;
    // .map(function (location) {
    // return {
    // 'id': location.id,
    // 'name': location.name,
    // 'clientId': location.clientId
    // };
    // });
  }

  filterLocationsByTypeAndParent(locationTypeCode: number, parentId?: number) {
    return this.locations.filter(location =>
      location.locationTypeCodeId === locationTypeCode && location.parentId === parentId);
    /* .map(function (location) {
      return {
        'id': location.id,
        'name': location.name,
        'clientId': location.clientId
      };
    }); */
  }
  filterLocationIdsByTypeAndParent(locationTypeCode: number, parentId?: number) {
    return this.locations.filter(location =>
      location.locationTypeCodeId === locationTypeCode && location.parentId === parentId)
      .map(function (location) {
        return location.id;
      });
  }

  filterLocationsByTypeAndParents(locationTypeCode: number, parents: number[]) {
    return this.locations.filter(location =>
      location.locationTypeCodeId === locationTypeCode && parents.includes(location.parentId));
    /*.map(function (location) {
      return {
        'id': location.id,
        'name': location.name,
        'clientId': location.clientId
      };
    });*/
  }

  filterLocationsByType(locationTypeCode: number) {
    return this.locations.filter(location =>
      location.locationTypeCodeId === locationTypeCode);
    /*.map(function (location) {
      return {
        'id': location.id,
        'name': location.name,
        'clientId': location.clientId
      };
    });*/
  }

  getLocationNameById(id: number): string {
    return this.locations.find(location => location.id === id).name;
  }

  getLocationIdByName(name: string): number {
    return this.locations.find(location => location.name === name).id;
  }


  getListOfStores() {
    if (this.selectedStoreId !== 0) {
      return [this.selectedStoreId];
    }
    else if (this.selectedDistrictId !== 0) {
      return this.locations.filter(x => x.parentId == this.selectedDistrictId).map(x => x.id);
    }
    else if (this.selectedRegionId !== 0) {
      let districts = this.locations.filter(x => x.parentId == this.selectedRegionId).map(x => x.id);
      return this.locations.filter(x => districts.includes(x.parentId)).map(x => x.id);
    }
  }

}
