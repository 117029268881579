<header class="section-header">
  <div class="tbl">
    <div class="tbl-row">
      <div class="tbl-cell">
        <h3>Users</h3>

      </div>
    </div>
  </div>
</header>
<div class="box-typical box-typical-padding">
  <div class="table-container">
    
    <div class="row" *ngIf="isSearchVisible">
      <div class="col-sm-12">
        <h3>Search Users</h3>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label for="searchUserClientId">Client</label>
          <select class="form-control" [(ngModel)]="searchModel.clientId" name="searchUserClientId" (change)="onClientSelected();">
            <option *ngFor="let client of clients" [value]="client.id">{{client.name}}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="searchUserRegionId">Region</label>
          <select class="form-control" [(ngModel)]="searchModel.regionId" name="searchUserRegionId" (change)="onRegionSelected();">
            <option [value]="0">Select Region</option>
            <option [value]="999999">Users Without Locations</option>
            <option *ngFor="let region of regions" [value]="region.id">{{region.name}}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="searchUserDistrictId">District</label>
          <select class="form-control" [(ngModel)]="searchModel.districtId" name="searchUserDistrictId" (change)="onDistrictSelected();">
            <option [value]="0">Select District</option>
            <option *ngFor="let district of districts" [value]="district.id">{{district.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label for="searchUserStoreId">Store</label>
          <select class="form-control" [(ngModel)]="searchModel.storeId" name="searchUserStoreId" (change)="onStoreSelected();">
            <option [value]="0">Select Store</option>
            <option *ngFor="let store of stores" [value]="store.id">{{store.name}}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="searchUserRoleId">Role</label>
          <select class="form-control" [(ngModel)]="searchModel.roleId" name="searchUserRoleId">
            <option [value]="0">Select Role</option>
            <option *ngFor="let role of roles" [value]="role.id">{{role.name}}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="searchUserFullName">Name</label>
          <input type="text" class="form-control" [(ngModel)]="searchModel.name" name="searchUserFullName" placeholder="Name">
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label for="searchUserName">Username</label>
          <input type="email" class="form-control" [(ngModel)]="searchModel.userName" name="searchUserName" placeholder="Username">
        </div>
        <div class="form-group">
          <label for="searchUserStatus">Status</label>
          <select class="form-control" [(ngModel)]="searchModel.status" name="searchUserStatus" (change)="onStatusSelected();">
            <option [value]="0">Select Status</option>
            <option *ngFor="let userStatusCode of userStatusCodes" [value]="userStatusCode.id">{{userStatusCode.name}}</option>
          </select>
        </div>
        <div class="form-group">
          <label>&nbsp;</label>
          <button class="btn btn-primary mr-2" (click)="searchUsers()">Search User</button>
          <button class="btn btn-primary" (click)="cancelSearch()">Cancel</button>
        </div>
      </div>
    </div>
    <div class="row table-header" *ngIf="!isSearchVisible">
      <div class="col-sm-3">

        <div class="form-group">
          <label for="searchUserFullName">Name</label>
          <input type="text" class="form-control" [(ngModel)]="searchModel.name" name="searchUserFullName" placeholder="Name">
        </div>
        <div class="form-group">
          <label for="searchUserName">Username</label>
          <input type="email" class="form-control" [(ngModel)]="searchModel.userName" name="searchUserName" placeholder="Username">
        </div>
      </div>
      <div class="col-sm-4">
        
        <div class="form-group">
          <label for="searchUserRoleId">Role</label>
          <select class="form-control" [(ngModel)]="searchModel.roleId" name="searchUserRoleId">
            <option [ngValue]="undefined">Select Role</option>
            <option *ngFor="let role of roles" [value]="role.id">{{role.name}}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="searchUserStatus">Status</label>
          <select class="form-control" [(ngModel)]="searchModel.status" name="searchUserStatus" (change)="onStatusSelected();">
            <option [value]="0">Select Status</option>
            <option *ngFor="let userStatusCode of userStatusCodes" [value]="userStatusCode.id">{{userStatusCode.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-sm-3 text-right">
        <!--<button class="btn btn-primary mr-2" (click)="showSearchUser()">Search User</button>-->

        <button class="btn btn-primary mr-2" (click)="searchUsers(false)">Search Users</button><br/><br />
        <button class="btn btn-primary mr-2" (click)="searchUsers(true)">Search Special Users</button><br/><br />
        <button class="btn btn-primary mr-2" (click)="addUser()">Add User</button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th *ngFor="let col of userAttributes" (click)="sorting(col.name, col.ascending)" class="sort-column">
                {{col.header}}
                <span *ngIf="col.sorting">
                  <i class="fa fa-caret-up" [ngClass]="{'grey': !col.ascending, 'black': col.ascending}"></i>
                  <i class="fa fa-caret-down" [ngClass]="{'grey': col.ascending, 'black': !col.ascending}"></i>
                </span>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody *ngIf="!loadingInProgress">
            <tr *ngFor="let user of users; let i=index" role="row">
              <td>{{i+1}}</td>
              <td>{{user.name}}</td>
              <td>{{user.userName}}</td>
              <td>{{user.client.name}}</td>
              <td>{{user.userStatusCode.name}}</td>
              <td>{{(user.userRoles.length > 0) ? user.userRoles[0].role.name : ''}}</td>
              <td>
                <a (click)="editUser(user)" class="icon-btn"><i class="fa fa-edit"></i></a>
                <a (click)="deleteUser(user)" class="icon-btn"><i class="fa fa-trash"></i></a>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="loadingInProgress">
            <tr role="row">
              <td><span class="loading-span"></span></td>
              <td><span class="loading-span"></span></td>
              <td><span class="loading-span"></span></td>
              <td><span class="loading-span"></span></td>
              <td><span class="loading-span"></span></td>
              <td><span class="loading-span"></span></td>
              <td><span class="loading-span"></span></td>
            </tr>
          </tbody>
          <tbody *ngIf="noResultFound">
            <tr role="row">
              <td colspan="7">No results found</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="dialog" style="height : 100%" [hidden]="!dialog.isOpen">
    <header>
      <span *ngIf="dialog.headerText">{{dialog.headerText}}</span>
      <a *ngIf="dialog.cancelButton" class="cancel-btn" (click)="dialog.cancel()"><i class="fa fa-times"></i></a>
    </header>
    <section>
      <form *ngIf="isCreateUser" #f="ngForm" (ngSubmit)="f.form.valid && createUser()" novalidate>
        <div class="form-group row">
          <label class="col-sm-4 form-control-label">Name</label>
          <div class="col-sm-8">
            <p class="form-control-static">
              <input type="text" class="form-control" [(ngModel)]="model.name" #name="ngModel"
                     [ngClass]="{'is-invalid': f.submitted && name.invalid }" name="name" placeholder="name" required />
              <span *ngIf="f.submitted && name.invalid" class="invalid-feedback">
                <span>Name is required.</span>
              </span>
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 form-control-label">User Name</label>
          <div class="col-sm-8">
            <p class="form-control-static">
              <input type="text" class="form-control" [(ngModel)]="model.userName" #userName="ngModel"
                     [ngClass]="{'is-invalid': f.submitted && userName.invalid}" name="userName" placeholder="Username" required />
              <span *ngIf="f.submitted && userName.invalid" class="invalid-feedback">
                <span>Username is required.</span>
              </span>
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 form-control-label">Email</label>
          <div class="col-sm-8">
            <p class="form-control-static">
              <input type="text" class="form-control" [(ngModel)]="model.email" #email="ngModel"
                     [ngClass]="{'is-invalid': f.submitted && email.invalid}" name="email" placeholder="Email" required email />
              <span *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                <span *ngIf="email.errors.required">Email is required.</span>
                <span *ngIf="email.errors.email">Email must be a valid email address.</span>
              </span>
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 form-control-label">Password</label>
          <div class="col-sm-8">
            <p class="form-control-static">
              <input type="password" class="form-control" [(ngModel)]="model.password" #password="ngModel"
                     [ngClass]="{'is-invalid': f.submitted && password.invalid}" name="password" placeholder="password" required minlength="8" />
              <span *ngIf="f.submitted && password.invalid" class="invalid-feedback">
                <span *ngIf="password.errors.required">Password is required.</span>
                <span *ngIf="password.errors.minlength">Password must be a at least 8 characters.</span>
              </span>
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 form-control-label">Select Client</label>
          <div class="col-sm-8">
            <p class="form-control-static">
              <select class="form-control" [(ngModel)]="model.clientId" #clientId="ngModel"
                      [ngClass]="{'is-invalid': f.submitted && clientId.invalid}" name="clientId" required>
                <option *ngFor="let client of clients" [value]="client.id">{{client.name}}</option>
              </select>
              <span *ngIf="f.submitted && clientId.invalid" class="invalid-feedback">
                <span>Client is required.</span>
              </span>
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 form-control-label">Select Status Code</label>
          <div class="col-sm-8">
            <p class="form-control-static">
              <select class="form-control" [(ngModel)]="model.userStatusCodeId" #userStatusCodeId="ngModel"
                      [ngClass]="{'is-invalid': f.submitted && userStatusCodeId.invalid}" name="statusCodeId" required>
                <option *ngFor="let userStatusCode of userStatusCodes" [value]="userStatusCode.id">{{userStatusCode.name}}</option>
              </select>
              <span *ngIf="f.submitted && userStatusCodeId.invalid" class="invalid-feedback">
                <span>User Status Code is required.</span>
              </span>
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 form-control-label">Select Role</label>
          <div class="col-sm-8">
            <p class="form-control-static">
              <select class="form-control" [(ngModel)]="model.roleId" #roleId="ngModel"
                      [ngClass]="{'is-invalid': f.submitted && roleId.invalid}" name="roleId" required>
                <option *ngFor="let role of roles" [ngValue]="role.id">{{role.name}}</option>
              </select>
              <span *ngIf="f.submitted && roleId.invalid" class="invalid-feedback">
                <span>Role is required.</span>
              </span>
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 form-control-label"></label>
          <div class="col-sm-8">
            <p class="form-control-static">
              <button type="submit" class="btn btn-inline">Submit</button>
            </p>
          </div>
        </div>
      </form>
      <form *ngIf="isUpdateUser" #f="ngForm" (ngSubmit)="f.form.valid && saveUser()" novalidate>
        <div class="form-group row">
          <label class="col-sm-4 form-control-label">Name</label>
          <div class="col-sm-8">
            <p class="form-control-static">
              <input type="text" class="form-control" [(ngModel)]="model.name" #name="ngModel"
                     [ngClass]="{'is-invalid': f.submitted && name.invalid }" name="name" placeholder="name" required />
              <span *ngIf="f.submitted && name.invalid" class="invalid-feedback">
                <span>Name is required.</span>
              </span>
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 form-control-label">User Name</label>
          <div class="col-sm-8">
            <p class="form-control-static">
              <input type="text" class="form-control" [(ngModel)]="model.userName" #userName="ngModel"
                     [ngClass]="{'is-invalid': f.submitted && userName.invalid}" name="userName" placeholder="Username" readonly required />
              <span *ngIf="f.submitted && userName.invalid" class="invalid-feedback">
                <span>Username is required.</span>
              </span>
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 form-control-label">Email</label>
          <div class="col-sm-8">
            <p class="form-control-static">
              <input type="text" class="form-control" [(ngModel)]="model.email" #email="ngModel"
                     [ngClass]="{'is-invalid': f.submitted && email.invalid}" name="email" placeholder="Email" required email />
              <span *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                <span *ngIf="email.errors.required">Email is required.</span>
                <span *ngIf="email.errors.email">Email must be a valid email address.</span>
              </span>
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 form-control-label">Select Client</label>
          <div class="col-sm-8">
            <p class="form-control-static">
              <select class="form-control" [(ngModel)]="model.clientId" #clientId="ngModel"
                      [ngClass]="{'is-invalid': f.submitted && clientId.invalid}" name="clientId" required>
                <option *ngFor="let client of clients" [value]="client.id">{{client.name}}</option>
              </select>
              <span *ngIf="f.submitted && clientId.invalid" class="invalid-feedback">
                <span>Client is required.</span>
              </span>
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 form-control-label">Select Status Code</label>
          <div class="col-sm-8">
            <p class="form-control-static">
              <select class="form-control" [(ngModel)]="model.userStatusCodeId" #userStatusCodeId="ngModel"
                      [ngClass]="{'is-invalid': f.submitted && userStatusCodeId.invalid}" name="statusCodeId" required>
                <option *ngFor="let userStatusCode of userStatusCodes" [value]="userStatusCode.id">{{userStatusCode.name}}</option>
              </select>
              <span *ngIf="f.submitted && userStatusCodeId.invalid" class="invalid-feedback">
                <span>User Status Code is required.</span>
              </span>
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 form-control-label">Select Role</label>
          <div class="col-sm-8">
            <p class="form-control-static">
              <select class="form-control" [(ngModel)]="model.roleId" #roleId="ngModel"
                      [ngClass]="{'is-invalid': f.submitted && roleId.invalid}" name="roleId" required>
                <option *ngFor="let role of roles" [ngValue]="role.id">{{role.name}}</option>
              </select>
              <span *ngIf="f.submitted && roleId.invalid" class="invalid-feedback">
                <span>Role is required.</span>
              </span>
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 form-control-label"></label>
          <div class="col-sm-8">
            <p class="form-control-static">
              <button type="submit" class="btn btn-inline">Save User</button>
            </p>
          </div>
        </div>
      </form>
    </section>
  </div>
  <div class="dialog-overlay" [hidden]="!dialog.isOpen"></div>
</div>
