import { Inject, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'column-create',
  templateUrl: 'column-create.component.html',
})
export class ColumnCreateDialogComponent implements OnInit {

  title: string;
  newTableGroupColumn: any; formulas: any;

  constructor(public dialogRef: MatDialogRef<ColumnCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.newTableGroupColumn = data.newTableGroupColumn;
    this.formulas = data.formulas;
  }

  ngOnInit() { }

  onConfirm(): void { this.dialogRef.close({action:'Save',newTableGroupColumn:this.newTableGroupColumn}); }
  addNewReportFormula(): void { this.dialogRef.close({action:'Add_Formula'}); }
  editFormula(id: any): void { this.dialogRef.close({action:'Edit_Formula', id: id, newTableGroupColumn:this.newTableGroupColumn}); }
  onDismiss(): void { this.dialogRef.close({action:'Close'}); }
}

