import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocationService } from '../services/location.service';
import { EmployeeService } from '../services/employee.service';
import { FormulaService } from '../services/formula.service';
import { Formula } from '../models/formula';
import { NotifierService } from 'angular-notifier';
import { Employee } from '../models/employee';
import { Seller } from '../models/seller';
import { SellingGroup } from '../models/selling-group';
import { Location } from '../models/location';
import { RankContest } from '../models/rank-contest';
import { ReportType } from '../models/report-type';
import { ReportTableGroupColumn } from '../models/report-table-group-column';
import * as moment from 'moment';

declare const _;

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private _baseUrl = '/api/report';
  selectedReport: RankContest = new RankContest();
  locations: Location[] = [];
  employees: Employee[] = [];
  formulas: Formula[] = [];
  rankReportSalesObject: any;
  groupARealLength = 0;
  groupBRealLength = 0;

  constructor(private http: HttpClient,
    private _notifier: NotifierService,
    /*private skuGroupService: SkuGroupService,
    private _tableService: TableService,*/
    private _formulaService: FormulaService,
    private _locationService: LocationService,
    private _employeeService: EmployeeService) {

      }
  createReport(userId: string, title: string, message: string, dateTypeId: number,
    locationTierFocusId: number, reportType: ReportType, locationId: number): Observable<any> {

    const reportConfig = {
      userId,
      title,
      message,
      dateTypeId,
      locationTierFocusId,
      reportType,
      locationId
    };
   // console.log(reportConfig);
    return this.http.post(this._baseUrl, reportConfig);
  }

  updateReport(id: number, userId: string, title: string, message: string, dateTypeId: number,
    locationTierFocusId: number, reportType: ReportType, locationId: number): Observable<any> {

    const reportConfig = {
      id,
      userId,
      title,
      message,
      dateTypeId,
      locationTierFocusId,
      reportType,
      locationId
    };
    return this.http.put(this._baseUrl + '/' + reportConfig.id + '/updateReport', reportConfig);
  }

  updateReportTableGroup(id: number, reportId: number, shortTitle: string, longTitle: string, description: string, displayPosition: number,
    rankingPercentage: number, rankingFormulaId: number): Observable<any> {

    const reportConfig = {
      id,
      reportId,
      shortTitle,
      longTitle,
      description,
      displayPosition,
      rankingPercentage,
      rankingFormulaId
    };
    return this.http.put(this._baseUrl + '/' + reportConfig.id + '/updateReportTableGroup', reportConfig);
  }

  addReportTableGroup(reportId: number, shortTitle: string, longTitle: string, description: string, displayPosition: number,
    rankingPercentage: number, rankingFormulaId: number): Observable<any> {

    const reportxConfig = {
      reportId,
      shortTitle,
      longTitle,
      description,
      displayPosition,
      rankingPercentage,
      rankingFormulaId

    };
    //console.log(reportxConfig);
    return this.http.put(this._baseUrl + '/addTableGroup', reportxConfig );
  }
  updateColumnRedValue(id: number, RedValueGroup1: number, RedValueGroup2: number): Observable<any> {
    const reportConfig = {
      id,
      RedValueGroup1,
      RedValueGroup2
    };
    return this.http.put(this._baseUrl + '/' + reportConfig.id + '/updateReportTableGroupColumnRedValue', reportConfig);
  }
  updateGroupRedValue(id: number, RedValueGroup1: number, RedValueGroup2: number): Observable<any> {
    const reportConfig = {
      id,
      RedValueGroup1,
      RedValueGroup2
    };
    return this.http.put(this._baseUrl + '/' + reportConfig.id + '/updateReportTableGroupRedValue', reportConfig);
  }

  updateReportTableGroupColumn(id: number, reportTableGroupId: number, formulaId: number, displayPosition: number,
  ): Observable<any> {

    const reportConfig = {
      id,
      reportTableGroupId,
      formulaId,
      displayPosition
    };
    return this.http.put(this._baseUrl + '/' + reportConfig.id + '/updateReportTableGroupColumn', reportConfig);
  }

  addReportTableGroupColumn(reportTableGroupId: number, formulaId: number, displayPosition: number,
     ): Observable<any> {

    const reportxConfig = {
      reportTableGroupId,
      formulaId,
      displayPosition


    };
    //console.log(reportxConfig);
    return this.http.put(this._baseUrl + '/addTableGroupColumn', reportxConfig);
  }
  changeColor(location: Location): Observable<any> {
      return this.http.put(this._baseUrl + '/' + location.id + '/editColor', location);

  }

  requestRankReport(reportid: number): Observable<any> {
    return this.http.get <RankContest>(this._baseUrl + '/' + reportid + '/requestRankReport');
  }
  requestRankReportOverrideLocation(reportid: number, districtid: number, monthYear: string): Observable<any> {
    return this.http.get<RankContest>(this._baseUrl + '/' + reportid + '/' + districtid + '/' + monthYear + '/requestRankReport');
  }

  evaluateFinalRank(id: any, groupCode: any) {
    const RankingObject = [];
    let mySum = 0;
    let tempValue = 1;
    if (groupCode === 'A') {
      this.selectedReport.GroupA.sellers.forEach((c) => {
        RankingObject.push([c.locationId, this.evaluateScore(c, groupCode)]);
        if (c.locationId === id) {
          mySum = this.evaluateScore(c, groupCode);
        }
      });
    } else if (this.selectedReport.locationTierFocusId !== 3 && groupCode === 'B') {
      this.selectedReport.GroupB.sellers.forEach((c) => {
        RankingObject.push([c.locationId, this.evaluateScore(c, groupCode)]);
        if (c.locationId === id) {
          mySum = this.evaluateScore(c, groupCode);
        }
      });
    } else if (this.selectedReport.locationTierFocusId === 3 && groupCode === 'B') {
      this.selectedReport.GroupB.sellers.forEach((c) => {
        RankingObject.push([c.employeeId, this.evaluateScore(c, groupCode)]);
        if (c.employeeId === id) {
          mySum = this.evaluateScore(c, groupCode);
        }

      });
    }
    let lowestValue = 0;
    let totalCount = 0;
    RankingObject.forEach((x) => {
      if (x[1] > lowestValue) {
        lowestValue = x[1];
      }
      if (mySum <= x[1]) {
        if (mySum < x[1]) {
        }
      } else {
        tempValue++;
      }
    });
    RankingObject.forEach((x) => {
      if (x[1] !== lowestValue) {
        totalCount++;
      }
    });
    return tempValue + '/' + totalCount;
  }

  evaluateScore(seller: any, groupCode: any) {
    let sum = 0;
    this.selectedReport.reportTableGroup.forEach((c) => {
      const newFormula: ReportTableGroupColumn = new ReportTableGroupColumn();
      newFormula.formula = new Formula();
      newFormula.formula.id = c.rankingFormulaId;
      newFormula.nameValueOverride = 'Rank';
      sum = sum + (this.findYourRankOrFormulaRaw(seller, newFormula) * c.rankingPercentage);
    });
    return sum;
  }

  findYourRankOrFormulaRaw(seller, column) {
    let response = 0;
    seller.formulas.formulas.forEach((formula) => {
      const theseGroups = formula;
      if (theseGroups.formulaId === column.formula.id && theseGroups.nameValueOverride === column.nameValueOverride) {
        response = theseGroups.value;
      }
    });
    return response;
  }

  evaluateFormula(formula: any, seller: any, groupCode: any) {
    let formulaId = formula.formulaId;
    if (!formulaId) {
      formulaId = formula.id;
    }
    const name = formula.nameValueOverride;
    if (name === 'Rank') {
      let myValue = 0;
      let tempValue = 1;
      const RankingObject = [];
      const newFormula: ReportTableGroupColumn = new ReportTableGroupColumn();
      newFormula.formulaId = formulaId;
      newFormula.nameValueOverride = '';
      if (groupCode === 'A') {
        this.selectedReport.GroupA.sellers.forEach((c) => {
          if (c.locationId === seller.locationId) {
            myValue = this.evaluateFormula(newFormula, c, 'A');
          }
          RankingObject.push([c.locationId, this.evaluateFormula(newFormula, c, 'A')]);
        });
        let wasLowest = true;
        RankingObject.forEach((x) => {
          if (myValue >= x[1]) {
            if (myValue > x[1] && myValue > 0) {
              wasLowest = false;
            }
          } else {
            tempValue++;
          }
        });
        if (wasLowest && formula.groupALowestRank <= tempValue) {
          formula.groupALowestRank = tempValue;
        }
      } else if (groupCode === 'B') {
        this.selectedReport.GroupB.sellers.forEach((c) => {
          if (this.selectedReport.locationTierFocusId !== 3) {
            if (c.locationId === seller.locationId) {
              myValue = this.evaluateFormula(newFormula, c, 'B');
            }
            RankingObject.push([c.locationId, this.evaluateFormula(newFormula, c, 'B')]);
          } else {
            if (c.employeeId === seller.employeeId) {
              myValue = this.evaluateFormula(newFormula, c, 'B');
            }
            RankingObject.push([c.employeeId, this.evaluateFormula(newFormula, c, 'B')]);
          }
        });
        let wasLowest = true;
        RankingObject.forEach((x) => {
          if (myValue >= x[1]) {
            if (myValue > x[1] && myValue > 0) {
              wasLowest = false;
            }
          } else {
            tempValue++;
          }
        });
        if (wasLowest && formula.groupBLowestRank <= tempValue) {
          formula.groupBLowestRank = tempValue;
        }
      }
      return tempValue;
    }
    if (formulaId === 0) {
      return 0;
    }
    const formulaObject = this.formulas.find(x => x.id === formulaId).formulaValue;
    let sellerObject = null;
    let goalObject = null;
    let conversionData = null;
    let hoursWorkedData = null;
    let sellerId = 0;
    if (groupCode === 'A') {
      sellerId = seller.locationId;
      sellerObject = this.rankReportSalesObject.sellingA.sellers.find(x => x.locationId === sellerId).skuGroups;
      goalObject = this.rankReportSalesObject.sellingA.sellers.find(x => x.locationId === sellerId).goalList;
      conversionData = this.rankReportSalesObject.sellingA.sellers.find(x => x.locationId === sellerId).conversionData;
      hoursWorkedData = this.rankReportSalesObject.sellingA.sellers.find(x => x.locationId === sellerId).hoursWorkedData;
    } else if (groupCode === 'B') {
      if (this.selectedReport.locationTierFocusId !== 3) {
        sellerId = seller.locationId;
        sellerObject = this.rankReportSalesObject.sellingB.sellers.find(x => x.locationId === sellerId).skuGroups;
        goalObject = this.rankReportSalesObject.sellingB.sellers.find(x => x.locationId === sellerId).goalList;
        conversionData = this.rankReportSalesObject.sellingB.sellers.find(x => x.locationId === sellerId).conversionData;
        hoursWorkedData = this.rankReportSalesObject.sellingB.sellers.find(x => x.locationId === sellerId).hoursWorkedData;
      } else {
        sellerId = seller.employeeId;
        sellerObject = this.rankReportSalesObject.sellingB.sellers.find(x => x.employeeId === sellerId).skuGroups;
        goalObject = this.rankReportSalesObject.sellingB.sellers.find(x => x.employeeId === sellerId).goalList;
        conversionData = this.rankReportSalesObject.sellingB.sellers.find(x => x.employeeId === sellerId).conversionData;
        hoursWorkedData = this.rankReportSalesObject.sellingB.sellers.find(x => x.employeeId === sellerId).hoursWorkedData;
      }
    }
    const formulaElements = formulaObject.split(',');
    let OpenCloseCount = 0;
    let stringFormula = '';
    let result = 0;
    for (let i = 0; i < formulaElements.length; i++) {
      const currentWord = formulaElements[i];
      const nextWord = (i < formulaElements.length - 1) ? formulaElements[i + 1] : '';
      if (currentWord === '(') {
        OpenCloseCount = OpenCloseCount + 1;
        stringFormula += currentWord;
      } else if (currentWord === ')' && OpenCloseCount > 0) {
        OpenCloseCount = OpenCloseCount - 1;
        stringFormula += currentWord;
      } else if (currentWord === 'QTY') {
        const skuGroup = sellerObject.find(s => s.name === nextWord);
        if (skuGroup && skuGroup.quantity >= 0) {
          stringFormula += '(' + skuGroup.quantity + ')';
        } else {
          stringFormula = '0';
          break;
        }
      } else if (currentWord === 'GP') {
        const skuGroup = sellerObject.find(s => s.name === nextWord);
        if (skuGroup && skuGroup.profit > 0) {
          stringFormula += '(' + skuGroup.profit + ')';
        } else {
          stringFormula = '0';
          break;
        }
      } else if (currentWord === 'GOAL') {
        if (goalObject) {
          const thisGoal = goalObject.find(s => s.name === nextWord);
          if (thisGoal && thisGoal.goalValue > 0) {
            stringFormula += '(' + thisGoal.goalValue + ')';
          } else {
            stringFormula = '0';
            break;
          }
        }
      } else if (currentWord === 'REBIZ') {
        if (conversionData) {
          switch (nextWord) {
            case 'Boxes':
              stringFormula += '(' + conversionData.boxes + ')';
              break;
            case 'Traffic':
              stringFormula += '(' + conversionData.traffic + ')';
              break;
            case 'GrossProfit':
              stringFormula += '(' + conversionData.grossProfit + ')';
              break;
            default:
              stringFormula += 0;
              break;
          }
        }
      } else if (currentWord === 'HOURS') {
        if (hoursWorkedData) {
          switch (nextWord) {
            case 'Worked':
              stringFormula += '(' + hoursWorkedData.hoursWorked + ')';
              break;
            default:
              stringFormula += 0;
              break;
          }
        }
      } else if (currentWord === 'DATE') {
        const currentMonth = moment().format('MMMM');
        const lastMonth = moment().subtract(1, 'month').format('MMMM');
        const secondLastMonth = moment().subtract(2, 'month').format('MMMM');
        const today = moment();
        const yesterday = moment().subtract(1, 'day');
        const previousWeekMonday = moment().weekday(-6);
        const twoWeekBeforeMonday = moment().weekday(-6).weekday(-6);
        const previousWeekSunday = moment().weekday(0).subtract(7, 'days');
        const currentWeekSunday = moment().weekday(0);
        const currentMonthFirstDay = moment().startOf('month');
        const currentMonthLastDay = moment().endOf('month');
        const currentYearFirstDay = moment().startOf('year');
        const previousMonthFirstDay = moment().subtract(1, 'months').startOf('month');
        const twoMonthBackFirstDay = moment().subtract(2, 'months').startOf('month');
        const twoMonthBackLastDay = moment().subtract(2, 'months').endOf('month');
        const previousMonthSameDay = moment().subtract(1, 'months');
        const previousMonthLastDay = moment().subtract(1, 'months').endOf('month');
        const previousYearFirstDay = moment().subtract(1, 'year').startOf('year');
        const previousYearSameDay = moment().subtract(1, 'year');
        const previousYearSameMonthFirstDay = moment().subtract(1, 'year').startOf('month');
        const passed: number = today.date();
        const remaining: number = currentMonthLastDay.date() - today.date();
        const daysinmonth: number = currentMonthLastDay.date();
        if (this.selectedReport.dateTypeId === 1) {
          if (nextWord === 'Passed') {
            stringFormula += '(' + passed + ')';
          } else if (nextWord === 'Remaining') {
            stringFormula += '(' + remaining + ')';
          } else if (nextWord === 'DaysInMonth') {
            stringFormula += '(' + daysinmonth + ')';
          } else {
            stringFormula = '0';
            break;
          }
        } else if (this.selectedReport.dateTypeId === 5) {
          stringFormula = '0';
        }
      } else if (currentWord === '+' || currentWord === '-' || currentWord === '*' || currentWord === '/') {
        stringFormula += currentWord;
      }
    }
    try {
      result = this.evaluate(stringFormula);
    } catch (e) {
      result = 0;
    }
    if (isNaN(result)) {
      result = 0;
    }
    return result;
  }

  evaluate(code: string) {
    const f = new Function('return ' + code);
    return f();
  }

  getReports(callback: any, districtId: number, clientId: number) {
    this.getReportsByUser(clientId).subscribe((response) => {
      this.selectedReport = response[0];
      this.selectedReport.GroupA = new SellingGroup();
      this.selectedReport.GroupA.sellers = [];
      this.selectedReport.GroupA.sellers = this.getGroupASellers(districtId);
      this.selectedReport.GroupA.locationTierEnum = this.selectedReport.locationTierFocusId + 1;
      this.selectedReport.GroupB = new SellingGroup();
      this.selectedReport.GroupB.sellers = [];
      this.selectedReport.GroupB.locationTierEnum = this.selectedReport.locationTierFocusId + 2;
      if (this.selectedReport.locationTierFocusId < 3) {
        this.selectedReport.GroupB.sellers = this.getGroupBSellers(true, this.selectedReport.GroupA.sellers);
      } else if (this.selectedReport.locationTierFocusId === 3) {
        this.selectedReport.GroupB.sellers = this.getGroupBSellers(false, this.selectedReport.GroupA.sellers);
      }
      this.selectedReport.totalQuantityColumns = 0;
      this.selectedReport.listOfColumns = [];
      if (this.selectedReport.reportTableGroup.length) {
        this.selectedReport.numberOfSubGroups = this.selectedReport.reportTableGroup.length;
        this.selectedReport.totalQuantityColumns = this.selectedReport.totalQuantityColumns
          + (this.selectedReport.numberOfSubGroups * 2);
        this.selectedReport.reportTableGroup.forEach((tableGroup) => {
          const thisGroup = tableGroup;
          thisGroup.numberOfColumns = 0;
          if (thisGroup.reportTableGroupColumn.length) {
            thisGroup.numberOfColumns = thisGroup.numberOfColumns + thisGroup.reportTableGroupColumn.length;
            thisGroup.reportTableGroupColumn.forEach((column) => {
              this.selectedReport.totalQuantityColumns = this.selectedReport.totalQuantityColumns + 1;
              this.selectedReport.listOfColumns.push(column);
            });
          }
          const rankColumn = new ReportTableGroupColumn();
          rankColumn.reportTableGroupId = thisGroup.id;
          rankColumn.displayPosition = 0;
          rankColumn.groupALowestRank = 0;
          rankColumn.groupBLowestRank = 0;
          rankColumn.formulaId = thisGroup.rankingFormulaId;
          rankColumn.formula = this.formulas.find(x => x.id === thisGroup.rankingFormulaId);
          rankColumn.nameValueOverride = 'Rank';
          const rankColumn2 = new ReportTableGroupColumn();
          rankColumn2.nameValueOverride = 'Ranking';
          rankColumn2.redValueGroup1 = thisGroup.redValueGroup1;
          rankColumn2.redValueGroup2 = thisGroup.redValueGroup2;
          rankColumn2.reportTableGroupId = thisGroup.id;
          rankColumn2.displayPosition = 0;
          rankColumn2.formulaId = thisGroup.rankingFormulaId;
          rankColumn2.formula = this.formulas.find(x => x.id === thisGroup.rankingFormulaId);
          this.selectedReport.listOfColumns.push(rankColumn);
          this.selectedReport.listOfColumns.push(rankColumn2);
        });
      }
      callback();
    });
  }

  getEmployees(callback: any) {
    this._employeeService.getAllEmployees()
      .subscribe((response: Employee[]) => {
        this.employees = response;
        callback();
      }, (error) => this._notifier.notify('error', error.message), () => { });
  }

  getLocations(callback: any) {
    this._locationService.getAllLocations()
      .subscribe((response: Location[]) => {
        this.locations = response;
        callback();
      }, (error) => this._notifier.notify('error', error.error), () => { });
  }
  getFormulas(clientId: number, callback: any) {
    this._formulaService.getFormulas(clientId, (response) => {
      this.formulas = response;
      callback();
    }, true);
  }

  finishReport(callback: any, districtId: number, yearMonth: string, reportId: number) {
    this.requestRankReportOverrideLocation(reportId, districtId, yearMonth).subscribe((response: any) => {

      this.rankReportSalesObject = response;
      this.rankReportSalesObject.sellingA.sellers.forEach((sellA) => {
        const thisSeller = sellA;
        sellA.formulas = [];
        this.selectedReport.listOfColumns.forEach((column) => {
          const thisCol = column;
          const formy = { value: 0, formulaId: 0, nameValueOverride: '' };
          formy.value = this.evaluateFormula(thisCol, thisSeller, 'A');
          formy.formulaId = thisCol.formula.id;
          formy.nameValueOverride = thisCol.nameValueOverride;
          sellA.formulas.push(formy);
        });
        this.selectedReport.GroupA.sellers.forEach((reportSellA) => {
          const selectedReportMatch = reportSellA;
          if (thisSeller.locationId === selectedReportMatch.locationId) {
            selectedReportMatch.formulas = sellA;
          }
        });
      });
      this.rankReportSalesObject.sellingB.sellers.forEach((sellB) => {
        const thisSeller = sellB;
        sellB.formulas = [];
        this.selectedReport.listOfColumns.forEach((column) => {
          const thisCol = column;
          const formy = { value: 0, formulaId: 0, nameValueOverride: '' };
          formy.value = this.evaluateFormula(thisCol, thisSeller, 'B');
          formy.formulaId = thisCol.formula.id;
          formy.nameValueOverride = thisCol.nameValueOverride;
          sellB.formulas.push(formy);
        });
        this.selectedReport.GroupB.sellers.forEach((reportSellB) => {
          const selectedReportMatch = reportSellB;
          if (thisSeller.locationId === selectedReportMatch.locationId || thisSeller.employeeId === selectedReportMatch.employeeId) {
            selectedReportMatch.formulas = sellB;
          }
        });
      });
      //this._notifier.notify('success', 'Report Got!');
      callback();
    }, (error) => this._notifier.notify('error', error.message), () => { });
  }

  async storeDefaultRankReport(callback: any, districtId: number, monthYear: string, clientId: number, reportId: number) {
    this.getFormulas(clientId, () => {
      this.getLocations(() => {
        this.getEmployees(() => {
          this.getReports(() => {
            this.finishReport(() => {
              callback();
            },
              districtId, monthYear, reportId);
          }, districtId, clientId);
      });
      });
    });
    return true;
  }

  getReportsByUser(selectedClientId: number): Observable<RankContest[]> {
      return this.http.get<RankContest[]>(this._baseUrl + '/' + selectedClientId);
  }

  deleteReportTableGroup(id: number): Observable<any> {
    return this.http.delete(this._baseUrl + '/' + id + '/deleteReportTableGroup');
  }
  deleteReportTableGroupColumn(id: number): Observable<any> {
    return this.http.delete(this._baseUrl + '/' + id + '/deleteReportTableGroupColumn');
  }
  deleteReport(id: number): Observable<any> {
    return this.http.delete(this._baseUrl + '/' + id + '/deleteReport');
  }

  getGroupASellers(locationId: number) {
    const sellerList: Seller[] = [];
    for (const location in this.locations) {
      if (this.locations[location].id === locationId) {
        for (const location2 in this.locations) {
          if (this.locations[location2].parentId === this.locations[location].id) {
            const thisStore: Location = this.locations[location2];
            const thisSeller = new Seller();
            thisSeller.isLocation = true;
            thisSeller.name = thisStore.name;
            thisSeller.locationId = thisStore.id;
            sellerList.push(thisSeller);
          }
        }
      }
    }
    return sellerList;
  }

  getGroupBSellers(isLocation: boolean, locationList: Seller[]) {
    const sellerList: Seller[] = [];
    locationList.forEach((location) => {
      if (isLocation) {
        const storeId = location.locationId;
        const storeList = this.locations.filter(loc => loc.parentId === storeId);
        storeList.forEach((store) => {
          const thisSeller = new Seller();
          const thisStore = store;
          thisSeller.isLocation = true;
          thisSeller.name = thisStore.name;
          thisSeller.locationId = thisStore.id;
          sellerList.push(thisSeller);
        });
      } else {
        const storeId = location.locationId;
        const empList = this.employees.filter(emp => emp.locationId === storeId && emp.user.userStatusCode.name !== 'Disabled');
        empList.forEach((employee) => {
          const thisSeller = new Seller();
          const thisEmp = employee;
          thisSeller.isLocation = false;
          thisSeller.name = thisEmp.user.name;
          thisSeller.employeeId = thisEmp.id;
          thisSeller.goals = [];
          sellerList.push(thisSeller);
        });
      }
    });
    return sellerList;
  }
}
