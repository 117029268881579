import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Observable, ReplaySubject, Subscription } from "rxjs";
import * as XLSX from 'xlsx';
import { map, takeUntil } from "rxjs/operators";
import { RqVzDataTableComponent } from "../../rq-vz-data-table/rq-vz-data-table.component";
import { CommissionReconcileService } from "../../services/commission-reconcile.service";
import { LoaderService } from "../../services/loader.service";
import { NotesDialogComponent } from "../../dialogs/notes/notes-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { LocationService } from "../../services/location.service";
import { ProductSearchDialogComponent } from "../../dialogs/product-search/product-search-dialog.component";

@Component({
  selector: 'discrepancies-data-table',
  templateUrl: './Discrepancies-data-table.component.html',
  providers: []
})

export class DiscrepanciesDataTableComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(private _commissionReconcileService: CommissionReconcileService, private searchDialog: MatDialog,
    private _loaderService: LoaderService, private dialog: MatDialog, private _locationService: LocationService) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(RqVzDataTableComponent) rqVzDataTableComponent: RqVzDataTableComponent;

  @Input()
  tableData: any;
  @Output()
  getCommissionReportUpdated = new EventEmitter<string>();
  debug = true;
  dataSource = new MatTableDataSource();
  searchTerm: string = "";
  selectedRow = new Set<any>();
  selectedImei: any;
  selectedItem = {};
  moneySymbol = '$';
  noData: Observable<boolean>;
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dataSource.paginator = this.paginator; this.dataSource.sort = this.sort;
    },0);
  }

  ngOnDestroy(): void {
    this.columnDefinations = null;
    this.dataSource = null;
    this.dialogRef?.close();
    this.searchDialogRef?.close();
  }

  ngOnInit(): void {
    //this.updateDataSource(this.tableData);
    this.subscriptionToClients = this._locationService.currentActualClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        this.selectedClientId = message; this.onClientSelected();
      });
  }

  onClientSelected() {
    if (!this.selectedClientId || +this.selectedClientId === 0) {
      return;
    }
    this.selectedClientId = +this.selectedClientId;
    this.selectedClient = this.clients.find(r => r.id === this.selectedClientId);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  columnDefinations = [
    { name: "Select", isSortable: true, mappedToProperty: "isSelected", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Soldby", isSortable: true, mappedToProperty: "soldBy", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Inv#", isSortable: true, mappedToProperty: "mostRelevantInvoice", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "EquipmentType", isSortable: true, mappedToProperty: "equipmentType", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "IMEI", isSortable: true, mappedToProperty: "imei", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "MTN", isSortable: true, mappedToProperty: "mtn", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Difference", isSortable: true, mappedToProperty: "difference", class: "price", isFilterable: true, sticky: false, hide: false },
    { name: "Notes", isSortable: false, mappedToProperty: "commissionIMEINotes", class: "", isFilterable: true, sticky: false, hide: false }
  ];

  exportTableToExcel() {
    let sheetData = [];
    let firstRow = [];
    let mapToList = [];
    this.columnDefinations.forEach(x => {
      if (!x.hide) {
        firstRow.push(x.name);
        mapToList.push(x.mappedToProperty);
      }
    });
    sheetData.push(firstRow);
    this.dataSource.filteredData.forEach((y: any) => {
      let thisRow = [];
      mapToList.forEach(m => {
        let textToShow='';
        switch(m){
         case 'commissionIMEINotes':          
          textToShow= this.getTextBydata(y,this.getColumnDefinationByMappedToProperty('commissionIMEINotes'));
          break;
          default:
            textToShow=y[m];
            break;
        }
        thisRow.push(textToShow);
      });
      sheetData.push(thisRow);
    });
    let ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'MonthlyCommission.xlsx');
  }


  selectedColumns = this.getDisplayedColumns();
  allAreSelected = false;

  getDisplayedColumns(): string[] {
    return this.columnDefinations.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
  }

  getColumnDefinationByMappedToProperty(mappedToProperty) {
    var column = this.columnDefinations.find(x => x.mappedToProperty == mappedToProperty);
    return column;
  }

  updateDataSource(data) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.data.forEach((x: any) => { x.isSelected = this.allAreSelected; });
    this.selectedImei = data[0];
    this.selectedItem = this.selectedImei;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this._loaderService.hide();
    this.dataSource.sortData = this.sortData();
    this.noData = this.dataSource.connect().pipe(map(data => data.length === 0));
    this.applyFilter(this.searchTerm);
  }

  selectItem(imei) {
    this.selectedItem = imei;
    this.rqVzDataTableComponent.updateSelectedImei(imei);
  }

  selectImei(imei) {
    imei.isSelected = !imei.isSelected;
    this.selectedImei = imei;
    this.allAreSelected = this.isAllSelected();
  }

  selectAll(e) {
    this.allAreSelected = e.checked;
    this.dataSource.filteredData.forEach((x: any) => {
      x.isSelected = this.allAreSelected;
    });

  }

  isAllSelected() {
    var notSelectedItems = this.dataSource.data.filter(function (item: any) {
      return item.isSelected === false;
    });
    return !(notSelectedItems != null && notSelectedItems.length > 0);
  }

  checkboxLabel(row): string {
    if (row != null) {
      return `${this.allAreSelected ? 'deselect' : 'select'} all`;
    }
    return "";
  }

  sendListOfImeis(decision: number) {
    this._loaderService.show();
    const thislist = this.dataSource.data.filter(function (item: any) {
      return item.isSelected === true;
    }).map(function (item: any) {
      return item.id;
    });
    this._commissionReconcileService.AddImeiListCommand(thislist, decision).subscribe((response: any) => {
      this.getCommissionReportUpdated.next(); this.selectAll({ checked: false });
    });
  }

  getTextBydata(element, columnDef) {
    var textToShow = '';
    switch (columnDef.name) {
      case 'Notes':
        textToShow = element.commissionIMEINotes.length > 0 ? " (+" + (element.commissionIMEINotes.length) + " ...)" : "";
        break;
      default:
        textToShow = element[columnDef.mappedToProperty];
    }
    return textToShow;
  }

  private dialogRef: any;
  selectedClientId: number;
  subscriptionToClients: Subscription;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  selectedClient: any;
  clients: any[] = [];
  regions: any[];

  openNotesDialog(column, element) {
    if (column == 'commissionIMEINotes') {
      element.notes = element.commissionIMEINotes;
      this.dialogRef = this.dialog.open(NotesDialogComponent,
        {
          autoFocus: true,
          disableClose: false,
          panelClass: 'notes-component',
          data: { element: element, selectedClientId: this.selectedClientId, pageOrigin: 'monthly-commision' }
        });
      this.dialogRef.afterClosed().subscribe(result => {
        if (result.update === true) {
          this.getCommissionReportUpdated.next(); this.selectAll({ checked: false });

        }
      });
    }
  }

  sortData() {
    let sortFunction =
      (items: any[], sort: MatSort): any[] => {
        if (!sort.active || sort.direction === '' || sort.active == 'symbol') {
          return items;
        }
        return items.sort((a: any, b: any) => {
          let comparatorResult = 0;
          switch (sort.active) {
            case 'isSelected':
              comparatorResult = (a[sort.active] === b[sort.active]) ? 0 : a[sort.active] ? -1 : 1;
              break;
            case 'commissionIMEINotes':
              comparatorResult = (a[sort.active].length - b[sort.active].length);
              break;
            case 'difference':
              comparatorResult = parseInt(a[sort.active].replace('$', '')) - parseInt(b[sort.active].replace('$', ''));
              break;
            default:
              if (a[sort.active] && b[sort.active]) {
                if (a[sort.active].localeCompare instanceof Function) {
                  comparatorResult = a[sort.active].localeCompare(b[sort.active]);
                }
              } else {
                comparatorResult = -1;
              }
              break;
          }
          return comparatorResult * (sort.direction == 'asc' ? 1 : -1);
        });
      };
    return sortFunction;
  }
  private searchDialogRef: any;
  openHistoryDialog(element) {
    this.searchDialogRef = this.searchDialog.open(ProductSearchDialogComponent,
      {
        autoFocus: true,
        disableClose: false,
        panelClass: 'product-search-component',
        data: { element: element }
      });
  }

}
