<style>
  .input-label {
    display: block;
    font-weight: bold;
    font-size: 0.875rem; /* This makes the text smaller. Adjust as needed. */
    margin-bottom: 0.5em; /* Adds space between the label and input */
  }
</style>

<button mat-icon-button class="close-button" (click)="onDismiss()">
    <mat-icon class="close-icon" >close</mat-icon>
</button>
<h1 class="heading-text"  mat-dialog-title>{{title}}</h1>
<form  #f="ngForm" >
  <div>
    <div class="d-flex">
      <div>
        <label class="input-label">Name</label>
        <div class="custom-input-box create-form-group-width-set">
          <input matInput type="text" [(ngModel)]="rewardGame.name" #name="ngModel" [ngClass]="{'is-invalid': f.submitted && name.invalid }" name="name" placeholder="Name*" required />
        </div>
        <mat-error class="mt-1" *ngIf="f.submitted && name.invalid">Reward Game requires a Name.</mat-error>
      </div>
      <div>
        <label class="input-label" style="margin-left:1em">Description</label>
        <div class="ml-3 custom-input-box create-form-group-width-set">
          <input matInput type="text" [(ngModel)]="rewardGame.description" #description="ngModel" name="description" placeholder="Description" />
        </div>
      </div>
    </div>
    <div class="d-flex mt-3">
      <div>
        <label class="input-label">Metric</label>
        <div class="custom-input-box create-form-group-width-set">
          <mat-select placeholder="Select Metric*" [ngClass]="{'is-invalid': f.submitted && formulaId.invalid }" [(ngModel)]="rewardGame.formulaId" #formulaId="ngModel" name="formulaId" required (selectionChange)="onFormulaSelected($event)">
            <mat-option *ngFor="let formula of formulas" [value]="formula.id">{{formula.name}}</mat-option>
          </mat-select>
        </div>
        <mat-error *ngIf="f.submitted && formulaId.invalid">Reward Game requires a Formula.</mat-error>
      </div>
      <div>
        <label class="input-label" style="margin-left:1em">Target</label>
        <div class="ml-3 custom-input-box create-form-group-width-set">

          <input matInput type="number" maxlength="5" [(ngModel)]="rewardGame.target" #target="ngModel" name="target" placeholder="Target" value="1" />
        </div>
      </div>
    </div>

    <div class="d-flex mt-4">
      <mat-label>Group Type</mat-label>
      <mat-radio-group [(ngModel)]="rewardGame.multipleEmployees" #multipleEmployees="ngModel" name="multipleEmployees" (change)="rewardGame.resetEmployeeList()">
        <mat-radio-button class="margin-left-1em" [value]="true">Employee List</mat-radio-button>
        <mat-radio-button class="margin-left-1em" [value]="false">Manager Only</mat-radio-button>
      </mat-radio-group>
    </div>
    <!--  MANAGER SELECTION -->

    <div class="d-flex mt-4" *ngIf="!rewardGame.multipleEmployees">
      <div>
        <div class="custom-input-box create-form-group-width-set">
          <mat-select placeholder="Manager Name*" [ngClass]="{'is-invalid': f.submitted && managerId.invalid }" [(ngModel)]="rewardGame.managerId" #managerId="ngModel" name="managerId" required>
            <mat-option *ngFor="let employee of employees" [value]="employee.id">{{employee.user.name}}</mat-option>
          </mat-select>
        </div>
        <mat-error *ngIf="f.submitted && managerId.invalid">Reward Game of Manager Type requires a manager.</mat-error>
      </div>
      <div class="ml-3">
        <div class="custom-input-box create-form-group-width-set">
          <mat-select placeholder="Store*" [ngClass]="{'is-invalid': f.submitted && managerLocationId.invalid }" [(ngModel)]="rewardGame.managerLocationId" #managerLocationId="ngModel" name="managerLocationId" required>
            <mat-option *ngFor="let location of locations" [value]="location.id">{{location.name}}</mat-option>
          </mat-select>
        </div>
        <mat-error *ngIf="f.submitted && managerLocationId.invalid">Reward Game of Manager Type requires a store.</mat-error>
      </div>

    </div>

    <div class="d-flex mt-3">
      <mat-checkbox [(ngModel)]="rewardGame.isEveryPerson" #isEveryPerson="ngModel" name="isEveryPerson" class=" vertical-center create-form-group-width-set" [labelPosition]="'after'">Automatic Add Everyone On Repeat</mat-checkbox>
      <mat-checkbox [(ngModel)]="rewardGame.isEveryStore" #isEveryStore="ngModel" name="isEveryStore" class="margin-left-1em vertical-center" [labelPosition]="'after'">Automatic Add Every Store On Repeat</mat-checkbox>
    </div>
    <div class="d-flex mt-4" *ngIf="!rewardGame.isEveryPerson">
      <div class="custom-input-box create-form-group-width-set">
        <mat-select placeholder="Select Employees" multiple [(value)]="rewardGame.employeeList" (selectionChange)="selectEmployee($event)">
          <mat-option *ngFor="let employee of employees" [value]="employee">{{employee.user.name}}</mat-option>
        </mat-select>
      </div>
      <div class="custom-input-box create-form-group-width-set ml-3">
        <mat-select placeholder="Select Stores" multiple [(value)]="rewardGame.locationList" (selectionChange)="selectLocation($event)">
          <mat-option *ngFor="let location of locations" [value]="location">{{location.name}}</mat-option>
        </mat-select>
      </div>

    </div>
    <!-- MULTIPLE EMPLOYEES -->
    
    <div class="mt-3 custom-input-box" *ngIf="isEdit">
      <mat-label>Status</mat-label>
      <mat-select style="text-align: center;" placeholder="Select Status" [(ngModel)]="rewardGame.status">
        <mat-option *ngFor="let status of rewardGameStatusesKeys" [value]="status">{{rewardGameStatuses[status]}}</mat-option>
      </mat-select>
    </div>
    
    <div class="mt-3 custom-input-box" *ngIf="rewardGame.multipleEmployees">
      <mat-label>Type</mat-label>
      <mat-select style="text-align: center;" placeholder="Reward Type" [(ngModel)]="rewardGame.type" #type="ngModel" name="type" (selectionChange)="changeGameType($event)">
        <mat-option *ngFor="let type of rewardGameTypesKeys" [value]="type">{{rewardGameTypes[type]}}</mat-option>
      </mat-select>
    </div>
    <div class="mt-3 custom-input-box" *ngIf="!rewardGame.multipleEmployees">
      <mat-label>Type</mat-label>
      <mat-select style="text-align: center;" placeholder="Reward Type" [(ngModel)]="rewardGame.type" #type="ngModel" name="type" (selectionChange)="changeGameType($event)">
        <mat-option *ngFor="let type of managerRewardGameTypesKeys" [value]="type">{{rewardGameTypes[type]}}</mat-option>
      </mat-select>
    </div>

    <h3 class="heading-text mt-3">{{rewardGameTypes[rewardGame.type]}}</h3>

    <div class="d-flex flex-wrap  form-width-set " *ngIf="rewardGameTypes[rewardGame.type] == 'Competition'">
      <div class="mb-3 custom-input-box create-form-group-width-set">
        <input placeholder="Winner Count" matInput type="number" max="10" min="1" [(ngModel)]="rewardGame.winnerCount" #winnerCount="ngModel" name="winnerCount" (keyup)="rewardGame.updateWinnerCount()" />
      </div>
      <ng-container *ngIf="rewardGame.winnerArray">
        <ng-container *ngFor="let winners of rewardGame.winnerArray; let i = index;">
          <div class="mb-3 custom-input-box create-form-group-width-set" [ngClass]="{'ml-3':i%2==0}">
            <input matInput [placeholder]="'Winner Table '+(i+1)+' ($)'" type="number" [(ngModel)]="rewardGame.winnerArray[i].prize" #winner{{rewardGame.winnerArray[i].rank}}="ngModel" name="winner{{rewardGame.winnerArray[i].rank}}">
          </div>

        </ng-container>
      </ng-container>
    </div>

    <div class="d-flex" [hidden]="rewardGameTypes[rewardGame.type] !== 'ForEach' && rewardGameTypes[rewardGame.type] !== 'Interval' && rewardGameTypes[rewardGame.type] !== 'SingleGoal'">
      <div>
        <mat-label [hidden]="rewardGameTypes[rewardGame.type] !== 'ForEach' && rewardGameTypes[rewardGame.type] !== 'Interval'">Pay Per</mat-label>
        <mat-label [hidden]="rewardGameTypes[rewardGame.type] !== 'SingleGoal'">Pay</mat-label>
        <div class="custom-input-box create-form-group-width-set">
          <input matInput type="number" maxlength="4" [(ngModel)]="rewardGame.payPer" #payPer="ngModel" name="payPer" />
        </div>
      </div>
      <div class="ml-3">
        <mat-label [hidden]="rewardGameTypes[rewardGame.type] !== 'ForEach'">Minimum count</mat-label>
        <mat-label [hidden]="rewardGameTypes[rewardGame.type] !== 'Interval'">Interval</mat-label>
        <mat-label [hidden]="rewardGameTypes[rewardGame.type] !== 'SingleGoal'">Goal</mat-label>
        <div class="custom-input-box create-form-group-width-set ">
          <input matInput type="number" maxlength="4" (change)="onValueChange($event)" [(ngModel)]="rewardGame.minimumCount" #minimumCount="ngModel" name="minimumCount" />
        </div>
        <span *ngIf="rewardGameTypes[rewardGame.type] == 'ForEach' && (rewardGame.payMinimumCount)">
          Starting Pay $
          {{ cleanMath(rewardGame.minimumCount * rewardGame.payPer) }}
        </span>


      </div>
    </div>

    <div class="d-flex mt-3 align-items-center">
      <div>
        <div [hidden]="rewardGameTypes[rewardGame.type] !== 'ForEach' && rewardGameTypes[rewardGame.type] !== 'Interval'">
          <mat-label [hidden]="rewardGameTypes[rewardGame.type] !== 'ForEach'">Maximum count</mat-label>
          <mat-label [hidden]="rewardGameTypes[rewardGame.type] !== 'Interval'">Total Intervals</mat-label>
          <div class="custom-input-box create-form-group-width-set">
            <input matInput type="number" maxlength="4" [(ngModel)]="rewardGame.maximumCount" #maximumCount="ngModel" name="maximumCount" />
          </div>
          <span>
            {{ rewardGameTypes[rewardGame.type] == 'ForEach' && (!rewardGame.payMinimumCount) ? 'Total Payable $' + cleanMath((rewardGame.maximumCount - rewardGame.minimumCount) * rewardGame.payPer) : rewardGameTypes[rewardGame.type] == 'ForEach'? 'Total Payable $' + cleanMath(rewardGame.maximumCount * rewardGame.payPer) : '' }}
            {{ rewardGameTypes[rewardGame.type] == 'Interval' ? 'Total Payable $' + cleanMath((rewardGame.maximumCount-rewardGame.doNotPayBeforeIntervals) * rewardGame.payPer) : '' }}
          </span>
        </div>
      </div>
      <div class="ml-3">
        <div class="d-flex align-items-center" [hidden]="rewardGameTypes[rewardGame.type] !== 'ForEach'">
          <mat-label [hidden]="rewardGameTypes[rewardGame.type] !== 'ForEach'">Pay For Minimum Count</mat-label>
          <mat-radio-group [(ngModel)]="rewardGame.payMinimumCount" #payMinimumCount="ngModel" name="payMinimumCount">
            <mat-radio-button class="margin-left-1em" [value]="true">Yes</mat-radio-button>
            <mat-radio-button class="margin-left-1em" [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
        <div [hidden]="rewardGameTypes[rewardGame.type] !== 'Interval'">
          <mat-label [hidden]="rewardGameTypes[rewardGame.type] !== 'Interval'">Skip This Many Intervals For Pay</mat-label>
          <div class="custom-input-box create-form-group-width-set">
            <input matInput type="number" maxlength="4" [(ngModel)]="rewardGame.doNotPayBeforeIntervals" #doNotPayBeforeIntervals="ngModel" name="doNotPayBeforeIntervals" />
          </div>
          <span>
            Pay From : {{ cleanMath((rewardGame.doNotPayBeforeIntervals+1) * rewardGame.minimumCount)}} to {{  cleanMath((rewardGame.maximumCount) * rewardGame.minimumCount) }}
          </span>

        </div>
      </div>
    </div>



    <div class="d-flex align-items " [ngClass]="{'mt-4': rewardGameTypes[rewardGame.type] !== 'Competition'}">
      <div class="d-flex aling-items-center create-form-group-width-set ">
        <mat-label>Repeats</mat-label>
        <mat-radio-group [(ngModel)]="rewardGame.recurring" #recurring="ngModel" name="recurring">
          <mat-radio-button class="margin-left-1em" [value]="true">Yes</mat-radio-button>
          <mat-radio-button class="margin-left-1em" [value]="false">No</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="ml-3 d-flex align-items-center">
        <mat-label>Monthly</mat-label>
        <mat-radio-group [(ngModel)]="rewardGame.monthly" #monthly="ngModel" name="monthly" [disabled]="blockNonMonthly">
          <mat-radio-button class="margin-left-1em" [value]="true">Yes</mat-radio-button>
          <mat-radio-button class="margin-left-1em" [value]="false">No</mat-radio-button>
        </mat-radio-group>

      </div>
    </div>

    <div class="input-group mt-4" *ngIf="rewardGame.monthly">
      <mat-form-field class="bottom-border-without-bg w-100">
        <mat-label class="form-label semibold">Month</mat-label>
        <input matInput type="month" [(ngModel)]="createTemplate.monthlyDate" #monthlyDuration="ngModel" name="monthlyDuration" (change)="setDatesUsingMonth()" required>
      </mat-form-field>
    </div>
    <div class=" d-flex mt-4" *ngIf="!rewardGame.monthly">
      <mat-form-field class="bottom-border-without-bg create-form-group-width-set">
        <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="startDate" [(ngModel)]="createTemplate.startDate" #startDateA="ngModel" name="startDateA" placeholder="Choose a start date" (dateChange)="startDateChanged('change', $event)" required>
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate startView="year"></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="bottom-border-without-bg create-form-group-width-set ml-3">
        <input matInput [min]="createTemplate.startDate" [max]="maxDate" [matDatepicker]="endDate" [(ngModel)]="createTemplate.endDate" #endDateA="ngModel" name="endDateA" placeholder="Choose a end date" (dateChange)="endDateChanged('change', $event)" required>
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate startView="year" [startAt]="createTemplate.startDate"></mat-datepicker>
      </mat-form-field>
    </div>

  </div>
</form>
<div class="d-flex align-items-center mt-4 pt-1">
  <button mat-raised-button color="primary" class="submit-btn" (click)="onConfirm()">Submit</button>
  <button mat-button class="clear-btn ml-3" (click)="onDismiss()">Clear</button>

</div>
