import { DateRange } from '../models/date-range';
import { DateRange as DateRangeMoment } from 'moment-range';

export class InvoiceViewerSearchRequest {
  HasNotFilter: any;
  HasFilter: any;
  SearchTerm: string;
  DateRange: DateRange;
  DateRangeMoment: DateRangeMoment;
  HideZeroGrossProfitItems: boolean;
}
