import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgotpassword/forgotpassword.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from '../core/core.module';
import { ResetPasswordComponent } from './resetpassword/resetpassword.component';
import { MaterialModule } from '../home/material.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CoreModule,
    HttpClientModule,
    AuthenticationRoutingModule,
    MaterialModule
  ],
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent
  ]
})
export class AuthenticationModule { }
