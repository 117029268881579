import { Component, OnInit, OnDestroy, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { Chart } from 'chart.js';
import * as moment from 'moment';
import { LocationService } from '../services/location.service';
import { EmployeeService } from '../services/employee.service';
import { ClientService } from '../services/client.service';

import { ReportService } from '../services/report.service';
import { Seller } from '../models/seller';
import { CommissionUserService } from '../services/commission-user.service';
import { MtdReportService } from '../services/mtd-report.service';
import { RankContest } from '../models/rank-contest';
import { AuthService } from '../../core/services/auth.service';
import { NotifierService } from 'angular-notifier';
import { CommissionUser } from '../models/commission-user';
import { CommissionGroup } from '../models/commission-group';
import { CommissionFormula } from '../models/commission-formula';
import { CommissionPeg } from '../models/commission-peg';
import { LocationTypeCode } from '../models/location-type-code';
import { ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import { MatTableDataSource } from '@angular/material/table';
import { RewardService } from '../services/reward-service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RewardGameType } from '../models/reward-game-type';
import { UserService } from '../users/services/user.service';
import { DurationService } from '../services/duration.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from "../dialogs/confirm/confirm-dialog.component";

const CONFIG_CLIENT_KEY = 'ConfigClient';
const CONFIG_REGION_KEY = 'ConfigRegion';
const CONFIG_DISTRICT_KEY = 'ConfigDistrict';
const CONFIG_STORE_KEY = 'ConfigStore';
const CONFIG_EMPLOYEE_KEY = 'ConfigEmployee';

@Component({
  selector: 'app-performance-summary',
  templateUrl: './performance-summary.component.html'
})
export class PerformanceSummaryComponent implements OnInit, OnDestroy {
  dialogRef: any;
  clients: any[] = [];
  clientId: number;
  selectedClientId: number;
  selectedClient: any;
  clientName: string;
  regions: any[] = [];
  selectedRegionId: number;
  selectedRegion: any;
  districts: any[] = [];
  selectedDistrictId: number;
  selectedDistrict: any;
  stores: any[] = [];
  selectedStoreId: number;
  selectedStore: any;
  selectedDuration: any;
  currentMonthYear: any;
  employees: any[] = [];
  selectedEmployee: any;
  selectedEmployeeId: number;
  groups: CommissionGroup[];
  selectedGroup: any;
  selectedCommissionUser: any;
  users: CommissionUser[];
  combinedStoreIds: number[] = [];
  commissionUsersGroupIds: string;
  previousMonthSales: any;
  previousYearSales: any;
  showYOYChartForUser = false;
  selectedSeller: Seller;
  dateLastMonth = new Date();
  dateNow = new Date();
  dateLastYear = new Date();
  subscriptionToEmployees: Subscription;
  subscriptionToDistricts: Subscription;
  subscriptionToActualClient: Subscription;
  userHasNoGoals: boolean;
  localStorageLoadingInProgress: boolean;
  loadingInProgress = false;
  loadingRankInProgress = false;
  reports: RankContest[];
  selectedReportId: number;
  selectedReport: RankContest;
  highestGoalTier = 0;
  dataFound = false;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  debug = false;
  selectedTabIndex = 0;
  monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'];

  tooltipCallbacks: any = {
    title: function () {

      return 'Pay / Goal';
    },
    label: function (tooltipItem) {
      const index = tooltipItem['dataIndex'];
      const datasetIndex = tooltipItem['datasetIndex'];
      const dataSet = tooltipItem.dataset;

      const labels = tooltipItem.dataset.label.split('-');
      for (let i = 0; i <= labels.length - 1; i++) {
        labels[i] = parseFloat(labels[i]).toFixed(4);
      }
      let newResponse = "";
      let labelNumber = 0;
      let goalNumber = 0;

      for (let i = 0; i <= index; i++) {
        labelNumber += parseFloat(labels[i]);
        goalNumber += parseFloat(dataSet['data'][i]);
      }

      newResponse = index >= 1
        ? (labelNumber * 100).toFixed(2) + '% / ' +
        (goalNumber).toFixed(2)

        : (labels[index] * 100).toFixed(2) + '% / ' + (dataSet['data'][index]).toFixed(2);

      return newResponse;
    }
  };

  constructor(
    private _locationService: LocationService,
    private _commissionUserService: CommissionUserService,
    private _employeeService: EmployeeService,
    private _userService: UserService,
    private _mtdReportService: MtdReportService,
    private _notifier: NotifierService,
    private _authService: AuthService,
    private _reportService: ReportService,
    private _clientService: ClientService,
    private _loaderService: LoaderService,
    private _rewardService: RewardService,
    private _durationService: DurationService, private matDialog: MatDialog) { }

  ngOnDestroy() {
    console.log("performance summary component ngOnDestroy ");
    this.destroyCharts();
    this.subscriptionToEmployees.unsubscribe();
    this.subscriptionToDistricts.unsubscribe();
    this.subscriptionToActualClient.unsubscribe();
    this.selectedGroup = null;
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
    this.dialogRef?.close();
  }
  destroyCharts() {
    return;
    if (this.selectedGroup)
      if (this.selectedGroup.commissionFormulas)
        this.selectedGroup.commissionFormulas.forEach((goal, index) => {
          if (goal.barChartData) {
            goal.barChartData.destroy();
            //console.log("bar chart destroyed");
          }
          if (goal.chartData) {
            goal.chartData.destroy(); //console.log("chart Data destroyed");
          }
          if (goal.barYOYChartData) {
            goal.barYOYChartData.destroy(); //console.log("bar YOY ChartData destroyed");
          }
        });
  }
  async ngOnInit() {
    this.subscriptionToActualClient = this._locationService.currentActualClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        this.clientHasLoaded(message);
      });
    Chart.defaults.plugins.datalabels.display = false;
    this.dateLastMonth.setMonth(this.dateLastMonth.getMonth() - 1);
    this.dateLastYear.setMonth(this.dateLastYear.getMonth() - 12);
    this.getClients();
    this.clientId = this.selectedClientId;
    this.selectedDuration = moment().format('YYYY-MM');
    this.currentMonthYear = moment().format('YYYY-MM');
    this.selectedEmployeeId = 0;
  }

  wait(ms) {
    return new Promise((resolve, reject) => setTimeout(resolve, ms));
  }

  waitForBothVariablesToLoad() {
    if (!this.loadingRankInProgress && this.loadingInProgress) {
      //this._loaderService.hide();
    } else {
      setTimeout(() => {
        this.waitForBothVariablesToLoad();
      }, 250);
    }
  }



  checkRewardsPermission() {
    return this._authService.hasRewardDashboardPermission();
  }

  clientHasLoaded(message: number) {
    if (message && message !== 0 && this.selectedClientId !== message) {
      this.selectedClientId = message;
      this.subscriptionToDistricts = this._locationService.currentDistrict
        .pipe(takeUntil(this.destroyed$))
        .subscribe(message1 => {
          this.selectedDistrictId = message1; this.districtHasLoaded();
        });
    }
  }
  districtHasLoaded() {
    if (this.selectedDistrictId && this.selectedDistrictId !== 0) {
      if (this.debug) {
        console.log('District has Loaded' + this.selectedDistrictId);
      }
      this.getReportsFirstLoad();
    }
  }

  onReportSelected() {
    if (this.debug) {
      console.log('Store Default Report 2');
    }
    this.destroyCharts();
    this._reportService.storeDefaultRankReport(() => {
      this.getGoalGroups();
      this.waitForBothVariablesToLoad();
      //this.loadingInProgress = false;
      // this._loaderService.hide();
    }, +this.selectedDistrictId, this.selectedDuration, this.selectedClientId, this.selectedReportId);
  }

  getReportsFirstLoad() {
    if (this.selectedClientId != null) {
      this._reportService.getReportsByUser(this.selectedClientId).subscribe((response: RankContest[]) => {
        this.reports = response;
        //console.log(this.reports);
        this.selectedReport = this.reports[0];
        this.selectedReportId = this.reports[0].id;
        this._employeeService.getAllEmployees().subscribe((responseEmp) => {
          this.employees = responseEmp;
          this._locationService.getLocationsByClientId(this.selectedClientId, () => {

            // this.determinePermissions(() => {
            if (this.debug) {
              console.log('Store Default Report 1');
            }
            if (!this.loadingInProgress) {
              this.loadingRankInProgress = true;
              this._loaderService.show();
              this.waitForBothVariablesToLoad();
              this.loadingInProgress = true;

              this._reportService.storeDefaultRankReport(() => {
                // this.getGoalGroups();
                this.loadingRankInProgress = false;
                this.subscriptionToEmployees = this._locationService.currentEmployee
                  .pipe(takeUntil(this.destroyed$))
                  .subscribe(message1 => {
                    this.selectedEmployeeId = message1; this.onEmployeeOrDurationSelected();
                  });
              }, +this.selectedDistrictId, this.selectedDuration, this.selectedClientId, this.selectedReportId);
              // });
            }
          });
        });
        // if (this.selectedDistrictId && this.selectedDistrictId !== 0) {
        // setTimeout(() => { this.onReportSelected(); }, 2000);
        // }
      }, (error) => this._notifier.notify('error', error.message), () => { });
    }
  }

  getRankString(code: string, id?: any) {
    if (id) {
      return this._reportService.evaluateFinalRank(this._locationService.getLocationIdByName(id), code);
    } else {
      return this._reportService.evaluateFinalRank(+this.selectedEmployeeId, code);
    }
  }

  getClients() {
    this._locationService.getLocations(LocationTypeCode.Client, (response) => {
      this.clients = response;
    });
  }

  onClientSelected() {
    if (+this.selectedClientId === 0) {
      this.regions = null;
      this.selectedRegionId = 0;
      this.districts = null;
      this.selectedDistrictId = 0;
      this.stores = null;
      this.selectedStoreId = 0;
      this.employees = null;
      this.selectedEmployeeId = 0;
      this.selectedGroup = null;
      this.groups = [];
      return;
    }

    this.selectedClient = this.clients.find(r => r.id === +this.selectedClientId);
    this._reportService.getFormulas(+this.selectedClient.clientId, () => { });
    this._locationService.getLocations(LocationTypeCode.Region, (response) => {
      this.regions = response;
    }, this.selectedClient.id);
  }


  onStoreSelected() {
    if (+this.selectedStoreId === 0) {
      this.groups = [];
      this.selectedEmployeeId = 0;
      this.selectedEmployee = null;
      this.selectedGroup = null;
      this.filterEmployees(false, +this.selectedDistrictId);
      return;
    }
    this.groups = [];
    this.selectedEmployeeId = 0;
    this.selectedEmployee = null;
    this.selectedGroup = null;
    this.selectedStore = this.stores.find(r => r.id === +this.selectedStoreId);
    this.getEmployees(true, +this.selectedStoreId);
  }


  filterEmployees(isStoreLocation: boolean, location?: number) {
    if (isStoreLocation) {
      this._employeeService.getEmployeesByLocationCallback((response) => {
        this.employees = response;
      }, location);
    } else {
      this._employeeService.getEmployeesByLocationsCallback((response) => {
        this.employees = response;
      }, this._locationService.filterLocationIdsByTypeAndParent(4, location));

    }
  }

  getEmployees(isStoreLocation: boolean, location?: number) {


    this.employees = this._employeeService.employees;

    if (isStoreLocation) {
      this._employeeService.getEmployeesByLocationCallback((response) => {
        this.employees = response;
      }, location);
    } else {
      this._employeeService.getEmployeesByLocationsCallback((response) => {
        this.employees = response;
      }, this._locationService.filterLocationIdsByTypeAndParent(4, location));
    }
  }



  async onDurationSelected() {
    this.destroyCharts();
    this.highestGoalTier = 0;
    this.dateNow.setMonth(this.selectedDuration.substr(5, 2));
    this.dateNow.setMonth(this.dateNow.getMonth() - 1);
    this.dateNow.setFullYear(this.selectedDuration.substr(0, 4));
    this.dateLastMonth = new Date(this.dateNow);
    this.dateLastYear = new Date(this.dateNow);
    this.dateLastMonth.setMonth(this.dateNow.getMonth() - 1);
    this.dateLastYear.setFullYear(this.dateNow.getFullYear() - 1);
    this.loadingInProgress = true;
    this._loaderService.show();
    this._employeeService.getAllEmployees().subscribe((response) => {
      this.employees = response;
      this._locationService.getLocationsByClientId(this._authService.clientId(), () => {
        this.loadingRankInProgress = true;
        this._reportService.storeDefaultRankReport(() => {
          this.waitForBothVariablesToLoad()
          this.getGoalGroups();
          this.loadingRankInProgress = false;
          //this._loaderService.hide();
        }, +this.selectedDistrictId, this.selectedDuration, this.selectedClientId, this.selectedReportId);
      });
    });
  }

  onEmployeeOrDurationSelected() {
    this.destroyCharts();
    this.highestGoalTier = 0;
    if (!this.selectedEmployeeId || + this.selectedEmployeeId === 0) {
      this.selectedGroup = null;
      this.selectedEmployee = null;
      this.groups = [];
      this._loaderService.hide();
      return;
    }
    this.selectedGroup = null;
    this.selectedEmployee = this.employees.find(r => r.id === +this.selectedEmployeeId);
    this.waitForBothVariablesToLoad();
    this.getGoalGroups();
    if (this.checkRewardsPermission()) {
      this.getRewardsAndClaims();
    }

  }



  getGoalGroups() {
    this.groups = [];
    this._loaderService.show();
    this._commissionUserService.getCommissionUser(this.selectedClientId, (response) => {
      if (response) {
        this.loadingInProgress = true;
        this._loaderService.show();
        this.userHasNoGoals = false;
        this.selectedCommissionUser = response;
        this.groups = this.selectedCommissionUser.commissionGroups;
        this.commissionUsersGroupIds = this.groups.map(g => g.id).join('|');
        this.reformatCommissionGroups();
        const duration = this._mtdReportService.getDurationForOneMonth(this.dateLastMonth.getFullYear() +
          '-' + ('0' + (this.dateLastMonth.getMonth() + 1)).slice(-2));
        const durationLastYear = this._mtdReportService.getDurationForOneMonth(this.dateLastYear.getFullYear() +
          '-' + ('0' + (this.dateLastYear.getMonth() + 1)).slice(-2));


        this._mtdReportService.getCommissionGroupReport(duration, this.selectedCommissionUser.employeeId, this.commissionUsersGroupIds)
          .subscribe((lastMonthResponse: any[]) => {
            this.previousMonthSales = lastMonthResponse['commissionGroups'];
            this._mtdReportService.
              getCommissionGroupReport(durationLastYear, this.selectedCommissionUser.employeeId, this.commissionUsersGroupIds)
              .subscribe((lastYearResponse: any[]) => {
                this.previousYearSales = lastYearResponse['commissionGroups'];
                this.showYOYChartForUser = this.previousYearSales.find(x => x.target === 'self') ?
                  this.previousYearSales.find(x => x.target === 'self').skuGroups[0].gp > 0 : false;
                this.calculateForEachCommissionGroup();

                this.selectedGroup = this.groups[0];
                this.getGoalReport();
                this.loadingInProgress = false;
              });
          }, (errorResponse) => { this._loaderService.hide(); }, () => { });
      } else {
        this._loaderService.hide();
        this.loadingInProgress = false;
        this.userHasNoGoals = true;
      }
    },
      +this.selectedEmployeeId, this.selectedDuration);
  }

  reformatCommissionGroups() {
    const tempGroups: CommissionGroup[] = [];

    if (this.groups.find(goals => goals.target === 'self')) {
      tempGroups.push(this.groups.find(goals => goals.target === 'self'));
    }
    if (this.groups.filter(goals => goals.target !== 'self').length > 1) {
      const combinedSales = new CommissionGroup();
      combinedSales.target = 'Combined Sales';
      tempGroups.push(combinedSales);

    }
    if (this.groups.length > 0) {
      this.groups.forEach((group) => {
        if (group.target !== 'self') {
          tempGroups.push(group);
          this.combinedStoreIds.push();
        }
      });
    }
    this.groups = tempGroups;
  }

  calculateForEachCommissionGroup() {
    this.dataFound = false;
    var hourFraction = (20 - moment().hour()) / 20;
    if (hourFraction < 0) hourFraction = 0;
    let trendingCounter = +((moment().daysInMonth()) / (moment().date() - hourFraction)).toFixed(2);
    //let trendingCounter = +(moment().daysInMonth() / moment().date()).toFixed(2);
    if (this.selectedDuration !== this.currentMonthYear) {
      trendingCounter = 1;
    }
    let groupCode = '';
    this.groups.filter(x => x.target !== 'Combined Sales').forEach((group) => {
      if (!group.commissionFormulas || group.commissionFormulas.length < 1) {
        return;
      }
      this.dataFound = true;
      group.commissionFormulas = group.commissionFormulas.
        sort((a, b) => a.formula.id < b.formula.id ? -1 : a.formula.id > b.formula.id ? 1 : 0);
      group.commissionFormulas[0].commissionPegs = group.commissionFormulas[0].commissionPegs.
        sort((a, b) => a.goal < b.goal ? -1 : a.goal > b.goal ? 1 : 0);
      let seller: any = null;
      if (group.target === 'self') {
        groupCode = 'B';
        seller = this._reportService.selectedReport.GroupB.sellers.
          find(sellers => sellers.employeeId === +this.selectedEmployeeId);
      } else {
        groupCode = 'A';
        seller = this._reportService.selectedReport.GroupA.sellers.
          find(sellers => sellers.locationId === this._locationService.getLocationIdByName(group.target));
      }

      const grossProfit = seller.formulas != null ? seller.formulas.skuGroups.find(x => x.name === 'GrossProfit').profit : 0;
      const trendingGrossProfit = grossProfit * trendingCounter;
      const grossProfitMax = group.commissionFormulas[0]
        .commissionPegs[group.commissionFormulas[0].commissionPegs.length - 1].goal;

      group.earnedPay = grossProfit * group.basePercentage;
      group.trendingEarned = trendingGrossProfit * group.basePercentage;
      group.maxEarnable = grossProfitMax * group.basePercentage;

      group.commissionFormulas.forEach((goal: any, index) => {
        goal.commissionPegs = goal.commissionPegs.sort((a, b) => a.goal < b.goal ? -1 : a.goal > b.goal ? 1 : 0);
        goal.value = Math.round(+this._reportService.evaluateFormula(goal.formula, seller, groupCode) * 1e2) / 1e2;
        goal.trendingValue = goal.formula.trendingPossible ? goal.value * trendingCounter : goal.value;
        goal.trendingValueForChart = goal.formula.trendingPossible ? Math.round((goal.value * (trendingCounter - 1)) * 1e2) / 1e2 : 0;
        goal.estimatedPay = this.calculateEstimatedPay(goal.commissionPegs, goal.growth, goal.value);
        goal.estimatedTrendingPay = this.calculateEstimatedPay(goal.commissionPegs, goal.growth, goal.trendingValue);
        goal.highestPeg = goal.commissionPegs[goal.commissionPegs.length - 1].pay;
        group.earnedPay += grossProfit * goal.estimatedPay;
        group.trendingEarned += trendingGrossProfit * goal.estimatedTrendingPay;
        group.maxEarnable += goal.highestPeg * grossProfitMax;
        const previousSales = this.previousMonthSales.find(x => x.id === group.id);
        const previousYearSales = this.previousYearSales.find(x => x.id === group.id);
        goal.lastMonthValue = +this._mtdReportService.CalculateFormulaValue(previousSales.skuGroups,
          previousSales.conversionData, goal.formula.formulaValue, previousSales.hoursWorkedData).toFixed(2);
        goal.lastYearValue = +this._mtdReportService.CalculateFormulaValue(previousYearSales.skuGroups,
          previousYearSales.conversionData, goal.formula.formulaValue, previousYearSales.hoursWorkedData).toFixed(2);
      });

    });

    if (this.groups.find(x => x.target === 'Combined Sales')) {
      const combinedSalesGroup = this.groups.find(x => x.target === 'Combined Sales');
      combinedSalesGroup.earnedPay = 0;
      combinedSalesGroup.trendingEarned = 0;
      combinedSalesGroup.maxEarnable = 0;
      combinedSalesGroup.commissionFormulas = [];
      let storeCount = 0;
      this.groups.filter(x => x.target !== 'Combined Sales' && x.target !== 'self').forEach((group) => {
        storeCount++;
        group.commissionFormulas.forEach((goal) => {
          if (!combinedSalesGroup.commissionFormulas.find(x => x.formulaId === goal.formulaId)) {
            const newGoal: CommissionFormula = new CommissionFormula();
            newGoal.formula = goal.formula;
            newGoal.formulaId = goal.formulaId;
            newGoal.value = goal.value;
            newGoal.lastMonthValue = goal.lastMonthValue;
            newGoal.lastYearValue = goal.lastYearValue;
            newGoal.trendingValue = goal.trendingValue;
            newGoal.trendingValueForChart = goal.trendingValueForChart;
            combinedSalesGroup.commissionFormulas.push(newGoal);
          } else {
            const thisGoal = combinedSalesGroup.commissionFormulas.find(x => x.formulaId === goal.formulaId);
            thisGoal.value += goal.value;
            thisGoal.lastMonthValue += goal.lastMonthValue;
            thisGoal.lastYearValue += goal.lastYearValue;
            thisGoal.trendingValue += goal.trendingValue;
            thisGoal.trendingValueForChart += goal.trendingValueForChart;
          }
        });

        combinedSalesGroup.earnedPay += group.earnedPay;
        combinedSalesGroup.trendingEarned += group.trendingEarned;
        combinedSalesGroup.maxEarnable += group.maxEarnable;
      });

      combinedSalesGroup.commissionFormulas.forEach((goal) => {
        if (!goal.formula.trendingPossible) {
          goal.value = goal.value / storeCount;
          goal.lastMonthValue = goal.lastMonthValue / storeCount;
          goal.lastYearValue = goal.lastYearValue / storeCount;
        }
      });
    }
  }

  selectedTabChange(event) {
    this.dataSourceRewards.paginator = this.paginator.toArray()[0];
    this.dataSourceClaims.paginator = this.paginator.toArray()[1];
    this.selectedGroup = (event != null) ? this.groups[event.index] : this.groups[0];
    setTimeout(() => { this.getGoalReport(); }, 400);
  }


  getGoalReport() {
    if (this.selectedGroup) {
      const self = this;
      setTimeout(() => {
        self.selectedGroup.commissionFormulas = self.selectedGroup.commissionFormulas.map((goal) => {
          if (goal.commissionPegs) {
            const currentPeg = { 'goal': +goal.value.toFixed(2), 'pay': goal.estimatedPay };
            const payAndGoalData = self.calculateGoalAndPayData(goal.commissionPegs, currentPeg, goal.formula.trendingPossible);
            const goals = self.getChartData(payAndGoalData, 'goal');
            const goalLabels = self.getChartData(payAndGoalData, 'goalLabel');
            const pays = self.getChartData(payAndGoalData, 'pay');
            const newDataJoined = [pays, goalLabels];
            goal.chartData = {
              type: 'doughnut',
              data: {
                labels: [],
                datasets: [
                  {
                    data: goals[0],
                    label: pays[0].join('-'),
                    backgroundColor: ['#3878E0', '#29B13F', '#ccc'], // blue green grey
                    hoverBackgroundColor: ['#3878E0', '#29B13F', '#ccc'],
                    hoverBorderColor: 'transparent',
                    // borderWidth: 20,
                    borderRadius: 5,
                  },
                  {
                    data: goals[1],
                    label: pays[1].join('-'),
                    backgroundColor: ['#FF594E', '#C64EFF', '#FFB84E', 'purple'], //red, orange, yellow, purple
                    hoverBackgroundColor: ['#FF594E', '#C64EFF', '#FFB84E', 'purple'],
                    hoverBorderColor: 'transparent',
                    // borderWidth: 20,
                    borderRadius: 5,
                  },
                ]
              },
              options: {
                plugins: {
                  legend: {
                    display: false
                  },
                  tooltip: {
                    displayColors: false,
                    backgroundColor: '#FFF',
                    titleFont: { size: 16, },
                    titleColor: '#0066ff',
                    bodyColor: '#000',
                    bodyFont: {
                      size: 14
                    },
                    callbacks: self.tooltipCallbacks
                  }
                },
                animation: {
                  duration: 0 // general animation time
                },
                cutout: '45%',

              }
            };
          }
          goal.barChartData = {
            type: 'bar',
            data: {
              labels: [self.monthNames[self.dateLastMonth.getMonth()], self.monthNames[self.dateNow.getMonth()]],
              datasets: [
                {
                  data: [goal.lastMonthValue, 0],
                  backgroundColor: '#FF594E',
                  //borderSkipped: 'bottom',
                  borderRadius: 5
                },
                {
                  label: 'Sales',
                  data: [0, goal.value],
                  backgroundColor: '#3878E0',
                  //borderSkipped: 'middle',
                  borderRadius: 5
                },
                {
                  label: 'Trending',
                  data: [0, goal.trendingValueForChart],
                  backgroundColor: '#29B13F',
                  //borderSkipped: 'middle',
                  borderRadius: 5
                }
              ]
            },
            options: {
              aspectRatio: 1.4,
              responsive: true,
              plugins: {
                tooltip: {
                  callbacks: {
                    label: function (tooltipItem) {
                      return Number(tooltipItem.raw).toFixed(2);
                    }
                  }
                },
                legend: {
                  display: false
                },
              },
              animation: {
                duration: 0 // general animation time
              },

              scales: {
                x: {
                  stacked: true,
                  grid: {
                    display: false // Remove x-axis grid lines
                  }
                },
                y: {
                  stacked: true,
                  grid: {
                    display: false // Remove x-axis grid lines
                  }
                }
              },

            }
          };

          if (self.selectedGroup.target !== 'self' || self.showYOYChartForUser) {
            goal.barYOYChartData = {
              type: 'bar',
              data: {
                labels: [(self.dateNow.getFullYear() - 1).toString(), self.dateNow.getFullYear().toString()],
                datasets: [
                  {
                    data: [goal.lastYearValue, 0],
                    backgroundColor: '#FF594E',
                    //borderSkipped: 'bottom',
                    borderRadius: 5
                  },
                  {
                    label: 'Sales',
                    data: [0, goal.value],
                    backgroundColor: '#3878E0',
                    //borderSkipped: 'middle',
                    borderRadius: 5
                  },
                  {
                    label: 'Trending',
                    data: [0, goal.trendingValueForChart],
                    backgroundColor: '#29B13F',
                    //borderSkipped: 'middle',
                    borderRadius: 5
                  }
                ]
              },
              options: {
                plugins: {
                  legend: {
                    display: false
                  },
                  tooltip: {
                    callbacks: {

                      label(tooltipItem) {
                        return Number(tooltipItem.raw).toFixed(2);
                      }
                    }
                  }
                },
                animation: {
                  duration: 0 // general animation time
                },

                scales: {
                  x: {
                    stacked: true,
                    grid: {
                      display: false // Remove x-axis grid lines
                    }
                  },
                  y: {
                    stacked: true,
                    grid: {
                      display: false // Remove x-axis grid lines
                    }
                  }
                },

              }
            };
          }
          return goal;
        })
        self._loaderService.hide();
      }, 300);

    }
  }
  calculateEstimatedPay(pegs: CommissionPeg[], growth: string, currentPerformance: number): number {
    let estimatedPay = 0;
    if (pegs.length > this.highestGoalTier) {
      this.highestGoalTier = pegs.length;
    }

    switch (growth) {
      case 'Tier':
        for (let i = 0; i <= pegs.length - 1; i++) {
          if (currentPerformance >= pegs[i].goal) {
            estimatedPay = pegs[i].pay;
          }
        }
        break;
      case 'Gradual':
        if (currentPerformance >= pegs[pegs.length - 1].goal) {
          estimatedPay = pegs[pegs.length - 1].pay;
        } else if (currentPerformance < pegs[0].goal) {
          estimatedPay = 0;
        } else {
          let isGreaterThanLastGoal = false;
          for (let i = 0; i <= pegs.length - 1; i++) {
            if (currentPerformance >= pegs[i].goal && currentPerformance < pegs[i + 1].goal) {
              isGreaterThanLastGoal = true;
            }

            if (isGreaterThanLastGoal) {
              const nextPeg = pegs[i + 1];
              const prevPeg = pegs[i];
              estimatedPay =
                ((nextPeg.pay - prevPeg.pay) * ((currentPerformance - prevPeg.goal) / (nextPeg.goal - prevPeg.goal))) + prevPeg.pay;
            }
          }
        }
        break;
    }
    return estimatedPay;
  }
  getChartData(data: any[], part: string) {
    const result: any[] = [];
    const outerData: any[] = [];
    const innerData: any[] = [];

    for (let i = 0; i <= data[0].length - 1; i++) {

      outerData.push(data[0][i][part]);

    }

    for (let i = 0; i <= data[1].length - 1; i++) {

      innerData.push(data[1][i][part]);

    }

    result.push(outerData), result.push(innerData);

    return result;
  }

  calculateGoalAndPayData(pegs: CommissionPeg[], currentPeg: any, isTrending: boolean): any {

    currentPeg = { 'goal': currentPeg.goal, 'pay': currentPeg.pay };
    let trendingCounter = +(moment().daysInMonth() / moment().date()).toFixed(2);
    if (this.currentMonthYear !== this.selectedDuration) {
      trendingCounter = 1;
    }
    const defaultPeg = { goal: 0, pay: 0 };
    const lastPeg: any = { 'goal': pegs[pegs.length - 1].goal, 'pay': pegs[pegs.length - 1].pay };
    const trendingPeg: any = { 'goal': currentPeg.goal * (trendingCounter - 1), 'pay': currentPeg.pay * trendingCounter };
    const innerData: any[] = [];
    let outerData: any[];
    const result: any[] = [];

    for (let i = 0; i <= pegs.length - 1; i++) {
      innerData.push((i === 0) ? { 'goal': pegs[i].goal, 'pay': pegs[i].pay } :
        { 'goal': (pegs[i].goal - pegs[i - 1].goal), 'pay': (pegs[i].pay - pegs[i - 1].pay) });
    }

    if (isTrending) {
      if (currentPeg.goal >= lastPeg.goal) {
        outerData = [lastPeg, defaultPeg, defaultPeg];
      } else if (trendingPeg.goal + currentPeg.goal >= lastPeg.goal) {
        outerData = [currentPeg, { goal: (lastPeg.goal - currentPeg.goal), pay: (lastPeg.pay - currentPeg.pay) }, defaultPeg];
      } else {
        outerData = [currentPeg, trendingPeg,
          { goal: (lastPeg.goal - currentPeg.goal - trendingPeg.goal), pay: (lastPeg.pay - currentPeg.pay - trendingPeg.pay) }];
      }
    } else {
      if (currentPeg.goal >= lastPeg.goal) {
        outerData = [lastPeg, defaultPeg, defaultPeg];
      } else {
        outerData = [currentPeg, defaultPeg, { goal: (lastPeg.goal - currentPeg.goal), pay: (lastPeg.pay - currentPeg.pay) }];
      }
    }
    result.push(outerData), result.push(innerData);
    return result;
  }

  columnDefinationsRewards = [
    { name: "Checkbox", isSortable: false, mappedToProperty: "checkbox", class: "", isFilterable: false, sticky: true, hide: false },
    { name: "Game", isSortable: false, mappedToProperty: "game", class: "", isFilterable: false, sticky: true, hide: false },
    { name: "Description", isSortable: false, mappedToProperty: "description", class: "", isFilterable: false, sticky: true, hide: false },
    { name: "Type", isSortable: false, mappedToProperty: "type", class: "", isFilterable: false, sticky: true, hide: false },
    { name: "MoneyEarned", isSortable: false, mappedToProperty: "moneyEarned", class: "", isFilterable: false, sticky: true, hide: false }
  ];
  
  columnDefinationsClaims = [
    { name: "IsComplete", isSortable: false, mappedToProperty: "isComplete", class: "", isFilterable: false, sticky: true, hide: false },
    { name: "FinalizedDate", isSortable: false, mappedToProperty: "finalizeDate", class: "", isFilterable: false, sticky: true, hide: false },
    { name: "AuthorizedBy", isSortable: false, mappedToProperty: "authorizedById", class: "", isFilterable: false, sticky: true, hide: false },
    { name: "Type", isSortable: false, mappedToProperty: "rewardClaimOptionId", class: "", isFilterable: false, sticky: true, hide: false },
    { name: "Money", isSortable: false, mappedToProperty: "money", class: "", isFilterable: false, sticky: true, hide: false }
  ];

  getTextByDataRewards(element, columnDef) {
    var textToShow = '';
    switch (columnDef.name) {
      case 'Game':
        if (element.rewardGame) {
          textToShow = element.rewardGame.name;
        } else {
          textToShow = "ManualReward";
        }
        break;
      case 'Description':
        if (element.rewardGame) {
          textToShow = element.rewardGame.description;
        } else {
          textToShow = element.description;
        }
        break;
      case 'Type':
        if (element.rewardGame) {
          textToShow = RewardGameType[element.rewardGame.type];
        } else {
          textToShow = "ManualReward";
        }
        break;
      case 'Win $':
        textToShow = "$" + element.money;
        break;

      default:
        textToShow = element[columnDef.mappedToProperty];
    }
    return textToShow;
  }
  
  getColumnDefinationByMappedToPropertyRewards(mappedToProperty) {
    var column = this.columnDefinationsRewards.find(x => x.mappedToProperty == mappedToProperty);
    switch (column.name) {
      case 'MoneyEarned':
        column.name = 'Win $';
        break;
    }
    return column;
  }

  selectedColumnsRewards = this.getDisplayedColumnsRewards();

  getDisplayedColumnsRewards(): string[] {
    return this.columnDefinationsRewards.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
  }

  selectedColumnsClaims = this.getDisplayedColumnsClaims();

  getDisplayedColumnsClaims(): string[] {
    return this.columnDefinationsClaims.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
  }

  getColumnDefinationByMappedToPropertyClaims(mappedToProperty) {
    var column = this.columnDefinationsClaims.find(x => x.mappedToProperty == mappedToProperty);
    switch (column.name) {
      case 'MoneyEarned':
        column.name = 'Win $';
        break;
    }
    return column;
  }

  getTextByDataClaims(element, columnDef) {
    var textToShow = '';
    switch (columnDef.name) {
      case 'IsComplete':
        element.isComplete ? textToShow = 'Yes' : textToShow = 'No';
        break;
      case 'FinalizedDate':
        if ('0001-01-01T00:00:00' == element[columnDef.mappedToProperty]) {
          textToShow = "N/A";
        }
        else {
          textToShow = moment(element[columnDef.mappedToProperty]).format(this._durationService.format1);
        }
        break;
      case 'Type':
        textToShow = this.claimOptionList.find(x => x.id == element.rewardClaimOptionId).name;
        break;
      case 'Money':
        textToShow = "$" + element.money;
        break;
      default:
        textToShow = element[columnDef.mappedToProperty];
    }
    return textToShow;
  }

  tableready = false;
  dataSourceRewards = new MatTableDataSource();
  dataSourceClaims = new MatTableDataSource();
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  rewardList: any[];
  claimList: any[];
  claimOptionList: any[];
  getRewardsAndClaims() {
    this.tableready = false;

    this._rewardService.getMoneyRewardsForUser(this.selectedEmployee.user.id).subscribe((response) => {
      this.rewardList = response;
      this.dataSourceRewards = new MatTableDataSource(this.rewardList.filter(x => !x.isClaimed));
      this.tableready = true;
      this.rewardObject.unclaimedDollars = 0;
      this.rewardList.filter(x => !x.isClaimed).map(x => x.money).forEach((x) => {
        this.rewardObject.unclaimedDollars += x;
      });
      this.dataSourceRewards.paginator = this.paginator.toArray()[0];
    });
    this._rewardService.getRewardClaimsForUser(this.selectedEmployee.user.id).subscribe((response) => {
      this.claimList = response;
      this.dataSourceClaims = new MatTableDataSource(this.claimList);
      this.dataSourceClaims.paginator = this.paginator.toArray()[1];
    });

    this._rewardService.getRewardClaimOptions(this.selectedClientId).subscribe((response) => {
      this.claimOptionList = response;
    });
  }

  rewardObject: any = {
    claimRewardList: new Array(0),
    unclaimedDollars: 0,
    currentClaim: 0,
    selectedOptionId: 0,
    selectAll: false
  };

  selectAllRewards(toAdd: boolean) {
    this.rewardObject.currentClaim = 0;
    this.rewardObject.claimRewardList = new Array(0);
    if (toAdd) {
      this.rewardList.filter(x => !x.isClaimed).forEach((x) => {
        this.rewardObject.claimRewardList.push(x.id);
        this.rewardObject.currentClaim += x.money;
      })
    }
  }

  updateRewardClaim(reward: number, toAdd: boolean) {
    if (toAdd) {
      this.rewardObject.claimRewardList.push(reward);
    } else {
      let index = this.rewardObject.claimRewardList.indexOf(reward);
      this.rewardObject.claimRewardList.splice(index, 1);
    }
    this.rewardObject.currentClaim = 0;
    this.rewardObject.claimRewardList.forEach((thisRewardId) => {
      this.rewardObject.currentClaim += this.rewardList.find(x => x.id == thisRewardId).money;
    })
  }


  finalizeClaimCreate() {
    this._rewardService.sendRewardClaim(this.rewardObject.claimRewardList, this.rewardObject.selectedOptionId).subscribe((response) => {
      this.getRewardsAndClaims();
      this.finalizeClaimCreateModal.isOpen = false;
      this.rewardObject.claimRewardList = new Array(0);
      this.rewardObject.currentClaim = 0;
    });
  }

  openFinalizeClaimCreateModal() {
    this.finalizeClaimCreateModal.isOpen = true;
    this.finalizeClaimCreateModal.body = ' \n ' + this.claimOptionList.find(x => x.id == this.rewardObject.selectedOptionId).name + ' \n  $ ' + this.rewardObject.currentClaim;
    this.finalizeClaimCreateModal.open();
    this.dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      autoFocus: true, disableClose: false, panelClass: '',
      data: {
        message: this.finalizeClaimCreateModal.body, title: "Claim gift card?",
      }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result === true) { this.finalizeClaimCreate(); }
    });
  }

  finalizeClaimCreateModal: any = {
    isOpen: false,
    header: '',
    body: '',
    open: function () {
      this.header = "Claim gift card?";
      this.isOpen = true;
    },
    cancelButton: true,
    cancel: function () {
      this.isOpen = false;
    }

  }

}
