<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" >close</mat-icon>
</button>
<h6 mat-dialog-title><span class="dialog-title">Update Notes</span></h6>
<mat-form-field class="example-full-width" appearance="outline">
  <textarea matInput [(ngModel)]="noteToAdd" #note="ngModel" [ngClass]="{'is-invalid': note.invalid}" name="note" required></textarea>
  <span *ngIf="note.invalid" class="invalid-feedback">
    <span *ngIf="note.errors.required">Note is required.</span>
  </span>
</mat-form-field>
<button mat-raised-button [disabled]="note.invalid" (click)="addNote()" color="primary" class="margin-bottom-1em">Add</button>
<br />
<div >
  <div class="" >    
    <div mat-dialog-content class="">    
      <table #table mat-table [dataSource]="notes" class="table table-bordered table-hover small-txt" *ngIf="notes.length>0">
        <ng-container matColumnDef="note">
          <th mat-header-cell *matHeaderCellDef class="sort-column mat-sort-header-button-custom mat-header-cell">Notes</th>
          <td mat-cell [matTooltip]="note.note" *matCellDef="let note; let i = index;">{{note.note}}</td>
        </ng-container>
        <ng-container matColumnDef="userName">
          <th mat-header-cell *matHeaderCellDef>User Name</th>
          <td mat-cell [matTooltip]="note.userName" *matCellDef="let note; let i = index;"><span style="margin-right:5px">{{note.userName}}</span></td>
        </ng-container>
        <ng-container matColumnDef="dateTime">
          <th mat-header-cell *matHeaderCellDef>Date Time</th>
          <td mat-cell [matTooltip]="note.dateTime" *matCellDef="let note; let i = index;"><span style="margin-right:5px">{{ getFormattedDate(note.dateTime)}}</span></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </table>
    </div>
  </div>
</div>

