<button color="primary" class="primary-action-btm" mat-raised-button type="button" (click)="addFormulaViewGroup()"> Add Formula View Group </button>
<div [hidden]="loadingInProgress" class="margin-top-1em table-container">
  <table mat-table [dataSource]="dataSource" matSort class="table table-bordered table-hover small-txt">
    <ng-container *ngFor="let column of getDisplayedColumns(); let colIndex = index;" [matColumnDef]="column">
      <th class="sort-column mat-sort-header-button-custom mat-header-cell" mat-header-cell *matHeaderCellDef mat-sort-header [matTooltip]="getColumnDefinationByMappedToProperty(column).name">{{getColumnDefinationByMappedToProperty(column).name}}</th>
      <ng-container *matCellDef="let element">
        <td mat-cell>
          <span *ngIf="column !== 'actions' && column !== 'isSelected'">{{getTextBydata(element,getColumnDefinationByMappedToProperty(column))}}</span>
          <span *ngIf=" column === 'isSelected'">{{getTextBydata(element,getColumnDefinationByMappedToProperty(column))? 'Yes': 'No'}}</span>
          <span *ngIf="column === 'actions'">
            <a color="primary" type="button" (click)="editFormulaViewGroup(element)" class="icon-btn"><i class="fa fa-edit"></i></a>
            <a color="primary" type="button" (click)="deleteFormulaViewGroup(element)" class="icon-btn"><i class="fa fa-trash-alt"></i></a>
          </span>         
        </td>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
    <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();">
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="10" showFirstLastButtons></mat-paginator>
</div>

