import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AssetService } from './core/services/asset.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { HomeModule } from './home/home.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotifierModule } from 'angular-notifier';
import { LoaderService } from './home/services/loader.service';
import { LoaderComponent } from './tools/progress-spinner/loader.component';
import { MaterialModule } from './home/material.module';
import { LoaderInterceptor } from './home/interceptors/loader.interceptor';
//import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb';
import { ErrorInterceptor } from "./home/interceptors/error.interceptor";
import { DateHelperService } from './home/services/date-helper.service';
import { NgChartsModule } from 'ng2-charts';
import { DynamicChartComponent } from './dynamic-chart/dynamic-chart.component';


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    AuthenticationModule,
    HomeModule,
    AppRoutingModule,
    MaterialModule,
    NgChartsModule,
    //NgDynamicBreadcrumbModule,
    NotifierModule.withConfig(
      {
        position: { horizontal: { position: 'middle' }, vertical: { position: 'top' }, },
        behaviour: { autoHide: 5000 /*in milliseconds*/ }
      })
  ],
  declarations: [
    AppComponent,
    LoaderComponent,
    DynamicChartComponent

  ],
  exports: [
  ],
  providers: [
    AssetService,
    LoaderService,
    DateHelperService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }

//Below are the options to configure notifier service Ref: https://github.com/dominique-mueller/angular-notifier
//{
//  position: {
//    horizontal: {
//      position: 'middle',
//        distance: 12,
//      },
//    vertical: {
//      position: 'top',
//      distance: 12,
//      gap: 10,
//      },
//    },
//   theme: 'material',
//  behaviour: {
//    autoHide: 9999,
//    onClick: false,
//    onMouseover: 'pauseAutoHide',
//    showDismissButton: true,
//    stacking: 4,
//    }
//  ,
//  animations: {
//    enabled: true,
//    show: {
//      preset: 'slide',
//      speed: 300,
//      easing: 'ease',
//    },
//    hide: {
//      preset: 'fade',
//      speed: 300,
//      easing: 'ease',
//      offset: 50,
//    },
//    shift: {
//      speed: 300,
//      easing: 'ease',
//    },
//    overlap: 150,
//  }
//}
