import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Setting } from '../models/setting';
import { ReportType } from '../models/reportType';
import { Location } from '../../models/location';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  private _baseUrl = '/api/setting';

  constructor(private http: HttpClient) {}

  getSettings(): Observable<Setting[]> {
    return this.http.get<Setting[]>(this._baseUrl);
  }
  getReportTypes(): Observable<ReportType[]> {
    return this.http.get<ReportType[]>(this._baseUrl + '/reporttypes');
  }

  getLocationsForReport(userId: string, reportId: number): Observable<Location[]> {
    return this.http.get<Location[]>(this._baseUrl + '/locationsForReport/' + userId + '/' + reportId);
  }
}
