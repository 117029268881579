import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotifierService } from 'angular-notifier';
import { Dashboard } from '../models/dashboard';
import { Location } from '../models/location';

declare const _;

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private _baseUrl = '/api/dashboard';


  constructor(private http: HttpClient,
    private _notifier: NotifierService,
    /*private skuGroupService: SkuGroupService,
    private _tableService: TableService,
    private _locationService: LocationService,
    private _employeeService: EmployeeService*/) {


  }

  getDashboardWithParams(userId: string, value: number, graphTypeId: number, dateTypeId: number,
    locationTierFocusId: number, locations: any[]): Observable<any> {

    const dashboardConfig = {
      userId,
      value,
      graphTypeId,
      dateTypeId,
      locationTierFocusId,
      locations
    };
    return this.http.put(this._baseUrl + '/linechart', dashboardConfig);
  }

  createDashboard(userId: string, name: string, skuGroupOrFormula: boolean, value: number, graphTypeId: number, dateTypeId: number,
    locationTierFocusId: number, locations: any[], employees: any[]): Observable<any> {

    const dashboardConfig = {
      userId,
      name,
      skuGroupOrFormula,
      value,
      graphTypeId,
      dateTypeId,
      locationTierFocusId,
      locations,
      employees,
    };
    //console.log(dashboardConfig);
    return this.http.post(this._baseUrl, dashboardConfig);
  }

  changeColor(location: Location): Observable<any> {


    return this.http.put(this._baseUrl + '/' + location.id + '/editColor', location);

  }

  updateDashboard(id: number, userId: string, name: string, skuGroupOrFormula: boolean,
    value: number, graphTypeId: number, dateTypeId: number,
    locationTierFocusId: number, locations: any[], employees: any[]): Observable<any> {

    const dashboardConfig = {
      id,
      userId,
      name,
      skuGroupOrFormula,
      value,
      graphTypeId,
      dateTypeId,
      locationTierFocusId,
      locations,
      employees,
    };
    //console.log(dashboardConfig);
    return this.http.put(this._baseUrl + '/' + id, dashboardConfig);
  }

  getDashboardById(param: number): Observable<any> {


    return this.http.get<any>(this._baseUrl + '/' + param);
  }

  getDashboardsByUser(id: string, dateOverride: string): Observable<Dashboard[]> {
    return this.http.get<Dashboard[]>(this._baseUrl + '/' + id + '/' + dateOverride);
  }

  updateDashboardPosition(dashboards: any[]) {
    return this.http.post<any>(this._baseUrl + '/positions', dashboards);
  }

  deleteDashboard(dashboardid: number): Observable<any> {
    return this.http.delete(this._baseUrl + '/' + dashboardid);
  }


}
