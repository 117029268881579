import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RqVzDataTableComponent } from "../../rq-vz-data-table/rq-vz-data-table.component";
import { CommissionReconcileService } from "../../services/commission-reconcile.service";
import { LoaderService } from "../../services/loader.service";

@Component({
  selector: 'spiffs-data-table',
  templateUrl: './spiffs-data-table.component.html',
  providers: []
})

export class SpiffsDataTableComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(private _commissionReconcileService: CommissionReconcileService,
    private _loaderService: LoaderService) { }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(RqVzDataTableComponent) rqVzDataTableComponent: RqVzDataTableComponent;

  @Input()
  tableData: any;
  @Output()
  getCommissionReportUpdated = new EventEmitter<string>();
  debug = false;
  dataSource = new MatTableDataSource();
  searchTerm: string;
  selectedRow = new Set<any>();
  selectedImei: any;
  selectedItem = {};
  moneySymbol = '$';
  noData: Observable<boolean>;
  ngAfterViewInit(): void {
    if (this.debug) console.log("Spiffs Init");
    setTimeout(() => {
      this.dataSource.paginator = this.paginator; this.dataSource.sort = this.sort; if (this.debug) console.log("Spiff Timeout Paginate Init");
    });
  }

  ngOnDestroy(): void {
    this.columnDefinations = null;
    this.dataSource = null;
  }

  ngOnInit(): void {

    this.updateDataSource(this.tableData);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  columnDefinations = [
    { name: "Select", isSortable: true, mappedToProperty: "isSelected", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "Name", isSortable: true, mappedToProperty: "soldByName", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "RQ", isSortable: true, mappedToProperty: "rqExpected", class: "price", isFilterable: true, sticky: true, hide: false },
    { name: "VZ", isSortable: true, mappedToProperty: "vzExpected", class: "price", isFilterable: true, sticky: true, hide: false },
    { name: "IMEI", isSortable: true, mappedToProperty: "imei", class: "", isFilterable: true, sticky: true, hide: false },
    { name: "MTN", isSortable: true, mappedToProperty: "mtn", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Matched In RQ?", isSortable: true, mappedToProperty: "imeiTiedToRq", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Spiffs", isSortable: true, mappedToProperty: "spiff", class: "price", isFilterable: true, sticky: false, hide: false },
    { name: "Kick", isSortable: true, mappedToProperty: "kick", class: "price", isFilterable: true, sticky: false, hide: false },
    { name: "Difference", isSortable: true, mappedToProperty: "difference", class: "price", isFilterable: true, sticky: false, hide: false },
  ];

  selectedColumns = this.getDisplayedColumns();
  allSpiffSelected = false;

  getDisplayedColumns(): string[] {
    return this.columnDefinations.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
  }

  getColumnDefinationByMappedToProperty(mappedToProperty) {
    var column = this.columnDefinations.find(x => x.mappedToProperty == mappedToProperty);
    return column;
  }

  updateDataSource(data) {
    if (this.debug) console.log("Spiff Update Data Source");
    data = data.filter(x =>
      (
        this.getSpiffs(x) + this.getKickers(x) === x.difference
        || this.getSpiffs(x) === x.difference
        || this.getKickers(x) === x.difference
      )
      && x.difference !== 0
    );
    data.forEach((x: any) => { x.isSelected = this.allSpiffSelected; });
    this.dataSource = new MatTableDataSource(data);
    this.selectedImei = data[0];
    this.selectedItem = this.selectedImei;
    if (this.debug) console.log("Spiff update Data Source Before Timeout")
    setTimeout(() => {
      this.dataSource.paginator = this.paginator; this.dataSource.sort = this.sort; //this._loaderService.hide(); if (this.debug) console.log("Hide Spinner Spiff");
    }, 1000);
    this.noData = this.dataSource.connect().pipe(map(data => data.length === 0));
  }

  selectItem(imei) {
    this.selectedItem = imei;
    if (this.rqVzDataTableComponent) this.rqVzDataTableComponent.updateSelectedImei(imei);
  }

  selectImei(imei) {
    imei.isSelected = !imei.isSelected;
    this.selectedImei = imei;
    //console.log(imei);
    this.allSpiffSelected = this.isAllSelected();
  }

  selectAll(e) {
    this.allSpiffSelected = e.checked;
    this.dataSource.filteredData.forEach((x: any) => {
      x.isSelected = this.allSpiffSelected;
    });

  }

  isAllSelected() {
    var notSelectedItems = this.dataSource.data.filter(function (item: any) {
      return item.isSelected === false;
    });
    return !(notSelectedItems != null && notSelectedItems.length > 0);
  }

  checkboxLabel(row): string {
    if (row != null) {
      return `${this.allSpiffSelected ? 'deselect' : 'select'} all`;
    }
    return "";
  }

  sendListOfImeis(decision: number) {
    const thislist = this.dataSource.data.filter(function (item: any) {
      return item.isSelected === true;
    });
    this._commissionReconcileService.AddImeiListCommand(thislist, decision).subscribe((response: any) => {
      this.getCommissionReportUpdated.next(); this.selectAll({ checked: false });
    });
  }

  getTextBydata(element, columnDef) {
    var textToShow = '';
    switch (columnDef.name) {
      case 'Spiffs':
        textToShow = this.getSpiffs(element).toString();
        break;
      case 'Kick':
        textToShow = this.getKickers(element).toString();
        break;
      case 'Matched In RQ?':
        if (element[columnDef.mappedToProperty] == false) {
          textToShow = 'No';
        } else {
          textToShow = 'Yes';
        }
        break;
      case 'Difference':
      case 'RQ':
      case 'VZ':
      case 'Spiffs':
      case 'Kick':
        textToShow = this.moneySymbol + element[columnDef.mappedToProperty].toFixed(2);
        break;
      default:
        textToShow = element[columnDef.mappedToProperty];
    }
    return textToShow;
  }

  getKickers(imei) {
    let sum = 0;
    imei.vzItems.forEach((x) => {
      sum = sum + x.kicker;
    });
    return sum;
  }

  getSpiffs(imei) {
    let sum = 0;
    imei.vzItems.forEach((x) => {
      sum = sum + x.spiff;
    });
    return sum;
  }
}
