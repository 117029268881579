import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private _auth: AuthService, private _router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot
  ): boolean {
    if (!this._auth.isAuthenticated()) {
      this._router.navigate(['login']);
      return false;
    }
    const routePath = route.url[0].path;
    if (!this._auth.hasDashboardPermission() && routePath === 'dashboard') {
      this._router.navigate(['welcome']);
      return false;
    }
    if (!this._auth.hasInvoicePermission() && routePath === 'invoice-viewer') {
      this._router.navigate(['welcome']);
      return false;
    }
    if (!this._auth.hasRankPermission() && routePath === 'rank-contest') {
      this._router.navigate(['welcome']);
      return false;
    }
    if (!this._auth.hasInventoryPermission() && routePath === 'inventory') {
      this._router.navigate(['welcome']);
      return false;
    }
    if (!this._auth.hasEmployeeCommissionsSpiffPermission() && routePath === 'forecast') {
      this._router.navigate(['welcome']);
      return false;
    }
    if (!this._auth.hasCommissionUserPermission() && routePath === 'commission-user') {
      this._router.navigate(['welcome']);
      return false;
    }
    if (!this._auth.hasUsersPermission() && routePath === 'users') {
      this._router.navigate(['welcome']);
      return false;
    }
    if (!this._auth.hasRoleManagerPermission() && routePath === 'roles') {
      this._router.navigate(['welcome']);
      return false;
    }
    if (!this._auth.hasImportPermission() && routePath === 'import') {
      this._router.navigate(['welcome']);
      return false;
    }
    if (!this._auth.hasCommissionReconcilePermission() && routePath === 'commission-reconcile') {
      this._router.navigate(['welcome']);
      return false;
    }
    if (!this._auth.isSuperAdmin() && routePath === 'clients') {
      this._router.navigate(['welcome']);
      return false;
    }
    if (!this._auth.isSuperAdmin() && routePath === 'upload-config') {
      this._router.navigate(['welcome']);
      return false;
    }
    if (!this._auth.hasSkuGroupsPermission() && routePath === 'sku-group') {
      this._router.navigate(['welcome']);
      return false;
    }
    if (!this._auth.hasFormulasPermission() && routePath === 'formulas') {
      this._router.navigate(['welcome']);
      return false;
    }
    if (!this._auth.hasFormulaViewGroupsPermission() && routePath === 'formula-view-group') {
      this._router.navigate(['welcome']);
      return false;
    }
    if (!this._auth.hasPerformanceSummaryPermission() && routePath === 'performance-summary') {
      this._router.navigate(['welcome']);
      return false;
    }
    if (!this._auth.hasRewardGameCreatePermission() && routePath === 'rewards-create') {
      this._router.navigate(['welcome']);
      return false;
    }
    if (!this._auth.hasRewardReconcilePermission() && routePath === 'rewards-claim-management') {
      this._router.navigate(['welcome']);
      return false;
    }
    if (!this._auth.hasRewardDashboardPermission() && routePath === 'rewards-dashboard') {
      this._router.navigate(['welcome']);
      return false;
    }
    if (!this._auth.hasDashboardSinglePermission() && routePath === 'dashboard-single') {
      this._router.navigate(['welcome']);
      return false;
    }

    return true;
  }

}
