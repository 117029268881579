<header class="section-header">
  <div class="tbl">
    <div class="tbl-row">
      <div class="tbl-cell">
        <h3>Change Password</h3>
      </div>
    </div>
  </div>
</header>
<div class="box-typical box-typical-padding">
  <ul class="warning-message" *ngIf="isPasswordValidationFailed">
    <li>New Password : </li>
    <li *ngFor="let error of errorArray"> {{error}}</li>
    <!--<li>Must have at least one lower case.</li>
    <li>Must have at least one upper case.</li>
    <li>Must have at least one number.</li>
    <li>Must have at least one special character.</li>
    <li>Must be atleast 8 characters.</li>-->
  </ul>
  <form #f="ngForm" (ngSubmit)="f.form.valid && changePassword()" novalidate>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">Current Password</label>
      <div class="col-sm-10">
        <p class="form-control-static">
          <input type="password" class="form-control width-30" [(ngModel)]="model.currentPassword" #currentPassword="ngModel" 
          [ngClass]="{'is-invalid': f.submitted && currentPassword.invalid }" name="currentPassword" placeholder="Current Password" required />
        </p>
        <span *ngIf="f.submitted && currentPassword.invalid" class="invalid-feedback">
            <span *ngIf="currentPassword.errors.required">Current password is required.</span>
          </span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">New Password</label>
      <div class="col-sm-10">
        <p class="form-control-static">
          <input type="password" class="form-control width-30" [(ngModel)]="model.newPassword" #newPassword="ngModel" 
          [ngClass]="{'is-invalid': f.submitted && newPassword.invalid }" name="newPassword" placeholder="New Password" required />
        </p>
        <span *ngIf="f.submitted && newPassword.invalid" class="invalid-feedback">
          <span *ngIf="newPassword.errors.required">New password is required.</span>
        </span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label">Re-enter New Password</label>
      <div class="col-sm-10">
        <p class="form-control-static">
          <input type="password" class="form-control width-30" [(ngModel)]="model.confirmPassword" #confirmPassword="ngModel" 
          [ngClass]="{'is-invalid': f.submitted && confirmPassword.invalid }" name="confirmPassword" placeholder="Re-enter New Password" required />
        </p>
        <span *ngIf="f.submitted && confirmPassword.invalid" class="invalid-feedback">
          <span *ngIf="confirmPassword.errors.required">Confirm password is required.</span>
        </span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 form-control-label"></label>
      <div class="col-sm-10">
        <p class="form-control-static">
          <button type="submit" class="btn btn-inline">Submit</button>
        </p>
      </div>
    </div>
  </form>
</div>
