import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html'
})
export class ChangePasswordComponent implements OnInit {
  model: any = {};
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  isPasswordValidationFailed: boolean;
  errorArray: string[];

  constructor(private _userService: UserService,
    private _authService: AuthService,
    private _notifier: NotifierService) { }

  ngOnInit() {
  }



  checkPasswordValidity(value){
    const isNonWhiteSpace = /^\S*$/;
    let errorArray = [];
    if (!isNonWhiteSpace.test(value)) {
      errorArray.push("Must not contain Whitespaces.");
    }

    const regExA = new RegExp("^(?=.*?[A-Z]).*$");
    if (!regExA.test(value)) {
      errorArray.push("Must have at least one upper case.");
    }

    const regExa = new RegExp("^(?=.*?[a-z]).*$");
    if (!regExa.test(value)) {
      errorArray.push("Must have at least one lower case.");
    }

    const regExNum = new RegExp('^(?=.*?[0-9]).*$');
    if (!regExNum.test(value)) {
      errorArray.push("Must have at least one number.");
    }

    const regExsp = new RegExp('^(?=.*?[#?!@$%^&*-]).*$');
    if (!regExsp.test(value)) {
      errorArray.push("Must have at least one special character.");
    }

    const regExlen = new RegExp('^.{8,}$');
    if (!regExlen.test(value)) {
      errorArray.push("Must be atleast 8 characters.");
    }
    return errorArray;
  }



  changePassword() {
    this.isPasswordValidationFailed = false;
    const regEx = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    if (this.model.currentPassword === this.model.newPassword) {
      this._notifier.notify('warning', 'Current password could not be same as new password.');
      return;
    }

    if (this.model.newPassword !== this.model.confirmPassword) {
      this._notifier.notify('warning', 'New password and confirm password should be same.');
      return;
    }

    //if (!regEx.test(this.model.newPassword)) {
    //  this.isPasswordValidationFailed = true;
    //  return;
    //}
    let response = this.checkPasswordValidity(this.model.newPassword);
    if (response.length > 0) {
      this.errorArray = response;
      this.isPasswordValidationFailed = true;
      return;
    }
    this._userService.changePassword(this.model.currentPassword, this.model.newPassword)
      .subscribe((response: string) => {
        this._notifier.notify('success', 'Password changed successfully, Please login again to continue.');
        this._authService.logout();
      }, (error) => this._notifier.notify('error', error.error), () => { });

    
  }
  
}
