<div class="auth-container d-flex align-items-center justify-content-center">
  <div class="sign-box">
    <div class="sign-avatar">
      <img src="assets/img/admin-logo.svg" alt="">
    </div>
    <form #loginForm="ngForm" novalidate (ngSubmit)="login(loginForm)">

      <h3 class="sign-title">Sign In</h3>
      <mat-form-field class="bottom-border">
        <mat-label>Username</mat-label>
        <input matInput type="text" name="username" placeholder="Username" [ngModel]="credentials.username"
               #username="ngModel" tmFocus validateEmail required />
      </mat-form-field>
      <mat-error *ngIf="username.dirty && username.invalid">Username is required.</mat-error>
      <mat-form-field class="bottom-border mt-2 pt-1">
        <mat-label>Password</mat-label>
        <input matInput type="password" id="password" required name="password" placeholder="Password" [ngModel]="credentials.password" />
      </mat-form-field>
      <a routerLink="/forgotpassword" class="forgot-password-text mt-2">Forgot Password?</a>

      <div class="mt-3">
        <mat-checkbox #rememberMeCheckBox id="signed-in" [(ngModel)]="rememberMe" (change)="onRememberMeChanged(rememberMeCheckBox.checked)" [color]="'primary'">
          <span class="keep-me-sign-in-text">
            Keep me signed in

          </span>
        </mat-checkbox>
      </div>
      <button color="primary" class="login-btn" mat-raised-button type="submit" (click)="login(loginForm)" [disabled]="loginForm.invalid"> Login </button>
      <div>
        <button class="mt-3 mb-2 sign-up-btn" type="button" href="#"> Sign up </button>
      </div>

      <div *ngIf="errors" class="alert alert-danger margin-top-1em" role="alert"><strong>Oops!</strong> {{errors}}</div>
    </form>
  </div>
</div>
