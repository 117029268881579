import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Duration } from '../models/duration';
import { DurationService } from '../services/duration.service';
import * as moment from 'moment';
import { NotifierService } from 'angular-notifier';
import { DatascapeService } from '../services/datascape.service';
import { LocationService } from '../services/location.service';
import { EmployeeService } from '../services/employee.service';
import { Employee } from '../models/employee';
import { ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BillPaymentViewModel } from '../models/bill-payment';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelectChange } from '@angular/material/select';
import * as XLSX from 'xlsx';
import { LoaderService } from '../services/loader.service';
import { MatDialog } from '@angular/material/dialog';
import { NotesDialogComponent } from '../dialogs/notes/notes-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDatepicker } from '@angular/material/datepicker';
import { ConfirmDialogComponent } from '../dialogs/confirm/confirm-dialog.component';

export class Filters {
  value: number;
  viewValue: string;
}
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-datascape-reconcile',
  templateUrl: './datascape-reconcile.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
})
export class DatascapeReconcileComponent implements OnInit, OnDestroy {

  @ViewChild('datascapeTable', { read: ElementRef }) datascapeTable: ElementRef;
  searchTerm = '';
  columnUnderSorting = 0;
  showAll = false;
  showReconciled = false;
  daysToIgnore = 0;
  dateRequest: Date;
  todayDate: Date;
  filterDate: Date;
  originalData: BillPaymentViewModel = new BillPaymentViewModel();
  data: BillPaymentViewModel = new BillPaymentViewModel();
  selectedMTN: any;
  selectedRadio = 1;
  doneLoading = false;
  sumDifference = 0;
  selectedViewMode = 1;
  currentRebateList: any = [];
  savedRebateList: any = [];
  filterDifference: number;
  filterImei: number;
  filterMtn: number;
  chargeBackForm: number;
  monthYear: string;
  totalDismissed = 0;
  totalChargedBack = 0;
  totalKeepers = 0;
  dismissedList: any = [];
  chargebackList: any = [];
  keeperList: any = [];
  keeperOldList: any = [];
  allAreSelected = false;
  employeeList: Employee[] = [];
  storeSubscription: Subscription;
  clients: any[] = [];
  regions: any[];
  selectedClientId: number;
  selectedClient: any;
  selectedRegionId: number;
  selectedRegion: any;
  districts: any[];
  selectedDistrictId: number;
  selectedDistrict: any;
  stores: any[];
  selectedStoreId: number;
  selectedStore: any;

  selectedLocationId: number;

  fetchReportInProgress: boolean;
  noteToAdd: string;
  customRangeStartDate: any;
  customRangeEndDate: any;
  recentDataDate: string;
  uploadStatus: string;
  localStorageLoadingInProgress: boolean;
  invoiceIsLoading = false;
  isAscendingOrder = true;
  clientName: string;
  regionName: string;
  districtName: string;
  storeName: string;
  table: any = {
    sorting: true,
    toggleColumns: true
  };
  lastFilter: string = ''
  subscriptionToClients: Subscription;
  subscriptionToRegions: Subscription;
  subscriptionToDistricts: Subscription;
  subscriptionToStores: Subscription;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private _notifier: NotifierService,
    private _locationService: LocationService,
    private _datascapeService: DatascapeService,
    private _employeeService: EmployeeService,
    private _durationService: DurationService,
    private _loaderService: LoaderService, private dialog: MatDialog) {
    this.data.Items = [];
  }

  //#region Table settings
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource();
  months: any;
  moneySymbol = '$';

  iconDefinition = [
    { toolTip: "Missing Data", color: "orange", code: 0 },
    { toolTip: "Incorrect", color: "red", code: 1 },
    { toolTip: "Balanced", color: "green", code: 2 },
    { toolTip: "Extra", color: "cyan", code: 3 },
  ];

  columnDefinations = [
    { name: "Status", isSortable: true, mappedToProperty: "icon", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Date", isSortable: true, mappedToProperty: "date", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Difference", isSortable: true, mappedToProperty: "difference", class: "price", isFilterable: true, sticky: false, hide: false },
    { name: "Charged $", isSortable: true, mappedToProperty: "dsCharged", class: "price", isFilterable: true, sticky: false, hide: false },
    { name: "Collected $", isSortable: true, mappedToProperty: "rqCollected", class: "price", isFilterable: true, sticky: false, hide: false },
    { name: "MTN", isSortable: true, mappedToProperty: "mtn", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Location", isSortable: true, mappedToProperty: "locationName", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Employee", isSortable: true, mappedToProperty: "user", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Type", isSortable: true, mappedToProperty: "transactionType", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "CtrlNum", isSortable: true, mappedToProperty: "ctrlNum", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Invoice#", isSortable: false, mappedToProperty: "invoiceNumber", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Reconciled", isSortable: true, mappedToProperty: "isHidden", class: "", isFilterable: true, sticky: false, hide: false },
    { name: "Notes", isSortable: false, mappedToProperty: "notes", class: "", isFilterable: true, sticky: false, hide: false }
  ];

  private dialogRef: any;

  openNotesDialog(column, element) {
    var tempDate = new Date(this.selectedDuration.duration.FromDate);
    this.monthYear = '0' + (tempDate.getMonth() + 1) + tempDate.getFullYear();
    if (this.monthYear.length > 6) this.monthYear = this.monthYear.substring(1, 7);
    if (column == 'notes') {
      this.dialogRef = this.dialog.open(NotesDialogComponent,
        {
          autoFocus: true,
          disableClose: false,
          panelClass: 'notes-component',
          data: { element: element, selectedClientId: this.selectedClientId, pageOrigin: 'datascape', monthYear: this.monthYear }
        });
      this.dialogRef.afterClosed().subscribe(result => {
        //console.log("afterClosed", result);
        if (result.update === true) {
          this.getDatascapeReport();
        }
      });
    }
  }


  getDisplayedColumns(): string[] {
    return this.columnDefinations.filter(cd => !cd.hide).map(cd => cd.mappedToProperty);
  }

  getColumnDefinationByMappedToProperty(mappedToProperty) {
    var column = this.columnDefinations.find(x => x.mappedToProperty == mappedToProperty);
    switch (column.name) {
      case 'Difference':
        column.name = 'Difference\n(Collected-Charged)';
        break;
    }
    return column;
  }

  exportTableToExcel() {
    let sheetData = [];
    let firstRow = [];
    let mapToList = [];
    this.columnDefinations.forEach(x => {
      if (!x.hide) {
        firstRow.push(x.name);
        mapToList.push(x.mappedToProperty);
      }
    });
    sheetData.push(firstRow);
    this.data.Items.forEach(y => {
      if (!this.showAll && y['difference'] === 0) {

      } else {
        let thisRow = [];
        mapToList.forEach(m => {
          let textToShow = '';
          switch (m) {
            case 'notes':
              textToShow = this.getTextBydata(y, this.getColumnDefinationByMappedToProperty('notes')) + this.getNoteCountText(y);
              break;
            case 'icon':
              textToShow = this.getIconHover(y);
              break;
            default:
              textToShow = y[m];
              break;
          }
          thisRow.push(textToShow);
        });
        sheetData.push(thisRow);
      }
    });
    let ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(sheetData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'BillpayReconciliation.xlsx');
  }

  getNoteCountText(element) {
    var textToShow = '';
    textToShow = element.notes.length > 0 ? " (+" + (element.notes.length) + " ...)" : "";
    return textToShow;
  }


  getTextBydata(element, columnDef) {
    var textToShow = '';
    switch (columnDef.name) {
      case 'Notes':

        if (element.notes && element.notes.length > 0) {
          textToShow = element.notes[element.notes.length - 1].note;
        } else {
          textToShow = "...";
        }
        break;
      case 'RQ $':
      case "Datascape $":
      case "Difference":
        textToShow = (element[columnDef.mappedToProperty]).toFixed(2);
        textToShow = this.moneySymbol + textToShow;
        break;
      case "Date":
        let dt = new Date(element[columnDef.mappedToProperty]);
        textToShow = moment(dt).format(this._durationService.format1);

        break;
      default:
        textToShow = element[columnDef.mappedToProperty];
    }
    return textToShow;
  }

  selectedColumns = this.getDisplayedColumns();
  columnsChanged(event: MatSelectChange) {
    for (var i = 0; i < this.columnDefinations.length; i++) {
      var column = this.selectedColumns.find(x => x == this.columnDefinations[i].mappedToProperty);
      if (column != null) {
        this.columnDefinations[i].hide = false;
      } else {
        this.columnDefinations[i].hide = true;
      }
    }
  }

  filterByProperty(collection, value, key) {
    return collection.filter(o => key.reduce((c, v) => c[v] || {}, o) === value);
  }

  notEqualToZero(element, index, array) {
    return (element.difference !== 0);
  }

  isReconciled(element, index, array) {
    return (element.isHidden);
  }

  isNotReconciled(element, index, array) {
    return (!element.isHidden);
  }


  updateDataSource(data: BillPaymentViewModel) {
    let mtnItems = data.Items;
    if (!this.showAll) {
      mtnItems = mtnItems.filter(this.notEqualToZero);
    }
    if (this.showReconciled) {
      mtnItems = mtnItems.filter(this.isReconciled);
    } else {
      mtnItems = mtnItems.filter(this.isNotReconciled);
    }

    let filteredItemsByDate = mtnItems.filter(x => this.checkDates(x));
    this.dataSource = new MatTableDataSource(filteredItemsByDate);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
    this.dialogRef?.close();
  }

  findLatestDateOfNonPOSData(mtnList: any) {
    var tempDate ;
    this.monthYear = '0' + ((new Date(this.selectedDuration.duration.FromDate)).getMonth() + 1) + (new Date(this.selectedDuration.duration.FromDate)).getFullYear();
    if (this.monthYear.length > 6) this.monthYear = this.monthYear.substring(1, 7);
    mtnList.forEach(x => {
      if (x.dateCharged && (!tempDate || x.dateCharged > tempDate))
        tempDate = x.dateCharged;
    });
    this.filterDate = tempDate;
    this.recentDataDate = moment(this.filterDate).format(this._durationService.format1);
    this.filterDays(0);
  }
  //rqCollected
  findIconType(element: any) {
    return element['rqCollected'] !== element['dsCharged'] ? this.iconDefinition[1] : this.iconDefinition[2];
  }

  getIconColor(element: any) {
    return this.findIconType(element).color;
  }

  getIconHover(element: any) {
    return this.findIconType(element).toolTip;
  }

  getDateFromIgnore() {
    return moment(this.filterDate).format(this._durationService.format1);
  }

  filterDays(add: number) {
    this.daysToIgnore += add;
    var temp1 = (new Date(this.filterDate));
    temp1.setDate(temp1.getDate() - add);
    this.filterDate = temp1;
    this.updateDataSource(this.data);
  }

  checkDates(element) {
    let newDate = Date.parse(this.filterDate.toDateString());
    let itemDate = Date.parse(element['date']);
    if (newDate >= itemDate) {
      return true;
    }
    return false;
  }


  onClientSelected() {
    if (!this.selectedClientId || +this.selectedClientId === 0) {
      return;
    }
    this.selectedClientId = +this.selectedClientId;
  }
  ngOnInit() {
    this.dateRequest = moment().toDate();
    //this.startDate = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0));
    //this.endDate = this.startDate;
    this.durations = this._durationService.getDuration();
    this.duration = 3;
    this.selectedDuration = this.durations.find(x => x.value === this.duration);
    let tempDate = new Date(this.selectedDuration.duration.FromDate);
    this.monthYear = '0' + (tempDate.getMonth() + 1) + tempDate.getFullYear();
    if (this.monthYear.length > 6) this.monthYear = this.monthYear.substring(1, 7);
    this.filterDate = moment().toDate();
    this.todayDate = moment().toDate();
    this._locationService.currentLocations
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
        if (message != null) {
          this.selectedClientId = message[1];
          this.selectedRegionId = message[2];
          this.selectedDistrictId = message[3];
          this.selectedStoreId = message[4];
          this.onClientSelected();
          this.getDatascapeReport();
        }
      });
  }

  OnShowAllToggle(oevent) {
    this.showAll = !this.showAll;
    this.updateDataSource(this.data);
    this.applyFilter(this.lastFilter);
  }

  OnShowReconciledToggle(oevent) {
    this.showReconciled = !this.showReconciled;
    this.updateDataSource(this.data);
    this.applyFilter(this.lastFilter);
  }

  sortTableEmployee() {
    this.isAscendingOrder = !this.isAscendingOrder;
    this.currentRebateList.sort((a, b) => this.isAscendingOrder ?
      (a.soldBy.user.name.localeCompare(b.soldBy.user.name) === 1) ? 1 : -1 :
      (a.soldBy.user.name.localeCompare(b.soldBy.user.name) === -1) ? 1 : -1);
  }

  applyFilter(filterValue: string) {
    this.lastFilter = filterValue;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  convertNumberToString(amount: number) {
    let response = '';
    response = amount.toFixed(2).toString();
    if (Math.sign(amount) === -1) {
      response = response.replace('-', '($') + ')';
    } else {
      response = '$' + response;
    }
    return response;
  }

  getDatascapeReport() {
    this.dataSource = new MatTableDataSource();
    if (this.selectedDuration) {
      this._loaderService.show();
      let locationToSend = 0;
      if (this.selectedStoreId !== 0) {
        locationToSend = this.selectedStoreId;
      } else if (this.selectedDistrictId !== 0) {
        locationToSend = this.selectedDistrictId;
      } else if (this.selectedRegionId !== 0) {
        locationToSend = this.selectedRegionId;
      }
      this.selectedLocationId = locationToSend;
      let tempDate = new Date(this.selectedDuration.duration.FromDate);
      this.monthYear = '0' + (tempDate.getMonth() + 1) + tempDate.getFullYear();
      if (this.monthYear.length > 6) this.monthYear = this.monthYear.substring(1, 7);
      const dateToSend = this.monthYear.substr(2, 4) + this.monthYear.substr(0, 2);
      this._datascapeService.getDatascapeReport(locationToSend, dateToSend)
        .subscribe((response) => {
          this.data.Items = response.items;
          if (this.data && this.data.Items && this.data.Items.length > 0) {
            this.data.TotalCharged = response.totalCharged;
            this.data.TotalCollected = response.totalCollected;
            this.originalData = response;
            this.selectedMTN = JSON.parse(JSON.stringify(this.data.Items[0]));
            this.selectedMTN.mtn = '00' // to not to select any row the first time
            this.doneLoading = true;

            this.updateDataSource(this.data);
            this.applyFilter(this.lastFilter);

          }
          this.findLatestDateOfNonPOSData(this.data.Items);

        }, (error) => this._notifier.notify('error', error.error), () => { this._loaderService.hide(); });
    }
  }

  getFormattedDateTime(unformattedDateTime) {
    this.uploadStatus = `${moment(unformattedDateTime).format('L')} ${moment(unformattedDateTime).format('LT')}`;
  }

  /**Duration */
  durations: Duration[];
  selectedMonth: any;
  duration: number = 0;
  subscriptionToLocations: Subscription;
  date = new FormControl(moment());
  selectedDuration: Duration;
  yearHandler(year: moment.Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(year.year());
    this.date.setValue(ctrlValue);
  }
  monthHandler(month: any, datepicker: MatDatepicker<moment.Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(month.month());
    ctrlValue.year(month.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.selectedDuration.duration.FromDate = month.startOf("month").format(this._durationService.dateStartFormat).toString();
    this.selectedDuration.duration.ToDate = month.endOf("month").format(this._durationService.dateEndFormat).toString();
    let tempDate = new Date(this.selectedDuration.duration.FromDate);
    this.monthYear = '0' + (tempDate.getMonth() + 1) + tempDate.getFullYear();
    if (this.monthYear.length > 6) this.monthYear = this.monthYear.substring(1, 7);
    this.getDatascapeReport();
  }

  toBeReconciled = new Map<string, ToHideReconciled>();
  toBeUnreconciled = new Map<string, ToHideReconciled>();
  collectToReconcile(event: MatCheckboxChange, element) {
    let tempDate = new Date(this.selectedDuration.duration.FromDate);
    this.monthYear = '0' + (tempDate.getMonth() + 1) + tempDate.getFullYear();
    if (this.monthYear.length > 6) this.monthYear = this.monthYear.substring(1, 7);
    let key = element['locationId'] + element['mtn'] + this.monthYear.substr(0, 4) + this.monthYear.substr(5, 2);
    let value: ToHideReconciled = {
      locationId: element['locationId'], mtn: element['mtn'],
      yearmonth: this.monthYear.substr(2, 4) + this.monthYear.substr(0, 2)
    };
    if (event.checked) {
      this.toBeReconciled.set(key, value);
      this.toBeUnreconciled.delete(key);
    } else {
      this.toBeReconciled.delete(key);
      this.toBeUnreconciled.set(key, value);
    }
  }

  reconcileDialog: any;
  hideMtnSelected(isReconcile) {
    this.reconcileDialog = this.dialog.open(ConfirmDialogComponent,
      {
        autoFocus: true, disableClose: false, panelClass: '',
        data: { title: "Confirm", message: "Are you sure you want to reconcile selected?" }
      });
    this.reconcileDialog.afterClosed().subscribe(result => {
      if (result === true) {
        if (isReconcile) {
          this._datascapeService.hideMtnMultiple(Array.from(this.toBeReconciled.values())).subscribe((response) => {
            this._notifier.notify('success', 'Reconciled successfully!');
            this.toBeReconciled.clear();
            this.getDatascapeReport();
          },
            (errorResponse) => { this._notifier.notify('error', errorResponse.error) },
            () => { });
        } else {
          this._datascapeService.hideMtnMultiple(Array.from(this.toBeUnreconciled.values())).subscribe((response) => {
            this._notifier.notify('success', 'Unreconciled successfully!');
            this.toBeUnreconciled.clear();
            this.getDatascapeReport();
          },
            (errorResponse) => { this._notifier.notify('error', errorResponse.error) },
            () => { });
        }

      }
    });
  }

  addNote(element) {
    let yearMonth = this.monthYear.substring(2, 5) + this.monthYear.substring(0, 1);
    this._datascapeService.addNote(element['locationId'], element['mtn'], yearMonth, this.noteToAdd).subscribe(
      (response) => {
        const thisSerial = this.data.Items.find(x => x['mtn'] === element['mtn']);
        thisSerial['notes'].push({ 'id': response, 'note': this.noteToAdd });
        this.updateDataSource(this.data);
      },
      (error) => this._notifier.notify('error', error.error),
      () => { });
  }

  editNote(element, note: string, noteId: number) {
    this._datascapeService.editNote(noteId, note).subscribe((response) => {
      const thisSerial = this.data.Items.find(x => x['mtn'] === element['mtn']);
      const thisNote = thisSerial['notes'].find(x => x.id === noteId);
      thisNote.note = note;
      this.updateDataSource(this.data);
    },
      (error) => this._notifier.notify('error', error.error),
      () => { });
  }

  deleteNote(element, noteId: number) {
    this._datascapeService.deleteNote(noteId).subscribe((response) => {
      const thisSerial = this.data.Items.find(x => x['mtn'] === element['mtn']);
      const thisNoteIndex = thisSerial['notes'].findIndex(x => x.id === noteId);
      thisSerial['notes'].splice(thisNoteIndex, 1);
      this.updateDataSource(this.data);
    },
      (error) => this._notifier.notify('error', error.error),
      () => { });
  }

  selectMTN(row) {
    if (this.selectedMTN !== row) {
      this.selectedMTN = row;
      this.noteToAdd = "";
    }
  }
}

type ToHideReconciled = {
  locationId: number;
  mtn: string;
  yearmonth: string;
};
