import { Seller } from './seller';

export class SellingGroup {

  id?: number;
  name?: string;
  locationTierEnum?: number;
  isLocation?: boolean;
  sellers?: Seller[];
}
