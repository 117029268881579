<button mat-icon-button class="close-button" (click)="onDismiss()">
  <mat-icon class="close-icon" >close</mat-icon>
</button>
<h1 mat-dialog-title class="heading-text">{{title}}</h1>
<form  #f="ngForm" novalidate>
    <div class="custom-input-box w-100">
       <mat-select placeholder="Select Formula" [(ngModel)]="newTableGroupColumn.formulaId" #formulaId="ngModel" name="formulaId" required>
        <mat-option *ngFor="let formula of formulas" [value]="formula.id">{{formula.name}}</mat-option>
      </mat-select>
    </div>
    <mat-error *ngIf="formulaId.invalid">Formula is required.</mat-error>
  <div  class="d-flex align-items-center mt-4">
    <button mat-raised-button (click)="addNewReportFormula()" color="primary" type="button" >Create Formula
      &nbsp;<i class="fa fa-plus"></i></button>
    <button mat-raised-button 
    (click)="editFormula(newTableGroupColumn.formulaId)" 
    [disabled]="newTableGroupColumn.formulaId < 1 " color="primary" type="button" class="ml-4">Edit Formula
      &nbsp;<i class="fa fa-edit"></i></button>
  </div>
</form>

<div class="d-flex align-items-center mt-4 pt-1">
  <button mat-raised-button color="primary" [disabled]="" class="submit-btn" (click)="onConfirm()">Submit</button>
  <button mat-button class="clear-btn ml-3" (click)="onDismiss()">Clear</button>
</div>
