import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { SkuGroupService } from './sku-group.service';
import { TableService } from '../services/table.service';
import { Formula } from '../models/formula';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../../core/services/auth.service';
import { LocationService } from './location.service';

declare const _;

@Injectable({
  providedIn: 'root'
})
export class FormulaService {
  private _baseUrl = '/api/formulas';
  private initialDecisions: string[] = ['(', 'GP', 'QTY'];
  private initialDecisions2: string[] = ['(', 'GP', 'QTY', 'GOAL', 'DATE', 'REBIZ', "HOURS"];
  private rebizDecisions: string[] = ['Boxes', 'Traffic', 'GrossProfit'];
  private dateDecisions2: string[] = ['Passed', 'Remaining', 'DaysInMonth'];
  private hoursDecision: string[] = ['Worked'];
  private operatorDecisions: string[] = ['+', '-', '*', '/', ')', '('];
  private skuGroupsDecisions: string[] = [];
  private formulaDecisions2: string[] = [];
  private formulas: Formula[] = [];

  constructor(private http: HttpClient,
    private skuGroupService: SkuGroupService,
    private _notifier: NotifierService,
    private _tableService: TableService,
    private _locationService: LocationService,
    private _authService: AuthService) {
    this.getSkuGroupsAsDecisions(this._authService.clientId());
    this.getFormulasAsDecisions(this._authService.clientId());
  }

  getAllFormulas(): Observable<Formula[]> {
    return this.http.get<Formula[]>(this._baseUrl);
  }

  getFormulas(clientId: number, callback: any, forceLoading: boolean = false) {
    if (this.formulas.length === 0) {
      this.getAllFormulas().subscribe((response: Formula[]) => {
        response = response.filter(x => x.clientId === clientId);
        response.forEach(function (formula) { formula.position = +formula.position; });
        this.formulas = this._tableService.sorting(response, 'position', true);
        callback(response);
      }, (error) => { this._notifier.notify('error', error.message); }, () => { });
    } else {
      if (forceLoading) {
        this.getAllFormulas().subscribe((response: Formula[]) => {
          response = response.filter(x => x.clientId === clientId);
          response.forEach(function (formula) { formula.position = +formula.position; });
          this.formulas = this._tableService.sorting(response, 'position', true);
          callback(response);
        }, (error) => { this._notifier.notify('error', error.message); }, () => { });
      } else {
        callback(this.formulas);
      }
    }
  }



  getNextDecisionsOld(currentDecision: string = null): Observable<string[]> {
    let decisions: string[] = null;

    switch (currentDecision) {
      case '(':
      case '+':
      case '-':
      case '*':
      case '/':
        decisions = this.initialDecisions;
        break;
      case ')':
        decisions = this.operatorDecisions;
        break;
      case 'GP':
      case 'QTY':
        decisions = this.skuGroupsDecisions;
        break;
      default:
        decisions = this.operatorDecisions;
        break;
    }

    return of(decisions);
  }
  getNextDecisions(currentDecision: string = null): Observable<string[]> {
    let decisions: string[] = null;

    switch (currentDecision) {
      case '(':
      case '+':
      case '-':
      case '*':
      case '/':
        decisions = this.initialDecisions2;
        break;
      case ')':
        decisions = this.operatorDecisions;
        break;
      case 'GP':
      case 'QTY':
        decisions = this.skuGroupsDecisions;
        break;
      case 'GOAL':
        decisions = this.formulaDecisions2;
        break;
      case 'DATE':
        decisions = this.dateDecisions2;
        break;
      case 'FORM':
        decisions = this.formulaDecisions2;
        break;
      case 'REBIZ':
        decisions = this.rebizDecisions;
        break;
      case 'HOURS':
        decisions = this.hoursDecision;
        break;
      default:
        decisions = this.operatorDecisions;
        break;
    }

    return of(decisions);
  }
  getSkuGroupsAsDecisions(clientId: number) {
    this.skuGroupService.getSkuGroupsByClient(clientId, (response) => {
      this.skuGroupsDecisions = response.map(function (skugroup) { return skugroup.name; });
    });
    /*, (response: any[]) => {

        this.skuGroupsDecisions = response.map(function (skugroup) { return skugroup.name; });
      }, () => { }, () => console.log('getSkuGroupsDecisions()'));*/
  }
  getFormulasAsDecisions(clientId: number) {
    this.getAllFormulas()
      .subscribe((response: any[]) => {
        response = response.filter(x => x.clientId == clientId);
        this.formulaDecisions2 = response.map(function (formula) { return formula.name; });
      }, () => { }, () => { });
  }

  createFormula(name: string, clientId: number, formulaValue: string,
    color: string, position: string, trendingPossible: boolean,
    isPercentage: boolean, averageAchievedForRewards: boolean, isMoney: boolean, isDefaultVisibleOnEmployeeAndCompareMTDReport: boolean,
    decimalLength: string, belowAverageLimit: string,
    warningLimit: string, formulaViewGroupId: number, containsComplexFormula: boolean): Observable<any> {

    const formula = {
      name,
      clientId,
      formulaValue,
      color,
      position,
      trendingPossible,
      isPercentage,
      averageAchievedForRewards,
      isMoney,
      isDefaultVisibleOnEmployeeAndCompareMTDReport,
      decimalLength,
      belowAverageLimit,
      warningLimit,
      formulaViewGroupId,
      containsComplexFormula
    };

    return this.http.post(this._baseUrl, formula);
  }

  updateFormula(id: number, name: string, formulaValue: string,
    color: string, position: string, trendingPossible: boolean,
    isPercentage: boolean, averageAchievedForRewards: boolean, isMoney: boolean, isDefaultVisibleOnEmployeeAndCompareMTDReport: boolean,
    decimalLength: string, belowAverageLimit: string,
    warningLimit: string, formulaViewGroupId: number, containsComplexFormula: boolean): Observable<any> {

    const formula = {
      name,
      formulaValue,
      color,
      position,
      trendingPossible,
      isPercentage,
      averageAchievedForRewards,
      isMoney,
      isDefaultVisibleOnEmployeeAndCompareMTDReport,
      decimalLength,
      belowAverageLimit,
      warningLimit,
      formulaViewGroupId,
      containsComplexFormula
    };

    return this.http.put(this._baseUrl + '/' + id, formula);
  }

  deleteFormula(id: string): Observable<any> {
    return this.http.delete(this._baseUrl + '/' + id);
  }
}
