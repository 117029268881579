<div class="row width-100">
  <div class="column commission-reconcile-data-table">
    <mat-form-field style="width: 20%; margin-right: 2%;top:-2px;">
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" [(ngModel)]="searchTerm">
    </mat-form-field>
    <button mat-raised-button (click)="sendListOfImeis(4)" style=" margin-right: 10px" color="primary">Reset selected</button>
    <button mat-raised-button (click)="exportTableToExcel()" style=" margin-right: 10px" color="primary" >Export</button>
    <table mat-table [dataSource]="dataSource" matSort matSortActive="symbol" matSortDirection="asc" class="table table-bordered table-hover small-txt">
      <ng-container matColumnDef="{{column}}" *ngFor="let column of selectedColumns; let i = index;">
        <span *ngIf="i === 0">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="sort-column mat-sort-header-button-custom mat-header-cell">
            <mat-checkbox (change)="$event?selectAll($event):null" [checked]="allSelected" [disabled]="noData| async"></mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row"  [ngClass]="{'yellow':row?.imei==selectedItem?.imei && row?.mtn == selectedItem?.mtn}">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectImei(row):null" [checked]="row.isSelected"></mat-checkbox>
          </td>
        </span>
        <span *ngIf="i >= 0 &&i<=8">
          <th class="sort-column mat-sort-header-button-custom mat-header-cell" mat-header-cell *matHeaderCellDef mat-sort-header [matTooltip]="getColumnDefinationByMappedToProperty(column).name" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip>
            {{getColumnDefinationByMappedToProperty(column).name}}
          </th>
          <td mat-cell *matCellDef="let element" class="mat-cell" [matTooltip]="getTextByDataDismissed(element,getColumnDefinationByMappedToProperty(column))" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip
              [ngClass]="{'mat-cell-highlight-red':element.imei==selectedItem.imei,'price':getColumnDefinationByMappedToProperty(column).class=='price'}">
            {{getTextByDataDismissed(element,getColumnDefinationByMappedToProperty(column))}}
          </td>
        </span>
        <span *ngIf="i >=9">
          <th class="sort-column mat-sort-header-button-custom mat-header-cell" mat-header-cell *matHeaderCellDef mat-sort-header [matTooltip]="getColumnDefinationByMappedToProperty(column).name" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip>
            {{getColumnDefinationByMappedToProperty(column).name}}
          </th>
          <td mat-cell *matCellDef="let element" class="mat-cell" [matTooltip]="element[getColumnDefinationByMappedToProperty(column).mappedToProperty]" [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip
              [ngClass]="{'mat-cell-highlight-red':element.imei==selectedItem.imei,'price':getColumnDefinationByMappedToProperty(column).class=='price'}">
            <textarea matInput placeholder="" [value]="element.comment" [(ngModel)]="element.comment"></textarea>
          </td>

        </span>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="selectedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: selectedColumns;" (click)="selectItem(row)"></tr>
    </table>
    <div *ngIf="noData | async" class="no-records">
      No records found
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="10" showFirstLastButtons></mat-paginator>
  </div>
  <mat-divider [vertical]="true" *ngIf="selectedImei" class="margin-left-1em"></mat-divider>
  <div class="column margin-left-1em rq-vz-data-table" *ngIf="selectedImei">
    <rq-vz-data-table [selectedImei]="selectedImei" *ngIf="selectedImei"></rq-vz-data-table>
  </div>
</div>
