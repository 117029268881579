<button mat-icon-button class="close-button" (click)="onDismiss()">
  <mat-icon class="close-icon">close</mat-icon>
</button>
<h1 class="heading-text" mat-dialog-title>{{manualRewardDialog.headerText}}</h1>
<form #form="ngForm">
  <div class="custom-input-box create-form-group-width-set">
    <input matInput type="text" [(ngModel)]="earnedRewardModel.description" name="description" placeholder="Description" />
  </div>
  <div class="custom-input-box create-form-group-width-set mt-4 d-flex">
    <mat-select placeholder="Select Employee" [errorStateMatcher]="matcher" id="employee" name="employee" [formControl]="employee" [(ngModel)]="earnedRewardModel.employeeId" required>
      <mat-option *ngFor="let employee of employees" [value]="employee.id">{{employee.user.name}}</mat-option>
    </mat-select>
    <mat-error *ngIf="employee.errors?.required">Manual Reward Requires an Employee.</mat-error>
  </div>
  <div class="custom-input-box create-form-group-width-set mt-4 d-flex">
    <input [errorStateMatcher]="matcher" matInput type="number" [(ngModel)]="earnedRewardModel.money" placeholder="Amount" [formControl]="amount" />
  </div>
  <mat-error *ngIf="amount.hasError('required')">Amount is required.</mat-error>
  <mat-error *ngIf="amount.hasError('max')">Amount can not be more than $50000.</mat-error>
</form>
<div class="d-flex align-items-center mt-4 pt-1">
  <button mat-raised-button color="primary" class="submit-btn" [disabled]="form.invalid" (click)="onConfirm()">Submit</button>
  <button mat-button class="clear-btn ml-3" (click)="onDismiss()">Clear</button>
</div>
