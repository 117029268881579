import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../../core/services/auth.service';
import { ClientService } from '../services/client.service';
import { LocationService } from '../services/location.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UploadStatusService } from '../services/upload-status.service';
import * as moment from 'moment';


@Component({
  selector: 'app-upload-status',
  templateUrl: './upload-status.component.html'
})
export class UploadStatusComponent implements OnInit, OnDestroy {
  clientId: number;
  stati: any;
  subscriptionToClients: Subscription;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  

  constructor(private _uploadStatusService: UploadStatusService,
    private _notifier: NotifierService,
    private _authService: AuthService,
    private _clientService: ClientService,
    private _locationService: LocationService) { }

  ngOnInit() {
    this.subscriptionToClients = this._locationService.currentActualClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {
      this.clientId = message; this.onClientSelected();
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
  }

 

  onClientSelected() {
    this._uploadStatusService.getUploadLog(this.clientId).subscribe((response) => {
      this.stati = response;

    });
  }

  getTimeDifference(time: string) {
    var now = new Date();
    var then = new Date(this.getFormattedDateTime(time));
    var diffInSeconds =  this.dateDiffInDays(now, then);
    
    return this.myFormat(diffInSeconds);
  }

  getFormattedDateTime(unformattedDateTime) {
    return `${moment(unformattedDateTime).format('L')} ${moment(unformattedDateTime).format('LT')}`;
  }

  myFormat(secs) { 
    var x = moment.utc(secs);
    var dayNum = parseInt(x.format('D')) - 1;
    return ('0' + dayNum).slice(-2) + x.format(':HH:mm');
}

  dateDiffInDays(b, a) {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate(), a.getHours(), a.getMinutes());
  const utc2 = Date.UTC(b.getFullYear(), (b.getMonth()), b.getDate(), b.getHours(), b.getMinutes());
  return Math.floor((utc2 - utc1));
}

  
}
