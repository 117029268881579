
<div class="d-flex align-items-center justify-content-between action-header ">
  <div class="d-flex align-items-center">
    <mat-form-field class="bottom-border width-set">
      <input matInput (blur)="applyFilter($event.target.value)" (keyup)="applyFilter($event.target.value)" placeholder="Search" [(ngModel)]="rewardSearchRequest.SearchTerm">
    </mat-form-field>

    <mat-form-field class="bottom-border width-set standard-margin-left">
      <mat-select placeholder="Select columns" multiple [(value)]="selectedColumns" (selectionChange)="columnsChanged($event)">
        <mat-select-trigger>
          {{selectedColumns ? getColumnDefinationByMappedToProperty(selectedColumns[0]).name : ''}}
          <span *ngIf="selectedColumns?.length > 1" class="example-additional-selection">(+{{selectedColumns.length - 1}} {{selectedColumns?.length === 2 ? 'other' : 'others'}})</span>
        </mat-select-trigger>
        <mat-option *ngFor="let column of columnDefinations" [value]="column.mappedToProperty">{{column.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <button color="primary" class="action-primary-btn px-5" mat-raised-button type="button" (click)="openCreateModal()"> Create </button>
    <button color="primary" class="action-success-btn ml-4" mat-raised-button type="button" (click)="finalizeRewardGames()"> Finalize </button>
  </div>

</div>
<div class="table-margin-top table-container">
  <table #TABLE *ngIf="selectedColumns.length>0 " mat-table [dataSource]="dataSource" matSort class="table table-bordered table-hover small-txt" [hidden]="!tableready">
    <ng-container *ngFor="let column of getDisplayedColumns(); let colIndex = index;" [matColumnDef]="column">
      <th [hidden]="getColumnDefinationByMappedToProperty(column).hide" class="sort-column mat-sort-header-button-custom mat-header-cell" mat-header-cell *matHeaderCellDef
          [matTooltip]="getColumnDefinationByMappedToProperty(column).name" [disabled]="!getColumnDefinationByMappedToProperty(column).isSortable">
        <div>
          <span mat-sort-header> {{getColumnDefinationByMappedToProperty(column).name}}</span>
          <div [ngSwitch]="getColumnDefinationByMappedToProperty(column).name">

            <div *ngSwitchCase="'Sales Person'">
              <mat-select placeholder="Select user" [(value)]="filterMenu.createdBy" (selectionChange)="applySelectedFilter()">
                <mat-option [value]="'Everyone'">Everyone</mat-option>
                <mat-option *ngFor="let user of createdByList" [value]="user">{{user}}</mat-option>
              </mat-select>
            </div>

            <div *ngSwitchCase="'Status'">
              <mat-select placeholder="Select status" [(value)]="filterMenu.status" (selectionChange)="applySelectedFilter()">
                <mat-option [value]="-1">All</mat-option>
                <mat-option *ngFor="let status of rewardGameStatusesKeys" [value]="status">{{rewardGameStatuses[status]}}</mat-option>
              </mat-select>
            </div>
            <div *ngSwitchCase="'Type'">
              <mat-select placeholder="Select type" [(value)]="filterMenu.type" (selectionChange)="applySelectedFilter()">
                <mat-option [value]="-1">All</mat-option>
                <mat-option *ngFor="let type of rewardGameTypesKeys" [value]="type">{{rewardGameTypes[type]}}</mat-option>
              </mat-select>

            </div>
            <div *ngSwitchCase="'Metric'">
              <mat-select placeholder="Select formula" [(value)]="filterMenu.formula" (selectionChange)="applySelectedFilter()">
                <mat-option [value]="-1">All</mat-option>
                <mat-option *ngFor="let formula of formulas" [value]="formula.id">{{formula.name}}</mat-option>
              </mat-select>
            </div>
            <div *ngSwitchCase="'CreatedDate'">
              <mat-select placeholder="Select duration" [(value)]="filterMenu.duration" (selectionChange)="applySelectedFilter()">
                <mat-option [value]="-1">All</mat-option>
                <mat-option *ngFor="let duration of durations" [value]="duration.value">{{duration.viewValue}}</mat-option>
              </mat-select>
            </div>
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let element" class="mat-cell" [matTooltip]="getTextBydata(element,getColumnDefinationByMappedToProperty(column))">
        <span *ngIf="column.name !== 'manage' && column !== 'isHidden'">{{getTextBydata(element,getColumnDefinationByMappedToProperty(column))}}</span>
        <span *ngIf="column == 'manage' && element.status == 0">
          <a color="primary" type="button" (click)="openEditModal(element)" class="icon-btn"><i class="fa fa-edit"></i></a>
          <a color="primary" type="button" (click)="openDeleteModal(element)" class="icon-btn"><i class="fa fa-trash-alt"></i></a>
        </span>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
    <tr mat-row *matRowDef="let row; columns: getDisplayedColumns(); ">
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="20" showFirstLastButtons [hidden]="!tableready"></mat-paginator>
</div>
