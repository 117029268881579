export enum PermissionCode {

  SalesMenu = 'P1',
  InventoryMenu = 'P2',
  EmployeesMenu = 'P3',
  AdminMenu = 'P4',
  SalesMTD = 'P5',
  Dashboard = 'P6',
  Rank = 'P7',
  SalesByInvoice = 'P8',
  Inventory = 'P9',
  EmployeeCommissionsSpiff = 'P10',
  CommissionUser = 'P11',
  Users = 'P12',
  RoleManager = 'P13',
  Import = 'P14',
  CommissionReconcile = 'P15',
  FormulaViewGroups = 'P16',
  Formulas = 'P17',
  SkuGroups = 'P18',
  PerformanceSummary = 'P19',
  PSIndividual = 'P20',
  PSStore = 'P21',
  PSDistrict = 'P22',
  PSRegion = 'P23',
  PSClientWide = 'P24',
  PSOmniClient = 'P25',
  EditRank = 'P26',
  DailyCommissionReconcile = 'P27',
  DatascapeReconcile = 'P28',
  ShippingReconcile = 'P29',
  CreateRewardGame = 'P30',
  RewardsReconcile = 'P31',
  RewardsDashboard = 'P32',
  DashboardSingle = 'P33'
}
