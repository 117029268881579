import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandleService {
  constructor() { }

  public handleError(error: any) {
    console.error('server error:', error);

    if (error instanceof HttpErrorResponse) {
      let errMessage = '';
      try {
        errMessage = error.error;
      } catch (err) {
        errMessage = error.statusText;
      }
      return observableThrowError(errMessage);
    }
    return observableThrowError(error || 'Unkown error');
  }
}
