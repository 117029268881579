import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NotifierService } from 'angular-notifier';
import { Location } from '../models/location';
import { Subclient } from '../models/subclient';
import { SubclientSettingOption } from '../models/subclientSettingOption';
import { SubclientVisibilitySetting } from '../models/subclientVisibilitySetting';
import { SubclientViewingSetting } from '../models/subclientViewingSetting';
import { SubclientVisibilitySettingOption } from '../models/subclientVisibilitySettingOption';

@Injectable({
  providedIn: 'root'
})
export class SubclientService {
  private _baseUrl = '/api/subclient';
  locations: Location[] = [];

  constructor(private http: HttpClient,
    private _notifier: NotifierService
  ) { }

  createSubclient(userId: string, locationId: number): Observable<Subclient[]> {
    const subclient = {
      userId,
      locationId
    };
      return this.http.post<Subclient[]>(this._baseUrl, subclient);
  }

  editSubclientSettings(subclient: Subclient): Observable<Subclient[]> {
    return this.http.post<Subclient[]>(this._baseUrl + '/editSubclient', subclient);
  }

  deleteSubclient(subclient: Subclient): Observable<Subclient[]> {
    return this.http.post<Subclient[]>(this._baseUrl + '/deleteSubclient', subclient);
  }

  getSubclientsByClientId(clientId: number): Observable<Subclient[]> {
    return this.http.get<Subclient[]>(this._baseUrl + '/' + clientId);
  }


  getSubclientSettingOptions(): Observable<SubclientSettingOption[]> {
    return this.http.get<SubclientSettingOption[]>(this._baseUrl + '/subclientSettingOption');
  }

  getSubclientVisibilitySettingOptions(): Observable<SubclientVisibilitySettingOption[]> {
    return this.http.get<SubclientVisibilitySettingOption[]>(this._baseUrl + '/subclientVisibilitySettingOption');
  }

  setSubclientViewSettings(subclientId, locationId, reportTypeId): Observable<SubclientViewingSetting[]> {
    return this.http.get<SubclientViewingSetting[]>(this._baseUrl
      + '/setSubclientViewingSetting/' + subclientId + '/' + locationId + '/' + reportTypeId);
  }

  getSubclientViewSettings(subclientId, reportTypeId): Observable<SubclientViewingSetting[]> {
    return this.http.get<SubclientViewingSetting[]>(this._baseUrl + '/getSubclientViewingSetting/' + subclientId + '/' + reportTypeId);
  }

  setSubclientVisibilitySettingOptions(subclientVisibilitySetting: SubclientVisibilitySetting ): Observable<Subclient> {
    return this.http.post<Subclient>(this._baseUrl + '/editSubclientVisibilitySetting', subclientVisibilitySetting);
  }

  getSubclientVisibilityTree(subclientId: number, reportTypeId: number): Observable<Location[]> {
    return this.http.get<Location[]>(this._baseUrl + '/visiblelocations/' + subclientId + '/' + reportTypeId);
  }
}
