import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Permission } from '../models/permission';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private _baseUrl = '/api/permissions';

  constructor(private http: HttpClient) {}

  getPermissions(): Observable<Permission[]> {
    return this.http.get<Permission[]>(this._baseUrl);
  }

  getPermissionsByRole(roleName: string): Observable<Permission[]> {
    return this.http.get<Permission[]>(this._baseUrl + '/' + roleName);
  }
}
