import { Component, OnInit } from '@angular/core';
import { Permission } from '../models/permission';
import { Role } from '../models/role';
import { PermissionService } from '../services/permission.service';
import { RoleService } from '../services/role.service';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html'
})
export class RoleComponent implements OnInit {
  model: any = {};
  permissions: Permission[] = [];
  selectedPermissions: Permission[] = [];
  roles: Role[] = [];
  roleId: string = null;
  permissionSelectSettings = {
    idField: 'id',
    textField: 'description'
  };

  constructor(
    private _permissionService: PermissionService,
    private _roleService: RoleService,
    private _authService: AuthService,
    private _notifier: NotifierService) { }

  ngOnInit() {
    this.getPermissions();
    this.getRoles();
  }

  getPermissions() {
    this._permissionService.getPermissions()
      .subscribe((response: Permission[]) => {
        this.permissions = response;
      }, (error) => this._notifier.notify('error', error.error), () => {});
  }

  getRoles() {
    const clientId: number = this._authService.clientId();

    this._roleService.getRoles(clientId, this._authService.roles())
      .subscribe((response: Role[]) => {
        this.roles = response;
      }, (error) => this._notifier.notify('error', error.error), () => {});
  }

  showPermissions() {
    const roleId = this.model.roleId;
    if (this.roles.filter(role => role.id === roleId)) {
      this.model.selectedPermissions = this.roles.filter(role => role.id === roleId)[0].permissions;
    } else {
      this.model.selectedPermissions = [];
    }
  }

  saveRoles() {
    this.roles.forEach((role) => {
      if (role.id === this.model.roleId) {
        const permissions = this.model.selectedPermissions.map((permission) => {
          return { Permission: { Id: permission.id, Description: permission.description } };
        });

        /*this._roleService.updateRole(role.id, role.name, this._authService.clientId(),  permissions).subscribe((response: string) => {
          this.getRoles();
          this._notifier.notify('success', 'Update role permissions successfully.');
        }, (error) => this._notifier.notify('error', error.message), () => {});*/
      }
    });
  }
}
