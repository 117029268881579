import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { EnsureModuleLoadedOnceGuard } from './models/ensure-module-loaded-once-guard';
import { AuthGuardService } from './services/auth-gaurd.service';
import { WindowRef } from './window-ref.service';
import { HeaderInterceptorService as HeaderInterceptor } from './services/interceptors/header-interceptor.service';
import { ErrorHandleInterceptorService as ErrorHandleInterceptor } from './services/interceptors/error-handle-interceptor.service';
import { HeaderComponent } from './header/header.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CenterContentLayoutComponent } from './center-content-layout/center-content-layout.component';
import { ColumnContentLayoutComponent } from './column-content-layout/column-content-layout.component';
import { RouterModule } from '@angular/router';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { TokenStorage } from './services/token-storage.service';
import { LoggedInUserDetails } from './services/user-details.service';
import { MaterialModule } from '../home/material.module';
//import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb';

export function jwtOptionsFactory(tokenStorage: TokenStorage) {
  return {
    tokenGetter: () => {
      return tokenStorage.getToken();
    }
  };
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    HttpClientModule,
    RouterModule,
    //NgDynamicBreadcrumbModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [TokenStorage]
      }
    })
  ],
  providers: [
    AuthService,
    AuthGuardService,
    TokenStorage,
    LoggedInUserDetails,
    WindowRef,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandleInterceptor,
      multi: true
    }
  ],
  declarations: [
    HeaderComponent,
    CenterContentLayoutComponent,
    ColumnContentLayoutComponent
  ],
  exports: [
    HeaderComponent,
    CenterContentLayoutComponent,
    ColumnContentLayoutComponent
  ]
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {

  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}

