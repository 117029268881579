import { ConfigTableColumnVariable } from './config-table-column-variable';

export class ConfigFunctionsCalledInTable {
    id?: number;
  order?: number;
  configSheetTableName?: string;
  configFunctionName?: string;
  configTableColumnVariables?: ConfigTableColumnVariable[];
  configSheetTableId?: number;
  configFunctionId?: number;
}
