import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CommissionUser } from '../models/commission-user';
import { NotifierService } from 'angular-notifier';

@Injectable({
  providedIn: 'root'
})
export class CommissionUserService {
  private _baseUrl = '/api/commissionUsers';
  drawOpts: any[] = [
    //{ text: '-Hourly', value: 'Hourly' },
    //{ text: '-Amount$', value: 'Dollar' },
    { text: 'None', value: 'None' }
  ];
  growths: any = [
    { text: 'Tier', value: 'Tier' },
    { text: 'Gradual', value: 'Gradual' }
  ];
  commissionUsers: CommissionUser[] = [];

  constructor(private http: HttpClient,
    private _notifier: NotifierService) { }

  getDrawOpts(): Observable<any[]> {
    return of(this.drawOpts);
  }

  getFormulaGrowths(): Observable<any[]> {
    return of(this.growths);
  }

  getAllCommissionUsers(clientId: number, duration: string): Observable<CommissionUser[]> {
    return this.http.get<CommissionUser[]>(this._baseUrl + '/' + clientId + '/' + duration + '/forClient');
  }

  deleteCommissionUser(id: number): Observable<number> {
    return this.http.delete<number>(this._baseUrl + '/' + id);
  }

  addCommissionUser(user: CommissionUser): Observable<any> {
    const commissionUser = {
      Name: user.name,
      LocationId: user.locationId,
      EmployeeId: user.employeeId,
      Duration: user.duration,
      CommissionGroups: []
    };

    user.commissionGroups.forEach((group) => {
      const commissionGroup = {
        Name: group.name,
        BasePercentage: group.basePercentage,
        Target: group.target,
        DrawOpt: group.drawOpt,
        CommissionFormulas: []
      };
      group.commissionFormulas.forEach((formula) => {
        const commissionFormula = {
          FormulaId: formula.formulaId,
          Growth: formula.growth,
          CommissionPegs: []
        };
        formula.commissionPegs.forEach((peg) => {
          commissionFormula.CommissionPegs.push({ Pay: peg.pay, Goal: peg.goal });
        });
        commissionGroup.CommissionFormulas.push(commissionFormula);
      });
      commissionUser.CommissionGroups.push(commissionGroup);
    });

    this.commissionUsers.push(user);

    return this.http.post(this._baseUrl, commissionUser);
  }

  updateCommissionUser(user: CommissionUser): Observable<any> {
    const commissionUser = {
      Id: user.id,
      Name: user.name,
      LocationId: user.locationId,
      EmployeeId: user.employeeId,
      Duration: user.duration,
      CommissionGroups: []
    };

    user.commissionGroups.forEach((group) => {
      const commissionGroup = {
        Id: group.id,
        Name: group.name,
        BasePercentage: group.basePercentage,
        Target: group.target,
        DrawOpt: group.drawOpt,
        CommissionFormulas: []
      };
      group.commissionFormulas.forEach((formula) => {
        const commissionFormula = {
          Id: formula.id,
          FormulaId: formula.formulaId,
          Growth: formula.growth,
          CommissionPegs: []
        };
        formula.commissionPegs.forEach((peg) => {
          commissionFormula.CommissionPegs.push({ Id: peg.id, Pay: peg.pay, Goal: peg.goal });
        });
        commissionGroup.CommissionFormulas.push(commissionFormula);
      });
      commissionUser.CommissionGroups.push(commissionGroup);
    });

    return this.http.put(this._baseUrl + '/' + user.id, commissionUser);
  }

  updateCommissionUsersCollection(clientId:number, duration:string , callback: any) {
    this.getAllCommissionUsers(clientId, duration).subscribe((response: CommissionUser[]) => {
      this.commissionUsers = response;
      callback();
    }, (error) => {  }, () => { });
  }

  getCommissionUsers(clientId: number, duration: string, callback: any,  locations?: number[] ) {
    //if (this.commissionUsers.length === 0) {
      this.getAllCommissionUsers(clientId, duration).subscribe((response: CommissionUser[]) => {
        this.commissionUsers = response;
        let result  = null;
        if (locations.length > 0 && duration) {
          result = this.filterCommissionUsersByDurationAndLocation(duration, locations);
        } else if (locations.length === 0 && duration) {
          result = this.filterCommissionUsersByDuration(duration);
        } else if (locations.length > 0 && !duration) {
          result = this.filterCommissionUsersByLocation(locations);
        } else {
          result = this.commissionUsers;
        }
        callback(result);
      }, (error) => { this._notifier.notify('error', error.message); }, () => { });
    //} else {
    //  let result = null;
    //  if (locations.length > 0 && duration) {
    //    result = this.filterCommissionUsersByDurationAndLocation(duration, locations);
    //  } else if (locations.length === 0 && duration) {
    //    result = this.filterCommissionUsersByDuration(duration);
    //  } else if (locations.length > 0 && !duration) {
    //    result = this.filterCommissionUsersByLocation(locations);
    //  } else {
    //    result = this.commissionUsers;
    //  }
    //  callback(result);
    //}
  }

  getCommissionUser(clientId:number, callback: any, userId: number, duration: string) {
    let result = null;
    //if (this.commissionUsers.length === 0) {
      this.getAllCommissionUsers(clientId, duration).subscribe((response: CommissionUser[]) => {
        this.commissionUsers = response;
         result = this.filterCommissionUsersByDurationAndEmployeeId(userId, duration);
         callback(result);
      });
    //} else {
    //  result = this.filterCommissionUsersByDurationAndEmployeeId(userId, duration);
    //  callback(result);
    //}
  }

  filterCommissionUsersByDurationAndEmployeeId(userId: number, duration: string) {
    return this.commissionUsers.find(user => user.duration === duration && user.employeeId === userId);
  }

  filterCommissionUsersByDuration(duration: string) {
    return this.commissionUsers.filter(user => user.duration === duration);
  }

  filterCommissionUsersByLocation(locations: number[]) {
    return this.commissionUsers.filter(user => locations.includes(user.locationId));
  }

  filterCommissionUsersByDurationAndLocation(duration: string, locations: number[]) {
    return this.commissionUsers.filter(user => locations.includes(user.locationId) && user.duration === duration);

  }


  // APPEARS NO BODY CALLS THIS.
  //searchCommissionUsers(location: number, duration: string): Observable<CommissionUser[]> {
  //  const locationId = +location;
    
  //  if (locationId && duration) {
  //    return this.http.get<CommissionUser[]>(this._baseUrl + '/' + locationId + '/' + duration);
  //  } else if (!locationId && duration) {
  //    return this.http.get<CommissionUser[]>(this._baseUrl + '/0/' + duration);
  //  } else if (locationId && !duration) {
  //    return this.http.get<CommissionUser[]>(this._baseUrl + '/' + +locationId);
  //  } else {
  //    return this.http.get<CommissionUser[]>(this._baseUrl);
  //  }
  //}

  // APPEARS NO BODY CALLS THIS.
  //getUsersByDuration(duration: string): Observable<any[]>  {
  //  const result = this.commissionUsers.filter(user => user.duration === duration).map(function (user) {
  //    return {
  //      id: user.id,
  //      name: user.name
  //    };
  //  });

  //  return of(result);
  //}

  //forecast(duration: string, id: string): Observable<CommissionGroup[]> {
  //  const result = this.commissionUsers.find(user => user.duration === duration && user.id === +id).commissionGroups;
  //  return of(result);
  //}

  isRecordAlreadyExists(duration: string, name: string): boolean {
    return this.commissionUsers.some(c => c.duration === duration && c.name === name);
  }
}
