import { ConfigFunctionsCalledInTable } from './config-function-sheet';
import { ConfigTableColumn } from './config-table-column';

export class ConfigSheetTable {
    id?: number;
  name?: string;
  configFunctionsCalledInTable?: ConfigFunctionsCalledInTable[];
  configTableColumns?: ConfigTableColumn[];
  configSheetId?: number;
  startingRow?: number;
}
