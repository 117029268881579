import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class DateHelperService {

    //It can handle below date formats and convert to dd-mm-yy
    // -ddmmyyyy -mmyyyy and Date object
    formatDate(date) {
        if (typeof date === 'string') {
            return /^\d{8}$/.test(date)
                ? `${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(6, 8)}`
                : /^\d{6}$/.test(date)
                    ? `01-${date.substring(0, 2)}-${date.substring(4, 6)}`
                    : '';
        } else if (date instanceof Date ) {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = String(date.getFullYear()).slice(-2);
            return `${day}-${month}-${year}`;
        }
        return '';
    }

    // It can sort the dates of format dd-mm-yy
    sortDates(dates, propertyName) {
        return dates.sort((a, b) => {
            const [dayA, monthA, yearA] = a[propertyName].split('-');
            const [dayB, monthB, yearB] = b[propertyName].split('-');
            const dateA = new Date(`20${yearA}-${monthA}-${dayA}`);
            const dateB = new Date(`20${yearB}-${monthB}-${dayB}`);
            return dateA.getTime() - dateB.getTime();
        });
    }
}