import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { WindowRef } from '../../core/window-ref.service';
import { ClientService } from '../../home/services/client.service';
import { LoggedInUserDetails } from '../../core/services/user-details.service';
import { Location } from '../../home/models/location';
import { SettingService } from '../../home/users/services/setting.service';
import { SubclientService } from '../../home/services/subclient.service';
import { LocationService } from '../../home/services/location.service';
import { ReportType } from '../../home/users/models/reportType';
import { LocationTypeCode } from '../../home/models/location-type-code';
import { EventEmitter, Output } from '@angular/core';
import { Employee } from '../../home/models/employee';
import { EmployeeService } from '../../home/services/employee.service';
import { UserService } from '../../home/users/services/user.service';
import { Title } from '@angular/platform-browser';

const CONFIG_CLIENTID_KEY = 'ConfigClientId';
const CONFIG_CLIENT_KEY = 'ConfigClient';
const CONFIG_REGION_KEY = 'ConfigRegion';
const CONFIG_DISTRICT_KEY = 'ConfigDistrict';
const CONFIG_STORE_KEY = 'ConfigStore';
const CONFIG_EMPLOYEE_KEY = 'ConfigEmployee';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  @Output() eventLocationsSelected: EventEmitter<any> = new EventEmitter();
  @Output() eventEmployeeSelected: EventEmitter<any> = new EventEmitter();
  @Output() eventStoreSelected: EventEmitter<any> = new EventEmitter();
  @Output() eventDistrictSelected: EventEmitter<any> = new EventEmitter();
  @Output() eventRegionSelected: EventEmitter<any> = new EventEmitter();
  @Output() eventClientSelected: EventEmitter<any> = new EventEmitter();
  @Output() eventActualClientSelected: EventEmitter<any> = new EventEmitter();
  baseUrl: string;
  clientName: string;
  loggedInUserName: string;
  localStorageLoadingInProgress: boolean;
  savedClientId: number;
  savedClientLocationId: number;
  savedRegionLocationId: number;
  savedDistrictLocationId: number;
  savedStoreLocationId: number;
  reportTypes: ReportType[] = [];
  selectedReportType: ReportType;
  clients: Location[];
  clientId: number;
  clientLocation: Location;
  regions: Location[];
  districts: Location[];
  stores: Location[];
  selectedClientId: number;
  selectedActualClientId: number;
  selectedRegionId: number;
  selectedDistrictId: number;
  selectedStoreId: number;

  selectedClient: Location;
  selectedRegion: Location;
  selectedDistrict: Location;
  selectedStore: Location;

  locations: Location[] = [];
  employees: Employee[] = [];
  selectedEmployeeId: number;
  selectedEmployee: Employee;

  filteredLocations: Location[] = [];
  filteredEmployees: Employee[] = [];

  debug = false;
  panelOpenState = false;
  pageTitle: string = '';
  _idleTimeOutDuration = 30; //in minutes
  constructor(public _authService: AuthService,
    private _router: Router,
    private _window: WindowRef,
    private _clientService: ClientService,
    private _userDetailsService: LoggedInUserDetails,
    private _userService: UserService,
    private _settingService: SettingService,
    private _subclientService: SubclientService,
    private _locationService: LocationService,
    private _employeeService: EmployeeService, private activatedRoute: ActivatedRoute, private titleService: Title
  ) { }
  getPageTitle(route: ActivatedRoute): string {
    let title = '';
    while (route.firstChild) {
      route = route.firstChild;
      if (route.snapshot.data && route.snapshot.data.title) {
        title = route.snapshot.data.title;
      }
    }
    return title;
  }
  updatePageTitle() {
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }
    const pageTitle = route.snapshot.data['title'];
    this.pageTitle = pageTitle || '';
    this.titleService.setTitle(this.pageTitle);
  }
  async ngOnInit() {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updatePageTitle();
      }
    });
    this.updatePageTitle();

    this.baseUrl = this._window.native.origin;
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.readAndApplyPageSettings(event.url, false);
      }
    });

    this._clientService.getClientNameById(this._authService.clientId(), (name) => {
      this.clientName = name;
    });
    await this.getClients();
    this.loggedInUserName = this._userDetailsService.getUserName();
    this.checkClientValidity();
    this.checkPasswordStatus();
    this._settingService.getReportTypes().subscribe((response) => {
      this.reportTypes = response;

      this._locationService.getAllLocations().subscribe((locs) => {
        this.filteredLocations = locs.filter(x => x.clientId === this.selectedActualClientId);
        this._employeeService.getEmployeeUsersByLocations(this.filteredLocations.map(x => x.id), async (emps) => {
          this.employees = await emps;
          this.readAndApplyPageSettings(this._router.url, true);
        });
      });
    });
    this._authService.initializeIdleSessionTimeout(this._idleTimeOutDuration);
  }

  async readAndApplyPageSettings(route: string, firstload: boolean) {
    let precheck = this.reportTypes.find(x => x.routing === route);
    if (!precheck) return;
    this.selectedReportType = precheck;
    let count = 0;
    if (!firstload) {
      while (!this._locationService.isDestroyed) {
        count++;
        await this.wait(100);
        if (count > 10) {
          return;
        }
      }
    }
    this._locationService.isDestroyed = false;
    if (route === '/inventory-sunburst') {
      this.selectedReportType = this.reportTypes.find(x => x.routing === '/inventory');
    }

    //if (route === '/datascape-reconcile' ||
    //  route === '/ccrs-reconcile') {
    //  this.selectedReportType = this.reportTypes.find(x => x.routing === '/commission-reconcile');
    //}
    if (this.debug) { console.log('actual client' + this.selectedActualClientId); }
    if (this.selectedReportType) {
      if (this._authService.isSuperAdmin()) {
        this.filteredLocations = this.filteredLocations.filter(location =>
          location.clientId === this.selectedActualClientId);
        this.setLocations();
      } else {
        this._settingService.getLocationsForReport(this._authService.userId(), this.selectedReportType.id).subscribe((response) => {
          this.filteredLocations = response;
          if (response.length !== 0) {
            this.setLocations();
          } else {
            this.disableAndDefaultLocationHeader();
          }
        });
      }
    }
  }

  disableAndDefaultLocationHeader() {
    this._locationService.getAllLocations().subscribe((response1) => {
      this.selectedEmployee = this.employees.find(x => x.userId === this._authService.userId());
      this.selectedEmployeeId = this.selectedEmployee.id;
      const tempLoc = response1;
      const tempStore = tempLoc.find(x => x.id === this.selectedEmployee.locationId);
      const tempDistrict = tempLoc.find(x => x.id === tempStore.parentId);
      const tempRegion = tempLoc.find(x => x.id === tempDistrict.parentId);
      this.selectedStoreId = tempStore.id;
      this.selectedDistrictId = tempDistrict.id;
      this.selectedRegionId = tempRegion.id;
      this._locationService.changeClient(tempRegion.parentId);
      this._locationService.changeRegion(tempRegion.id);
      this._locationService.changeDistrict(tempDistrict.id);
      this._locationService.changeStore(tempStore.id);
      this._locationService.changeEmployee(this.selectedEmployeeId);
      this.filteredEmployees = [this.selectedEmployee];
      this.clients = [tempLoc.find(x => x.parentId === 0 && x.clientId === this._authService.clientId())];
      this.regions = [tempRegion];
      this.districts = [tempDistrict];
      this.stores = [tempStore];
    });
  }

  getClients(): Promise<boolean> {
    return new Promise(resolve => {
      this._locationService.getLocations(LocationTypeCode.Client, (response) => {
        this.clients = response;
        const isSuperAdmin = this._authService.isSuperAdmin();
        if (isSuperAdmin) {
          if (localStorage.getItem(CONFIG_CLIENT_KEY)) {
            this.selectedClientId = +localStorage.getItem(CONFIG_CLIENT_KEY);
          }
          if (localStorage.getItem(CONFIG_CLIENTID_KEY)) {
            this.selectedActualClientId = +localStorage.getItem(CONFIG_CLIENTID_KEY);
          } else {
            this.selectedClientId = this.clients[0].id;
            this.selectedActualClientId = this.clients[0].clientId;
          }
        } else {
          const thisUserClientId = this._authService.clientId();

          this.selectedClientId = this.clients
            .find(x => x.locationTypeCodeId === LocationTypeCode.Client && x.clientId === thisUserClientId).id;
          this.selectedActualClientId = this.clients
            .find(x => x.locationTypeCodeId === LocationTypeCode.Client && x.clientId === thisUserClientId).clientId;
        }
        resolve(true);
      });
    });
  }

  setLocations() {
    this.regions = [];
    this.districts = [];
    this.stores = [];
    this.filteredEmployees = [];
    this._locationService.changeClient(this.selectedClientId);
    if (this.selectedReportType.lowestLocationToShow > 1) {
      this.regions = this.filteredLocations
        .filter(x => x.locationTypeCodeId === LocationTypeCode.Region && x.parentId === this.selectedClientId);
      if (localStorage.getItem(CONFIG_REGION_KEY)) {

        if (this.selectedReportType.allowUnselectedLocationUpTo < 3 && +localStorage.getItem(CONFIG_REGION_KEY) === 0) {
          this.selectedRegionId = +localStorage.getItem(CONFIG_REGION_KEY);
        } else if (this.regions.find(x => x.id === +localStorage.getItem(CONFIG_REGION_KEY))) {
          this.selectedRegionId = +localStorage.getItem(CONFIG_REGION_KEY);
        } else if (+localStorage.getItem(CONFIG_REGION_KEY) === 0) {
          this.selectedRegionId = this.regions[0].id;
        }

      } else {
        this.selectedRegionId = this.regions[0].id;
      }
      this._locationService.changeRegion(this.selectedRegionId);
      if (this.selectedReportType.lowestLocationToShow > 2) {
        if (this.selectedRegionId === 0) {
          this.districts = this.filteredLocations.filter(x => x.locationTypeCodeId === LocationTypeCode.District);
        } else {
          this.districts = this.filteredLocations
            .filter(x => x.locationTypeCodeId === LocationTypeCode.District && x.parentId === this.selectedRegionId);
        }

        if (localStorage.getItem(CONFIG_DISTRICT_KEY)) {

          if (this.selectedReportType.allowUnselectedLocationUpTo < 4 && +localStorage.getItem(CONFIG_DISTRICT_KEY) === 0) {
            this.selectedDistrictId = +localStorage.getItem(CONFIG_DISTRICT_KEY);
          } else if (this.districts.find(x => x.id === +localStorage.getItem(CONFIG_DISTRICT_KEY))) {
            this.selectedDistrictId = +localStorage.getItem(CONFIG_DISTRICT_KEY);
          } else if (+localStorage.getItem(CONFIG_DISTRICT_KEY) === 0) {
            this.selectedDistrictId = this.districts[0].id;
          } else {
            this.selectedDistrictId = this.districts[0].id;
          }

        } else {
          this.selectedDistrictId = this.districts[0].id;
        }
        this._locationService.changeDistrict(this.selectedDistrictId);
        if (this.selectedReportType.lowestLocationToShow > 3) {
          if (this.selectedDistrictId === 0) {
            this.stores = this.filteredLocations.
              filter(x => x.locationTypeCodeId === LocationTypeCode.Store
                && this.districts.map(y => y.id).includes(x.parentId));
          } else {
            this.stores = this.filteredLocations
              .filter(x => x.locationTypeCodeId === LocationTypeCode.Store && x.parentId === this.selectedDistrictId);
          }

          if (localStorage.getItem(CONFIG_STORE_KEY)) {
            if (this.selectedReportType.allowUnselectedLocationUpTo < 5 && +localStorage.getItem(CONFIG_STORE_KEY) === 0) {
              this.selectedStoreId = +localStorage.getItem(CONFIG_STORE_KEY);
            } else if (this.stores.find(x => x.id === +localStorage.getItem(CONFIG_STORE_KEY))) {
              this.selectedStoreId = +localStorage.getItem(CONFIG_STORE_KEY);
            } else if (+localStorage.getItem(CONFIG_STORE_KEY) === 0) {
              this.selectedStoreId = this.stores[0].id;
            }

          } else {
            this.selectedStoreId = this.stores[0].id;
          }
          this._locationService.changeStore(this.selectedStoreId);
          if (this.selectedReportType.lowestLocationToShow > 4) {
            if (this.selectedStoreId === 0) {
              this._employeeService.getEmployeeUsersByLocations(
                this.stores.map(x => x.id), (response) => {
                  this.filteredEmployees = response;
                });
            } else {
              this.filteredEmployees = this.employees.filter(x => x.locationId === this.selectedStoreId);
            }

            if (localStorage.getItem(CONFIG_EMPLOYEE_KEY)) {

              if (this.selectedReportType.allowUnselectedLocationUpTo < 6 && +localStorage.getItem(CONFIG_EMPLOYEE_KEY) === 0) {
                this.selectedEmployeeId = +localStorage.getItem(CONFIG_EMPLOYEE_KEY);
              } else if (!this.filteredEmployees || this.filteredEmployees.length < 1) {
                return;
              } else if (this.filteredEmployees.find(x => x.id === +localStorage.getItem(CONFIG_EMPLOYEE_KEY))) {
                this.selectedEmployeeId = +localStorage.getItem(CONFIG_EMPLOYEE_KEY);
              } else if (+localStorage.getItem(CONFIG_EMPLOYEE_KEY) === 0) {
                this.selectedEmployeeId = this.filteredEmployees[0].id;
              } else {
                this.selectedEmployeeId = this.filteredEmployees[0].id;
              }
            } else if (this.filteredEmployees && this.filteredEmployees.length > 0) {
              this.selectedEmployeeId = this.filteredEmployees[0].id;
            }
            this._locationService.changeEmployee(this.selectedEmployeeId);
          }
        }
      }
    }
    let arr = [this.selectedActualClientId, this.selectedClientId, this.selectedRegionId, this.selectedDistrictId, this.selectedStoreId, this.selectedEmployeeId];
    this._locationService.changeLocations(arr);
  }

  wait(ms) {
    return new Promise((resolve, reject) => setTimeout(resolve, ms));
  }
  logout(): void {
    this._authService.logout();
    this._router.navigate(['login']);
  }
  checkPasswordStatus() {
    this._userService.checkPasswordStatus().subscribe((response) => {
      if (!response) {
        this._router.navigate(['changepassword']);
      }
    });
  }

  checkClientValidity() {
    this._authService.isClientActive().subscribe((response) => {
      if (!response) {
        this.logout();
      }
    });
  }

  onClientSelected() {
    this.selectedClientId = +this.selectedClientId;
    if (this.debug) { console.log('Client Selected ' + this.selectedClientId); }
    this.selectedClient = this.clients.find(r => r.id === this.selectedClientId);
    this.selectedActualClientId = this.selectedClient.clientId;
    this._locationService.getAllLocations().subscribe((response) => {
      this.filteredLocations = response.filter(x => x.clientId === this.selectedActualClientId);
      this._employeeService.getEmployeeUsersByLocations(this.filteredLocations.map(x => x.id), async (emps) => {
        this.employees = await emps;
        this._locationService.changeClient(this.selectedClientId);
        localStorage.setItem(CONFIG_CLIENT_KEY, (+this.selectedClientId).toString());
        localStorage.setItem(CONFIG_CLIENTID_KEY, (+this.selectedActualClientId).toString());
        this.trickleThroughLocationChange(1, this.selectedReportType.lowestLocationToShow);
      });
    });
  }

  onRegionSelected() {
    localStorage.setItem(CONFIG_REGION_KEY, (this.selectedRegionId ? +this.selectedRegionId : 0).toString());
    this.selectedRegionId = +this.selectedRegionId;
    this._locationService.changeRegion(this.selectedRegionId);
    this.selectedRegion = this.regions.find(r => r.id === this.selectedRegionId);
    this.trickleThroughLocationChange(2, this.selectedReportType.lowestLocationToShow);
  }

  onDistrictSelected() {
    localStorage.setItem(CONFIG_DISTRICT_KEY, (this.selectedDistrictId ? +this.selectedDistrictId : 0).toString());
    this.selectedDistrictId = +this.selectedDistrictId;
    this._locationService.changeDistrict(this.selectedDistrictId);
    this.trickleThroughLocationChange(3, this.selectedReportType.lowestLocationToShow);
  }

  onStoreSelected() {
    localStorage.setItem(CONFIG_STORE_KEY, (this.selectedStoreId ? +this.selectedStoreId : 0).toString());
    this.selectedStoreId = +this.selectedStoreId;
    this._locationService.changeStore(this.selectedStoreId);
    this.selectedStore = this.stores.find(s => s.id === this.selectedStoreId);
    this.trickleThroughLocationChange(4, this.selectedReportType.lowestLocationToShow);
  }

  onEmployeeSelected() {
    localStorage.setItem(CONFIG_EMPLOYEE_KEY, (this.selectedEmployeeId ? +this.selectedEmployeeId : 0).toString());
    if (this.debug) { console.log('Employee Selected:' + this.selectedEmployeeId); }
    if (+this.selectedEmployeeId === 0) {
      this._locationService.changeEmployee(+this.selectedEmployeeId);
      return;
    } else {
      this._locationService.changeEmployee(+this.selectedEmployeeId);
      this.selectedEmployee = this.filteredEmployees.find(s => s.id === this.selectedEmployeeId);
      let arr = [this.selectedActualClientId, this.selectedClientId, this.selectedRegionId, this.selectedDistrictId, this.selectedStoreId, this.selectedEmployeeId];
      this._locationService.changeLocations(arr);
    }
  }

  trickleThroughLocationChange(tierId: number, limitToGo: number) {
    if (tierId < 2) {
      this.regions = this.filteredLocations
        .filter(x => x.locationTypeCodeId === LocationTypeCode.Region && x.parentId === this.selectedClientId);
      this.selectedRegionId = this.regions[0].id;
      this.selectedRegion = this.regions[0];
      this._locationService.changeRegion(this.selectedRegionId);
      localStorage.setItem(CONFIG_REGION_KEY, (this.selectedRegionId ? +this.selectedRegionId : 0).toString());
    }

    if (tierId < 3 && limitToGo > 2) {
      if (this.selectedRegionId === 0) {
        this.districts = this.filteredLocations
          .filter(x => x.locationTypeCodeId === LocationTypeCode.District);
      } else {
        this.districts = this.filteredLocations
          .filter(x => x.locationTypeCodeId === LocationTypeCode.District && x.parentId === this.selectedRegionId);
      }

      this.selectedDistrictId = this.districts[0].id;
      this.selectedDistrict = this.districts[0];
      this._locationService.changeDistrict(this.selectedDistrictId);
      localStorage.setItem(CONFIG_DISTRICT_KEY, (this.selectedDistrictId ? +this.selectedDistrictId : 0).toString());
    }
    if (tierId < 4 && limitToGo > 3) {
      if (this.selectedDistrictId === 0) {
        this.stores = this.filteredLocations.
          filter(x => x.locationTypeCodeId === LocationTypeCode.Store
            && this.districts.map(y => y.id).includes(x.parentId));
      } else {
        this.stores = this.filteredLocations
          .filter(x => x.locationTypeCodeId === LocationTypeCode.Store && x.parentId === this.selectedDistrictId);
      }
      if (this.selectedStoreId === 0) {
      } else {
        this.selectedStoreId = this.stores[0].id;
        this.selectedStore = this.stores[0];
        this._locationService.changeStore(this.selectedStoreId);
        localStorage.setItem(CONFIG_STORE_KEY, (this.selectedStoreId ? +this.selectedStoreId : 0).toString());
      }
    }
    if (tierId < 5 && limitToGo > 4) {
      if (this.selectedStoreId === 0) {
        this._employeeService.getEmployeeUsersByLocations(
          this.stores.map(x => x.id), (response) => {
            this.filteredEmployees = response;
          });
      } else {
        this.filteredEmployees = this.employees.filter(x => x.locationId === this.selectedStoreId);
      }
      if (+this.selectedEmployeeId === 0) {
        if (this.debug) { console.log('EMPLOYEE ZERO'); }
        this._locationService.changeEmployee(this.selectedEmployeeId);
      } else if (this.filteredEmployees && this.filteredEmployees.length > 0
        && !this.filteredEmployees.find(x => x.id === this.selectedEmployeeId)) {
        this.selectedEmployeeId = this.filteredEmployees[0].id;
        if (this.debug) { console.log('EMPLOYEE ' + this.selectedEmployeeId); }
        this._locationService.changeEmployee(this.selectedEmployeeId);
      }
    }
    let arr = [this.selectedActualClientId, this.selectedClientId, this.selectedRegionId, this.selectedDistrictId, this.selectedStoreId, this.selectedEmployeeId];
    this._locationService.changeLocations(arr);
  }
}
