import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['../../../assets/css/separate/pages/login.min.css']
})
export class ForgotPasswordComponent implements OnInit {

  model: any = {};
  currentPassword: string;

  constructor(private _router: Router, private _authService: AuthService, private _notifier: NotifierService) { }

  ngOnInit() {

  }

  requestPasswordReset() {
    this._authService.forgotPassword(this.model.email).subscribe((response) => {
      this._notifier.notify('success', "We have emailed you a link");
      this.goToLogin();
    }, (error) => this._notifier.notify('error', error.error), () => { });
  }

  goToLogin() {
      this._router.navigate(['login']);
  }
}
