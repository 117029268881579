import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { TokenStorage } from '../../core/services/token-storage.service';
import { LocalStorage } from '../../core/decorators/local-storage';
import { WindowRef } from '../../core/window-ref.service';
import { LoggedInUserDetails } from '../../core/services/user-details.service';
import { Title } from '@angular/platform-browser';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
    errors: string;
    baseUrl: string;

    @LocalStorage
    username: string = '';

    password: string;

    get rememberMe() {
        return this._token.rememberMe === 'true';
    }

    set rememberMe(rememberMe) {
        this._token.rememberMe = rememberMe;
    }

    credentials: Credentials = {
        username: this.username,
        password: ''
    };

    constructor(private _router: Router,
        private _authService: AuthService,
      private _token: TokenStorage,
      private _window: WindowRef,
      private _userDetailsService: LoggedInUserDetails, private titleService: Title,private activatedRoute: ActivatedRoute) {
      this.baseUrl = this._window.native.origin;
    }
    updatePageTitle() {
      let route = this.activatedRoute;
      while (route.firstChild) {
        route = route.firstChild;
      }
      const pageTitle = route.snapshot.data['title'];
      // let pageTitle = pageTitle || '';
      this.titleService.setTitle(pageTitle);
    }
    ngOnInit() {
      this.updatePageTitle();
    }


    login({ value, valid }: { value: Credentials, valid: boolean }): void {
        this.username = value.username;
        this._authService.attemptAuth(value.username, value.password)
            .subscribe(
                data => {
                    this._token.saveToken(data);

                    this._userDetailsService.saveUserName(data.userName);
                    this._router.navigate(['welcome']);

                },
              error => error.error ?
                this.errors = error.error.message :
                this.errors = 'username or password did not match'
            );
    }

    goToForgotpassword() {
        this._router.navigate(['forgotpassword']);
    }

    onRememberMeChanged(value: boolean) {
        this.rememberMe = value;
    }
}

export interface Credentials {
    username: string;
    password: string;
}
