<style>
  @media (min-device-width: 767px) and (max-device-width: 1025px) {


    canvas {
      height: auto !important;
      width: auto !important;
    }

    .chart-list-item {
      flex: 1 0 46vw; /*NEW */
      background-color: transparent;
      /*height: 52vh;*/
      margin-right: 10px;
      text-align: center;
      line-height: 5rem;
      position: relative;
      margin-left: 10px;
      margin-bottom: 10px;
    }

    .inner {
      max-width: auto !important;
    }
  }

  .chart-list {
    display: flex;
    /* justify-content: space-between;
    margin: 5px;*/
    flex-wrap: wrap; /* NEW */
    width: 100%;
  }

  .inner {
    height: 100%;
    text-align: center;
    line-height: 5rem;
    /*   background-color: #efefef;*/
    cursor: move;
  }

  .chart-list-item {
    flex: 1 0 46vw; /* NEW */
    background-color: transparent;
    height: 52vh;
    margin-right: 10px;
    text-align: center;
    line-height: 5rem;
    position: relative;
    margin-left: 10px;
    margin-bottom: 10px;
  }


  .placeholder {
    flex: 1 0 5rem; /* NEW */
    margin: 5px; /* NEW */
    background-color: white;
    height: 5rem;
    text-align: center;
    line-height: 5rem;
    border: 1px;
  }

  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .mat-radio-button {
    margin-right: 1em;
  }

  p {
    margin-bottom: 0 !important;
  }

  .mat-form-field {
    display: inherit;
    /* margin-bottom: -0.5em;*/
    /*line-height: 1;*/
  }

  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    /*padding-bottom: 1em;*/
  }

  .mat-dialog-title {
    background-color: #3F51B5 !important;
  }

  .dialog-title {
    color: #ffffff;
    margin-left: 10px;
    margin-top: 5px;
    font-size: 16px
  }

  .close-button {
    color: white;
  }

  ::ng-deep.mat-form-field-appearance-standard, ::ng-deep.mat-form-field-flex {
    padding-top: 0px !important;
  }

  .tooltip {
    position: absolute;
    height: 50px;
    width: 80px;
    background-color: lavender;
    text-align: center;
    border-radius: 5px;
    padding: 6px;
    font-family: Helvetica;
    border: 1px solid gray;
  }
</style>

<header class="section-header">
  <div class="tbl">
    <div class="tbl-row">
      <div class="tbl-cell">
        <h3>Dashboard</h3>
      </div>
      <div class="tbl-cell">
        <div class="row table-header">
          <div class="col-sm-9" style="min-width:80vw">

            <button mat-raised-button style="margin-left: 15vw; float: left;" color="primary" class="" (click)="addDashboard()">Add Dashboard</button>

            <mat-radio-group style="margin-left: 3vw; float:left;" [(ngModel)]="overrideObject.isDefault" #isDefault="ngModel" name="isDefault" (change)="changeIsDefaultRange()">
              <mat-radio-button [value]="true">Default Charts</mat-radio-button>
              <mat-radio-button style="margin-left:25px" [value]="false">Override Date</mat-radio-button>
            </mat-radio-group>

            <span [hidden]="overrideObject.isDefault">
              <mat-form-field style="width: 200px; margin-left: 2%; float:left">
                <mat-label class="form-label semibold">Month</mat-label>
                <input matInput type="month" [(ngModel)]="overrideObject.monthlyDate" #monthlyDate="ngModel" name="monthlyDate" required>
              </mat-form-field>
              <button mat-raised-button style="margin-left: 30px; float:left;" color="primary" class="" (click)="getDashboards()">Submit</button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

</header>
<!--<div id="px" style=" margin-left:auto; margin-right:auto;">
</div>-->
<div class="dialog-dashboard" [hidden]="!dialog.isOpen" *ngIf="dialog.isOpen">
  <div mat-dialog-title *ngIf="dialog.headerText" style="display: flex; justify-content: space-between;">
    <h4 class="dialog-title">{{dialog.headerText}}</h4>
    <a *ngIf="dialog.cancelButton" class="cancel-btn close-button" style="margin-left: 30em;" (click)="dialog.cancel()"><mat-icon>close</mat-icon></a>
    <mat-divider></mat-divider>
  </div>
  <div class="mat-app-background">
    <div class="mat-elevation-z8">
      <div mat-dialog-content *ngIf="dashboard" style=" padding-right: 1em; overflow: auto; max-height: 45em; padding-left: 1em; padding-bottom: 1em;">
        <div class="row">
          <div class="col-6">
            <p>
              <mat-form-field *ngIf="_authService.isSuperAdmin()">
                <mat-select placeholder="Client Select" [(value)]="selectedClientId" (change)="onClientSelected();" required [formControl]="dashboardClient">
                  <ng-container *ngFor="let client of clients">
                    <mat-option [value]="client.id">{{client.name}}</mat-option>
                  </ng-container>
                </mat-select>
                <mat-error *ngIf="checkError('dashboardClient')">{{getErrorMessage("dashboardClient")}}</mat-error>
              </mat-form-field>
            </p>
            <p>
              <mat-label class="mat-radio-button">Formula</mat-label>
              <mat-radio-group aria-label="Formula" *ngIf="dashboard" [(ngModel)]="dashboard.skuGroupOrFormula">
                <mat-radio-button [value]="false">Formula</mat-radio-button>
              </mat-radio-group>
            </p>
          </div>
          <!--<div class="col-6">
            <p>
              <mat-form-field appearance="standard">
                <mat-label class="margin-right-1em">Name</mat-label>
                <input matInput placeholder="Name" [(ngModal)]="dashboard.name">
              </mat-form-field>
            </p>
          </div>-->
        </div>

        <div class="row" style="margin-top: 1em;">
          <div class="col-6">
            <p>
              <mat-form-field *ngIf="dashboard">
                <mat-select placeholder="Graph Type" [(value)]="dashboard.graphTypeId" (selectionChange)="graphTypeSelected(dashboard.graphTypeId);">
                  <mat-option [value]="1">Bar</mat-option>
                  <mat-option [value]="2">Compare</mat-option>
                  <mat-option [value]="3">Line</mat-option>
                </mat-select>
              </mat-form-field>
            </p>
            <p>
              <mat-form-field>
                <mat-select placeholder="Location Tier" [(value)]="dashboard.locationTierFocusId" (selectionChange)="trimFullTree();">
                  <mat-option [value]="2">Region</mat-option>
                  <mat-option [value]="3">District</mat-option>
                  <mat-option [value]="4">Store</mat-option>
                  <mat-option [value]="5">Employee</mat-option>
                </mat-select>
              </mat-form-field>
            </p>
          </div>
          <div class="col-6">
            <p>
              <mat-form-field *ngIf="!dashboard.isSkuGroupElseFormula">
                <mat-select placeholder="Select Formula" [(value)]="dashboard.formulaId" required [formControl]="dashboardFormula">
                  <mat-option *ngFor="let formula of formulas" [value]="formula.id">
                    {{formula.name}}
                  </mat-option>
                  <mat-error *ngIf="checkError('dashboardFormula')">{{getErrorMessage("dashboardFormula")}}</mat-error>
                </mat-select>
              </mat-form-field>
            </p>
            <p>
              <mat-form-field appearance="standard" *ngIf="dashboard.isSkuGroupElseFormula">
                <mat-select placeholder="Select Sku Groups" multiple required [formControl]="dashboardSkuGroups">
                  <mat-select-trigger>{{getTextToShowInDropdown()}}</mat-select-trigger>
                  <mat-option *ngFor="let skuGroup of skuGroups" [value]="skuGroup.isSelected" (click)="skuGroup.isSelected = !skuGroup.isSelected;">{{skuGroup.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="checkError('dashboardSkuGroups')">{{getErrorMessage("dashboardSkuGroups")}}</mat-error>
              </mat-form-field>
            </p>
            <p>
              <mat-form-field *ngIf="dashboard && dashboard.graphTypeId == 1">
                <mat-select placeholder="Date Type" [(value)]="dashboard.dateTypeId">
                  <mat-option [value]="1">MTD</mat-option>
                  <mat-option [value]="2">Today</mat-option>
                  <mat-option [value]="3">Yesterday</mat-option>
                  <mat-option [value]="4">Last Week</mat-option>
                  <mat-option [value]="5">Last Month</mat-option>
                  <mat-option [value]="6">This Year</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="dashboard && dashboard.graphTypeId == 2">
                <mat-select placeholder="Date Type" [(value)]="dashboard.dateTypeId">
                  <mat-option [value]="11">Year</mat-option>
                  <mat-option [value]="12">Month</mat-option>
                  <mat-option [value]="13">Week</mat-option>
                  <mat-option [value]="14">EntireYear</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field *ngIf="dashboard && dashboard.graphTypeId == 3">
                <mat-select placeholder="Date Type" [(value)]="dashboard.dateTypeId">
                  <mat-option [value]="21">6 Months</mat-option>
                  <mat-option [value]="22">12 Months</mat-option>
                  <mat-option [value]="23">This Year</mat-option>
                  <mat-option [value]="24">2 Years</mat-option>
                  <mat-option [value]="25">3 Years</mat-option>
                  <mat-option [value]="26">5 Years</mat-option>
                </mat-select>
              </mat-form-field>
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 form-control-label"></label>
          <div class="col-sm-8">
            <button type="button" mat-raised-button style=" margin-right: 10px" color="primary" (click)="openLocationTree();buildLocationTreeInterface(); ">LocationTree</button>
            <div id="cancelZoom" class="dialog-tree" [hidden]="!locationSelect.isOpen">
              <header>
                <span *ngIf="locationSelect.headerText">
                  <input type="button" (click)="hideDepth()" value="Click Here To Hide To">
                  <select [(ngModel)]="depthSelection" #depthSelectionF="ngModel"><option *ngFor="let depth of depthview" [value]="depth.value">{{depth.text}}</option></select>
                  <span style=" padding-left:10vw; font-size:300%">{{locationSelect.headerText}}</span>
                </span>
                <a *ngIf="locationSelect.cancelButton" class="cancel-btn" (click)="locationSelect.refresh();locationSelect.cancel(); "><i class="fa fa-times"></i></a>
              </header>
              <div id="p2" style=" margin-left:auto; margin-right:auto;">
              </div>
            </div>
          </div>
        </div>
        <div class="tree-control">
          <input type="text" class="form-control" [(ngModel)]="locationSearchString" (keyup)="searchLocation()">
          <ul>
            <li *ngFor="let location of locations2">
              <ng-template [ngTemplateOutlet]="locationTemplateRef" [ngTemplateOutletContext]="{ $implicit: location }"></ng-template>
            </li>
          </ul>
        </div>

        <button mat-raised-button style=" margin-top:1em;" color="primary" type="submit" (click)="saveDashboard()" [disabled]="checkError('dashboardClient') || checkError('dashboardFormula') ">Submit</button>
      </div>
    </div>

  </div>
</div>

<div class="chart-list" cdkDropListGroup>
  <mat-card *ngFor="let item of dashboards;let i=index" style="margin: 0.1em;padding: 0.1em">
    <mat-card-content>
      <div class="chart-list-item" cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="{item:item,index:i}"
           (cdkDropListDropped)="drop($event)">
        <div class="inner" cdkDrag style="">
          <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
          <span [attr.id]="'chartContainer'+i"></span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div class="dialog-overlay" [hidden]="!dialog.isOpen"></div>
<ng-template #locationTemplateRef let-location>
  <a [ngClass]="{ dselect: location.selected, 'd-block': location.isVisible, dempty: !location.isVisible || location.isFiltered}" (click)="onLocationSelected(location)">
    <span *ngIf="location.children.length">      <i></i>    </span>
    <span [hidden]="location.IsVisible || location.isFiltered">{{ location.name }}</span>
  </a>
  <ul>
    <li *ngIf="location.children.length">
      <ng-template ngFor [ngForOf]="location.children" [ngForTemplate]="locationTemplateRef"></ng-template>
    </li>
  </ul>
</ng-template>
<div class="dialog-color" style="border-style:solid;" [hidden]="!colordialog.isOpen">
  <header>
    <span *ngIf="colordialog.headerText">{{colordialog.headerText}}</span>
    <a *ngIf="colordialog.cancelButton" class="cancel-btn" (click)="colordialog.cancel()"><i class="fa fa-times"></i></a>
  </header>
  <div class="form-group row">
    <div class="col-sm-8"><label>Color</label><input type="color" [(ngModel)]="selectedLocationColor.color" /></div>
  </div>
  <span (click)="setColorForLocation();">Submit</span>
</div>
