import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../core/services/auth.service";
import { LoggedInUserDetails } from "../../core/services/user-details.service";
import { LoaderService } from '../services/loader.service';
import { LocationService } from '../services/location.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'welcome',
  templateUrl: './welcome.component.html'
})
export class WelcomeComponent implements OnInit {
  roles: any;
  loggedInUserName: any;
  subscriptionToClients: Subscription;
  subscriptionToRegions: Subscription;
  subscriptionToDistricts: Subscription;
  subscriptionToStores: Subscription;
  subscriptionToEmployees: Subscription;

  clientId: number;
  selectedClientId: number;
  selectedClient: any;
  selectedRegionId: number;
  selectedRegion: any;
  districts: any[];
  selectedDistrictId: number;
  selectedDistrict: any;
  stores: any[];
  selectedStoreId: number;
  selectedStore: any;
  employees: any[];
  selectedEmployeeId: number;
  selectedEmployee: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(public _authService: AuthService, private _userDetailsService: LoggedInUserDetails, private _loaderService: LoaderService, private _locationService: LocationService) {
    this.roles = _authService.roles();
    this.loggedInUserName = this._userDetailsService.getUserName();
  }

  ngOnDestroy() {
    this.subscriptionToClients.unsubscribe();
    this.subscriptionToRegions.unsubscribe();
    this.subscriptionToDistricts.unsubscribe();
    this.subscriptionToEmployees.unsubscribe();
    this.subscriptionToStores.unsubscribe();
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this._locationService.isDestroyed = true;
  }

  ngOnInit(): void {
    this.subscriptionToClients = this._locationService.currentActualClient
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {

        this.onClientSelected(+message);
      });

    this.subscriptionToRegions = this._locationService.currentRegion
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {

        this.selectedRegionId = message;

      });
    this.subscriptionToDistricts = this._locationService.currentDistrict
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {

        this.selectedDistrictId = message;

      });
    this.subscriptionToStores = this._locationService.currentStore
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {

        this.selectedStoreId = message;

      });
    this.subscriptionToEmployees = this._locationService.currentEmployee
      .pipe(takeUntil(this.destroyed$))
      .subscribe(message => {

        this.selectedEmployeeId = message;

      });
  }
  onClientSelected(clientId: number) {
    if (clientId && clientId !== 0 && clientId !== this.clientId) {
      this.selectedClientId = +clientId;
      this.clientId = +clientId;
    }
  }
}
