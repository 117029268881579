import { Component, OnInit } from '@angular/core';
import { AssetService } from '../services/asset.service';
import { AssetType } from '../models/asset-type';

@Component({
  selector: 'app-center-content-layout',
  templateUrl: './center-content-layout.component.html'
})
export class CenterContentLayoutComponent implements OnInit {

  constructor(private assetService: AssetService) {}

  ngOnInit() {
    this.assetService.loadAssets(AssetType.SCRIPTS, ['jquery', 'tether', 'plugins', 'jquery-ui', 'jquery-matchHeight', 'app', 'idle-timer'])
      .then(data => { console.log('script loaded ', data); }).catch(error => console.log(error));
  }
}
