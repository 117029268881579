import { Inject, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'sku-filter-create-dialog',
  templateUrl: 'sku-filter-create.component.html',
})
export class SkuFilterCreateComponent implements OnInit {

  title: string;
  skuFilter: any;
  skuFilterTypes: any;
  isSkuListFilterTypeSelected: any;
  productCatalogs: any; categories: any;productCatalogSearchString:any;categorySearchString:any;
  constructor(public dialogRef: MatDialogRef<SkuFilterCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.skuFilter = data.skuFilter;
    this.skuFilterTypes = data.skuFilterTypes;
    this.productCatalogs = data.productCatalogs;
    this.categories = data.categories;
  }

  ngOnInit() { }

  onConfirm(): void { this.dialogRef.close(this.skuFilter); }

  onDismiss(): void { this.dialogRef.close(false); }
  onSkuFilterTypeSelected() {
    this.isSkuListFilterTypeSelected = (+this.skuFilter.skuFilterType.id === 1) ? true : false;
  }
  getProductCatalogName(id: number) {
    const productCatalog = this.productCatalogs.find(p => p.id === +id);
    return productCatalog ? productCatalog.productName : null;
  }

  getCategoryName(id: number) {
    const category = this.searchCategoryTree(this.categories, 'children', 'id', +id);
    return category ? category.name : null;
  }

  searchCategoryTree(tree, nodesProp, prop, value) {
    let i, f = null;
    if (Array.isArray(tree)) {
      for (i = 0; i < tree.length; i++) {
        f = this.searchCategoryTree(tree[i], nodesProp, prop, value);
        if (f) {
          return f;
        }
      }
    } else if (typeof tree === 'object') {
      if (tree[prop] !== undefined && tree[prop] === value) {
        return tree;
      }
    }
    if (  tree[nodesProp] !== undefined && tree[nodesProp].length > 0) {
      return this.searchCategoryTree(tree[nodesProp], nodesProp, prop, value);
    } else {
      return null;
    }
  }
  
  searchProductCatalog() {
    if (this.productCatalogSearchString !== null) {
      this.productCatalogs.forEach((catalog) => {
        if (catalog.productName.toLowerCase().indexOf(this.productCatalogSearchString.toLowerCase()) > -1 ||
          catalog.productSku.toLowerCase().indexOf(this.productCatalogSearchString.toLowerCase()) > -1) {
          catalog.isVisible = true;
        } else {
          catalog.isVisible = false;
        }
      });
    } else {
      this.productCatalogs.forEach((catalog) => {
        catalog.isVisible = true;
      });
    }
  }

  selectProductCatalog(productCatalog) {
    this.skuFilter.value = productCatalog.id;
  }

  selectCategory(category) {
    this.skuFilter.value = category.id;
  }
  searchCategory(array: any = null) {
    array = array ? array : this.categories;

    for (const key in array) {
      if (typeof array[key] === 'object') {
        this.searchCategory(array[key]);
      } else {
        if (key === 'name') {
          if (array.name.toLowerCase().indexOf(this.categorySearchString.toLowerCase()) > -1) {
            array.isVisible = true;
          } else {
            array.isVisible = false;
          }
        }
      }
    }
  }
}
