import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MTDReport } from '../models/mtd-report';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MtdReportService {
  private _baseUrl = '/api/mtdreport';
  modes: any[] = [
    { 'name': MTDReport.Store, 'value': MTDReport[MTDReport.Store] },
    { 'name': MTDReport.Employee, 'value': MTDReport[MTDReport.Employee] },
    { 'name': MTDReport.Compare, 'value': MTDReport[MTDReport.Compare] }];
  thisMonthVsLastMonthToDateDuration: any;
  currentMonthDuration: any;
  currentDayDuration: any;
  report: any[] = [];
  dateStartFormat = 'YYYY-MM-DD 00:00:00';
  dateEndFormat = 'YYYY-MM-DD 23:59:59';
  constructor(private http: HttpClient) { }

  getDurations(report: any, callback: any) {
    let durations: any[] = [];
    const currentMonth = moment().format('MMMM');
    const lastMonth = moment().subtract(1, 'month').format('MMMM');
    const secondLastMonth = moment().subtract(2, 'month').format('MMMM');
    const today = moment();
    const yesterday = moment().subtract(1, 'day');
    const previousWeekMonday = moment().weekday(-6);
    const twoWeekBeforeMonday = moment().weekday(-6).weekday(-6);
    const previousWeekSunday = moment().weekday(0).subtract(7, 'days');
    const currentWeekSunday = moment().weekday(0);
    const currentMonthFirstDay = moment().startOf('month');
    const currentMonthLastDay = moment().endOf('month');
    const currentYearFirstDay = moment().startOf('year');
    const previousMonthFirstDay = moment().subtract(1, 'months').startOf('month');
    const twoMonthBackFirstDay = moment().subtract(2, 'months').startOf('month');
    const twoMonthBackLastDay = moment().subtract(2, 'months').endOf('month');
    const previousMonthSameDay = moment().subtract(1, 'months');
    const previousMonthLastDay = moment().subtract(1, 'months').endOf('month');
    const previousYearFirstDay = moment().subtract(1, 'year').startOf('year');
    const previousYearSameDay = moment().subtract(1, 'year');
    const previousYearSameMonthFirstDay = moment().subtract(1, 'year').startOf('month');

    const today_duration = this.currentDayDuration = today.format(this.dateStartFormat) + '|' +
      today.format(this.dateEndFormat);
    const yesterday_duration = yesterday.format(this.dateStartFormat) + '|' +
      yesterday.format(this.dateEndFormat);
    const previous_week_duration = previousWeekMonday.format(this.dateStartFormat) + '|' +
      currentWeekSunday.format(this.dateEndFormat);
    const current_month_duration = this.currentMonthDuration = currentMonthFirstDay.format(this.dateStartFormat) + '|' +
      currentMonthLastDay.format(this.dateEndFormat);
    const previous_month_duration = previousMonthFirstDay.format(this.dateStartFormat) + '|' +
      previousMonthLastDay.format(this.dateEndFormat);

    const this_month_vs_last_month_to_date = this.thisMonthVsLastMonthToDateDuration
      = currentMonthFirstDay.format(this.dateStartFormat) + '|' +
      today.format(this.dateEndFormat) + '||' +
      previousMonthFirstDay.format(this.dateStartFormat) + '|' +
      previousMonthSameDay.format(this.dateEndFormat);
    const ytd_vs_last_ytd = currentYearFirstDay.format(this.dateStartFormat) + '|' +
      today.format(this.dateEndFormat) + '||' +
      previousYearFirstDay.format(this.dateStartFormat) + '|' +
      previousYearSameDay.format(this.dateEndFormat);
    const this_year_mtd_vs_last_year_mtd = currentMonthFirstDay.format(this.dateStartFormat) + '|' +
      today.format(this.dateEndFormat) + '||' +
      previousYearSameMonthFirstDay.format(this.dateStartFormat) + '|' +
      previousYearSameDay.format(this.dateEndFormat);
    const past_7_day_week_before = previousWeekMonday.format(this.dateStartFormat) + '|' +
      currentWeekSunday.format(this.dateEndFormat) + '||' +
      twoWeekBeforeMonday.format(this.dateStartFormat) + '|' +
      previousWeekSunday.format(this.dateEndFormat);
    const previous_month_vs_two_months_before = previousMonthFirstDay.format(this.dateStartFormat) + '|' +
      previousMonthLastDay.format(this.dateEndFormat) + '||' +
      twoMonthBackFirstDay.format(this.dateStartFormat) + '|' +
      twoMonthBackLastDay.format(this.dateEndFormat);

    switch (+report) {
      case MTDReport.Store:
      case MTDReport.Employee:
        durations = [{ value: today_duration, text: 'Today' },
          { value: yesterday_duration, text: 'Yesterday' },
          { value: previous_week_duration, text: 'Last Week' },
          { value: current_month_duration, text: currentMonth },
          { value: previous_month_duration, text: lastMonth },
          { value: 'OTH', text: 'Other' },
          { value: 'CR', text: 'Custom Range' },
          ];
        break;
      case MTDReport.Compare:
        durations = [{ value: this_month_vs_last_month_to_date, text: 'This Month vs Last Month ( To Date )' },
          { value: ytd_vs_last_ytd, text: 'YTD vs Last YTD' },
          { value: this_year_mtd_vs_last_year_mtd, text: 'This Year MTD vs Last Year MTD' },
          { value: past_7_day_week_before, text: 'Past 7 vs Week before' },
          { value: previous_month_vs_two_months_before, text: lastMonth + ' vs ' + secondLastMonth },
          { value: 'OTH', text: 'Other' },
          { value: 'CR', text: 'Custom Range' }
        ];
        break;
    }

    callback(durations);
  }

  getReport(mode: any, district: number, duration: string): Observable<any[]> {
    const parameters = mode + '@' + district + '@' + duration;
    return this.http.get<any[]>(this._baseUrl + '/' + parameters);
  }

  getCommissionGroupReport(duration: string, employee: number, commissionGroups: string): Observable<any[]> {
    return this.http.get<any[]>(this._baseUrl + '/' + duration + '/' + employee + '/' + commissionGroups);
  }

  getDurationInMonth(durations: string[]): string[]  {
    const result: string[] = [];
    durations.forEach((duration) => {
      const startOfMonth = moment(duration + '-01').startOf('month');
      const endOfMonth = moment(duration + '-01').endOf('month');
      result.push(startOfMonth.format(this.dateStartFormat) + '|' + endOfMonth.format(this.dateEndFormat));
    });

    return result;
  }


  getDuration(durations: string[]): string[]  {
    const result: string[] = [];
    const startDate = moment(durations[0]).format(this.dateStartFormat);
    const endDate = moment(durations[1]).format(this.dateEndFormat);
    result.push(startDate + '|' + endDate);
    return result;
  }

  getDurationForOneMonth(duration: string): string {
    const startOfMonth = moment(duration + '-01').startOf('month');
    const endOfMonth = moment(duration + '-01').endOf('month');
    return startOfMonth.format(this.dateStartFormat) + '|' + endOfMonth.format(this.dateEndFormat);
  }

  isCurrentMonthSelected(duration: string): boolean {
    const durationSelected = moment(duration + '-01');
    const today = moment();
    return today.format('MM') === durationSelected.format('MM') && today.format('YYYY') === durationSelected.format('YYYY');
  }

  CalculateFormulaValue(skuGroups: any[], conversionData: any, formulaValue: string, hoursWorkedData: any): number {
    const formulaElements = formulaValue.split(',');
    let OpenCloseCount = 0;
    let stringFormula = '';
    let result = 0;
    for (let i = 0; i < formulaElements.length; i++) {
      const currentWord = formulaElements[i];
      const nextWord = (i < formulaElements.length - 1) ? formulaElements[i + 1] : '';
      if (currentWord === '(') {
        OpenCloseCount = OpenCloseCount + 1;
        stringFormula += currentWord;
      } else if (currentWord === ')' && OpenCloseCount > 0) {
        OpenCloseCount = OpenCloseCount - 1;
        stringFormula += currentWord;
      } else if (currentWord === 'QTY') {
        const skuGroup = skuGroups.find(s => s.name === nextWord);
        if (skuGroup) {
          stringFormula += '(' + skuGroup.qty + ')';
        } else {
          stringFormula += 0;
        }
      } else if (currentWord === 'GP') {
        const skuGroup = skuGroups.find(s => s.name === nextWord);
        if (skuGroup) {
          stringFormula += '(' + skuGroup.gp + ')';
        } else {
          stringFormula += 0;
        }
      } else if (currentWord === 'REBIZ') {
        if (conversionData) {
          switch (nextWord) {
            case 'Boxes':
              stringFormula += '(' + conversionData.boxes + ')';
              break;
            case 'Traffic':
              stringFormula += '(' + conversionData.traffic + ')';
              break;
            case 'GrossProfit':
              stringFormula += '(' + conversionData.grossProfit + ')';
              break;
            default:
              stringFormula += 0;
              break;
          }
        } else {
          stringFormula += 0;
        }
      } else if (currentWord === 'HOURS') {
        if (hoursWorkedData) {
          switch (nextWord) {
            case 'Worked':
              stringFormula += '(' + hoursWorkedData.hoursWorked + ')';
              break;
            default:
              stringFormula += 0;
              break;
          }
        } else {
          stringFormula += 0;
        }
      } else if (currentWord === '+' || currentWord === '-' || currentWord === '*' || currentWord === '/') {
        stringFormula += currentWord;
      }
    }
    try {
     result = this.evaluate(stringFormula);
    } catch (e) {
      result = 0;
    }
    if (result === Infinity || result === -Infinity) {
      result = 0;
    }
    if (isNaN(result)) {
      result = 0;
    }
    return result;
  }

  evaluate(code: string) {
    const f = new Function('return ' + code);
    return f();
  }
}
