import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { Status } from '../models/status';
import { AuthService } from '../../../core/services/auth.service';
import { NotifierService } from 'angular-notifier';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _baseUrl = '/api/users';
  private users: User[] = [];
  private disabledUsersIncluded: User[] = [];

  constructor(private http: HttpClient,
    private _authService: AuthService,
    private _notifier: NotifierService) { }

  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(this._baseUrl);
  }

  getUsers(callback: any, forceLoading: boolean = false) {
    if (forceLoading || this.users.length === 0) {
      this.getAllUsers().subscribe((response: User[]) => {
        this.users = response;
        callback(this.filterUsersByStatus(Status.Active));
      }, (error) => { this._notifier.notify('error', error.message); }, () => { });
    } else {
      callback(this.filterUsersByStatus(Status.Active));
    }
  }

  getUsername(callback: any, userId: string) {
    if (this.users.length === 0) {
      this.getAllUsers().subscribe((response: User[]) => {
        this.users = response;
        callback(this.users.find(x => x.id == userId));
      }, (error) => { this._notifier.notify('error', error.message); }, () => { });
    } else {
      callback(this.users.find(x => x.id == userId));
    }
  }

  getUsersByClient(callback: any, clientId: number, forceLoading: boolean = false) {
    if (forceLoading || this.users.length === 0) {
      this.getAllUsers().subscribe((response: User[]) => {
        this.users = response.filter(x => x.client.id === clientId);
        callback(this.filterUsersByStatus(Status.Active));
      }, (error) => { this._notifier.notify('error', error.message); }, () => { });
    } else {
      callback(this.filterUsersByStatus(Status.Active));
    }
  }

  getUsersByClientNonbias(callback: any, clientId: number, forceLoading: boolean = false) {
    if (forceLoading || this.users.length === 0) {
      this.getAllUsers().subscribe((response: User[]) => {

        this.disabledUsersIncluded = response.filter(x => x.client.id === clientId);
        callback(this.disabledUsersIncluded);
      }, (error) => { this._notifier.notify('error', error.message); }, () => { });
    } else {
      callback(callback(this.disabledUsersIncluded));
    }
  }

  getUsersRegardlessOfStatus(callback: any, forceLoading: boolean = true) {
    if (forceLoading || this.users.length === 0) {
      this.getAllUsers().subscribe((response: User[]) => {
        this.users = response;
        callback(this.filterUsersByStatus(Status.Active));
      }, (error) => { this._notifier.notify('error', error.message); }, () => { });
    } else {
      callback(this.filterUsersByStatus(Status.Active));
    }
  }

  createUser(name: string, email: string, userName: string, password: string, clientId: number, userStatusCodeId: number, roleId: string)
    : Observable<any> {
    const user = {
      Name: name,
      Email: email,
      UserName: userName,
      Password: password,
      Client: { Id: clientId },
      UserStatusCode: { Id: userStatusCodeId },
      UserRoles: [{ Role: { Id: roleId } }],
      UserStatusCodeId: userStatusCodeId
    };

    return this.http.post(this._baseUrl, user);
  }

  updateUser(id: string, name: string, email: string, userName: string, clientId: number, userStatusCodeId: number, roleId: string)
    : Observable<any> {
    const user = {
      Id: id,
      Name: name,
      Email: email,
      UserName: userName,
      Client: { Id: clientId },
      UserStatusCode: { Id: userStatusCodeId },
      UserRoles: [{ Role: { Id: roleId } }]
    };

    return this.http.put(this._baseUrl, user);
  }

  deleteUser(id: string): Observable<any> {
    return this.http.delete(this._baseUrl + '/' + id);
  }
  resetPassword(id: string): Observable<any> {
    return this.http.get(this._baseUrl + '/' + id + '/ResetPassword');
  }

  changePassword(currentPassword: string, newPassword: string): Observable<any> {
    const id = this._authService.userId();

    const user = {
      Id: id,
      Password: currentPassword,
      NewPassword: newPassword
    };

    return this.http.put(this._baseUrl, user);
  }

  filterUsersByStatus(status: number, users: any[] = []) {
    if (users.length === 0) {
      users = this.users;
    }

    return users.filter(user => user.userStatusCode.id === status);
  }

  searchUser(filter: any) {
    return this.disabledUsersIncluded.filter(function (user) {
      let result = true;

      if (filter.client) {
        result = result && user.client.id === filter.client;
      }
      if (filter.regionId === 999999) {
        result = result && !filter.regionDistrictAndStore.includes(user.id);
      } else if (filter.regionDistrictAndStore.length > 0) {
        result = result && filter.regionDistrictAndStore.includes(user.id);
      }

      if (filter.name) {
        result = result && user.name.toLowerCase().includes(filter.name.toLowerCase());
      }

      if (filter.userName) {
        result = result && user.userName.toLowerCase().includes(filter.userName.toLowerCase());
      }

      if (filter.role) {
        result = result && user.userRoles.map(function (role) { return role.role.id; }).includes(filter.role);
      }

      if (filter.status) {
        result = result && user.userStatusCode.id === filter.status;
      }

      return result;
    });
  }
  checkPasswordStatus(): Observable<any> {
    return this.http.get<any>(this._baseUrl + '/CheckPasswordStatus');
  }
}
