import { Injectable, Component, OnInit } from '@angular/core';
import { Route, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'error',
  templateUrl: 'error.html',
  styleUrls: ['error.component.css']
})
@Injectable()
export class ErrorComponent implements OnInit {
  errorCode: any = 500;
  message: string = '';
  constructor(private router: ActivatedRoute) {
    let that = this;
    console.log('this.router', this.router.snapshot.params.errorCode);
    this.router.params.subscribe(params => {
      console.log('params', params.errorCode);
      that.errorCode = params.errorCode;
    });

    switch (this.router.snapshot.params.errorCode) {
      case '422': this.message = "Characters not supported. Please contact administrator."; break;
      case '400': this.message = "The request the client made is incorrect or corrupt, and the server can't understand it. Please contact administrator."; break;
      case '401': this.message = 'Not Authorized'; break;
      case '404': this.message = 'The page you requested cannot be found. Please contact administrator.'; break;
      case '500': this.message = "Internal server error. Please contact administrator."; break;
      default: this.message = "Internal server error. Please contact administrator."; break;
    }

  }

  ngOnInit(): void {
  }

}

export const ErrorRoute: Route = { path: 'error', component: ErrorComponent };
