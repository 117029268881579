<style>
  .tooltip {
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }

  .sort-column .tooltiptext {
    visibility: hidden;
    position: absolute;
    background-color: #fff;
    border-style: solid;
    color: black;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    margin-left: -300px;
    margin-top: 100px;
    /* Position the tooltip text - see examples below! */
    z-index: 1;
  }

  .sort-column:hover .tooltiptext {
    transition-delay: 1.5s;
    visibility: visible;
  }

  .abc {
    width: 200px;
    height: 200px;
  }

  .reportBoilerPlate {
    width: 200px;
    height: 200px;
  }

  .example-container {
    width: 400px;
    max-width: 100%;
    margin: 0 25px 25px 0;
    display: inline-block;
    vertical-align: top;
  }

  .dialog-border {
    border-bottom-style: solid;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-width: 1px;
  }

  .example-list {
    border: solid 1px #ccc;
    min-height: 60px;
    background: white;
    border-radius: 4px;
    overflow: hidden;
    display: block;
  }

  .example-box {
    padding: 20px 10px;
    border-bottom: solid 1px #ccc;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    background: white;
    font-size: 14px;
  }

  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .cdk-drag-placeholder {
    opacity: 0;
  }

  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .example-box:last-child {
    border: none;
  }

  .example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  ::ng-deep .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color: none;
  }

  td {
    border: 1px solid black;
    text-align: center;
  }

  table {
    border: 1px solid black;
  }

  th {
    border: 1px solid black;
    text-align: center;
  }
</style>


<div class="d-flex align-items-center  action-header">
  <mat-radio-group aria-label="Select an option" [(ngModel)]="optionRadioSelected">
    <mat-radio-button id="client" name="optionRadioSelected"
                      value="1" (change)="onRadioButtonChanged($event)">Regions and Districts</mat-radio-button>
    <mat-radio-button class="ml-5" id="region" name="optionRadioSelected"
                      value="2" (change)="onRadioButtonChanged($event)">Districts and Stores</mat-radio-button>
    <mat-radio-button class="ml-5" id="district" name="optionRadioSelected"
                      value="3" (change)="onRadioButtonChanged($event)">Stores and Employees</mat-radio-button>
  </mat-radio-group>
  <mat-form-field class="bottom-border-without-bg ml-5 filter-action-width">
    <mat-label class="form-label semibold">Duration</mat-label>
    <input matInput type="month" name="selectedDuration" [(ngModel)]="selectedDuration" (change)="onReportSelected();">
  </mat-form-field>
  <div>
    <!---<div *ngIf="selectedReport" class="margin-right-1em">Title: <b> {{selectedReport.title}}</b></div> -->
    <mat-form-field class="bottom-border-without-bg ml-5 filter-action-width">
      <mat-label>Select Report</mat-label>
      <mat-select [(ngModel)]="selectedReportId" #selectedReportName="ngModel" name="selectedReportName" (change)="onReportSelected()">
        <mat-option *ngFor="let report of reports" [value]="report.id">{{report.title}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="d-flex justify-content-between action-header mt-3">

  <div>
    <p class="heading-text">Uploaded </p>
    <span> {{uploadStatus}}</span>
  </div>
  <div>
    <a *ngIf="showModifyButtons" (click)="editReport(selectedReport)" style="margin-right:20px"><img class="edit-delet-btn" src="assets/img/edit.svg" /></a>
    <a *ngIf="showModifyButtons" (click)="deleteReport(selectedReport)" style="margin-right:20px"><img class="edit-delet-btn" src="assets/img/delete.svg" /></a>
    <button mat-raised-button *ngIf="showModifyButtons" color="primary" (click)="addNewReport()" style="margin-right:20px">Add New Report</button>
    <button mat-raised-button *ngIf="!disableModifyButton" color="primary" type="button" (click)="showModifyButtons = !showModifyButtons" style="margin-right:20px">
      <i class="fa" [ngClass]="showModifyButtons ? 'fa-check' : 'fa-times'"></i>
    </button>
  </div>
</div>

<!--- MAIN DISPLAY -->
<!--- RANK REPORT COMPONENTS-->
<div id="px" style=" margin-left:auto; margin-right:auto;" *ngIf="selectedReport!==undefined && reportDoneLoading && !firstLoad">
  <div class="row">
    <div class="col-sm-12">
      <div *ngIf="selectedReport!==undefined && reportDoneLoading && !firstLoad" class="rank-report-components">
        <table class="table table-bordered table-hover small-txt" width="90%" style=" border-style: solid">
          <tr>
            <th width="16%" style="word-wrap: break-word; text-align: center;"><div *ngFor="let groups of selectedReport.reportTableGroup" style=" font-weight:bold">  <span>{{groups.shortTitle}}  {{(groups.rankingPercentage*100).toFixed(0)}}%</span><br /></div><span><button *ngIf="showModifyButtons" mat-raised-button color="primary" (click)="addNewReportTableGroup()">+ Group</button></span></th>
            <th *ngFor="let groups of selectedReport.reportTableGroup" width="{{(sizeOfReportOnPage/selectedReport.totalQuantityColumns)*(groups.numberOfColumns+2)}}%"
                style="word-wrap: break-word;">
              <div style="text-align: center; height:100%; font-size:1.5em">
                {{groups.longTitle}}<br />
                <div style="font-size:0.5em">
                  {{groups.description}}<br />
                  <div style="vertical-align:bottom; font-size:1.5em">
                    <a style="margin-top:10px" *ngIf="showModifyButtons" (click)="editReportTableGroup(groups)" class="icon-btn"><i class="fa fa-edit"></i></a>
                    <a *ngIf="showModifyButtons" (click)="deleteReportTableGroup(groups)" class="icon-btn"><i class="fa fa-trash"></i></a><br /><br /><br />
                    <button mat-raised-button *ngIf="showModifyButtons" color="primary" (click)="addNewReportTableColumn(groups)">+ Column</button>
                  </div>
                </div>
              </div>
            </th>
          </tr>
          <tr>
          </tr>
        </table>
        <table #GroupAReportTable class="table table-bordered table-hover small-txt" width="90%">
          <thead>
            <tr>
              <th width="8%" style="text-align:center; font-size:2.0em">{{locationTypeCodeName(selectedReport.GroupA.locationTierEnum)}}</th>
              <th width="4%" (click)="sortTable( 1 );sortTableB(1);" class="sort-column" style="text-align:center; font-size:1.2em">
                Rank
                <i class="fa fa-caret-up" *ngIf="-2 == columnUnderSorting && isAscendingOrder"></i>
                <i class="fa fa-caret-down" *ngIf="-2 == columnUnderSorting && !isAscendingOrder"></i>
              </th>
              <th width="4%" (click)="sortTable( 2 );sortTableB(2);" class="sort-column" style="text-align:center; font-size:1.2em">
                Score
                <i class="fa fa-caret-up" *ngIf="-1 == columnUnderSorting && isAscendingOrder"></i>
                <i class="fa fa-caret-down" *ngIf="-1 == columnUnderSorting && !isAscendingOrder"></i>
              </th>
              <th style=" white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:1px; text-align: center;"
                  width="{{(sizeOfReportOnPage/selectedReport.totalQuantityColumns)}}%"
                  *ngFor="let columns of selectedReport.listOfColumns, let i = index" (click)="sortTable( i +3 ) ; sortTableB(i+3);" class="sort-column">
                <span class="tooltiptext">{{columns.formula.name}}<br>{{columns.formula.formulaValue}}</span>
                <span *ngIf="columns.formula && columns.nameValueOverride !== 'Rank'">
                  <!-- add color to column [ngStyle]="{'background-color': columns.formula.color }-->
                  {{columns.formula.name}}<br />
                  <a *ngIf="showModifyButtons && columns.nameValueOverride !== 'Ranking' " (click)="editReportTableGroupColumn(columns)" class="icon-btn"><i class="fa fa-edit"></i></a>
                  <a *ngIf="showModifyButtons && columns.nameValueOverride !== 'Ranking'" (click)="deleteReportTableGroupColumn(columns)" class="icon-btn"><i class="fa fa-trash"></i></a>
                  <span *ngIf="columns.nameValueOverride">{{columns.nameValueOverride}} </span>
                  <a class="icon-btn">
                    <!---  <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" title="{{columns.formula.name}}{{columns.formula.formulaValue}}"></i>-->
                    <i class="fa fa-caret-up" *ngIf="i == columnUnderSorting && isAscendingOrder"></i>
                    <i class="fa fa-caret-down" *ngIf="i == columnUnderSorting && !isAscendingOrder"></i>
                  </a>
                </span>
                <span *ngIf="columns.nameValueOverride == 'Ranking'">
                </span>
                <span *ngIf="columns.nameValueOverride == 'Rank'">
                  {{columns.formula.name}}<br />
                  {{columns.nameValueOverride}}<a class="icon-btn">
                    <i class="fa fa-caret-up" *ngIf="i == columnUnderSorting && isAscendingOrder"></i>
                    <i class="fa fa-caret-down" *ngIf="i == columnUnderSorting && !isAscendingOrder"></i>
                  </a>
                </span>
              </th>
            </tr>
            <tr *ngIf="showModifyButtons">
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td width="{{(sizeOfReportOnPage/selectedReport.totalQuantityColumns)}}%"
                  *ngFor="let columns of selectedReport.listOfColumns">
                <span *ngIf="columns.formula && columns.nameValueOverride !== 'Rank'">
                  <input type="number" min="1" max="5" width="60px"
                         [(ngModel)]="columns.redValueGroup1" #redValueGroup1="ngModel" name="redValueGroup1" />
                  <a *ngIf="showModifyButtons && columns.nameValueOverride !== 'Ranking'" (click)="saveReportTableColumnRedValue(columns)" class="icon-btn">
                    <i class="fa fa-edit"></i>
                  </a>
                  <a *ngIf="showModifyButtons && columns.nameValueOverride === 'Ranking'" (click)="saveReportTableGroupRedValue(columns)" class="icon-btn">
                    <i class="fa fa-edit"></i>
                  </a>
                </span>
                <span *ngIf="columns.nameValueOverride == 'Rank'">X </span>
              </td>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let seller of selectedReport.GroupA.sellers ">
              <tr role="row"
                  *ngIf="evaluateFinalRank(seller, 'A') !== groupALowestRank"
                  [ngStyle]="{ 'padding':'0px', 'margin':'0px', 'height':'10px', 'overflow':'hidden' }">
                <td [ngStyle]="{'padding':'0px', 'margin':'0px', 'height':'10px', 'overflow':'hidden' }"
                    style="white-space: nowrap; text-overflow: ellipsis; max-width: 1px;" width="8%">
                  {{seller.name}}
                </td>
                <td width="4%" [ngStyle]="{'background-color': perc2color(evaluateFinalRank(seller, 'A'), groupALowestRank),
                  'padding':'0px', 'margin':'0px', 'height':'10px', 'overflow':'hidden' }">
                  {{evaluateFinalRank(seller, 'A')}}
                </td>
                <td [ngStyle]="{ 'padding':'0px', 'margin':'0px', 'height':'10px', 'overflow':'hidden' }" width="4%">
                  {{evaluateScore(seller, 'A').toFixed(2)}}
                </td>
                <td width="{{(sizeOfReportOnPage/selectedReport.totalQuantityColumns)}}%"
                    *ngFor="let columns of selectedReport.listOfColumns"
                    [ngStyle]="{'background-color': perc2color(findYourRankOrFormulaRaw(seller,columns), columns.groupALowestRank),
                  'font-weight' : 'bold',
                  'padding':'0px', 'margin':'0px', 'height':'10px', 'overflow':'hidden' }">
                  <span *ngIf="columns.formula && !columns.nameValueOverride" [ngStyle]="{'color':isFormulaUnderWarningLimit(findYourRankOrFormulaRaw(seller,columns),columns.redValueGroup1,columns)}"> {{findYourRankOrFormula(seller,columns)}}<!---{{getFormulaComputedPropertyValue(columns, seller, 'A')}}---> </span>
                  <span *ngIf="columns.nameValueOverride == 'Ranking'" [ngStyle]="{'color':isFormulaUnderWarningLimit(findYourRankOrFormulaRaw(seller,columns),columns.redValueGroup1,columns)}"> {{findYourRankOrFormula(seller,columns)}}<!---{{getFormulaComputedPropertyValue(columns, seller, 'A')}}---> </span>
                  <span *ngIf="columns.nameValueOverride == 'Rank'">  {{findYourRankOrFormulaRaw(seller,columns).toFixed(0)}} </span>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <table #GroupBReportTable class="table table-bordered table-hover small-txt" width="90%">
          <thead>
            <tr>
              <th style="font-size: 1.6em; word-wrap:hyphenate; text-align: center; " width="8%">
                {{locationTypeCodeName(selectedReport.GroupB.locationTierEnum)}}
              </th>
              <th style="font-size: 1.2em; word-wrap:hyphenate; text-align: center;" width="4%"
                  (click)="sortTable( 1 );sortTableB(1);">
                Rank
                <a class="icon-btn">
                  <!---<i class="fa fa-info-circle" data-toggle="tooltip" data-html="true" title="<span>{{columns.formula.name}}<br/>{{columns.formula.formulaValue}}</span>"> </i>-->
                  <i class="fa fa-caret-up" *ngIf="-2 == columnUnderSorting && isAscendingOrder"></i>
                  <i class="fa fa-caret-down" *ngIf="-2 == columnUnderSorting && !isAscendingOrder"></i>
                </a>
              </th>
              <th style="font-size: 1.2em; word-wrap:hyphenate; text-align: center;" width="4%"
                  (click)="sortTable( 2 );sortTableB(2);">
                Score
                <a class="icon-btn">
                  <!---<i class="fa fa-info-circle" data-toggle="tooltip" data-html="true" title="<span>{{columns.formula.name}}<br/>{{columns.formula.formulaValue}}</span>"> </i>-->
                  <i class="fa fa-caret-up" *ngIf="-1 == columnUnderSorting && isAscendingOrder"></i>
                  <i class="fa fa-caret-down" *ngIf="-1 == columnUnderSorting && !isAscendingOrder"></i>
                </a>
              </th>
              <th style=" white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:1px; text-align: center;"
                  width="{{(sizeOfReportOnPage/selectedReport.totalQuantityColumns)}}%"
                  *ngFor="let columns of selectedReport.listOfColumns, let i = index" (click)="sortTable( i +3 ); sortTableB(i+3);" class="sort-column">
                <span class="tooltiptext">{{columns.formula.name}}<br>{{columns.formula.formulaValue}}</span>
                <span *ngIf="columns.formula && columns.nameValueOverride !== 'Rank'" style="text-align:center">
                  {{columns.formula.name}}<br />
                  <span *ngIf="columns.nameValueOverride">{{columns.nameValueOverride}} </span>

                  <a class="icon-btn">
                    <!---<i class="fa fa-info-circle" data-toggle="tooltip" data-html="true" title="<span>{{columns.formula.name}}<br/>{{columns.formula.formulaValue}}</span>"> </i>-->
                    <i class="fa fa-caret-up" *ngIf="i == columnUnderSorting && isAscendingOrder"></i>
                    <i class="fa fa-caret-down" *ngIf="i == columnUnderSorting && !isAscendingOrder"></i>
                  </a>
                </span>
                <span *ngIf="columns.nameValueOverride == 'Ranking'">
                  <br />
                </span>
                <span *ngIf="columns.nameValueOverride == 'Rank'">
                  {{columns.formula.name}}<br />
                  {{columns.nameValueOverride}}
                  <a class="icon-btn">
                    <i class="fa fa-caret-up" *ngIf="i == columnUnderSorting && isAscendingOrder"></i>
                    <i class="fa fa-caret-down" *ngIf="i == columnUnderSorting && !isAscendingOrder"></i>
                  </a>
                </span>
              </th>
            </tr>
            <tr *ngIf="showModifyButtons">
              <td>X</td>
              <td>X</td>
              <td>X</td>
              <td width="{{(sizeOfReportOnPage/selectedReport.totalQuantityColumns)}}%"
                  *ngFor="let columns of selectedReport.listOfColumns">
                <span *ngIf="columns.formula && columns.nameValueOverride !== 'Rank'">
                  <input type="number" min="1" max="5" width="60px"
                         [(ngModel)]="columns.redValueGroup2" #redValueGroup2="ngModel" name="redValueGroup2" />
                  <a *ngIf="showModifyButtons && columns.nameValueOverride !== 'Ranking'" (click)="saveReportTableColumnRedValue(columns)" class="icon-btn">
                    <i class="fa fa-edit"></i>
                  </a>
                  <a *ngIf="showModifyButtons && columns.nameValueOverride === 'Ranking'" (click)="saveReportTableGroupRedValue(columns)" class="icon-btn">
                    <i class="fa fa-edit"></i>
                  </a>
                </span>
                <span *ngIf="columns.nameValueOverride === 'Rank'">  X </span>
              </td>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let seller of selectedReport.GroupB.sellers ">
              <tr *ngIf="evaluateFinalRank(seller, 'B') !== groupBLowestRank"
                  [ngStyle]="{ 'padding':'0px', 'margin':'0px', 'height':'10px', 'overflow':'hidden' }">
                <td [ngStyle]="{ 'padding':'0px', 'margin':'0px', 'height':'10px', 'overflow':'hidden' }"
                    style="white-space: nowrap; text-overflow:ellipsis; max-width:1px;"
                    width="8%">
                  <div>{{seller.name}}</div>
                </td>
                <td width="4%" [ngStyle]="{'background-color': perc2color(evaluateFinalRank(seller, 'B'), groupBLowestRank),
                   'padding':'0px', 'margin':'0px', 'height':'10px', 'overflow':'hidden' }">
                  <div>{{evaluateFinalRank(seller, 'B')}}</div>
                </td>
                <td width="4%"
                    [ngStyle]="{'padding':'0px', 'margin':'0px', 'height':'10px', 'overflow':'hidden' }">
                  <div>{{evaluateScore(seller, 'B').toFixed(2)}}</div>
                </td>
                <td style="text-overflow: clip;"
                    width="{{(sizeOfReportOnPage/selectedReport.totalQuantityColumns)}}%"
                    *ngFor="let columns of selectedReport.listOfColumns"
                    [ngStyle]="{'background-color': perc2color(findYourRankOrFormulaRaw(seller,columns), columns.groupBLowestRank),
                  'font-weight' : 'bold',
                  'padding':'0px', 'margin':'0px', 'height':'10px', 'overflow':'hidden' }">
                  <div>
                    <span *ngIf="columns.formula && !columns.nameValueOverride" [ngStyle]="{'color':isFormulaUnderWarningLimit(findYourRankOrFormulaRaw(seller,columns),columns.redValueGroup2,columns)}"> {{findYourRankOrFormula(seller,columns)}} </span>
                    <span *ngIf="columns.nameValueOverride == 'Ranking'" [ngStyle]="{'color':isFormulaUnderWarningLimit(findYourRankOrFormulaRaw(seller,columns),columns.redValueGroup2,columns)}"> {{findYourRankOrFormula(seller,columns)}} </span>
                    <span *ngIf="columns.nameValueOverride == 'Rank'"> {{findYourRankOrFormulaRaw(seller,columns).toFixed(0)}} </span>
                  </div>
                </td>

              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

