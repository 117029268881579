<div class="col-md-12 bg-white p-0  mb-3" *ngIf="!invoiceIsLoading">
  <div class="row pt-20">
    <div class="col-md-3">
      <mat-form-field style="" class="searchtab margin-left-1em">
        <mat-label>Month and Year</mat-label>
        <input matInput [matDatepicker]="datepicker" [formControl]="date">
        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker startView="year" (yearSelected)="yearHandler($event)" (monthSelected)="monthHandler($event, datepicker)"></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <div style="display: flex;" class="margin-left-1em vertical-center">
        <label class="form-label semibold margin-left-1em margin-right-1em" for="selectedDayToFilter">Ignore Recent Days</label>
        <mat-icon color="warn" class="material-icons-round" mat-raised-button type="button" (click)="filterDays(1)">remove_circle</mat-icon>
        <span class="margin-right-1em margin-left-1em">{{ getDateFromIgnore()  }}</span>
        <mat-icon color="primary" class="material-icons-round" mat-raised-button type="button" (click)="filterDays(-1)">add_circle</mat-icon>
      </div>
    </div>
    <div class="col-md-3">
      <div class="row">
        <div class="col-md-6">
          <mat-checkbox (change)="OnShowReconciledToggle($event)" [checked]="showReconciled" class="margin-left-1em vertical-center" [labelPosition]="'before'">Show Reconciled</mat-checkbox>
        </div>
        <div class="col-md-6">
          <mat-checkbox (change)="OnShowAllToggle($event)" style="margin-left: 30px;" [checked]="showAll" class="margin-left-1em vertical-center" [labelPosition]="'before'">Include Non-Variance</mat-checkbox>
        </div>
      </div>
    </div>
    <div class="col-md-3" *ngIf="doneLoading">
      <div class="flex-items" style="min-width: 12vw; display: inline-block;">
        Total Charged To You :
        <span style="text-align: right; float: right; font-weight: bold; margin-right: 20px">: ${{originalData.totalCollected.toFixed(2) }}</span>
        <br />
        Total Collected :
        <span style="text-align: right; float: right; font-weight: bold; margin-right: 20px">: ${{ originalData.totalCharged.toFixed(2) }}</span>
        <br />
        Most Recent Billpay Data Date :
        <span style="text-align: right; float: right; font-weight: bold; margin-right: 20px"> {{ recentDataDate }}</span>
      </div>

    </div>
  </div>
</div>



<div class="col-md-12 bg-white mb-3 p-3">
  <div class="row">
    <div class="col-md-3">
      <mat-form-field class="searchtab">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search" [(ngModel)]="searchTerm">
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field class="margin-left-1em searchtab">
        <mat-select placeholder="Select columns" multiple [(value)]="selectedColumns" (selectionChange)="columnsChanged($event)">
          <mat-select-trigger>
            {{selectedColumns ? getColumnDefinationByMappedToProperty(selectedColumns[0]).name : ''}}
            <span *ngIf="selectedColumns?.length > 1" class="example-additional-selection">
              (+{{selectedColumns.length - 1}} {{selectedColumns?.length === 2 ? 'other' : 'others'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let column of columnDefinations" [value]="column.mappedToProperty">{{column.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-6 text-right pt-2">
      <button *ngIf="doneLoading" mat-raised-button color="primary" class="" type="button" (click)="exportTableToExcel()"> Export </button>
      <button *ngIf="toBeReconciled.size>0" mat-raised-button color="primary" class="" type="button" (click)="hideMtnSelected(true)">Reconcile selected</button>
      <button *ngIf="showReconciled && toBeUnreconciled.size>0" mat-raised-button color="primary" class="" type="button" (click)="hideMtnSelected(false)">Unreconcile selected</button>
    </div>
  </div>
</div>
<div class="table-container">
  <table *ngIf="selectedColumns.length>0" mat-table [dataSource]="dataSource" matSort class="table table-stripped table-hover small-txt">
    <ng-container *ngFor="let column of getDisplayedColumns(); let colIndex = index;" [matColumnDef]="column">
      <th [hidden]="getColumnDefinationByMappedToProperty(column).hide" class="sort-column mat-sort-header-button-custom mat-header-cell" mat-header-cell *matHeaderCellDef mat-sort-header
          [matTooltip]="getColumnDefinationByMappedToProperty(column).name" [disabled]="!getColumnDefinationByMappedToProperty(column).isSortable">
        {{getColumnDefinationByMappedToProperty(column).name}}
      </th>
      <ng-container *matCellDef="let element">
        <td mat-cell class="mat-cell" [matTooltip]="getTextBydata(element,getColumnDefinationByMappedToProperty(column))"
            [ngClass]="{'yellow' : selectedMTN.mtn == element.mtn}" (click)="openNotesDialog(column,element)"  [matTooltipDisabled]="toolTip.scrollWidth <= toolTip.clientWidth" #toolTip>
          <span *ngIf="column !== 'isHidden' && column !== 'notes' && column !== 'icon'">
            {{getTextBydata(element,getColumnDefinationByMappedToProperty(column))}}
          </span>
          <span *ngIf="column === 'notes'"><div class="fa fa-sticky-note">{{getNoteCountText(element)}}</div></span>
          <span *ngIf="column === 'icon'">
            <div class="fas fa-circle" [ngStyle]="{'color': getIconColor(element)}"></div>
          </span>
          <mat-checkbox *ngIf="column === 'isHidden'"  type="checkbox" [(ngModel)]="element['isHidden']" [checked]="element['isHidden']" (change)="collectToReconcile($event,element)"></mat-checkbox>
          <span *ngIf="column == 'notes' && selectedMTN.mtn == element.mtn"></span>
        </td>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
    <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();" (click)="selectMTN(row)"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="10" showFirstLastButtons></mat-paginator>
 </div>
