<style>
  .loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }

  .custom-accordion .mat-expansion-panel-content {
    background-color: white;
    color: white;
  }

  .custom-accordion .mat-expansion-panel-body {
    background-color: white;
  }

  ::ng-deep .left-aligned-tabs .mat-tab-label-container{
    flex-grow:0;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .box {
    height: 20px;
    width: 20px;
    margin-bottom: 15px;
    margin-left: 25px;
    border: 1px solid black;
    float: left;
  }

  .red {
    background-color: red;
  }

  .green {
    background-color: green;
  }

  .blue {
    background-color: blue;
  }

  .orange {
    background-color: orange;
  }

  .yellow {
    background-color: yellow;
  }

  .purple {
    background-color: purple;
  }

  .summaryContainer {
    margin-left: 15px;
    min-width: 280px;
    max-width: 280px;
    background: #ffffff;
    min-height: 100px;
    max-height: 100px;
    display: inline-block;
    float: left;
    align-content: center;
  }

  .summaryBox {
    width: 200px;
    height: 100px;
    background: #ffffff;
  }




  .tabDisplay {
    display: inline-block;
    float: left;
    width: auto;
    min-width: 40px;
    height: 40px;
  }

  .tabDisplaySelected {
    display: inline-block;
    float: left;
    width: auto;
    min-width: 40px;
    height: 40px;
    background-color: #143085;
    border-color: darkblue;
    border-Top-style: solid;
    border-Right-style: solid;
    border-left-style: solid;
    border-top-width: 1.3px;
    border-right-width: 1.3px;
    border-left-width: 1.3px;
  }

  .tabDisplay > a {
    margin-left: 15px;
    margin-right: 15px;
    color: var(--blue);
  }

  .tabDisplaySelected > a {
    margin-left: 15px;
    margin-right: 15px;
    color: #ffffff;
  }

  .goalChart {
    min-height: 180px;
    max-height: 260px;
    min-width: 180px;
    max-width: 260px;
    width: 260px;
    height: 260px;
    display: inline-block;
    float: left;
    align-content: center;
    background: #ffffff;
  }

  .chartShell {
    margin-left: 15px;
    background: #ffffff;
    min-width: 200px;
    max-width: 280px;
    min-height: 200px;
    max-height: 280px;
    width: 280px;
    height: 280px;
    display: inline-block;
    float: left;
    align-content: center;
  }

  .mat-column-checkbox {
    width: 60px;
    white-space: normal;
  }
</style>

<header class="">
  <div class="tbl">
    <div class="tbl-row">
      <div class="tbl-cell">
        <h3 style="float: left;">
          <!--Performance Summary For -->
          <span style="" *ngIf="selectedEmployee">
            {{selectedEmployee.user.name}}  -  {{monthNames[dateNow.getMonth()]}} {{dateNow.getFullYear()}}
          </span>
        </h3>
      </div>
    </div>
  </div>
</header>
<div class="box-typical box-typical-padding" style=" min-width:90%; ">
  <form>
    <div style=" display: flex; flex-flow: wrap;">
      <mat-form-field>
        <mat-label class="form-label semibold" style="max-width:100px">Duration</mat-label>
        <input matInput type="month" name="selectedDuration" [(ngModel)]="selectedDuration" (change)="onDurationSelected();">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Rank Report</mat-label>
        <mat-select [(ngModel)]="selectedReportId" #selectedReportName="ngModel"
                    name="selectedReportName" (change)="onReportSelected()">
          <mat-option *ngFor="let report of reports" [value]="report.id">
            {{report.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div style=" display: flex; flex-flow: wrap;">
        <div *ngIf="currentMonthYear !== selectedDuration"><div class="box blue" style="margin-left:25px;"></div><label class="form-label semibold " style="margin-left:5px; float:left">Accomplished</label> </div>
        <div *ngIf="currentMonthYear === selectedDuration"><div class="box blue" style="margin-left:25px;"></div><label class="form-label semibold " style="margin-left:5px; float:left">Current</label> </div>
        <div *ngIf="currentMonthYear === selectedDuration"><div class="box green" style="margin-left:25px;"></div><label class="form-label semibold " style="margin-left: 5px; float: left">Trending</label></div>
      </div>
      <mat-divider style="margin-left:1em" [vertical]="true"></mat-divider>
      <div style=" display: flex; flex-flow: wrap;">
        <div *ngIf="highestGoalTier>= 1"><div class="box red"></div><label class="form-label semibold " style="margin-left: 5px; float: left">1st Goal </label></div>
        <div *ngIf="highestGoalTier>= 2"><div class="box orange"></div><label class="form-label semibold " style="margin-left: 5px; float: left">2nd Goal </label></div>
        <div *ngIf="highestGoalTier>= 3"><div class="box yellow"></div><label class="form-label semibold " style="margin-left: 5px; float: left">3rd Goal </label></div>
        <div *ngIf="highestGoalTier>= 4"><div class="box purple"></div><label class="form-label semibold " style="margin-left: 5px; float: left">4th Goal </label></div>
      </div>
      <mat-divider style="margin-left:1em" [vertical]="true" *ngIf="checkRewardsPermission()"></mat-divider>
      <div style="margin-left:50px" *ngIf="checkRewardsPermission()">Unclaimed Rewards : ${{rewardObject.unclaimedDollars}}</div>
    </div>
  </form>
  <br />
  <div *ngIf="userHasNoGoals && selectedEmployee" class="d-xl-flex justify-content-xl-center">
    <div style="text-align: center;">
      Please setup individual/store goals to view Performance Summary Dashboard <br />
      <a [routerLink]="['/mtd-report']"> Click here to view Employee MTD</a>
    </div>
  </div>
  <div *ngIf="selectedCommissionUser && selectedCommissionUser.commissionGroups && selectedEmployeeId != 0 && !loadingInProgress" class="overflow">
    <mat-tab-group class="left-aligned-tabs"  mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="selectedTabChange($event)" backgroundColor="primary">
      <mat-tab *ngFor="let commissionGroup of groups" label="{{commissionGroup.target == 'self'?commissionGroup.name:commissionGroup.target}}">
        <div>
          <br />
          <div *ngIf="userHasNoGoals && selectedEmployee" class="d-xl-flex justify-content-xl-center">
            <div style="text-align: center;">
              Please setup individual/store goals to view Performance Summary Dashboard <br />
              <a [routerLink]="['/mtd-report']"> Click here to view Employee MTD</a>
            </div>
          </div>
          <div *ngIf="!loadingInProgress && dataFound" style="padding-bottom: 50px">
            <div class="col" style="display: flex; flex-direction: column;" *ngIf="selectedGroup && selectedEmployeeId != 0">
              <mat-accordion class="custom-accordion">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Pay Details
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="content-container d-xl-flex justify-content-xl-center" style="min-width: 100%; max-width: 100%;">
                    <div class="d-xl-flex justify-content-xl-center align-items-xl-center summaryContainer" *ngIf="selectedGroup.target != 'Combined Sales'">
                      <div class="summaryBox">
                        <p class="text-center d-xl-flex justify-content-xl-center align-items-xl-end" style="font-size: 16px">Rank</p>
                        <p class="d-xl-flex justify-content-xl-center align-items-xl-center"
                           *ngIf="selectedGroup.target == 'self'" style="width: 100%; height: 50px; text-align: center; font-size: 30px;">{{getRankString('B')}}</p>
                        <p *ngIf="selectedGroup.target != 'self'" class="d-xl-flex justify-content-xl-center align-items-xl-center"
                           style="width: 100%; height: 50px; text-align: center; font-size: 30px;">{{getRankString('A', selectedGroup.target)}}</p>
                      </div>
                    </div>
                    <div class="d-xl-flex justify-content-xl-center align-items-xl-center summaryContainer">
                      <div class="summaryBox">
                        <p class="text-center d-xl-flex justify-content-xl-center align-items-xl-end" style="font-size: 16px">Earned</p>
                        <p class="d-xl-flex justify-content-xl-center align-items-xl-center"
                           style="width: 100%; height: 50px; text-align: center; font-size: 30px;">
                          <span *ngIf="selectedGroup.earnedPay < selectedGroup.maxEarnable " style="color: var(--danger);">${{selectedGroup.earnedPay.toFixed(0)}}</span>
                          <span *ngIf="selectedGroup.earnedPay >= selectedGroup.maxEarnable " style="color: forestgreen;">${{selectedGroup.earnedPay.toFixed(0)}}</span>
                        </p>
                      </div>
                    </div>
                    <div class="d-xl-flex justify-content-xl-center align-items-xl-center summaryContainer" *ngIf="currentMonthYear == selectedDuration">
                      <div class="summaryBox">
                        <p class="text-center d-xl-flex justify-content-xl-center align-items-xl-end" style="font-size: 16px">Trending</p>
                        <p class="d-xl-flex justify-content-xl-center align-items-xl-center"
                           style="width: 100%; height: 50px; text-align: center; font-size: 30px;">
                          <span *ngIf="selectedGroup.trendingEarned < selectedGroup.maxEarnable " style="color: var(--danger);">${{selectedGroup.trendingEarned.toFixed(0)}}</span>
                          <span *ngIf="selectedGroup.trendingEarned >= selectedGroup.maxEarnable " style="color: forestgreen;">${{selectedGroup.trendingEarned.toFixed(0)}}</span>
                        </p>
                      </div>
                    </div>
                    <div class="d-xl-flex justify-content-xl-center align-items-xl-center summaryContainer" *ngIf="currentMonthYear == selectedDuration">
                      <div class="summaryBox">
                        <p class="text-center d-xl-flex justify-content-xl-center align-items-xl-end" style="font-size: 16px">MaxPotential</p>
                        <p class="d-xl-flex justify-content-xl-center align-items-xl-center" style="width: 100%; height: 50px; text-align: center; font-size: 30px;">
                          ${{selectedGroup.maxEarnable.toFixed(0)}}
                        </p>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div *ngIf="selectedGroup && selectedGroup.target != 'Combined Sales'" class="col text-left d-xl-flex align-items-xl-end" style="min-height: 280px; max-height: 280px; margin-top: 25px; margin-left: 0px; width: 100%; height: 280px;">
              <div class="d-xl-flex justify-content-xl-center" style="min-width: 100%; max-width: 100%;">
                <div *ngFor="let goal of selectedGroup.commissionFormulas; let j=index"
                     class="d-xl-flex chartShell"
                     style="height: 280px; width: 280px; min-width: 280px; max-width: 280px; min-height: 280px; max-height: 280px;">
                  <div style="width: 100%; height: 100%;">
                    <div class="d-xl-flex justify-content-xl-center" style="height: 30px;">
                      <a class="float-left d-xl-flex justify-content-xl-center align-items-xl-start" [routerLink]="['/forecast']">{{goal.formula.name}}</a>
                    </div>
                    <div class="d-xl-flex justify-content-xl-center" style="height: 240px;">
                      <div class="goalChart" style="height: 240px; width: 240px;">
                        <canvas baseChart *ngIf="goal.chartData"
                                [data]="goal.chartData.data"
                                [options]="goal.chartData.options"
                                [type]="goal.chartData.type" style="display: block; height: 240px; width: 240px;"></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="selectedGroup" class="col text-left d-xl-flex align-items-xl-end" style="min-height: 280px; max-height: 280px; margin-top: 25px; margin-left: 0px; width: 100%; height: 280px;">
              <div class="d-xl-flex justify-content-xl-center" style="min-width: 100%; max-width: 100%;">
                <div *ngFor="let goal of selectedGroup.commissionFormulas; let j=index" class="d-xl-flex chartShell" style="height: 280px; width: 280px; min-width: 280px; max-width: 280px; min-height: 280px; max-height: 280px;">
                  <div style="width: 100%; height: 100%;">
                    <div class="d-xl-flex justify-content-xl-center" style="height: 30px;">
                      <a class="float-left d-xl-flex justify-content-xl-center align-items-xl-start" [routerLink]="['/mtd-report']">{{goal.formula.name}}</a>
                    </div>
                    <div class="d-xl-flex justify-content-xl-center" style="height: 240px;">
                      <div class="goalChart" style="height: 240px; width: 240px;">
                        <canvas baseChart *ngIf="goal.barChartData"
                                [data]="goal.barChartData.data"
                                [options]="goal.barChartData.options"
                                [type]="goal.barChartData.type" style="display: block; height: 240px; width: 240px;"></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="selectedGroup && (selectedGroup.target != 'self' || showYOYChartForUser)"
                 class="col text-left d-xl-flex align-items-xl-end"
                 style="min-height: 280px; max-height: 280px; margin-top: 25px; margin-left: 0px; width: 100%; height: 280px;">
              <div class="d-xl-flex justify-content-xl-center" style="min-width: 100%; max-width: 100%;">
                <div *ngFor="let goal of selectedGroup.commissionFormulas; let j=index"
                     class="d-xl-flex chartShell"
                     style="height: 280px; width: 280px; min-width: 280px; max-width: 280px; min-height: 280px; max-height: 280px;">
                  <div style="width: 100%; height: 100%;">
                    <div class="d-xl-flex justify-content-xl-center" style="height: 30px;">
                      <a class="float-left d-xl-flex justify-content-xl-center align-items-xl-start">{{goal.formula.name}}</a>
                    </div>
                    <div class="d-xl-flex justify-content-xl-center" style="height: 240px;">
                      <div class="goalChart" style="height: 240px; width: 240px;">
                        <canvas baseChart *ngIf="goal.barYOYChartData"
                                [data]="goal.barYOYChartData.data"
                                [options]="goal.barYOYChartData.options"
                                [type]="goal.barYOYChartData.type" style="display: block; height: 240px; width: 240px;"></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab [hidden]="!checkRewardsPermission()" label="Rewards" (click)="selectRewardTab()">
        <br /><br />
        <div style=" display: flex; flex-flow: wrap; ">
          <mat-label>Current Claim ${{rewardObject.currentClaim}}</mat-label>
          <mat-form-field appearance="fill">
            <mat-select [(ngModel)]="rewardObject.selectedOptionId" #selectedOptionId="ngModel" name="selectedOptionId">
              <mat-option *ngFor="let option of claimOptionList" [value]="option.id">{{option.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <button color="primary" style=" max-height:36px; margin-left: 50px" mat-raised-button type="button" [disabled]="!rewardObject.selectedOptionId || rewardObject.currentClaim == 0 || rewardObject.currentClaim < 25" (click)="openFinalizeClaimCreateModal()"> Claim </button>
        </div>
        <br /><br />
        <table #TABLE *ngIf="selectedColumnsRewards.length>0 " mat-table [dataSource]="dataSourceRewards" matSort class="table table-bordered table-hover small-txt"
               [hidden]="!tableready">
          <ng-container *ngFor="let column of getDisplayedColumnsRewards(); let colIndex = index;" [matColumnDef]="column">
            <th [hidden]="getColumnDefinationByMappedToPropertyRewards(column).hide" class="sort-column mat-sort-header-button-custom mat-header-cell" mat-header-cell *matHeaderCellDef mat-sort-header
                [matTooltip]="getColumnDefinationByMappedToPropertyRewards(column).name" [disabled]="!getColumnDefinationByMappedToPropertyRewards(column).isSortable">
              <span *ngIf="column !== 'checkbox'">{{getColumnDefinationByMappedToPropertyRewards(column).name}}</span>
              <span *ngIf="column == 'checkbox'">
                <input type="checkbox" [checked]="(rewardObject.selectAll)" (change)="selectAllRewards($event.target.checked)">
              </span>
            </th>
            <td mat-cell *matCellDef="let element" class="mat-cell" [matTooltip]="getTextByDataRewards(element,getColumnDefinationByMappedToPropertyRewards(column))">
              <span *ngIf="column == 'checkbox'">
                <input type="checkbox" [checked]="(rewardObject.claimRewardList.includes(element.id))" (change)="updateRewardClaim(element.id, $event.target.checked)">
              </span>
              <span *ngIf="column !== 'checkbox' && column !== 'isHidden'">
                {{getTextByDataRewards(element,getColumnDefinationByMappedToPropertyRewards(column))}}
              </span>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="getDisplayedColumnsRewards()"></tr>
          <tr mat-row *matRowDef="let row; columns: getDisplayedColumnsRewards(); ">
          </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5]" [pageSize]="5" showFirstLastButtons [hidden]="!tableready"></mat-paginator>
      </mat-tab>
      <mat-tab [hidden]="!checkRewardsPermission()" label="Claims">
        <br /><br /><br /><br />
        <table #TABLE *ngIf="selectedColumnsClaims.length>0 " mat-table [dataSource]="dataSourceClaims" matSort class="table table-bordered table-hover small-txt"
               [hidden]="!tableready">
          <ng-container *ngFor="let columnClaims of getDisplayedColumnsClaims(); let colIndex = index;" [matColumnDef]="columnClaims">
            <th [hidden]="getColumnDefinationByMappedToPropertyClaims(columnClaims).hide" class="sort-column mat-sort-header-button-custom mat-header-cell" mat-header-cell *matHeaderCellDef mat-sort-header
                [matTooltip]="getColumnDefinationByMappedToPropertyClaims(columnClaims).name" [disabled]="!getColumnDefinationByMappedToPropertyClaims(columnClaims).isSortable">
              <span>{{getColumnDefinationByMappedToPropertyClaims(columnClaims).name}}</span>
            </th>
            <td mat-cell *matCellDef="let element" class="mat-cell" [matTooltip]="getTextByDataClaims(element,getColumnDefinationByMappedToPropertyClaims(columnClaims))">
              <span *ngIf="columnClaims !== 'isHidden'">{{getTextByDataClaims(element,getColumnDefinationByMappedToPropertyClaims(columnClaims))}}</span>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="getDisplayedColumnsClaims()"></tr>
          <tr mat-row *matRowDef="let row; columns: getDisplayedColumnsClaims(); ">
          </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5]" [pageSize]="5" showFirstLastButtons [hidden]="!tableready"></mat-paginator>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
