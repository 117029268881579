<style>
  .selectNone {
    display: block;
    border-color: sandybrown;
    border-style: solid;
  }

  .selectGreen {
    display: block;
    border-color: green;
    border-style: solid;
  }

  .selectBlue {
    display: block;
    border-color: blue;
    border-style: solid;
  }

  .selectOrange {
    display: block;
    border-color: orange;
    border-style: solid;
  }

  .selectPurple {
    display: block;
    border-color: purple;
    border-style: solid;
  }

  .selectRed {
    display: block;
    border-color: red;
    border-style: solid;
  }

  .selectSilver {
    display: block;
    border-color: silver;
    border-style: solid;
  }

  .selectBlack {
    display: block;
    border-color: black;
    border-style: solid;
  }

  .tabDisplay {
    display: inline-block;
    float: left;
    width: auto;
    min-width: 40px;
    height: 40px;
  }

  .tabDisplaySelected {
    display: inline-block;
    float: left;
    width: auto;
    min-width: 40px;
    height: 40px;
    background-color: #143085;
    border-color: darkblue;
    border-Top-style: solid;
    border-Right-style: solid;
    border-left-style: solid;
    border-top-width: 1.3px;
    border-right-width: 1.3px;
    border-left-width: 1.3px;
  }

  .tabDisplay > a {
    margin-left: 15px;
    margin-right: 15px;
    color: var(--blue);
  }

  .tabDisplaySelected > a {
    margin-left: 15px;
    margin-right: 15px;
    color: #ffffff;
  }
</style>
<div class="box-typical box-typical-padding">
  <div class="row">

    <div>
      <mat-radio-group [(ngModel)]="optionRadioSelected" aria-label="Select an option">
        <mat-radio-button value="subclient" (change)="onSubclientSelected()"
                          style="margin-left: 25px; float:left; display:inline-block">Assign Subclient </mat-radio-button>
        <mat-radio-button value="visibility" (change)="selectWhatWeShowMenu()"
                          style="margin-left: 25px; float: left; display: inline-block">What we show </mat-radio-button>
        <mat-radio-button value="viewing" (change)="selectWhatWeSeeMenu()"
                          style="margin-left: 25px; float: left; display: inline-block">What we see </mat-radio-button>
      </mat-radio-group>
    </div>
    <div style=" font-size:16px; margin-left: 50px; clear: both" *ngIf="optionRadioSelected !== 'subclient'">
      <div *ngIf="(roleTemplateName == 'Client Owner' || roleTemplateName == 'Super Admin')">
        <mat-label>Select SubClient</mat-label>
        <mat-select [(ngModel)]="selectedSubclientId" #subClient="ngModel" name="subClient"
                    (selectionChange)="onSubclientSelected()">
          <mat-option *ngFor="let subclient of subclients"
                      [value]="subclient.id">{{getLocationNameById(subclient.locationId)}}</mat-option>
        </mat-select>
      </div>
      <div *ngIf="selectedSubclient && roleTemplateName !== 'Client Owner' && roleTemplateName !== 'Super Admin'">
        {{getLocationNameById(selectedSubclient.locationId)}}
      </div>
    </div>

  </div>

  <div style="height: 900px;" class="row">
    <div style="display: inline-block;float: left;clear: left;margin-top: 40px;" *ngIf="optionRadioSelected == 'subclient'" class="col-sm-3">
      <div class="tree-control">
        <input type="text" class="form-control" name="locationSearchString" [(ngModel)]="locationSearchString"
               (keyup)="searchLocation()">
        <ul>
          <li *ngFor="let location of locations2">
            <ng-template [ngTemplateOutlet]="locationTemplateRefSubclient"
                         [ngTemplateOutletContext]="{ $implicit: location }">
            </ng-template>
          </li>
        </ul>
      </div>

      <div class="form-group" *ngIf="!selectedLocation && (roleTemplateName === 'Client Owner' || roleTemplateName === 'Super Admin')">
        Select a Location to Assign it
      </div>
      <div class="form-group" *ngIf="selectedLocation && (roleTemplateName === 'Client Owner' || roleTemplateName === 'Super Admin')">
        <label for="searchUserClientId"> Set the owner of {{selectedLocation.name}} to </label>
        <select class="form-control" [(ngModel)]="selectedUser" name="selectedUser">
          <option *ngFor="let user of usersByLocation" [ngValue]="user">{{user.name}}</option>
        </select>
        <button mat-raised-button color="primary" (click)="createSubclient()">Confirm</button>
      </div>

    </div>


    <div *ngIf="optionRadioSelected == 'subclient'" class="col-sm-6"
         style="display: inline-block; float: left; margin-top:40px">
      <table class="table table-bordered table-hover" *ngIf="subclients.length > 0">
        <thead>
          <tr>
            <th>#</th>
            <th>Owner</th>
            <th>Location</th>
            <th *ngIf="roleTemplateName === 'Client Owner' || roleTemplateName === 'Super Admin'">Settings</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let subclient of subclients; let i=index" role="row">
            <td>{{subclient.id}}</td>
            <td>{{getUserNameById(subclient.userId)}}</td>
            <td>{{getLocationNameById(subclient.locationId)}}</td>

            <td *ngIf="roleTemplateName === 'Client Owner' || roleTemplateName === 'Super Admin'">
              <a (click)="openEditSubclient(subclient.id)" class="icon-btn"><i class="fa fa-edit"></i></a>
              <a (click)="deleteSubclient(subclient)" class="icon-btn"><i class="fa fa-trash"></i></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>





    <div style="margin-top:60px; clear: left;" *ngIf="optionRadioSelected == 'visibility'">
      <div class="col-sm-10"
           style="min-width:100%; height: 40px;min-height: 40px;max-height: 40px;border-bottom-width: 1.3px;border-bottom-style: solid; border-color:darkblue;"
           *ngIf="reportTypes && reportTypes.length !== 0">
        <div class="tabDisplay"
             [ngClass]="{'tabDisplaySelected': selectedReportTypeId === 0, selectRed: !subclientCanControlAllReports }"
             (click)="selectedReportTypeId = 0; onSubclientSelected();">
          <a>All Reports</a>
        </div>
        <div class="tabDisplay" *ngFor="let reportType of reportTypes"
             [ngClass]="{'tabDisplaySelected': selectedReportTypeId === reportType.id, selectRed: getSubclientOptionForReport(reportType)  }"
             (click)="selectedReportTypeId = reportType.id; onSubclientSelected();">
          <a>{{reportType.name}}</a>
        </div>
      </div>
      <div class="box-typical box-typical-padding" style="width:20vw; display: inline-block; float: left;">
        <div class="tree-control">
          <input type="text" class="form-control" name="locationSearchString" [(ngModel)]="locationSearchString"
                 (keyup)="searchLocation(locationsForSelectedSubclient[0])">

          <ul>
            <li *ngFor="let location of locationsForSelectedSubclient">
              <ng-template [ngTemplateOutlet]="locationTemplateRefVisibility"
                           [ngTemplateOutletContext]="{ $implicit: location }">
              </ng-template>
            </li>
          </ul>
        </div>
        <div class="form-group" *ngIf="!selectedLocation &&
         (roleTemplateName === 'Client Owner' || roleTemplateName === 'Super Admin' || (subclientCanControlAllReports && selectedReportId === 0 ) ||
         (currentReportSubclientSettings && currentReportSubclientSettings.subclientSettingOptionId === 1))">
          Select a Location to Assign it
        </div>

        <div *ngIf="selectedLocation &&
         (roleTemplateName === 'Client Owner' || roleTemplateName === 'Super Admin' || (subclientCanControlAllReports && selectedReportId === 0 ) ||
         (currentReportSubclientSettings && currentReportSubclientSettings.subclientSettingOptionId === 1))">

          <mat-label>Choose How {{getLocationNameById(selectedLocation.id)}} will be shown to others</mat-label>
          <mat-select [(ngModel)]="selectedLocation.subclientVisibilitySettingOptionId" #visibility="ngModel"
                      name="visibility">
            <mat-option *ngFor="let option of subclientVisibilitySettingOptions" [value]="option.id"> {{option.name}}</mat-option>
          </mat-select>


          <button style="clear:left" mat-raised-button color="primary"
                  (click)="updateVisibilitySetting()">
            Confirm
          </button>
        </div>
      </div>


      <div style=" display: inline-block; float: left;"
           *ngIf=" selectedLocation && selectedLocation.subclientVisibilitySettingOptionId === 4">
        <div class="tree-control">
          <ng-container *ngFor="let subclient of subclients">
            <div *ngIf="subclient.id != selectedSubclientId">
              <input type="checkbox" [(ngModel)]="subclient.selected"
                     (change)="addSubclientToListForVisibilitySetting(subclient.id, $event.target.checked)" />
              {{getLocationNameById(subclient.locationId)}}

            </div>
          </ng-container>
        </div>
        <button mat-raised-button color="primary" (click)="updateVisibilitySetting()">Confirm</button>
      </div>



    </div>


    <div *ngIf="optionRadioSelected == 'viewing'">
      <div class="col-sm-10"
           style="margin-top: 60px; min-width: 100%; height: 40px; min-height: 40px; max-height: 40px; border-bottom-width: 1.3px; border-bottom-style: solid; border-color: darkblue;"
           *ngIf="reportTypes && reportTypes.length !== 0">
        <div class="tabDisplay"
             [ngClass]="{'tabDisplaySelected': selectedReportTypeId === 0, selectRed: !subclientCanControlAllReports }"
             (click)="selectedReportTypeId = 0; onSubclientSelected();">
          <a>All Reports</a>
        </div>
        <div class="tabDisplay" *ngFor="let reportType of reportTypes"
             [ngClass]="{'tabDisplaySelected': selectedReportTypeId === reportType.id, selectRed:getSubclientOptionForReport(reportType)   }"
             (click)="selectedReportTypeId = reportType.id; onSubclientSelected();">
          <a>{{reportType.name}}</a>
        </div>
      </div>
      <div class="box-typical box-typical-padding" style="width:20vw; display: inline-block; float: left;">
        <div class="tree-control">
          <input type="text" class="form-control" name="locationSearchString" [(ngModel)]="locationSearchString">

          <ul>
            <li *ngFor="let location of viewSettingLocationTree">
              <ng-template [ngTemplateOutlet]="locationTemplateRef" [ngTemplateOutletContext]="{ $implicit: location }">
              </ng-template>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #locationTemplateRefSubclient let-location>
  <a (click)="onLocationSelected(location)">
    <span *ngIf="location.children.length">
      <i></i>
    </span>
    <span [hidden]="location.IsVisible || location.isFiltered">{{ location.name }}</span>
  </a>
  <ul>
    <li *ngIf="location.children.length">
      <ng-template ngFor [ngForOf]="location.children" [ngForTemplate]="locationTemplateRefSubclient">
      </ng-template>
    </li>
  </ul>
</ng-template>

<ng-template #locationTemplateRefVisibility let-location>
  <a [ngClass]="{
     selectSilver: (selectedLocation && location.id === selectedLocation.id && false),
     selectNone: (location.subclientVisibilitySettingOptionId === 0),
     selectGreen: (location.subclientVisibilitySettingOptionId === 1),
     selectBlue: (location.subclientVisibilitySettingOptionId === 2),
     selectOrange: (location.subclientVisibilitySettingOptionId === 3),
     selectPurple: (location.subclientVisibilitySettingOptionId === 4),
     selectRed: (location.subclientVisibilitySettingOptionId === 5 ),
     selectBlack: (location.subclientVisibilitySettingOptionId === 7 )
     }" (click)="onLocationSelected(location)">

    <span *ngIf="location.children.length">
      <i></i>
    </span>
    <span [hidden]="location.IsVisible || location.isFiltered">{{ location.name }}</span>

  </a>
  <ul>
    <li *ngIf="location.children.length">
      <ng-template ngFor [ngForOf]="location.children" [ngForTemplate]="locationTemplateRefVisibility">
      </ng-template>
    </li>
  </ul>
</ng-template>



<!-- Template for Location Tree -->

<ng-template #locationTemplateRef let-location>
  <a [ngClass]="{
     selectSilver: (selectedLocation && location.id === selectedLocation.id && false),
     selectNone: (location.subclientVisibilitySettingOptionId === 0),
     selectGreen: (location.subclientVisibilitySettingOptionId === 1 || subclientViewingSettings && lookUpViewingSetting(location.id) === 2),
     selectBlue: (location.subclientVisibilitySettingOptionId === 2),
     selectOrange: (location.subclientVisibilitySettingOptionId === 3),
     selectPurple: (location.subclientVisibilitySettingOptionId === 4),
     selectRed: (location.subclientVisibilitySettingOptionId === 5 || subclientViewingSettings && lookUpViewingSetting(location.id) === 3),
     selectBlack: (location.subclientVisibilitySettingOptionId === 7 || subclientViewingSettings && lookUpViewingSetting(location.id) === 4)
     }" (click)="onLocationSelected(location)">
   
    <span *ngIf="location.children.length">
      <i></i>
    </span>
    <span [hidden]="location.IsVisible || location.isFiltered">{{ location.name }}</span>

  </a>
  <ul>
    <li *ngIf="location.children.length">
      <ng-template ngFor [ngForOf]="location.children" [ngForTemplate]="locationTemplateRef">
      </ng-template>
    </li>
  </ul>
</ng-template>



<!--- ClientManager Edits Subclient Settings Dialog  -->
<div *ngIf="selectedSubclient && dialog.isOpen" class="dialog" [hidden]="!dialog.isOpen"
     style="min-height:60vh;  position: fixed; z-index:100">
  <header>
    <span *ngIf="dialog.headerText">{{dialog.headerText}}</span>
    <a *ngIf="dialog.cancelButton" class="cancel-btn" (click)="dialog.cancel()"><i class="fa fa-times"></i></a>
  </header>
  <section style="min-height:60vh">
    <form #f="ngForm" (ngSubmit)="f.form.valid && editSubclient()" novalidate>
      <div class="form-group row" *ngFor="let settings of selectedSubclient.subclientSetting">
        <label class="col-sm-4 form-control-label">
          {{getReportNameById(settings.reportTypeId)}} <sup class="required">*</sup>
        </label>
        <div class="col-sm-8">
          <p class="form-control-static">
            <select class="form-control" [(ngModel)]="settings.subclientSettingOptionId"
                    #{{settings.reportTypeId}}="ngModel" [ngClass]="{'is-invalid': f.submitted && name.invalid }"
                    name="{{settings.reportTypeId}}" required>

              <option *ngFor="let option of subclientSettingOptions" [value]="option.id"> {{option.name}}</option>
            </select>
            <span *ngIf="f.submitted && name.invalid" class="invalid-feedback">
              <span>Option is required.</span>
            </span>
          </p>
        </div>
      </div>


      <div class="form-group row">
        <label class="col-sm-4 form-control-label"></label>
        <div class="col-sm-8">
          <p class="form-control-static">
            <button type="submit" class="btn btn-inline">Submit</button>
          </p>
        </div>
      </div>
    </form>
  </section>
</div>
