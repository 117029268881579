import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Category } from '../models/category';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private _baseUrl = '/api/categories';

  constructor(private http: HttpClient) {}

  getCategories(clientId: number): Observable<Category[]> {
    return this.http.get<Category[]>(this._baseUrl + '/false/0/' + clientId);
  }

  getCategoryTree(clientId: number): Observable<Category[]> {
    return this.http.get<Category[]>(this._baseUrl + '/true/0/' + clientId);
  }

  getCategoryTreeWithInventoryCount(location, clientId: number): Observable<Category[]> {
    return this.http.get<Category[]>(this._baseUrl + '/' + true + '/' + location + '/' + clientId);
  }
  getLimitedCategoryTreeWithInventoryCount(location, clientId: number): Observable<Category[]> {
    return this.http.get<Category[]>(this._baseUrl + '/ClientCategorySetting/' + true + '/' + location + '/' + clientId);
  }
}
