import { SubclientVisibilitySettingSubclient } from './subclientVisibilitySettingSubclient';
export class SubclientVisibilitySetting {
  id: number;
  clientId: number;
  subclientId: number;
  reportTypeId: number;
  locationId: number;
  subclientVisibilitySettingOptionId: number;
  subclientVisibilitySettingSubclients?: SubclientVisibilitySettingSubclient[];
  subclientVisibilitySettingLocation?: number[];
}
