import { CommissionFormula } from './commission-formula';

export class CommissionGroup {
    id?: number;
    name: string;
    basePercentage: number;
    target: string;
    drawOpt: string;
    targetId?: number;
    commissionFormulas?: CommissionFormula[];
    skuGroups?: any[];
    conversionData?: any[];
    earnedPay?: number;
    trendingEarned?: number;
    maxEarnable?: number;
    totalEarned?: number;
}
